import angular from 'angular';
import WebFont from 'webfontloader';

/**
 * @ngdoc service
 * @name frontendApp.fontService
 * @description
 * # fontService
 * Factory in the frontendApp.
 */
angular.module('frontendApp').factory('fontService', [
  '$http',
  'messageService',
  'gettextCatalog',
  '$q',
  '$timeout',
  function($http, messageService, gettextCatalog, $q, $timeout) {
    var service = this;

    this.availableFontFamilies = [
      'Cedarville Cursive',
      'Herr Von Muellerhoff',
      'Dawning of a New Day',
      'Caveat',
      'Reenie Beanie',
      'Sacramento',
      'Waiting for the Sunrise',
      'Zeyada'
    ];

    this.loadedFonts = [];
    this.failedFonts = [];

    this.loadStarted = false;
    this.loadFonts = function() {
      service.loadStarted = true;
      WebFont.load({
        google: {
          families: service.availableFontFamilies
        },
        fontactive: function(font) {
          service.loadedFonts.push(font);
        },
        fontinactive: function(font) {
          service.failedFonts.push(font);
        }
      });
    };

    this.fontHasLoaded = function(font) {
      return ~service.loadedFonts.indexOf(font);
    };

    this.fontHasFailed = function(font) {
      return ~service.failedFonts.indexOf(font);
    };

    this.waitForFont = function(font) {
      var deferred = $q.defer();
      if (!service.loadStarted) {
        service.loadFonts();
      }
      var checkReady = function() {
        if (service.fontHasLoaded(font)) {
          deferred.resolve(font);
        } else if (service.fontHasFailed(font)) {
          deferred.reject(font);
        } else {
          $timeout(checkReady, 10);
        }
      };
      checkReady();
      return deferred.promise;
    };

    return this;
  }
]);
