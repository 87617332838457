<template>
  <div>
    <h3 v-translate>Two-Factor Authentication</h3>
    <p v-if="hasTokenGeneratorDevice" v-translate>
      Tokens will be generated by your token generator.
    </p>
    <template v-if="user.has_2fa">
      <SrButton
        sr-style="outline"
        class="ml-0"
        small
        @click="
          openEnableTwoFactorAuthModal({ add_only: true, step: 'backup' })
        "
      >
        <translate>Backup tokens</translate>
      </SrButton>
      <v-list>
        <template v-for="device in user.two_factor.devices">
          <v-list-tile :key="device.uuid" class="py-2">
            <v-list-tile-avatar>
              <span
                class="fa icon--32"
                :class="[
                  device.device_type === 'generator' ? 'fa-mobile' : 'fa-phone'
                ]"
              ></span>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title v-if="device.device_type === 'phone'">
                <translate>Phone</translate>
                <Space></Space>
                <span>{{ device.number }}</span>
              </v-list-tile-title>
              <v-list-tile-title
                v-else-if="device.device_type === 'generator'"
                v-translate
              >
                Token Generator
              </v-list-tile-title>
              <v-list-tile-sub-title>
                <translate>Last used:</translate>
                <translate v-if="device.active">Active now</translate>
                <template v-else>
                  {{ device.last_usage | datetime }}
                </template>
              </v-list-tile-sub-title>
            </v-list-tile-content>
            <v-list-tile-action>
              <SrButton
                sr-style="danger"
                small
                :loading="loading"
                @click="confirmDeviceDelete(device.device_type, device.uuid)"
              >
                <translate>Delete</translate>
              </SrButton>
            </v-list-tile-action>
          </v-list-tile>
        </template>
      </v-list>
      <v-layout mt-4>
        <v-flex>
          <SrButton
            small
            @click="openEnableTwoFactorAuthModal({ add_only: true })"
          >
            <translate>Add Two-Factor Authentication Device</translate>
          </SrButton>
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex shrink>
          <SrButton
            small
            sr-style="danger"
            @click="confirmTwoFactorAuthDisable"
          >
            <translate>Disable Two-factor Authentication</translate>
          </SrButton>
        </v-flex>
      </v-layout>
    </template>
    <div v-else>
      <v-layout wrap>
        <v-flex xs12>
          <p v-translate>
            Two-factor authentication is not enabled for your account. Enable
            two-factor authentication for enhanced account security.
          </p>
        </v-flex>
      </v-layout>
      <v-layout justify-center>
        <SrButton @click="openEnableTwoFactorAuthModal">
          <translate>Enable Two-Factor Authentication</translate>
        </SrButton>
      </v-layout>
    </div>
  </div>
</template>

<script>
import permissions from '@/mixins/permissions';
import { mapActions, mapGetters } from 'vuex';
import Space from '@/components/Space';

export default {
  components: { Space },
  mixins: [permissions],
  data: function() {
    return {
      loading: false
    };
  },
  computed: {
    ...mapGetters('users', ['user', 'hasTokenGeneratorDevice'])
  },
  methods: {
    ...mapActions('modals', [
      'openEnableTwoFactorAuthModal',
      'openConfirmationModal'
    ]),
    ...mapActions('users', ['deleteDevice', 'disableTwoFactorAuth']),
    async confirmDeviceDelete(deviceType, deviceUuid) {
      if (
        await this.openConfirmationModal({
          title: this.$gettext('Are you sure you want to delete this device?'),
          body: this.$gettext('This cannot be undone.'),
          confirmationButtonText: this.$gettext('Delete device'),
          confirmationButtonColor: 'error'
        })
      ) {
        this.loading = true;
        await this.deleteDevice({ deviceType, deviceUuid });
        this.loading = false;
      }
    },
    async confirmTwoFactorAuthDisable() {
      if (
        await this.openConfirmationModal({
          title: this.$gettext(
            'Are you sure you want to disable two-factor authentication?'
          ),
          confirmationButtonText: this.$gettext('Disable'),
          confirmationButtonColor: 'error'
        })
      ) {
        await this.disableTwoFactorAuth();
      }
    }
  }
};
</script>

<style scoped lang="scss">
.fa.icon--32 {
  color: setting(colors, dark);
}
::v-deep .v-list > div {
  border-bottom: 1px solid setting(colors, border);
}
.v-list__tile__title {
  font-size: setting(font-size) * 1px;
  font-weight: setting(font-weights, bold);
}
</style>
