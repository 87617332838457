<template>
  <v-layout wrap>
    <v-flex
      v-for="(name, key) in documentsSettingOptions"
      :key="key"
      data-test-id="documents-settings"
      xs6
      sm6
      md4
    >
      <v-checkbox
        :input-value="documentsSettings[key]"
        :label="name"
        :column="false"
        :height="14"
        :name="key"
        :readonly="readonly"
        hide-details
        @change="newValue => handleUpdate(key, newValue)"
      ></v-checkbox>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    documentsSettings: { type: Object, required: true },
    category: { type: String, required: true },
    readonly: { type: Boolean, default: false }
  },
  computed: {
    documentsSettingOptions() {
      return {
        documents_as_attachments: this.$gettext(
          'Send documents as email attachments'
        )
      };
    }
  },
  methods: {
    ...mapActions('users', ['updateDocumentSettings']),
    ...mapActions('events', ['trackDocumentSettingsEvent']),
    handleUpdate(key, value) {
      this.updateDocumentSettings({
        settings: { ...this.documentsSettings, [key]: value },
        category: this.category
      });
      this.trackDocumentSettingsEvent({
        settings: this.documentsSettings,
        category: this.category
      });
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .v-label {
  font-size: setting(font-size) * 1px;
  font-weight: setting(font-weights, normal);
}
</style>
