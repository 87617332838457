<template>
  <v-app v-if="userLoaded">
    <CheckDocumentViewPermission>
      <v-container pa-0>
        <v-layout wrap>
          <v-flex xs12 text-xs-center>
            <v-btn
              outline
              color="primary"
              :disabled="!canCreateTemplates && !canBeUpgraded"
              @click="createTemplate"
            >
              <i
                class="icon icon--document-stack v-icon--left"
                aria-hidden="true"
              ></i>
              <span v-translate>Create template</span>
              <Space></Space>
              <v-tooltip max-width="350" nudge-right="15" right>
                <template v-slot:activator="{ on }">
                  <v-icon size="13" style="color: inherit" v-on="on">
                    help_outline
                  </v-icon>
                </template>
                <p v-translate class="mt-0">
                  Using templates you can reuse documents multiple times. This
                  way you don't have to prepare the document each time when
                  sending a SignRequest.
                </p>
                <p v-translate>
                  Choose 'SignRequest' to use the template the normal way in
                  which it's possible to have multiple signers all signing 1
                  document. The result is 1 document with 1 or more signers
                  (signatures).
                </p>
                <p v-translate>
                  Choose 'Bulk send' to use one template and send it to up to
                  1000 contacts at once. To clarify: the bulk send option
                  results in up to 1000 separate contracts that all have one
                  signer.
                </p>
              </v-tooltip>
            </v-btn>
          </v-flex>
          <v-flex xs12 text-xs-center>
            <p
              v-if="canSendBulkSend || numSignRequestsLeftInBundle > 0"
              v-translate="{ count: numSignRequestsLeftInBundle }"
              class="font-weight-light"
              :translate-n="numSignRequestsLeftInBundle"
              translate-plural="You currently have %{ count } SignRequests left in your bulk send bundle."
            >
              You currently have %{ count } SignRequest left in your bulk send
              bundle.
            </p>
            <a
              v-if="user.permissions.allow_buy_credit"
              v-translate
              href="#/bundle"
            >
              Click here to buy more bulk send SignRequests
            </a>
            <a
              v-if="!user.permissions.allow_buy_credit"
              v-translate
              href="#/billing"
            >
              To send SignRequests in bulk upgrade to the Business Plan
            </a>
          </v-flex>
          <v-flex xs12>
            <div class="list-group">
              <div
                v-for="template in userTemplates"
                :key="template.uuid"
                class="list-group__item"
              >
                <div class="flag">
                  <div class="flag__object">
                    <i
                      class="icon icon--document-stack--25"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <div class="flag__body">
                    <p>
                      <strong>{{ template.name }}</strong>
                      <v-layout wrap>
                        <span class="status">
                          <v-btn
                            class="mr-1 ml-0"
                            small
                            outline
                            color="primary"
                            @click="createSignRequestFromTemplate(template)"
                          >
                            SignRequest
                          </v-btn>
                          <v-btn
                            v-if="canSendBulkSend"
                            class="mr-1 ml-0"
                            small
                            outline
                            color="primary"
                            :disabled="template.who !== 'o'"
                            style="pointer-events: auto"
                            @click="createBulkSend(template)"
                          >
                            <translate>Bulk send</translate>
                            <Space></Space>
                            <v-tooltip
                              v-if="template.who !== 'o'"
                              max-width="350"
                              nudge-right="15"
                              right
                            >
                              <template v-slot:activator="{ on }">
                                <v-icon
                                  size="13"
                                  style="color: inherit"
                                  v-on="on"
                                >
                                  help_outline
                                </v-icon>
                              </template>
                              <translate>
                                It's only possible to use bulk send when the
                                document needs to be signed by the receiver
                              </translate>
                            </v-tooltip>
                            <v-tooltip
                              v-if="
                                template.who === 'o' &&
                                  numSignRequestsLeftInBundle <= 0
                              "
                              max-width="350"
                              nudge-right="15"
                              right
                            >
                              <template v-slot:activator="{ on }">
                                <v-icon
                                  size="13"
                                  style="color: inherit"
                                  v-on="on"
                                >
                                  help_outline
                                </v-icon>
                              </template>
                              <translate>
                                You currently don't have enough SignRequests in
                                your bulk send bundle.
                              </translate>
                            </v-tooltip>
                            <v-tooltip
                              v-if="
                                template.who === 'o' &&
                                  numSignRequestsLeftInBundle > 0
                              "
                              max-width="350"
                              nudge-right="15"
                              right
                            >
                              <template v-slot:activator="{ on }">
                                <v-icon
                                  size="13"
                                  style="color: inherit"
                                  v-on="on"
                                >
                                  help_outline
                                </v-icon>
                              </template>
                              <span
                                v-translate="{
                                  count: numSignRequestsLeftInBundle
                                }"
                                :translate-n="numSignRequestsLeftInBundle"
                                translate-plural="You currently have %{ count } SignRequests left in your bulk send bundle."
                              >
                                You currently have %{ count } SignRequest left
                                in your bulk send bundle.
                              </span>
                            </v-tooltip>
                          </v-btn>
                          <v-btn
                            class="mr-1 ml-0"
                            small
                            outline
                            color="primary"
                            :disabled="!template.may_edit"
                            @click="openTemplate(template)"
                          >
                            <translate>Edit</translate>
                          </v-btn>
                          <v-btn
                            class="mr-1 ml-0"
                            small
                            outline
                            color="primary"
                            :disabled="!template.may_edit"
                            @click="copyTemplateThenGetTemplates(template.uuid)"
                          >
                            <translate>Copy</translate>
                          </v-btn>
                          <v-btn
                            class="mr-1 ml-0"
                            small
                            outline
                            color="primary"
                            :disabled="!template.may_edit"
                            @click="
                              openMoveTemplateModal({ uuid: template.uuid })
                            "
                          >
                            <translate>Move</translate>
                          </v-btn>
                        </span>
                        <v-spacer></v-spacer>
                        <v-btn
                          small
                          color="error"
                          :disabled="!template.may_edit"
                          @click="confirmDelete(template.uuid)"
                        >
                          <translate>Delete</translate>
                        </v-btn>
                      </v-layout>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </CheckDocumentViewPermission>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import permissions from '@/mixins/permissions';
import maximizeWindow from '@/utils/maximizeWindow';
import Space from './Space';
import CheckDocumentViewPermission from './CheckDocumentViewPermision';
import location from '@/utils/location';

export default {
  components: {
    Space,
    CheckDocumentViewPermission
  },
  mixins: [permissions],
  computed: {
    ...mapGetters('users', [
      'user',
      'userTemplates',
      'canCreateSignRequestFromTemplate',
      'canSendBulkSend',
      'canBeUpgraded',
      'canCreateTemplates',
      'numSignRequestsLeftInBundle'
    ])
  },
  created() {
    this.getUserTemplates();
  },
  methods: {
    ...mapActions('templates', [
      'getUserTemplates',
      'copyTemplate',
      'deleteTemplate'
    ]),
    ...mapActions('modals', [
      'openMoveTemplateModal',
      'openConfirmationModal',
      'showUploadModal'
    ]),
    openTemplate(template) {
      location.assign(template.template_url);
    },
    createSignRequestFromTemplate(template) {
      if (this.canCreateSignRequestFromTemplate) {
        location.assign(
          `/#/?api=v1&template_uuid=${template.uuid}&source=self`
        );
      } else {
        this.showSubscribeModal({ plan: 'PRO' });
      }
    },
    createBulkSend(template) {
      if (this.canSendBulkSend) {
        if (this.isSalesforceBulkSend()) {
          maximizeWindow();
          const queryString = location.href.split('?')[1];
          location.assign(`${template.template_url}?${queryString}`);
        } else {
          location.assign(`${template.template_url}?bulksend=1`);
        }
      } else {
        this.showSubscribeModal({ plan: 'SME' });
      }
    },
    isSalesforceBulkSend() {
      // TODO: Use vue router params
      return location.href.includes('sfbulk');
    },
    async copyTemplateThenGetTemplates(uuid) {
      await this.copyTemplate({ uuid });
      this.getUserTemplates();
    },
    async confirmDelete(uuid) {
      const confirmed = await this.openConfirmationModal({
        title: this.$gettext('Are you sure you want to delete this template?'),
        body: this.$gettext('This cannot be undone.'),
        confirmationButtonText: this.$gettext('Delete'),
        confirmationButtonColor: 'error'
      });

      if (confirmed) {
        await this.deleteTemplate({ uuid });
        await this.getUserTemplates();
      }
    },
    createTemplate() {
      if (this.canCreateTemplates) {
        this.showUploadModal({ as_template: true });
      } else if (this.canBeUpgraded) {
        this.showSubscribeModal();
      } else {
        // this should be unreachable
        throw new Error('Invalid state');
      }
    }
  }
};
</script>

<style scoped lang="scss">
.status .v-btn {
  min-width: inherit;
}
</style>
