<template>
  <v-select
    :items="statusChoices"
    label="Status"
    :value="value"
    @change="$emit('input', $event)"
  ></v-select>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    value: { type: String, default: '*' }
  },
  computed: {
    ...mapGetters('conf', ['languageCode']),
    statusChoices() {
      return this.$store.state.documents.statusChoices.map(x => ({
        ...x,
        text: _.capitalize(x.text)
      }));
    }
  },
  watch: {
    languageCode() {
      this.getStatusChoices();
    }
  },
  created() {
    this.getStatusChoices();
  },
  methods: { ...mapActions('documents', ['getStatusChoices']) }
};
</script>
