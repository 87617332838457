<template>
  <v-dialog
    ref="modal"
    v-model="showModal"
    lazy
    max-width="500"
    :content-class="contentClass"
  >
    <v-card
      role="dialog"
      :aria-hidden="!showModal"
      :aria-modal="showModal"
      aria-labelledby="many-documents-gmodal-title"
      :class="{
        mobile: $isMobile
      }"
    >
      <sr-button
        class="close"
        sr-style="text"
        data-test-id="close-many-doc-btn"
        @click="closeModal"
      >
        <v-icon aria-label="$gettext('Close upgrade dialog')" class="round">
          close
        </v-icon>
      </sr-button>
      <template v-if="!hasTrialEnded">
        <v-card-title id="many-documents-gmodal-title" class="headline">
          <span v-if="isSubscribeOnly" key="start-trial">
            <strong v-translate>Start TRIAL</strong>
          </span>
          <span v-else key="limited">
            <strong v-translate>
              Your current FREE plan only supports 10 documents per month.
            </strong>
          </span>
        </v-card-title>
        <v-card-text>
          <p key="trial-ended-text" v-translate>
            Start a 14-day trial to send unlimited documents and get access to
            all {{ planDisplayName }} features.
          </p>

          <div class="sr-layout margin-bottom align--center">
            <div class="sr-layout__item lap-one-whole margin-top">
              <article
                v-if="
                  subscriptionDetails &&
                    subscriptionDetails.details &&
                    subscriptionDetails.details.plan_type
                "
                class="subscription block selected fadeIn"
              >
                <div class="block__header">
                  <h2>{{ planDisplayName }} PLAN</h2>
                </div>
                <div class="block__devider"></div>
                <div class="block__item">
                  <ul
                    v-for="(feature, index) in planFeatures"
                    :key="index"
                    class="list list--included"
                  >
                    <li>{{ feature }}</li>
                  </ul>
                </div>
              </article>
            </div>
          </div>
        </v-card-text>
      </template>
      <template v-else>
        <v-card-title id="many-documents-gmodal-title" class="headline">
          <span>
            <strong v-translate>
              Your Trial has ended!
            </strong>
          </span>
        </v-card-title>
        <v-card-text>
          <p key="trial-text" v-translate>
            Please subscribe to the SignRequest PRO plan to continue using PRO
            features
          </p>
        </v-card-text>
      </template>
      <v-card-actions>
        <sr-button
          v-if="hasTrialEnded"
          key="trial-ended-btn"
          sr-style="fancy"
          round
          :loading="isLoading"
          class="subscribe-btn"
          @click="goToBilling"
        >
          <translate>Subscribe</translate>
        </sr-button>
        <sr-button
          v-else
          key="trial-start"
          sr-style="fancy"
          round
          :loading="isLoading"
          class="subscribe-btn"
          @click="goToBilling"
        >
          <translate>Start free trial</translate>
        </sr-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import SrButton from '@/components/elements/SrButton';
import ModalMixin from '@/components/modals/ModalMixin';
import Screen from '@/mixins/Screen';

export default {
  components: {
    SrButton
  },
  mixins: [ModalMixin('tooManyDocuments'), Screen],
  props: {
    contentClass: { type: String, default: '' }
  },
  data: function() {
    return {
      plan: 'PRO'
    };
  },
  computed: {
    ...mapGetters('subscription', ['subscriptionDetails']),
    ...mapGetters('users', ['hasTrialEnded']),
    ...mapGetters('api', ['isLoading']),
    ...mapGetters('conf', ['getTrialSubscribeVersion']),
    planDisplayName() {
      const plans = {
        PRO: 'Professional',
        SME: 'Business'
      };
      return plans[this.plan];
    },
    trialDays() {
      if (!this.hasTrialEnded && !this.isSubscribeOnly) {
        const trialDays = this.$route.params.trial_days || 14;
        if (trialDays === 14 || trialDays === 30) {
          return trialDays;
        } else {
          return 14;
        }
      }
      return 0;
    },
    isSubscribeOnly() {
      return Boolean(this.$route.params.open_subscribe);
    },
    trackingParams() {
      return {
        trial_ended: this.hasTrialEnded,
        trial_days: this.trialDays,
        plan: this.plan,
        plan_interval: this.subscriptionDetails.details.interval,
        isSubscribeOnly: this.isSubscribeOnly,
        trial_subscribe_version: this.getTrialSubscribeVersion,
        feature: this.modalOptions.feature,
        trigger: this.modalOptions.trigger
      };
    },
    trackingCategory() {
      return this.hasTrialEnded ? 'Trial Ended Modal' : 'Subscribe Modal';
    },
    planFeatures() {
      return [
        this.$gettext('Branded account, logo and color'),
        this.$gettext('Custom templates'),
        this.$gettext('Store and manage documents'),
        this.$gettext('Cancel online at any time')
      ];
    }
  },
  watch: {
    showModal: {
      immediate: false,
      async handler(showModal) {
        if (showModal) {
          await this.getSubscription();
          this.trackEvent({
            action: `${this.trackingCategory} Opened`,
            category: this.trackingCategory,
            params: this.trackingParams
          });
          this.plan =
            this.$route.params.open_subscribe ||
            this.$route.params.plan ||
            'PRO';
        }
      }
    }
  },
  methods: {
    ...mapActions('trackEvents', ['trackEvent']),
    ...mapActions('subscription', ['getSubscription']),
    ...mapActions('modals', ['showSubscribeModal']),
    goToBilling() {
      this.trackEvent({
        action: `${this.trackingCategory} Closed`,
        category: this.trackingCategory,
        params: this.trackingParams
      });
      this.closeModal();
      this.showSubscribeModal({
        trial_days: this.trialDays,
        interval: this.subscriptionDetails.details.interval
      });
    }
  }
};
</script>

<style scoped>
button.sr-button {
  font-size: 18px;
  padding: 0 3rem;
  margin: 0px;
  height: 4rem;
  width: calc(50% - 0.75rem);
}
>>> .v-dialog {
  border-radius: 25px;
}
.v-card {
  padding: 0 3rem;
}
.mobile.v-card {
  padding: 0 2rem;
}
.v-card__title {
  padding: 0;
  padding-top: 4rem;
  padding-bottom: 2rem;
  font-weight: 900;
  font-size: 24px;
  line-height: 1.33;
}
.v-card__text {
  font-size: 17px;
  padding: 0;
}
.v-card__text p {
  margin-top: 0;
  margin-bottom: 24px;
  line-height: 1.33;
}
.v-card__text label,
.v-card__text span.form--error {
  display: block;
  font-size: 16px;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.27px;
}
.v-card__text label a {
  text-decoration: underline;
}
.v-card__actions {
  padding: 0px;
  padding-top: 38px;
  padding-bottom: 36px;
  justify-content: center;
}
.v-btn-toggle .v-btn {
  opacity: 1;
  padding: 10px 20px;
}
.v-btn-toggle--selected,
.v-btn-toggle--selected .v-btn {
  box-shadow: unset;
}
.v-btn-toggle .v-btn:first-child {
  border-radius: 9px 0px 0 9px;
}
.v-btn-toggle .v-btn:last-child {
  border-radius: 0px 9px 9px 0px;
}
button.sr-button.close {
  font-size: 14px;
  position: absolute;
  min-width: 1em;
  width: 1em;
  height: 1em;
  top: 2rem;
  color: #111111;
  opacity: 0.8;
  margin: 0;
  padding: 0;
}
.ltr .close {
  right: 2rem;
}
.rtl .close {
  left: 2rem;
}

.mobile .subscribe-btn {
  width: 100%;
}
</style>
