<template>
  <div>
    <div class="ml-4">
      <h1>{{ plan.displayName }} plan</h1>
      <div class="benefits">
        <p v-show="trialEligible" class="srtext--text subtitle">
          <translate :translate-params="{ trialDays: plan.trialDays }">
            %{trialDays}-day free trial
          </translate>
        </p>
        <h3 v-translate>Unlimited documents</h3>
        <p v-if="plan.name == 'PRO'" class="all-basic-features srtext--text">
          <translate>All Free features</translate>
          +
        </p>
        <p v-else class="all-basic-features srtext--text" tranlsate>
          <translate>All Professional features</translate>
          +
        </p>
        <v-list class="mt-2">
          <v-list-tile-content
            v-for="(benefit, index) in plan.benefits"
            :key="index"
            class="benefit-item mt-2"
          >
            <span class="srtext--text">
              <i class="fa fa-check" aria-hidden="true"></i>
              <translate>{{ benefit }}</translate>
            </span>
          </v-list-tile-content>
        </v-list>
      </div>
    </div>
    <div class="pricing">
      <v-layout
        v-if="trialEligible"
        row
        justify-space-between
        class="price-item py-1"
      >
        <v-flex xs6><span v-translate>Today</span></v-flex>
        <v-flex xs3 class="price">
          <span>{{ planCurrencySymbol }}</span>
          <span>0</span>
        </v-flex>
      </v-layout>
      <v-layout row justify-space-between class="price-item py-1">
        <v-flex xs6>
          <span v-if="trialEligible" v-translate>After the trial</span>
          <span v-else v-translate>Plan price</span>
        </v-flex>
        <v-flex class="price xs3">
          <span>{{ planCurrencySymbol }}</span>
          <span>{{ planPrice }}</span>
        </v-flex>
      </v-layout>
      <span>
        {{ planInterval }}
      </span>
      <div v-if="trialEligible" class="pricing-disclaimer">
        <translate :translate-params="{ trialEnds: trialEndsOnDate }">
          This amount will not be charged before the end of the trial on
          %{trialEnds}. You can cancel the trial at any time.
        </translate>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
export default {
  computed: {
    ...mapState('subscription', ['plan', 'subscriptionDetails']),
    ...mapGetters('subscription', ['trialEligible', 'trialEndsOn']),
    planCurrencySymbol() {
      return this.plan.currency.symbol;
    },
    planPrice() {
      const planPrice = parseInt(this.plan.price);
      return this.plan.interval === 'year' ? planPrice * 12 : planPrice;
    },
    planInterval() {
      return this.plan.interval === 'month'
        ? this.$gettext('per month, per user')
        : this.$gettext('per year, per user');
    },
    trialEndsOnDate() {
      return (
        this.trialEndsOn.getDate() +
        '/' +
        (this.trialEndsOn.getMonth() + 1) +
        '/' +
        this.trialEndsOn.getFullYear()
      );
    }
  }
};
</script>
<style scoped lang="scss">
h1 {
  margin-top: 0.7rem;
  font-size: 1.7rem;
  font-weight: 900;
  letter-spacing: 0.57px;
}
h3 {
  margin-top: 20px;
  color: #3670e1;
  font-weight: normal;
}
.subtitle {
  margin-top: 17px;
  font-size: 16px;
  font-weight: 300;
  opacity: 0.8;
}
.benefits {
  font-size: 16px;
  background-color: transparent;
}
.v-list {
  background-color: transparent;
}
.benefit-item {
  height: 20px;
  font-weight: normal;

  i {
    width: 10px;
    height: 7px;
    color: setting(colors, success);
    margin-right: 8px;
  }

  span {
    opacity: 0.8;
    font-weight: 300;
  }
}

.all-basic-features {
  font-weight: bold;
  font-size: 16px;
  margin-top: 16px;
  opacity: 0.8;
}
.pricing {
  max-width: 247px;
  height: auto;
  width: 100%;
  margin: 60px auto 0;
  border-radius: 20px;
  background-color: setting(colors, backgrount-dark-gray);
  padding: 20px 20px 20px 10px;
  font-size: 1rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.17px;
  color: rgba(0, 0, 0, 0.8);
}
.pricing p {
  margin-top: 15px;
}
.price-item {
  opacity: 0.8;
  font-size: 1.1rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.27px;
  color: #000000;
}
.price-item:first-of-type {
  border-bottom: solid 1px rgba(0, 0, 0, 0.6);
}
.price-item .price {
  font-size: 1.2rem;
  margin-right: 6px;
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
}
.pricing-disclaimer {
  margin-top: 5px;
  text-align: justify;
  font-size: 0.86rem;
}
</style>
