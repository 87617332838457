import angular from 'angular';

/**
 * @ngdoc service
 * @name frontendApp.template
 * @description
 * # confService
 * Service in the frontendApp.
 */
angular.module('frontendApp').service('templateService', [
  '$http',
  '$q',
  '$templateCache',
  function templateService($http, $q, $templateCache) {
    //  Returns a promise which gets the template, either
    //  from the optional template parameter or via a request to the
    //  template url parameter.
    this.getTemplate = function(templateUrl, template) {
      var deferred = $q.defer();
      if (template) {
        deferred.resolve(template);
      } else if (templateUrl) {
        // check to see if the template has already been loaded
        var cachedTemplate = $templateCache.get(templateUrl);
        if (cachedTemplate !== undefined) {
          deferred.resolve(cachedTemplate);
        }
        // if not, let's grab the template for the first time
        else {
          $http({ method: 'GET', url: templateUrl, cache: true })
            .then(function(result) {
              // save template into the cache and return the template
              $templateCache.put(templateUrl, result.data);
              deferred.resolve(result.data);
            })
            .catch(function(error) {
              deferred.reject(error);
            });
        }
      } else {
        deferred.reject('No template or templateUrl has been specified.');
      }
      return deferred.promise;
    };
  }
]);
