import angular from 'angular';

/**
 * @ngdoc service
 * @name frontendApp.conf
 * @description
 * # conf
 * Factory in the frontendApp.
 */
angular.module('frontendApp').factory('confFactory', [
  '$vuex',
  function($vuex) {
    return {
      getSettings: async function() {
        return $vuex.dispatch('conf/loadConfig');
      }
    };
  }
]);
