<template>
  <div>
    <div v-if="canAccessDocuments">
      <slot></slot>
    </div>
    <v-layout v-else>
      <v-flex xs12 class="align--center margin-bottom">
        <h2 v-translate class="margin-bottom">
          You do not have permission to view documents. You need to request
          access from team owner.
        </h2>

        <a role="button" class="srlink--text" href="/#/teams" mt-4>
          <translate>Teams</translate>
        </a>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'CheckDocumentViewPermission',
  computed: {
    ...mapGetters('users', ['canAccessDocuments'])
  },
  methods: {
    ...mapActions('signrequest', ['navigateTo']),
    async redirectToTeams() {
      return this.navigateTo('/');
    }
  }
};
</script>
