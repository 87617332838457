<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <path
      fill-rule="evenodd"
      d="M1.20525773,3.64593925 L7.76586234,9.6702241 C7.89896821,9.79246418 8.10102846,9.79246509 8.23413538,9.67022619 L9.45597202,8.54808276 L13.7890704,12.963546 C13.7272718,12.9718845 13.6641939,12.9761905 13.6001103,12.9761905 L2.39988968,12.9761905 C2.33546154,12.9761905 2.27204996,12.971838 2.20993294,12.9634111 L6.54297202,8.54808276 L6.02297202,8.07008276 L1.51592389,12.6618506 C1.2010704,12.4051547 1,12.014209 1,11.5763008 L1,4.37608016 C1,4.10853435 1.07505476,3.85851853 1.20525773,3.64593925 Z M15.00083,5.29161532 L15,11.5763008 C15,12.0137401 14.7993599,12.4043175 14.485087,12.6610256 L9.977,8.07004321 L13.0021853,5.29293169 C13.3078399,5.42612885 13.6452932,5.5 14,5.5 C14.3558736,5.5 14.69438,5.42564206 15.00083,5.29161532 Z M11.5219839,3.3329981 C11.6053633,3.95944563 11.9207221,4.51257761 12.3788648,4.9031984 L7.9993,8.92404762 L1.9121,3.33333333 L11.5219839,3.3329981 Z M14,1 C15.1045695,1 16,1.8954305 16,3 C16,4.1045695 15.1045695,5 14,5 C12.8954305,5 12,4.1045695 12,3 C12,1.8954305 12.8954305,1 14,1 Z"
    />
  </svg>
</template>
<script>
export default {};
</script>
