<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <path
      d="M14.5261538,0 C14.7912996,0 15,0.224470967 15,0.494208494 L15,0.494208494 L15,1.05019305 C15,1.31993058 14.7912996,1.54440154 14.5261538,1.54440154 L14.5261538,1.54440154 L12.9314462,1.54378378 L12.9314462,14.4553514 L14.5261538,14.4555985 C14.764785,14.4555985 14.9576955,14.6374199 14.9938912,14.8704229 L14.9938912,14.8704229 L15,14.9498069 L15,15.5057915 C15,15.775529 14.7912996,16 14.5261538,16 L14.5261538,16 L9.87384615,16 C9.60870044,16 9.4,15.775529 9.4,15.5057915 L9.4,15.5057915 L9.4,14.9498069 C9.4,14.6800694 9.60870044,14.4555985 9.87384615,14.4555985 L9.87384615,14.4555985 L11.4676923,14.4553514 L11.4676923,1.54378378 L9.87384615,1.54440154 C9.63521501,1.54440154 9.44230454,1.36258006 9.40610876,1.12957711 L9.40610876,1.12957711 L9.4,1.05019305 L9.4,0.494208494 C9.4,0.224470967 9.60870044,0 9.87384615,0 L9.87384615,0 Z M8.61564715,2.81081081 C9.21843003,2.81081081 9.61538462,3.14445225 9.61538462,3.69815506 C9.61538462,4.25185788 9.21107902,4.58549932 8.61564715,4.58549932 L8.61564715,4.58549932 L6.41769493,4.58549932 L6.41769493,12.0746848 C6.41769493,12.7774615 6.00603833,13.1891892 5.30769231,13.1891892 C4.60934629,13.1891892 4.19768968,12.7774615 4.19768968,12.0746848 L4.19768968,12.0746848 L4.19768968,4.58549932 L2.00708847,4.58549932 C1.40430559,4.58549932 1,4.25185788 1,3.69815506 C1,3.14445225 1.39695458,2.81081081 2.00708847,2.81081081 L2.00708847,2.81081081 Z"
    />
  </svg>
</template>
<script>
export default {};
</script>
