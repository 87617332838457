import angular from 'angular';
/**
 * @author Slawomir Zytko <slawek@amsterdam-standard.pl>
 * @copyright Amsterdam Standard Sp. Z o.o.
 */
(function() {
  'use strict';

  angular.module('frontendApp').factory('feedback', Feedback);

  Feedback.$inject = ['$resource'];

  function Feedback($resource) {
    return $resource(
      '/mails/send_feedback_email',
      {},
      {
        send: {
          method: 'POST',
          isArray: false
        }
      }
    );
  }
})();
