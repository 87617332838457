<template>
  <sr-field :label="label" :label-for="uid" is-empty :horizontal="horizontal">
    <div class="sr-color--wrapper">
      <input ref="input" :name="name" @input="handleRawInput" />
    </div>
    <template v-slot:append-outer>
      <sr-button sr-style="link" @click="handleReset">
        <reset-icon />
        <translate>Reset to Signrequest color</translate>
      </sr-button>
    </template>
  </sr-field>
</template>
<script>
/* jquery, really? */
import jquery from 'jquery';
import 'spectrum-colorpicker';
import SrField from './SrField';
import SrButton from './SrButton';
import ResetIcon from '@/components/icons/ResetIcon';
import uniqueId from '@/mixins/uniqueId';
import config from '@/config';

export default {
  components: { SrField, SrButton, ResetIcon },
  mixins: [uniqueId],
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    label: { type: String, default: '' },
    value: { type: String, default: null, required: false },
    name: { type: String, required: true },
    horizontal: { type: Boolean, default: false }
  },
  computed: {
    $input() {
      return jquery(this.$refs.input);
    }
  },
  watch: {
    value(newValue) {
      this.$input.spectrum('set', newValue);
    }
  },
  mounted() {
    this.$input.spectrum({
      showInitial: true,
      showInput: true,
      showButtons: true,
      preferredFormat: 'hex',
      color: this.$props.value || config.globalSettings.DEFAULT_PRIMARY_COLOR,
      change: this.handleChange,
      move: this.handleChange,
      hide: this.handleChange,
      show: () => this.$input.spectrum('reflow'),
      cancelText: this.$gettext('Cancel'),
      chooseText: this.$gettext('Choose')
    });
  },
  beforeDestroy() {
    this.$input.spectrum('destroy');
  },
  methods: {
    handleRawInput(event) {
      this.$emit('input', event.target.value);
    },
    handleReset() {
      this.$emit('input', DEFAULT_PRIMARY_COLOR);
    },
    handleClick() {
      this.$input.spectrum('toggle');
    },
    handleChange(color) {
      this.$emit('input', color.toString());
    }
  }
};
</script>
<style scoped>
.sr-color--wrapper {
  display: flex;
  justify-content: space-between;
  padding-top: 0.3rem;
}
.sr-field > .sr-button {
  margin: 0;
  padding: 0;
  font-weight: normal;
}
.ltr .sr-field > .sr-button {
  margin-left: auto;
}
.rtl .sr-field > .sr-button {
  margin-right: auto;
}
.mobile .sr-field > .sr-button {
  margin: 0;
}
svg {
  width: 1em;
  min-width: 1em;
  margin: 0;
}
.ltr svg {
  margin-right: 0.5ch;
}
.rtl svg {
  margin-left: 0.5ch;
}
</style>
