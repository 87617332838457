<template>
  <div class="expired-container" :class="{ mobile: $isMobile }">
    <div class="expired-content">
      <img
        :src="require('./images/icon-expired.svg')"
        :alt="$gettext('Expired')"
        width="160px"
        height="116px"
      />
      <h1 v-if="linkSent" v-translate>We have sent you a new link</h1>
      <sr-button
        v-else
        class="x-large"
        sr-style="fancy"
        round
        :loading="inProgress"
        @click="sendRequest()"
      >
        <translate>Generate new link</translate>
      </sr-button>
      <h2 v-if="linkSent" v-translate>
        Protecting your data is our top priority. This link has expired. To keep
        your documents secure, we have emailed you a new link.
      </h2>
      <h2 v-else>
        Protecting your data is our top priority. This link has expired. To keep
        your documents secure, click on the button above and we will email you a
        new link.
      </h2>

      <h3 v-if="linkSent">
        <translate>Didn’t receive any email?</translate>
        <a
          role="button"
          class="srlink--text"
          :disabled="linkResent"
          @click="resendRequest"
        >
          <translate>Click here</translate>
        </a>
      </h3>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import Screen from '@/mixins/Screen';

export default {
  mixins: [Screen],
  data() {
    return {
      linkSent: false,
      linkResent: false,
      inProgress: false
    };
  },
  computed: {
    uuid() {
      return this.$route.query.uuid || null;
    },
    signer() {
      return this.$route.query.signer || null;
    }
  },
  methods: {
    ...mapActions('signrequest', ['resendDocumentEmail']),
    async sendRequest() {
      await this.handleResend();
      this.linkSent = true;
    },
    async resendRequest() {
      if (this.linkResent) {
        return;
      }
      await this.handleResend();
      this.linkResent = true;
    },
    async handleResend() {
      this.inProgress = true;
      await this.resendDocumentEmail({
        uuid: this.uuid,
        signer: this.signer
      });
      this.inProgress = false;
    }
  }
};
</script>
<style scoped>
.expired-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.mobile.expired-container {
  margin-top: 0;
  min-height: unset;
  height: 100%;
  align-items: stretch;
}
.expired-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 52em;
  width: 100%;
}

h1 {
  margin-top: 0;
  font-size: 34px;
  font-weight: 900;
  letter-spacing: 0.57px;
}
h2 {
  margin-top: 31px;
  margin-bottom: 31px;
  font-size: 16px;
  font-weight: 300;
  opacity: 0.6;
  letter-spacing: 0.27px;
  max-width: 472px;
  text-align: center;
}
h3 {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.23px;
  margin-bottom: 64px;
}
h3 a:hover {
  text-decoration: none;
}
.expired-content > img {
  margin: 46px 0;
}
.mobile .expired-content > img {
  margin: 26px 0;
}
</style>
<style>
body.ExpiredCtrl {
  background-color: #f7f9fe;
}
</style>
