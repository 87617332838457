<template>
  <div
    :class="{
      'app-header--prepare': !hasSigningMode,
      'app-header--sign': hasSigningMode
    }"
  >
    <h1
      v-if="
        hasSigningMode &&
          canSign &&
          !isReadyToFinalize &&
          !hasActionablePlaceholders
      "
      key="s"
      v-translate
      class="srtext--text"
    >
      Click anywhere to sign
    </h1>
    <h1
      v-else-if="hasSigningMode && hasDeclined"
      key="d"
      v-translate
      class="srtext--text"
    >
      The document has already been declined.
    </h1>
    <h1
      v-else-if="hasSigningMode && hasForwarded"
      key="f"
      v-translate
      class="srtext--text"
    >
      The document has already been forwarded.
    </h1>
    <h1
      v-else-if="hasSigningMode && hasSigned"
      key="s"
      v-translate
      class="srtext--text"
    >
      The document has already been signed.
    </h1>
    <h1
      v-else-if="!hasSigningMode && currentFileLoading"
      key="c"
      v-translate
      class="primary--text"
    >
      We are uploading your file…
    </h1>
    <h1
      v-else-if="!hasSigningMode && somebodyNeedsToSign"
      key="n"
      v-translate
      class="primary--text"
    >
      Click anywhere to start preparing
    </h1>
    <h1 v-else-if="!hasSigningMode" key="n2" v-translate class="primary--text">
      Start by adding the signer information
    </h1>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('signrequest', [
      'currentFileLoading',
      'hasSigningMode',
      'somebodyNeedsToSign',
      'hasActionablePlaceholders',
      'canSign',
      'hasDeclined',
      'hasForwarded',
      'hasSigned',
      'isReadyToFinalize',
      'showDownloadAll'
    ])
  }
};
</script>
<style scoped>
div {
  text-align: center;
  flex-grow: 1;
}
h1 {
  margin-top: 0;
  white-space: nowrap;
}
.ltr .app-header--prepare {
  margin-left: 10rem;
}
.rtl .app-header--prepare {
  margin-right: 10rem;
}

.mobile .app-header--prepare {
  display: none;
}
.mobile h1 {
  display: none;
}
.app-header--sign h1 {
  font-size: 16px;
}
</style>
