<template>
  <div
    class="add-file--wrap"
    :class="{
      'add-file--wrap-space': !hasFile,
      'add-file--readonly': readonly
    }"
  >
    <sr-menu v-if="!readonly" ref="menu" container-class="sr-menu--files-menu">
      <template v-slot:activator="{ on }">
        <div
          :class="{
            'add-file--more': hasFile,
            'add-file--first': !hasFile,
            'add-file--multi': allFiles.length > 1,
            'add-file--too-much': allFiles.length > 4
          }"
        >
          <document-tabs
            key="with-file"
            :items="allFiles"
            @select="selectForm"
          />
          <sr-button
            v-show="!srDragging"
            class="add-file--button"
            :sr-style="hasFile ? 'link' : 'fancy'"
            data-test-id="add-file"
            :round="!hasFile"
            :aria-label="$gettext('Add file to sign')"
            @click="handleAdd"
          >
            <span v-if="hasFile" key="more">
              <plus class="add-file--plus" aria-hidden="true" />
              <span class="add-file--text">{{ addFile }}</span>
            </span>
            <span v-if="!hasFile" key="first">
              <translate>Add file to sign</translate>
            </span>
          </sr-button>
          <sr-button
            v-show="srDragging"
            sr-style="fancy"
            data-test-id="add-drop"
            round
          >
            <span key="dragging" class="font-weight-bold">
              <translate>Release!</translate>
            </span>
          </sr-button>
        </div>
      </template>
      <add-file data-test-id="upload-file" multiple>
        <sr-button
          sr-style="text"
          inline
          class="add-file--option"
          role="menuitem"
          tabindex="-1"
          @click="handleFile"
        >
          <v-icon>desktop_windows</v-icon>
          &nbsp;
          <translate>Upload from computer</translate>
        </sr-button>
      </add-file>

      <add-file data-test-id="drop-file">
        <sr-button
          sr-style="text"
          inline
          class="add-file--option"
          role="menuitem"
          tabindex="-1"
          @click="handleFile"
        >
          <v-icon>archive</v-icon>
          &nbsp;
          <translate>Drag and drop a file here</translate>
        </sr-button>
      </add-file>

      <sr-button
        v-if="showDemo"
        key="1"
        sr-style="text"
        inline
        class="add-file--option"
        role="menuitem"
        tabindex="-1"
        data-test-id="demo"
        @click="demoFile"
      >
        <v-icon>attach_file</v-icon>
        &nbsp;
        <translate>Sign a demo document</translate>
      </sr-button>
      <sr-button
        v-if="hasTemplates"
        key="2"
        sr-style="text"
        inline
        class="add-file--option"
        role="menuitem"
        tabindex="-1"
        data-test-id="templates"
        @click.stop="handleTemplates"
      >
        <i class="icon icon--document-stack" aria-hidden="true" />
        &nbsp;
        <translate>Select a template</translate>
      </sr-button>

      <sr-button
        v-if="hasDropbox"
        key="3"
        sr-style="text"
        inline
        class="add-file--option"
        role="menuitem"
        tabindex="-1"
        data-test-id="dropbox"
        @click="handleDropbox"
      >
        <i class="icon icon--dropbox" aria-hidden="true" />
        &nbsp;
        <translate>Upload from Dropbox</translate>
      </sr-button>

      <sr-button
        v-if="hasSfFiles"
        key="4"
        sr-style="text"
        inline
        class="add-file--option"
        role="menuitem"
        tabindex="-1"
        data-test-id="sf"
        @click.stop="handleSalesforce"
      >
        <i class="icon icon--lock" aria-hidden="true" />
        &nbsp;
        <translate>Salesforce</translate>
      </sr-button>
    </sr-menu>

    <div v-if="hasSelectedFile" class="file-info">
      <a class="filename" :title="selectedFileInfo.fileName">
        {{ selectedFileInfo.fileName | shortFilename }}
      </a>
      <sr-button
        v-if="!readonly"
        inline
        sr-style="text-danger"
        :title="$gettext('Remove file')"
        @click="discardFile({ localId: selectedFileInfo.localId })"
      >
        <v-icon aria-hidden="true" class="icon">
          delete_forever
        </v-icon>
      </sr-button>
    </div>
  </div>
</template>
<script>
import { getAngularDeps } from '@/plugins/angular-injector';
import { mapGetters, mapActions } from 'vuex';

import AddFile from '@/components/elements/AddFile';
import SrButton from '@/components/elements/SrButton';
import SrMenu from '@/components/elements/SrMenu';
import permissions from '@/mixins/permissions';
import Plus from './Plus';
import DocumentTabs from './DocumentTabs';

import { shortFilename } from '@/utils/format';

export default {
  components: {
    AddFile,
    SrMenu,
    SrButton,
    DocumentTabs,
    Plus
  },
  filters: { shortFilename },
  mixins: [permissions],
  props: {
    showDemo: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    srDragging: { type: Boolean, default: false },
    canUploadMore: { type: Boolean, default: false }
  },
  computed: {
    ...mapGetters('signrequest', [
      'allFiles',
      'hasFile',
      'hasSelectedFile',
      'selectedFileInfo'
    ]),
    ...mapGetters('users', ['hasTemplates', 'hasDropbox']),
    ...mapGetters('signrequest/salesforce', ['hasSfFiles']),
    addFile() {
      return ['nl', 'de'].includes(this.$language.current)
        ? this.$gettext('Add')
        : this.$gettext('Add file');
    }
  },
  watch: {
    allFiles(newList, oldList) {
      if (newList.length === oldList.length && this.$refs.menu) {
        this.$refs.menu.close();
      }
    }
  },
  methods: {
    ...mapActions('signrequest', [
      'selectForm',
      'discardFile',
      'demoFile',
      'fromDropbox'
    ]),
    ...mapActions('events', ['trackDocumentRemovedEvent']),
    async handleDropbox() {
      const { DropboxService } = await getAngularDeps('DropboxService');
      if (this.canUploadMore) {
        try {
          const { name, link } = await DropboxService.chooseFile();
          this.fromDropbox({ name, link });
        } catch (e) {
          return;
        }
      } else {
        this.showLoginOrSubscribeModal({ feature: 'multi_doc' });
      }
    },
    handleAdd(event) {
      if (!this.canUploadMore) {
        this.showLoginOrSubscribeModal({ feature: 'multi_doc' });
      } else {
        this.$refs.menu.handleClick();
      }
    },
    handleTemplates() {
      if (!this.canUploadMore) {
        this.showLoginOrSubscribeModal({ feature: 'create_template' });
      } else {
        this.$emit('click:templates');
        this.$refs.menu.close();
      }
    },
    handleSalesforce() {
      this.$emit('click:sf');
      this.$refs.menu.close();
    },
    handleFile(event) {
      if (!this.canUploadMore) {
        event.stopPropagation();
        this.showLoginOrSubscribeModal({ feature: 'multi_doc' });
      } else {
        // do nothing and allow event to bubble up to add-file
        // where it would open file dialog
      }
    }
  }
};
</script>
<style scoped>
.add-file--option {
  height: 68px;
  padding: 0 30px;
  font-size: 15px;
  font-weight: 400;
  opacity: 1;
}

.icon {
  display: inline-flex;
  font-size: 1em;
  align-items: flex-start;
  line-height: inherit;
}

.add-file--wrap.add-file--readonly {
  margin-top: 32px;
}

.add-file--wrap-space {
  margin: 4rem 0;
}

.add-file--first .sr-button {
  font-size: 18px;
  width: 100%;
  min-width: 100%;
  margin: 0;
  height: 69px;
  line-height: 1.3;
}

@media only screen and (max-width: 420px) {
  .lang-nl .add-file--first .sr-button {
    font-size: clamp(15px, 4.5vw, 18px);
    margin-left: -10px;
    margin-right: -10px;
    width: calc(100% + 20px);
  }
}

.add-file--more,
.add-file--more /deep/ .sr-button {
  display: flex;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.23px;
  margin: 0;
  padding: 0;
  height: unset;
  min-width: unset;
  text-align: center;
}

.add-file--more /deep/ .sr-button {
  padding: 0.25em 1em;
  position: relative;
  border-radius: 1.5em;
  cursor: pointer;
  outline-offset: -4px;
}

.sr-button .v-icon {
  vertical-align: middle;
}
.add-file--option .icon {
  vertical-align: unset;
  width: 1em;
  font-size: 23px;
  display: inline-flex;
  justify-content: center;
  opacity: 0.8;
}
.add-file--option .v-icon {
  opacity: 0.54;
  margin-top: 3px;
}
.ltr .add-file--option .v-icon,
.ltr .add-file--option .icon {
  margin-right: 6px;
}
.rtl .add-file--option .v-icon,
.rtl .add-file--option .icon {
  margin-left: 6px;
}

.add-file--option {
  width: 100%;
  outline-offset: -10px !important;
}
.add-file--option /deep/ .sr-button--content {
  justify-content: start;
}
.sr-menu /deep/ .sr-menu--files-menu.sr-menu--content > span {
  width: 100%;
  padding: 0;
}

.sr-menu--files-menu > * {
  position: relative;
  display: block;
}

.sr-menu--files-menu > * + *::after {
  content: ' ';
  display: block;
  border-color: #d6d6d6;
  border-width: 0 0 1px 0;
  border-style: solid;
  transform: scaleY(0.5);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.add-file--plus {
  margin: 0 2px;
  position: relative;
  top: 1px;
}
.add-file--multi .add-file--text {
  display: none;
}
.file-info {
  display: flex;
  flex-direction: row;
  min-width: 92%;
  margin: 18px 0px;
  justify-content: space-between;
}
.filename {
  overflow-wrap: break-word;
  text-decoration: none;
  overflow: hidden;
}
</style>
