<template>
  <div>
    <h2 v-translate>Contacts</h2>
    <div v-if="userContacts">
      <div class="sr-layout sr-layout--right">
        <div class="sr-layout__item" style="text-align: right">
          <button
            v-translate
            class="button button--warning"
            :disabled="selectedContactsCount === 0"
            @click="confirmDeleteContacts"
          >
            Delete selected
          </button>
        </div>
        <div>
          <a class="sr-layout__item" href="" @click.prevent="toggleSelectAll">
            <span v-if="!allSelected" key="select" v-translate>select all</span>
            <span v-if="allSelected" key="deselect" v-translate>
              deselect all
            </span>
          </a>
        </div>
      </div>

      <div class="list-group">
        <div
          v-for="(contact, index) in userContacts"
          :key="index"
          class="list-group__item"
        >
          <div class="flag">
            <div class="flag__object">
              <v-gravatar
                :email="contact.value"
                :size="48"
                default-img="mm"
              ></v-gravatar>
            </div>
            <div class="flag__body">
              <div class="sr-layout">
                <div class="sr-layout__item eleven-twelfths">
                  <strong>{{ contact.text }}</strong>
                </div>
                <div class="sr-layout__item one-twelfth">
                  <div class="checkbox">
                    <label :for="`contact--delete--${index}`">
                      <input
                        :id="`contact--delete--${index}`"
                        v-model="selectedContacts"
                        :value="contact.value"
                        name="delete"
                        type="checkbox"
                        class="warning"
                      />
                      <span></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p v-if="userContacts.length === 0" v-translate>
      You don't have any contacts yet. They are automatically created when you
      send a SignRequest to someone.
    </p>
  </div>
</template>

<script>
import permissions from '@/mixins/permissions';
import { mapActions, mapGetters } from 'vuex';

export default {
  mixins: [permissions],
  data: function() {
    return {
      selectedContacts: []
    };
  },
  computed: {
    ...mapGetters('users', ['user', 'userContacts']),
    allSelected() {
      return this.selectedContactsCount === this.userContacts.length;
    },
    selectedContactsCount() {
      return this.selectedContacts.length;
    },
    contactsToBeDeletedMessage() {
      const translatedContactCount = this.$ngettext(
        '%{ count } contact will be deleted.',
        '%{ count } contacts will be deleted.',
        this.selectedContactsCount
      );
      return this.$gettextInterpolate(translatedContactCount, {
        count: this.selectedContactsCount
      });
    }
  },
  methods: {
    ...mapActions('users', ['deleteContacts']),
    ...mapActions('modals', ['openConfirmationModal']),
    toggleSelectAll() {
      if (this.allSelected) {
        this.selectedContacts = [];
      } else {
        this.selectedContacts = this.userContacts.map(x => x.value);
      }
    },
    confirmDeleteContacts: async function() {
      const confirmed = await this.openConfirmationModal({
        title: this.$ngettext(
          'Are you sure you want to delete the selected contact?',
          'Are you sure you want to delete the selected contacts?',
          this.selectedContactsCount
        ),
        body: `${this.contactsToBeDeletedMessage} ${this.$gettext(
          'This cannot be undone.'
        )}`,
        confirmationButtonText: this.$gettext('Delete'),
        confirmationButtonColor: 'error'
      });

      if (confirmed) {
        this.deleteContacts(this.selectedContacts);
      }
    }
  }
};
</script>
