import Vue from 'vue';
const $gettext = msgid => Vue.prototype.$gettext(msgid);

export default function signerLabel({ name, label, email }, index) {
  if (name) {
    return name;
  }
  if (!email && !label) {
    return index
      ? $gettext('Contact %d').replace('%d', index)
      : $gettext('Sender');
  }
  if (email && label) {
    return `${email} (${label})`;
  }

  return label || email;
}
