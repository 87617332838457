<template>
  <v-card class="modal-card signature-field" flat>
    <div class="toolbar">
      <sr-control-button
        class="toolbar-element"
        :active="smoothing"
        @click="toggleSmooth"
      >
        <span v-show="smoothing"><translate>Smoothing on</translate></span>
        <span v-show="!smoothing"><translate>Smoothing off</translate></span>
      </sr-control-button>

      <v-spacer />

      <sr-control-button
        class="toolbar-element"
        :disabled="!hasSignature"
        :title="$gettext('Undo last stroke')"
        @click="undoClear"
      >
        <v-icon aria-hidden="true">undo</v-icon>
      </sr-control-button>

      <sr-color-drop
        :value="penColor"
        :colors="colors"
        class="icons"
        name="color"
        @input="$emit('update-color', $event)"
      />
    </div>

    <div class="canvas-container">
      <div class="baseline-footer">
        <div class="sr-tip tip-draw">
          <span v-translate>Draw signature</span>
        </div>

        <div class="sr-tip tip-rotate">
          <span v-translate>Rotate your phone for easy signing</span>
        </div>
      </div>

      <canvas
        ref="canvas"
        tabindex="0"
        role="presentation"
        class="signature-canvas"
        :width="width"
        :height="height"
      ></canvas>
    </div>
  </v-card>
</template>

<script>
import SmoothSignature from '../../scripts/external/smoothSignature';
import SrColorDrop from './SrColorDrop.vue';
import SrControlButton from './SrControlButton.vue';

export default {
  components: { SrColorDrop, SrControlButton },

  model: {
    prop: 'hasSignature',
    event: 'input'
  },

  props: {
    width: { type: Number, default: 1024 },
    height: { type: Number, default: 400 },
    hasSignature: { type: Boolean, required: true },
    penColor: { type: String, required: true },
    colors: { type: Array, required: true }
  },

  data: () => {
    return {
      smoothing: true
    };
  },

  watch: {
    penColor(v) {
      if (this.signaturePad) {
        this.signaturePad.updatePenColor(v);
      }
    },
    smoothing(v) {
      if (this.signaturePad) {
        this.signaturePad.updateSmooth(v);
      }
    }
  },

  mounted() {
    const canvas = this.$refs['canvas'];
    this.signaturePad = new SmoothSignature(canvas, {
      onchange: signaturePad => {
        const hasSignature = signaturePad.hasSignature();
        this.$emit('input', hasSignature);
      }
    });
    this.signaturePad.updatePenColor(this.penColor);
    this.signaturePad.updateSmooth(this.smoothing);
  },

  beforeDestroy() {
    if (this.signaturePad) {
      this.signaturePad.destroy();
    }
  },

  methods: {
    focus() {
      this.$el.querySelector('button').focus();
    },
    undoClear(ev) {
      if (this.signaturePad) {
        this.signaturePad.undo();
      }
    },
    toggleSmooth(ev) {
      this.smoothing = !this.smoothing;
    },
    getSignature() {
      return Object.freeze({
        data_uri: this.signaturePad.toDataURL('image/png'),
        width: this.$props.width,
        height: this.$props.height
      });
    }
  }
};
</script>

<style scoped lang="scss">
.modal-card {
  max-width: 1024px;
  max-height: 512px;

  position: relative;
}

.toolbar {
  width: 100%;

  /* items have their own paddings */
  margin: 0px;

  display: flex;
  flex: 1 1 auto;
  flex-wrap: nowrap;
  min-width: 0;
}

.canvas-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.canvas-container > canvas {
  border: 1px dashed setting(colors, border-dark);

  width: 100%;
  height: 100%;
  position: relative; // create stacking order wrt footer
}

.baseline-footer {
  position: absolute;
  top: 75%;
  width: 100%;

  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: -5px;

  pointer-events: none;
}

.baseline-footer .sr-tip {
  pointer-events: none;
  font-size: 1rem;
  font-weight: 600;
  color: setting(colors, border-dark);
  width: 100%;
  text-align: center;
}

.baseline-footer .tip-rotate {
  display: none;
}

@media screen and (orientation: portrait) {
  .baseline-footer .tip-draw {
    display: none;
  }

  .baseline-footer .tip-rotate {
    display: unset;
  }
}
</style>
