import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{ref:"modal",attrs:{"lazy":"","max-width":"500","content-class":_vm.contentClass},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c(VCard,{class:{
      mobile: _vm.$isMobile
    },attrs:{"role":"dialog","aria-hidden":!_vm.showModal,"aria-modal":_vm.showModal,"aria-labelledby":"many-documents-gmodal-title"}},[_c('sr-button',{staticClass:"close",attrs:{"sr-style":"text","data-test-id":"close-many-doc-btn"},on:{"click":_vm.closeModal}},[_c(VIcon,{staticClass:"round",attrs:{"aria-label":"$gettext('Close upgrade dialog')"}},[_vm._v("\n        close\n      ")])],1),(!_vm.hasTrialEnded)?[_c(VCardTitle,{staticClass:"headline",attrs:{"id":"many-documents-gmodal-title"}},[(_vm.isSubscribeOnly)?_c('span',{key:"start-trial"},[_c('strong',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("Start TRIAL")])]):_c('span',{key:"limited"},[_c('strong',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("\n            Your current FREE plan only supports 10 documents per month.\n          ")])])]),_c(VCardText,[_c('p',{directives:[{name:"translate",rawName:"v-translate"}],key:"trial-ended-text"},[_vm._v("\n          Start a 14-day trial to send unlimited documents and get access to\n          all "+_vm._s(_vm.planDisplayName)+" features.\n        ")]),_c('div',{staticClass:"sr-layout margin-bottom align--center"},[_c('div',{staticClass:"sr-layout__item lap-one-whole margin-top"},[(
                _vm.subscriptionDetails &&
                  _vm.subscriptionDetails.details &&
                  _vm.subscriptionDetails.details.plan_type
              )?_c('article',{staticClass:"subscription block selected fadeIn"},[_c('div',{staticClass:"block__header"},[_c('h2',[_vm._v(_vm._s(_vm.planDisplayName)+" PLAN")])]),_c('div',{staticClass:"block__devider"}),_c('div',{staticClass:"block__item"},_vm._l((_vm.planFeatures),function(feature,index){return _c('ul',{key:index,staticClass:"list list--included"},[_c('li',[_vm._v(_vm._s(feature))])])}),0)]):_vm._e()])])])]:[_c(VCardTitle,{staticClass:"headline",attrs:{"id":"many-documents-gmodal-title"}},[_c('span',[_c('strong',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("\n            Your Trial has ended!\n          ")])])]),_c(VCardText,[_c('p',{directives:[{name:"translate",rawName:"v-translate"}],key:"trial-text"},[_vm._v("\n          Please subscribe to the SignRequest PRO plan to continue using PRO\n          features\n        ")])])],_c(VCardActions,[(_vm.hasTrialEnded)?_c('sr-button',{key:"trial-ended-btn",staticClass:"subscribe-btn",attrs:{"sr-style":"fancy","round":"","loading":_vm.isLoading},on:{"click":_vm.goToBilling}},[_c('translate',[_vm._v("Subscribe")])],1):_c('sr-button',{key:"trial-start",staticClass:"subscribe-btn",attrs:{"sr-style":"fancy","round":"","loading":_vm.isLoading},on:{"click":_vm.goToBilling}},[_c('translate',[_vm._v("Start free trial")])],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }