import { translate } from 'vue-gettext';
const $gettext = msgid => translate.gettext(msgid);

export const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// partially taken from: http://stackoverflow.com/questions/19605150/regex-for-password-must-be-contain-at-least-8-characters-least-1-number-and-bot
export const PASSWORD_STRENGTH_REGEX = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;

export function validateEmail(value) {
  return EMAIL_REGEXP.test(value) ? true : $gettext('Enter a valid email.');
}

export function validatePassword(value) {
  return PASSWORD_STRENGTH_REGEX.test(value)
    ? true
    : this.$gettext(
        'At least 8 characters (including 1 uppercase letter and 1 number).'
      );
}

export function validateUrl(value) {
  let urlValue;
  try {
    urlValue = new URL(value);
  } catch (e) {
    return this.$gettext('Invalid URL');
  }
  if (['https:', 'http:'].includes(urlValue.protocol)) {
    return true;
  }
  return this.$gettext('Invalid URL');
}

export function shortFilename(fn) {
  const max = 24;
  const prefix = 5;

  if (!fn || !fn.length || fn.length <= max) {
    return fn;
  }

  const noext = fn.replace(/\.[a-zA-Z]{2,}$/, '');
  if (noext.length <= max) {
    return noext;
  }

  const a = noext.slice(0, prefix);
  const suffix = max - prefix - 3;
  const b = noext.slice(noext.length - suffix);
  return a + '\u2026' + b;
}

export function formatSize(size) {
  const sizeK = size / 1024;
  const sizeM = sizeK / 1024;
  if (sizeM >= 1) {
    return `${Math.round(sizeM)} MB`;
  }
  if (sizeK >= 1) {
    return `${Math.round(sizeK)} KB`;
  }
  return size;
}

export function formatType(type) {
  return (
    {
      'application/pdf': 'pdf',
      'image/png': 'png'
    }[type.toLowerCase()] || type
  );
}
