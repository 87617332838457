<template>
  <div>
    <div class="sr-layout">
      <div class="sr-layout__item one-half">
        <h4 id="slack">Slack</h4>
      </div>
      <div class="sr-layout__item one-half">
        <img
          class="pull-right margin-top"
          alt="Slack"
          width="200"
          src="../images/slack/slack_rgb.png"
        />
      </div>
    </div>

    <div v-if="user.integrations.slack_webhook">
      <h5 v-translate>Slack notifications</h5>
      <NotificationSettings
        notification-type="slack"
        :user-notification-settings="user.slack_settings"
      ></NotificationSettings>
      <div v-if="user.integrations.slack_webhook" class="sr-layout">
        <div class="sr-layout__item three-quarters">
          <p>
            <span>Your Slack notifications go to channel</span>
            <strong>{{ user.integrations.slack_webhook.channel_name }}</strong>
            in team
            <strong>
              {{ user.integrations.slack_webhook.slack_team_name }}
            </strong>
            <br />
            <a
              target="_blank"
              rel="noopener noreferrer"
              :href="user.integrations.slack_webhook.configuration_url"
            >
              Manage in Slack
            </a>
          </p>
        </div>
        <div class="sr-layout__item one-quarter">
          <p>
            <button
              v-translate
              class="pull-right button button--small button--error"
              @click="deleteIntegration('slack')"
            >
              Remove integration
            </button>
          </p>
        </div>
      </div>
    </div>

    <div v-if="!user.integrations.slack_webhook" class="sr-layout">
      <div class="sr-layout__item three-quarters palm-one-whole">
        <p>
          <span v-translate>
            Enable the Slack integration to receive notifications in a Slack
            channel.
          </span>
          <br />
          <span v-translate>
            We recommend using the @SlackBot channel which is personal to you.
          </span>
        </p>
      </div>
      <div class="sr-layout__item one-quarter palm-one-whole">
        <p class="align--center">
          <a
            class="pull-right"
            href="/user/social/login/slack/?next=/%23/account%23slack"
          >
            <img
              alt="Add to Slack"
              height="40"
              width="139"
              src="../images/slack/add_to_slack.png"
              srcset="
                ../images/slack/add_to_slack.png    1x,
                ../images/slack/add_to_slack@2x.png 2x
              "
            />
          </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import permissions from '@/mixins/permissions';
import NotificationSettings from '@/components/elements/NotificationSettings';

export default {
  components: { NotificationSettings },
  mixins: [permissions],
  computed: mapGetters('users', ['user']),
  methods: {
    ...mapActions('users', ['deleteIntegration'])
  }
};
</script>
