import WebFont from 'webfontloader';

export default {
  namespaced: true,
  state: {
    loadedFonts: [],
    failedFonts: [],
    signatureFonts: [
      'Cedarville Cursive',
      'Herr Von Muellerhoff',
      'Dawning of a New Day',
      'Caveat',
      'Reenie Beanie',
      'Sacramento',
      'Waiting for the Sunrise',
      'Zeyada'
    ]
  },
  getters: {
    signatureFonts(state) {
      return state.signatureFonts;
    },
    fontLoadedFunc(state) {
      const loaded = state.loadedFonts;
      return name => loaded.includes(name);
    }
  },
  mutations: {
    setUserLoaded(state, userLoaded) {
      state.userLoaded = userLoaded;
    },
    finishLoading(state, family) {
      state.loadedFonts.push(family);
    },
    finishFailing(state, family) {
      state.failedFonts.push(family);
    }
  },
  actions: {
    async loadFamilies({ dispatch, commit }, fontList) {
      WebFont.load({
        google: {
          families: fontList
        },
        fontactive: function(font) {
          commit('finishLoading', font);
        },
        fontinactive: function(font) {
          commit('finishFailing', font);
        }
      });
    },
    async initSignatureFonts({ dispatch, state }) {
      return dispatch('loadFamilies', state.signatureFonts);
    }
  }
};
