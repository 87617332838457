import config from '@/config';

export function getCookie(name) {
  return (
    ('; ' + document.cookie)
      .split('; ' + name + '=')
      .pop()
      .split(';')
      .shift() || null
  );
}

export default function getJSONCookie(name) {
  const cookieValue = getCookie(name);
  return cookieValue && JSON.parse(decodeURIComponent(cookieValue));
}

const DEFAULT_OPTIONS = {
  path: '/',
  domain: config.cookieDomain,
  secure: true,
  SameSite: 'Strict',
  'max-age': 31536000 // 1y
};

export function setCookie(name, value, options = {}) {
  document.cookie = [
    [name, encodeURIComponent(value)],
    ...Object.entries({ ...DEFAULT_OPTIONS, ...options })
  ]
    .map(([optkey, optvalue]) => `${optkey}=${optvalue}`)
    .join(';');
}

export function getCookies() {
  const pairs = document.cookie.split(';');
  const cookies = {};
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=');
    cookies[(pair[0] + '').trim()] = unescape(pair.slice(1).join('='));
  }
  return cookies;
}
