function initialState() {
  return {
    context: {
      uid: null,
      objectId: null,
      objectType: null,
      objectIdList: null,
      objectIdListType: null,
      selectAllDocs: null,
      fileType: null
    },
    getParams: null,
    files: [] // Name, Id, lastModified
  };
}

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    reset(state) {
      Object.assign(state, initialState());
    },
    setContext(
      state,
      {
        uid,
        objectId,
        objectType,
        objectIdList,
        objectIdListType,
        selectAllDocs,
        fileType
      }
    ) {
      Object.assign(state.context, {
        uid,
        objectId,
        objectType,
        objectIdList,
        objectIdListType,
        selectAllDocs: Boolean(selectAllDocs),
        fileType
      });
    },
    saveParams(state, searchParams) {
      const params = {};
      for (let [key, value] of searchParams.entries()) {
        params[key] = value;
      }
      state.getParams = params;
    },
    setFiles(state, { files, documents, quotes, attachments }) {
      function mark(list, docutype) {
        return list.map(datum => Object.freeze({ ...datum, docutype }));
      }
      state.files = [].concat(
        mark(files || [], 'File'),
        mark(documents || [], 'Document'),
        mark(quotes || [], 'QuoteDocument'),
        mark(attachments || [], 'Attachment')
      );
    },
    setContacts(state, contacts) {
      state.contacts = contacts;
    }
  },
  getters: {
    allFiles(state) {
      return state.files.map(file => ({
        name: file.Name,
        uuid: file.Id,
        docutype: file.docutype,
        integration: 'salesforce'
      }));
    },
    hasSfFiles(state) {
      return state.files.length > 0;
    },
    getFileFilter(state) {
      const { fileType } = state.context;
      const allowedTypes = {
        Files: ['File'],
        Attachments: ['Attachment', 'QuoteDocument', 'Document']
      }[fileType];
      return function(file) {
        if (!allowedTypes) {
          return true;
        }
        return allowedTypes.includes(file.docutype);
      };
    },
    getFiles(_state, getters) {
      return getters.allFiles.filter(getters.getFileFilter);
    },
    getFile(state) {
      return function(uuid) {
        return state.files.find(iterFile => iterFile.Id == uuid);
      };
    },
    getIntegrationData(state, getters) {
      return function(uuid, docUUID) {
        const file = getters.getFile(uuid);
        if (!file) {
          return null;
        }
        return {
          name: 'salesforce',
          object_type: state.context.objectType,
          object_id: state.context.objectId,
          uid: state.context.uid,
          get_params: {
            ...state.getParams,
            doc_uuid: docUUID
          },
          sf_doc_id: uuid
        };
      };
    }
  },
  actions: {
    async fetchData({ state, dispatch, commit }) {
      const url = '/integrations/salesforce/sfget_data';
      const {
        files,
        documents,
        quotes,
        attachments,
        contacts
      } = await dispatch(
        'api/makeRequest',
        {
          method: 'GET',
          data: {
            objectType: state.context.objectType,
            objectId: state.context.objectId,
            uid: state.context.uid,
            object_id_list: state.context.objectIdList,
            object_id_list_type: state.context.objectIdListType
          },
          url
        },
        { root: true }
      );
      commit('setFiles', { files, documents, quotes, attachments });
      commit('setContacts', contacts);
    },
    async upload({ state, dispatch, getters }, { uuid }) {
      const url = '/integrations/salesforce/sfdoc_only';
      const file = getters.getFile(uuid);
      if (!file) {
        return { uuid: null };
      }
      const data = {
        doc_id: uuid,
        filename: file.Name,
        doc_type: file.docutype,
        object_id: state.context.objectId,
        object_type: state.context.objectType,
        uid: state.context.uid,
        extra_docs: state.context.selectAllDocs ? state.files : [],
        hr2day: state.context.objectIdListType === 'HR2Day'
      };
      return dispatch(
        'api/makeRequest',
        { method: 'POST', data, url },
        { root: true }
      );
    },
    async init({ dispatch, commit }, { url }) {
      const { searchParams } = url;
      const context = {
        objectId: searchParams.get('objectId'),
        objectType: searchParams.get('objectType'),
        uid: searchParams.get('userId'),
        objectIdList: searchParams.get('object_id_list'),
        objectIdListType: searchParams.get('object_id_list_type'),
        fileType: searchParams.get('fileType')
      };
      commit('setContext', context);
      commit('saveParams', searchParams);
      await dispatch('fetchData');
      return { selectFiles: true, integration: 'salesforce' };
    }
  }
};
