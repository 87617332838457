<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fill="#fff"
      d="M11.1616486,19.8635038 C10.6069478,19.681509 10.0760199,19.3293885 9.56886488,18.8071423 L0.558939357,9.26428071 C0.194421702,8.86863968 0.00820072582,8.4136525 0.00027642896,7.89931916 C-0.0076478679,7.38498583 0.154800218,6.95373711 0.487620686,6.605573 C0.867986935,6.20993197 1.323634,6.00815505 1.85456189,6.00024223 C2.38548978,5.99232941 2.83321256,6.17828069 3.19773021,6.55809608 L11.9936997,15.8873115 L20.7896692,6.55809608 C21.1541869,6.17828069 21.5979475,5.99232941 22.1209511,6.00024223 C22.6439547,6.00815505 23.0956396,6.20993197 23.4760059,6.605573 C23.8405235,6.93791147 24.0148581,7.36520378 23.9990095,7.88744993 C23.9831609,8.40969609 23.7850534,8.86863968 23.4046872,9.26428071 L14.4185346,18.8308808 C13.9113796,19.3373013 13.3804517,19.681509 12.8257509,19.8635038 C12.2710501,20.0454987 11.7163493,20.0454987 11.1616486,19.8635038 Z"
    />
  </svg>
</template>
<script>
export default {};
</script>
