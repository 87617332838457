<template>
  <div class="message" role="alert">
    <div class="m-icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="45"
        height="45"
        viewBox="0 0 45 45"
      >
        <g fill="none" fill-rule="evenodd">
          <circle
            cx="22.5"
            cy="22.5"
            r="22.5"
            :fill="circleColor"
            fill-rule="nonzero"
          />
          <g
            stroke="#FFF"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.6"
          >
            <path
              v-if="iconPath === 'alert'"
              d="M21.1 15.968l-6.93 11.164a1.531 1.531 0 0 0-.004 1.571c.288.487.823.79 1.404.797h13.86a1.647 1.647 0 0 0 1.404-.797 1.531 1.531 0 0 0-.005-1.571L23.9 15.968a1.65 1.65 0 0 0-1.399-.76 1.65 1.65 0 0 0-1.4.76zM22.5 20.026v3.158M22.5 26.342h.008"
            />
            <path v-if="iconPath === 'success'" d="M29 18l-8.938 9L16 22.91" />
          </g>
        </g>
      </svg>
    </div>

    <div class="m-content">
      <span v-if="titleText" class="m-title">{{ titleText }}</span>
      <span class="m-body">
        {{ body }}
      </span>
    </div>

    <div class="m-close">
      <a v-translate role="button" :class="colorClass" @click="$emit('click')">
        close
      </a>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  components: {},
  props: {
    type: { type: String, default: 'error' },
    title: { type: String, default: '' },
    body: { type: String, default: 'Oops, something went wrong.' }
  },
  computed: {
    titleText() {
      if (this.title) {
        return this.title;
      }

      switch (this.type) {
        case 'success':
          return this.$gettext('Success');
        case 'warning':
          return this.$gettext('Warning');
        case 'error':
          return this.$gettext('Error');
        default:
          return this.$gettext('Alert');
      }
    },
    iconPath() {
      switch (this.type) {
        case 'success':
          return 'success';
        default:
          return 'alert';
      }
    },
    circleColor() {
      switch (this.type) {
        case 'success':
          return '#42D36B';
        case 'warning':
          return '#FA7D4D';
        case 'error':
        case 'danger':
          return '#FA4D59';
        default:
          return '#FA4D59';
      }
    },
    colorClass() {
      switch (this.type) {
        case 'success':
          return 'success--text';
        case 'warning':
          return 'warning--text';
        case 'error':
        case 'danger':
          return 'error--text';
        default:
          return 'error--text';
      }
    }
  },
  methods: {}
});
</script>

<style scoped>
/* redesign */
.message {
  min-height: 5rem;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: -2px 6px 44px 10px rgba(0, 0, 0, 0.06);

  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;

  font-size: 16px;
  font-weight: normal;
}

.message .m-icon {
  height: 45px;
  width: 45px;
}

.message .m-content {
  flex-grow: 1;
  flex-shrink: 1;
  margin: 0 1rem;
}

.message .m-content > span {
  display: block;
  width: 100%;
  color: #111111;
}
.message .m-content > span.m-body {
  opacity: 0.6;
}

.message .m-close {
  display: flex;
  align-items: center;
}

.message .m-close a {
  display: block;
  font-size: 1.2em;
  cursor: pointer;
}

.message .m-close a:hover {
  text-decoration: none;
}
</style>
