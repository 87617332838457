<template>
  <div class="payment-method-form">
    <v-layout row wrap class="credit-card">
      <v-flex xs12 md6>
        <PaymentInput
          :label="$gettext('Credit card number')"
          :element="stripeNumber"
          @element-completed="stripeNumberCompleted = $event"
          @stripeInputError="stripeNumberError = $event"
          @input="elementInputEvent"
        ></PaymentInput>
      </v-flex>
      <v-flex xs6 md3>
        <PaymentInput
          :label="$gettext('Expiry date')"
          :element="stripeExpiry"
          @element-completed="stripeExpiryCompleted = $event"
          @stripeInputError="stripeExpiryError = $event"
          @input="elementInputEvent"
        ></PaymentInput>
      </v-flex>
      <v-flex xs6 md3>
        <PaymentInput
          :label="$gettext('CVC')"
          :element="stripeCVC"
          @element-completed="stripeCVCCompleted = $event"
          @stripeInputError="stripeCVCError = $event"
          @input="elementInputEvent"
        ></PaymentInput>
      </v-flex>
      <v-layout column class="subscribe-errors">
        <v-flex v-for="cardError in cardErrors" :key="cardError" xs12>
          <span class="text--error ml-1">
            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            {{ cardError }}
          </span>
        </v-flex>
        <v-flex v-if="stripeNumberError">
          <span class="text--error ml-1">
            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            {{ stripeNumberError }}
          </span>
        </v-flex>
        <v-flex v-if="stripeExpiryError">
          <span class="text--error ml-1">
            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            {{ stripeExpiryError }}
          </span>
        </v-flex>
        <v-flex v-if="stripeCVCError">
          <span class="text--error ml-1">
            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            {{ stripeCVCError }}
          </span>
        </v-flex>
      </v-layout>
    </v-layout>
  </div>
</template>

<script>
import PaymentInput from '@/components/subscribe/PaymentInput';
import { mapActions, mapState, mapGetters } from 'vuex';

export default {
  components: { PaymentInput },
  props: {
    fontSize: { type: String, default: '14px' }
  },
  data() {
    return {
      stripeNumber: null,
      stripeExpiry: null,
      stripeCVC: null,
      stripeNumberCompleted: false,
      stripeExpiryCompleted: false,
      stripeCVCCompleted: false,
      stripeNumberError: null,
      stripeExpiryError: null,
      stripeCVCError: null,
      cardErrors: []
    };
  },
  computed: {
    ...mapGetters('subscription', [
      'stripeTokenErrorMessage',
      'subscriptionErrorMessage'
    ]),
    isComplete() {
      return (
        this.stripeNumberCompleted &&
        this.stripeExpiryCompleted &&
        this.stripeCVCCompleted
      );
    }
  },
  watch: {
    isComplete(complete) {
      if (complete) {
        this.elementCompleted();
      }
    },
    stripeTokenErrorMessage(message) {
      if (message) this.cardErrors.push(this.$gettext(message));
    },
    subscriptionErrorMessage(message) {
      if (message) this.cardErrors.push(this.$gettext(message));
    }
  },
  mounted: function() {
    this.mountStripeElements();
  },
  methods: {
    ...mapActions('subscription', [
      'changeStripeElementsInputCompleted',
      'changeStripeElement',
      'getStripe'
    ]),
    mountStripeElements: async function() {
      const style = {
        base: {
          fontSize: this.fontSize,
          fontFamily: '"Lato", "Open Sans", Helvetica, Arial, sans-serif'
        }
      };

      const stripe = await this.getStripe();
      const elements = stripe.elements();

      this.stripeNumber = elements.create('cardNumber', {
        placeholder: '0000 0000 0000 0000',
        style: style
      });
      this.stripeCVC = elements.create('cardCvc', {
        placeholder: '000',
        style: style
      });
      this.stripeExpiry = elements.create('cardExpiry', {
        placeholder: 'MM/YY',
        style: style
      });
    },
    elementCompleted() {
      this.changeStripeElement({ element: Object.freeze(this.stripeNumber) });
      this.changeStripeElementsInputCompleted({ completed: true });
    },
    displayInputError(errorMessage) {
      if (!this.cardErrors.includes(errorMessage))
        this.cardErrors.push(errorMessage);
    },
    elementInputEvent() {
      this.cardErrors = [];
    }
  }
};
</script>
