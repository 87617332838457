<template>
  <v-container ref="container" fluid column data-test-id="integrations">
    <v-layout column content>
      <h1 v-translate class="srtext--text">
        Integrate with your favorite tools
      </h1>
      <p v-translate class="srtext--text">
        Seamlessly integrate SignRequest with the most popular tools like Slack,
        Zapier, Google Drive, and much more.
      </p>

      <v-layout features row>
        <img
          :src="require('@/images/integration-google-workspace.png')"
          alt="Google Workspace"
          width="256px"
          height="32px"
        />
        <img
          :src="require('@/images/integration-topdesk.png')"
          alt="Topdesk"
          width="103x"
          height="16px"
        />
        <img
          :src="require('@/images/integration-zapier.png')"
          alt="Zapier"
          width="110px"
          height="51px"
        />
        <img
          :src="require('@/images/integration-slack.png')"
          alt="Slack"
          width="110px"
          height="29px"
        />
      </v-layout>
      <sr-button sr-style="link" :href="getPageUrlForLang('integrations')">
        <translate>See all integrations</translate>
        <span class="arrow">→</span>
      </sr-button>
    </v-layout>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import SrButton from '@/components/elements/SrButton';
export default {
  components: { SrButton },
  computed: mapGetters('conf', ['getPageUrlForLang'])
};
</script>
<style scoped>
.column {
  flex-direction: column;
  align-items: center;
}
.features {
  align-items: center;
  border-radius: 25px;
  margin-top: 3rem;
  width: 100%;
  max-width: 768px;
  justify-content: space-between;
}
.mobile .features {
  flex-wrap: wrap;
  padding: 0 10px;
  margin-bottom: 4rem;
}

.features img {
  flex-grow: 0;
  flex-shrink: 0;
}
.mobile .features img {
  margin-bottom: 2rem;
  transform: scale(0.75);
  width: 33%;
}

h1 {
  opacity: 0.9;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.3;
  letter-spacing: -0.21px;
  margin-top: 54px;
}

.mobile h1 {
  font-size: 2rem;
  text-align: center;
  line-height: 1.29;
  margin-top: 1em;
}

h3 {
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.27px;
  opacity: 0.8;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
  max-width: 592px;
  text-align: center;
}

.sr-button {
  margin-top: 5rem;
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 15px;
}
.mobile .sr-button {
  margin-top: 0;
}
span.arrow {
  margin: 0 0.4ch;
}
</style>
