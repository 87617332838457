import angular from 'angular';
import _ from 'lodash';

/**
 * @ngdoc directive
 * @name frontendApp.directive:signaturePad
 * @description
 * # signaturePad
 */
angular.module('frontendApp').directive('signatureText', [
  'docService',
  '$window',
  '$timeout',
  'srSignatureSelectModal',
  'UserService',
  'fontService',
  function(
    docService,
    $window,
    $timeout,
    srSignatureSelectModal,
    UserService,
    fontService
  ) {
    return {
      template: require('../../views/directives/signature-text-create.html'),
      restrict: 'E',
      scope: {
        api: '=',
        initialSignatureText: '=',
        availableColors: '='
      },
      link: function postLink(scope) {
        scope.availableFontFamilies = fontService.availableFontFamilies;
        scope.selectedFillStyle = '#000000';
        scope.selectedFontFamily = scope.availableFontFamilies[0];

        scope.selectFontFamily = function(service) {
          scope.selectedFontFamily = service.options.fontFamily;
          scope.selectedService = service;
        };

        scope.signatureText =
          scope.signatureText || scope.initialSignatureText || '';
        UserService.waitForUser().then(function(user) {
          if (user && user.last_name && user.full_name) {
            scope.signatureText = user.full_name;
          }
        });

        scope.onColorChange = function(newColor) {
          scope.selectedFillStyle = newColor;
        };

        scope.show_text = false;

        scope.closeModal = function() {
          srSignatureSelectModal.deactivate();
        };

        scope.api = {};
        scope.finish_callback = function() {};

        scope.api.toggleShowSigText = function(callback) {
          scope.show_text = !scope.show_text;
          if (angular.isFunction(callback)) {
            scope.finish_callback = callback;
          } else {
            scope.finish_callback = function() {};
          }
        };

        scope.selectedServiceReady = function() {
          return scope.selectedService && scope.selectedService.canvasSetupDone;
        };

        scope.saveSig = function() {
          if (
            !scope.signatureText ||
            scope.signatureText.length === 0 ||
            !scope.selectedServiceReady()
          ) {
            return false;
          } else {
            var canvas = scope.selectedService.getCanvas();
            var type = 'image/png';
            var image = docService.newSigImage();
            image.src = canvas.toDataURL(type);
            image.class = 'signature-image';
            image.width = canvas.width;
            image.height = canvas.height;
            image.from_text = true;
            scope.show_text = false;
            scope.finish_callback(image);
            scope.selectedService.clearCanvas();
            scope.signatureText = '';
          }
        };
      }
    };
  }
]);
