import angular from 'angular';
import $ from 'jquery';

/**
 * @ngdoc function
 * @name frontendApp.controller:SigCtrl
 * @description
 * # SigCtrl
 * Controller of the frontendApp
 */
angular
  .module('frontendApp')
  .controller('SigCtrl', [
    '$scope',
    '$resource',
    'UserService',
    'srSignatureSelectModal',
    'gettextCatalog',
    '$timeout',
    'confService',
    'docService',
    '$q',
    '$vuex',
    'ngAsyncWrapper',
    function(
      $scope,
      $resource,
      UserService,
      srSignatureSelectModal,
      gettextCatalog,
      $timeout,
      confService,
      docService,
      $q,
      $vuex,
      ngAsyncWrapper
    ) {
      //$scope.editing = (angular.isDefined($scope.editing)) ? $scope.editing : true;
      $scope.editing = true;

      $scope.confService = confService;

      $scope.tabs = { active_tab: 'saved' };

      // settings in the API may disable certain types of signatures
      $scope.textSigAllowed = function() {
        if ($scope.doc && $scope.doc.signrequest) {
          if ($scope.doc.signrequest.disable_text_signatures) {
            return false;
          }
        }
        return true;
      };

      $scope.uploadSigAllowed = function() {
        if ($scope.doc && $scope.doc.signrequest) {
          if ($scope.doc.signrequest.disable_upload_signatures) {
            return false;
          }
        }
        return true;
      };

      $scope.drawSigAllowed = function() {
        if ($scope.doc && $scope.doc.signrequest) {
          if ($scope.doc.signrequest.disable_draw_signatures) {
            return false;
          }
        }
        return true;
      };

      $scope.sigsRestricted = function() {
        return (
          !$scope.textSigAllowed() ||
          !$scope.uploadSigAllowed() ||
          !$scope.drawSigAllowed()
        );
      };

      $scope.forcedColor = function() {
        if (
          $scope.doc &&
          $scope.doc.signrequest &&
          $scope.doc.signrequest.force_signature_color
        ) {
          // force colors is set on signrequest
          return $scope.doc.signrequest.force_signature_color;
        } else if (
          $scope.user &&
          $scope.user.team_user &&
          $scope.user.team_user.team &&
          $scope.user.team_user.team.default_force_signature_color
        ) {
          // there is no doc, but the user is on the team with forced colors
          return $scope.user.team_user.team.default_force_signature_color;
        }
        return null;
      };

      // technically it should return a list
      // but then angular digests fight you
      // which you can solve with some array copy hacks
      // or by using strings...
      $scope.availableColors = function() {
        return $scope.forcedColor() || '#5b5baf,#d8181f,#000000';
      };

      $scope.signatureAllowed = function(sig) {
        return !(
          (sig.from_upload && !$scope.uploadSigAllowed()) ||
          (sig.from_text && !$scope.textSigAllowed())
        );
      };

      $scope.tabs.activateSavedTab = function() {
        $scope.tabs.active_tab = 'saved';
      };

      $scope.tabs.activateDrawTab = function() {
        if ($scope.drawSigAllowed()) {
          $scope.tabs.active_tab = 'draw';
          $scope.showSigPad();
        }
      };

      $scope.tabs.activateTextTab = function() {
        if ($scope.textSigAllowed()) {
          $scope.tabs.active_tab = 'text';
          $scope.showSigText();
        } else {
          $scope.tabs.activateDrawTab();
        }
      };

      $scope.tabs.activateUploadTab = function() {
        if ($scope.uploadSigAllowed()) {
          $scope.tabs.active_tab = 'upload';
          $scope.showSigUpload();
        }
      };

      $scope.closeModal = function() {
        srSignatureSelectModal.deactivate();
      };

      $scope.callback = function() {};
      $scope.openSigsSelectModal = function(callback) {
        $scope.editing = false;
        $scope.openSigsModal();
        if (angular.isDefined(callback)) {
          $scope.callback = callback;
        }
      };

      $scope.sigPad = { openSigsSelectModal: $scope.openSigsSelectModal };
      $scope.sigUpload = { openSigsSelectModal: $scope.openSigsSelectModal };
      $scope.sigText = { openSigsSelectModal: $scope.openSigsSelectModal };

      $scope.deleteSig = function(sig) {
        $scope.loading = true;

        $scope.user.sigs.splice($scope.user.sigs.indexOf(sig), 1);
        if ($scope.user.logged_in || $scope.signer_uuid) {
          if (!$scope.user.logged_in && $scope.signer_uuid) {
            // store sig as inactive signature for later activation
            sig.signer_uuid = $scope.signer_uuid;
          }
          UserService.delSig(sig).then(function(response) {
            $scope.loading = false;
          });
        } else {
          $scope.loading = false;
        }
      };

      $scope.getSaveAndSendTrans = function() {
        // '&' somehow doesn't work in the templates
        return gettextCatalog.getString('Save & insert');
      };

      $scope.openSigsModal = ngAsyncWrapper(function() {
        // basically passed to SigSelectCtrl
        if ($vuex.getters['conf/isVueSignbox']) {
          $scope.openNewSigsModal();
        } else {
          $scope.openOldSigsModal();
        }
      });

      $scope.openOldSigsModal = ngAsyncWrapper(function() {
        srSignatureSelectModal.activate(
          {},
          {
            tabs: $scope.tabs,
            deleteSig: $scope.deleteSig,
            sigPad: $scope.sigPad,
            sigText: $scope.sigText,
            sigUpload: $scope.sigUpload,
            closeModal: $scope.closeModal,
            selectOrSave: $scope.selectOrSave,
            selectSig: $scope.selectSig,
            editing: $scope.editing,
            signatureAllowed: $scope.signatureAllowed,
            uploadSigAllowed: $scope.uploadSigAllowed,
            drawSigAllowed: $scope.drawSigAllowed,
            textSigAllowed: $scope.textSigAllowed,
            sigsRestricted: $scope.sigsRestricted,
            availableColors: $scope.availableColors,
            getSaveAndSendTrans: $scope.getSaveAndSendTrans,
            initialSignatureText: $scope.doc ? $scope.doc.signer.full_name : ''
          }
        );
      });

      $scope.openNewSigsModal = ngAsyncWrapper(function() {
        const options = {
          text: $scope.textSigAllowed,
          draw: $scope.drawSigAllowed,
          upload: $scope.uploadSigAllowed,
          forceSignatureColor: $scope.forcedColor()
        };
        $vuex.dispatch('modals/openSignatureModal', options);
      });

      $scope.stampSignatureImage = function(sig) {
        var deferred = $q.defer();
        if ($scope.doc && $scope.doc.signrequest.use_stamped_signatures) {
          docService
            .getStampedSignature(
              sig.data_uri,
              $scope.doc.uuid,
              $scope.doc.signer.uuid
            )
            .then(
              function(resp) {
                if (resp.data.data_uri) {
                  sig.data_uri = resp.data.data_uri;
                  sig.width = resp.data.width;
                  sig.height = resp.data.height;
                }
                deferred.resolve(sig);
              },
              function() {
                deferred.resolve(sig);
              }
            );
        } else {
          deferred.resolve(sig);
        }
        return deferred.promise;
      };

      $scope.selectSig = function(sig) {
        $scope.user.selected_sig = sig;
        if ($scope.editing) {
          $scope.loading = false;
          return $scope.tabs.activateSavedTab();
        } else {
          $scope.closeModal();
          $scope.loading = true;
          $scope.stampSignatureImage(angular.copy(sig)).then(function(sig) {
            $scope.loading = false;
            $scope.callback(sig);
          });
        }
      };

      $scope.processImage = function(image) {
        $scope.loading = true;

        var sig = UserService.newSig();
        sig.from_text = image.from_text || false;
        sig.data_uri = image.src;
        sig.width = image.width;
        sig.height = image.height;

        if ($scope.user.logged_in || $scope.signer_uuid) {
          if (!$scope.user.logged_in && $scope.signer_uuid) {
            // store sig as inactive signature for later activation
            sig.signer_uuid = $scope.signer_uuid;
          }
          UserService.saveSig(sig).then(function(response) {
            $scope.loading = false;
            if (response.data) {
              sig = response.data.signature;
              if (sig.is_new) {
                $scope.user.sigs.unshift(sig);
              }
            } else {
              $scope.user.sigs.unshift(sig);
            }
          });
        } else {
          $scope.user.sigs.unshift(sig);
          $scope.loading = false;
        }
        // select the signature right away and insert into the document
        $scope.selectSig(sig);
      };

      $scope.showSigPad = function() {
        if (angular.isUndefined($scope.sigPad.api)) {
          // we need to wait for the modal to open
          $timeout($scope.showSigPad, 100);
        } else {
          $timeout(function() {
            $scope.sigPad.api.toggleShowSigPad($scope.processImage);
          }, 100);
        }
      };

      $scope.showSigUpload = function() {
        if (angular.isUndefined($scope.sigUpload.api)) {
          $timeout($scope.showSigUpload, 100);
        } else {
          $timeout(function() {
            if ($scope.signer_uuid) {
              // if we don't have a logged in user we store the signature using the signer uuid
              $scope.sigUpload.api.signer_uuid = $scope.signer_uuid;
            }
            $scope.sigUpload.api.toggleShowSigUpload(function(image) {
              if (image.is_new && $scope.user && $scope.user.sigs) {
                $scope.user.sigs.unshift(image);
              }
              $scope.selectSig(image);
            });
          }, 100);
        }
      };

      $scope.showSigText = function() {
        if (angular.isUndefined($scope.sigText.api)) {
          $timeout($scope.showSigText, 100);
        } else {
          $timeout(function() {
            $scope.sigText.api.toggleShowSigText(function(image) {
              $scope.processImage(image);
            });

            $('#input--name').focus();
          }, 100);
        }
      };
    }
  ])
  .controller('SigSelectCtrl', [
    '$scope',
    'messageService',
    'gettextCatalog',
    'UserService',
    function($scope, messageService, gettextCatalog, UserService) {
      $scope.tabs.activateSavedTab();

      UserService.waitForUser().then(function() {
        $scope.user.selected_sig = $scope.user.selected_sig || null;

        if ($scope.user && $scope.user.sigs && $scope.user.sigs.length) {
          if (!$scope.user.selected_sig) {
            angular.forEach($scope.user.sigs, function(sig, index) {
              if (!$scope.user.selected_sig && $scope.signatureAllowed(sig)) {
                $scope.user.selected_sig = $scope.user.sigs[index];
              }
            });
          }
        }

        if (!$scope.user.selected_sig) {
          // anomymous user or user without sigs (that can be used)
          $scope.tabs.activateTextTab();
        }
      });

      $scope.selectOrSave = function() {
        if ($scope.editing) {
          $scope.closeModal();
        } else {
          if ($scope.user && $scope.user.selected_sig) {
            $scope.selectSig($scope.user.selected_sig);
          }
        }
      };

      $scope.checkOrSelect = function(sig) {
        if (!$scope.editing) {
          if ($scope.signatureAllowed(sig)) {
            $scope.selectSig(sig);
          } else {
            messageService.add(
              'error',
              gettextCatalog.getString(
                'This signature type is not allowed for this document. Please create a new one.'
              ),
              5000
            );
          }
        }
      };

      //$scope.$watch('user.selected_sig', function (n, o) {
      //  // select the newly created sig
      //  if (o !== n && !$scope.editing){
      //    $scope.selectOrSave();
      //  }
      //});
    }
  ]);
