<template>
  <div :dir="dir">
    <span :lang="code">{{ name }}</span>
    &nbsp;
    <span dir="ltr" lang="en">({{ shortName }})</span>
  </div>
</template>
<script>
export default {
  props: {
    dir: { type: String, required: true },
    code: { type: String, required: true },
    name: { type: String, required: true },
    shortName: { type: String, required: true }
  }
};
</script>
