<template>
  <v-btn
    block
    flat
    :large="large"
    :outline="selected"
    :href="href"
    color="primary"
    :class="{ inline, selected }"
    @click.prevent="onClick"
  >
    <v-layout :class="{ 'srdefault--text': !selected }">
      <v-flex grow class="text-xs-start">
        <slot />
      </v-flex>

      <v-flex shrink>
        <v-fade-transition>
          <span v-if="selected">
            <translate>selected</translate>
          </span>
        </v-fade-transition>
      </v-flex>
    </v-layout>
  </v-btn>
</template>

<script>
export default {
  model: {
    prop: 'selected',
    event: 'changed'
  },
  props: {
    href: { type: String, required: false, default: null },
    selected: { type: Boolean, default: false },
    inline: { type: Boolean, default: false },
    large: { type: Boolean, default: false }
  },
  computed: {
    cssBorderColor() {
      return { 'border-color': this.$vuetify.theme.primary + ' !important' };
    }
  },
  methods: {
    focus() {
      this.$el.focus();
    },
    onClick(v) {
      if (v) {
        this.$emit('changed', v);
      }
    }
  }
};
</script>

<style scoped>
.v-btn {
  border-radius: 5px;
}

.v-btn.inline {
  margin-left: -16px;
  margin-right: -16px;
  padding-left: 32px;
  padding-right: 32px;
  width: calc(100% + 32px);
}

.v-btn.inline.large {
  margin-left: -32px;
  margin-right: -32px;
  padding-left: 64px;
  padding-right: 64px;
  width: calc(100% + 64px);
}
.text-xs-start {
  text-align: start;
}
</style>
