<template>
  <ul
    v-if="items.length"
    class="file-list"
    role="menu"
    @keydown="keyMenuKeyboard"
  >
    <li
      v-for="({ isSelected, localId, fileName, fileType, fileSize, fileReady },
      idx) in items"
      :key="idx"
      :title="fileName"
      :class="{ 'file-list--active': isSelected }"
      class="file-list--item primary--text"
      role="none"
    >
      <div v-if="!isSelected" class="file-list--bg"></div>
      <sr-button
        role="menuitem"
        :sr-style="isSelected ? 'primary' : 'subtle'"
        class="filename"
        @click="$emit('select', { localId })"
      >
        <span>{{ fileName }}</span>
      </sr-button>
    </li>
  </ul>
</template>
<script>
import SrButton from '@/components/elements/SrButton';
import KeyMenu from '@/mixins/KeyMenu';

export default {
  components: { SrButton },
  mixins: [KeyMenu('horizontal')],
  props: {
    items: { type: Array, required: true }
  }
};
</script>
<style scoped>
.file-list {
  list-style: none;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.2px;
  margin: 0;
  max-width: calc(100% - 10ch);
}
.add-file--multi .file-list {
  max-width: calc(100% - 4ch);
}

.file-list--item {
  margin: 0 2.5px;
  overflow: hidden;
}

.file-list--item .filename {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  display: block;
}
.add-file--too-much .file-list--item .filename {
  text-overflow: '';
}

.ltr .file-list--item:first-child {
  margin-left: 0;
}
.ltr .file-list--item:last-child {
  margin-right: 0;
}
.rtl .file-list--item:first-child {
  margin-right: 0;
}
.rtl .file-list--item:last-child {
  margin-left: 0;
}
span {
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
