<template>
  <toggle
    class="box-checkbox"
    :class="{ 'box-checkbox--right': help }"
    v-bind="props"
  />
</template>
<script>
export default {
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: { type: Boolean, required: false, default: false },
    help: { type: String, required: false, default: '' },
    label: { type: String, required: true },
    name: { type: String, required: false, default: '' }
  },
  computed: {
    props() {
      return Object.freeze(
        this.help
          ? {
              name: this.$props.name,
              label: this.$props.label,
              description: this.$props.help,
              isToggleRightAligned: true,
              isOn: this.$props.value,
              onChange: this.handleChange
            }
          : {
              name: this.$props.name,
              label: null,
              description: this.$props.label,
              isToggleRightAligned: false,
              isOn: this.$props.value,
              onChange: this.handleChange
            }
      );
    }
  },
  methods: {
    handleChange(event) {
      this.$emit('input', !this.$props.value);
    }
  }
};
</script>
<style scoped>
.box-checkbox:focus-within {
  box-shadow: 0 0 1px 3px #6ca1f9;
}
.box-checkbox /deep/ .toggle-container {
  display: flex;
}
.box-checkbox.box-checkbox--right /deep/ .toggle-container {
  flex-direction: column;
}

.box-checkbox /deep/ .toggle-simple-description {
  margin-top: 0;
  margin-left: 12px;
}
.box-checkbox:not(.box-checkbox--right) /deep/ .toggle-simple-description {
  color: inherit;
}
.box-checkbox.box-checkbox--right /deep/ .toggle-simple-description {
  margin-left: 0;
}
</style>
