<template>
  <div
    class="sr-field"
    :class="{
      'sr-field--empty': isEmpty,
      'sr-field--has-placeholder': hasPlaceholder,
      'sr-field--has-error': validations,
      'sr-field--horizontal': horizontal,
      'sr-field--vertical': !horizontal,
      'sr-field--prefix': $slots['append-prefix']
    }"
    v-on="$listeners"
  >
    <label
      :for="labelFor"
      class="srtext--text"
      :class="{ 'error--text': validations }"
      @click="$emit('click:label')"
    >
      <slot name="label">
        <span>{{ label }}</span>
      </slot>
      <help-hint v-if="help" visible bottom>{{ help }}</help-hint>
    </label>
    <div class="sr-field--control">
      <slot name="append-prefix" />
      <slot />
      <div class="sr-field--error" :aria-hidden="!validations">
        <v-messages
          v-if="validations"
          :id="`${labelFor}--error`"
          :value="validations"
          :class="{ 'error--text': validations }"
        />
      </div>
      <slot name="append-inner" />
    </div>
    <slot name="append-outer" />
  </div>
</template>
<script>
import HelpHint from '@/components/signrequest/HelpHint';
export default {
  components: { HelpHint },
  props: {
    label: { type: String, required: false, default: '' },
    labelFor: { type: String, required: false, default: null },
    help: { type: String, required: false, default: '' },
    validations: { type: Array, required: false, default: null },
    hasPlaceholder: { type: Boolean, default: false },
    isEmpty: { type: Boolean, default: false },
    horizontal: { type: Boolean, default: false }
  }
};
</script>
<style scoped>
.sr-field {
  min-height: 72px;
  position: relative;
  display: flex;
}
.ay11-kb .sr-field:focus-within {
  outline-color: rgba(54, 108, 216, 0.7);
  outline-width: 4px;
  outline-style: solid;
  outline-offset: 0.5rem;
}

.sr-field--vertical {
  flex-direction: column;
  justify-content: center;
}
.sr-field--horizontal {
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
}

.sr-field--control {
  display: flex;
  flex-basis: 48px;
  flex-grow: 1;
  flex-direction: column;
}
.sr-field--horizontal .sr-field--control {
  height: 100%;
  max-width: 455px;
  flex-basis: 455px;
}

.sr-field--control > *:not(svg) {
  flex-grow: 1;
  flex-basis: 1.5em;
}
.sr-field--empty:not(.sr-field--has-placeholder):not(.sr-field--has-error):not(:focus-within):not(.sr-field--horizontal)
  label {
  top: 21px;
  font-size: 16px;
  line-height: 24px;
  cursor: text;
}
label {
  font-size: 13px;
  position: relative;
  top: 2px;
  color: inherit;
  opacity: 0.8;
  font-weight: normal;
  transition-property: top;
  transition-duration: 0.1s;
  letter-spacing: 0.27px;
}
.sr-field--horizontal > label {
  align-self: flex-start;
  font-size: 16px !important;
  opacity: 0.8;
  flex: 192px 0 0;
  height: 25px;
  margin-bottom: 1rem;
}
.ltr .sr-field--horizontal > label {
  margin-right: 1rem;
}
.rtl .sr-field--horizontal > label {
  margin-left: 1rem;
}
.signrequest-lib label {
  font-weight: bold;
  font-style: normal;
  letter-spacing: 0.3px;
  color: #767676;
}
.signrequest-lib .sr-field--error:empty,
.sr-field--horizontal .sr-field--error:empty {
  display: none;
}

.signrequest-lib input,
.signrequest-lib textarea {
  border: 1px solid #bcbcbc;
  box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.08);
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: 0.3px;
  font-stretch: normal;
  border-radius: 6px;
  margin-top: 8px;
  flex-basis: unset;
  flex-grow: unset;
  padding: 7px 10px;
  width: auto;
}
.signrequest-lib input:focus,
.signrequest-lib textarea:focus {
  border: 1px solid #0061d5;
  outline: 0;
  box-shadow: none;
}
.signrequest-lib input:hover,
.signrequest-lib textarea:hover {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.15);
}
.signrequest-lib input:disabled,
.signrequest-lib textarea:disabled {
  background-color: #f4f4f4;
}
.signrequest-lib input:placeholder {
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
  color: #767676;
}
.sr-field--prefix .sr-field--control {
  display: inline-flex;
  flex-grow: unset;
  flex-basis: unset;
  flex-direction: unset;
  align-items: center;
}
</style>
