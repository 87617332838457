import Vue from 'vue';
import { getAngularDeps } from '@/plugins/angular-injector';
import startCase from 'lodash/startCase';

export default {
  namespaced: true,
  state: {
    stack: []
  },
  getters: {
    modalOpen(state) {
      return state.stack.length !== 0;
    },
    currentModal(state) {
      const { stack } = state;
      return stack.length ? stack[stack.length - 1] : null;
    },
    findModal(state) {
      return findKey =>
        state.stack.find(modal => modal.key === findKey) || null;
    },
    currentModalName(_state, getters) {
      const { key } = getters.currentModal;
      return key && startCase(key);
    }
  },
  mutations: {
    reset(state) {
      state.stack = [];
    },
    pushModal(state, modalOptions) {
      state.stack.push(modalOptions);
    },
    dropModal(state, key) {
      state.stack = state.stack.filter(modal => modal.key !== key);
    }
  },
  actions: {
    async openModal({ state, getters, commit, dispatch }, modalOptions) {
      let resolve;
      let reject;
      const promise = new Promise((...args) => {
        [resolve, reject] = args;
      });

      const opts = Object.assign({}, modalOptions, {
        promise,
        resolve,
        reject
      });

      if (
        getters.currentModal &&
        getters.currentModal.key === modalOptions.key
      ) {
        return getters.currentModal.promise;
      }

      commit('pushModal', Object.freeze(opts));
      dispatch(
        'events/trackModalOpenedEvent',
        {
          name: getters.currentModalName,
          params: {
            feature: modalOptions.feature,
            trigger: modalOptions.trigger
          }
        },
        { root: true }
      );

      return promise;
    },

    async closeModal({ commit, getters, dispatch }, { returnValue, key }) {
      const { resolve } = getters.findModal(key);
      dispatch(
        'events/trackModalClosedEvent',
        { name: getters.currentModalName },
        { root: true }
      );
      commit('dropModal', key);

      if (resolve) {
        resolve(returnValue);
      }
    },

    async resolveModal({ getters, dispatch }, { key, returnValue }) {
      const { resolve } = getters.findModal(key) || {};
      if (resolve) {
        resolve(returnValue);
      }
    },

    async showSubscribeModal({ commit, dispatch, rootGetters }, options = {}) {
      commit('reset');
      return dispatch('openModal', {
        ...options,
        key: 'subscribe'
      });
    },

    async showLoginModal({ dispatch }, options = {}) {
      return dispatch('openModal', {
        doRegister: false,
        ...options,
        key: 'login'
      });
    },

    async showRegisterModal({ dispatch }, options = {}) {
      return dispatch('showLoginModal', {
        ...options,
        doRegister: true
      });
    },

    async showUploadModal({ commit }, options = {}) {
      const { srUploadModal } = await getAngularDeps('srUploadModal');
      srUploadModal.activate({}, options);
    },

    async openSignatureModal({ commit, dispatch }, modalOptions) {
      const opts = Object.assign({}, modalOptions, { key: 'signature' });

      const ret = await dispatch('openModal', opts);
      return ret;
    },

    async openTextEntryModal(
      { commit, dispatch },
      { fullType, text, multiline, couldChangeMultiline, maxColumns }
    ) {
      return dispatch('openModal', {
        key: 'textEntry',
        fullType,
        text,
        multiline,
        couldChangeMultiline,
        maxColumns
      });
    },

    async openCheckboxModal({ commit, dispatch }) {
      return dispatch('openModal', {
        key: 'skipCheckbox'
      });
    },

    async openMoveTemplateModal({ commit, dispatch }, { uuid }) {
      const opts = Object.assign({}, { uuid }, { key: 'templateMove' });

      return await dispatch('openModal', opts);
    },

    async openConfirmationModal(
      { commit, dispatch },
      {
        title,
        body,
        confirmationButtonText,
        confirmationButtonColor,
        dismissButtonText
      }
    ) {
      const opts = Object.assign(
        {},
        {
          title,
          body,
          confirmationButtonText,
          confirmationButtonColor,
          dismissButtonText
        },
        { key: 'confirmation' }
      );

      return await dispatch('openModal', opts);
    },

    async showTermsModal({ dispatch }, options) {
      return dispatch('openModal', { ...options, key: 'terms' });
    },

    async openChangeEmailModal({ commit, dispatch }) {
      const opts = Object.assign({}, {}, { key: 'changeEmail' });
      return await dispatch('openModal', opts);
    },

    async openEnableTwoFactorAuthModal({ commit }, options = {}) {
      const { srTwoFactorModal } = await getAngularDeps('srTwoFactorModal');
      srTwoFactorModal.activate({}, options);
    },

    async openIframeModal({ dispatch }, { url, title, size }) {
      return dispatch('openModal', {
        url,
        title,
        size,
        key: 'iframe'
      });
    },

    async openPlaceholderModal({ dispatch }, data) {
      return dispatch('openModal', { key: 'edit-placeholder', ...data });
    },

    async openRequestDetailsModal({ dispatch }, data) {
      return dispatch('openModal', { key: 'request-details', ...data });
    },
    async hideRequestDetailsModal({ dispatch }) {
      return dispatch('closeModal', { key: 'request-details' });
    },

    async openAttachmentsModal({ dispatch }) {
      return dispatch('openModal', { key: 'upload-attachments' });
    },

    async showDeclineModal({ dispatch }) {
      return dispatch('openModal', { key: 'decline' });
    },

    async hideDeclineModal({ dispatch }) {
      return dispatch('closeModal', { key: 'decline' });
    },

    async showCancelModal({ dispatch }) {
      return dispatch('openModal', { key: 'cancel' });
    },

    async hideCancelModal({ dispatch }) {
      return dispatch('closeModal', { key: 'cancel' });
    },
    async showLoginOrContinue({ dispatch }, { next, email }) {
      const $gettext = msgid => Vue.prototype.$gettext(msgid);
      const ret = await dispatch('openConfirmationModal', {
        title: $gettext('Welcome back!'),
        body: $gettext('Please log in to use your saved signatures'),
        confirmationButtonText: $gettext('Login'),
        dismissButtonText: $gettext('No thanks')
      });
      if (ret) {
        return dispatch('showLoginModal', { next, email });
      }
      return null;
    },

    async showSubscribeOrLoginModal(
      { dispatch, rootGetters },
      {
        next,
        plan,
        interval,
        trial_days,
        new_subscribe,
        doRegister = false,
        trigger
      }
    ) {
      await dispatch('users/getUser', {}, { root: true });
      if (rootGetters['users/userLoggedIn']) {
        plan = plan && plan.toUpperCase();
        dispatch('showSubscribeModal', {
          plan,
          interval,
          trial_days,
          callback: () => {
            dispatch('users/getUser', { forceReload: true }, { root: true });
          },
          new_subscribe: new_subscribe,
          trigger
        });
      } else {
        if (doRegister) {
          dispatch('showRegisterModal', { next });
        } else {
          dispatch('showLoginModal', { next });
        }
      }
    },

    showEsign({ dispatch }, text) {
      return dispatch('openModal', { key: 'esign', text });
    },

    showForwardModal({ dispatch }) {
      return dispatch('openModal', { key: 'forward' });
    },

    hideForwardModal({ dispatch }) {
      return dispatch('closeModal', { key: 'forward' });
    },

    async showTooMuchDocuments({ dispatch }) {
      return dispatch('openModal', {
        key: 'tooManyDocuments',
        trigger: 'missing_pro_permissions',
        feature: 'unlimited_documents'
      });
    },

    showEnterPasswordModal({ dispatch }, data) {
      return dispatch('openModal', { key: 'enterPassword', ...data });
    },

    async verifyDocumentPassword({ dispatch }, { signer_uuid, password }) {
      return await dispatch(
        'api/makeRequest',
        {
          url: `/docs/unlock-doc/${signer_uuid}/`,
          method: 'POST',
          data: {
            password
          }
        },
        { root: true }
      );
    },

    showVerifyPhoneModal({ dispatch }, data) {
      return dispatch('openModal', { ...data, key: 'verifyPhone' });
    },

    async showUpgradeModal({ dispatch }) {
      return dispatch('openModal', { key: 'memberUpgrade' });
    },

    async showConfirmMemberUpgrade({ dispatch }, data) {
      return dispatch('openModal', { key: 'confirmMemberUpgrade', ...data });
    },

    async showPublicLinkModal({ dispatch, rootGetters }) {
      const link = rootGetters['signrequest/publicLink'];
      const email = rootGetters['users/userEmail'];
      return dispatch('openModal', { key: 'publink', link, email });
    },

    async showBulkSendModal(_store, { uuid }) {
      const { srBulkSendModal } = await getAngularDeps('srBulkSendModal');
      return new Promise(resolve => {
        srBulkSendModal
          .activate(
            {},
            {
              template_uuid: uuid,
              emails: '',
              callback: resolve
            }
          )
          .then(resolve);
      });
    },

    showContactEmailModal({ dispatch }, { label }) {
      return dispatch('openModal', { key: 'contact-email', label });
    }
  }
};
