<template>
  <app-wrapper id="message-area">
    <v-snackbar
      v-if="topAction"
      :key="topAction.key"
      top
      :value="true"
      :timeout="0"
    >
      <span v-if="topAction.status === 'started'">{{ topAction.text }}</span>
      <span v-if="topAction.status === 'success'">
        {{ topAction.successText }}
      </span>
      <span v-if="topAction.status === 'error'">{{ topAction.errorText }}</span>

      <v-btn v-if="topAction.status === 'started'" color="white" flat>
        <v-progress-circular
          width="2"
          size="14"
          indeterminate
          color="primary"
        />
      </v-btn>

      <v-btn v-if="topAction.status === 'success'" color="success" flat>
        <translate>Done</translate>
      </v-btn>

      <v-btn v-if="topAction.status === 'error'" color="error" flat>
        <translate>Error</translate>
      </v-btn>
    </v-snackbar>

    <div class="message-box">
      <div class="message-container">
        <v-scale-transition group>
          <sr-message
            v-for="message in displayMessages"
            :key="message.key"
            :type="message.type"
            :body="message.msg"
            class="v-dialog__content--active"
            @click="closeMessage(message.key)"
          ></sr-message>
        </v-scale-transition>
      </div>
    </div>
  </app-wrapper>
</template>

<script>
import { mapGetters } from 'vuex';
import variant from '@/utils/variant';
import SrMessage from './elements/SrMessage.vue';
import BoxMessage from './elements/BoxMessage.vue';

export default {
  components: { SrMessage: variant(BoxMessage, SrMessage) },
  computed: {
    ...mapGetters('messages', ['topAction', 'messages']),
    standardMessages() {
      return {
        E_OOPS: this.$gettext(
          'An error occurred, we have been notified of the issue. Please come back later and try again. Sorry for the inconvenience.'
        ),
        W_DECLINED: this.$gettext('You have declined the document.'),
        W_CANCELED: this.$gettext('You have canceled the document.'),
        E_BAD_DOC: this.$gettext(
          'We are having trouble processing your document'
        ),
        O_DONE: this.$gettext('All done!'),
        O_SENT: this.$gettext(
          'You will receive an email with the signed document and signing log after all parties have signed.'
        )
      };
    },
    displayMessages() {
      return this.messages.map(message => ({
        ...message,
        msg: this.standardMessages[message.msg] || message.msg
      }));
    }
  },
  methods: {
    closeMessage(key) {
      this.$store.dispatch('messages/removeMessage', key);
    }
  }
};
</script>

<style scoped>
div.message-box {
  position: fixed;
  z-index: 1050;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 0px;
}

div.message-box .message-container {
  max-width: 60rem;
  margin-left: auto;
  margin-right: auto;
}
</style>
