<template>
  <v-form
    data-test-id="signer-details"
    :class="[showHelp && 'form-show-help']"
    class="homebox--details-form"
    autocomplete="off"
    @submit.prevent="$emit('submit')"
  >
    <homebox-screen :title-divider="true">
      <v-slide-y-transition origin="top right 0">
        <v-container class="pa-0 details-container">
          <div class="h-section">
            <sr-input
              v-if="template"
              ref="label"
              v-model="labelField"
              :placeholder="signerLabel"
              name="label"
              type="text"
              autocomplete="off"
              :readonly="me"
              :label="$gettext('Contact Name')"
            />
            <sr-input
              v-else
              ref="email"
              v-model="emailField"
              name="email"
              type="email"
              autocomplete="off"
              :label="$gettext('Email')"
              :readonly="!removable && !template"
              :rules="[emailRule]"
              suppress-first-error
            />
          </div>

          <hr class="h-divider" />

          <div class="h-section" @click="handleProLevel">
            <sr-combobox
              v-model="level"
              name="level"
              :items="levels"
              :label="$gettext('Authorization')"
              :readonly="!(canChangeSignLevel || allIsPermittedForDocument)"
              :enable-manual="false"
              show-caret
            >
              <template v-slot:item="{ item }">
                <level-icon :level="item.value" />
                <sr-space />
                <level :level="item.value" data-test-id="level" />
              </template>
              <template v-slot:value>
                <level-icon :level="level" />
                <sr-space />
                <level :level="level" />
              </template>
            </sr-combobox>
          </div>

          <hr class="h-divider" />

          <v-slide-y-transition>
            <v-container
              v-if="value.level !== 'cc' && value.level !== 'notify'"
              class="pa-0"
            >
              <div class="h-section" @click="handleProInput">
                <sr-input
                  v-model="stringOrder"
                  name="order"
                  type="number"
                  min="0"
                  :label="$gettext('Signing order')"
                  :readonly="!(canChangeSignOrder || allIsPermittedForDocument)"
                  :disabled="signerExistInFirstSr"
                >
                  <template v-slot:append-outer>
                    <help-hint>
                      <div>
                        <translate>
                          By default all contacts receive the SignRequest
                          simultaneously.
                        </translate>
                        <br />
                        <br />
                        <translate>
                          If you change the order, to for example 0, 1 and 2,
                          contact 1 will only receive the SignRequest after
                          contact 0 has signed. Contact 2 only after contact 1
                          has signed and so forth.
                        </translate>
                      </div>
                    </help-hint>
                  </template>
                </sr-input>
              </div>

              <hr class="h-divider" />

              <div class="h-section" @click="handleProInput">
                <sr-tel-input
                  v-model="verify_phone_number"
                  class="srtext--text"
                  name="verify_phone_number"
                  :label="$gettext('Text message verification')"
                  :readonly="!(canChangeTel || allIsPermittedForDocument)"
                >
                  <template v-slot:append-outer>
                    <help-hint>
                      <div>
                        <translate>
                          For additional security you can request your signers
                          to also input a unique code they receive by a text
                          message. (Additional fees apply.)
                        </translate>
                        <br />
                        <br />
                        <translate>
                          Important: this is not required and does not function
                          as a notification that a SignRequest is sent by email.
                        </translate>
                      </div>
                    </help-hint>
                  </template>
                </sr-tel-input>
              </div>

              <hr class="h-divider" />

              <div v-if="!me" class="h-section" @click="handleProInput">
                <sr-input
                  v-model="password"
                  name="password"
                  type="text"
                  autocomplete="new-password"
                  :label="$gettext('Require Password')"
                  :placeholder="$gettext('Enter password')"
                  :readonly="!(canChangePassword || allIsPermittedForDocument)"
                >
                  <template v-slot:append-outer>
                    <help-hint>
                      <div v-translate>
                        As an extra verification measure you can add a password
                        here. You should send this password separately to the
                        signer, for example with a text message or during a
                        phone call. The document contents will only be visible
                        after the signer clicks on the link and enters the
                        password.
                      </div>
                    </help-hint>
                  </template>
                </sr-input>
              </div>

              <hr class="h-divider" />
              <div class="h-section" @click="handleProInput">
                <sr-combobox
                  v-model="required_attachments"
                  name="required_attachments"
                  :label="$gettext('Require attachment')"
                  :placeholder="$gettext('Enter attachment name')"
                  :readonly="
                    !(canChangeRequireAttachment || allIsPermittedForDocument)
                  "
                  :items="[]"
                  chips
                  removable
                  multi
                >
                  <template v-slot:append-outer>
                    <help-hint>
                      <div v-translate>
                        By adding a name you can require your signer to upload
                        an attachment during signing. Only after the attachment
                        is uploaded the signer will be able to finalize the
                        document
                      </div>
                    </help-hint>
                  </template>
                </sr-combobox>
              </div>
              <hr class="h-divider" />
            </v-container>
          </v-slide-y-transition>
          <div v-if="removable" class="h-section h-section__remove">
            <sr-button
              sr-style="link"
              data-test-id="remove"
              @click="$emit('remove')"
            >
              <trash-icon />
              <translate>Remove email address</translate>
            </sr-button>
          </div>
          <hr class="h-divider" />
        </v-container>
      </v-slide-y-transition>
    </homebox-screen>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapModel } from '@/utils/vuexExtra';
import { validateEmail } from '@/utils/format';
import makeFields from '@/utils/makeFields';
import permissions from '@/mixins/permissions';
import FocusTrap from '@/mixins/FocusTrap';
import signerLabel from '@/utils/signerLabel';

import HomeboxScreen from './HomeboxScreen';
import SrSpace from '@/components/elements/SrSpace';
import SrTelInput from '@/components/elements/SrTelInput.vue';
import SrInput from '@/components/elements/SrInput';
import SrCombobox from '@/components/elements/SrCombobox';
import HelpHint from './HelpHint';
import TrashIcon from './TrashIcon';
import { Level, LevelIcon } from './SignerEntry';
export default {
  components: {
    Level,
    LevelIcon,
    HomeboxScreen,
    SrSpace,
    SrInput,
    SrTelInput,
    SrCombobox,
    HelpHint,
    TrashIcon
  },
  mixins: [
    permissions,
    FocusTrap('firstInputFocus', 'handleClose', 'shouldTrapFocus')
  ],
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: { type: Object, required: true },
    signerIndex: { type: Number, default: 0 },
    template: { type: Boolean, default: false },
    usedEmails: { type: Array, required: true },
    removable: { type: Boolean, default: true }
  },
  computed: {
    ...mapModel('conf', 'showHelp', 'updateShowHelp'),
    ...mapGetters('signrequest', [
      'allIsPermittedForDocument',
      'othersNeedToSign',
      'firstSr',
      'selectedSr'
    ]),
    ...mapGetters('users', [
      'canChangeSignLevel',
      'canChangeSignOrder',
      'canChangeTel',
      'canChangePassword',
      'canChangeRequireAttachment'
    ]),
    ...mapGetters('modals', ['modalOpen']),
    ...makeFields(
      'email',
      'name',
      'label',
      'level',
      'order',
      'verify_phone_number',
      'required_attachments',
      'password'
    ),
    // actual modals, like subscribe modal should be above sidepanel
    shouldTrapFocus() {
      return !this.modalOpen;
    },
    me() {
      return this.signerIndex === 0;
    },
    emailField: {
      get() {
        return this.email;
      },
      set(value) {
        this.formUpdate = { email: value, name: value };
      }
    },
    signerExistInFirstSr() {
      return (
        this.selectedSr &&
        this.selectedSr !== this.firstSr &&
        this.firstSr.form.signers
          .map(firstSrSigner => firstSrSigner.email)
          .includes(this.email)
      );
    },
    signerLabel() {
      return signerLabel(this.value, this.signerIndex);
    },
    labelField: {
      get() {
        return this.email || this.label;
      },
      set(value) {
        this.formUpdate = value.includes('@')
          ? { email: value, name: value, label: '' }
          : { email: '', name: '', label: value };
      }
    },
    levels() {
      const contactsLevels = [
        'cc',
        'signature',
        'approve',
        'notify',
        'in_person'
      ];
      const selfLevels = ['signature', 'cc'];
      const selfLevelsWithExistingSigners = ['signature', 'approve', 'cc'];

      const levels = this.me
        ? this.othersNeedToSign
          ? selfLevelsWithExistingSigners
          : selfLevels
        : contactsLevels;
      return levels.map(level => ({
        value: level,
        text: Level.getLevelTitle.call(this, level)
      }));
    },
    stringOrder: {
      get() {
        const { order } = this.value;
        return String(order);
      },
      set(value) {
        this.order = Math.abs(Number(value) || 0);
      }
    },
    emailRule() {
      return (email = '') => {
        if (this.usedEmails.includes(email.toLowerCase())) {
          return this.$gettext('This email is already used by another signer');
        }
        return validateEmail.call(this, email);
      };
    }
  },
  methods: {
    firstInputFocus() {
      (this.$refs.email || this.$refs.label).focus();
    },
    handleClose() {
      this.$emit('close');
    },
    handleProInput(event) {
      if (event.target.readOnly) {
        this.showLoginOrSubscribeModal({ feature: event.target.name });
      }
    },
    handleProLevel() {
      const readonly = !(
        this.canChangeSignLevel || this.allIsPermittedForDocument
      );
      if (readonly) {
        this.showLoginOrSubscribeModal({ feature: 'level' });
      }
    }
  }
};
</script>
<style scoped>
.homebox--details-form button.stick-start {
  margin-left: 0px;
  margin-right: 0px;
}
.homebox--details-form {
  height: 100%;
}
.homebox--details-form /deep/ .h-section--buttons .buttons.buttons-multi {
  justify-content: start;
}
.homebox--details-form /deep/ .intl-tel-input .selected-flag {
  padding: 0;
}
.ltr .h-section /deep/ .v-input {
  margin-right: 1em;
  margin-top: 1em;
}
.rtl .h-section /deep/ .v-input {
  margin-left: 1em;
}
.h-section /deep/ .v-input__slot {
  margin-bottom: 0;
}
.h-section__remove button {
  padding: 0;
  margin: 18px 0;
  font-weight: bold;
}
.h-section /deep/ .sr-button svg {
  font-size: inherit;
  height: 1em;
  width: 1em;
}
.h-section /deep/ .sr-button span {
  margin: 0 0.3em;
}
</style>
<style>
.v-menu__content.signer-level--menu {
  border-radius: 15px;
}
</style>
