var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'app-header--prepare': !_vm.hasSigningMode,
    'app-header--sign': _vm.hasSigningMode
  }},[(
      _vm.hasSigningMode &&
        _vm.canSign &&
        !_vm.isReadyToFinalize &&
        !_vm.hasActionablePlaceholders
    )?_c('h1',{directives:[{name:"translate",rawName:"v-translate"}],key:"s",staticClass:"srtext--text"},[_vm._v("\n    Click anywhere to sign\n  ")]):(_vm.hasSigningMode && _vm.hasDeclined)?_c('h1',{directives:[{name:"translate",rawName:"v-translate"}],key:"d",staticClass:"srtext--text"},[_vm._v("\n    The document has already been declined.\n  ")]):(_vm.hasSigningMode && _vm.hasForwarded)?_c('h1',{directives:[{name:"translate",rawName:"v-translate"}],key:"f",staticClass:"srtext--text"},[_vm._v("\n    The document has already been forwarded.\n  ")]):(_vm.hasSigningMode && _vm.hasSigned)?_c('h1',{directives:[{name:"translate",rawName:"v-translate"}],key:"s",staticClass:"srtext--text"},[_vm._v("\n    The document has already been signed.\n  ")]):(!_vm.hasSigningMode && _vm.currentFileLoading)?_c('h1',{directives:[{name:"translate",rawName:"v-translate"}],key:"c",staticClass:"primary--text"},[_vm._v("\n    We are uploading your file…\n  ")]):(!_vm.hasSigningMode && _vm.somebodyNeedsToSign)?_c('h1',{directives:[{name:"translate",rawName:"v-translate"}],key:"n",staticClass:"primary--text"},[_vm._v("\n    Click anywhere to start preparing\n  ")]):(!_vm.hasSigningMode)?_c('h1',{directives:[{name:"translate",rawName:"v-translate"}],key:"n2",staticClass:"primary--text"},[_vm._v("\n    Start by adding the signer information\n  ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }