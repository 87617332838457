<template>
  <sr-field
    v-if="readonly"
    key="r"
    :label="label"
    :label-for="uid"
    :horizontal="horizontal"
  >
    <span class="sr-subdomain--link">
      <span>https://</span>
      <a>{{ value }}</a>
      <span>.signrequest.com</span>
    </span>
    <template v-slot:append-inner>
      <em key="er" v-translate>
        The SignRequest subdomain cannot be changed. If you need a different
        domain you can create a new team.
      </em>
    </template>
  </sr-field>
  <sr-input
    v-else
    key="w"
    :label="label"
    placeholder="myteam"
    :name="name"
    :value="value"
    :horizontal="horizontal"
    @input="$emit('input', $event)"
    @blur="checkNow"
  >
    <template v-slot:append-inner>
      <em class="suffix srtext--text">.signrequest.com</em>
      <div class="sr-subdomain--status">
        <span v-if="available === true" key="s" class="status status--success">
          <span>available</span>
        </span>
        <span v-if="available === false" key="e" class="status status--error">
          <span>not available</span>
        </span>
        <span v-if="loading" key="l">
          <span v-translate>Checking availability...</span>
          <i class="fa fa-refresh fa-spin"></i>
        </span>
      </div>
      <em key="ew" v-translate>
        Choose the SignRequest subdomain carefully. This is permanent for this
        team.
      </em>
    </template>
  </sr-input>
</template>
<script>
import { mapActions } from 'vuex';
import debounce from 'lodash/debounce';
import SrInput from './SrInput';
import SrField from './SrField';
import uniqueId from '@/mixins/uniqueId';

const CHECK_DEBOUNCE_MS = 3000;

export default {
  components: { SrInput, SrField },
  mixins: [uniqueId],
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    label: { type: String, default: '' },
    value: { type: String, default: '' },
    readonly: { type: Boolean, default: false },
    name: { type: String, required: true },
    horizontal: { type: Boolean, default: false }
  },
  data() {
    return {
      loading: false,
      available: null,
      check: debounce(this.checkSubDomainAvail, CHECK_DEBOUNCE_MS)
    };
  },
  watch: {
    value(newValue) {
      this.available = null;
      this.check(newValue);
    }
  },
  methods: {
    ...mapActions('users', ['checkSubDomain']),
    checkNow() {
      this.check.flush();
    },
    async checkSubDomainAvail(name) {
      this.loading = true;
      const { available = null } = await this.checkSubDomain(name);
      this.loading = false;
      if (available !== null) {
        this.available = available;
      }
    }
  }
};
</script>
<style scoped>
::v-deep .sr-field--control {
  position: relative;
}
em.suffix {
  content: '.signrequest.com';
  position: absolute;
  font-weight: bold;
  font-style: normal;
  width: 120px;
  top: 11px;
  font-size: 16px;
}
.ltr em.suffix {
  text-align: right;
  right: 1rem;
}
.rtl em.suffix {
  text-align: left;
  left: 1rem;
}

.ltr .sr-field ::v-deep input {
  padding-left: 1rem;
  padding-right: calc(120px + 1rem);
  width: 100%;
}
.rtl .sr-field ::v-deep input {
  padding-right: 1rem;
  padding-left: calc(120px + 1rem);
  width: 100%;
}

::v-deep .sr-field--error {
  display: none;
}
.sr-subdomain--link {
  font-weight: bold;
  padding-top: 0.3rem;
}
em {
  line-height: normal;
}
input ~ em,
.sr-subdomain--status {
  margin-top: 0.5rem;
}
.sr-subdomain--status:empty {
  display: none;
}
</style>
