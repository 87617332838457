import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"lazy":"","max-width":"290","content-class":_vm.contentClass},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c(VCard,[_c(VCardTitle,{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"headline"},[_vm._v("\n      Please select a Team\n    ")]),_c(VCardText,[_c('translate',[_vm._v("\n        The template will be transferred to the selected team\n      ")]),_c('TeamSelector',{staticClass:"mt-2",model:{value:(_vm.selectedTeam),callback:function ($$v) {_vm.selectedTeam=$$v},expression:"selectedTeam"}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"flat":""},on:{"click":_vm.closeModal}},[_c('translate',[_vm._v("Cancel")])],1),_c(VBtn,{attrs:{"color":"primary","disabled":!_vm.selectedTeam},on:{"click":_vm.moveTemplateAndCloseModal}},[_c('translate',[_vm._v("Move")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }