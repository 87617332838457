import { translate } from 'vue-gettext';
import { loadTranslation } from '@/utils/langs';
import { getAngularDeps } from '@/plugins/angular-injector';
import { getCookie } from '@/utils/getJSONCookie';
import config from '@/config';

const pageUrls = {
  en: {
    // NOTE: if you change english links here be sure to also change then in the html as we hardcode them there
    // for search engines to find the links for SEO purposes
    home: '/en/',
    contact: '/en/contact/',
    benefits: '/en/benefits/',
    faq: 'https://help.signrequest.com/hc/en-us/',
    'how-it-works': '/en/how-it-works/',
    legality: 'https://signrequest.com/en/electronic-signature-legal',
    security: '/en/security-electronic-signatures/',
    plans: '/en/plans/',
    api: '/en/plans/api-pricing',
    about: '/en/about-us/',
    customers: '/en/customers/',
    privacy: '/en/privacy-notice/',
    terms: '/en/terms-of-service/',
    dpa: '/en/gdpr-avg-update/',
    blog: 'https://blog.signrequest.com/',
    integrations: '/en/integrations/',
    zapier_integration: '/en/digital-electronic-signatures-with-zapier/',
    jobs: '/en/jobs/',
    press: '/en/press/',
    forward_help:
      'https://help.signrequest.com/hc/en-us/articles/360000027414-Forward-SignRequest-to-another-signer',
    help_center: 'https://help.signrequest.com/hc/en-us',
    new_support_ticket: 'https://help.signrequest.com/hc/en-us/requests/new',
    how_to_sign:
      'https://help.signrequest.com/hc/en-us/articles/115004494714-How-to-sign-a-document-',
    how_to_team_account:
      'https://help.signrequest.com/hc/en-us/articles/115002908305-How-to-create-a-branded-team-account-and-invite-team-members-',
    how_to_delete:
      'https://help.signrequest.com/hc/en-us/articles/360014775253-How-to-delete-my-account-',
    refund:
      'https://help.signrequest.com/hc/en-us/articles/360010075359-What-is-the-refund-policy-',
    complete_sent: 'https://signrequest.com/en/landing/confirmation-sent',
    complete_signed: 'https://signrequest.com/en/landing/confirmation-signed',
    complete_declined:
      'https://signrequest.com/en/landing/confirmation-declined',
    choose_plan: 'https://signrequest.com/en/landing/choose-plan',
    choose_trial: 'https://signrequest.com/en/landing/choose-trial',
    features: 'https://signrequest.com/en/features',
    password_protected:
      'https://help.signrequest.com/hc/en-us/articles/360012220000-2FA-Methods-for-your-signers',
    own_email_in_contact_field_error: null
  },
  nl: {
    home: '/nl/',
    contact: '/nl/contact/',
    benefits: '/nl/voordelen/',
    faq: 'https://help.signrequest.com/hc/nl/',
    'how-it-works': '/nl/hoe-het-werkt/',
    legality: 'https://signrequest.com/nl/digitale-handtekening-rechtsgeldig',
    security: '/nl/data-beveiliging-digitale-handtekening/',
    plans: '/nl/prijzen/',
    api: '/nl/prijzen/api',
    about: '/nl/over-ons/',
    customers: '/nl/klanten/',
    privacy: '/en/privacy-notice/',
    terms: '/en/terms-of-service/',
    blog: 'https://blog.signrequest.com/nl/',
    integrations: '/nl/integraties/',
    zapier_integration: '/en/digital-electronic-signatures-with-zapier/',
    jobs: '/nl/vacatures/',
    press: '/nl/pers/',
    forward_help:
      'https://help.signrequest.com/hc/nl/articles/360000027414-De-SignRequest-doorsturen-naar-een-andere-ondertekenaar',
    help_center: 'https://help.signrequest.com/hc/nl',
    new_support_ticket: 'https://help.signrequest.com/hc/nl/requests/new',
    how_to_sign:
      'https://help.signrequest.com/hc/nl/articles/115004494714-Hoe-onderteken-ik-een-document-',
    how_to_team_account:
      'https://help.signrequest.com/hc/nl/articles/115002908305-Hoe-maak-ik-een-team-account-en-voeg-ik-extra-leden-toe-',
    how_to_delete:
      'https://help.signrequest.com/hc/nl/articles/360014775253-Hoe-verwijder-ik-mijn-account-',
    refund:
      'https://help.signrequest.com/hc/en-us/articles/360010075359-What-is-the-refund-policy-',
    complete_sent: 'https://signrequest.com/nl/landing/bevestiging-getekend',
    complete_signed: 'https://signrequest.com/nl/landing/bevestiging-getekend',
    complete_declined:
      'https://signrequest.com/nl/landing/bevestiging-afgekeurd',
    features: 'https://signrequest.com/nl/functionaliteiten'
  },
  de: {
    home: '/de/',
    contact: '/de/kontakt/',
    benefits: '/de/grunde-elektronische-unterschrift/',
    faq: '/de/faq/',
    'how-it-works': '/en/how-it-works/',
    security: '/en/security-electronic-signatures/',
    plans: '/de/preis/',
    about: '/de/uber-uns/',
    customers: '/de/kundschaft/',
    privacy: '/en/privacy-notice/',
    terms: '/en/terms-of-service/',
    blog: 'https://blog.signrequest.com/',
    integrations: '/en/integrations/',
    zapier_integration: '/en/digital-electronic-signatures-with-zapier/',
    jobs: '/en/jobs/',
    press: '/en/press/',
    forward_help:
      'https://help.signrequest.com/hc/en-us/articles/360000027414-Forward-SignRequest-to-another-signer',
    help_center: 'https://help.signrequest.com/hc/en-us',
    new_support_ticket: 'https://help.signrequest.com/hc/en-us/requests/new',
    how_to_sign:
      'https://help.signrequest.com/hc/en-us/articles/115004494714-How-to-sign-a-document-',
    how_to_team_account:
      'https://help.signrequest.com/hc/en-us/articles/115002908305-How-to-create-a-branded-team-account-and-invite-team-members-',
    refund:
      'https://help.signrequest.com/hc/en-us/articles/360010075359-What-is-the-refund-policy-',
    complete_sent: 'https://signrequest.com/en/landing/confirmation-sent',
    complete_signed: 'https://signrequest.com/en/landing/confirmation-signed',
    complete_declined:
      'https://signrequest.com/en/landing/confirmation-declined'
  }
};

let configLoading = null;
let allowIndexConfig = true; // only allowed on first call

function initialState() {
  return {
    config: config.frontendSettings || {
      trans: {
        locale_code: 'en'
      },
      team: {}
    },
    globalSettings: config.globalSettings || {
      SITE_URL: null
    },
    trialSubscribeVersion: 2,
    configLoaded: !!config.frontendSettings,
    flags: {
      versionSwitchDismissed: false,
      vueHomebox: false,
      vueTemplate: false,
      vueSignbox: false,
      oldApiFallback: false,
      prepareMode: false
    },
    userLanguage: getCookie('sr_lang') || null,
    userTheme: {
      primaryColor: config.globalSettings.DEFAULT_PRIMARY_COLOR
    },
    plan: null,
    showHelp: false
  };
}

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    flags(state) {
      return state.flags;
    },
    showHomeboxToggle(state, getters, rootState, rootGetters) {
      return (
        Boolean(rootGetters['users/userLoggedIn']) && !getters.isVueHomebox
      );
    },
    showHomeboxToggleInMenu(state, getters, rootState, rootGetters) {
      return Boolean(rootGetters['users/userLoggedIn']);
    },
    showVersionSwitch(state, getters) {
      return getters.showHomeboxToggle && !getters.flags.versionSwitchDismissed;
    },
    isVueHomebox(state) {
      return Boolean(state.flags.vueHomebox);
    },
    isVueSignbox(state) {
      return Boolean(state.flags.vueSignbox);
    },
    isVueTemplate(state) {
      return Boolean(state.flags.vueTemplate);
    },
    isOldApiFallback(state) {
      return Boolean(state.flags.oldApiFallback);
    },
    languageCode(state) {
      return state.userLanguage || state.config.trans.locale_code;
    },
    getPageUrlForLang: (state, getters) => pageUrlType => {
      let pageUrl;
      const langMap = pageUrls[getters.languageCode];
      if (langMap && langMap.hasOwnProperty(pageUrlType)) {
        pageUrl = pageUrls[getters.languageCode][pageUrlType];
      } else {
        // defaults to EN pages
        pageUrl = pageUrls['en'][pageUrlType];
      }

      if (pageUrlType === 'new_support_ticket') {
        pageUrl = getters.zendeskLoginLink(pageUrl);
      }

      return pageUrl;
    },
    getHelpDeskPageUrlForLang: (state, getters) => pageUrlType => {
      if (config.hideHelpCenter) return null;

      return getters.getPageUrlForLang(pageUrlType);
    },
    getTrialSubscribeVersion: state => {
      return state.trialSubscribeVersion;
    },
    helpCenterUrl: (state, getters) => {
      return getters.getHelpDeskPageUrlForLang('help_center');
    },
    howToDeleteUrl: (state, getters) => {
      return getters.getHelpDeskPageUrlForLang('how_to_delete');
    },
    forwardHelpUrl: (state, getters) => {
      return getters.getHelpDeskPageUrlForLang('forward_help');
    },
    ownEmailInContactFieldErrorUrl: (state, getters) => {
      return getters.getHelpDeskPageUrlForLang(
        'own_email_in_contact_field_error'
      );
    },
    howToMakeTeamUrl: (state, getters) => {
      return getters.getHelpDeskPageUrlForLang('how_to_team_account');
    },
    zendeskLoginLink: (state, getters, rootState) => returnTo => {
      if (rootState.users.user.logged_in) {
        return `https://help.signrequest.com/hc/en-us/signin?return_to=${encodeURIComponent(
          returnTo
        )}`;
      } else {
        return returnTo;
      }
    },
    localTheme(state, getters) {
      const teamTheme = getters.teamColor
        ? { primaryColor: getters.teamColor }
        : {};
      const thm = Object.assign(
        { primaryColor: config.globalSettings.DEFAULT_PRIMARY_COLOR },
        state.userTheme,
        teamTheme
      );
      return Object.freeze(thm);
    },
    teamColor(state) {
      const { team } = state.config;
      return team && team.primary_color;
    },
    teamLogo(state) {
      const { team } = state.config;
      return team && team.logo;
    },
    globalSettings(state) {
      return state.globalSettings;
    },
    teamDomain(state) {
      const { team } = state.config;
      return team && team.subdomain;
    },
    planCurrencySymbol(state) {
      return (
        (state.plan && state.plan.currency && state.plan.currency.symbol) ||
        null
      );
    },
    planCurrency(state) {
      return state.plan && state.plan.currency;
    },
    planPricing(state) {
      return state.plan && state.plan.pricing;
    },
    countryISOCode(state) {
      return state.config.country_iso_code;
    },
    isLoaded(state) {
      return state.configLoaded;
    },
    showHelp(state) {
      return state.showHelp;
    }
  },
  mutations: {
    reset(state) {
      Object.assign(state, initialState());
    },
    install(state, conf) {
      if (conf.FRONTEND_SETTINGS) {
        // new endpoint has FRONTEND_SETTINGS and GLOBAL_SETTINGS
        state.config = Object.freeze({ ...conf.FRONTEND_SETTINGS });
        state.globalSettings = Object.freeze({ ...conf.GLOBAL_SETTINGS });
      } else {
        // can be removed after deploy
        state.config = Object.freeze({ ...conf });
      }
      state.config = Object.freeze({ ...conf });
      state.configLoaded = true;
    },
    setCurrentLanguage(state, lang) {
      state.userLanguage = lang;
    },
    setUserTheme(state, options) {
      state.userTheme = Object.freeze(
        Object.assign({}, state.userTheme, options)
      );
    },
    setFlags(state, flagUpdate) {
      state.flags = Object.freeze({ ...state.flags, ...flagUpdate });
    },
    setPlanCurrency(state, { currency, pricing }) {
      state.plan = Object.freeze({ currency, pricing });
    },
    setShowHelp(state, v) {
      state.showHelp = v;
    }
  },
  actions: {
    async setThemeFromAngular({ commit }, options) {
      commit('setUserTheme', options);
    },
    async _loadConfig({ state, commit, dispatch }) {
      const conf = await dispatch(
        'api/makeRequest',
        {
          url: '/conf/settings/'
        },
        { root: true }
      );
      commit('install', conf);
      return conf;
    },
    async loadConfig({ state, dispatch }) {
      if (state.configLoaded && allowIndexConfig) return state.config;
      allowIndexConfig = false;
      let ret = await (configLoading =
        configLoading || dispatch('_loadConfig'));
      configLoading = null;
      return ret;
    },
    setFlags({ commit }, flags) {
      return commit('setFlags', flags);
    },
    async changeLanguage({ commit, dispatch }, lang) {
      if (lang !== this.languageCode) {
        await dispatch(
          'api/makeRequest',
          {
            url: '/conf/set_lang/',
            method: 'POST',
            data: { language: lang }
          },
          { root: true }
        );
      }
    },
    async getStats({ commit, dispatch }, lang) {
      if (lang !== this.languageCode) {
        await dispatch(
          'api/makeRequest',
          {
            url: '/conf/stats/',
            method: 'GET'
          },
          { root: true }
        );
      }
    },
    async getPlanCurrency({ commit, dispatch }) {
      const { currency, pricing } = await dispatch(
        'api/makeRequest',
        {
          url: '/orders/billing/get-currency-from-request',
          method: 'GET'
        },
        { root: true }
      );
      commit('setPlanCurrency', { currency, pricing });
    },
    updateShowHelp({ commit }, value) {
      commit('setShowHelp', value);
    },
    async switchLang({ commit, dispatch }, { lang, sync_backend = true }) {
      const { gettextCatalog } = await getAngularDeps('gettextCatalog');
      const { localeKey, strings } = await loadTranslation(lang);
      // do not set current language to anything we dont have translations
      // for, as it would break date formatting
      if (!localeKey) {
        return null;
      }
      // translations can be empty in if localeKey is en (which is en-us)
      // in this case we still switch the language
      translate.initTranslations({ [localeKey]: strings || {} });
      if (gettextCatalog) {
        gettextCatalog.setStrings(localeKey, strings || {});
        gettextCatalog.setCurrentLanguage(localeKey); // for frontend
      }

      // user config should reflect user input
      // if we dont have language file for nl_BE, we should still
      // mark user choice in ase we have it later
      if (sync_backend) {
        await dispatch('changeLanguage', lang);
      }

      commit('setCurrentLanguage', localeKey);
    }
  }
};
