<template>
  <div class="banner--container">
    <div v-if="showUpgrade" id="banner">
      <p>
        <translate>
          The number of active users exceeds the number allowed for your current
          subscription:
        </translate>
        &nbsp;{{ upgradeTo.quantity }} ({{ planQuantity }}).
        <translate v-if="!isPayer">
          Please ask your subscription owner to upgrade.
        </translate>
        <span v-translate class="text--error">
          Features will soon be disabled.
        </span>
        <a v-if="isPayer" v-translate :href="actionLink">Upgrade now</a>
        <span v-if="isPayer" v-translate>
          to avoid suspension of your account!
        </span>
      </p>
    </div>
    <div v-if="hasUnpaidDue && isPayer" key="1">
      <p>
        <strong>Your account is past due.</strong>
        &nbsp;
        <a v-translate href="/#/billing" target="_blank">
          Update your payment details
        </a>
        <translate>to avoid an interruption to your service.</translate>
      </p>
    </div>
    <div v-if="hasUnpaidDue && !isPayer" key="2">
      <p>
        <strong>Your account is past due.</strong>
        &nbsp;
        <translate>
          Please ask your subscription owner to update their payment details to
          avoid an interruption to your service
        </translate>
      </p>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('signrequest', ['hasPrepareMode']),
    ...mapGetters('users', [
      'upgradeTo',
      'isPayer',
      'planQuantity',
      'hasUnpaidDue'
    ]),
    showUpgrade() {
      return Boolean(this.upgradeTo && this.upgradeTo.plan);
    },
    actionLink() {
      return `/#/billing?do_upgrade=1&plan=${this.upgradeTo.plan}&quantity=${this.upgradeTo.quantity}`;
    }
  }
};
</script>
<style scoped>
.banner--container {
  position: relative;
  z-index: 25;
}
.banner--container p {
  margin-top: 0;
  background: #fff4f4;
  text-align: center;
  padding: 1rem 2rem;
  border-radius: 20px;
  box-shadow: -2px 6px 44px 10px rgba(0, 0, 0, 0.06);
  color: #111111;
  margin-bottom: 2rem;
}
</style>
