export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async isLoggedIn({ dispatch, rootGetters }) {
      if (!rootGetters['users/userLoaded']) {
        await dispatch('users/getUser', null, { root: true });
      }
      return rootGetters['users/userLoggedIn'];
    },
    async showLoginOrPage({ dispatch, rootGetters }, pageName) {
      if (await dispatch('isLoggedIn')) {
        location.assign(rootGetters['conf/getPageUrlForLang'](pageName));
      } else {
        await dispatch(
          'modals/showLoginModal',
          { doPermLogin: false },
          { root: true }
        );
      }
    },
    async showLoginOrSubscribeModal(
      { dispatch, rootGetters },
      { feature, trigger = 'missing_pro_permissions' }
    ) {
      if (await dispatch('isLoggedIn')) {
        await dispatch(
          'modals/showSubscribeModal',
          { feature, trigger },
          { root: true }
        );
      } else {
        await dispatch(
          'modals/showLoginModal',
          { doPermLogin: true },
          { root: true }
        );
      }
    }
  }
};
