<script>
export default {
  props: {
    show: { type: Boolean, required: true },
    zIndex: { type: Number, required: true }
  },
  watch: {
    show(value) {
      this.el.classList[value ? 'add' : 'remove']('homebox-overlay-in');
    }
  },
  mounted() {
    const el = document.createElement('div');
    el.classList.add('homebox-overlay');
    if (this.$props.show) {
      el.classList.toggle('homebox-overlay-in', this.$props.show);
    }
    el.style.zIndex = this.$props.zIndex;
    el.onclick = () => {
      this.$emit('click');
    };
    document.body.appendChild(el);
    this.el = el;
  },
  beforeDestroy() {
    this.el.remove();
  },
  render() {
    return null;
  }
};
</script>
<style>
div.homebox-overlay {
  transition: opacity 200ms linear;
  opacity: 0;
}

div.homebox-overlay.homebox-overlay-in {
  position: fixed;

  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  border-radius: 0px;

  background: black;
  opacity: 0.75;
}
</style>
