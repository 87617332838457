import angular from 'angular';
import { store } from '../../store';

/**
 * @ngdoc service
 * @name frontendApp.confService
 * @description
 * # confService
 * Factory in the frontendApp.
 */
angular.module('frontendApp').factory('confService', [
  'ngAsyncWrapper',
  function(ngAsyncWrapper) {
    this.getStats = ngAsyncWrapper(async function() {
      return await store.dispatch('conf/getStats');
    });

    this.getPageUrlForLang = function(link_name) {
      return store.getters['conf/getPageUrlForLang'](link_name);
    };

    this.getHelpDeskPageUrlForLang = function(link_name) {
      return store.getters['conf/getHelpDeskPageUrlForLang'](link_name);
    };

    return this;
  }
]);
