import angular from 'angular';
import Hammer from 'hammerjs';

/**
 * @ngdoc directive
 * @name frontendApp.directive:drawer
 * @description
 * # drawer
 */
angular
  .module('frontendApp')
  .directive('drawer', [
    function() {
      return {
        restrict: 'A',
        link: function postLink(scope, element, attrs) {
          var mc = new Hammer(element[0]);

          function drawer(event) {
            angular.element('body').toggleClass('drawer-open--' + attrs.drawer);
            //event.srcEvent.preventDefault();
          }

          function openDrawer(side) {
            angular.element('body').toggleClass('drawer-open--' + side);
          }

          scope.openRightDrawer = function() {
            openDrawer('right');
          };

          scope.openLeftDrawer = function() {
            openDrawer('left');
          };

          mc.on('tap press', drawer);

          scope.$on('$destroy', function() {
            mc.off('tap press', drawer);
          });
        }
      };
    }
  ])

  .directive('drawerSwipe', [
    function() {
      return {
        restrict: 'A',
        link: function postLink(scope, element, attrs) {
          var mc = new Hammer(element[0]);

          function swipe(event) {
            angular
              .element('body')
              .removeClass('drawer-open--' + attrs.drawerSwipe);
            event.srcEvent.preventDefault();
          }

          mc.on('swipe' + attrs.drawerSwipe, swipe);

          scope.$on('$destroy', function() {
            mc.off('swipe' + attrs.drawerSwipe, swipe);
          });
        }
      };
    }
  ]);
