export function readFile(file) {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onload = event =>
      resolve({
        name: file.name,
        contents: new Uint8Array(event.target.result)
      });
    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
}
