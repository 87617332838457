import angular from 'angular';
import _ from 'lodash';
/**
 * @author Sławomir Żytko <slawek@amsterdam-standard.pl>
 * @copyright Amsterdam Standard Sp. Z o.o.
 */
(function() {
  'use strict';

  angular.module('frontendApp').directive('pagination', Pagination);

  Pagination.$inject = ['$location'];
  /**
   * Directive renders pagination
   * Usage
   * <code>
   *  //In view
   * <pagination></pagination>
   *
   * //In JS
   * $promise.then(function() {
   *      $scope.pageCount = Math.ceil(count / limit);
   * });
   * </code>
   *
   * @param $location
   * @returns {{templateUrl: string, restrict: string, replace: boolean, link: Function}}
   * @constructor
   */
  function Pagination($location) {
    return {
      template: require('../../views/directives/pagination.html'),
      restrict: 'EA',
      replace: true,
      link: function(scope, element, attrs) {
        scope.BOUND_SIZE = 3;

        /**
         * Watches on page change
         */
        scope.$watch('page', function(page) {
          if (!angular.isUndefined(page)) {
            scope.$emit('pagination:pageChanged', page);
          }
        });

        /**
         * Returns current page
         * @returns {*|number}
         */
        scope.currentPage = function() {
          return scope.page ? Number(scope.page) : 1;
        };

        /**
         * Returns range of pages in the following format: 1 .. 4 5 6 7 8 9 10 .. 100
         * @returns {*}
         */
        scope.pages = function() {
          if (scope.pageCount < 1) {
            return [];
          }
          if (scope.BOUND_SIZE > scope.pageCount) {
            scope.BOUND_SIZE = scope.pageCount;
          }
          var delta = Math.ceil(scope.BOUND_SIZE / 2);
          if (scope.page - delta > scope.pageCount - scope.BOUND_SIZE) {
            scope.lowBound = scope.pageCount - scope.BOUND_SIZE + 1;
            scope.topBound = scope.pageCount;
          } else {
            if (scope.page - delta < 0) {
              delta = scope.page;
            }

            var offset = scope.page - delta;
            scope.lowBound = offset + 1;
            scope.topBound = offset + scope.BOUND_SIZE;
          }
          if (scope.lowBound < 0) scope.lowBound = 1;
          return _.range(scope.lowBound, scope.topBound + 1);
        };

        /**
         * Goes to indicated page
         * @param p
         */
        scope.goToPage = function(p) {
          scope.page = p;
        };

        /**
         * Goes to next page
         */
        scope.next = function() {
          scope.goToPage(scope.currentPage() + 1);
        };

        /**
         * Goes to previous page
         */
        scope.previous = function() {
          scope.goToPage(scope.currentPage() - 1);
        };
      }
    };
  }
})();
