//optional need to be loaded before angular-file-upload-shim(.min).js
window.FileAPI = {
  // will be set to false by angular-file-upload-shim.js
  hasFlash: true,
  //to debug flash in HTML5 browsers
  forceLoad: false,
  html5: false,

  jsUrl: '/shared/scripts/FileAPI.min.js',
  flashUrl: '/shared/flash/FileAPI.flash.swf'
};
