import angular from 'angular';

/**
 * @ngdoc function
 * @name frontendApp.controller:OrgUserController
 * @description
 * # OrgUserController
 * Controller of the frontendApp
 */
angular.module('frontendApp').controller('OrgUserController', [
  '$rootScope',
  '$scope',
  '$route',
  '$http',
  'srLoginModal',
  '$window',
  '$location',
  '$timeout',
  'UserService',
  'orgUserService',
  'confService',
  function(
    $rootScope,
    $scope,
    $route,
    $http,
    srLoginModal,
    $window,
    $location,
    $timeout,
    UserService,
    orgUserService,
    confService
  ) {
    $scope.confService = confService;
    $scope.loading = false;
    $scope.users = [];

    UserService.waitForUser().then(function(user) {
      if (!user.logged_in) {
        return;
      }
      $scope.loading = true;
      orgUserService.getUsers().then(
        function(response) {
          $scope.loading = false;
          $scope.users = response.data;
        },
        function() {
          $scope.loading = false;
        }
      );
    });

    $scope.removeUser = function(uuid) {
      $scope.loading = true;
      UserService.removeMember(uuid).then(
        function() {
          orgUserService.getUsers().then(
            function(response) {
              $scope.users = response.data;
              $scope.loading = false;
            },
            function() {
              $scope.loading = false;
            }
          );
        },
        function() {
          $scope.loading = false;
        }
      );
    };
  }
]);
