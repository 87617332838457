import angular from 'angular';

/**
 * @ngdoc directive
 * @name frontendApp.directive:sr-user-stats
 * @description
 * # Shows how many users we currently have and in how many countries
 */
angular
  .module('frontendApp')
  /**
   * <sr-user-stats></sr-user-stats>
   */
  .component('srUserStats', {
    template: require('../../views/directives/sr-user-stats.html'),
    controllerAs: 'ctrl',
    controller: [
      '$scope',
      '$element',
      '$attrs',
      'confService',
      '$filter',
      function($scope, $element, $attrs, confService, $filter) {
        var ctrl = this;
        confService.getStats().then(function(response) {
          if (response && response.data) {
            ctrl.numUsers = $filter('number')(response.data.users, 0);
            ctrl.numCountries = $filter('number')(response.data.countries, 0);
            ctrl.numLanguages = $filter('number')(response.data.languages, 0);
          }
        });
      }
    ]
  });
