<template>
  <div>
    <v-gravatar
      alt="profile-picture"
      :email="member.preferred_email"
      default-img="mm"
      :size="48"
    />
    <div class="member--info">
      <strong>{{ member.name }}</strong>
      <span v-if="member.role == 'inactive'">- {{ member.role }}</span>
    </div>
    <div class="align-select">
      <strong v-if="readonly" data-test-id="member-permission">
        {{ teamMemberDisplayValue }}
      </strong>
      <v-select
        v-else
        :value="teamMemberValueSelected"
        name="teamMemberOptions"
        :items="teamMemberOptions"
        solo
        class="team-member-options"
        :menu-props="{
          [$vuetify.rtl ? 'nudge-left' : 'nudge-right']: -16,
          'nudge-top': 7,
          contentClass: 'team-member-options--menu'
        }"
        @input="handleChangeOption"
      >
        <template v-slot:item="{ item }">
          <div
            v-if="item.value === teamMemberValueSelected"
            class="srtext--text"
          >
            <i class="icon icon--tick" aria-hidden="true"></i>
            <span v-if="member.is_admin" data-test-id="member-permission">
              {{ item.text }}
            </span>
            <span v-else data-test-id="member-permission">
              {{ item.selected }}
            </span>
          </div>
          <div v-else>
            <span class="menu-icon-margin" />
            <span data-test-id="member-permission">{{ item.text }}</span>
          </div>
        </template>
        <template v-slot:selection="{ item }">
          <strong class="selected-option">{{ teamMemberDisplayValue }}</strong>
        </template>
      </v-select>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
  props: {
    member: { type: Object, required: true },
    readonly: { type: Boolean, default: false },
    contentClass: { type: String, default: '' }
  },
  data() {
    return {
      teamMemberValueSelected: '',
      teamMember: this.member
    };
  },
  computed: {
    isAdmin() {
      return this.member.is_admin && !this.member.is_owner;
    },
    teamMemberOptions() {
      if (this.isAdmin) {
        return [
          {
            value: 'can',
            text: this.$gettext('Can view documents'),
            selected: this.$gettext('System administrator'),
            disabled: true
          },
          {
            value: 'can_not',
            text: this.$gettext("Can't view documents"),
            selected: this.$gettext('System administrator'),
            disabled: true
          }
        ];
      } else {
        return [
          {
            value: 'member',
            text: this.$gettext('Set as member'),
            selected: this.$gettext('Member')
          },
          {
            value: 'owner',
            text: this.$gettext('Set as owner'),
            selected: this.$gettext('Owner')
          },
          {
            value: 'remove',
            text: this.$gettext('Remove'),
            selected: this.$gettext('Remove')
          }
        ];
      }
    },
    teamMemberValue() {
      return this.isAdmin
        ? this.member.can_access_documents
          ? 'can'
          : 'can_not'
        : this.member.is_owner
        ? 'owner'
        : 'member';
    },
    teamMemberDisplayValue() {
      const option = this.teamMemberOptions.find(
        option => option.value == this.teamMemberValue
      );
      return option.selected;
    }
  },
  mounted() {
    this.teamMemberValueSelected = this.teamMemberValue;
  },
  methods: {
    ...mapActions('modals', ['openConfirmationModal']),
    ...mapActions('users', ['removeMember', 'updateTeamUser']),
    async handleChangeOption(value) {
      const oldValue = this.teamMemberValueSelected;
      let returnValue = null;
      if (
        value != this.teamMemberValueSelected &&
        this.teamMemberValueSelected != ''
      ) {
        if (value == 'owner') {
          returnValue = await this.handleSetAsOwner();
        }
        if (value == 'member') {
          returnValue = await this.handleSetAsMember();
        }
        if (value == 'remove') {
          returnValue = await this.handleRemoveMember();
          return;
        }
        this.teamMemberValueSelected = this.teamMemberValue;
      }
    },
    async handleRemoveMember() {
      const confirmed = await this.openConfirmationModal({
        body: this.$gettext(
          'Are you sure you want to remove %member from this team?'
        ).replace('%member', this.member.name),
        confirmationButtonText: this.$gettext('Remove'),
        confirmationButtonColor: 'error'
      });
      if (!confirmed) {
        return false;
      }
      await this.removeMember(this.member.uuid);
      return true;
    },
    async handleSetAsOwner() {
      const confirmed = await this.openConfirmationModal({
        body: this.$gettext(
          'Are you sure you want to set the member %member as the team owner?'
        ).replace('%member', this.member.name),
        confirmationButtonText: this.$gettext('Set as owner'),
        confirmationButtonColor: 'fancy'
      });
      if (!confirmed) {
        return false;
      }
      await this.updateTeamUser({
        teamUserUuid: this.member.uuid,
        isOwner: true
      });
      return true;
    },
    async handleSetAsMember() {
      const confirmed = await this.openConfirmationModal({
        body: this.$gettext(
          'Are you sure you want to set the team owner %member as the member?'
        ).replace('%member', this.member.name),
        confirmationButtonText: this.$gettext('Set as member'),
        confirmationButtonColor: 'fancy'
      });
      if (!confirmed) {
        return false;
      }
      await this.updateTeamUser({
        teamUserUuid: this.member.uuid,
        isOwner: false
      });
      return true;
    }
  }
};
</script>
<style scoped>
.member--item {
  padding: 1rem 0;
  position: relative;
  display: flex;
  align-items: center;
}
.member--info {
  margin: 0 1rem;
}
.member--item::after {
  content: ' ';
  border-bottom: 1px solid #ddd;
  transform: scaleY(0.5);
  width: 100%;
  display: block;
  bottom: 0;
  position: absolute;
}
.ltr .align-select {
  margin-left: auto;
  margin-top: 20px;
}
.rtl .align-select {
  margin-right: auto;
  margin-top: 20px;
}
.team-member-options {
  width: 250px;
}
.team-member-options ::v-deep .v-input__slot {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}
.team-member-options ::v-deep .v-select__selections {
  display: flex;
  justify-content: right;
}
.team-member-options ::v-deep input {
  display: none;
}
.ltr .selected-option {
  width: 100%;
  text-align: right;
}
.rtl .selected-option {
  width: 100%;
  text-align: left;
}
.ltr .icon {
  margin-right: 5px;
}
.rtl .icon {
  margin-left: 5px;
}
.ltr .menu-icon-margin {
  margin-left: 21px;
}
.rtl .menu-icon-margin {
  margin-right: 21px;
}
</style>
<style>
.v-menu__content.team-member-options--menu {
  border-radius: 15px;
}
</style>
