import angular from 'angular';

/**
 * @ngdoc directive
 * @name frontendApp.directive:status
 * @description
 * # docPage
 */
angular
  .module('frontendApp')
  /**
   * Shows a spinner, shown when 'loading' is true or undefined. Also watches the 'loading' var if
   * defined on the parent scope
   * <sr-status></sr-status>
   */
  .component('srStatus', {
    template: require('../../views/directives/status.html'),
    controllerAs: 'ctrl',
    controller: [
      '$scope',
      '$element',
      '$attrs',
      '$http',
      'gettextCatalog',
      function($scope, $element, $attrs, $http, gettextCatalog) {
        var ctrl = this;

        ctrl.status = null; // can be 'none', 'minor', 'major', 'critical'
        ctrl.getStatusTranslation = function() {
          switch (ctrl.status) {
            case 'none':
              return gettextCatalog.getString('All Systems Operational');
            case 'minor':
              return gettextCatalog.getString('Partial System Outage');
            case 'major':
              return gettextCatalog.getString('Major System Outage');
            case 'critical':
              return gettextCatalog.getString('Critical System Outage');
            case 'maintenance':
              return gettextCatalog.getString('Service Under Maintenance');
          }
        };

        function updateStatus() {
          $http
            .get('https://62vqqh6qv58h.statuspage.io/api/v2/status.json', {
              ignoreLoadingBar: true
            })
            .then(function(response) {
              if (
                response.data &&
                response.data.status &&
                response.data.status.indicator
              ) {
                ctrl.status = response.data.status.indicator;
              }
            });
        }

        updateStatus();
        // update every minute
        setInterval(updateStatus, 1000 * 60);
      }
    ]
  });
