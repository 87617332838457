import angular from 'angular';
import _ from 'lodash';
import Hammer from 'hammerjs';
import $ from 'jquery';
import { getSignerColor } from '@/utils/signerColors';
import { formatShortDate } from '@/utils/getDefaultDate';

/**
 * @ngdoc directive
 * @name frontendApp.directive:docPageEditor
 * @description
 * # docPageEditor
 */
angular.module('frontendApp').directive('docPageEditor', [
  '$window',
  '$rootScope',
  '$timeout',
  'docService',
  'srTextInputModal',
  'UserService',
  'srPlaceholderModal',
  'srCheckboxInputModal',
  'localeService',
  'utils',
  '$q',
  '$vuex',
  function(
    $window,
    $rootScope,
    $timeout,
    docService,
    srTextInputModal,
    UserService,
    srPlaceholderModal,
    srCheckboxInputModal,
    localeService,
    utils,
    $q,
    $vuex
  ) {
    return {
      template: require('../../views/directives/doc-page-editor.html'),
      restrict: 'E',
      replace: true,
      scope: {
        page: '=',
        editor: '=',
        sigPad: '=',
        doc: '='
      },
      link: function postLink(scope, element, attrs) {
        // correct for the position when we do not have all options available
        // disable_text also disables the checkbox for now
        scope.tooltip_left_correction = -120;

        scope.allowDateInput = function() {
          return !scope.doc.signrequest.disable_date;
        };
        if (!scope.allowDateInput()) scope.tooltip_left_correction += 24;

        scope.allowTextInput = function() {
          return !scope.doc.signrequest.disable_text;
        };
        if (!scope.allowTextInput()) scope.tooltip_left_correction += 24;

        scope.allowCheckboxInput = function() {
          return scope.allowTextInput();
        };
        if (!scope.allowCheckboxInput()) scope.tooltip_left_correction += 24;

        scope.tooltip_css = {
          left: scope.tooltip_left_correction + 'px',
          top: '-57px',
          position: 'absolute',
          display: 'block'
        };

        scope.pageContainerId = 'page-container-' + scope.page.index;
        scope.container = angular.element('#' + scope.pageContainerId);

        scope.initEditor = function() {
          if (!scope.editor.saved) {
            scope.editor.init = true;
            return;
          }
          if (scope.editor.loaded) {
            scope.dragApi.setPosition(
              scope.editor.x_pos,
              scope.editor.y_pos,
              false,
              true
            );
            scope.type = scope.editor.placeholder.type || '';
            scope.checkPrefillTextFromPlaceholder();
            scope.editor.init = true;
            scope.$emit('update-fonts');
          } else {
            $timeout(function() {
              scope.initEditor();
            }, 100);
          }
        };
        scope.initEditor();

        $rootScope.$on('prefillFromText', function(type, data) {
          var text =
            _.get(data, 'prefill__' + scope.editor.placeholder.external_id) ||
            false;
          if (text) {
            docService.textToImg(text, scope.doc.uuid).then(function(response) {
              scope.changeSig(response.data.image, { is_text: true });
            });
          }
        });

        $rootScope.$on('clearPrefillFromText', function() {
          scope.clearPlaceholderEditor();
        });

        /**
         * Positions the tooltip in the middle above the signature
         */
        var centerTooltip = function() {
          var imgWidth =
            (parseFloat(scope.editor.sig.css.width.replace('%', '')) / 100) *
            scope.page.width;
          var left_correction = -48;
          if (!scope.type) {
            left_correction = scope.tooltip_left_correction;
          }
          scope.tooltip_css.left = imgWidth / 2 + left_correction + 'px';
        };

        scope.setPositionFromEditor = function(conf) {
          // to ensure we only add the sig (with the right dimensions) we need to wait for the page image to load
          // this is only a problem when people are really quick but yeah, we need to be sure here. Bad connections
          // on a phone could take some time.
          conf = conf || {};
          var image = conf.image;
          var is_initial = conf.is_initial;
          var is_text = conf.is_text;
          var is_checkbox = conf.is_checkbox;
          var is_signature = !is_checkbox && !is_text;
          var placeholder = conf.placeholder;

          var is_placeholder_being_prepared =
            scope.editor.is_placeholder &&
            !scope.editor.placeholder.action_required;

          if (scope.page.loaded) {
            scope.editor.sig.x_pos = scope.editor.x_pos;
            scope.editor.sig.y_pos = scope.editor.y_pos;

            // pixels to percent points
            if (typeof image === 'object') {
              // only set height and with when we have the image available
              if (image.width && image.height) {
                // make new percent points
                var scale_factor = 1;
                // never scale placeholders as they have a default size
                if (!is_placeholder_being_prepared) {
                  scale_factor = is_text ? 0.4 : 0.2; // resize factor (just some trial error default variables)
                }
                scope.editor.sig.width =
                  (image.width / scope.page.natural_width) * scale_factor;
                scope.editor.sig.height =
                  (image.height / scope.page.natural_height) * scale_factor;

                scope.editor.sig.start_width = scope.editor.sig.width;
                scope.editor.sig.start_height = scope.editor.sig.height;

                if (image.scale_factor && !placeholder) {
                  scope.editor.sig.width *= image.scale_factor;
                  scope.editor.sig.height *= image.scale_factor;
                }

                if ($rootScope.user && !placeholder) {
                  // if we scaled text or dates before apply that scale now, but only when we're not filling a
                  // placeholder (signing mode). We used to have a bug here where when we would prepare a document first
                  // and add a text placeholder we would store that size locally and use that when later filling the a
                  // placeholder while signing.
                  if (
                    is_text &&
                    ($rootScope.user.text_scale_height ||
                      $rootScope.user.placeholder_text_scale_height)
                  ) {
                    if (scope.editor.is_placeholder) {
                      if ($rootScope.user.placeholder_text_scale_height) {
                        scope.editor.sig.width *=
                          $rootScope.user.placeholder_text_scale_width;
                        scope.editor.sig.height *=
                          $rootScope.user.placeholder_text_scale_height;
                      }
                    } else if ($rootScope.user.text_scale_height) {
                      scope.editor.sig.width *=
                        $rootScope.user.text_scale_width;
                      scope.editor.sig.height *=
                        $rootScope.user.text_scale_height;
                    }
                  }
                  if (
                    is_checkbox &&
                    ($rootScope.user.checkbox_scale ||
                      $rootScope.user.placeholder_checkbox_scale)
                  ) {
                    if (scope.editor.is_placeholder) {
                      if ($rootScope.user.placeholder_checkbox_scale) {
                        scope.editor.sig.width *=
                          $rootScope.user.placeholder_checkbox_scale;
                        scope.editor.sig.height *=
                          $rootScope.user.placeholder_checkbox_scale;
                      }
                    } else if ($rootScope.user.checkbox_scale) {
                      scope.editor.sig.width *= $rootScope.user.checkbox_scale;
                      scope.editor.sig.height *= $rootScope.user.checkbox_scale;
                    }
                  }
                  if (is_signature && scope.editor.is_placeholder) {
                    if (
                      $rootScope.user.placeholder_signature_width &&
                      $rootScope.user.placeholder_signature_height
                    ) {
                      scope.editor.sig.width =
                        $rootScope.user.placeholder_signature_width;
                      scope.editor.sig.height =
                        $rootScope.user.placeholder_signature_height;
                    }
                  }
                }

                if (placeholder) {
                  var scale_to_fit = 1;

                  if (is_signature) {
                    // scale to the height of the placeholder if smaller
                    if (scope.editor.sig.height < placeholder.height_p) {
                      scale_to_fit = Math.max(
                        placeholder.height_p / scope.editor.sig.height,
                        scale_to_fit
                      );
                    }
                  } else if (!scope.editor.sig.multiline) {
                    // scale to fit the placeholder
                    if (scope.editor.sig.height > placeholder.height_p) {
                      scale_to_fit = Math.min(
                        placeholder.height_p / scope.editor.sig.height,
                        scale_to_fit
                      );
                    }
                  } else if (scope.editor.sig.multiline) {
                    // scale to fit the placeholder
                    if (scope.editor.sig.width > placeholder.width_p) {
                      scale_to_fit = Math.min(
                        placeholder.width_p / scope.editor.sig.width,
                        Math.min(scale_to_fit, 1)
                      );
                    }
                  }
                  scope.editor.sig.width *= scale_to_fit;
                  scope.editor.sig.height *= scale_to_fit;

                  // when we are filling a placeholder and want to maximize the size to the placeholder boundaries
                  // but we don't want to go off page
                  scale_to_fit = 1;
                  var max_width_p_space_left = Math.abs(1 - scope.editor.x_pos);
                  if (scope.editor.sig.width > max_width_p_space_left) {
                    scale_to_fit = Math.min(
                      max_width_p_space_left / scope.editor.sig.width,
                      scale_to_fit
                    );
                  }
                  scope.editor.sig.width *= scale_to_fit;
                  scope.editor.sig.height *= scale_to_fit;

                  var corrected_y_pos = scope.editor.placeholder.y_pos;
                  var corrected_x_pos = scope.editor.placeholder.x_pos;

                  if (is_signature) {
                    if (
                      scope.editor.placeholder.height_p <
                      scope.editor.sig.height
                    ) {
                      // shift the signature up to the middle of the placeholder box as the signature is bigger
                      // than the placeholder
                      corrected_y_pos =
                        scope.editor.placeholder.y_pos +
                        (scope.editor.placeholder.height_p -
                          scope.editor.sig.height) /
                          2;
                    } else {
                      // place the signature at the bottom left of the box placeholder box
                      corrected_y_pos =
                        scope.editor.placeholder.y_pos +
                        scope.editor.placeholder.height_p -
                        scope.editor.sig.height;
                    }
                  } else {
                    if (
                      // center the text in the middle of the box
                      !scope.editor.sig.multiline && // we want multiline to start at the top left of a placeholder
                      scope.editor.placeholder.height_p >
                        scope.editor.sig.height
                    ) {
                      // center the sig vertically in the placeholder box which is bigger now
                      corrected_y_pos =
                        scope.editor.placeholder.y_pos +
                        (scope.editor.placeholder.height_p -
                          scope.editor.sig.height) /
                          2;
                    }

                    if (
                      conf.is_rtl_text &&
                      scope.editor.placeholder.width_p > scope.editor.sig.width
                    ) {
                      // this is hebrew and we want to align the x pos to the right of the placeholder
                      corrected_x_pos =
                        scope.editor.placeholder.x_pos +
                        (scope.editor.placeholder.width_p -
                          scope.editor.sig.width);
                    }
                  }

                  if (
                    corrected_y_pos !== scope.editor.placeholder.y_pos ||
                    corrected_x_pos !== scope.editor.placeholder.x_pos
                  ) {
                    scope.dragApi.setPosition(
                      corrected_x_pos,
                      corrected_y_pos,
                      false
                    );
                  }

                  if (placeholder.uuid) {
                    scope.editor.sig.placeholder_uuid = placeholder.uuid;
                  }
                }

                if (angular.isDefined(is_initial) && is_initial) {
                  // we are inserting this image for the first time and want to compensate for the touch location
                  // and the center of the object
                  // we should not do this when we are filling a placeholder as the location is known already
                  if (!placeholder) {
                    scope.editor.sig.x_pos =
                      scope.editor.sig.x_pos - scope.editor.sig.width / 2;
                    scope.editor.sig.y_pos =
                      scope.editor.sig.y_pos - scope.editor.sig.height / 2;
                  }

                  $timeout(function() {
                    scope.dragApi.setPosition(
                      scope.editor.sig.x_pos,
                      scope.editor.sig.y_pos,
                      false
                    );
                  }, 10);
                }
                // check if we have a signature so we can finalize the document
              }
              scope.doc.checkForSignatures();
              scope.editor.updateCss();
              centerTooltip();
            }
          } else {
            $timeout(function() {
              scope.setPositionFromEditor(image);
            }, 100);
          }
        };

        scope.showEditor = function() {
          if (scope.editor.is_placeholder && !scope.editor.placeholder.filled) {
            if (scope.editor.placeholder.editable) {
              scope.editor.show = true;
            } else if (scope.editor.placeholder.action_required) {
              // we are filling this placeholder and the current signer needs to fill this
              switch (scope.editor.placeholder.type) {
                // TODO: add initials and name specific inputs
                case 's':
                  scope.showSigInput();
                  break;
                case 'i':
                  scope.showSigInput();
                  break;
                case 'd':
                  scope.showDateInput();
                  break;
                case 't':
                  scope.showTextInput();
                  break;
                case 'n':
                  scope.showTextInput();
                  break;
                case 'c':
                  scope.showCheckboxInput();
                  break;
              }
            }
          } else if (scope.editor.sig.editable) {
            scope.editor.show = true;
          }
        };

        scope.hideEditor = function() {
          scope.editor.show = false;
        };

        scope.clearPlaceholderEditor = function() {
          scope.editor.placeholder.filled = false;
          // reset the sig to the initial version (empty)
          scope.editor.sig = docService.newSig(scope.editor.placeholder);
          var is_text =
            scope.editor.placeholder.type === 't' ||
            scope.editor.placeholder.type === 'n';
          scope.setPositionFromEditor({
            image: scope.editor.placeholder,
            initial: true,
            is_text: is_text
          });
          scope.hideEditor();
        };

        scope.editor.deleteEditor = function() {
          if (
            scope.editor.placeholder.action_required &&
            !scope.doc.prepare_doc
          ) {
            scope.clearPlaceholderEditor();
          } else {
            scope.page.editors.splice(
              scope.page.editors.indexOf(scope.editor),
              1
            );
          }
        };

        scope.toggleEditor = function(event) {
          // if sig is not editable the editor is not shown, calling this should also not show it
          // if it would show somehow backend would not allow any changes
          if (scope.editor.isEditable(scope.doc.prepare_doc)) {
            event.preventDefault(); // bust the ghost click
            event.stopPropagation();
            scope.page.checkOpenEditors();
            if (scope.editor.show) {
              scope.hideEditor();
            } else {
              scope.showEditor();
            }
            centerTooltip();
            return false;
          }
          return true;
        };

        scope.placeholderKeypress = function(event) {
          const keyCode = event.keyCode ? event.keyCode : event.which;
          if (keyCode === 13 || keyCode === 32) {
            return scope.toggleEditor(event);
          }

          return false;
        };

        $(element)
          .find('.img--wrapper')
          .on('touchstart', function(event) {
            // fix for android stock browser ghost clicks we need to cancel here
            event.preventDefault();
            scope.toggleEditor(event);
          });

        scope.type = '';

        scope.getTypeTranslation = function() {
          return docService.sigantureTypeTranslations[scope.type];
        };

        scope.getTypeIcon = function() {
          if (scope.type === 't' || scope.type === 'n') {
            return 'icon--no-font-size icon--text';
          }
          if (scope.type === 'd') {
            return 'icon--no-font-size icon--calendar';
          }
          if (scope.type === 'c') {
            return 'icon--checkbox';
          }
          return 'icon--no-font-size icon--signature';
        };

        scope.getSignerColor = function(index) {
          return `color: ${getSignerColor(index)};`;
        };

        scope.element = element;

        scope.savePlaceHolder = function(placeholder_conf) {
          var is_initial = scope.editor.placeholder.signer_index === null;

          if (!is_initial) {
            placeholder_conf.signer_index = Number(
              placeholder_conf.signer_index
            );
            scope.doc.last_selected_signer_index =
              scope.editor.placeholder.signer_index;
          }
          scope.editor.placeholder = placeholder_conf;
          scope.type = scope.editor.placeholder.type;

          scope.editor.sig.type = scope.type;
          var is_text = false;
          var is_checkbox = false;
          if (scope.type === 's' || scope.type === 'i') {
            if (is_initial) {
              scope.editor.placeholder.width =
                docService.initial_placeholder_width;
              scope.editor.placeholder.height =
                docService.initial_placeholder_height;
            }
          } else if (scope.type === 'c') {
            is_checkbox = true;
            if (is_initial) {
              scope.editor.placeholder.width =
                docService.initial_placeholder_checkbox_width;
              scope.editor.placeholder.height =
                docService.initial_placeholder_checkbox_height;
            }
          } else {
            is_text = true;
            if (is_initial) {
              scope.editor.placeholder.width =
                docService.initial_placeholder_text_width;
              scope.editor.placeholder.height =
                docService.initial_placeholder_text_height;
            }
          }

          srPlaceholderModal.deactivate();
          scope.setPositionFromEditor({
            image: scope.editor.placeholder,
            is_initial: is_initial,
            is_text: is_text,
            is_checkbox: is_checkbox
          });
          scope.$emit('update-fonts');
        };

        scope.cancelPlaceholderModal = function() {
          srPlaceholderModal.deactivate();
        };

        scope.showPlaceHolderModal = function(conf) {
          conf = conf || { signer_index: null };
          var placeholder_conf = angular.copy(scope.editor.placeholder);

          // also changes / sets the type
          angular.extend(placeholder_conf, conf);

          if (placeholder_conf.signer_index === null) {
            placeholder_conf.signer_index =
              String(scope.doc.last_selected_signer_index) || null;
          } else {
            placeholder_conf.signer_index = String(
              placeholder_conf.signer_index
            );
          }

          scope.doc.updateSignerSelectOptions();
          srPlaceholderModal.activate(
            {},
            {
              editor: scope.editor,
              placeholder_conf: placeholder_conf,
              doc: scope.doc,
              savePlaceHolder: scope.savePlaceHolder,
              getTypeTranslation: scope.getTypeTranslation,
              getTypeIcon: scope.getTypeIcon,
              cancelPlaceholderModal: scope.cancelPlaceholderModal,
              showAdvanced: ~['d', 't', 'c'].indexOf(placeholder_conf.type)
            }
          );
        };

        scope.showTextInput = function() {
          if (scope.doc.prepare_doc) {
            scope.showPlaceHolderModal({ type: 't' });
          } else {
            // constant 96 was decided upon after a long and bloody battle
            var maxColumns = Math.floor(
              (scope.editor.placeholder.width_p || 1) * 96
            );
            srTextInputModal.activate(
              {},
              {
                textInsert: {
                  maxColumns: maxColumns,
                  changeText: scope.changeText,
                  isDate: false,
                  text: scope.editor.sig.text || '',
                  multiline: !!(
                    scope.editor.placeholder &&
                    scope.editor.placeholder.multiline
                  ),
                  placeholder: scope.editor.placeholder
                }
              }
            );
          }
        };

        scope.showCheckboxInput = function() {
          if (scope.doc.prepare_doc) {
            var required = scope.editor.placeholder.required;
            if (scope.editor.placeholder.type !== 'c') {
              // we are coming from a different type and the default of 'required' for checkboxes is false;
              required = false;
            }
            scope.showPlaceHolderModal({ type: 'c', required: required });
          } else {
            if (scope.editor.is_placeholder) {
              if (scope.editor.placeholder.required) {
                // we don't need a popup as it's not skippable
                scope.changeCheckbox(true);
              } else {
                srCheckboxInputModal.activate(
                  {},
                  {
                    checkbox: {
                      changeCheckbox: scope.changeCheckbox,
                      placeholder: scope.editor.placeholder
                    }
                  }
                );
              }
            } else {
              scope.changeCheckbox(true);
            }
          }
        };
        scope.showDateInput = function() {
          if (scope.doc.prepare_doc) {
            scope.showPlaceHolderModal({ type: 'd' });
          } else {
            srTextInputModal.activate(
              {},
              {
                textInsert: {
                  changeText: scope.changeText,
                  isDate: true,
                  dateFormatLocale: scope.doc.date_format_locale,
                  text: scope.editor.sig.text || '',
                  placeholder: scope.editor.placeholder
                }
              }
            );
          }
        };

        scope.checkPrefillTextFromPlaceholder = function() {
          // check if we can prefill a placeholder with text we have already
          // we are signing this document
          if (
            !scope.doc.prepare_doc &&
            scope.editor.is_placeholder &&
            scope.editor.placeholder.action_required &&
            !scope.editor.placeholder.filled &&
            scope.editor.placeholder.prefill &&
            // we have not filled this placeholder yet and this placeholder has text we can prefill
            // OR it' a date placeholder / checkbox we can fill right away
            (scope.editor.placeholder.text ||
              ~['d', 'c'].indexOf(scope.editor.placeholder.type))
          ) {
            var isDate = scope.editor.placeholder.type === 'd';
            var isCheckbox = scope.editor.placeholder.type === 'c';

            scope.hideEditor(); // just to be sure
            scope.type = scope.editor.placeholder.type;

            var prefillWithText = function(text) {
              docService
                .textToImg(text, scope.doc.uuid, scope.doc.signer.uuid)
                .then(function(response) {
                  scope.changeSig(response.data.image, { is_text: true });
                });
            };

            var prefillWithCheckbox = function(checkbox_value) {
              var image = docService.getCheckboxImg(checkbox_value);
              scope.changeSig(image, {
                is_checkbox: true,
                checkbox_value: checkbox_value
              });
            };

            if (!scope.editor.placeholder.text && isDate) {
              const languageCode = $vuex.getters['conf/languageCode'];
              const dateValue = scope.editor.placeholder.date_value || null;
              const prefill = scope.editor.placeholder.prefill || false;

              let formattedDateValue = '';
              if (dateValue && prefill) {
                // we prefill from prefill_tags with dateValue
                formattedDateValue = formatShortDate(
                  dateValue,
                  languageCode,
                  true
                );
              } else if (prefill) {
                // we prefill with current default date
                formattedDateValue = formatShortDate(
                  new Date(),
                  languageCode,
                  true
                );
              } else {
                // we just create placeholder and do not prefill
                return;
              }

              prefillWithText(formattedDateValue);
            } else if (isCheckbox) {
              prefillWithCheckbox(scope.editor.placeholder.checkbox_value);
            } else {
              prefillWithText(scope.editor.placeholder.text);
            }
          }
        };

        scope.wrapLine = function(line, maxColumns) {
          if (!maxColumns) {
            return line;
          }

          var lines = [];

          while (line) {
            if (line.length <= maxColumns) {
              lines.push(line);
              break;
            } else {
              // find a space and break there if possible
              // do maxColumns + 1 in case word is exactly maxColumns in length
              var lastSpace = line.slice(0, maxColumns + 1).lastIndexOf(' ');
              if (lastSpace !== -1) {
                lines.push(line.slice(0, lastSpace));
                line = line.slice(lastSpace + 1);
              } else {
                lines.push(line.slice(0, maxColumns));
                line = line.slice(maxColumns);
              }
            }
          }

          return lines.join('\n');
        };

        scope.wrapText = function(text, maxColumns) {
          var arr = text.split('\n').map(function(line) {
            return scope.wrapLine(line, maxColumns);
          });

          return arr.join('\n');
        };

        scope.changeText = function(text, isDate, multiline, maxColumns) {
          if (text) {
            scope.hideEditor();
            if (isDate) {
              multiline = null;
              scope.type = 'd';
            } else {
              scope.type = 't';
            }

            if (multiline && !!maxColumns) {
              text = scope.wrapText(text, maxColumns);
            }

            docService
              .textToImg(text, scope.doc.uuid, scope.doc.signer.uuid)
              .then(function(response) {
                scope.changeSig(response.data.image, {
                  is_text: true,
                  is_rtl_text: utils.isRTLText(text),
                  multiline: multiline
                });
                scope.showEditor();
              });
          } else {
            var image = { src: '', width: 0, height: 0 };
            scope.changeSig(image, { is_text: true });
            scope.showEditor();
          }
        };

        scope.changeCheckbox = function(checkbox_value) {
          scope.type = 'c';
          if (!angular.isDefined(checkbox_value)) {
            return;
          }
          var image = docService.getCheckboxImg(checkbox_value);
          scope.changeSig(image, {
            is_checkbox: true,
            checkbox_value: checkbox_value
          });
          if (checkbox_value) {
            scope.showEditor();
          }
        };

        scope.changeSig = function(sig, extra_conf) {
          extra_conf = extra_conf || {};
          if (!(extra_conf.is_text || extra_conf.is_checkbox)) {
            scope.type = 's';
          }
          scope.editor.sig.text = sig.text || '';
          scope.editor.sig.multiline = extra_conf.multiline || null;
          scope.editor.sig.data_uri = sig.data_uri || sig.src;
          scope.editor.sig.page_uuid = scope.page.uuid;
          scope.editor.sig._sig_uuid = sig.uuid;
          scope.editor.sig.index = sig.index;
          scope.editor.sig.checkbox_value = extra_conf.checkbox_value || null;
          scope.editor.sig.type = scope.type;

          var is_initial =
            !(scope.editor.sig.width || scope.editor.sig.height) &&
            !scope.editor.is_placeholder;
          var conf = {
            image: sig,
            is_initial: is_initial,
            is_text: extra_conf.is_text,
            is_rtl_text: extra_conf.is_rtl_text,
            is_checkbox: extra_conf.is_checkbox
          };
          if (scope.editor.is_placeholder) {
            // we are filling a placeholder
            conf.placeholder = scope.editor.placeholder;
            // after filling it's not a placeholder anymore
            scope.editor.placeholder.filled = true;
          }
          scope.setPositionFromEditor(conf);
        };

        scope.showSigInput = function() {
          if (scope.doc.prepare_doc) {
            scope.showPlaceHolderModal({ type: 's' });
          } else {
            scope.sigPad.openSigsSelectModal(scope.changeSig);
          }
        };

        scope.$watch(
          'dragApi.position',
          function(newValue, oldValue) {
            if (newValue !== oldValue) {
              scope.editor.x_pos = newValue.left;
              scope.editor.y_pos = newValue.top;
              if (scope.editor.sig) {
                scope.setPositionFromEditor(); // will only update x/y pos on sig
              }
            }
          },
          true
        );

        element.css({
          left: scope.editor.x_pos * 100 + '%',
          top: scope.editor.y_pos * 100 + '%'
        });

        ////// rezise the img //////
        var mc = new Hammer(element.find('.resize-handle')[0], {
          touchAction: 'none'
        });

        // let the pan gesture support all directions.
        // this will block the vertical scrolling on a touch-device while on the element
        mc.get('pan').set({ direction: Hammer.DIRECTION_ALL });

        var initialWidth = 0,
          initialHeight = 0;
        mc.on('panstart', function(event) {
          initialWidth = scope.editor.sig.width;
          initialHeight = scope.editor.sig.height;
        });

        mc.on('panmove', function(event) {
          var containerWidth = scope.page.natural_width;
          var containerHeight = scope.page.natural_height;
          var scaleDeltaX = event.deltaX / containerWidth;
          var scaleDeltaY = event.deltaY / scope.container.height();

          scope.$apply(function() {
            var sig_type = scope.editor.sig.type;
            if (!sig_type && scope.editor.is_placeholder) {
              sig_type = scope.editor.placeholder.type;
            }
            var allowYscaling =
              scope.editor.is_placeholder && scope.editor.placeholder.editable;
            var minWidth;
            var minHeight;
            switch (sig_type) {
              case 'c':
                minWidth = 18 / containerWidth;
                minHeight = 18 / containerHeight;
                allowYscaling = false;
                break;
              case 'd':
                minWidth = 32 / containerWidth;
                minHeight = 18 / containerHeight;
                break;
              case 't':
                minWidth = 18 / containerWidth;
                minHeight = 18 / containerHeight;
                break;
              default:
                minWidth = 80 / containerWidth;
                minHeight = 35 / containerHeight;
            }

            var newWidth = initialWidth + scaleDeltaX;
            if (newWidth < minWidth) {
              scaleDeltaX = scaleDeltaX + minWidth - newWidth;
            }
            scope.editor.sig.width = initialWidth + scaleDeltaX;
            if (allowYscaling) {
              var newHeight = initialHeight + scaleDeltaY;
              if (newHeight < minHeight) {
                scaleDeltaY = scaleDeltaY + minHeight - newHeight;
              }
              scope.editor.sig.height = initialHeight + scaleDeltaY;
            } else {
              scope.editor.sig.height =
                (scaleDeltaX / initialWidth + 1) * initialHeight;
            }

            scope.editor.updateCss();
            if (scope.editor.sig.type && scope.editor.sig.start_width) {
              // store for next text, date, checkbox or name input to have the same scale
              UserService.waitForUser().then(function() {
                var scale_width =
                  scope.editor.sig.width / scope.editor.sig.start_width;
                var scale_height =
                  scope.editor.sig.height / scope.editor.sig.start_height;
                if (
                  scope.editor.sig.type === 's' ||
                  scope.editor.sig.type === 'i'
                ) {
                  if (scope.editor.is_placeholder) {
                    // we're editing a signature placeholder and want to store the width / height for next insert
                    $rootScope.user.placeholder_signature_width =
                      scope.editor.sig.width;
                    $rootScope.user.placeholder_signature_height =
                      scope.editor.sig.height;
                  } else {
                    // we're scaling a signature and we want to store the scale in the signature for next insert
                    var user_sig = _.find($rootScope.user.sigs, {
                      uuid: scope.editor.sig._sig_uuid
                    });
                    if (user_sig) {
                      user_sig.scale_factor = scale_width;
                    }
                  }
                } else if (scope.editor.sig.type === 'c') {
                  // checkbox
                  if (scope.editor.is_placeholder) {
                    $rootScope.user.placeholder_checkbox_scale = scale_height;
                  } else {
                    $rootScope.user.checkbox_scale = scale_height;
                  }
                } else {
                  // text, date, name
                  if (scope.editor.is_placeholder) {
                    $rootScope.user.placeholder_text_scale_width = scale_width;
                    $rootScope.user.placeholder_text_scale_height = scale_height;
                  } else {
                    $rootScope.user.text_scale_width = scale_width;
                    $rootScope.user.text_scale_height = scale_height;
                  }
                }
              });
            }
          });
          centerTooltip();
          scope.$emit('update-fonts');
        });

        mc.on('panend', function(event) {
          scope.dragApi.setPosition(scope.editor.x_pos, scope.editor.y_pos);
        });
        scope.$on('$destroy', function() {
          mc.off('panstart panmove panend');
        });
      }
    };
  }
]);
