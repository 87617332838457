<template>
  <v-card class="menu menu-nav">
    <v-list dense>
      <v-list-tile
        v-if="user.email && teams"
        :key="'user-gravatar'"
        class="header"
        avatar
        @click.prevent="switchTeam(undefined)"
      >
        <v-list-tile-avatar>
          <v-gravatar :email="user.email" default-img="mm" :size="48" />
        </v-list-tile-avatar>

        <v-list-tile-content>
          <v-list-tile-title>
            {{ user.full_name }}
            <span v-if="!hasActiveTeam" v-translate>
              (current)
            </span>
          </v-list-tile-title>

          <v-list-tile-sub-title>
            <span v-translate>Personal</span>
          </v-list-tile-sub-title>
        </v-list-tile-content>
      </v-list-tile>

      <template v-for="teamUser in teams ? availableTeamUsers : []">
        <v-divider :key="'div:' + teamUser.uuid" />
        <v-list-tile
          :key="'tile:' + teamUser.uuid"
          avatar
          class="team-header"
          @click.prevent="switchTeam(teamUser)"
        >
          <v-list-tile-avatar>
            <i
              :style="`color: ${teamUser.team.primary_color};`"
              class="icon icon--companies icon--25"
              :class="{
                'icon--companies': !teamUser.team.sandbox,
                'icon--sandbox': teamUser.team.sandbox
              }"
              aria-hidden="true"
            ></i>
          </v-list-tile-avatar>

          <v-list-tile-content>
            <v-list-tile-title>
              {{ teamUser.team.name }}

              <span
                v-if="hasActiveTeam && teamUser.uuid === user.team_user.uuid"
                v-translate
              >
                (current)
              </span>
            </v-list-tile-title>

            <v-list-tile-sub-title>
              {{
                $gettextInterpolate(
                  $ngettext(
                    '%{ count } member',
                    '%{ count } members',
                    teamUser.team.members.length
                  ),
                  { count: teamUser.team.members.length }
                )
              }}
            </v-list-tile-sub-title>
          </v-list-tile-content>
        </v-list-tile>
      </template>

      <template v-if="showHomeboxToggleInMenu && version">
        <v-divider key="div-new-version" />

        <v-list-tile key="new-version" avatar @click="flipVersion">
          <v-list-tile-avatar>
            <v-icon>loop</v-icon>
          </v-list-tile-avatar>

          <v-list-tile-content>
            <span>
              <translate>New Design</translate>
              <switch-icon :value="isVueHomebox" />
            </span>
          </v-list-tile-content>
        </v-list-tile>
      </template>

      <template v-if="support">
        <v-divider key="div-support" />

        <v-list-tile
          key="support"
          avatar
          :href="getPageUrlForLang('help_center')"
        >
          <v-list-tile-avatar>
            <help-icon />
          </v-list-tile-avatar>

          <v-list-tile-content>
            <translate>Support</translate>
          </v-list-tile-content>
        </v-list-tile>
      </template>

      <template v-for="item in sections ? menuItems : []">
        <v-divider :key="'div:' + item.icon" />

        <v-list-tile
          :key="item.icon"
          avatar
          :href="item.url"
          :class="{ 'primary--text': isActive(item.url) }"
          @click="menuActionClick(item.action)"
        >
          <v-list-tile-avatar>
            <i class="icon" :class="item.icon" aria-hidden="true"></i>
          </v-list-tile-avatar>

          <v-list-tile-content>
            <v-list-tile-title>{{ item.name }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </template>
    </v-list>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import SwitchIcon from '@/components/icons/Switch';
import HelpIcon from '@/components/document/HelpIcon';
import { ENABLE_BETA_HOMEBOX } from '@/utils/userTagNames';

export default {
  components: { SwitchIcon, HelpIcon },
  props: {
    teams: { type: Boolean, default: false },
    version: { type: Boolean, default: false },
    support: { type: Boolean, default: false },
    logout: { type: Boolean, default: false },
    sections: { type: Boolean, default: false }
  },
  computed: {
    ...mapGetters('conf', [
      'showHomeboxToggleInMenu',
      'isVueHomebox',
      'getPageUrlForLang'
    ]),
    ...mapGetters('users', ['user', 'availableTeamUsers', 'hasActiveTeam']),
    menuItems() {
      return [
        {
          url: '/#/account',
          name: this.$gettext('Account'),
          action: 'follow_link',
          icon: 'icon--user--25'
        },
        {
          url: '/#/documents',
          name: this.$gettext('Documents'),
          action: 'follow_link',
          icon: 'icon--documents--25'
        },
        {
          url: '/#/templates',
          name: this.$gettext('Templates'),
          action: 'follow_link',
          icon: 'icon--document-stack--25'
        },
        {
          url: '/#/billing',
          name: this.$gettext('Billing'),
          action: 'follow_link',
          icon: 'icon--creditcard--25'
        },
        {
          url: '/#/signatures',
          name: this.$gettext('Signatures'),
          action: 'follow_link',
          icon: 'icon--signature--25'
        },
        {
          url: '/#/teams',
          name: this.$gettext('Teams'),
          action: 'follow_link',
          icon: 'icon--companies--25'
        },
        {
          url: '/#/users',
          name: this.$gettext('Users'),
          action: 'follow_link',
          icon: 'icon--users'
        },
        this.$props.logout && {
          url: '',
          action: 'logout',
          name: this.$gettext('Logout'),
          icon: 'icon--lock'
        }
      ].filter(item => item);
    }
  },
  methods: {
    ...mapActions('users', ['switchTeam', 'setTag', 'unsetTag', 'logoutUser']),
    async flipVersion() {
      if (this.isVueHomebox) {
        await this.unsetTag(ENABLE_BETA_HOMEBOX);
      } else {
        await this.setTag(ENABLE_BETA_HOMEBOX);
      }

      location.reload();
    },
    isActive(url) {
      // don't move this import to module level,
      // since it would create circular dependency.
      // No, we can't replace this with `this.$router`,
      // not until we stop calling this code from
      // angular via `vue-component name`, which
      // somehow doesn't have it.
      const { default: router } = require('@/router');
      return router.currentRoute.path === url.slice(2);
    },
    async menuActionClick(action) {
      if (action === 'logout') {
        await this.logoutUser();
      }
    }
  }
};
</script>

<style scoped>
.v-card .v-list {
  padding-top: 0px;
  padding-bottom: 0px;
}

.v-card.menu {
  border-radius: 4px;
  border: 1px solid #ccc;
}

hr.v-divider {
  margin-top: 0px;
}

.v-list > .v-divider:first-child {
  display: none;
}
.v-list .header /deep/ .v-list__tile--link {
  height: 72px;
}

.v-list .header /deep/ .v-list__tile__title {
  font-weight: 600;
  color: #222222;
}

.v-list .team-header /deep/ .v-list__tile--link {
  height: 72px;
}

.v-list .team-header /deep/ .v-list__tile__title {
  font-weight: 600;
  color: #222222;
}
</style>
