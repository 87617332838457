import angular from 'angular';

/**
 * @ngdoc function
 * @name frontendApp.controller:MembersCtrl
 * @description
 * # MembersCtrl
 * Controller of the frontendApp
 */
angular.module('frontendApp').controller('MembersCtrl', [
  '$scope',
  '$rootScope',
  'UserService',
  'utils',
  'srMemberUpgradeModal',
  function($scope, $rootScope, UserService, utils, srMemberUpgradeModal) {
    $scope.email_to_invite = '';
    $scope.emailValid = true;
    $scope.loading = false;

    $scope.reloadUser = function() {
      $scope.loading = true;
      UserService.getUser().then(function(user) {
        $scope.loading = false;
      });
    };

    $scope.inviteMember = function() {
      UserService.waitForUser().then(function() {
        $scope.emailValid = utils.EMAIL_REGEXP.test($scope.email_to_invite);
        if ($scope.emailValid) {
          $scope.loading = true;
          UserService.inviteMember(
            $scope.email_to_invite,
            $scope.user.team_user.uuid
          ).then(function(response) {
            $scope.loading = false;
            if (response.data.should_upgrade) {
              srMemberUpgradeModal.activate();
            } else {
              $scope.email_to_invite = '';
              $scope.emailValid = true;
              $scope.inviteMemberForm.$setUntouched();
              $scope.inviteMemberForm.$setPristine();
              if (response.data.status === 'SUCCESS') {
                $scope.reloadUser();
              }
            }
          });
        }
      });
    };

    $scope.removeMember = function(member) {
      UserService.removeMember(member.uuid).then(function(response) {
        $scope.reloadUser();
      });
    };
  }
]);
