<template>
  <a
    ref="link"
    :href="href"
    class="icon"
    :class="{ [provider.provider]: true, expanded: expanded }"
    :data-provider="provider.provider"
    data-test-id="social-login"
    v-on="$listeners"
  >
    <img :src="provider.icon" :alt="`${provider.name} Login`" />
    <template v-if="expanded">
      <translate
        v-if="register"
        key="sign-up-button"
        :translate-params="{ socialProviderName: provider.name }"
      >
        Sign up with %{ socialProviderName }
      </translate>
      <translate
        v-else
        key="login-button"
        :translate-params="{ socialProviderName: provider.name }"
      >
        Login with %{ socialProviderName }
      </translate>
    </template>
  </a>
</template>
<script>
import { providerUrl } from '@/utils/socialProviders';

export default {
  props: {
    next: { type: String, default: '/' },
    provider: { type: Object, required: true },
    expanded: { type: Boolean, default: false },
    register: { type: Boolean, default: false }
  },
  computed: {
    href() {
      return providerUrl(this.provider, this.next);
    }
  },
  methods: {
    click() {
      location.assign(this.href);
    }
  }
};
</script>
<style scoped lang="scss">
$social-image-margin: 8px;
a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 41px;
  height: 41px;
  border-radius: 50%;
  background-color: #e9eaf0;
  img {
    height: auto;
    width: auto;
    max-height: 18px;
    max-width: 18px;
    filter: grayscale(100);
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
  &.expanded,
  &.expanded:active {
    text-decoration: none;
    width: auto;
    border-radius: 21px;
    border: solid 0.5px #747474;
    background-color: #fff;
    padding: 13px 16px;
    color: #000;
    font-weight: 400;
    img {
      margin-right: $social-image-margin;
    }
  }
  &.google {
    border: solid 0.5px #747474;
    background-color: #fff;
    img {
      filter: none;
    }
  }
}
.rtl a.expanded img,
.rtl a.expanded:active img {
  margin-right: 0;
  margin-left: $social-image-margin;
}
</style>
