import angular from 'angular';
/**
 * @ngdoc directive
 * @name frontendApp.directive:colorPicker
 * @description
 * # docPage
 */
angular.module('frontendApp').component('colorPicker', {
  template: require('../../views/directives/color-picker.html'),
  controllerAs: 'ctrl',
  bindings: {
    onColorChange: '&',
    availableColors: '='
  },
  controller: [
    '$scope',
    function($scope) {
      var ctrl = this;

      // this will be replaced by availableColors watcher
      ctrl.selectedColor = '#000001';
      ctrl.availableColorArray = [ctrl.selectedColor];

      $scope.$watch('ctrl.selectedColor', function(color) {
        ctrl.onColorChange({ color });
      });

      $scope.$watch('ctrl.availableColors', function(value) {
        if (!value) return;

        const colors = value.split(',');
        ctrl.availableColorArray = colors;

        if (colors && colors.length) {
          if (colors.indexOf(ctrl.selectedColor) === -1) {
            ctrl.selectedColor = colors[0];
          }
        }
      });
    }
  ]
});
