import angular from 'angular';

/**
 * @ngdoc function
 * @name frontendApp.controller:TeamsCtrl
 * @description
 * # TeamsCtrl
 * Controller of the frontendApp
 */
angular.module('frontendApp').controller('TeamsCtrl', [
  '$scope',
  '$rootScope',
  '$route',
  '$filter',
  'UserService',
  'srUploadLogoModal',
  '$location',
  function(
    $scope,
    $rootScope,
    $route,
    $filter,
    UserService,
    srUploadLogoModal,
    $location
  ) {
    $scope.vueTeams = Boolean(
      $route.current && $route.current.params.new_teams !== 'no'
    );
    $scope.openUploadModal = srUploadLogoModal.activate;
    $scope.$filter = $filter;
    $scope.showAdvance = false;
    $scope.status = {
      checkingDomain: false,
      subDomainChecked: false,
      subDomainAvail: false
    };

    $scope.checkSubDomainAvail = function(team) {
      $scope.status.checkingDomain = true;
      UserService.checkSubDomain(team).then(function(response) {
        $scope.status.checkingDomain = false;
        $scope.status.subDomainAvail = response.data.available;
        $scope.status.subDomainChecked = true;
        //        $scope.teamSettingsForm.subdomain.$setValidity('valid',$scope.status.subDomainAvail);
      });
    };

    $scope.resetColor = function() {
      $scope.user.team_user.team.primary_color = '#366cd8';
      $scope.updateColors();
    };

    $scope.updateColors = function() {
      $rootScope.primary_color = $scope.user.team_user.team.primary_color;
      UserService.setColorTemplate($rootScope.primary_color);
    };

    $scope.updateTeam = function(team) {
      team.is_new = false;
      if (
        $scope.user.team_user.api_settings &&
        angular.isDefined(
          $scope.user.team_user.api_settings.events_callback_url
        )
      ) {
        team.events_callback_url =
          $scope.user.team_user.api_settings.events_callback_url;
      }
      UserService.createUpdateTeam(team).then(function() {});
    };

    $scope.deleteTeam = function(team) {
      UserService.deleteTeam(team).then(function(response) {
        $rootScope.team_delete_after = response.data.delete_after;
      });
    };

    $scope.cancelDeleteTeam = function(team) {
      UserService.cancelDeleteTeam(team).then(function(response) {
        $rootScope.team_delete_after = response.data.delete_after;
      });
    };

    $scope.createApiToken = function(token_name) {
      UserService.createApiToken({
        subdomain: $scope.user.team_user.team.subdomain,
        name: token_name
      }).then(function(response) {
        if (response.data.token) {
          $scope.user.team_user.api_settings.tokens.push(response.data.token);
        }
      });
    };

    $scope.deleteApiToken = function(token) {
      var tokens = $scope.user.team_user.api_settings.tokens;
      UserService.deleteApiToken({
        subdomain: $scope.user.team_user.team.subdomain,
        key: token.key
      }).then(function(response) {
        tokens.splice(tokens.indexOf(token), 1);
      });
    };

    $scope.updateApiSettings = function() {
      var team = $scope.user.team_user.team;
      $scope.updateTeam(team);
    };

    $scope.createTeam = function(team) {
      var team_saving = angular.copy(team);
      team_saving.is_new = true;
      team_saving.sandbox = $scope.onSandbox();
      var perm = team_saving.sandbox ? 'sandbox' : 'pro';

      UserService.hasPermOrModal(perm, 'create_team', function() {
        UserService.createUpdateTeam(team_saving).then(function(response) {
          if (response.data.status === 'ERROR') {
            return;
          }
          UserService.getUser().then(function(user) {
            var team_user = $scope.$filter('filter')(
              user.available_team_users,
              function(_team_user) {
                return _team_user.team.subdomain === team_saving.subdomain;
              },
              true
            )[0];
            UserService.switchTeam(team_user);
          });
        });
      });
    };

    $scope.convertToProductionTeam = function() {
      UserService.hasPermOrModal(
        'pro',
        'convert_team_to_production',
        function() {
          var team = $scope.user.team_user.team;
          team.sandbox = false;
          $scope.updateTeam(team);
        }
      );
    };

    $scope.switchAccounts = function() {
      document.querySelector('#account-menu').click();
    };

    $scope.toggleAdvanced = function() {
      $scope.showAdvanced = !$scope.showAdvanced;
    };

    $scope.onSandbox = function() {
      return $location.search().sandbox === 'true';
    };

    $scope.force_signature_color_enabled = function(value) {
      if (!$scope.user) return false;
      const team = $scope.user.team_user.team;

      // called as a setter
      if (angular.isDefined(value)) {
        team.default_force_signature_color = value ? '#366cd8' : null;
      }

      return !!team.default_force_signature_color;
    };

    $scope.resetSigColor = function() {
      // this will reset the color
      $scope.force_signature_color_enabled(true);
    };
  }
]);
