import angular from 'angular';
import _ from 'lodash';

/**
 * @ngdoc function
 * @name frontendApp.controller:DocCtrl
 * @description
 * # DocCtrl
 * Controller of the frontendApp
 */
angular.module('frontendApp').controller('DocStatusCtrl', [
  '$scope',
  '$route',
  'docService',
  '$location',
  '$rootScope',
  '$window',
  'UserService',
  'utils',
  'srLoginModal',
  '$vuex',

  function(
    $scope,
    $route,
    docService,
    $location,
    $rootScope,
    $window,
    UserService,
    utils,
    srLoginModal,
    $vuex
  ) {
    $scope.docService = docService;

    $scope.uuid = $route.current.params.uuid;
    $scope.loading = true;

    $scope.getDocument = function() {
      docService.getDocumentStatus($scope.uuid).then(function(response) {
        const data = response.data;

        if (data.redirect_url) {
          utils.navigateTo(data.redirect_url);
        }
        if (data.doc) {
          $scope.doc = data.doc;
        }
        if (data.logged_in === false) {
          let next = $scope.next;
          if (!next) {
            next = '/#' + $location.url(); // redirect to the current route
          }

          $vuex.dispatch('modals/showLoginModal', { next: next });
        }

        $scope.download_needs_verification =
          data.doc_authenticated !== undefined && !data.doc_authenticated;

        $scope.loading = false;

        if (data.doc && data.doc.processing) {
          setTimeout($scope.getDocument, 5000);
        }
      });
      $scope.getEmailLogs($scope.uuid);
    };

    UserService.waitForUser().then($scope.getDocument);

    $scope.softDeleteDocument = function() {
      $scope.loading = true;
      docService.softDeleteDocument($scope.doc.uuid).then(function() {
        $location.path('/documents');
        $scope.loading = false;
      });
    };

    $scope.cancelDocument = function() {
      $scope.loading = true;
      docService.cancelDocument($scope.doc.uuid).then(function() {
        $scope.getDocument();
      });
    };

    $scope.deleteReminders = function() {
      $scope.loading = true;
      docService.deleteReminders($scope.doc.uuid).then(function() {
        $scope.getDocument();
      });
    };

    $scope.getDocumentAttachments = function(doc) {
      if (!doc || $scope.loading) {
        return [];
      }
      return doc.attachments || [];
    };

    $scope.getSignerAttachments = function(doc) {
      if (!doc || $scope.loading) {
        return [];
      }
      return _.flatten(
        _.map(doc.signrequest.signers, function(signer) {
          return signer.attachments || [];
        })
      );
    };

    $scope.docHasAttachments = function(doc) {
      if (!doc || $scope.loading) {
        return false;
      }
      return (
        $scope.getSignerAttachments(doc).length ||
        $scope.getDocumentAttachments(doc).length
      );
    };

    $scope.getDocUrl = function(base_url, doc) {
      var url = base_url;
      if (doc && doc.uuid) {
        url = url + doc.uuid + '/';
        if (doc.signer && doc.signer.uuid) {
          url = url + doc.signer.uuid + '/';
        }
        return url;
      } else {
        return '';
      }
    };

    $scope.hasAdminPermsForDoc = function() {
      // users that get this back from teh backend may administer the document
      return (
        $scope.doc && $scope.doc.signrequest && $scope.doc.signrequest.uuid
      );
    };

    $scope.sr_emails = [];
    $scope.reminders = [];
    $scope.getEmailLogs = function(doc_uuid) {
      docService.getEmailLogs(doc_uuid).then(function(resp) {
        if (resp.data && resp.data.emails) {
          $scope.sr_emails = resp.data.emails;
        }
        if (resp.data && resp.data.reminders) {
          $scope.reminders = resp.data.reminders;
        }
      });
    };

    $scope.copyToTemplate = function(doc_uuid) {
      if ($scope.loading) {
        return;
      }
      $scope.loading = true;

      UserService.hasPermOrModal('pro', 'copy_to_template', function() {
        docService.createTemplateFromDoc(doc_uuid).then(function(resp) {
          if (
            angular.isObject(resp.data.doc) &&
            angular.isObject(resp.data.doc.template) &&
            resp.data.doc.template.template_url
          ) {
            // open the template in a new tab
            $window.open(resp.data.doc.template.template_url, '_blank');
          }
          $scope.loading = false;

          docService.getTemplates().then(function(resp) {
            $rootScope.user.templates = resp.data.templates;
          });
        });
      });
    };
  }
]);
