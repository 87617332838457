<template>
  <loading-indicator class="box-loading-indicator" />
</template>
<script>
export default {};
</script>

<style scoped>
/deep/ .crawler {
  line-height: 1em;
}
.sr-button.sr-button--fancy:not([disabled])
  .box-loading-indicator
  /deep/
  .crawler
  div {
  background-color: white;
}
</style>
