var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items.length)?_c('ul',{staticClass:"file-list",attrs:{"role":"menu"},on:{"keydown":_vm.keyMenuKeyboard}},_vm._l((_vm.items),function(ref,idx){
var isSelected = ref.isSelected;
var localId = ref.localId;
var fileName = ref.fileName;
var fileType = ref.fileType;
var fileSize = ref.fileSize;
var fileReady = ref.fileReady;
return _c('li',{key:idx,staticClass:"file-list--item primary--text",class:{ 'file-list--active': isSelected },attrs:{"title":fileName,"role":"none"}},[(!isSelected)?_c('div',{staticClass:"file-list--bg"}):_vm._e(),_c('sr-button',{staticClass:"filename",attrs:{"role":"menuitem","sr-style":isSelected ? 'primary' : 'subtle'},on:{"click":function($event){return _vm.$emit('select', { localId: localId })}}},[_c('span',[_vm._v(_vm._s(fileName))])])],1)}),0):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }