<template>
  <v-dialog
    ref="modal"
    v-model="showModal"
    lazy
    max-width="500"
    :content-class="contentClass"
  >
    <v-form ref="form" v-model="formValid" @submit.prevent="handleUpgrade">
      <v-card
        role="dialog"
        :aria-hidden="!showModal"
        :aria-modal="showModal"
        aria-labelledby="terms-gmodal-title"
        :class="{
          mobile: $isMobile
        }"
      >
        <sr-button
          class="close"
          sr-style="text"
          data-test-id="close-member-upgrade-btn"
          @click="closeModal"
        >
          <v-icon
            aria-label="$gettext('Close confirmation dialog')"
            class="round"
          >
            close
          </v-icon>
        </sr-button>
        <v-card-title id="terms-gmodal-title" class="headline">
          <span v-translate>
            Upgrade Subscription
          </span>
        </v-card-title>
        <v-card-text>
          <p v-translate>
            In order to invite more members you need to upgrade your
            subscription.
          </p>
          <p>
            <translate>Number of licenses in your current plan:</translate>
            <strong>
              {{ userPermissions.quantity }} ({{ userPermissions.plan }})
            </strong>
            <span
              v-if="
                userPermissions.plan !== 'PRO' &&
                  userPermissions.quantity !== userPermissions.used_quantity
              "
            >
              <br />
              <span v-translate>Number of used licenses:</span>
              <strong>{{ userPermissions.used_quantity }}</strong>
            </span>
          </p>
          <p v-if="userPermissions.plan !== 'SME'" key="hasPRO" v-translate>
            In order to add more licences you need to upgrade to the Business
            plan.
          </p>
          <p v-if="userPermissions.plan === 'SME'" key="hasSME" v-translate>
            How many licenses would you like to add?
          </p>

          <sr-input
            v-if="userPermissions.plan === 'SME'"
            ref="add_num_licences"
            v-model="add_num_licences"
            name="add_num_licences"
            type="number"
            :label="$gettext('Licenses')"
            class="bordered-field"
            min="1"
            :rules="[fieldRequired]"
          />
        </v-card-text>
        <v-card-actions>
          <sr-button
            sr-style="cancel"
            :loading="isLoading"
            round
            @click="closeModal"
          >
            <translate>
              Cancel
            </translate>
          </sr-button>
          <sr-button
            ref="next"
            sr-style="fancy"
            :loading="isLoading"
            round
            data-test-id="submit-member-upgrade-btn"
            type="submit"
          >
            <translate>Next</translate>
          </sr-button>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import SrButton from '@/components/elements/SrButton';
import ModalMixin from '@/components/modals/ModalMixin';
import Screen from '@/mixins/Screen';
import SrInput from '@/components/elements/SrInput.vue';

export default {
  components: { SrButton, SrInput },
  mixins: [ModalMixin('memberUpgrade'), Screen],
  props: {
    contentClass: { type: String, default: '' }
  },
  data: function() {
    return {
      add_num_licences: 1,
      formValid: false
    };
  },
  computed: {
    ...mapGetters('users', ['user', 'userPermissions', 'hasPermission']),
    ...mapGetters('api', ['isLoading']),
    fieldRequired() {
      return value => (value ? true : this.$gettext('This field is required'));
    }
  },
  watch: {
    showModal(value, oldValue) {
      if (value) {
        if (
          this.userPermissions.used_quantity &&
          this.userPermissions.used_quantity > this.userPermissions.quantity
        ) {
          this.add_num_licences =
            this.userPermissions.used_quantity - this.userPermissions.quantity;
        }
        this.$nextTick(() => this.focusFirst());
      }
    }
  },
  methods: {
    ...mapActions('trackEvents', ['trackEvent']),
    ...mapActions('messages', ['addMessage']),
    ...mapActions('modals', ['showConfirmMemberUpgrade']),
    focusFirst() {
      if (this.userPermissions.plan === 'SME') {
        this.$refs.add_num_licences.focus();
      } else {
        this.$refs.next.focus();
      }
    },
    trackUpgradeEvent(event_name) {
      return this.trackEvent({
        action: 'Member Upgrade ' + event_name,
        category: 'Member Upgrade',
        params: {
          plan: this.userPermissions.plan,
          quantity: this.userPermissions.quantity + this.add_num_licences,
          used_quantity: this.userPermissions.used_quantity,
          add_num_licences: this.add_num_licences,
          is_payer: this.userPermissions.is_payer,
          paid_usage: this.hasPermission('pro')
        }
      });
    },
    handleUpgrade() {
      if (!this.formValid) {
        return false;
      }

      if (this.userPermissions.is_payer) {
        this.trackUpgradeEvent('Next');
        if (this.user.active_subscription.plan_type === 'PRO') {
          // we need to upgrade to SME
          this.showConfirmMemberUpgrade({ quantity: 4, plan: 'SME' });
        } else {
          this.showConfirmMemberUpgrade({
            quantity:
              Number(this.add_num_licences) +
              Number(this.userPermissions.quantity),
            plan: this.userPermissions.plan
          });
        }
      } else {
        this.trackUpgradeEvent('Payer Error');
        this.addMessage({
          type: 'error',
          msg: this.$gettext(
            'You need to be logged in with the account that manages this subscription.'
          )
        });
      }
      this.closeModal();
      return true;
    }
  }
};
</script>

<style scoped>
button.sr-button {
  font-size: 18px;
  padding: 0 3rem;
  margin: 0px;
  height: 4rem;
  width: calc(50% - 0.75rem);
}

>>> .v-dialog {
  border-radius: 25px;
}
.v-card {
  padding: 0 3rem;
}
.mobile.v-card {
  padding: 0 2rem;
}
.v-card__title {
  padding: 0;
  padding-top: 4rem;
  padding-bottom: 2rem;
  font-weight: 900;
  font-size: 24px;
  line-height: 1.33;
}
.v-card__text {
  font-size: 17px;
  padding: 0;
}
.v-card__text p {
  margin-top: 0;
  margin-bottom: 24px;
  line-height: 1.33;
}
.v-card__text label,
.v-card__text span.form--error {
  display: block;
  font-size: 16px;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.27px;
}
.v-card__text label a {
  text-decoration: underline;
}

.v-card__actions {
  padding: 0px;
  padding-top: 38px;
  padding-bottom: 36px;
  justify-content: space-between;
}
.v-btn-toggle .v-btn {
  opacity: 1;
  padding: 10px 20px;
}
.v-btn-toggle--selected,
.v-btn-toggle--selected .v-btn {
  box-shadow: unset;
}
.v-btn-toggle .v-btn:first-child {
  border-radius: 9px 0px 0 9px;
}
.v-btn-toggle .v-btn:last-child {
  border-radius: 0px 9px 9px 0px;
}

button.sr-button.close {
  font-size: 14px;
  position: absolute;
  min-width: 1em;
  width: 1em;
  height: 1em;
  top: 2rem;
  color: #111111;
  opacity: 0.8;
  margin: 0;
  padding: 0;
}
.ltr .close {
  right: 2rem;
}
.rtl .close {
  left: 2rem;
}

.bordered-field.sr-field {
  margin: 0 -3rem;
  padding: 0 3rem;
  outline-offset: -0.5rem !important;
}
.mobile .bordered-field.sr-field {
  margin: 0 -2rem;
  padding: 0 2rem;
}

.bordered-field.sr-field::before,
.bordered-field.sr-field::after {
  position: absolute;
  content: '';
  border-top: 1px solid #d6d6d6;
  opacity: 0.84;
  left: 0;
  right: 0;
  transform: scaleY(0.5);
}
.bordered-field.sr-field::after {
  top: 0;
}

.bordered-field.sr-field::before {
  bottom: 0;
}
</style>
