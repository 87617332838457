import angular from 'angular';
import _ from 'lodash';

/**
 * @ngdoc function
 * @name frontendApp.controller:TemplatesCtrl
 * @description
 * # TemplatesCtrl
 * Controller of the frontendApp
 */
angular.module('frontendApp').controller('TemplatesCtrl', [
  '$scope',
  '$route',
  '$window',
  'UserService',
  function($scope, $route, $window, UserService) {
    $scope.sfBulk = $route.current.params.sfbulk && true; // Salesforce bulksend

    UserService.waitForUser().then(function() {
      if ($scope.sfBulk) {
        // We need to redirect to salesforce OAuth if the user is not logged in
        var sfSocialAuth = _.find($scope.user.social_auth, function(item) {
          if (
            (item.backend === 'salesforce-oauth2' && !$scope.isSandbox) ||
            (item.backend === 'salesforce-oauth2-sandbox' && $scope.isSandbox)
          ) {
            if (angular.isDefined($route.current.params.userId)) {
              return item.uid.indexOf($route.current.params.userId) > -1;
            } else {
              return true;
            }
          }
          return false;
        });
        if (
          !$scope.user.logged_in ||
          !angular.isDefined(sfSocialAuth) ||
          sfSocialAuth === undefined
        ) {
          // we need a salesforce social user
          const sandbox = $scope.isSandbox ? '-sandbox' : '';
          const url = new URL(
            `/user/social/login/salesforce-oauth2${sandbox}/`
          );
          url.searchParams.set('next', `/${$window.location.hash}`);
          $window.location.assign(url);
        }
      }
    });
  }
]);
