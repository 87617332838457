<template>
  <div class="box-toggle-field">
    <box-checkbox v-model="show" :label="label" :help="help" />
    <slot v-if="show" />
  </div>
</template>
<script>
import BoxCheckbox from '@/components/elements/BoxCheckbox';

export default {
  components: { BoxCheckbox },
  model: {
    props: 'value',
    event: 'change'
  },
  props: {
    help: { type: String, required: false, default: '' },
    value: { type: [String, Array], required: false, default: null },
    label: { type: String, required: true }
  },
  data() {
    return {
      show: Boolean(this.value)
    };
  },
  watch: {
    show(value) {
      if (!value) {
        this.$emit('change', null);
      }
    }
  }
};
</script>
<style scoped>
.box-toggle-field {
  padding: 24px;
  padding-top: 20px;
  padding-bottom: 14px;
  position: relative;
}
.box-toggle-field:not(:last-child)::after {
  position: absolute;
  bottom: 0;
  width: calc(100% - 24px - 24px);
  content: ' ';
  display: block;
  border-bottom: 1px solid #d8d8d8;
  transform: scaleY(0.5);
}
</style>
