import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{ref:"modal",attrs:{"lazy":"","max-width":"443","content-class":_vm.contentClass},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c(VForm,{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c(VCard,{class:{
        mobile: _vm.$isMobile
      },attrs:{"role":"dialog","aria-hidden":!_vm.showModal,"aria-modal":_vm.showModal,"aria-labelledby":"terms-gmodal-title"}},[_c('sr-button',{staticClass:"close",attrs:{"sr-style":"text","data-test-id":"close-password-btn"},on:{"click":_vm.closeModal}},[_c(VIcon,{staticClass:"round",attrs:{"aria-label":"$gettext('Close confirmation dialog')"}},[_vm._v("\n          close\n        ")])],1),_c(VCardTitle,{staticClass:"headline",attrs:{"id":"terms-gmodal-title"}},[_c('span',{directives:[{name:"translate",rawName:"v-translate"}],key:"enter"},[_vm._v("\n          Enter password\n        ")])]),_c(VCardText,[_c('p',[_c('translate',[_vm._v("This document is password protected")]),_c('a',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"learn-more-left-margin",attrs:{"href":_vm.getPageUrlForLang('password_protected'),"target":"_blank","rel":"noopener noreferrer"}},[_vm._v("\n            Learn more\n          ")])],1)]),_c('sr-input',{ref:"password",staticClass:"password-input",attrs:{"name":"password","label":_vm.$gettext('Enter document password'),"rules":[_vm.fieldRequired]},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c(VCardActions,[_c('sr-button',{attrs:{"sr-style":"cancel","loading":_vm.isLoading,"round":"","data-test-id":"cancel-password-btn"},on:{"click":_vm.closeModal}},[_c('translate',[_vm._v("\n            Cancel\n          ")])],1),_c('sr-button',{attrs:{"loading":_vm.isLoading,"sr-style":"fancy","round":"","data-test-id":"verify-password-btn","type":"submit"}},[_c('translate',[_vm._v("Verify")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }