<template>
  <v-card-text class="v-card__text__buttons">
    <sr-button
      id="submit-button"
      sr-style="fancy"
      round
      type="submit"
      :disabled="!formValid"
    >
      <translate>Forward document</translate>
    </sr-button>
  </v-card-text>
</template>
<script>
import SrButton from '@/components/elements/SrButton.vue';
export default {
  components: {
    SrButton
  },
  props: {
    formValid: { type: Boolean, default: false }
  }
};
</script>
