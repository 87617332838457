<template>
  <v-layout>
    <v-flex x12>
      <h2 v-translate class="mb-3 mt-0">Details</h2>
      <v-text-field
        v-model="userToEdit.first_name"
        :label="$gettext('First name')"
      ></v-text-field>
      <v-text-field
        v-model="userToEdit.last_name"
        :label="$gettext('Last name')"
      ></v-text-field>
      <v-layout align-center>
        <v-text-field
          v-model="userToEdit.email"
          :label="$gettext('Email')"
          disabled
          class="mr-2"
        ></v-text-field>
        <a v-translate href="#" @click.prevent="openChangeEmailModal">
          Change email
        </a>
      </v-layout>
      <TeamSelector
        v-model="userToEdit.default_team"
        :label="$gettext('Default team')"
        :hint="
          $gettext(
            'The default team is automatically activated when logging in. After logging in you can still manually switch to other teams. The default team will also be used for integrations like Google Drive.'
          )
        "
      ></TeamSelector>
      <v-layout>
        <SrButton class="mt-2 ml-0" @click="updateUser(userToEdit)">
          <translate>Save</translate>
        </SrButton>
      </v-layout>
      <v-layout justify-end>
        <SrButton sr-style="danger" @click="confirmAccountDeletion">
          <translate>Delete account</translate>
        </SrButton>
      </v-layout>
      <ChangeEmailModal></ChangeEmailModal>
    </v-flex>
  </v-layout>
</template>

<script>
import permissions from '@/mixins/permissions';
import TeamSelector from '@/components/elements/TeamSelector';
import { mapGetters, mapActions } from 'vuex';
import ChangeEmailModal from '@/components/modals/ChangeEmailModal';

export default {
  components: { ChangeEmailModal, TeamSelector },
  mixins: [permissions],
  data: function() {
    return {
      userToEdit: {}
    };
  },
  computed: {
    ...mapGetters('users', ['user'])
  },
  watch: {
    user(user) {
      this.userToEdit = { ...user };
    }
  },
  mounted() {
    this.userToEdit = { ...this.user };
  },
  methods: {
    ...mapActions('users', ['updateUser', 'prepareForDeleteUser']),
    ...mapActions('modals', ['openChangeEmailModal', 'openConfirmationModal']),
    async confirmAccountDeletion() {
      if (
        await this.openConfirmationModal({
          title: this.$gettext('Are you sure you want to delete your account?'),
          body: this.$gettext('This cannot be undone.'),
          confirmationButtonText: this.$gettext('Delete account'),
          confirmationButtonColor: 'error'
        })
      ) {
        this.prepareForDeleteUser();
      }
    }
  }
};
</script>
