var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('sr-autocomplete',_vm._g(_vm._b({ref:"autocomplete",staticClass:"sr-combobox",class:{
    'sr-combobox--with-input': _vm.enableManual
  },attrs:{"items":_vm.items,"value":_vm.value,"enable-manual":_vm.enableManual},on:{"commit":_vm.handleCommit,"clear":_vm.handleClear},scopedSlots:_vm._u([{key:"value",fn:function(){return [(_vm.$slots.value)?_vm._t("value"):_c('div',{staticClass:"sr-autocomplete--value"},[_vm._v("\n      "+_vm._s(_vm.selectedText)+"\n    ")])]},proxy:true},{key:"append-outer",fn:function(){return [_vm._t("append-outer")]},proxy:true},{key:"append-inner",fn:function(){return [(_vm.chips)?_c('sr-combobox-chip',{attrs:{"removable":_vm.removable,"value":_vm.value},on:{"delete":_vm.handleDelete},scopedSlots:_vm._u([{key:"chip",fn:function(ref){
  var item = ref.item;
return [_vm._t("chip",null,{"item":item})]}}],null,true)}):_vm._e(),_vm._t("append-inner")]},proxy:true},{key:"additem",fn:function(ref){
  var value = ref.value;
return [_vm._t("additem",null,{"value":value})]}},{key:"item",fn:function(ref){
  var item = ref.item;
return [_vm._t("item",null,{"item":item})]}}],null,true)},'sr-autocomplete',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }