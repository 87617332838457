<template>
  <v-layout wrap>
    <v-flex xs12>
      <p>
        <span v-translate>Document storage is part of our paid plans.</span>
        <Space></Space>
        <span v-translate>
          SignRequest automatically deletes finished documents.
        </span>
        <Space></Space>
        <span v-translate>
          Upgrade now for the full SignRequest experience.
        </span>
      </p>
    </v-flex>
    <v-flex xs12 class="text-xs-center">
      <v-btn color="success" @click="upgradeForDocumentStorage">
        <translate>START FREE TRIAL</translate>
      </v-btn>
    </v-flex>
  </v-layout>
</template>
<script>
import { mapActions } from 'vuex';
import Space from './Space';

export default {
  name: 'DocumentUpgradeForStorageMessage',
  components: { Space },
  methods: {
    ...mapActions('modals', ['showSubscribeModal']),
    upgradeForDocumentStorage() {
      this.showSubscribeModal({ isSubscribeOnly: false });
    }
  }
};
</script>
