import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileAvatar } from 'vuetify/lib/components/VList';
import { VListTileContent } from 'vuetify/lib/components/VList';
import { VListTileSubTitle } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"menu menu-nav"},[_c(VList,{attrs:{"dense":""}},[(_vm.user.email && _vm.teams)?_c(VListTile,{key:'user-gravatar',staticClass:"header",attrs:{"avatar":""},on:{"click":function($event){$event.preventDefault();return _vm.switchTeam(undefined)}}},[_c(VListTileAvatar,[_c('v-gravatar',{attrs:{"email":_vm.user.email,"default-img":"mm","size":48}})],1),_c(VListTileContent,[_c(VListTileTitle,[_vm._v("\n          "+_vm._s(_vm.user.full_name)+"\n          "),(!_vm.hasActiveTeam)?_c('span',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("\n            (current)\n          ")]):_vm._e()]),_c(VListTileSubTitle,[_c('span',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("Personal")])])],1)],1):_vm._e(),_vm._l((_vm.teams ? _vm.availableTeamUsers : []),function(teamUser){return [_c(VDivider,{key:'div:' + teamUser.uuid}),_c(VListTile,{key:'tile:' + teamUser.uuid,staticClass:"team-header",attrs:{"avatar":""},on:{"click":function($event){$event.preventDefault();return _vm.switchTeam(teamUser)}}},[_c(VListTileAvatar,[_c('i',{staticClass:"icon icon--companies icon--25",class:{
              'icon--companies': !teamUser.team.sandbox,
              'icon--sandbox': teamUser.team.sandbox
            },style:(("color: " + (teamUser.team.primary_color) + ";")),attrs:{"aria-hidden":"true"}})]),_c(VListTileContent,[_c(VListTileTitle,[_vm._v("\n            "+_vm._s(teamUser.team.name)+"\n\n            "),(_vm.hasActiveTeam && teamUser.uuid === _vm.user.team_user.uuid)?_c('span',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("\n              (current)\n            ")]):_vm._e()]),_c(VListTileSubTitle,[_vm._v("\n            "+_vm._s(_vm.$gettextInterpolate(
                _vm.$ngettext(
                  '%{ count } member',
                  '%{ count } members',
                  teamUser.team.members.length
                ),
                { count: teamUser.team.members.length }
              ))+"\n          ")])],1)],1)]}),(_vm.showHomeboxToggleInMenu && _vm.version)?[_c(VDivider,{key:"div-new-version"}),_c(VListTile,{key:"new-version",attrs:{"avatar":""},on:{"click":_vm.flipVersion}},[_c(VListTileAvatar,[_c(VIcon,[_vm._v("loop")])],1),_c(VListTileContent,[_c('span',[_c('translate',[_vm._v("New Design")]),_c('switch-icon',{attrs:{"value":_vm.isVueHomebox}})],1)])],1)]:_vm._e(),(_vm.support)?[_c(VDivider,{key:"div-support"}),_c(VListTile,{key:"support",attrs:{"avatar":"","href":_vm.getPageUrlForLang('help_center')}},[_c(VListTileAvatar,[_c('help-icon')],1),_c(VListTileContent,[_c('translate',[_vm._v("Support")])],1)],1)]:_vm._e(),_vm._l((_vm.sections ? _vm.menuItems : []),function(item){return [_c(VDivider,{key:'div:' + item.icon}),_c(VListTile,{key:item.icon,class:{ 'primary--text': _vm.isActive(item.url) },attrs:{"avatar":"","href":item.url},on:{"click":function($event){return _vm.menuActionClick(item.action)}}},[_c(VListTileAvatar,[_c('i',{staticClass:"icon",class:item.icon,attrs:{"aria-hidden":"true"}})]),_c(VListTileContent,[_c(VListTileTitle,[_vm._v(_vm._s(item.name))])],1)],1)]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }