import angular from 'angular';
import $ from 'jquery';

/**
 * @ngdoc directive
 * @name frontendApp.directive:srMatchheight
 * @description
 * # srMatchheight
 */
angular.module('frontendApp').directive('srMatchheight', [
  '$timeout',
  function($timeout) {
    return {
      // Restrict it to be an attribute in this case
      restrict: 'A',
      scope: true,
      // responsible for registering DOM listeners as well as updating the DOM
      link: function(scope, element, attrs) {
        $('[sr-matchheight="' + attrs.srMatchheight + '"]').matchHeight({
          byRow: true, // byRow is true or false to enable row detection
          property: 'height', // property is the CSS property name to set (e.g. 'height' or 'min-height')
          target: $(attrs.srMatchheight), // target is an optional element to use instead of the element with maximum height
          remove: false // remove is true or false to remove previous bindings instead of applying new ones
        });
      }
    };
  }
]);
