<template>
  <notification v-bind="props" />
</template>
<script>
export default {
  props: {
    type: { type: String, default: 'error' },
    title: { type: String, default: '' },
    body: { type: String, default: 'Oops, something went wrong.' }
  },
  computed: {
    genericBody() {
      if (this.title) {
        return this.title;
      }

      switch (this.type) {
        case 'success':
          return this.$gettext('Success');
        case 'warning':
          return this.$gettext('Warning');
        case 'error':
          return this.$gettext('Error');
        default:
          return this.$gettext('Alert');
      }
    },
    boxType() {
      return {
        success: 'info',
        error: 'error',
        warning: 'warn'
      }[this.type];
    },
    props() {
      return {
        type: this.boxType,
        onClose: this.handleClick,
        children: this.renderChildren
      };
    }
  },
  methods: {
    renderChildren(h) {
      return h('div', { className: 'notification--content' }, [
        h('span', { key: 'body' }, [this.body || this.genericBody])
      ]);
    },
    handleClick() {
      this.$emit('click');
    }
  }
};
</script>
<style scoped>
div /deep/ .notification {
  width: 100%;
  min-width: 100%;
  margin: 10px auto;
  padding: 10px 20px;
}
div /deep/ .notification > svg {
  margin-right: 15px;
}
div /deep/ .notification--content {
  flex-grow: 1;
}
</style>
