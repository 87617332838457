import { getAngularDeps } from '@/plugins/angular-injector';
import {
  PLAN_DISPLAY_NAMES,
  PLAN_BENEFITS,
  STRIPE_ERRORS
} from '@/components/subscribe/constants';

let Stripe = null;

function initialState() {
  return {
    paymentMethod: 'card',
    paymentMethodDetails: {},
    subscribing: false,
    subscriptionDetails: {},
    subscriptionLoaded: false,
    subscriptionSucceeded: false,
    subscriptionError: null,
    billingDetails: {},
    countryOptions: {},
    stripeToken: '',
    stripeTokenError: null,
    stripeElement: {},
    stripeElementsInputCompleted: false,
    countrySelected: false,
    sourceData: {},
    stripe: {},
    plan: {},
    useOld: false,
    userAlreadyOnPlan: false
  };
}

const empty = Object.freeze({});

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    setStripe(state, stripeObj) {
      state.stripe = stripeObj;
    },
    setPlan(state, plan) {
      state.plan = plan;
    },
    setPaymentMethod(state, result) {
      state.paymentMethod = result;
    },
    setPaymentMethodDetails(state, result) {
      state.paymentMethodDetails = result;
    },
    setSubscriptionError(state, error) {
      state.subscriptionError = error;
    },
    setSubscriptionDetails(state, result) {
      state.subscriptionDetails = result;
    },
    setSubscriptionLoaded(state, loaded) {
      state.subscriptionLoaded = loaded;
    },
    setSubscribing(state, subscribing) {
      state.subscribing = subscribing;
    },
    setBillingDetails(state, details) {
      state.billingDetails = details;
    },
    setCountryOptions(state, options) {
      state.countryOptions = options;
    },
    setStripeElement(state, element) {
      state.stripeElement = element;
    },
    setStripeElementsInputCompleted(state, completed) {
      state.stripeElementsInputCompleted = completed;
    },
    setSourceData(state, data) {
      state.sourceData = data;
    },
    setStripeToken(state, token) {
      state.stripeToken = token;
    },
    setStripeTokenError(state, error) {
      state.stripeTokenError = error;
    },
    setSubscriptionSucceeded(state, finished) {
      state.subscriptionSucceeded = finished;
    },
    setUseOld(state, useOld) {
      state.useOld = useOld;
    },
    setUserAlreadyOnPlan(state, userAlreadyOnPlan) {
      state.userAlreadyOnPlan = userAlreadyOnPlan;
    },
    resetState(state) {
      Object.assign(state, initialState());
    }
  },
  getters: {
    subscriptionDetails(state) {
      return state.subscriptionDetails || empty;
    },
    billingDetails(state) {
      return state.billingDetails || empty;
    },
    cardValid(state) {
      return (
        state.subscriptionDetails.details.card_details.card_is_valid || false
      );
    },
    cardLastFourDigits(state) {
      return state.subscriptionDetails.details.card_details.card_last_4 || '';
    },
    cardExpires(state) {
      return state.subscriptionDetails.details.card_details.card_expires || '';
    },
    cardBrand(state) {
      return (
        state.subscriptionDetails.details.card_details.card_kind || 'unknown'
      );
    },
    bankAccountLastFourDigits(state) {
      return state.subscriptionDetails.details.bank_account_iban_last_4 || '';
    },
    quantity(state) {
      return state.subscriptionDetails.details.quantity || 1;
    },
    stripeTokenErrorMessage(state) {
      return state.stripeTokenError
        ? state.stripeTokenError.code in STRIPE_ERRORS
          ? STRIPE_ERRORS[state.stripeTokenError.code]
          : STRIPE_ERRORS['default']
        : '';
    },
    subscriptionErrorMessage(state) {
      return state.subscriptionError
        ? state.subscriptionError.code &&
          state.subscriptionError.code in STRIPE_ERRORS
          ? STRIPE_ERRORS[state.subscriptionError.code]
          : state.subscriptionError.message || STRIPE_ERRORS['default']
        : '';
    },
    isUpdateView(state, getters) {
      return (
        (state.subscriptionDetails &&
          ((getters.cardValid && state.paymentMethod === 'card') ||
            (getters.bankAccountLastFourDigits &&
              state.paymentMethod === 'sepa'))) ||
        false
      );
    },
    hasPaymentMethodData(getters) {
      return getters.cardLastFourDigits || getters.bankAccountLastFourDigits;
    },
    isSubmitEnabled(state, getters) {
      return (
        state.stripeElementsInputCompleted ||
        (getters.isUpdateView && state.useOld)
      );
    },
    trialEndsOn(state) {
      let endDate = new Date();
      return state.subscriptionDetails.details.trial_ends_on
        ? new Date(state.subscriptionDetails.details.trial_ends_on)
        : new Date(endDate.setDate(endDate.getDate() + state.plan.trialDays));
    },
    trialEligible(state, getters) {
      return getters.trialEndsOn > new Date();
    },
    trialDays(state) {
      const timeDiff =
        new Date(state.subscriptionDetails.details.trial_ends_on).getTime() -
        new Date(state.subscriptionDetails.details.trial_started_on).getTime();
      return Math.ceil(timeDiff / (1000 * 3600 * 24));
    },
    planUpgrade(state) {
      return state.userAlreadyOnPlan &&
        state.subscriptionDetails.details.plan_type === 'PRO' &&
        state.plan.name === 'SME'
        ? true
        : false;
    }
  },
  actions: {
    async initSubscription(
      { dispatch, rootGetters, commit },
      { plan, interval, trial_days }
    ) {
      commit('resetState');

      await Promise.all([
        dispatch('initStripe'),
        dispatch('getSubscription'),
        dispatch('getBillingDetails'),
        dispatch('getCountryOptions'),
        dispatch('conf/getPlanCurrency', {}, { root: true })
      ]);
      await dispatch('initPlan', {
        currency: rootGetters['conf/planCurrency'],
        pricing: rootGetters['conf/planPricing'],
        plan: plan,
        interval: interval,
        trial_days: trial_days
      });
      commit('setSubscriptionLoaded', true);
    },
    async initStripe({ commit, dispatch }) {
      if (window.Stripe === undefined) {
        setTimeout(() => dispatch('initStripe', 100));
        return;
      } else {
        commit('setStripe', window.Stripe);
      }
    },
    async getStripe({ state }) {
      if (Stripe === null) {
        const { ENV } = await getAngularDeps('ENV');
        Stripe = state.stripe(ENV.stripe_publishable_key);
      }
      return Stripe;
    },
    async initPlan(
      { commit, dispatch, state },
      { currency, pricing, plan, interval, trial_days }
    ) {
      let planName = plan;
      let planInterval = interval;
      // only 14 days no matter the url
      const trialDays = 14;
      if (!(planName === 'PRO' || planName === 'SME')) {
        planName = 'PRO';
      }
      // default to monthly plans
      if (!(planInterval === 'year' || planInterval === 'month')) {
        planInterval = 'month';
      }
      // net new customer only monthly plans
      if (state.subscriptionDetails.details.only_monthly_plan) {
        planInterval = 'month';
      }

      const currencyCode = currency.code || 'EUR';
      const planPrice = pricing[currencyCode][planName][planInterval];
      const planObject = {
        name: planName,
        interval: planInterval,
        currency: currency,
        price: planPrice,
        trialDays: trialDays,
        displayName: PLAN_DISPLAY_NAMES[planName],
        benefits: PLAN_BENEFITS[planName]
      };
      commit('setPlan', planObject);
      dispatch('checkPlan');
    },
    checkPlan({ state, commit }) {
      if (
        state.subscriptionDetails.details.has_valid_subscription &&
        state.plan.name === state.subscriptionDetails.details.plan_type &&
        (state.plan.interval === state.subscriptionDetails.details.interval ||
          state.subscriptionDetails.details.interval === 'year')
      ) {
        commit('setUserAlreadyOnPlan', true);
      } else {
        commit('setUserAlreadyOnPlan', false);
      }
    },
    async updatePlanPricing({ state, commit, dispatch, rootGetters }) {
      let planObject = Object.assign({}, state.plan);
      await dispatch('conf/getPlanCurrency', {}, { root: true });
      planObject.currency = rootGetters['conf/planCurrency'];
      commit('setPlan', planObject);
    },
    async getStripeToken({ commit, state, dispatch }) {
      const stripe = await dispatch('getStripe');

      return await stripe.createToken(state.stripeElement, state.sourceData);
    },
    async updatePaymentMethod({ commit, dispatch, state }) {
      let data = { stripe_token: state.stripeToken };
      if (state.paymentMethod === 'sepa') {
        const new_bank_account = {
          bank_account_holder_name: state.sourceData.owner.name
        };
        data.new_bank_account = new_bank_account;
      }
      const url =
        state.paymentMethod === 'card'
          ? '/orders/billing/update-card'
          : '/orders/billing/update-bank-account';
      const result = await dispatch(
        'api/makeRequest',
        {
          url,
          method: 'POST',
          data: data,
          silent: true,
          silentError: true
        },
        { root: true }
      );
      commit('setPaymentMethodDetails', result);
      if (result.status === 'ERROR') {
        commit('setSubscriptionError', result);
      }
    },
    async subscribe({ state, commit, dispatch, getters }) {
      let scaChecked;
      commit('setSubscribing', true);
      if (state.useOld && getters.isUpdateView) {
        await dispatch('setSubscription', { plan: state.plan });
        scaChecked = await dispatch('checkSCA', {
          data: state.subscriptionDetails
        });
        if (scaChecked && state.subscriptionDetails.status === 'SUCCESS') {
          commit('setSubscriptionSucceeded', true);
        }
      } else {
        const result = await dispatch('getStripeToken');

        if (!result.error) {
          commit('setStripeToken', result.token.id);
          await dispatch('updatePaymentMethod');
          if (state.paymentMethodDetails.status === 'SUCCESS') {
            scaChecked = await dispatch('checkSCA', {
              data: state.paymentMethodDetails
            });

            if (scaChecked) {
              await dispatch('setSubscription', { plan: state.plan });
              scaChecked = await dispatch('checkSCA', {
                data: state.subscriptionDetails
              });

              if (
                scaChecked &&
                state.subscriptionDetails.status === 'SUCCESS'
              ) {
                commit('setSubscriptionSucceeded', true);
              }
            }
          }
        } else {
          commit('setStripeTokenError', result.error);
        }
      }
      commit('setSubscribing', false);
    },
    async checkSCA({ commit, dispatch }, { data: data }) {
      const intentToken =
        data.payment_intent_client_secret || data.setup_intent_client_secret;
      if (intentToken) {
        const stripe = await dispatch('getStripe');
        const response = await dispatch('handleSCA', {
          data: data,
          stripe: stripe
        });
        if (response.error) {
          commit('setSubscriptionError', response.error);
          await dispatch('cancelSubscription');
          return false;
        }
      }
      return true;
    },
    async handleSCA({ commit, dispatch, state }, { data, stripe }) {
      const stripeHandler = data.payment_intent_client_secret
        ? stripe.handleCardPayment
        : stripe.handleCardSetup;
      const intentToken =
        data.payment_intent_client_secret || data.setup_intent_client_secret;
      return await stripeHandler(intentToken);
    },
    async getSubscription({ state, commit, dispatch }) {
      const url = '/orders/billing/get-or-set-subscription-details';
      const result = await dispatch(
        'api/makeRequest',
        { url, method: 'GET' },
        { root: true }
      );
      commit('setSubscriptionDetails', result);
      dispatch('initPaymentMethod');
    },
    async getBillingDetails({ commit, dispatch }) {
      const url = '/orders/billing/get-or-set-billing-details';
      const result = await dispatch(
        'api/makeRequest',
        { url, method: 'GET' },
        { root: true }
      );
      commit('setBillingDetails', result);
    },
    async getCountryOptions({ commit, dispatch }) {
      const url = '/orders/billing/get-country-options';
      const result = await dispatch(
        'api/makeRequest',
        { url, method: 'GET' },
        { root: true }
      );
      commit('setCountryOptions', result);
    },
    async getPlanOptions(
      { dispatch },
      { quote_plan, quote_quantity, interval }
    ) {
      let params = {};
      if (quote_plan && quote_quantity) {
        params = {
          quote: quote_plan,
          quantity: quote_quantity,
          interval: interval
        };
      }
      const url = '/orders/billing/get-plan-options';
      return dispatch(
        'api/makeRequest',
        { url, method: 'GET', data: params },
        { root: true }
      );
    },
    async setSubscription({ commit, dispatch, getters }, { plan }) {
      const url = '/orders/billing/get-or-set-subscription-details';
      const data = {
        plan_type: plan.name,
        quantity: plan.quantity || getters.quantity || 1,
        interval: plan.interval,
        trial_days: plan.trialDays
      };
      const result = await dispatch(
        'api/makeRequest',
        { url, method: 'POST', data: data, silent: true, silentError: true },
        { root: true }
      );
      commit('setSubscriptionDetails', result);
      if (result.status === 'ERROR') {
        commit('setSubscriptionError', result);
      }
    },
    async setBillingDetails({ commit, dispatch, state }, { billingDetails }) {
      let billDetails = Object.assign(
        {},
        state.billingDetails.details,
        billingDetails
      );
      const url = '/orders/billing/get-or-set-billing-details';
      const result = await dispatch(
        'api/makeRequest',
        {
          url,
          method: 'POST',
          data: billDetails,
          silent: true,
          silentError: true
        },
        { root: true }
      );
      commit('setBillingDetails', result);
    },
    async cancelSubscription({ dispatch, getters }) {
      const planObject = {
        name: 'FREE',
        interval: 'month',
        quantity: getters.quantity || 1
      };
      await dispatch('setSubscription', { plan: planObject });
      return true;
    },
    initPaymentMethod({ commit, state }) {
      const subscription = state.subscriptionDetails;
      if (
        subscription.details.card_details.card_is_valid ||
        !subscription.details.bank_account_iban_last_4
      ) {
        commit('setPaymentMethod', 'card');
      } else {
        commit('setPaymentMethod', 'sepa');
      }
    },
    changeStripeElement({ commit }, { element }) {
      commit('setStripeElement', element);
    },
    changeStripeElementsInputCompleted({ commit }, { completed }) {
      commit('setStripeElementsInputCompleted', completed);
    },
    changeSourceData({ commit }, { data }) {
      commit('setSourceData', data);
    },
    changePaymentMethod({ commit }, { method }) {
      commit('setPaymentMethod', method);
      commit('setStripeElementsInputCompleted', false);
    },
    changeUseOld({ commit }, { useOld }) {
      commit('setUseOld', useOld);
    },
    resetToken({ commit }) {
      commit('setStripeToken', '');
      commit('setStripeElement', null);
    },
    resetErrors({ commit }) {
      commit('setStripeTokenError', null);
      commit('setSubscriptionError', null);
    },
    changeOpenSubscribe({ commit }, { openSub }) {
      commit('setOpenSubscribe', openSub);
    },
    async showSubscribeOrLoginPage(
      { dispatch, rootGetters },
      { next, to, plan, interval, trial_days }
    ) {
      await dispatch('users/getUser', { forceReload: true }, { root: true });
      if (!rootGetters['users/userLoggedIn']) {
        next({ name: 'register', params: { nextUrl: to.fullPath } });
      } else {
        next();
      }
    }
  }
};
