import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';
import { EMAIL_REGEXP } from '@/utils/format';
import {
  parseObjectFromEncodedUrlParam,
  makeEncodedUrlParam,
  redirectFilter
} from '@/utils/encodedParams';

/**
 * @ngdoc service
 * @name frontendApp.utils
 * @description
 * # utils
 * Service in the frontendApp.
 */
angular.module('frontendApp').service('utils', [
  'messageService',
  '$window',
  '$location',
  '$document',
  '$q',
  '$httpParamSerializer',
  '$rootScope',
  'deviceService',
  '$route',
  'gettextCatalog',
  function(
    messageService,
    $window,
    $location,
    $document,
    $q,
    $httpParamSerializer,
    $rootScope,
    deviceService,
    $route,
    gettextCatalog
  ) {
    var service = this;

    // this.EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[\w-]+[\w\-]*(?:\.\w{2,})+$/i;

    this.EMAIL_REGEXP = EMAIL_REGEXP;

    this.ALLOWED_MIME_TYPES = [
      // for attachment uploads we need to allow audio etc. also (we don't care what is it)
      'audio/*',
      // 'video/*',
      // 'image/*',
      'text/*',
      'application/msword*',
      'application/vnd.ms-*',
      'application/vnd.oasis.opendocument.*',
      'application/pdf'
    ];

    // partially taken from: http://stackoverflow.com/questions/19605150/regex-for-password-must-be-contain-at-least-8-characters-least-1-number-and-bot
    this.PASSWORD_STRENGTH_REGEX = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;

    // check if a text is rtl
    // taken from: https://stackoverflow.com/questions/13731909/how-to-detect-that-text-typed-in-text-area-is-rtl
    this.RTL_REGEX = /[\u0591-\u07FF]/;

    // keep in sync with backend/signrequest/settings.py, these are only allowed for SignRequests
    this.ALLOWED_IMAGE_FILE_EXTENSIONS = [
      'jpg',
      'jpeg',
      'png',
      'tiff',
      'eps',
      'ico',
      'gif',
      'bmp'
    ];

    this.ALLOWED_FILE_EXTENSIONS = this.ALLOWED_IMAGE_FILE_EXTENSIONS.concat([
      'txt',
      'md',
      'pdf',
      'html',
      'htm',
      'rtf',
      'doc',
      'docx',
      'xls',
      'xlsx',
      'ppt',
      'pps',
      'ppsx',
      'pptx',
      'dot',
      'dotx',
      'xlt',
      'xltx',
      'odt',
      'oth',
      'odg',
      'odp',
      'ods',
      'odi',
      'oxt',
      'csv'
    ]);

    this.FILE_INPUT_ACCEPT_STRING = this.ALLOWED_FILE_EXTENSIONS.map(function(
      i
    ) {
      return '.' + i;
    })
      .concat(this.ALLOWED_MIME_TYPES)
      .join(',');

    if (deviceService.isMobileDevice.iOS()) {
      // there is a bug is iOS when 'image/*' or 'video/*' is added in the accept string this overwrites all others that match
      this.FILE_INPUT_ACCEPT_STRING = _.filter(
        this.FILE_INPUT_ACCEPT_STRING,
        function(f) {
          return !~['image/*', 'video/*'].indexOf(f);
        }
      );
    }

    this.IMAGE_FILE_INPUT_ACCEPT_STRING = this.ALLOWED_IMAGE_FILE_EXTENSIONS.map(
      function(i) {
        return '.' + i;
      }
    ).join(',');

    this.parseValues = function(value, delimiter) {
      if (angular.isArray(value)) {
        return value;
      }
      if (!value) {
        return [];
      }
      delimiter = delimiter || ',';
      return String(value).split(delimiter);
    };

    this.stripHashPrefixOfPath = function(path) {
      if (path) {
        if (path.substring(0, 2) === '/#') {
          path = path.substring(2);
        } else if (path.substring(0, 1) === '#') {
          path = path.substring(1);
        }
        if (path.substring(0, 1) !== '/') {
          // always start with a slash
          path = '/' + path;
        }
      }
      return path;
    };

    this.stripQueryParams = function(path) {
      if (path) {
        return path.split('?')[0];
      }
      return path;
    };

    this.pathIsAppPath = function(path) {
      if (path) {
        path = service.stripHashPrefixOfPath(path);
        path = service.stripQueryParams(path);
        var match = false;
        angular.forEach($route.routes, function(config, route) {
          if (route && config && config.regexp) {
            if (config.regexp.test(path)) {
              match = true;
            }
          }
        });
        return match;
      }
      return false;
    };

    this.navigateTo = function(url) {
      if (url.substring(0, 4) === 'http') {
        // it's some external page we want to go to next
        $window.location = url;
      } else {
        if (service.pathIsAppPath(url)) {
          // it's our own page (/#/account for example)
          url = service.stripHashPrefixOfPath(url);
          if (url.indexOf('?') > -1) {
            // if we have a querystring we need to use .url else path will just not match
            $location.url(url);
          } else {
            $location.path(url);
          }
        } else {
          $window.location = url;
        }
      }
    };

    this.closeWindow = function(onError) {
      onError = onError || function() {};
      if ($window.close) {
        try {
          $window.close();
        } catch (e) {
          onError();
        }
      } else {
        onError();
      }
    };

    this.addUrlParam = function(url, name, value) {
      // add a parameter to a url, checks if we need the ?
      url = url.indexOf('?') > -1 ? url + '&' : url + '?';
      url = url + name + '=' + encodeURIComponent(value);
      return url;
    };

    this.urlParamsFromObject = function(object) {
      return $httpParamSerializer(object);
    };

    this.loadLibrary = function(library_src_url) {
      var deferred = $q.defer();

      var doc = $document[0],
        script = doc.createElement('script');
      script.src = library_src_url;

      script.onload = function() {
        deferred.resolve();
      };

      script.onreadystatechange = function() {
        var rs = this.readyState;
        if (rs === 'loaded' || rs === 'complete') {
          deferred.resolve();
        }
      };

      script.onerror = function() {
        deferred.reject(new Error('Unable to load checkout.js'));
      };

      var container = doc.getElementsByTagName('head')[0];
      container.appendChild(script);

      return deferred.promise;
    };

    this.longCookieExpires = function() {
      var expires = new Date();
      expires.setFullYear(expires.getFullYear() + 20);
      return expires;
    };

    this.requireLibrary = function(library_src_url, global_name) {
      var deferred = $q.defer();
      if ($window.hasOwnProperty(global_name)) {
        deferred.resolve($window[global_name]);
      } else {
        service.loadLibrary(library_src_url).then(
          function() {
            deferred.resolve($window[global_name]);
          },
          function(err) {
            deferred.reject(err);
          }
        );
      }
      return deferred.promise;
    };

    this.parseUrl = function(url) {
      // parser.protocol; // => "http:"
      // parser.host;     // => "example.com:3000"
      // parser.hostname; // => "example.com"
      // parser.port;     // => "3000"
      // parser.pathname; // => "/pathname/"
      // parser.hash;     // => "#hash"
      // parser.search;   // => "?search=test"
      // parser.origin;   // => "http://example.com:3000"
      var parser = document.createElement('a');
      parser.href = url;
      return parser;
    };

    this.getHTML = function(element) {
      element = $(element);
      var html = element.html();
      if (!html) {
        // needed for firefox to parse inner html from script tag
        // see: http://stackoverflow.com/questions/6638625/script-tag-with-type-text-html-and-src-loaded-but-ignored-by-jquery
        // WTF!
        html = $('<div />')
          .append(element.contents().clone())
          .html();
      }
      return html;
    };

    this.getAndRemoveParamFromRoute = function(param_name) {
      // get a param value and remove it from the route
      if ($route.current && $route.current.params) {
        var param_value = $route.current.params[param_name];
        $location.search(param_name, null).replace();
        return param_value;
      }
    };

    this.replaceSpacesFromUrlParam = function(param) {
      // manually adding ?email=michael+test@signrequest.com in a browser will be changed by the browser to:
      // ?email=michael%20test@signrequest.com altough the user wanted actually a plus
      if (param) {
        param = param.replace(' ', '+');
      }
      return param;
    };

    this.escapeJsonControlChars = function(str) {
      // When we get json strings of which the values can contain special chars JSON.parse might fail
      // this function can be used to escape special chars before sending is to JSON.parse
      if (!str) return str;
      return str
        .replace('\r\n', '\\n') // Carriage return newline to normal Newline
        .replace('\n', '\\n') // Newline
        .replace('\r', '\\r') // Carriage return
        .replace('\f', '\\f') // Form feed
        .replace('\b', '\\b') // Backspace
        .replace('\t', '\\t'); // Tab
    };

    this.parseObjectFromEncodedUrlParam = parseObjectFromEncodedUrlParam;
    this.makeEncodedUrlParam = makeEncodedUrlParam;

    this.isElementInViewport = function(el) {
      var rect = el.getBoundingClientRect();
      return (
        rect.bottom > 0 &&
        rect.right > 0 &&
        rect.left <
          (window.innerWidth || document.documentElement.clientWidth) &&
        rect.top < (window.innerHeight || document.documentElement.clientHeight)
      );
    };

    this.isLanguageEnglish = function() {
      return _.startsWith(gettextCatalog.currentLanguage, 'en-');
    };

    this.replaceHostname = function replaceHostname(urlString, hostname) {
      const url = new URL(urlString);
      url.hostname = hostname;
      return url.toString();
    };

    this.redirectFilter = redirectFilter;

    const UUID_RE = /^[A-Za-z0-9-]+$/;
    this.ensureUUID = function ensureUUID(value) {
      if (value && !UUID_RE.test(value)) {
        throw new Error('Malformed uuid value');
      }
      return value;
    };

    const JWT_RE = /^[A-Za-z0-9-_=\.]+$/;
    this.ensureJWT = function ensureJWT(value) {
      if (value && !JWT_RE.test(value)) {
        throw new Error('Malformed token value');
      }
      return value;
    };

    this.parseIntParam = function parseIntParam(value) {
      let parsed = parseInt(value);
      if (isNaN(parsed)) return 0;
      return parsed;
    };

    this.isRTLText = function textIsRTL(text) {
      if (!text) return false;
      return this.RTL_REGEX.test(text);
    };
  }
]);
