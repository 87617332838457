<template>
  <svg viewBox="0 0 120 45" xmlns="http://www.w3.org/2000/svg">
    <text v-translate fill="currentColor" x="0" y="35">
      Signature
    </text>
  </svg>
</template>
<script>
export default {};
</script>
<style scoped>
text {
  font-size: 35px;
  font-family: 'Caveat', serif;
}
.lang-nl text,
.lang-fi text {
  font-size: 26px;
}
</style>
