import angular from 'angular';
/**
 * @author Slawomir Zytko <slawek@amsterdam-standard.pl>
 * @copyright Amsterdam Standard Sp. Z o.o.
 */
(function() {
  'use strict';

  angular
    .module('frontendApp')
    .controller('FeedbackModalCtrl', FeedbackModalCtrl);

  FeedbackModalCtrl.$inject = [
    '$rootScope',
    '$scope',
    'feedbackService',
    'messageService',
    '$timeout',
    'close',
    'gettextCatalog',
    'feedbackModalService'
  ];

  function FeedbackModalCtrl(
    $rootScope,
    $scope,
    FeedbackService,
    messageService,
    $timeout,
    close,
    gettextCatalog,
    feedbackModal
  ) {
    var notificationTimeout = 3000;
    $scope.close = feedbackModal.deactivate;
    $scope.feedback = {
      contact: angular.isDefined($rootScope.user) ? $rootScope.user.email : ''
    };
    $scope.errors = {};

    var feedbackService = new FeedbackService($scope.feedback);

    /**
     * Sends feedback
     */
    $scope.send = function() {
      try {
        $scope.errors = {};
        //puts sending promise into scope
        $scope.sendingFeedbackPromise = feedbackService.send();

        $scope.sendingFeedbackPromise.then(
          function(response) {
            $scope.feedback = {};
            messageService.add(
              'success',
              gettextCatalog.getString('Thanks for helping us!'),
              notificationTimeout
            );
            $scope.close();
          },
          function(response) {
            messageService.handleError({}, notificationTimeout);
          }
        );
      } catch (e) {
        $scope.errors = e.errors;
      }
    };
  }
})();
