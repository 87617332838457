import angular from 'angular';
import _ from 'lodash';
import $ from 'jquery';

/**
 * @ngdoc function
 * @name frontendApp.controller:UserCtrl
 * @description
 * # UserCtrl
 * Controller of the frontendApp
 */
angular.module('frontendApp').controller('UserCtrl', [
  '$scope',
  '$rootScope',
  '$route',
  '$location',
  'srLoginModal',
  'srEnterPasswordModal',
  'UserService',
  'srVerifyPhoneModal',
  '$vuex',
  'utils',
  function(
    $scope,
    $rootScope,
    $route,
    $location,
    srLoginModal,
    srEnterPasswordModal,
    UserService,
    srVerifyPhoneModal,
    $vuex,
    utils
  ) {
    $scope = $rootScope;

    $scope.isOwner = false;
    $scope.user_delete_after = false;

    $scope.processUser = function(callback) {
      $scope.loading = true;
      $scope.signer_uuid = $route.current
        ? $route.current.params.signer_uuid
        : null;
      UserService.getUser($scope.signer_uuid).then(function(user) {
        $scope.loading = false;
        if (angular.isFunction(callback)) {
          callback();
        }
      });
    };

    $scope.refreshUserForSigner = function() {
      // used to refresh the user on the doc page when we have a logged in user and we're signing for an in
      // person signer
      $scope.processUser();
    };

    $scope.getUserAfterRouteChange = function() {
      var unwatch = $scope.$on('$routeChangeSuccess', function() {
        $scope.processUser(function() {
          if ($scope.user && !$scope.user.logged_in) {
            // /#/?login and /#/?register should open the popups
            if ($route.current) {
              var loginData = {
                hideClose: $route.current.params.no_close,
                next: utils.getAndRemoveParamFromRoute('next')
              };
              if ($route.current.params.register) {
                $scope.openRegisterModal(loginData);
              } else if ($route.current.params.login) {
                $scope.openLoginModal(loginData);
              }
            }
          }
        });
        unwatch();
      });
    };

    $scope.getUserAfterRouteChange();

    $scope.openLoginModal = function(options = {}) {
      $route.current.params.register = false;
      $route.current.params.login = true;
      const next = '/#' + $location.url();
      return $vuex.dispatch('modals/showLoginModal', { next, ...options });
    };

    $scope.openRegisterModal = function(options = {}) {
      $route.current.params.register = true;
      $route.current.params.login = false;
      const next = '/#' + $location.url();
      return $vuex.dispatch('modals/showLoginModal', {
        next,
        ...options,
        doRegister: true
      });
    };

    $scope.openPasswordModal = function(request) {
      const signer_uuid =
        $route.current.params.signer_uuid || $scope.signer_uuid;
      const doc_uuid = $route.current.params.uuid;

      srEnterPasswordModal.activate(
        {},
        {
          signer_uuid: signer_uuid,
          doc_uuid: doc_uuid
        }
      );
    };

    $scope.openVerifyPhoneModal = function(phone_number) {
      srVerifyPhoneModal.activate(
        {},
        {
          finishCallback: $route.reload,
          signer_uuid: $scope.signer_uuid,
          signer_verify_phone_number: phone_number
        }
      );
    };
  }
]);
