import Vue from 'vue';
import { store } from './store';

Vue.filter('date', function(value) {
  if (!value) return '';
  const lang = store.getters['conf/languageCode'];
  const date = value instanceof Date ? value : new Date(value);

  return formatDate(date, lang);
});

Vue.filter('datetime', function(value) {
  if (!value) return '';
  const lang = store.getters['conf/languageCode'];
  const date = value instanceof Date ? value : new Date(value);

  return `${formatDate(date, lang)} ${formatTime(date, lang)}`;
});

function formatTime(date, lang) {
  return date.toLocaleTimeString(lang, {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
  });
}

function formatDate(date, lang) {
  return date.toLocaleDateString(lang, {
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  });
}
