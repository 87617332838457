import flatten from 'lodash/flatten';
import groupBy from 'lodash/groupBy';
import { parseObjectFromEncodedUrlParam } from '@/utils/encodedParams';

function strBool(value) {
  return Boolean(value && value !== '0' && value !== 'false');
}

export function serializePlaceholders(
  placeholders,
  pageUUIDs,
  signers,
  { asTemplate } = {}
) {
  const serializedPlaceholders = placeholders
    .filter(placeholder => signers && signers[placeholder.signer_index])
    .map(placeholder => {
      const pageUUID = pageUUIDs[placeholder.pageNum - 1];
      return {
        page_uuid: pageUUID,
        is_placeholder: true,
        x_pos: placeholder.position.x,
        y_pos: placeholder.position.y,
        sig: {
          width: placeholder.position.width,
          height: placeholder.position.height
        },
        placeholder: {
          uuid: placeholder.uuid || null,
          type: placeholder.type,
          comment: placeholder.comment,
          data_uri: '',
          repeat_on_all: false,
          required: placeholder.required,
          external_id: placeholder.external_id || null,
          text: null,
          multiline: placeholder.multiline,
          prefill: placeholder.prefill,
          signer_email: '',
          signer_index: placeholder.signer_index
        }
      };
    })
    .map(placeholder => {
      const signer = signers && signers[placeholder.placeholder.signer_index];
      return asTemplate
        ? placeholder
        : {
            ...placeholder,
            placeholder: {
              ...placeholder.placeholder,
              signer_email: signer.email,
              signer_index: null
            }
          };
    });
  const pages = groupBy(serializedPlaceholders, 'page_uuid');
  return Object.entries(pages).map(([uuid, editors]) => ({
    uuid,
    editors
  }));
}

export function parsePlaceholders(pages, signers) {
  function findIndex(placeholder) {
    if (placeholder.signer_index === null) {
      const index = signers.findIndex(
        ({ email }) => email === placeholder.signer_email
      );
      return index < 0 ? null : index;
    } else {
      return placeholder.signer_index;
    }
  }
  return flatten(
    pages.map((page, pageIdx) =>
      flatten([page.sigs, page.placeholders]).map(placeholder => ({
        ...placeholder,
        pageNum: pageIdx + 1,
        pageUUID: page.uuid,
        id: placeholder.uuid,
        type: placeholder.type || 's',
        signer_index: findIndex(placeholder),
        data_uri: placeholder.data_uri || null,
        position: {
          x: placeholder.x_pos,
          y: placeholder.y_pos,
          width: placeholder.width || placeholder.width_p,
          height: placeholder.height || placeholder.height_p
        }
      }))
    )
  );
}

function parseSignrequestForm(doc, sr) {
  return {
    auto_expire_days: doc.auto_expire_days || null,
    message: sr.message || null,
    subject: sr.subject || null,
    send_reminders: sr.send_reminders || null
  };
}

export function parseSignrequest(doc) {
  const signers = (doc.signrequest && doc.signrequest.signers) || [];
  const placeholders = Object.freeze(parsePlaceholders(doc.pages, signers));
  const pageUUIDs = Object.freeze(doc.pages.map(page => page.uuid));
  const esignDisclosure = doc.signrequest && doc.signrequest.esign_disclosure;

  const created = doc.created;
  const shortId = doc.short_id;
  const isTemplate = Boolean(doc.template_url);
  const publicLink = doc.public_url || null;
  const boxFileId = doc.box_file_id || null;
  const boxFileVersionId = doc.box_file_version_id || null;
  const boxFolderId = doc.box_folder_id || null;
  const form = parseSignrequestForm(doc, doc.signrequest || {});
  return {
    ...doc,
    form,
    isTemplate,
    publicLink,
    placeholders,
    pageUUIDs,
    signers,
    esignDisclosure,
    created,
    shortId,
    boxFileId,
    boxFileVersionId,
    boxFolderId
  };
}

export function parseUrlApi(searchParams) {
  const who = searchParams.get('who') || 'mo';
  const guessContactLevel = who === 'm' ? 'cc' : 'signature';
  const guessOwnLevel = who === 'o' ? 'cc' : 'signature';
  const defaultLevel = searchParams.get('default_level');
  const prefillTags = searchParams.get('prefill_tags');
  const signers = (searchParams.get('signers') || '')
    .split(',')
    .filter(part => part)
    .map(email =>
      Object.freeze({
        email,
        name: email,
        level: defaultLevel || guessContactLevel,
        verify_phone_number: '',
        order: 0
      })
    );
  const myself = Object.freeze({
    email: searchParams.get('from_email') || '',
    level: defaultLevel || guessOwnLevel,
    verify_phone_number: '',
    order: 0
  });
  return {
    signers: Object.freeze([myself, ...signers]),
    message: searchParams.get('message') || '',
    subject: searchParams.get('subject') || '',
    disable_attachments: strBool(searchParams.get('disable_attachments')),
    required_attachments: (searchParams.get('required_attachments') || '')
      .split(',')
      .filter(part => part)
      .map(name => ({ name })),
    prefill_tags: prefillTags ? parseObjectFromEncodedUrlParam(prefillTags) : []
  };
}
const signs = level => !(level === 'cc' || level === 'notify');

function serializeWho(signers) {
  const [myself, ...others] = signers;
  if (signs(myself.level) && others.map(signer => signer.level).some(signs)) {
    return 'mo';
  }
  if (signs(myself.level)) {
    return 'm';
  }

  return 'o';
}

function serializeSignrequest(
  { uuid, form, file, isProvisional, placeholders, pageUUIDs, integrationData },
  { asTemplate } = {}
) {
  const signers = form.signers
    .map(signer => ({
      label: signer.label,
      name: signer.name || signer.email,
      email: signer.email,
      level: signer.level,
      order: signer.order,
      verify_phone_number: signer.verify_phone_number,
      required_attachments: signer.required_attachments || [],
      password: signer.password || ''
    }))
    .map((signer, index) =>
      asTemplate ? { ...signer, signer_index: index } : signer
    );
  const who = serializeWho(signers);

  const doc = {
    name: form.name || file.name,
    uuid,
    auto_expire_days: form.auto_expire_days,
    pages: serializePlaceholders(placeholders, pageUUIDs, signers, {
      asTemplate
    }),
    parent_doc: null
  };

  const [firstEmail, ...restEmails] = signers.map(signer => signer.email);

  return {
    from_email: firstEmail,
    signers: restEmails.join(','),
    signer_options: signers,
    message: form.message,
    who,
    doc,
    prepare_doc: isProvisional,
    subject: form.subject,
    send_reminders: form.send_reminders,
    disable_attachments: form.disable_attachments,
    required_attachments: form.required_attachments,
    prefill_tags: form.prefill_tags,
    integration_data: integrationData,
    update_doc_data: true
  };
}

export function serializeSignrequests(
  signrequests,
  { integrationData, provisionalUUID }
) {
  const selectedToSave = provisionalUUID
    ? signrequests.filter(({ uuid }) => uuid === provisionalUUID)
    : signrequests;

  const [{ uuid: parentUUID, form: parentForm }] = selectedToSave;
  const [firstSR, ...extraSRs] = selectedToSave.map(
    ({ uuid, form, file, pageUUIDs, placeholders }, idx) =>
      serializeSignrequest({
        uuid,
        form: form.copy ? { ...parentForm, name: form.name } : form,
        file,
        isProvisional: Boolean(provisionalUUID),
        integrationData,
        placeholders,
        pageUUIDs
      })
  );
  return {
    ...firstSR,
    doc: {
      ...firstSR.doc,
      extra_docs: extraSRs.map(
        ({ doc, signers: _signers, signer_options, ...iterSr }) => ({
          ...doc,
          signrequest: {
            ...iterSr,
            signers: signer_options
          },
          parent_doc: parentUUID
        })
      )
    }
  };
}

export function serializeTemplate(signrequest) {
  const {
    doc,
    who,
    message,
    subject,
    send_reminders,
    signer_options: signers,
    ...rest
  } = serializeSignrequest(signrequest, { asTemplate: true });
  return {
    ...doc,
    ...rest,
    signrequest: { who, message, subject, signers, send_reminders }
  };
}
