<template>
  <sr-button
    v-if="hasFile === showWithFile && !hideHelpCenter"
    class="floating-help-button"
    :class="{ 'floating-help-button--fixed': !showWithFile }"
    sr-style="invert"
    circle
    :title="$gettext('Need help?')"
    target="_blank"
    rel="noopener noreferrer"
    :href="helpCenterUrl"
  >
    <svg
      width="19px"
      height="19px"
      viewBox="-1 0 19 19"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <text
          id="?"
          font-family="Lato-Regular, Lato"
          font-size="16"
          font-weight="normal"
          fill="#737986"
        >
          <tspan x="5.3" y="16">?</tspan>
        </text>
        <circle
          stroke="#747A86"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
          cx="8.5"
          cy="9.5"
          r="8.5"
        ></circle>
      </g>
    </svg>
  </sr-button>
</template>
<script>
import { mapGetters } from 'vuex';
import SrButton from '@/components/elements/SrButton';
import config from '@/config';

export default {
  components: { SrButton },
  props: {
    showWithFile: { type: Boolean, default: false }
  },
  computed: {
    ...mapGetters('conf', ['helpCenterUrl']),
    ...mapGetters('signrequest', ['hasFile']),
    hideHelpCenter() {
      return config.hideHelpCenter;
    }
  }
};
</script>
<style scoped>
.DocCtrl:not(.vue-sign) .floating-help-button {
  display: none;
}
.floating-help-button {
  font-size: 18px;
  box-shadow: -2px 6px 44px 10px rgba(0, 0, 0, 0.06);
}
.floating-help-button--fixed {
  position: fixed;
  bottom: 41px;
  z-index: 20;
}

.ltr .floating-help-button--fixed {
  right: 41px;
}
.rtl .floating-help-button--fixed {
  left: 41px;
}
svg {
  direction: ltr;
}
</style>
