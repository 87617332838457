<template>
  <div>
    <div class="sr-layout">
      <div class="sr-layout__item one-half">
        <h4 id="google-drive">Google Drive</h4>
      </div>
      <!--
       -->
      <div class="sr-layout__item one-half">
        <img
          class="pull-right margin-top"
          alt="Google Drive"
          width="200"
          src="../images/google/google-drive-logo-lockup.png"
        />
      </div>
    </div>

    <div class="sr-layout">
      <div class="sr-layout__item three-quarters palm-one-whole">
        <p v-if="!user.integrations.google_drive.enabled">
          <!-- TODO: Figure out why this string was being translated in Angular, but not here. -->
          <span v-translate>
            Enable the Google Drive integration to automatically store signed
            documents in Google Drive.
          </span>
          <Space></Space>
          <span v-translate>
            This will also make it possible to create a SignRequest from within
            Google Drive directly.
          </span>
        </p>
        <p v-show="user.integrations.google_drive.enabled">
          <span v-translate>The Google Drive integration is enabled.</span>
          <br />
          <span v-translate>
            Your signed documents and the signing log will be stored in a folder
            called 'SignRequest'.
          </span>
        </p>
      </div>
      <div class="sr-layout__item one-quarter palm-one-whole">
        <p v-if="!user.integrations.google_drive.enabled" class="align--center">
          <a
            class="lap-and-up-pull-right button button--google"
            href="/user/social/login/google-drive/?next=/%23/account%23google-drive"
          >
            Enable Google Drive
          </a>
        </p>
        <p v-if="user.integrations.google_drive.enabled" class="align--center">
          <button
            v-translate
            class="pull-right button button--small button--error"
            @click="deleteIntegration('google_drive')"
          >
            Remove integration
          </button>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import permissions from '@/mixins/permissions';
import { mapActions, mapGetters } from 'vuex';
import Space from '@/components/Space';

export default {
  components: { Space },
  mixins: [permissions],
  computed: mapGetters('users', ['user']),
  methods: {
    ...mapActions('users', ['deleteIntegration'])
  }
};
</script>
