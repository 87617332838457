<template>
  <div>
    <sr-autocomplete
      v-model="toAdd"
      name="signers"
      :label="$gettext('Add a recipient')"
      :placeholder="$gettext('Name or email address')"
      :items="items"
      :rules="[emailRule]"
      @commit="handleCommit"
      @update:searchInput="handleSearch"
    />
    <ul data-test-id="contact-list">
      <li v-for="(signer, index) in value" :key="index">
        <box-signer-entry
          :with-order="withOrder"
          :signer-index="index"
          :value="signer"
          @input="handleChangeSigner($event, index)"
          @select="handleSelect(index)"
        />
      </li>
    </ul>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import signerLabel from '@/utils/signerLabel';

import debouncePromise from '@/utils/debouncePromise';
import { patch, updateAtIdx, merge, append } from '@/utils/fp';
import { emptySigner } from '@/utils/addSigner';
import { validateUniqueEmail } from '@/utils/validateSigner';

import SrAutocomplete from '@/components/elements/SrAutocomplete';
import BoxSignerEntry from './BoxSignerEntry';
import { Level } from './SignerEntry';

const LOAD_DEBOUNCE = 300;

export default {
  components: {
    BoxSignerEntry,
    SrAutocomplete
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: { type: Array, required: true },
    isTemplate: { type: Boolean, default: false },
    withOrder: { type: Boolean, required: false, default: false }
  },
  data() {
    return {
      items: [],
      toAdd: null,
      findContacts: debouncePromise(this.findBoxContacts, LOAD_DEBOUNCE)
    };
  },
  computed: {
    ...mapGetters('signrequest', ['template']),
    contactsLevels() {
      return ['cc', 'signature', 'approve', 'notify', 'in_person'].map(
        level => ({
          value: level,
          text: Level.getLevelTitle.call(this, level)
        })
      );
    },
    emailRule() {
      return value => validateUniqueEmail(this.value, value);
    }
  },
  methods: {
    ...mapActions('users', ['findBoxContacts']),
    ...mapActions('modals', ['showContactEmailModal']),
    handleCommit({ value, text }) {
      const user =
        value && typeof value === 'object'
          ? value
          : { email: value, name: text };
      const newSigner = merge(emptySigner(this.template, this.value.length), {
        boxId: user.id || null,
        email: user.email,
        name: user.name
      });
      this.toAdd = null;
      this.items = [];
      this.$emit('change', append(this.value, newSigner));
    },
    handleChangeSigner(update, index) {
      this.$emit('change', updateAtIdx(this.value, index, patch(update)));
    },
    async handleSelect(index) {
      const signer = this.value[index];
      if (signer.email || this.$props.isTemplate) {
        this.$emit('select', index);
        return;
      }
      const label = signerLabel(signer, index);
      const email = await this.showContactEmailModal({ label });
      if (email) {
        this.$emit('change', updateAtIdx(this.value, index, patch({ email })));
      }
    },
    async handleSearch(value) {
      let results;
      try {
        results = await this.findContacts({ query: value });
      } catch (e) {
        return;
      }
      if (!Array.isArray(results)) {
        return;
      }
      const usedEmails = this.value.map(signer => signer.email);
      this.items = results
        .map(result => ({
          isExternalUser: result.isExternalUser,
          type: result.type,
          value: { email: result.email, id: result.id, name: result.name },
          text: `${result.name} <${result.email}>`
        }))
        .filter(item => !usedEmails.includes(item.value.email));
    }
  }
};
</script>
<style scoped>
ul {
  padding: 0;
  margin: 0;
}
li {
  width: 100%;
}
.signer-actions {
  display: flex;
}
.signer-actions > .sr-button {
  height: 34px;
  min-width: 72px;
  margin: 6px 0;
}
.signer-actions > .sr-button:nth-child(2) {
  margin-left: 10px;
  margin-right: 10px;
}

.sr-combobox.sr-field:focus-within {
  outline-style: none;
}
.signer-actions .sr-field {
  min-height: unset;
}

.signer-actions .sr-field /deep/ .sr-field--control {
  margin-top: 0 !important;
  border-style: none !important;
  padding: 0 !important;
}
.signer-actions /deep/ .sr-autocomplete--value,
.signer-actions /deep/ .sr-autocomplete--caret {
  font-size: 13px;
  top: -1px;
}
</style>
