<template>
  <AppWrapper>
    <CheckLogin>
      <v-container>
        <AccountDetails></AccountDetails>
        <h2 v-translate>Account Security</h2>
        <AccountTwoFactorAuth></AccountTwoFactorAuth>
        <AccountActivity></AccountActivity>
        <AccountDocumentSettings></AccountDocumentSettings>
        <AccountNotifications></AccountNotifications>
        <AccountIntegrations></AccountIntegrations>
        <AccountContacts></AccountContacts>
      </v-container>
    </CheckLogin>
  </AppWrapper>
</template>

<script>
import AccountDetails from '@/components/AccountDetails';
import permissions from '@/mixins/permissions';
import CheckLogin from '@/components/CheckLogin';
import AccountTwoFactorAuth from '@/components/AccountTwoFactorAuth';
import AccountActivity from '@/components/AccountActivity';
import AccountNotifications from '@/components/AccountNotifications';
import AccountIntegrations from '@/components/AccountIntegrations';
import AccountContacts from '@/components/AccountContacts';
import AccountDocumentSettings from '@/components/AccountDocumentSettings';

export default {
  components: {
    AccountIntegrations,
    AccountTwoFactorAuth,
    CheckLogin,
    AccountDetails,
    AccountActivity,
    AccountNotifications,
    AccountContacts,
    AccountDocumentSettings
  },
  mixins: [permissions]
};
</script>
