<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fill-rule="evenodd">
      <rect width="24" height="24" fill="#222" fill-opacity=".08" rx="12" />
      <path
        fill="#222"
        fill-rule="nonzero"
        d="M16.6692538,16.6672481 C17.1042894,16.2324377 17.1101682,15.4979606 16.6868903,15.0690259 L13.612247,12.0018495 L16.6868903,8.92879727 C17.1101682,8.50573845 17.1042894,7.77126134 16.6692538,7.33057507 C16.2283393,6.89576462 15.4934819,6.8898888 15.070204,7.31294762 L11.9955607,10.3859999 L8.92091726,7.3070718 C8.52115483,6.90751625 7.75690313,6.8898888 7.32186754,7.32469925 C6.88683196,7.76538552 6.90446854,8.5233659 7.30423097,8.92292145 L10.3847532,12.0018495 L7.30423097,15.0749018 C6.90446854,15.4803331 6.88683196,16.2383135 7.32186754,16.673124 C7.75690313,17.1079344 8.52115483,17.090307 8.92091726,16.6907514 L11.9955607,13.6176992 L15.070204,16.6848756 C15.4934819,17.1079344 16.2342182,17.1079344 16.6692538,16.6672481 Z"
      />
    </g>
  </svg>
</template>
<script>
export default {};
</script>
