const B64_RE = /^(?:[A-Za-z\d+\/]{4})*?(?:[A-Za-z\d+\/]{2}(?:==)?|[A-Za-z\d+\/]{3}=?)?$/;

function utfAtoB(input) {
  // atob can work with strings with whitespaces, even inside the encoded part,
  // but only \t, \n, \f, \r and ' ', which can be stripped.
  let string = String(input).replace(/[\t\n\f\r ]+/g, '');
  if (!B64_RE.test(string)) {
    throw new TypeError('Invalid base64 string');
  }

  const ret = Buffer.from(string, 'base64');
  return ret.toString();
}

export function makeEncodedUrlParam(param_val) {
  /* eslint no-empty: off */
  // this may be an object / array / string etc.
  // can be decoded with parseObjectFromEncodedUrlParam
  let ret = param_val;
  try {
    // encode to a string
    ret = JSON.stringify(ret);
  } catch (err) {}
  try {
    // base64 the string
    ret = btoa(ret);
  } catch (err) {}
  return ret;
}
export function parseObjectFromEncodedUrlParam(param_val) {
  /* eslint no-empty: off */
  // decodes output of makeEncodedUrlParam, (other external systems / integrations also make these params
  // such as salesforce and signrequest-js)
  let ret = param_val;
  try {
    // try to decode as base64 encoded val
    ret = utfAtoB(ret);
  } catch (err) {}
  try {
    // try to decode from json
    try {
      ret = JSON.parse(ret);
    } catch (err) {
      // failed, maybe due to special control chars from external user input, try replacing those
      ret = JSON.parse(this.escapeJsonControlChars(ret));
    }
    if (typeof param_val === 'string') {
      // we might have a double json encoded value:
      // https://stackoverflow.com/questions/710586/json-stringify-array-bizarreness-with-prototype-js/6611126#6611126
      // let's do it again
      ret = JSON.parse(ret);
    }
  } catch (err) {}
  return ret;
}

export function redirectFilter(urlString, ...whitelist) {
  if (!urlString || whitelist.indexOf(urlString) !== -1) {
    return urlString;
  }
  const isRelative = urlString.indexOf('/') === 0;
  const isHttp = urlString.indexOf('http:') === 0;
  const isHttps = urlString.indexOf('https:') === 0;
  if (!isHttp && !isHttps && !isRelative) {
    throw new Error('Security incident, illegal redirect protocol');
  }
  return urlString;
}
