<template>
  <div class="language--container">
    <div v-if="show" class="language--content">
      <v-icon class="primary--text icon--info-sign" role="button">
        info
      </v-icon>
      {{ ' ' }}
      <p>
        Would you rather enjoy SignRequest in Dutch?
        <strong>
          <a role="button" @click.prevent="handleSwitch">
            Click here 🇳🇱
          </a>
        </strong>
      </p>
      <v-icon class="round close" role="button" @click="handleDismiss">
        close
      </v-icon>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { getCookie, setCookie } from '@/utils/getJSONCookie';

const DISMISS_LANG = 'dissmiss_lang';

function speaksDutch() {
  const langs = Array.from(navigator.languages || []);
  return langs.includes('nl') || langs.includes('nl-be');
}

export default {
  data() {
    return {
      show: false
    };
  },
  computed: {
    ...mapGetters('users', ['userLoaded']),
    ...mapGetters('conf', ['languageCode'])
  },
  watch: {
    userLoaded(value, oldValue) {
      if (value && !oldValue) {
        this.checkIfShow();
      }
    },
    languageCode(value) {
      if (value === 'nl') {
        this.show = false;
      }
    }
  },
  methods: {
    ...mapActions('conf', ['switchLang']),
    checkIfShow() {
      if (getCookie(DISMISS_LANG) === 'yes') {
        return;
      }
      if (!speaksDutch()) {
        return;
      }
      if (this.$language.current === 'nl') {
        return;
      }
      this.show = true;
    },
    handleDismiss() {
      setCookie(DISMISS_LANG, 'yes');
      this.show = false;
    },
    async handleSwitch() {
      this.switchLang({ lang: 'nl' });
      this.show = false;
    }
  }
};
</script>
<style scoped>
.language--container {
  justify-content: center;
}
.language--content {
  padding: 1rem 1.9rem;
  margin: 0 auto;
  max-width: 1442px;
  color: #111111;
  background-color: #dbecfa;
  border: 1px solid #b8d9f6;

  display: none;
  align-items: flex-start;
}
body.MainCtrl .language--content {
  display: flex;
}

.language--content > p {
  margin: auto;
  padding: 0 0.5ch;
  flex-grow: 1;
}
.mobile .language--content {
  padding: 1rem 1rem;
}

.round {
  background-color: rgba(17, 17, 17, 0.1);
  color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  font-size: 16px;
  padding: 0.2em;
}

.v-icon {
  vertical-align: middle;
}
.icon--info-sign {
  font-size: 26px;
  transform: rotate(180deg);
}
</style>
