<template>
  <div class="h-container h-screen-root" v-on="$listeners">
    <div v-if="$slots.title" class="h-section h-section--title srtext--text">
      <slot name="title"></slot>
    </div>

    <hr v-if="$slots.title && titleDivider" class="h-divider" />

    <slot />

    <div class="h-section h-section--buttons">
      <div :class="buttonClasses">
        <slot name="buttons"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    titleDivider: { type: Boolean, default: true }
  },
  computed: {
    buttonClasses() {
      const buttonCount = this.$slots.buttons ? this.$slots.buttons.length : 0;
      return { buttons: true, 'buttons-multi': buttonCount };
    }
  }
};
</script>

<style>
.h-screen-root {
  height: 100%;
}

.h-container .h-section {
  padding: 0 25px;
  margin: 0 0px;
}
.signrequest-lib .h-container .h-section {
  padding: 0 24px;
}

.h-container .h-divider {
  margin: 0px;
  border-color: #d6d6d6;
  border-width: 0 0 1px 0;
  transform: scaleY(0.5);
}

.h-section .v-input__control > .v-input__slot::before,
.h-section .v-input__control > .v-input__slot::after {
  border-width: 0;
}
.h-section .v-text-field .v-input__append-inner {
  margin-top: unset;
  align-self: center;
}

.h-section .v-input--checkbox .v-input__control {
  width: 100%;
}
.h-section .v-input__control {
  font-weight: normal;
  color: inherit;
}
.h-section .v-input__control label {
  font-weight: normal;
  color: inherit;
  letter-spacing: 0.27px;
  opacity: 0.8;
}
.h-section .v-input__control label.v-label--active {
  color: #111 !important;
  transform: none;
  font-size: 13px;
  top: -24px;
}

.h-section .v-input__control input::placeholder {
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  opacity: 0.8;
}
</style>

<style scoped>
.h-container /deep/ .h-section {
  font-weight: 400;
}

.h-section .buttons {
  display: flex;
  padding: 1.5rem 0;
  vertical-align: middle;
  justify-content: space-around;
  align-items: center;
  font-weight: 600;
}

.h-section .buttons.buttons-multi {
  justify-content: space-evenly;
}

.h-section .buttons button[type='submit'] {
  min-width: 60%;
  min-height: 4rem;
  font-size: 1.285rem;
}

.h-section .buttons button.small,
.h-section .buttons button.small::before,
.h-section .buttons .small-placeholder {
  font-size: 16px;
  min-width: 20%;
  border-width: 0;
  background: none;
  flex-shrink: 1;
}
.form-fields .h-section /deep/ .sr-field:not(.signer-entry--checkbox) {
  outline: none !important;
}

.ay11-kb .form-fields /deep/ .h-section--focusable:focus-within,
.ay11-kb /deep/ .v-input [role='combobox']:focus-within,
.ay11-kb /deep/ .v-input:not(.v-select) input:focus,
.ay11-kb /deep/ .v-input.v-select:focus-within {
  outline-color: rgba(54, 108, 216, 0.7);
  outline-width: 4px;
  outline-style: solid;
  outline-offset: -4px;
}
/deep/ .v-input:not(.v-select) input:focus {
  outline-offset: -0.1rem;
}

.h-section--title {
  padding: 1.33rem 25px;
  font-size: 24px;
  font-weight: normal;
  position: relative;
}
.rtl .h-section--title i.arrow {
  transform: rotate(180deg);
}

.h-container {
  display: flex;
  flex-direction: column;
}
.h-section.h-section--buttons {
  justify-self: flex-end;
  margin-top: auto;
}
</style>
