<template>
  <div class="homepage-line">
    <div class="homepage-line--content">
      <div class="homepage-line--tile">
        <img
          class="homepage-line--pen"
          :src="require('./sign.svg')"
          aria-hidden="true"
          :alt="$gettext('Sign')"
        />
        <translate>Sign anywhere</translate>
      </div>
      <div class="homepage-line--tile">
        <img
          :src="require('./security.svg')"
          aria-hidden="true"
          :alt="$gettext('Security')"
        />
        <translate>Fast, secure and legally binding</translate>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.homepage-line {
  display: flex;
  background: linear-gradient(90deg, #2a43f8, #3196ec);
  justify-content: center;
  padding: 0 5%;
}

.homepage-line--content {
  flex-basis: 100%;
  max-width: 870px;
  min-height: 48px;

  font-size: 17.4px;
  font-weight: normal;
  line-height: 0.94;
  color: white;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.homepage-line--tile {
  display: flex;
}
.mobile .homepage-line--tile {
  flex-flow: column;
  margin-bottom: 16px;
}
.homepage-line--tile img {
  margin: 0 15px;
  height: 15px;
}
.homepage-line--tile span {
  white-space: nowrap;
}
.mobile .homepage-line--tile span {
  white-space: normal;
}

.mobile .homepage-line--tile span {
  font-size: 15px;
  font-weight: normal;
  line-height: 1.25;
  text-align: center;
  min-width: 33%;
}
.mobile .homepage-line--tile img {
  margin: 7px 0;
  height: 25px;
}
</style>
