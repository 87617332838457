<template>
  <div class="version--container">
    <div v-if="showVersionSwitch" class="version--content">
      <v-icon class="primary--text icon--info-sign" role="button">
        info
      </v-icon>
      <p>
        <strong v-translate>SignRequest is getting a new look!</strong>
        <span v-translate>
          Only a lucky few get to try it out and you are one of them. If you
          prefer the old version you can still switch back.
        </span>
        {{ ' ' }}
        <span v-if="isVueHomebox" key="1">
          <strong>
            <a
              v-translate
              href="#/?new=no"
              @click.prevent="handleSwitch(false)"
            >
              Switch to old Design
            </a>
          </strong>
        </span>
        <span v-else key="2">
          <span>Start exploring.</span>
          {{ ' ' }}
          <strong>
            <a
              v-translate
              href="#/?new=yes"
              @click.prevent="handleSwitch(true)"
            >
              Switch to new Design
            </a>
          </strong>
        </span>
      </p>

      <v-icon class="round close" role="button" @click="handleDismiss">
        close
      </v-icon>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { setCookie } from '@/utils/getJSONCookie';

import {
  DISMISS_VERSION_SWITCH,
  ENABLE_BETA_HOMEBOX
} from '@/utils/userTagNames';

export default {
  computed: {
    ...mapGetters('conf', ['showVersionSwitch', 'isVueHomebox'])
  },
  watch: {
    showVersionSwitch: {
      immediate: true,
      handler(showVersionSwitch) {
        if (!showVersionSwitch) return;

        if (this.isVueHomebox) {
          this.trackFeatureOptOutBannerShownEvent(ENABLE_BETA_HOMEBOX);
        } else {
          this.trackFeatureOptInBannerShownEvent(ENABLE_BETA_HOMEBOX);
        }
      }
    }
  },
  methods: {
    ...mapMutations('conf', ['setFlags']),
    ...mapActions('events', [
      'trackFeatureOptInBannerShownEvent',
      'trackFeatureOptOutBannerShownEvent',
      'trackFeatureOptInBannerDismissedEvent',
      'trackFeatureOptOutBannerDismissedEvent'
    ]),
    ...mapActions('users', ['setTag', 'unsetTag']),
    async handleSwitch(enable) {
      if (enable) {
        await this.setTag(ENABLE_BETA_HOMEBOX);
      } else {
        await this.unsetTag(ENABLE_BETA_HOMEBOX);
      }

      location.reload();
    },
    async handleDismiss() {
      const oneYear = 3600 * 24 * 365;
      const twoWeeks = 3600 * 24 * 14;

      setCookie(DISMISS_VERSION_SWITCH, 'yes', {
        'max-age': this.isVueHomebox ? oneYear : twoWeeks
      });

      this.setFlags({ versionSwitchDismissed: true });
      if (this.isVueHomebox) {
        this.trackFeatureOptOutBannerDismissedEvent(ENABLE_BETA_HOMEBOX);
      } else {
        this.trackFeatureOptInBannerDismissedEvent(ENABLE_BETA_HOMEBOX);
      }
    }
  }
};
</script>
<style scoped>
.version--container {
  justify-content: center;
}
.version--content {
  padding: 1rem 1.9rem;
  margin: 0 auto;
  max-width: 1442px;
  color: #111111;
  background-color: #dbecfa;
  border: 1px solid #b8d9f6;

  display: flex;
  align-items: flex-start;
}
body.DocCtrl .version--content {
  display: none;
}

.version--content > p {
  margin: auto;
  padding: 0 0.5ch;
  flex-grow: 1;
}
.mobile .version--content {
  padding: 1rem 1rem;
}
.ltr .mobile p {
  margin-right: -2rem;
}
.rtl .mobile p {
  margin-left: -2rem;
}

.mobile strong:first-child {
  display: block;
}

.round {
  background-color: rgba(17, 17, 17, 0.1);
  color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  font-size: 16px;
  padding: 0.2em;
}

.v-icon {
  vertical-align: middle;
}
.icon--info-sign {
  font-size: 26px;
  transform: rotate(180deg);
}
</style>
