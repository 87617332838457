import angular from 'angular';
import _ from 'lodash';
import $ from 'jquery';
import Mousetrap from 'mousetrap';
import { formatShortDate } from '@/utils/getDefaultDate';

/**
 * @ngdoc service
 * @name frontendApp.srModal
 * @description
 * # srModal
 * Factory in the frontendApp.
 */
angular
  .module('frontendApp')

  .factory('srModalFactory', [
    'ModalService',
    'EventDispatcher',
    '$q',
    function(ModalService, EventDispatcher, $q) {
      /**
       * Based on angular-modal-service
       * @url https://github.com/dwmkerr/angular-modal-service
       * @param options
       * @constructor
       */
      var ModalFactory = function(options) {
        var service = this;
        /**
         * Modal options
         */
        this.modalOptions = options;
        /**
         *
         * @type {null}
         */
        this.modalInstance = null;
        /**
         * Flag that determines if modal is opened
         * @type {boolean}
         */
        this.active = false;
        /**
         * Modal event disptacher
         * @type {EventDispatcher}
         */
        this.eventDispatcher = new EventDispatcher();
        /**
         * Returns event dispatcher instance
         * @returns {*}
         */
        this.getEventDispatcher = function() {
          return service.eventDispatcher;
        };
        /**
         * Closes all modals
         */
        this.closeAll = function() {
          $.magnificPopup.instance.close();
        };

        this._activate = function(locals, scope) {
          service.modalOptions.inputs = locals;
          service.modalOptions.scope = scope;
          return ModalService.showModal(service.modalOptions).then(function(
            modalInstance
          ) {
            $.magnificPopup.open({
              items: {
                src: modalInstance.element
              },
              //removalDelay: 100,
              mainClass: 'mfp-fade',
              type: 'inline',
              midClick: true,
              closeOnBgClick: false,
              closeOnContentClick: false,
              fixedContentPos: true,
              modal: modalInstance.scope.hideClose || false,
              callbacks: {
                afterClose: function() {
                  // also resolves the promise and removes scope + elem
                  service._cleanUp();
                }
              }
            });
            service.active = true;
            service.modalInstance = modalInstance;
            // service.getEventDispatcher().trigger('sr.modal.shown', modalInstance);
          });
        };
        /**
         * Shows modal
         */
        this.activate = function(locals, scope) {
          service.deferedClose = null;
          service.deferedActivateClose = $q.defer();
          service._activate(locals, scope);
          return service.deferedActivateClose.promise;
        };
        this.activateOnce = function(locals, scope) {
          if (!this.active) {
            this.activate(locals, scope);
          }
          return service.deferedActivateClose.promise;
        };
        this._cleanUp = function() {
          service.modalInstance.scope.$destroy();
          service.modalInstance.element.remove();
          //service.modalInstance.element.fadeOut('fast', function() {
          //  angular.element(this).remove();
          //});
          service.getEventDispatcher().trigger('sr.modal.hidden');
          if (service.deferedClose !== null) {
            service.deferedClose.resolve();
          }
          service.deferedActivateClose.resolve();
          service.active = false;
        };
        /**
         * Hides modal programmatically
         * @returns {promise}
         */
        this.deactivate = function() {
          service.deferedClose = $q.defer();
          service.active = false;
          service.closeAll();
          return service.deferedClose.promise;
        };

        this.deactivateOnce = function() {
          return service.active ? service.deactivate() : null;
        };

        this.resolveToValue = function(ret) {
          service.deferedActivateClose.resolve(ret);
        };
      };

      return {
        create: function(options) {
          return new ModalFactory(options);
        }
      };
    }
  ])

  .factory('srLoginModal', [
    'srModalFactory',
    '$rootScope',
    '$vuex',
    function(srModalFactory, $rootScope, $vuex) {
      return srModalFactory.create({
        controller: 'LoginCtrl',
        controllerAs: 'modal',
        template: require('../../partials/modals/login.html')
      });
    }
  ])

  .factory('srLoginOrContinueModal', [
    'srModalFactory',
    function(srModalFactory) {
      var modal = srModalFactory.create({
        controller: 'LoginOrContinueCtrl',
        controllerAs: 'modal',
        template: require('../../partials/modals/login-or-continue.html')
      });
      return modal;
    }
  ])

  .controller('LoginOrContinueCtrl', [
    '$scope',
    'srLoginOrContinueModal',
    function($scope, srLoginOrContinueModal) {
      $scope.title = $scope.title || '';
      $scope.meessage = $scope.message || '';
      $scope.continueMessage = $scope.continueMessage || '';

      $scope.doLogin = function() {
        srLoginOrContinueModal.resolveToValue(true);
      };

      $scope.closeModal = function() {
        srLoginOrContinueModal.resolveToValue(false);
      };
    }
  ])

  .factory('srTwoFactorLoginModal', [
    'srModalFactory',
    function(srModalFactory) {
      var modal = srModalFactory.create({
        controller: 'srTwoFactorLoginCtrl',
        controllerAs: 'modal',
        template: require('../../partials/modals/two-factor-login.html')
      });
      return modal;
    }
  ])
  .controller('srTwoFactorLoginCtrl', [
    '$scope',
    'srTwoFactorLoginModal',
    'UserService',
    function($scope, srTwoFactorLoginModal, UserService) {
      $scope.UserService = UserService;
      $scope.loading = false;

      $scope.closeModal = function() {
        srTwoFactorLoginModal.deactivate();
      };

      $scope.device_type = null;
      $scope.phone_method = 'sms';
      $scope.token = '';

      $scope.step = 'start';

      $scope.resetForm = function() {
        $scope.device_type = null;
        $scope.phone_method = 'sms';
        $scope.token = '';
      };

      $scope.setTokenDevice = function() {
        $scope.resetForm();
        $scope.device_type = 'generator';
      };

      $scope.setPhoneDevice = function(phone_method) {
        $scope.resetForm();
        $scope.device_type = 'phone';
        $scope.phone_method = phone_method || 'sms';
      };

      $scope.setBackupDevice = function() {
        $scope.resetForm();
        $scope.device_type = 'backup';
      };

      if (UserService.userHasTokenGeneratorDevice()) {
        $scope.setTokenDevice();
      } else if (UserService.userHasPhoneTokenDevice()) {
        $scope.setPhoneDevice('sms');
      } else if (UserService.userHasBackupTokenDevice()) {
        $scope.setBackupDevice();
      }

      $scope.hasNext = function() {
        return ~['start'].indexOf($scope.step);
      };

      $scope.timer_seconds = 0;
      $scope.generateChallenge = function(device) {
        $scope.timer_seconds = 60;
        $scope.srTokenForm.$setUntouched();
        $('form[name="srTokenForm"] #token').focus();
        UserService.generate2faChallenge(device, $scope.phone_method).then(
          function(resp) {
            if (resp.data.status !== 'SUCCESS') {
              $scope.timer_seconds = 0;
            }
          },
          function() {
            $scope.timer_seconds = 0;
          }
        );
      };
      $scope.onTimerDone = function() {
        $scope.timer_seconds = 0;
      };

      $scope.verifyToken = function(event) {
        if (event) {
          event.preventDefault();
        }
        if ($scope.token) {
          $scope.loading = true;
          var device_uuid = null;
          if ($scope.device_type === 'backup') {
            device_uuid = $scope.user.two_factor.backup_device;
          }
          UserService.verify2faDevice(
            $scope.token,
            $scope.device_type,
            device_uuid
          ).then(
            function(resp) {
              if (resp.data.status === 'SUCCESS') {
                $scope.loading = false;
                $scope.closeModal();
              } else {
                $scope.loading = false;
              }
            },
            function() {
              $scope.loading = false;
            }
          );
        }
        return false;
      };

      $scope.logOutUser = function() {
        UserService.logout();
      };

      $scope.next = function() {
        switch ($scope.step) {
          case 'start':
            $scope.step = 'verify';
            break;
          default:
            break;
        }
      };

      if (
        !_.every([
          UserService.userHasTokenGeneratorDevice(),
          UserService.userHasPhoneTokenDevice()
        ]) &&
        !UserService.userHasBackupTokenDevice()
      ) {
        // we only have one device so continue to the next step
        $scope.next();
      }
    }
  ])

  .factory('srTwoFactorModal', [
    'srModalFactory',
    function(srModalFactory) {
      var modal = srModalFactory.create({
        controller: 'srTwoFactorModalCtrl',
        controllerAs: 'modal',
        template: require('../../partials/modals/two-factor.html')
      });
      return modal;
    }
  ])
  .controller('srTwoFactorModalCtrl', [
    '$scope',
    'srTwoFactorModal',
    'UserService',
    function($scope, srTwoFactorModal, UserService) {
      $scope.closeModal = function() {
        UserService.refreshUser();
        srTwoFactorModal.deactivate();
      };

      // might be inserted when opening the modal
      $scope.add_only = !!$scope.add_only;
      $scope.step = $scope.step ? $scope.step : 'start';

      $scope.token = '';
      $scope.phone_method = 'sms';
      $scope.phone_number = '';
      $scope.phone_device_uuid = '';
      $scope.backup_codes = [];
      $scope.qr_code_url = '';
      $scope.device_type = 'generator';
      $scope.loading = false;
      $scope.timer_seconds = 0;

      $scope.resetForm = function() {
        $scope.token = '';
        $scope.qr_code_url = '';
        $scope.phone_number = '';
        $scope.phone_device_uuid = '';
        $scope.backup_codes = [];
        $scope.device_type = 'generator';
        $scope.loading = false;
        $scope.timer_seconds = 0;
      };

      $scope.setTokenDevice = function() {
        $scope.resetForm();
        $scope.device_type = 'generator';
      };

      $scope.setPhoneDevice = function(phone_method) {
        $scope.device_type = 'phone';
        $scope.phone_method = phone_method;
      };

      $scope.addTokenDevice = function() {
        $scope.device_type = 'generator';
        $scope.step = 'add_generator';
        $scope.loading = true;
        UserService.add2faTOTPDevice().then(function(resp) {
          $scope.loading = false;
          $scope.qr_code_url = resp.data.qr_url;
        });
      };

      $scope.addPhoneDevice = function() {
        $scope.resetForm();
        $scope.device_type = 'phone';
        $scope.step = 'add_phone';
      };

      $scope.onTimerDone = function() {
        $scope.timer_seconds = 0;
      };

      $scope.verifyPhone = function() {
        if ($scope.phone_number) {
          $scope.loading = true;
          UserService.add2faPhoneDevice({
            number: $scope.phone_number,
            method: $scope.phone_method
          }).then(function(resp) {
            if (resp.data.status === 'SUCCESS') {
              $scope.timer_seconds = 60;
              UserService.refreshUser().then(function() {
                $scope.loading = false;
                $scope.phone_device_uuid = resp.data.device_uuid;
                $scope.step = 'verify_phone';
              });
            } else {
              $scope.loading = false;
            }
          });
        }
      };

      $scope.showBackupCodes = function() {
        $scope.loading = true;
        UserService.create2faBackupCodes().then(function(resp) {
          if (resp.data.status === 'SUCCESS') {
            UserService.refreshUser().then(function() {
              $scope.loading = false;
              $scope.backup_codes = resp.data.tokens;
            });
          } else {
            $scope.loading = false;
          }
        });
      };

      $scope.addBackupDevice = function() {
        $scope.step = 'backup';
        $scope.resetForm();
      };

      $scope.hasNext = function() {
        return ~[
          'start',
          'generator_done',
          'phone_done',
          'add_phone',
          'backup',
          'done'
        ].indexOf($scope.step);
      };

      $scope.next = function() {
        switch ($scope.step) {
          case 'start':
            switch ($scope.device_type) {
              case 'generator':
                $scope.addTokenDevice();
                break;
              case 'phone':
                $scope.addPhoneDevice($scope.phone_method);
                break;
              default:
                break;
            }
            break;
          case 'generator_done':
            if (!UserService.userHasPhoneTokenDevice()) {
              $scope.addPhoneDevice($scope.phone_method);
            } else {
              // skip the phone step
              $scope.step = 'phone_done';
              $scope.next();
            }
            break;
          case 'phone_done':
            // this is not an actual view
            if (!UserService.userHasBackupTokenDevice()) {
              $scope.addBackupDevice();
            } else {
              $scope.step = 'done';
            }
            break;
          case 'add_phone':
            $scope.verifyPhone();
            break;
          case 'backup':
            $scope.step = 'done';
            break;
          case 'done':
            $scope.closeModal();
            break;
          default:
            break;
        }
      };

      $scope.verifyToken = function(event) {
        if (event) {
          event.preventDefault();
        }
        if ($scope.token) {
          $scope.loading = true;
          switch ($scope.step) {
            case 'add_generator':
              UserService.add2faTOTPDevice($scope.token).then(function(resp) {
                if (resp.data.status === 'SUCCESS') {
                  UserService.refreshUser().then(function() {
                    $scope.loading = false;
                    $scope.qr_code_url = '';
                    $scope.step = 'generator_done';
                  });
                } else {
                  $scope.loading = false;
                }
              });
              break;
            case 'verify_phone':
              UserService.add2faPhoneDevice({
                device_uuid: $scope.phone_device_uuid,
                token: $scope.token
              }).then(function(resp) {
                if (resp.data.status === 'SUCCESS') {
                  UserService.refreshUser().then(function() {
                    $scope.loading = false;
                    $scope.timer_seconds = 0;
                    $scope.step = 'phone_done';
                    $scope.next();
                  });
                } else {
                  $scope.loading = false;
                }
              });
              break;
            default:
              break;
          }
        }
        return false;
      };
    }
  ])

  //.factory('srLocaleModal',['srModalFactory', function (srModalFactory) {
  //  var modal = srModalFactory.create({
  //    controller: 'SwitchLocaleCtrl',
  //    controllerAs: 'modal',
  //    template: require('../../partials/modals/switch-lang.html')
  //  });
  //  return modal;
  //}])
  //.controller('SwitchLocaleCtrl', ['$scope', 'srLocaleModal',
  //  function ($scope, srLocaleModal) {
  //    $scope.closeModal = srLocaleModal.deactivate;
  //  }])

  .factory('srSignatureSelectModal', [
    'srModalFactory',
    function(srModalFactory) {
      var modal = srModalFactory.create({
        controller: 'SigSelectCtrl',
        controllerAs: 'modal',
        template: require('../../partials/modals/signature-select.html')
      });
      return modal;
    }
  ])
  .factory('srApiUploadModal', [
    'srModalFactory',
    function(srModalFactory) {
      var modal = srModalFactory.create({
        controller: 'ApiUploadModalCtrl',
        controllerAs: 'modal',
        template: require('../../partials/modals/apiupload.html')
      });

      return modal;
    }
  ])
  .controller('ApiUploadModalCtrl', [
    '$scope',
    'srApiUploadModal',
    function($scope, srApiUploadModal) {
      $scope.closeModal = srApiUploadModal.deactivate;
    }
  ])

  .factory('srVideoModal', [
    'srModalFactory',
    function(srModalFactory) {
      var modal = srModalFactory.create({
        controller: 'srVideoModalCtrl',
        controllerAs: 'modal',
        template: require('../../partials/modals/video.html')
      });

      return modal;
    }
  ])
  .controller('srVideoModalCtrl', [
    '$scope',
    'srVideoModal',
    function($scope, srVideoModal) {
      $scope.closeModal = srVideoModal.deactivate;
    }
  ])

  .factory('srClaimReferredUserModal', [
    'srModalFactory',
    function(srModalFactory) {
      var modal = srModalFactory.create({
        controller: 'srClaimReferredUserCtrl',
        controllerAs: 'modal',
        template: require('../../partials/modals/claim-referred-user.html')
      });

      return modal;
    }
  ])
  .controller('srClaimReferredUserCtrl', [
    '$scope',
    'srClaimReferredUserModal',
    'referralLinksService',
    function($scope, srClaimReferredUserModal, referralLinksService) {
      $scope.closeModal = srClaimReferredUserModal.deactivate;
      $scope.loading = false;
      $scope.claim = {
        claimed_email: '',
        claim_comment: ''
      };
      $scope.send = function() {
        $scope.loading = true;
        referralLinksService
          .createReferralClaim($scope.claim)
          .then(function(resp) {
            $scope.loading = false;
            if (resp.data.status === 'SUCCESS') {
              $scope.closeModal();
            }
          });
      };
    }
  ])

  .factory('srUploadModal', [
    'srModalFactory',
    function(srModalFactory) {
      var modal = srModalFactory.create({
        controller: 'UploadModalCtrl',
        controllerAs: 'modal',
        template: require('../../partials/modals/upload.html')
      });
      return modal;
    }
  ])
  .directive('openInputFile', [
    '$timeout',
    function($timeout) {
      return {
        restrict: 'A',
        link: function(scope, element, attrs) {
          scope.$watch('openInputFile', function(trigger) {
            if (angular.isDefined(trigger) && trigger) {
              $timeout(function() {
                angular.element(element).trigger('click');
              });
            }
          });
        }
      };
    }
  ])
  .controller('UploadModalCtrl', [
    '$scope',
    'Upload',
    'srUploadModal',
    'messageService',
    '$window',
    'gettextCatalog',
    'docService',
    '$timeout',
    'utils',
    'UserService',
    'DropboxService',
    function(
      $scope,
      Upload,
      srUploadModal,
      messageService,
      $window,
      gettextCatalog,
      docService,
      $timeout,
      utils,
      UserService,
      DropboxService
    ) {
      $scope.DropboxService = DropboxService;

      $scope.as_template = angular.isDefined($scope.as_template)
        ? $scope.as_template
        : false;
      $scope.parent_doc = angular.isDefined($scope.parent_doc)
        ? $scope.parent_doc
        : false;

      if (
        $scope.user &&
        angular.isArray($scope.user.templates) &&
        $scope.user.templates.length &&
        !$scope.as_template
      ) {
        $scope.includeTemplates = UserService.userHasPerm('pro');
      } else {
        $scope.includeTemplates = false;
      }

      $scope.showDemo = false;
      if (
        angular.isFunction($scope.makeDemo) &&
        $scope.user &&
        !$scope.user.logged_in
      ) {
        $scope.showDemo = true;
      }

      $scope.onDemoDocClick = function() {
        $scope.closeModal();
        $scope.makeDemo();
      };

      $scope.afterTemplateUploaded = function(data) {
        $scope.converting = true;
        docService.getConverted(data.doc).then(
          function(data) {
            $scope.converting = false;
            $scope.closeModal();
            // go to the prepare url of the template
            utils.navigateTo(data.template_url);
          },
          function() {
            $scope.converting = false;
            $scope.closeModal();
            $scope.updateDocuments();
          }
        );
      };

      $scope.onChooseDropbox = function() {
        DropboxService.chooseFile().then(
          function(file) {
            $scope.uploading = true;
            docService
              .getApiDocument({
                doc_url: file.link,
                name: file.name,
                as_template: $scope.as_template,
                parent_doc: $scope.parent_doc
              })
              .then(
                function(resp) {
                  $scope.uploading = false;
                  if ($scope.as_template) {
                    $scope.afterTemplateUploaded(resp.data);
                  } else {
                    $scope.closeModal();
                    // defined in the main scope
                    $scope.updateDocuments();
                  }
                },
                function() {
                  $scope.uploading = false;
                  $scope.converting = false;
                }
              );
          },
          function() {
            // no file selected
          }
        );
      };

      $scope.selected_template = { uuid: null };
      $scope.template_choices = [];
      if ($scope.includeTemplates) {
        $scope.template_choices = _.map($scope.user.templates, function(
          template
        ) {
          return { text: template.name, value: template.uuid };
        });
      }

      $scope.onTemplateChange = function(template) {
        template = template || $scope.selected_template.uuid;
        if (template) {
          $timeout(function() {
            $scope.uploading = false;
            $scope.closeModal();
            $scope.urlApiV1({
              api: 'v1',
              template_uuid: template,
              parent_doc: $scope.parent_doc
            });
          }, 10);
        }
      };

      if ($scope.includeTemplates) {
        $scope.$watch('selected_template.uuid', $scope.onTemplateChange);
      }

      $scope.getSelectTemplateTranslation = function() {
        return gettextCatalog.getString('Select template');
      };

      $scope.closeModal = srUploadModal.deactivate;
      $scope.dropSupported = false;
      $scope.uploading = false;
      $scope.converting = false;
      $scope.hasFlash = window.FileAPI.hasFlash;
      $scope.$watch(
        function() {
          return $window.FileAPI.hasFlash;
        },
        function(n, o) {
          if (n !== o) {
            $scope.hasFlash = $window.FileAPI.hasFlash;
          }
        }
      );

      $scope.$watch('triggerOpenFileChoice', function(trigger) {
        if (angular.isDefined(trigger) && trigger) {
          $scope.openInputFile = true;
        }
      });

      $scope.onFileSelect = function($files) {
        var onProgress = function(evt) {
            $scope.uploadedPercentage = parseInt(
              (100.0 * evt.loaded) / evt.total
            );
          },
          onSuccess = function(data) {
            if (data.error) {
              return onError(data);
            }
            // file is uploaded successfully
            $scope.uploading = false;
            if ($scope.as_template && data.doc.template_url) {
              $scope.afterTemplateUploaded(data);
            } else {
              $scope.closeModal();
              // defined in the main scope
              $scope.updateDocuments();
            }
          },
          onError = function(data) {
            // an error occurred
            messageService.handleError(data);
            $scope.uploading = false;
            $scope.closeModal();
          };

        $scope.uploading = true;
        //$files: an array of files selected, each file has name, size, and type.
        var file = $files[0];

        var url = '/docs/upload/';
        var query_params = [];
        $scope.as_template && query_params.push('as_template=1');
        $scope.parent_doc &&
          query_params.push('parent_doc=' + $scope.parent_doc);
        if (query_params.length) {
          url += '?' + query_params.pop();
          angular.forEach(query_params, function(param) {
            url += '&' + param;
          });
        }

        $scope.upload = Upload.upload({
          url: url, //upload.php script, node.js route, or servlet url

          method: 'POST',
          // headers: {'header-key': 'header-value'},
          // withCredentials: true,
          //          data: {},
          data: { file: file } // or list of files: $files for html5 only
          /* set the file formData name ('Content-Desposition'). Default is 'file' */
          //fileFormDataName: myFile, //or a list of names for multiple files (html5).
          /* customize how data is added to formData. See #40#issuecomment-28612000 for sample code */
          //formDataAppender: function(formData, key, val){}
        })
          .progress(onProgress)
          .success(onSuccess)
          .error(onError);
        //.then(success, error, progress);
        //.xhr(function(xhr){xhr.upload.addEventListener(...)})// access and attach any event listener to XMLHttpRequest.
        /* alternative way of uploading, send the file binary with the file's content-type.
       Could be used to upload files to CouchDB, imgur, etc... html5 FileReader is needed.
       It could also be used to monitor the progress of a normal http post/put request with large data*/
        // $scope.upload = Upload.http({...})  see 88#issuecomment-31366487 for sample code.
      };
    }
  ])

  .factory('srUploadLogoModal', [
    'srModalFactory',
    function(srModalFactory) {
      var modal = srModalFactory.create({
        controller: 'UploadLogoModalCtrl',
        controllerAs: 'modal',
        template: require('../../partials/modals/upload-logo.html')
      });

      return modal;
    }
  ])
  .controller('UploadLogoModalCtrl', [
    '$scope',
    '$rootScope',
    'Upload',
    'srUploadLogoModal',
    'messageService',
    '$window',
    'utils',
    function(
      $scope,
      $rootScope,
      Upload,
      srUploadLogoModal,
      messageService,
      $window,
      utils
    ) {
      $scope.file_input_accept_string = utils.IMAGE_FILE_INPUT_ACCEPT_STRING;
      $scope.closeModal = srUploadLogoModal.deactivate;
      $scope.dropSupported = false;
      $scope.uploading = false;
      $scope.hasFlash = $window.FileAPI.hasFlash;
      $scope.$watch(
        function() {
          return $window.FileAPI.hasFlash;
        },
        function(n, o) {
          if (n !== o) {
            $scope.hasFlash = $window.FileAPI.hasFlash;
          }
        }
      );
      $scope.onFileSelect = function($files) {
        var onProgress = function(evt) {
            $scope.uploadedPercentage = parseInt(
              (100.0 * evt.loaded) / evt.total
            );
          },
          onSuccess = function(data) {
            if (data.error) {
              return onError(data);
            }
            // file is uploaded successfully

            $rootScope.logo = data.logo_url ? data.logo_url : $rootScope.logo;
            $scope.user.team_user.team.logo = data.logo_url
              ? data.logo_url
              : $scope.user.team_user.team.logo;
            $scope.uploading = false;
            $scope.closeModal();
          },
          onError = function(data) {
            // an error occurred
            messageService.handleError(data);
            $scope.uploading = false;
            $scope.closeModal();
          };

        $scope.uploading = true;
        for (var i = 0; i < $files.length; i++) {
          var file = $files[i];
          $scope.upload = Upload.upload({
            url:
              '/user/auth/team/upload-logo/' +
              $scope.user.team_user.team.subdomain +
              '/',
            method: 'POST',
            data: { logo: file }
            // fileFormDataName: 'logo'
          })
            .progress(onProgress)
            .success(onSuccess)
            .error(onError);
        }
      };
    }
  ])

  .factory('srUploadAttachmentModal', [
    'srModalFactory',
    function(srModalFactory) {
      var modal = srModalFactory.create({
        controller: 'UploadAttachmentModalCtrl',
        controllerAs: 'modal',
        template: require('../../partials/modals/upload-attachments.html')
      });

      return modal;
    }
  ])
  .controller('UploadAttachmentModalCtrl', [
    '$scope',
    '$route',
    '$rootScope',
    'Upload',
    'srUploadAttachmentModal',
    'docService',
    'messageService',
    '$window',
    'gettextCatalog',
    function(
      $scope,
      $route,
      $rootScope,
      Upload,
      srUploadAttachmentModal,
      docService,
      messageService,
      $window,
      gettextCatalog
    ) {
      $scope.closeModal = srUploadAttachmentModal.deactivate;
      $scope.dropSupported = false;
      $scope.uploading = false;
      $scope.isDocumentFile = false;

      // `$scope.required_attachments` can be set by .activate()
      $scope.required_attachments = $scope.required_attachments || [];
      $scope.required_attachment_options = [];
      angular.forEach($scope.required_attachments, function(item) {
        $scope.required_attachment_options.push({
          value: item.uuid,
          text: item.name
        });
      });
      // $scope.required_attachment_options.push({value: 'other', text: gettextCatalog.getString('other')});

      if (
        angular.isArray($scope.unfilled_required_attachments) &&
        $scope.unfilled_required_attachments.length
      ) {
        // select the first one we haven't uploaded yet
        $scope.selected_required_attachment =
          $scope.unfilled_required_attachments[0].uuid;
      } else {
        // $scope.selected_required_attachment = 'other';
        $scope.selected_required_attachment = null;
      }

      $scope.hasFlash = $window.FileAPI.hasFlash;
      $scope.$watch(
        function() {
          return $window.FileAPI.hasFlash;
        },
        function(n, o) {
          if (n !== o) {
            $scope.hasFlash = $window.FileAPI.hasFlash;
          }
        }
      );
      $scope.onFileSelect = function($files) {
        var onProgress = function(evt) {
            $scope.uploadedPercentage = parseInt(
              (100.0 * evt.loaded) / evt.total
            );
          },
          onSuccess = function(data) {
            if (data.error) {
              return onError(data);
            }
            // file is uploaded successfully
            $scope.uploading = false;
            $scope.closeModal().then(function() {
              // reload attachments
              $scope.callback();
            });
          },
          onError = function(data) {
            // an error occurred
            messageService.handleError(data);
            $scope.uploading = false;
          };
        $scope.uploading = true;
        if ($scope.isTemplate) {
          $scope.template_uuid = $route.current.params.template_uuid;
        } else {
          $scope.doc_uuid = $route.current.params.uuid;
          $scope.signer_uuid = $route.current.params.signer_uuid.replace(
            '?',
            ''
          );
        }

        for (var i = 0; i < $files.length; i++) {
          var file = $files[i];
          var url;
          if (!$scope.isTemplate) {
            url =
              '/docs/upload/signer-attachment/' +
              $scope.doc_uuid +
              '/' +
              $scope.signer_uuid +
              '/';
          }
          if (
            $scope.selected_required_attachment &&
            $scope.selected_required_attachment !== 'other'
          ) {
            url = url + $scope.selected_required_attachment + '/';
          }
          if ($scope.isDocumentAttachment) {
            url =
              '/docs/upload/document-attachment/' +
              $scope.doc_uuid +
              '/' +
              $scope.signer_uuid +
              '/';
          }
          if ($scope.isTemplate) {
            url =
              '/docs/upload/template-attachment/' + $scope.template_uuid + '/';
          }

          $scope.upload = Upload.upload({
            url: url,
            method: 'POST',
            data: { file: file }
          })
            .progress(onProgress)
            .success(onSuccess)
            .error(onError);
        }
      };
    }
  ])

  .controller('UploadAfterDocumentsModalCtrl', [
    '$scope',
    'Upload',
    'srUploadModal',
    'messageService',
    '$window',
    'gettextCatalog',
    'docService',
    '$timeout',
    'utils',
    'UserService',
    function(
      $scope,
      Upload,
      srUploadModal,
      messageService,
      $window,
      gettextCatalog,
      docService,
      $timeout,
      utils,
      UserService
    ) {
      $scope.as_template = angular.isDefined($scope.as_template)
        ? $scope.as_template
        : false;
      $scope.parent_doc = angular.isDefined($scope.parent_doc)
        ? $scope.parent_doc
        : false;

      if (
        $scope.user &&
        angular.isArray($scope.user.templates) &&
        $scope.user.templates.length &&
        !$scope.as_template
      ) {
        $scope.includeTemplates = UserService.userHasPerm('pro');
      } else {
        $scope.includeTemplates = false;
      }

      $scope.selected_template = { uuid: null };
      $scope.template_choices = [];
      if ($scope.includeTemplates) {
        $scope.template_choices = _.map($scope.user.templates, function(
          template
        ) {
          return { text: template.name, value: template.uuid };
        });
      }

      $scope.onTemplateChange = function(template) {
        template = template || $scope.selected_template.uuid;
        if (template) {
          $timeout(function() {
            $scope.uploading = false;
            $scope.closeModal();
            $scope.urlApiV1({ api: 'v1', template_uuid: template });
          }, 10);
        }
      };

      if ($scope.includeTemplates) {
        $scope.$watch('selected_template.uuid', $scope.onTemplateChange);
      }

      $scope.getSelectTemplateTranslation = function() {
        return gettextCatalog.getString('Select template');
      };

      $scope.closeModal = srUploadModal.deactivate;
      $scope.dropSupported = false;
      $scope.uploading = false;
      $scope.converting = false;
      $scope.hasFlash = window.FileAPI.hasFlash;
      $scope.$watch(
        function() {
          return $window.FileAPI.hasFlash;
        },
        function(n, o) {
          if (n !== o) {
            $scope.hasFlash = $window.FileAPI.hasFlash;
          }
        }
      );

      $scope.$watch('triggerOpenFileChoice', function(trigger) {
        if (angular.isDefined(trigger) && trigger) {
          $scope.openInputFile = true;
        }
      });

      $scope.onFileSelect = function($files) {
        var onProgress = function(evt) {
            $scope.uploadedPercentage = parseInt(
              (100.0 * evt.loaded) / evt.total
            );
          },
          onSuccess = function(data) {
            if (data.error) {
              return onError(data);
            }
            // file is uploaded successfully
            $scope.uploading = false;

            if ($scope.as_template && data.doc.template_url) {
              $scope.converting = true;
              docService.getConverted(data.doc).then(
                function(data) {
                  $scope.converting = false;
                  $scope.closeModal();
                  // go to the prepare url of the template
                  utils.navigateTo(data.template_url);
                },
                function() {
                  $scope.converting = false;
                  $scope.closeModal();
                }
              );
            } else {
              $scope.closeModal();
              // defined in the main scope
              $scope.getConverted(null, data.doc);
            }
          },
          onError = function(data) {
            // an error occurred
            messageService.handleError(data);
            $scope.uploading = false;
            $scope.closeModal();
          };

        $scope.uploading = true;
        //$files: an array of files selected, each file has name, size, and type.
        var file = $files[0];
        $scope.upload = Upload.upload({
          url:
            '/docs/upload/' +
            ($scope.as_template ? '?as_template=1' : '') +
            'parent_document=' +
            $scope.parent_doc, //upload.php script, node.js route, or servlet url
          method: 'POST',
          // headers: {'header-key': 'header-value'},
          // withCredentials: true,
          //          data: {},
          data: { file: file } // or list of files: $files for html5 only
          /* set the file formData name ('Content-Desposition'). Default is 'file' */
          //fileFormDataName: myFile, //or a list of names for multiple files (html5).
          /* customize how data is added to formData. See #40#issuecomment-28612000 for sample code */
          //formDataAppender: function(formData, key, val){}
        })
          .progress(onProgress)
          .success(onSuccess)
          .error(onError);
        //.then(success, error, progress);
        //.xhr(function(xhr){xhr.upload.addEventListener(...)})// access and attach any event listener to XMLHttpRequest.
        /* alternative way of uploading, send the file binary with the file's content-type.
       Could be used to upload files to CouchDB, imgur, etc... html5 FileReader is needed.
       It could also be used to monitor the progress of a normal http post/put request with large data*/
        // $scope.upload = Upload.http({...})  see 88#issuecomment-31366487 for sample code.
      };
    }
  ])

  //
  //.factory('srUserModal',['srModalFactory', function (srModalFactory) {
  //  var modal = srModalFactory.create({
  //    controller: 'UserModalCtrl',
  //    controllerAs: 'modal',
  //    template: require('../../partials/modals/account-switch.html')
  //  });
  //  return modal;
  //}])
  //.controller('UserModalCtrl', ['$scope', 'srUserModal',
  //  function ($scope, srUserModal) {
  //    $scope.closeModal = srUserModal.deactivate;
  //  }])

  .factory('srHowSignModal', [
    'srModalFactory',
    function(srModalFactory) {
      var modal = srModalFactory.create({
        controller: 'HowSignCtrl',
        controllerAs: 'modal',
        template: require('../../partials/modals/how-to-sign.html')
      });

      return modal;
    }
  ])
  .controller('HowSignCtrl', [
    '$scope',
    'srHowSignModal',
    function($scope, srHowSignModal) {
      $scope.closeModal = srHowSignModal.deactivate;
    }
  ])

  .factory('srHowPrepareModal', [
    'srModalFactory',
    function(srModalFactory) {
      var modal = srModalFactory.create({
        controller: 'HowPrepareCtrl',
        controllerAs: 'modal',
        template: require('../../partials/modals/how-to-prepare.html')
      });
      return modal;
    }
  ])
  .controller('HowPrepareCtrl', [
    '$scope',
    'srHowPrepareModal',
    function($scope, srHowPrepareModal) {
      $scope.closeModal = srHowPrepareModal.deactivate;
    }
  ])

  .factory('srForwardDocumentModal', [
    'srModalFactory',
    function(srModalFactory) {
      var modal = srModalFactory.create({
        controller: 'ForwardDocumentCtrl',
        controllerAs: 'modal',
        template: require('../../partials/modals/forward-document.html')
      });
      return modal;
    }
  ])
  .controller('ForwardDocumentCtrl', [
    '$scope',
    'srForwardDocumentModal',
    'docService',
    'utils',
    'confService',
    function($scope, srForwardDocumentModal, docService, utils, confService) {
      $scope.confService = confService;
      $scope.closeModal = srForwardDocumentModal.deactivate;
      $scope.emailValid = true;
      $scope.loading = false;
      $scope.forwardDocument = function(event) {
        event.preventDefault();
        $scope.emailValid = utils.EMAIL_REGEXP.test($scope.email);
        if (!$scope.loading && $scope.emailValid) {
          $scope.loading = true;
          // doc, signer and onDocForwarded are injected
          docService
            .forwardDocument(
              $scope.doc.uuid,
              $scope.signer.uuid,
              $scope.email,
              $scope.forwarded_reason
            )
            .then(
              function() {
                $scope.loading = false;
                $scope.onDocForwarded();
                $scope.closeModal();
              },
              function() {
                $scope.loading = false;
              }
            );
        }
        return false;
      };
    }
  ])

  .factory('srPrepareHelpModal', [
    'srModalFactory',
    function(srModalFactory) {
      var modal = srModalFactory.create({
        controller: 'PrepareHelpCtrl',
        controllerAs: 'modal',
        template: require('../../partials/modals/prepare-help.html')
      });
      return modal;
    }
  ])
  .controller('PrepareHelpCtrl', [
    '$scope',
    'srPrepareHelpModal',
    'docService',
    function($scope, srPrepareHelpModal, docService) {
      $scope.docService = docService;
      $scope.closeModal = srPrepareHelpModal.deactivate;
    }
  ])

  .factory('srPlaceholderModal', [
    'srModalFactory',
    function(srModalFactory) {
      var modal = srModalFactory.create({
        controller: 'PlaceholderCtrl',
        controllerAs: 'modal',
        template: require('../../partials/modals/placeholder.html')
      });
      return modal;
    }
  ])
  .controller('PlaceholderCtrl', [
    '$scope',
    'srPlaceholderModal',
    function($scope, srPlaceholderModal) {
      $scope.advanced_open = false;
      $scope.toggleAdvanced = function() {
        $scope.advanced_open = !$scope.advanced_open;
      };
      $scope.closeModal = srPlaceholderModal.deactivate;
    }
  ])

  .factory('srVerifyPhoneModal', [
    'srModalFactory',
    function(srModalFactory) {
      var modal = srModalFactory.create({
        controller: 'VerifyPhoneModalCtrl',
        controllerAs: 'modal',
        template: require('../../partials/modals/verify-phone.html')
      });
      return modal;
    }
  ])
  .controller('VerifyPhoneModalCtrl', [
    '$scope',
    'srVerifyPhoneModal',
    'docService',
    function($scope, srVerifyPhoneModal, docService) {
      $scope.closeModal = srVerifyPhoneModal.deactivate;

      $scope.sendTimer = 120;
      $scope.loading = false;
      $scope.text_sent = false;
      $scope.verification_code_error = false;
      $scope.verification_code = '';

      if (!$scope.signer && $scope.signer_uuid) {
        $scope.signer = {};
        $scope.signer.uuid = $scope.signer_uuid;
        $scope.signer.verify_phone_number = $scope.signer_verify_phone_number;
      }

      $scope.sendTextVerificationMessage = function() {
        $scope.loading = true;
        docService
          .sendTextVerificationMessage($scope.signer.uuid)
          .then(function(response) {
            $scope.sendTimer = 120;
            $scope.loading = false;
            $scope.text_sent = true;
          });
      };

      $scope.verifySignerPhone = function(event) {
        event.preventDefault();
        if ($scope.verification_code) {
          $scope.loading = true;
          $scope.verification_code_error = false;
          docService
            .verifySignerPhone($scope.signer.uuid, $scope.verification_code)
            .then(function(response) {
              $scope.loading = false;
              if (response.data.status === 'SUCCESS') {
                $scope.signer.phone_number_verified = true;
                if ($scope.finishCallback) {
                  $scope.closeModal();
                  $scope.finishCallback();
                } else {
                  srVerifyPhoneModal.resolveToValue(true);
                  $scope.closeModal();
                }
              } else {
                $scope.verification_code_error = true;
              }
            });
        } else {
          $scope.verification_code_error = true;
        }
        return false;
      };
    }
  ])

  .factory('srVerifyBankAccountModal', [
    'srModalFactory',
    function(srModalFactory) {
      var modal = srModalFactory.create({
        controller: 'VerifyBankAccountCtrl',
        controllerAs: 'modal',
        template: require('../../partials/modals/verify-bank-account.html')
      });
      return modal;
    }
  ])

  .controller('VerifyBankAccountCtrl', [
    '$scope',
    'srVerifyBankAccountModal',
    'docService',
    function($scope, srVerifyBankAccountModal, docService) {
      $scope.closeModal = srVerifyBankAccountModal.deactivate;

      $scope.loading = false;

      $scope.makeVerificationPayment = function(event) {
        event.preventDefault();
        if ($scope.loading) {
          return;
        }
        $scope.loading = true;
        docService.createBankAccountVerification($scope.signer.uuid).then(
          function(response) {
            // we will get redirected
          },
          function() {
            $scope.loading = false;
          }
        );
        return false;
      };
    }
  ])

  .factory('srSocialShareModal', [
    'srModalFactory',
    function(srModalFactory) {
      var modal = srModalFactory.create({
        controller: 'SocialShareCtrl',
        controllerAs: 'modal',
        template: require('../../partials/modals/social-share.html')
      });
      return modal;
    }
  ])

  .controller('SocialShareCtrl', [
    '$scope',
    'srSocialShareModal',
    '$timeout',
    'UserService',
    'gettextCatalog',
    function(
      $scope,
      srVerifyBankAccountModal,
      $timeout,
      UserService,
      gettextCatalog
    ) {
      $scope.closeModal = srVerifyBankAccountModal.deactivate;

      $scope.maybeLater = function() {
        UserService.trackSocialAction('share_later');
        $scope.closeModal();
      };

      UserService.trackSocialAction('share_shown');

      var share_url = 'http://bit.ly/20rbVFh';
      var twitter =
        'Love this service! Do you get your contracts signed fast and paperless as well?';
      if (gettextCatalog.currentLanguage === 'nl') {
        share_url = 'http://bit.ly/1PzHCEr';
        twitter =
          'Geweldige dienst! Laat jij je klanten ook snel en papierloos ondertekenen?';
      }

      $timeout(function() {
        $('#social-share').jsSocials({
          shares: [
            {
              share: 'twitter',
              //via: 'SignRequest',
              hashtags: 'SignRequest',
              url: share_url,
              text: twitter
            },
            {
              share: 'facebook',
              url: share_url
            },
            {
              share: 'linkedin',
              url: share_url
            }
          ],
          showCount: false,
          on: {
            click: function(event) {
              UserService.trackSocialAction('share_' + this.share);
              return true;
            }
          }
        });
      }, 0);
    }
  ])

  .factory('srConfirmSubscriptionModal', [
    'srModalFactory',
    function(srModalFactory) {
      var modal = srModalFactory.create({
        controller: 'SubscribeCtrl',
        controllerAs: 'ctrl',
        template: require('../../partials/modals/confirm-subscription.html')
      });
      return modal;
    }
  ])

  .factory('srMemberUpgradeModal', [
    'srModalFactory',
    function(srModalFactory) {
      var modal = srModalFactory.create({
        controller: 'MemberUpgradeCtrl',
        controllerAs: 'ctrl',
        template: require('../../partials/modals/upgrade-member-subscription.html')
      });
      return modal;
    }
  ])
  .controller('MemberUpgradeCtrl', [
    '$scope',
    'srMemberUpgradeModal',
    'UserService',
    'messageService',
    'gettextCatalog',
    'utils',
    function(
      $scope,
      srMemberUpgradeModal,
      UserService,
      messageService,
      gettextCatalog,
      utils
    ) {
      var ctrl = this;

      ctrl.add_num_licences = 1;
      ctrl.permissions = UserService.getUserPermissions();

      if (
        ctrl.permissions.used_quantity &&
        ctrl.permissions.used_quantity > ctrl.permissions.quantity
      ) {
        ctrl.add_num_licences =
          ctrl.permissions.used_quantity - ctrl.permissions.quantity;
      }

      ctrl.closeModal = function() {
        srMemberUpgradeModal.deactivate();
      };

      ctrl.trackUpgradeEvent = function(event_name) {
        UserService.trackEvent(
          'Member Upgrade ' + event_name,
          'Member Upgrade',
          {
            plan: ctrl.permissions.plan,
            quantity: ctrl.permissions.quantity + ctrl.add_num_licences,
            used_quantity: ctrl.permissions.used_quantity,
            add_num_licences: ctrl.add_num_licences,
            is_payer: ctrl.permissions.is_payer,
            paid_usage: UserService.userHasPaidUsage()
          }
        );
      };

      ctrl.trackUpgradeEvent('Opened');

      ctrl.doUpgrade = function(event, doMemberUpgrade) {
        if (event && event.preventDefault) {
          event.preventDefault();
        }
        if (ctrl.permissions.is_payer) {
          ctrl.trackUpgradeEvent('Next');
          if (ctrl.permissions.plan === 'PRO') {
            // we need to upgrade to SME
            doMemberUpgrade(4, 'SME');
          } else {
            doMemberUpgrade(
              ctrl.add_num_licences + ctrl.permissions.quantity,
              ctrl.permissions.plan
            );
          }
        } else {
          ctrl.trackUpgradeEvent('Payer Error');
          messageService.add(
            'error',
            gettextCatalog.getString(
              'You need to be logged in with the account that manages this subscription.'
            ),
            10000
          );
        }
        ctrl.closeModal();
        return false;
      };
    }
  ])

  .factory('srSubscribeModal', [
    'srModalFactory',
    function(srModalFactory) {
      var modal = srModalFactory.create({
        controller: 'SubscribeModalCtrl',
        controllerAs: 'modal',
        template: require('../../partials/modals/subscribe.html')
      });
      return modal;
    }
  ])

  .factory('srSubscribeTooMuchDocumentsModal', [
    'srModalFactory',
    function(srModalFactory) {
      var modal = srModalFactory.create({
        controller: 'SubscribeModalCtrl',
        controllerAs: 'modal',
        template: require('../../partials/modals/subscribe-too-much-documents.html')
      });
      return modal;
    }
  ])

  .controller('SubscribeModalCtrl', [
    '$scope',
    'srSubscribeModal',
    'gettextCatalog',
    'utils',
    'confService',
    'BillingService',
    'UserService',
    '$route',
    'ENV',
    function(
      $scope,
      srSubscribeModal,
      gettextCatalog,
      utils,
      confService,
      BillingService,
      UserService,
      $route,
      ENV
    ) {
      $scope.first = true;
      $scope.trialEnded = UserService.hasTrialEnded();
      $scope.plans_url = confService.getPageUrlForLang('plans');
      $scope.plan =
        $scope.plan ||
        $route.current.params.open_subscribe ||
        $route.current.params.plan ||
        'PRO';
      let plan_display_names = {
        PRO: 'Professional',
        SME: 'Business'
      };
      $scope.plan_display_name = plan_display_names[$scope.plan];
      $scope.interval =
        $scope.interval || $route.current.params.interval || 'month';

      if (!$scope.trialEnded && !$scope.isSubscribeOnly) {
        // $scope.isSubscribeOnly can be injected by the caller
        // trial_days may also be set to 30, the backend only allows 0, 14 and 30
        $scope.trial_days =
          utils.parseIntParam(
            $scope.trial_days || $route.current.params.trial_days
          ) || 14;
        if (!~[14, 30].indexOf($scope.trial_days)) {
          $scope.trial_days = 14;
        }
      } else {
        $scope.trial_days = 0;
      }

      $scope.isSubscribeOnly =
        $scope.isSubscribeOnly ||
        $scope.open_subscribe ||
        !!$route.current.params.open_subscribe;

      $scope.callback = angular.isFunction($scope.callback)
        ? $scope.callback
        : function() {};

      var tracking_params = {
        trial_ended: $scope.trialEnded,
        trial_days: $scope.trial_days,
        plan: $scope.plan,
        plan_interval: $scope.interval,
        isSubscribeOnly: $scope.isSubscribeOnly,
        trial_subscribe_version: ENV.trial_subscribe_version,
        perm: $scope.perm,
        feature: $scope.feature,
        trigger: $scope.trigger
      };
      var tracking_category = $scope.trialEnded
        ? 'Trial Ended Modal'
        : 'Subscribe Modal';
      UserService.trackEvent(
        tracking_category + ' Opened',
        tracking_category,
        tracking_params
      );

      $scope.closeModal = function() {
        UserService.trackEvent(
          tracking_category + ' Closed',
          tracking_category,
          tracking_params
        );
        srSubscribeModal.deactivate();
      };

      // $scope.subscribe = function () {
      //   $scope.loading = true;
      //   if ($scope.trialEnded){
      //     $scope.closeModal();
      //     utils.navigateTo('/billing?plan=PRO');
      //   } else {
      //     BillingService.setSubscriptionDetails("TRIAL").then(function (response) {
      //       $scope.loading = false;
      //       $scope.first = false;
      //     })
      //   }
      // };

      $scope.goToBilling = function(plan) {
        plan = plan || '';
        $scope.closeModal();
        utils.navigateTo('/billing?plan=' + plan);
      };

      $scope.onTrialRegisterSubscribe = function(user) {
        if (UserService.userHasPerm('pro')) {
          UserService.trackEvent(
            tracking_category + ' Subscribed',
            tracking_category,
            tracking_params
          );
          $scope.loading = false;
          $scope.first = false;
        }
      };

      $scope.closeModalAfterSubscribe = function() {
        $scope.callback();
        $scope.closeModal();
      };
    }
  ])

  .factory('srTextInputModal', [
    'srModalFactory',
    function(srModalFactory) {
      var modal = srModalFactory.create({
        controller: 'TextInputCtrl',
        controllerAs: 'modal',
        template: require('../../partials/modals/text-input.html')
      });

      return modal;
    }
  ])
  .controller('TextInputCtrl', [
    '$scope',
    '$rootScope',
    '$filter',
    'srTextInputModal',
    '$vuex',
    function($scope, $rootScope, $filter, srTextInputModal, $vuex) {
      if ($scope.textInsert.isDate) {
        const languageCode = $vuex.getters['conf/languageCode'];
        const defaultDate = formatShortDate(new Date(), languageCode);
        $scope.textInsert.text = $scope.textInsert.text || defaultDate;
      } else {
        $scope.textInsert.text = $scope.textInsert.text || '';
      }
      $scope.closeModal = srTextInputModal.deactivate;

      $scope.save = function(event, skip) {
        event.preventDefault();
        if (skip) {
          $scope.textInsert.changeText(null, false);
        } else {
          if (!$scope.textInsert.text) {
            return;
          }
          $scope.textInsert.changeText(
            $scope.textInsert.text,
            $scope.textInsert.isDate,
            $scope.textInsert.multiline,
            $scope.textInsert.maxColumns
          );
        }
        $scope.closeModal();
        return false;
      };

      Mousetrap.bind(
        ['return'],
        function(event) {
          // fires when no input is focused but we hit enter
          if ($scope.textInsert.text) {
            if (
              $scope.textInsert.multiline &&
              $('#input-multiline').is(':focus')
            ) {
              return true;
            }
            $scope.save(event, false);
            return false;
          }
          return true;
        },
        'keydown'
      );

      $scope.$on('$destroy', function() {
        Mousetrap.unbind(['return'], 'keydown');
      });
    }
  ])

  .factory('srCheckboxInputModal', [
    'srModalFactory',
    function(srModalFactory) {
      var modal = srModalFactory.create({
        controller: 'CheckboxInputCtrl',
        controllerAs: 'modal',
        template: require('../../partials/modals/checkbox-input.html')
      });

      return modal;
    }
  ])
  .controller('CheckboxInputCtrl', [
    '$scope',
    '$rootScope',
    '$filter',
    'srCheckboxInputModal',
    'docService',
    function($scope, $rootScope, $filter, srCheckboxInputModal, docService) {
      $scope.closeModal = srCheckboxInputModal.deactivate;
      $scope.docService = docService;
      $scope.chooseValue = function($event, checkbox_value) {
        $event.preventDefault();
        $scope.checkbox.changeCheckbox(checkbox_value);
        $scope.closeModal();
      };
    }
  ])

  .factory('srTermsAgreeModal', [
    'srModalFactory',
    function(srModalFactory) {
      var modal = srModalFactory.create({
        controller: 'TermsAgreeCtrl',
        controllerAs: 'modal',
        template: require('../../partials/modals/terms-agree.html')
      });

      return modal;
    }
  ])
  .controller('TermsAgreeCtrl', [
    '$scope',
    '$rootScope',
    'srTermsAgreeModal',
    'confService',
    '$vuex',
    function($scope, $rootScope, srTermsAgreeModal, confService, $vuex) {
      $scope.confService = confService;
      $scope.loading = false;
      $scope.esignChecked = false;
      $scope.termsChecked = false;

      $scope.agreed = function(event) {
        event.preventDefault();

        const termsOk = $scope.user.agreed_to_terms || $scope.disable_terms;
        const esignOk = $scope.user.agreed_to_esign || !$scope.esign_disclosure;

        if (!termsOk) {
          $scope.termsChecked = true;
        } else if ($scope.save) {
          $scope.loading = true;
          $scope.save(function() {
            $scope.closeModal({ explicit: true });
          });
        } else {
          srTermsAgreeModal.resolveToValue(esignOk && termsOk);
          $scope.closeModal({ explicit: true });
        }

        return false;
      };

      $scope.showEsign = function(text) {
        $vuex.dispatch('modals/showEsign', text);
      };

      $scope.closeModal = function({ explicit = false } = {}) {
        if (
          !$scope.user.agreed_to_esign &&
          $scope.esign_disclosure &&
          !explicit
        ) {
          $scope.esignChecked = true;
          return false;
        }
        srTermsAgreeModal.deactivate();
      };
    }
  ])

  .factory('srNoActionNeededModal', [
    'srModalFactory',
    function(srModalFactory) {
      var modal = srModalFactory.create({
        controller: 'NoActionNeededModalCtrl',
        controllerAs: 'modal',
        template: require('../../partials/modals/no-action-needed.html')
      });
      return modal;
    }
  ])
  .controller('NoActionNeededModalCtrl', [
    '$scope',
    '$rootScope',
    'srNoActionNeededModal',
    'docService',
    function($scope, $rootScope, srNoActionNeededModal, docService) {
      $scope.docService = docService;
      $scope.closeModal = srNoActionNeededModal.deactivate;
    }
  ])

  .factory('srDeclineModal', [
    'srModalFactory',
    function(srModalFactory) {
      var modal = srModalFactory.create({
        controller: 'DeclineModalCtrl',
        controllerAs: 'modal',
        template: require('../../partials/modals/decline.html')
      });

      return modal;
    }
  ])
  .controller('DeclineModalCtrl', [
    '$scope',
    'srDeclineModal',
    function($scope, srDeclineModal) {
      $scope.closeModal = srDeclineModal.deactivate;
      $scope.loading = false;
      $scope.message = '';
      $scope.agreeDecline = function(event) {
        event.preventDefault();
        $scope.loading = true;
        if (!$scope.save) {
          srDeclineModal.resolveToValue({
            declined: true,
            message: $scope.message
          });
        } else {
          $scope.save(
            function() {
              $scope.closeModal();
            },
            $scope.message,
            true
          );
        }
        return false;
      };
    }
  ])

  .factory('srPublicTemplateModal', [
    'srModalFactory',
    function(srModalFactory) {
      var modal = srModalFactory.create({
        controller: 'PublicTemplateCtrl',
        controllerAs: 'modal',
        template: require('../../partials/modals/public-template.html')
      });
      return modal;
    }
  ])
  .controller('PublicTemplateCtrl', [
    '$scope',
    'srPublicTemplateModal',
    function($scope, srPublicTemplateModal) {
      $scope.closeModal = srPublicTemplateModal.deactivate;
    }
  ])

  .factory('srRequireAttachmentModal', [
    'srModalFactory',
    function(srModalFactory) {
      var modal = srModalFactory.create({
        controller: 'srRequireAttachmentCtrl',
        controllerAs: 'modal',
        template: require('../../partials/modals/require-attachment.html')
      });
      return modal;
    }
  ])
  .controller('srRequireAttachmentCtrl', [
    '$scope',
    'srRequireAttachmentModal',
    function($scope, srRequireAttachmentModal) {
      $scope.closeModal = srRequireAttachmentModal.deactivate;
      $scope.attachmentValid = true;
      $scope.addRequiredAttachment = function() {
        if (
          angular.isDefined($scope.signer.required_attachments) &&
          $scope.signer.required_attachments.indexOf($scope.attachment) > -1
        ) {
          $scope.attachmentValid = false;
        } else {
          $scope.callback($scope.signer, $scope.attachment);
          $scope.closeModal();
        }
      };
    }
  ])

  .factory('srSetPasswordModal', [
    'srModalFactory',
    function(srModalFactory) {
      var modal = srModalFactory.create({
        controller: 'srSetPasswordCtrl',
        controllerAs: 'modal',
        template: require('../../partials/modals/set-password.html')
      });
      return modal;
    }
  ])

  .controller('srSetPasswordCtrl', [
    '$scope',
    'srSetPasswordModal',
    function($scope, srSetPasswordModal) {
      $scope.closeModal = srSetPasswordModal.deactivate;

      $scope.setPassword = function() {
        $scope.callback($scope.signer, $scope.password);
        $scope.closeModal();
      };
    }
  ])

  .factory('srEnterPasswordModal', [
    'srModalFactory',
    function(srModalFactory) {
      var modal = srModalFactory.create({
        controller: 'srEnterPasswordCtrl',
        controllerAs: 'modal',
        template: require('../../partials/modals/enter-password.html')
      });
      return modal;
    }
  ])

  .controller('srEnterPasswordCtrl', [
    '$scope',
    'confService',
    'srEnterPasswordModal',
    '$route',
    '$http',
    'messageService',
    function(
      $scope,
      confService,
      srEnterPasswordModal,
      $route,
      $http,
      messageService
    ) {
      $scope.confService = confService;
      $scope.closeModal = srEnterPasswordModal.deactivate;

      $scope.EnterPassword = function() {
        return $http
          .post('/docs/unlock-doc/' + $scope.signer_uuid + '/', {
            password: $scope.password
          })
          .then(
            function(resp) {
              if ($scope.noReload) {
                srEnterPasswordModal.resolveToValue(resp);
              } else {
                $route.reload();
              }
              $scope.closeModal();
            },
            function(resp) {
              return messageService.handleError(resp);
            }
          );
      };
    }
  ])
  .factory('srCSVBulkSendModal', [
    'srModalFactory',
    function(srModalFactory) {
      var modal = srModalFactory.create({
        controller: 'CSVBulkSendModalCtrl',
        controllerAs: 'modal',
        template: require('../../partials/modals/bulk-send-template.html')
      });

      return modal;
    }
  ])
  .controller('CSVBulkSendModalCtrl', [
    '$scope',
    'srCSVBulkSendModal',
    '$window',
    'Upload',
    'messageService',
    'gettextCatalog',
    'bulkSendService',
    'validation',
    function(
      $scope,
      srBulkSendModal,
      $window,
      Upload,
      messageService,
      gettextCatalog,
      bulkSendService,
      validation
    ) {
      $scope.closeModal = srBulkSendModal.deactivate;
      $scope.dropSupported = false;
      $scope.uploading = false;
      $scope.converting = false;
      $scope.isDocumentFile = false;
      $scope.resultReady = false;
      $scope.hasFlash = $window.FileAPI.hasFlash;
      $scope.$watch(
        function() {
          return $window.FileAPI.hasFlash;
        },
        function(n, o) {
          if (n !== o) {
            $scope.hasFlash = $window.FileAPI.hasFlash;
          }
        }
      );

      $scope.bulkSend = function() {
        $scope.closeModal();
        $scope.emails = $scope.unparseEmails($scope.emails);
        $scope.callback($scope.results);
      };

      $scope.parseEmails = function(emails) {
        $scope.bulk = true;
        emails = emails || [];
        if (!emails.length) {
          return '\n\n';
        }
        return _.join(emails, '\n') + '\n';
      };

      $scope.unparseEmails = function(emails_string) {
        emails_string = _.replace(emails_string, new RegExp(',|;', 'g'), '\n');
        var mails = emails_string.split('\n');
        mails = _.map(mails, function(item) {
          return _.toLower(_.trim(item));
        });
        return _.filter(mails, validation.email);
      };

      $scope.onFileSelect = function($files) {
        var onProgress = function(evt) {
            $scope.uploadedPercentage = parseInt(
              (100.0 * evt.loaded) / evt.total
            );
          },
          onSuccess = function(data) {
            if (data.error) {
              return onError(data);
            }
            $scope.uploading = false;
            $scope.converting = true;
            bulkSendService.checkResult(data.task_id).then(
              function(data) {
                $scope.converting = false;
                if (data.emails.length) {
                  $scope.resultReady = true;
                  $scope.emails =
                    _.trim(
                      _.join(
                        [$scope.emails, $scope.parseEmails(data.emails)],
                        '\n'
                      ),
                      '\n'
                    ) + '\n';
                  // todo: callback is undefined here, is this code used?
                  // callback(data.results);
                  $scope.closeModal();
                }
              },
              function(data) {
                // error
                $scope.converting = false;
              }
            );
          },
          onError = function(data) {
            // an error occurred
            messageService.handleError(data);
            $scope.uploading = false;
          };
        $scope.uploading = true;

        for (var i = 0; i < $files.length; i++) {
          var file = $files[i];
          $scope.upload = Upload.upload({
            url: '/docs/get-emails-from-file/',
            method: 'POST',
            data: { file: file }
          })
            .progress(onProgress)
            .success(onSuccess)
            .error(onError);
        }
      };

      $scope.emails = $scope.parseEmails($scope.emails);
    }
  ])
  .factory('srUploadReplaceModal', [
    'srModalFactory',
    function(srModalFactory) {
      var modal = srModalFactory.create({
        controller: 'UploadReplaceOriginalFileModalCtrl',
        controllerAs: 'modal',
        template: require('../../partials/modals/upload.html')
      });
      return modal;
    }
  ])
  .directive('openInputFile', [
    '$timeout',
    function($timeout) {
      return {
        restrict: 'A',
        link: function(scope, element, attrs) {
          scope.$watch('openInputFile', function(trigger) {
            if (angular.isDefined(trigger) && trigger) {
              $timeout(function() {
                angular.element(element).trigger('click');
              });
            }
          });
        }
      };
    }
  ])
  .controller('UploadReplaceOriginalFileModalCtrl', [
    '$scope',
    'Upload',
    'srUploadModal',
    'messageService',
    '$window',
    'gettextCatalog',
    'docService',
    '$timeout',
    'utils',
    'UserService',
    function(
      $scope,
      Upload,
      srUploadModal,
      messageService,
      $window,
      gettextCatalog,
      docService,
      $timeout,
      utils,
      UserService
    ) {
      $scope.closeModal = srUploadModal.deactivate;
      $scope.dropSupported = false;
      $scope.uploading = false;
      $scope.converting = false;
      $scope.hasFlash = window.FileAPI.hasFlash;
      $scope.$watch(
        function() {
          return $window.FileAPI.hasFlash;
        },
        function(n, o) {
          if (n !== o) {
            $scope.hasFlash = $window.FileAPI.hasFlash;
          }
        }
      );

      $scope.$watch('triggerOpenFileChoice', function(trigger) {
        if (angular.isDefined(trigger) && trigger) {
          $scope.openInputFile = true;
        }
      });

      $scope.onFileSelect = function($files) {
        var onProgress = function(evt) {
            $scope.uploadedPercentage = parseInt(
              (100.0 * evt.loaded) / evt.total
            );
          },
          onSuccess = function(data) {
            if (data.error) {
              return onError(data);
            }
            // file is uploaded successfully
            $scope.uploading = false;

            $scope.converting = true;
            docService.getConverted($scope.doc).then(
              function(data) {
                $scope.converting = false;
                $scope.closeModal();
                // go to the prepare url of the template
                $window.location.reload();
              },
              function() {
                $scope.converting = false;
                $scope.closeModal();
              }
            );
          },
          onError = function(data) {
            // an error occurred
            messageService.handleError(data);
            $scope.uploading = false;
            $scope.closeModal();
          };

        $scope.uploading = true;
        //$files: an array of files selected, each file has name, size, and type.
        var file = $files[0];

        var url = '/docs/replace-original-file/';

        if ($scope.is_template) {
          url = url + 'template/' + $scope.doc.uuid + '/';
        } else {
          url = url + 'signrequest/' + $scope.doc.uuid + '/';
        }

        $scope.upload = Upload.upload({
          url: url, //upload.php script, node.js route, or servlet url

          method: 'POST',
          // headers: {'header-key': 'header-value'},
          // withCredentials: true,
          //          data: {},
          data: { file: file } // or list of files: $files for html5 only
          /* set the file formData name ('Content-Desposition'). Default is 'file' */
          //fileFormDataName: myFile, //or a list of names for multiple files (html5).
          /* customize how data is added to formData. See #40#issuecomment-28612000 for sample code */
          //formDataAppender: function(formData, key, val){}
        })
          .progress(onProgress)
          .success(onSuccess)
          .error(onError);
        //.then(success, error, progress);
        //.xhr(function(xhr){xhr.upload.addEventListener(...)})// access and attach any event listener to XMLHttpRequest.
        /* alternative way of uploading, send the file binary with the file's content-type.
       Could be used to upload files to CouchDB, imgur, etc... html5 FileReader is needed.
       It could also be used to monitor the progress of a normal http post/put request with large data*/
        // $scope.upload = Upload.http({...})  see 88#issuecomment-31366487 for sample code.
      };
    }
  ])

  .factory('srBulkSendModal', [
    'srModalFactory',
    function(srModalFactory) {
      var modal = srModalFactory.create({
        controller: 'BulkSendModalCtrl',
        controllerAs: 'modal',
        template: require('../../partials/modals/bulk-send-template.html')
      });

      return modal;
    }
  ])
  .controller('BulkSendModalCtrl', [
    '$scope',
    'srBulkSendModal',
    '$window',
    'Upload',
    'messageService',
    'gettextCatalog',
    'bulkSendService',
    'validation',
    function(
      $scope,
      srBulkSendModal,
      $window,
      Upload,
      messageService,
      gettextCatalog,
      bulkSendService,
      validation
    ) {
      $scope.closeModal = srBulkSendModal.deactivate;
      $scope.dropSupported = false;
      $scope.uploading = false;
      $scope.converting = false;
      $scope.isDocumentFile = false;
      $scope.resultReady = false;
      $scope.results = [];
      $scope.hasFlash = $window.FileAPI.hasFlash;
      $scope.header_url =
        '/docs/get-bulk-send/' + $scope.template_uuid + '/csv/?get_headers=1';
      $scope.numberOfResults = 0;

      $scope.$watch(
        function() {
          return $window.FileAPI.hasFlash;
        },
        function(n, o) {
          if (n !== o) {
            $scope.hasFlash = $window.FileAPI.hasFlash;
          }
        }
      );

      $scope.bulkSend = function() {
        if (!$scope.resultReady && $scope.emailsPasted()) {
          $scope.emailListAsResults();
        } else {
          $scope.callback({
            results: $scope.results,
            task_id: $scope.task_id,
            bulksend_id: $scope.bulksend_id,
            emailsPasted: $scope.emailsPasted()
          });
          $scope.closeModal();
        }
      };

      $scope.parseEmails = function(emails) {
        emails = emails || [];
        if (!emails.length) {
          return '';
        }
        return _.join(emails, '\n') + '\n';
      };

      $scope.unparseEmails = function(emails_string) {
        emails_string = _.replace(emails_string, new RegExp(',|;', 'g'), '\n');
        var mails = emails_string.split('\n');
        mails = _.map(mails, function(item) {
          return _.toLower(_.trim(item));
        });
        return _.filter(mails, validation.email);
      };

      $scope.emailListAsResults = function() {
        var emails = $scope.unparseEmails($scope.emails);
        $scope.results = [];
        angular.forEach(emails, function(email) {
          $scope.results.push({
            contact_1_email: email,
            list_name: email
          });
        });
        if ($scope.results.length) {
          $scope.numberOfResults = $scope.results.length;
          $scope.resultReady = true;
        }
      };

      $scope.emailsPasted = function() {
        return !!$scope.emails.trim();
      };

      $scope.onFileSelect = function($files) {
        var onProgress = function(evt) {
            $scope.uploadedPercentage = parseInt(
              (100.0 * evt.loaded) / evt.total
            );
          },
          onSuccess = function(data) {
            if (data.error) {
              return onError(data);
            }
            $scope.uploading = false;
            $scope.converting = true;
            $scope.task_id = data.task_id;
            $scope.bulksend_id = data.bulksend_id;
            bulkSendService
              .checkResult($scope.template_uuid, data.task_id)
              .then(
                function(data) {
                  $scope.converting = false;
                  $scope.resultReady = true;
                  $scope.results = data.results;
                  $scope.errors = data.errors;
                  $scope.task_id = data.task_id;
                  $scope.numberOfResults = $scope.results.length;
                  if (!$scope.errors.length) {
                    $scope.callback({
                      results: $scope.results,
                      task_id: $scope.task_id,
                      bulksend_id: $scope.bulksend_id
                    });
                    $scope.closeModal();
                  }
                },
                function(data) {
                  // error
                  $scope.converting = false;
                }
              );
          },
          onError = function(data) {
            // an error occurred
            messageService.handleError(data);
            $scope.uploading = false;
          };
        $scope.uploading = true;

        for (var i = 0; i < $files.length; i++) {
          var file = $files[i];

          $scope.upload = Upload.upload({
            url: '/docs/get-bulk-send/' + $scope.template_uuid + '/csv/',
            method: 'POST',
            data: { file: file }
          })
            .progress(onProgress)
            .success(onSuccess)
            .error(onError);
        }
      };

      $scope.emails = $scope.parseEmails($scope.emails);
    }
  ]);
