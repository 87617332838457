<template>
  <div data-test-id="team-members">
    <h2 v-translate>Members</h2>
    <span v-if="disabled" key="d" v-translate>
      You do not have a team activated.
    </span>
    <div v-else class="sr-layout">
      <v-form
        ref="inviteForm"
        name="inviteMemberForm"
        class="form sr-layout__item one-whole"
        @submit.prevent="handleInvite"
      >
        <sr-input
          ref="email"
          v-model="email"
          horizontal
          name="email_to_invite"
          :label="$gettext('Invite member by email')"
          :placeholder="$gettext('Email')"
          :error-messages="emailErrors"
          @blur="emailErrors = email ? emailErrors : null"
        >
          <template v-slot:append-outer>
            <sr-button class="member--invite" type="submit">
              <translate>Invite</translate>
            </sr-button>
          </template>
        </sr-input>
      </v-form>
    </div>

    <div class="member--list">
      <team-member
        v-for="member of teamMembers"
        :key="member.uuid"
        class="member--item"
        :member="member"
        :readonly="readonly"
      ></team-member>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { validateEmail } from '@/utils/format';
import SrButton from '@/components/elements/SrButton';
import SrInput from '@/components/elements/SrInput';
import TeamMember from '@/components/team/TeamMember';

export default {
  components: { SrButton, SrInput, TeamMember },
  props: {
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false }
  },
  data() {
    return { email: '', emailErrors: null };
  },
  computed: {
    ...mapGetters('users', ['teamMembers']),
    emailRule() {
      return validateEmail.bind(this);
    }
  },
  methods: {
    ...mapActions('users', ['inviteMember']),
    async handleInvite() {
      const ifError = value => (value === true ? null : value);
      this.emailErrors = ifError(this.emailRule(this.email));
      if (this.emailErrors) {
        return;
      }
      if (await this.inviteMember(this.email)) {
        this.email = '';
      }
    }
  }
};
</script>
<style scoped>
.sr-button.member--invite {
  margin: 0;
  margin-top: 10px;
}
.ltr .member--invite,
.ltr .member--item button {
  margin-left: auto;
}
.rtl .member--invite,
.rtl .member--item button {
  margin-right: auto;
}
</style>
