import angular from 'angular';
import _ from 'lodash';
import getCheckboxImage from '@/components/elements/getCheckboxImage';
import { dropEmpty } from '@/utils/dropEmpty';
import jstz from '@/utils/timeZone';

/**
 * @ngdoc service
 * @name frontendApp.docService
 * @description
 * # docService
 * Service in the frontendApp.
 */
angular.module('frontendApp').service('docService', [
  '$http',
  'messageService',
  'gettextCatalog',
  '$window',
  '$sce',
  '$location',
  '$route',
  '$timeout',
  '$q',
  '$httpParamSerializer',
  'utils',
  '$vuex',
  function docService(
    $http,
    messageService,
    gettextCatalog,
    $window,
    $sce,
    $location,
    $route,
    $timeout,
    $q,
    $httpParamSerializer,
    utils,
    $vuex
  ) {
    var service = this;

    this.getNewDocuments = function(doc_uuid) {
      var url = '/docs/user-docs/';
      return $http.get(url, { params: { doc_uuid: doc_uuid } }).then(
        function(resp) {
          return messageService.handleResponse(resp);
        },
        function(resp) {
          return messageService.handleError(resp);
        }
      );
    };

    function getDocumentSearchParams(page, limit, filter, format) {
      var params = { limit: limit, page: page };
      if (format) {
        params.format = format;
      }
      if (filter.status && filter.status !== '*') {
        params.status = filter.status;
      }
      if (filter.q) {
        params.autocomplete = filter.q;
      }
      return params;
    }

    this.searchDocuments = function(page, limit, filter, format) {
      const url = '/api/v1/documents-search/';

      const params = getDocumentSearchParams(page, limit, filter, format);

      return $http.get(url, { params: params }).catch(function(resp) {
        return messageService.handleError(resp);
      });
    };

    this.getApiDocument = function(conf) {
      conf = conf || {};
      return $http.post('/docs/upload/', conf).then(
        function(resp) {
          return messageService.handleResponse(resp);
        },
        function(resp) {
          return messageService.handleError(resp);
        }
      );
    };

    this.getDocument = function(uuid, signer_uuid, signer_token, reloading) {
      var url = '/docs/user-doc/' + uuid + '/';
      if (angular.isDefined(signer_uuid)) {
        url += signer_uuid + '/';
      }
      if (angular.isDefined(reloading) && reloading) {
        url += url.includes('?') ? '&' : '?';
        url += 'reloading=1';
      }
      const headers = dropEmpty({
        'Signer-Token': signer_token || null
      });
      return $http.get(url, { headers }).then(
        function(resp) {
          if (!resp.data.expired) {
            const emails = [];
            resp.data.doc.signrequest.signers.forEach(function(signer, index) {
              emails.push(signer.email);
              signer.signer_index = index;
            });
            resp.data.doc.pages.forEach(page => {
              page.placeholders.forEach(placeholder => {
                placeholder.signer_index = emails.indexOf(
                  placeholder.signer_email
                );
              });
            });
          }
          return messageService.handleResponse(resp);
        },
        function(resp) {
          return messageService.handleError(resp);
        }
      );
    };

    this.createTemplateFromDoc = function(doc_uuid) {
      return $http.post('/docs/save-template/', { doc_uuid: doc_uuid }).then(
        function(resp) {
          return messageService.handleResponse(resp);
        },
        function(resp) {
          return messageService.handleError(resp);
        }
      );
    };

    this.newSignRequestFromTemplate = function(template_uuid) {
      var searchParams = { api: 'v1', template_uuid: template_uuid };
      $location.path('/');
      $location.search(searchParams);
    };

    this.bulksendFromTemplate = function(
      from_email,
      template_uuid,
      emaillist,
      integration_data,
      integration_type
    ) {
      // used in the main SignRequest box
      var url = '/docs/signrequest/';
      var data = {};
      data.from_email = from_email;
      data.doc = {};
      data.bulk = true;
      data.template_uuid = template_uuid;
      data.emaillist = emaillist;
      if (
        angular.isDefined(integration_data) &&
        angular.isDefined(integration_type)
      ) {
        data.integration_data = integration_data;
        data.integration_type = integration_type;
      }

      return $http.post(url, data).then(
        function(resp) {
          return messageService.handleResponse(resp);
        },
        function(resp) {
          return messageService.handleError(resp);
        }
      );
    };

    this.resendSignRequestEmail = function(signrequest_uuid) {
      var url =
        '/api/v1/signrequests/' +
        signrequest_uuid +
        '/resend_signrequest_email/';
      return $http.post(url).then(
        function(resp) {
          resp.data.message = gettextCatalog.getString(
            'The SignRequest email has been sent.'
          );
          return messageService.handleResponse(resp);
        },
        function(resp) {
          return messageService.handleError(resp);
        }
      );
    };

    this.getDocumentStatus = function(uuid) {
      var url = '/docs/doc-status/' + uuid + '/';
      return $http.get(url).then(
        function(resp) {
          return messageService.handleResponse(resp);
        },
        function(resp) {
          return messageService.handleError(resp);
        }
      );
    };

    this.isSigned = function(doc) {
      return (
        angular.isObject(doc) &&
        (doc.status.code === 'si' || doc.status.code === 'sd')
      );
    };

    this.isCancelled = function(doc) {
      return angular.isObject(doc) && doc.status.code === 'ca';
    };

    this.isDeclined = function(doc) {
      return angular.isObject(doc) && doc.status.code === 'de';
    };

    this.statusClass = function(doc) {
      if (angular.isObject(doc)) {
        switch (doc.status.code || doc.status) {
          case 'si': // signed
            return 'status--success';
          case 'do': // downloaded
            return 'status--success';
          case 'sd': // signed and downloaded
            return 'status--success';
          case 'de': // declined
            return 'status--error';
          case 'ca': // cancelled
            return 'status--error';
          case 'ec': // error convert
            return 'status--error';
          case 'es': // error sending
            return 'status--error';
          default:
            return 'status--waiting';
        }
      }
    };

    this.getSendButtonDisplay = function(signrequest) {
      if (angular.isObject(signrequest)) {
        if (signrequest.who === 'm') {
          return gettextCatalog.getString('Sign');
        }
        if (signrequest.who === 'mo') {
          return gettextCatalog.getString('Sign and send');
        }
        if (signrequest.who === 'o') {
          return gettextCatalog.getString('Send');
        }
      }
      return '';
    };

    this.getSignerAttachments = function(uuid, signer_uuid, signer_token) {
      const headers = dropEmpty({
        'Signer-Token': signer_token || null
      });
      var url =
        '/docs/get-signer-attachments/' + uuid + '/' + signer_uuid + '/';
      return $http.get(url, { headers }).then(
        function(resp) {
          return messageService.handleResponse(resp);
        },
        function(resp) {
          return messageService.handleError(resp);
        }
      );
    };

    this.getStampedSignature = function(data_uri, uuid, signer_uuid) {
      var url = '/docs/signature-stamp/' + uuid + '/' + signer_uuid + '/';
      return $http
        .post(url, {
          data_uri: data_uri,
          timezone: jstz.determine().name()
        })
        .then(
          function(resp) {
            return messageService.handleResponse(resp);
          },
          function(resp) {
            return messageService.handleError(resp);
          }
        );
    };

    this.getDocumentAttachments = function(uuid, signer_uuid, signer_token) {
      const headers = dropEmpty({
        'Signer-Token': signer_token || null
      });
      var url =
        '/docs/get-document-attachments/' + uuid + '/' + signer_uuid + '/';
      return $http.get(url, { headers }).then(
        function(resp) {
          return messageService.handleResponse(resp);
        },
        function(resp) {
          return messageService.handleError(resp);
        }
      );
    };

    this.getTemplateAttachments = function(uuid) {
      var url = '/docs/get-template-attachments/' + uuid + '/';
      return $http.get(url).then(
        function(resp) {
          return messageService.handleResponse(resp);
        },
        function(resp) {
          return messageService.handleError(resp);
        }
      );
    };

    this.deleteSignerAttachment = function(
      signer_attachment_uuid,
      signer_uuid,
      isDocumentAttachment
    ) {
      var url;
      if (isDocumentAttachment) {
        url =
          '/docs/delete-document-attachment/' +
          signer_attachment_uuid +
          '/' +
          signer_uuid +
          '/';
      } else {
        url =
          '/docs/delete-signer-attachment/' +
          signer_attachment_uuid +
          '/' +
          signer_uuid +
          '/';
      }
      return $http.post(url).then(
        function(resp) {
          return messageService.handleResponse(resp);
        },
        function(resp) {
          return messageService.handleError(resp);
        }
      );
    };

    this.deleteTemplateAttachment = function(template_attachment_uuid) {
      var url =
        '/docs/delete-template-attachment/' + template_attachment_uuid + '/';

      return $http.post(url).then(
        function(resp) {
          return messageService.handleResponse(resp);
        },
        function(resp) {
          return messageService.handleError(resp);
        }
      );
    };

    this.checkRequiredSignerAttachments = function(doc_uuid, signer_uuid) {
      var url =
        '/docs/check-required-attachments/' +
        doc_uuid +
        '/' +
        signer_uuid +
        '/';
      return $http.get(url).then(
        function(resp) {
          return messageService.handleResponse(resp);
        },
        function(resp) {
          return messageService.handleError(resp);
        }
      );
    };

    this.verifySignerPhone = function(signer_uuid, code) {
      return $http
        .post('/verifications/text/verify-text-message/', {
          signer_uuid: signer_uuid,
          code: code
        })
        .then(
          function(resp) {
            return messageService.handleResponse(resp);
          },
          function(resp) {
            return messageService.handleError(resp);
          }
        );
    };

    this.sendTextVerificationMessage = function(signer_uuid) {
      return $http
        .post('/verifications/text/send-text-message/', {
          signer_uuid: signer_uuid
        })
        .then(
          function(resp) {
            return messageService.handleResponse(resp);
          },
          function(resp) {
            return messageService.handleError(resp);
          }
        );
    };

    this.createBankAccountVerification = function(signer_uuid) {
      return $http
        .post('/verifications/bank-account/create-payment/', {
          signer_uuid: signer_uuid
        })
        .then(
          function(resp) {
            let data = resp.data;
            if (data.redirect_url) {
              $window.location = utils.redirectFilter(data.redirect_url);
            }
            return messageService.handleResponse(resp);
          },
          function(resp) {
            return messageService.handleError(resp);
          }
        );
    };

    this.deleteDocument = function(uuid) {
      var url = '/docs/del-doc/' + uuid + '/';
      return $http.post(url).then(
        function(resp) {
          return messageService.handleResponse(resp);
        },
        function(resp) {
          return messageService.handleError(resp);
        }
      );
    };

    this.softDeleteDocument = function(uuid) {
      var url = '/docs/soft-del-doc/' + uuid + '/';
      return $http.post(url).then(
        function(resp) {
          return messageService.handleResponse(resp);
        },
        function(resp) {
          return messageService.handleError(resp);
        }
      );
    };

    this.cancelDocument = function(uuid) {
      var url = '/docs/cancel-doc/' + uuid + '/';
      return $http.post(url).then(
        function(resp) {
          return messageService.handleResponse(resp);
        },
        function(resp) {
          return messageService.handleError(resp);
        }
      );
    };

    this.deleteReminders = function(uuid) {
      var url = '/docs/delete_reminders/' + uuid + '/';
      return $http.post(url).then(
        function(resp) {
          return messageService.handleResponse(resp);
        },
        function(resp) {
          return messageService.handleError(resp);
        }
      );
    };

    this.forwardDocument = function(
      doc_uuid,
      signer_uuid,
      email,
      forwarded_reason
    ) {
      var data = { email: email };
      if (forwarded_reason) {
        data.forwarded_reason = forwarded_reason;
      }
      var url = '/docs/forward-document/' + doc_uuid + '/' + signer_uuid + '/';
      return $http.post(url, data).then(
        function(resp) {
          return messageService.handleResponse(resp);
        },
        function(resp) {
          return messageService.handleError(resp);
        }
      );
    };

    this.getLabelForSignerIndex = function(signer_index) {
      if (signer_index === 0) {
        return gettextCatalog.getString('Sender (you)');
      } else {
        return (
          gettextCatalog.getString('Contact') + ' ' + signer_index.toString()
        );
      }
    };

    this.newSigner = function(conf) {
      conf = conf || {};
      // we use a 'fake' email here as the placeholder
      // email is not used in the backend for templates
      if (!conf.label && angular.isNumber(conf.signer_index)) {
        conf.label = service.getLabelForSignerIndex(conf.signer_index);
      }

      var signer = {
        email: conf.email,
        signer_index: conf.signer_index,
        name: conf.name || conf.email,
        order: conf.order || 0,
        level: conf.level || 'signature'
      };

      signer.updateIndex = function(signer_index) {
        signer.signer_index = signer_index;
        signer.label =
          signer.label || service.getLabelForSignerIndex(signer.signer_index);
      };

      return signer;
    };

    this.doSignRequest = function(data) {
      // used in the main SignRequest box
      var url = '/docs/signrequest/';
      data.doc = data.doc || {};
      return $http.post(url, data).then(
        function(resp) {
          return messageService.handleResponse(resp);
        },
        function(resp) {
          return messageService.handleError(resp);
        }
      );
    };

    this.saveDocPrepare = function(doc_uuid, signer_uuid, doc, background) {
      var url = '/docs/save-doc-prepare/' + doc_uuid + '/' + signer_uuid + '/';
      doc.pages.forEach(page => {
        page.editors.forEach(editor => {
          const signer =
            doc.signrequest.signers[editor.placeholder.signer_index];
          if (signer) {
            editor.placeholder.signer_email = signer.email;
          }
        });
      });

      return $http.post(url, { doc: doc }).then(
        function(resp) {
          return messageService.handleResponse(resp, 7000, background);
        },
        function(resp) {
          return messageService.handleError(resp);
        }
      );
    };

    this.getTemplates = function() {
      return $http.get('/docs/get-templates/').then(
        function(resp) {
          return messageService.handleResponse(resp);
        },
        function(resp) {
          return messageService.handleError(resp);
        }
      );
    };

    this.getTemplate = function(uuid) {
      return $http.get('/docs/get-template/' + uuid + '/').then(
        function(resp) {
          let data = resp.data;
          if (
            angular.isObject(data.doc.signrequest) &&
            data.doc.signrequest.signers
          ) {
            _.each(data.doc.signrequest.signers, function(signer) {
              signer.email = signer.email || signer.label || '';
              signer.label = '';
            });
          }
          return messageService.handleResponse(resp);
        },
        function(resp) {
          return messageService.handleError(resp);
        }
      );
    };

    this.getPublicTemplate = function(id) {
      return $http.get('/docs/get-public-template/' + id + '/').then(
        function(resp) {
          return messageService.handleResponse(resp);
        },
        function(resp) {
          return messageService.handleError(resp);
        }
      );
    };

    this.getConverted = function(doc) {
      var deferred = $q.defer();
      if (!doc) {
        deferred.reject();
      }

      var _get = function() {
        var url = '/docs/converted/' + doc.uuid + '/';
        if (doc.template_url) {
          url = url + '?is_template=1';
        }
        $http.get(url).then(
          function(resp) {
            let data = resp.data;
            if (!data.ready && !data.error) {
              // retry this task id again a bit later as it didn't finish yet
              $timeout(function() {
                _get();
              }, 1000);
            } else {
              deferred.resolve(data);
            }
          },
          function(resp) {
            messageService.handleError(resp);
            deferred.reject();
          }
        );
      };

      if (doc) {
        _get();
      }

      return deferred.promise;
    };

    this.saveTemplate = function(uuid, doc, background) {
      var url = '/docs/save-template/';
      if (doc.signrequest.signers) {
        const signers = doc.signrequest.signers.map(function(signer, index) {
          const key = /.+@.+/.test(signer.email || '') ? 'email' : 'label';
          const ret = {
            ...signer,
            signer_index: index,
            email: '',
            label: '',
            [key]: signer.email || ''
          };
          ret.label = ret.label.substr(0, 255) || '';
          return ret;
        });
        doc = {
          ...doc,
          signrequest: {
            ...doc.signrequest,
            signers
          }
        };
      }
      return $http.post(url, { doc: doc, uuid: uuid }).then(
        function(resp) {
          return messageService.handleResponse(resp, 7000, background);
        },
        function(resp) {
          return messageService.handleError(resp);
        }
      );
    };

    this.sendSignRequest = function(doc_uuid, signer_uuid, doc, bulk) {
      // used after preparing a document and sending the signrequest
      var url = '/docs/send-signrequest/' + doc_uuid + '/' + signer_uuid + '/';
      return $http.post(url, { doc: doc, bulk: bulk }).then(
        function(resp) {
          return messageService.handleResponse(resp);
        },
        function(resp) {
          return messageService.handleError(resp);
        }
      );
    };

    this.newSig = function(conf) {
      conf = conf || {};
      return {
        editable: true, // only the owner can change a signature (enforced in backend)
        uuid: '',
        page_uuid: conf.page_uuid || '',
        data_uri: conf.data_uri || '',
        text: conf.text || '',
        x_pos: conf.x_pos || 0,
        y_pos: conf.y_pos || 0,
        width: conf.width_p || conf.width || 0,
        //start_width: conf.width_p || conf.width || 0,
        height: conf.height_p || conf.height || 0
      };
    };

    this.sigantureTypeTranslations = {
      s: gettextCatalog.getString('Signature'),
      i: gettextCatalog.getString('Initials'),
      n: gettextCatalog.getString('Name'),
      t: gettextCatalog.getString('Text'),
      d: gettextCatalog.getString('Date'),
      c: '' // we do not show a 'Checkbox' in the placeholder but only the icon
    };

    this.initial_placeholder_width = 325;
    this.initial_placeholder_height = 160;

    this.initial_placeholder_text_width = 325;
    this.initial_placeholder_text_height = 45;

    this.initial_placeholder_checkbox_width = 45;
    this.initial_placeholder_checkbox_height = 45;

    this.checkbox_true_char = '✓';
    this.checkbox_false_char = '✗';

    this.getCheckboxImg = function(checked) {
      if (!checked) {
        // empty image
        return { src: '', width: 0, height: 0 };
      } else {
        return getCheckboxImage();
      }
    };

    this.newPlaceholder = function() {
      return {
        type: 's', // signature
        required: true,
        prefill: true,
        repeat_on_all: false,
        editable: true,
        uuid: '',
        doc_uuid: '',
        page_uuid: '',
        signer_email: '',
        signer_index: null, // for templates only
        comment: '',
        data_uri: '',
        text: '',
        x_pos: 0,
        y_pos: 0,
        width: 0,
        height: 0
      };
    };

    this.newSigImage = function() {
      return {
        src: '',
        class: '',
        width: '',
        height: '',
        text: ''
      };
    };

    this.newEditor = function(opts) {
      // x_pos, y_pos, show, loaded, sig
      opts = opts || {};
      var is_placeholder = opts.is_placeholder || false;
      var placeholder = opts.placeholder || this.newPlaceholder();
      var sig =
        typeof opts.sig === 'object'
          ? opts.sig
          : this.newSig(is_placeholder ? placeholder : {});
      var editor = {
        x_pos: opts.x_pos || 0, // if 0 we calculate it from sig.x_pos and the page width in doc-page.js
        y_pos: opts.y_pos || 0,
        show: opts.show && sig.editable,
        sig: sig,
        loaded: opts.loaded, // set to true when the x_pos and y_pos are converted in doc-page.js
        init: false,
        saved: false,
        active: false,
        is_placeholder: is_placeholder,
        placeholder: is_placeholder ? placeholder : {}
      };

      editor.updateCss = function() {
        editor.sig.css = {
          width: editor.sig.width * 100 + '%',
          height: editor.sig.height * 100 + '%'
        };
      };

      editor.isEditable = function(prepare_doc) {
        if (editor.sig.editable && !editor.sig.saved) {
          // scope.editor.sig.saved = true means that this signature form someone else, hence we cannot change it

          if (editor.is_placeholder && !prepare_doc) {
            if (!editor.placeholder.action_required) {
              // only when we are preparing a doc or when we need to fill a placeholder we can edit it
              return false;
            }

            if (
              editor.placeholder.action_required &&
              editor.placeholder.prefill
            ) {
              // when a placeholder is prefilled
              return false;
            }
          }

          // normal signing procedure (not prepared)
          return true;
        }
        return false;
      };

      editor.isDraggable = function(prepare_doc) {
        if (!editor.isEditable(prepare_doc)) {
          return false;
        }
        if (editor.placeholder.action_required && !prepare_doc) {
          return false;
        }
        return true;
      };

      editor.isUnfilledPlaceholder = function(doc) {
        return (
          editor.isEditable(doc.prepare_doc) &&
          editor.is_placeholder &&
          !editor.placeholder.filled
        );
      };

      editor.isRequiredPlaceholder = function(doc) {
        return (
          editor.isEditable(doc.prepare_doc) &&
          editor.is_placeholder &&
          editor.placeholder.required
        );
      };

      editor.showArrowForPlaceholder = function(doc) {
        if (editor.isRequiredPlaceholder(doc)) {
          return true;
        }
        // always show the arrow for checkboxes
        return editor.is_placeholder && editor.placeholder.type === 'c';
      };

      return editor;
    };

    this.levelOptions = [
      {
        value: 'signature',
        text: function() {
          return gettextCatalog.getString('Signature');
        }
      },
      {
        value: 'approve',
        text: function() {
          return gettextCatalog.getString('Approve only');
        }
      },
      {
        value: 'notify',
        text: function() {
          return gettextCatalog.getString('Notify (cc)');
        }
      },
      {
        value: 'cc',
        text: function() {
          return gettextCatalog.getString('Copy only');
        }
      },
      {
        value: 'in_person',
        text: function() {
          return gettextCatalog.getString('Sign now in person');
        }
      }
    ];

    this.levelOptionsForOwner = _.filter(this.levelOptions, function(level) {
      return !~['in_person', 'notify'].indexOf(level.value);
    });

    this.levelOptionsForOwnerWithouApprove = _.filter(
      this.levelOptions,
      function(level) {
        return !~['in_person', 'notify', 'approve'].indexOf(level.value);
      }
    );

    this.levelOptionsForRecipient = this.levelOptions;

    this.getLevelOptionsForSigner = function(signer, who) {
      if (signer.is_owner && (who === 'mo' || who === 'o')) {
        return service.levelOptionsForOwner;
      } else if (signer.is_owner && who === 'm') {
        return service.levelOptionsForOwnerWithouApprove;
      }
      return service.levelOptionsForRecipient;
    };

    this.getLevelFromSignerConfig = function(signer) {
      // should be the same as signer.get_level in backend
      if (signer.notify_only) {
        return 'notify';
      }
      if (signer.hasOwnProperty('needs_to_sign') && !signer.needs_to_sign) {
        return 'cc';
      }
      if (signer.approve_only) {
        return 'approve';
      }
      if (signer.in_person) {
        return 'in_person';
      }
      return 'signature';
    };

    this.getLevelIconClass = function(level_value) {
      return {
        cc: 'icon icon--paper-airplane',
        approve: 'icon icon--check',
        signature: 'icon icon--signature',
        in_person: 'icon icon--user',
        notify: 'fa fa-rss'
      }[level_value];
    };

    this.saveSigs = function(conf) {
      conf = conf || {};
      var declined = angular.isDefined(conf.declined) ? conf.declined : false; // when someone declines we still save signatures
      var sigs = _.filter(conf.sigs, function(sig) {
        return sig.editable;
      }); // enforced in backend
      angular.forEach(sigs, function(sig, key) {
        sig.x_pos = sig.x_pos.toString();
        sig.y_pos = sig.y_pos.toString();
        sig.width = sig.width.toString();
        sig.height = sig.height.toString();
      });
      const url = '/docs/user-sig/sigs/save/';
      const data = {
        doc_uuid: conf.doc_uuid,
        signer_uuid: conf.signer_uuid,
        signer_declined: declined,
        message: conf.message || null,
        sigs: sigs
      };
      const headers = dropEmpty({
        'Signer-Token': conf.signer_token || null
      });
      return $http.post(url, data, { headers }).then(
        function(resp) {
          return messageService.handleResponse(resp);
        },
        function(resp) {
          return messageService.handleError(resp);
        }
      );
    };

    this.getEmailLogs = function(uuid) {
      var url = '/docs/doc-status/' + uuid + '/email-logs/';
      return $http.get(url).then(
        function(resp) {
          return messageService.handleResponse(resp);
        },
        function(resp) {
          return messageService.handleError(resp);
        }
      );
    };
    this.textToImg = function(text, doc_uuid, signer_uuid) {
      doc_uuid = doc_uuid || '';
      signer_uuid = signer_uuid || '';
      return $http
        .post('/docs/textimg/', {
          text: text,
          doc_uuid: doc_uuid,
          signer_uuid: signer_uuid
        })
        .then(
          function(resp) {
            return messageService.handleResponse(resp);
          },
          function(resp) {
            return messageService.handleError(resp);
          }
        );
    };
  }
]);
