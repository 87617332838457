export default (focusMethod, closeMethod, activeFlag) => ({
  watch: {
    [activeFlag]: {
      immediate: true,
      handler(value, old) {
        if (value) {
          this.trapFocus();
          this.$nextTick(() => this[focusMethod]());
        } else {
          this.releaseFocus();
        }
      }
    }
  },
  beforeDestroy() {
    this.releaseFocus();
  },
  methods: {
    trapFocus() {
      document.addEventListener('focus', this.focustrapHandleFocus, true);
      document.addEventListener('keyup', this.focustrapHandleKb, true);
    },
    releaseFocus() {
      document.removeEventListener('focus', this.focustrapHandleFocus, true);
      document.addEventListener('keyup', this.focustrapHandleKb, true);
    },
    focustrapHandleKb(event) {
      if (event.key === 'Escape') {
        this[closeMethod]();
      }
    },
    focustrapHandleFocus(event) {
      const isInside = this.$el.contains(event.target);
      if (!isInside) {
        this[focusMethod]();
      }
    }
  }
});
