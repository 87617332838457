<template>
  <v-dialog
    v-if="modalOptions"
    ref="modal"
    v-model="showModal"
    lazy
    max-width="500"
    :content-class="contentClass"
  >
    <v-card
      role="dialog"
      :aria-hidden="!showModal"
      :aria-modal="showModal"
      aria-labelledby="terms-gmodal-title"
      :class="{
        mobile: $isMobile
      }"
    >
      <v-icon class="round close" role="button" @click="closeModal(false)">
        close
      </v-icon>
      <v-card-title id="terms-gmodal-title" class="headline">
        <span v-if="esignDisclosure" key="e">
          Please confirm that you agree to use Electronic Record and Signature
        </span>
        <span v-else key="s">
          Please confirm that you agree to our terms and conditions
        </span>
      </v-card-title>
      <v-card-text>
        <p v-if="approveOnly" key="a" v-translate>
          After approving the document you cannot change it anymore.
        </p>
        <p v-else key="s" v-translate>
          Effective September 20, 2022 new and existing users who continue to
          use the SignRequest services and platform acknowledge the revised
          <a :href="privacyURL">Privacy Notice</a>
          , which incorporates the updated Data Processing Addendum (DPA). You
          may learn more about the DPA
          <a :href="dpaURL">here</a>
          . If you do not agree to the updated Privacy Notice, you must
          discontinue your use of SignRequest and may delete your account by
          emailing support@signrequest.com.
        </p>
        <label v-if="esignDisclosure" key="e" class="srtext--text">
          <input ref="input" v-model="terms" type="checkbox" name="terms" />
          <gettext-slots
            :message="
              $gettext(
                'I agree to the <a>Electronic Record and Signature Disclosure</a>'
              )
            "
          >
            <template v-slot:a="{ text }">
              <a
                class="srtext--text"
                role="button"
                @click.stop="showEsign(esignDisclosure)"
              >
                {{ text }}
              </a>
            </template>
          </gettext-slots>
        </label>

        <label v-else key="t" class="srtext--text">
          <input ref="input" v-model="terms" type="checkbox" name="terms" />
          <gettext-slots
            :message="
              $gettext(
                'By clicking the accept button below, you acknowledge that you have read and agree to these <terms>Terms of Service</terms> and <privacy>Privacy Notice</privacy>'
              )
            "
          >
            <template v-slot:terms="{ text }">
              <a
                class="srtext--text"
                :href="getPageUrlForLang('terms')"
                target="_blank"
                rel="noopener noreferrer"
              >
                {{ text }}
              </a>
            </template>
            <template v-slot:privacy="{ text }">
              <a
                class="srtext--text"
                :href="getPageUrlForLang('privacy')"
                target="_blank"
                rel="noopener noreferrer"
              >
                {{ text }}
              </a>
            </template>
          </gettext-slots>
        </label>
        <br />
        <v-card-notice>
          <p v-translate class="red--text">
            If you do not agree to the updated Documents, you must discontinue
            your use of SignRequest. You may also contact us to delete your
            account by emailing support@signrequest.com.
          </p>
        </v-card-notice>
      </v-card-text>
      <v-card-text>
        <span v-show="termsError" v-translate class="form--error">
          Please confirm that you agree to our terms and conditions
        </span>
      </v-card-text>
      <v-card-actions>
        <sr-button sr-style="fancy" round @click="handleSave">
          <translate>Accept</translate>
        </sr-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import SrButton from '@/components/elements/SrButton';
import ModalMixin from '@/components/modals/ModalMixin';
import Screen from '@/mixins/Screen';
import GettextSlots from '@/components/elements/GettextSlots';

export default {
  components: { SrButton, GettextSlots },
  mixins: [ModalMixin('terms'), Screen],
  props: {
    contentClass: { type: String, default: '' }
  },
  data: function() {
    return { returnValue: null, terms: false, termsError: false };
  },
  computed: {
    ...mapGetters('conf', ['getPageUrlForLang']),
    ...mapGetters('users', ['userLoggedIn']),
    privacyURL() {
      return this.getPageUrlForLang('privacy');
    },
    dpaURL() {
      return this.getPageUrlForLang('dpa');
    },
    approveOnly() {
      return Boolean(this.modalOptions.approve_only);
    },
    esignDisclosure() {
      return this.modalOptions.esign_disclosure;
    }
  },
  watch: {
    showModal(value, oldValue) {
      if (value && !oldValue) {
        this.terms = false;
        this.termsError = false;
      }
    },
    terms() {
      this.termsError = false;
    }
  },
  methods: {
    ...mapActions('modals', ['showEsign']),
    ...mapActions('users', ['updateAgreedToTerms']),
    focusFirst() {
      this.$refs.input.focus();
    },
    handleSave() {
      if (this.terms || this.esignDisclosure) {
        this.returnValue = this.terms;
        this.showModal = false;
      } else {
        this.termsError = true;
      }
      if (this.terms && this.userLoggedIn) {
        this.updateAgreedToTerms({
          agreed_to_terms: this.terms
        });
      }
    }
  }
};
</script>

<style scoped>
button.sr-button {
  font-size: 18px;
  padding: 0 3rem;
  margin: 0px;
  height: 4rem;
  width: calc(50% - 0.75rem);
}

/deep/ .v-dialog {
  border-radius: 25px;
}
.v-card {
  padding: 0 3rem;
}
.mobile.v-card {
  padding: 0 2rem;
}
.v-card__title {
  padding: 0;
  padding-top: 4rem;
  padding-bottom: 2rem;
  font-weight: 900;
  font-size: 24px;
  line-height: 1.33;
}
.v-card__text {
  font-size: 16px;
  padding: 0;
}
.v-card__text p {
  margin-top: 0;
  margin-bottom: 24px;
  line-height: 1.33;
}
.v-card__notice {
  font-weight: normal;
  font-size: 16px;
}
.v-card__text label,
.v-card__text span.form--error {
  display: block;
  font-size: 16px;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.27px;
}
.v-card__text label /deep/ a {
  text-decoration: underline;
}
input[type='checkbox'] {
  width: 1em;
  height: 1em;
  vertical-align: middle;
  margin-bottom: 2px;
}

.ltr input[type='checkbox'] {
  margin-right: 9px;
}
.rtl input[type='checkbox'] {
  margin-left: 9px;
}

.v-card__actions {
  padding: 0px;
  padding-top: 38px;
  padding-bottom: 36px;
  justify-content: center;
}
.v-card__actions .sr-button {
  min-width: 250px;
}
span.sps {
  width: 0.5ch;
  display: inline-block;
}
.close {
  position: absolute;
  width: 1em;
  height: 1em;
  top: 2rem;
}
.v-card__notice {
  font-weight: normal;
  font-size: 16px;
}
.ltr .close {
  right: 2rem;
}
.rtl .close {
  left: 2rem;
}
</style>
