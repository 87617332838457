import Router from 'vue-router';
import Home from './views/Home.vue';
import ActivateAccount from '@/components/confirm/ActivateAccount';
import Documents from '@/components/Documents';
import TeamsPage from '@/components/team/TeamsPage';
import LoginPage from '@/components/LoginPage';
import SubscribePage from '@/components/subscribe/SubscribePage';
import queryParams from '@/utils/queryParams';
import ExpiredLink from '@/components/document/ExpiredLink';

const router = new Router({
  mode: 'abstract',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/documents/',
      name: 'documents',
      component: Documents
    },
    {
      path: '/document/:uuid/:signer_uuid/',
      name: 'document',
      component: Home
    },
    {
      path: '/template/:uuid/',
      name: 'template',
      component: Home
    },
    {
      path: '/expired',
      name: 'expired',
      component: ExpiredLink
    },
    {
      // loader is a part of index.html, so render function is empty.
      // route is registered, so it could be detected in AppLoader
      path: '/loading',
      name: 'loading',
      component: {
        render: () => null
      }
    },
    {
      path: '/confirm',
      name: 'confirm',
      component: ActivateAccount
    },
    {
      path: '/register',
      name: 'register',
      component: LoginPage,
      meta: {
        isAuthPage: true
      }
    },
    {
      path: '/login',
      name: 'login',
      component: LoginPage,
      props: {
        doRegister: false
      },
      meta: {
        isAuthPage: true
      }
    },
    {
      path: '/subscribe',
      name: 'subscribe',
      component: SubscribePage,
      meta: {
        isSubscribePage: true
      }
    },
    {
      path: '/teams',
      name: 'teams',
      component: TeamsPage
    }
  ]
});
router.mode = 'hash';

export async function handleOpenSubscribe(to, from, next, vueRouter = router) {
  const nextRoute = Object.assign({}, to);
  const isAuthPage =
    to.matched && to.matched.some(record => record.meta.isAuthPage);
  const isSubscribePage =
    to.matched && to.matched.some(record => record.meta.isSubscribePage);
  if (to.query.open_subscribe && !isAuthPage) {
    const queryString = new URLSearchParams(to.query).toString();
    const nextPath = isSubscribePage
      ? `/#${to.fullPath}`
      : `/#/?${queryString}`;
    if (isSubscribePage) {
      await vueRouter.app.$store.dispatch(
        'subscription/showSubscribeOrLoginPage',
        {
          next: next,
          to: to
        }
      );
    } else {
      vueRouter.app.$store.dispatch('modals/showSubscribeOrLoginModal', {
        next: nextPath,
        doRegister: !to.query.login,
        plan: to.query.open_subscribe,
        interval: to.query.interval,
        trial_days: to.query.trial_days,
        new_subscribe: to.query.new_subscribe,
        trigger: 'subscribe_link'
      });
      nextRoute.query = {};
      next(nextRoute);
    }
  } else {
    next();
  }
}

export async function handleSocialLoginFail(
  to,
  from,
  next,
  vueRouter = router
) {
  if (to.query.login_social_auth_fail) {
    queryParams.getAndRemoveParamFromRoute('login_social_auth_fail');
    await vueRouter.app.$store.dispatch('users/socialLoginFailed');
  } else {
    next();
  }
}

router.beforeEach(handleSocialLoginFail);
router.beforeEach(handleOpenSubscribe);

export default router;
