import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{ref:"modal",attrs:{"lazy":"","max-width":"500","content-class":_vm.contentClass},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c(VForm,{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.handleUpgrade.apply(null, arguments)}},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c(VCard,{class:{
        mobile: _vm.$isMobile
      },attrs:{"role":"dialog","aria-hidden":!_vm.showModal,"aria-modal":_vm.showModal,"aria-labelledby":"terms-gmodal-title"}},[_c('sr-button',{staticClass:"close",attrs:{"sr-style":"text","data-test-id":"close-member-upgrade-btn"},on:{"click":_vm.closeModal}},[_c(VIcon,{staticClass:"round",attrs:{"aria-label":"$gettext('Close confirmation dialog')"}},[_vm._v("\n          close\n        ")])],1),_c(VCardTitle,{staticClass:"headline",attrs:{"id":"terms-gmodal-title"}},[_c('span',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("\n          Upgrade Subscription\n        ")])]),_c(VCardText,[_c('p',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("\n          In order to invite more members you need to upgrade your\n          subscription.\n        ")]),_c('p',[_c('translate',[_vm._v("Number of licenses in your current plan:")]),_c('strong',[_vm._v("\n            "+_vm._s(_vm.userPermissions.quantity)+" ("+_vm._s(_vm.userPermissions.plan)+")\n          ")]),(
              _vm.userPermissions.plan !== 'PRO' &&
                _vm.userPermissions.quantity !== _vm.userPermissions.used_quantity
            )?_c('span',[_c('br'),_c('span',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("Number of used licenses:")]),_c('strong',[_vm._v(_vm._s(_vm.userPermissions.used_quantity))])]):_vm._e()],1),(_vm.userPermissions.plan !== 'SME')?_c('p',{directives:[{name:"translate",rawName:"v-translate"}],key:"hasPRO"},[_vm._v("\n          In order to add more licences you need to upgrade to the Business\n          plan.\n        ")]):_vm._e(),(_vm.userPermissions.plan === 'SME')?_c('p',{directives:[{name:"translate",rawName:"v-translate"}],key:"hasSME"},[_vm._v("\n          How many licenses would you like to add?\n        ")]):_vm._e(),(_vm.userPermissions.plan === 'SME')?_c('sr-input',{ref:"add_num_licences",staticClass:"bordered-field",attrs:{"name":"add_num_licences","type":"number","label":_vm.$gettext('Licenses'),"min":"1","rules":[_vm.fieldRequired]},model:{value:(_vm.add_num_licences),callback:function ($$v) {_vm.add_num_licences=$$v},expression:"add_num_licences"}}):_vm._e()],1),_c(VCardActions,[_c('sr-button',{attrs:{"sr-style":"cancel","loading":_vm.isLoading,"round":""},on:{"click":_vm.closeModal}},[_c('translate',[_vm._v("\n            Cancel\n          ")])],1),_c('sr-button',{ref:"next",attrs:{"sr-style":"fancy","loading":_vm.isLoading,"round":"","data-test-id":"submit-member-upgrade-btn","type":"submit"}},[_c('translate',[_vm._v("Next")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }