import angular from 'angular';
import 'spectrum-colorpicker';

/**
 * @ngdoc directive
 * @name frontendApp.directive:srSpectrum
 * @description
 * # srSpectrum
 */
angular.module('frontendApp').directive('spectrumColorpicker', [
  'gettextCatalog',
  '$timeout',
  function(gettextCatalog, $timeout) {
    return {
      restrict: 'E',
      require: 'ngModel',
      scope: false,
      replace: true,
      template: '<span><input class="input-small" /></span>',
      link: function($scope, $element, attrs, $ngModel) {
        var $input = $element.find('input');
        var fallbackValue = attrs.fallbackValue;
        var format = $scope.$eval(attrs.format) || undefined;

        function setViewValue(color) {
          var value = fallbackValue;
          if (color) {
            value = color.toString(format);
          } else if (angular.isUndefined(fallbackValue)) {
            value = color;
          }
          if (value) {
            $ngModel.$setViewValue(value);
          }
        }

        var onChange = function(color) {
          $scope.$apply(function() {
            setViewValue(color);
          });
        };
        var onToggle = function() {
          $input.spectrum('toggle');
          return false;
        };
        var onShow = function() {
          $input.spectrum('reflow');
        };
        var options = angular.extend(
          {
            showInitial: true,
            showInput: true,
            showButtons: true,
            preferredFormat: 'hex',
            //          color: $ngModel.$viewValue,
            change: onChange,
            move: onChange,
            hide: onChange,
            show: onShow,
            cancelText: gettextCatalog.getString('cancel'),
            chooseText: gettextCatalog.getString('Choose')
          },
          $scope.$eval(attrs.options)
        );

        if (attrs.triggerId) {
          angular
            .element(document.body)
            .on('click', '#' + attrs.triggerId, onToggle);
        }

        $ngModel.$render = function() {
          $input.spectrum('set', $ngModel.$viewValue || '');
        };

        function initColor() {
          if (
            angular.equals($ngModel.$viewValue, NaN) ||
            !angular.isDefined($ngModel.$viewValue)
          ) {
            $timeout(initColor, 1000);
          } else {
            var color = $ngModel.$viewValue || options.color || '';
            $input.spectrum('set', color);
            setViewValue(color);
          }
        }

        if (options.color) {
          initColor();
        }
        $input.spectrum(options);

        $scope.$on('$destroy', function() {
          $input.spectrum('destroy');
        });
      }
    };
  }
]);
