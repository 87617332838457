<template>
  <v-container fill-height fluid column data-test-id="landing-features">
    <v-layout column content>
      <h2 v-translate class="primary--text">SignRequest</h2>
      <h1 v-translate class="srtext--text">
        The most complete and affordable signature solution
      </h1>
      <v-layout features row>
        <v-layout column feature>
          <security-icon class="primary--text" />
          <h1 v-translate class="srtext--text">Fully secured</h1>
          <p v-translate class="srtext--text">
            All documents and signatures are 256 bit AES encrypted and we are
            ISO 27001 certified.
          </p>
        </v-layout>
        <v-layout column feature>
          <ease-icon class="primary--text" />
          <h1 v-translate class="srtext--text">Easy to use</h1>
          <p v-translate class="srtext--text">
            Automatically manage and store all electronically signed documents
            in one place.
          </p>
        </v-layout>
      </v-layout>
      <sr-button sr-style="link" :href="getPageUrlForLang('features')">
        <translate>All features SignRequest offers</translate>
        <span class="arrow">→</span>
      </sr-button>
    </v-layout>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';

import SecurityIcon from './SecurityIcon';
import EaseIcon from './EaseIcon';
import SrButton from '@/components/elements/SrButton';

export default {
  components: { SrButton, SecurityIcon, EaseIcon },
  computed: {
    ...mapGetters('conf', ['getPageUrlForLang'])
  }
};
</script>
<style scoped>
.content {
  max-width: 1024px;
  width: 100%;
}

.column {
  flex-direction: column;
  align-items: center;
}
.features {
  justify-content: space-between;
  width: 100%;
  padding: 0;
}
.mobile .features {
  margin-top: 0;
}
.feature {
  align-items: center;
  margin: 0;
  margin-top: 58px;
  margin-bottom: 38px;
}
.mobile .feature {
  margin: 2.5rem 0 0 0;
}

.feature svg {
  height: 32px;
  width: 26px;
}
h1 {
  opacity: 0.9;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.3;
  letter-spacing: -0.4px;
  margin-top: 5px;
}

.mobile.mobile h1 {
  margin: 0px 30px;
  text-align: center;
}
.mobile .layout .row {
  flex-direction: column;
}
h2 {
  font-size: 24px;
  font-weight: 300;
  line-height: 1.83;
  letter-spacing: 1px;
  margin-top: 48px;
}
.mobile h2 {
  margin-top: 0;
}
.mobile h3 {
  margin-top: 36px;
}
h3 {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.27px;
}
p {
  text-align: center;
  max-width: 277px;
  font-size: 16px;
  font-weight: 300;
  opacity: 0.8;
  margin-top: 15px;
}
.mobile p {
  margin-top: 1rem;
  font-size: 16px;
  font-weight: 300;
}

.sr-button {
  min-width: 356px;
  height: 19px;
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 92px;
  margin-top: 0;
}
span.arrow {
  margin: 0 0.4ch;
}
.mobile .sr-button {
  min-width: 212px;
  height: 55px;
  margin-bottom: 40px;
}
</style>
