<template>
  <v-dialog
    v-if="modalOptions"
    ref="modal"
    v-model="showModal"
    lazy
    max-width="436"
    :content-class="contentClass"
  >
    <v-card
      role="dialog"
      :aria-hidden="!showModal"
      :aria-modal="showModal"
      aria-labelledby="confirm-modal-title"
      :class="{
        mobile: $isMobile
      }"
    >
      <v-card-title id="confirm-modal-title" class="headline">
        {{ title }}
      </v-card-title>
      <v-card-text>
        {{ body }}
      </v-card-text>
      <v-card-actions>
        <sr-button ref="first" sr-style="cancel" round @click="close(false)">
          {{ dismissButtonText }}
        </sr-button>
        <sr-button
          :sr-style="confirmationButtonStyle"
          round
          @click="close(true)"
        >
          {{ confirmationButtonText }}
        </sr-button>
      </v-card-actions>
      <sr-button class="close" sr-style="text" @click="closeModal(false)">
        <v-icon
          aria-label="$gettext('Close confirmation dialog')"
          class="round"
        >
          close
        </v-icon>
      </sr-button>
    </v-card>
  </v-dialog>
</template>

<script>
import SrButton from '@/components/elements/SrButton';
import ModalMixin from '@/components/modals/ModalMixin';
import Screen from '@/mixins/Screen';

export default {
  components: { SrButton },
  mixins: [ModalMixin('confirmation'), Screen],
  props: {
    contentClass: { type: String, default: '' }
  },
  data: function() {
    return { returnValue: null };
  },
  computed: {
    confirmationButtonText() {
      return this.modalOptions.confirmationButtonText || this.$gettext('Yes');
    },
    confirmationButtonStyle() {
      if (this.modalOptions.confirmationButtonColor === 'error') {
        return 'danger';
      }
      return 'fancy';
    },
    dismissButtonText() {
      return (
        this.modalOptions.dismissButtonText ||
        (this.confirmationButtonText === this.$gettext('Yes')
          ? this.$gettext('No')
          : this.$gettext('Cancel'))
      );
    },
    title() {
      return this.modalOptions.title || this.$gettext('Are you sure?');
    },
    body() {
      return this.modalOptions.body || '';
    }
  },
  watch: {
    showModal(value, oldValue) {
      if (value && !oldValue) {
        this.$nextTick(() => this.focusFirst());
      }
    }
  },
  methods: {
    focusFirst() {
      this.$refs.first.focus();
    },
    close(confirmed) {
      this.returnValue = confirmed;
      this.showModal = false;
    }
  }
};
</script>

<style scoped>
button.sr-button {
  font-size: 18px;
  padding: 0 3rem;
  margin: 0px;
  height: 4rem;
  width: calc(50% - 0.75rem);
}

/deep/ .v-dialog {
  border-radius: 25px;
}
.v-card {
  padding: 0 3rem;
}
.v-card.mobile {
  padding: 0 2rem;
}
.v-card__title {
  padding: 0;
  padding-top: 4rem;
  padding-bottom: 1rem;
  font-weight: 900;
}
.v-card__text {
  font-size: 17px;
  padding: 0;
  padding-bottom: 1.5rem;
}
.v-card__actions {
  padding: 0px;
  padding-bottom: 3rem;
  justify-content: space-between;
}
button.sr-button.close {
  font-size: 14px;
  position: absolute;
  min-width: 1em;
  width: 1em;
  height: 1em;
  top: 2rem;
  color: #111111;
  opacity: 0.8;
  margin: 0;
  padding: 0;
}
.ltr .close {
  right: 2rem;
}
.rtl .close {
  left: 2rem;
}
</style>
