import angular from 'angular';

/**
 * @ngdoc function
 * @name frontendApp.controller:LoginValidateEmailCtrl
 * @description
 * # LoginValidateEmailCtrl
 * Controller of the frontendApp
 */
angular.module('frontendApp').controller('SignRequestValidateEmailCtrl', [
  '$scope',
  '$rootScope',
  '$location',
  '$routeParams',
  'UserService',
  'messageService',
  'gettextCatalog',
  '$window',
  'ipCookie',
  'utils',
  function(
    $scope,
    $rootScope,
    $location,
    $routeParams,
    UserService,
    messageService,
    gettextCatalog,
    $window,
    ipCookie,
    utils
  ) {
    $scope.loading = true;
    UserService.waitForUser().then(function() {
      if ($routeParams.token && $routeParams.email) {
        UserService.validateSignRequestEmail(
          $routeParams.email,
          $routeParams.token
        ).then(function(response) {
          $scope.loading = false;
          utils.navigateTo(response.data.next || '/#/done');
        });
      } else {
        messageService.add(
          'error',
          gettextCatalog.getString('Invalid link, please try again.'),
          3000
        );
        $location.path('/');
      }
    });
  }
]);
