<template>
  <ul class="info-container--features srtext--text">
    <feature-line><translate>Work in teams</translate></feature-line>
    <feature-line><translate>Templates</translate></feature-line>
    <feature-line><translate>Custom branding</translate></feature-line>
    <feature-line><translate>2,000+ integrations</translate></feature-line>
    <feature-line><translate>API access</translate></feature-line>
  </ul>
</template>
<script>
import FeatureLine from './FeatureLine';

export default {
  components: { FeatureLine }
};
</script>
<style scoped>
ul.info-container--features {
  font-size: 22.8px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.96;
  letter-spacing: normal;
  display: block;
  margin: auto;
  margin-top: 5rem;
  margin-bottom: 4rem;
}
.lang-de ul.info-container--features,
.lang-fi ul.info-container--features {
  margin-top: 2rem;
}
.mobile ul.info-container--features {
  font-size: 18.3px;
  margin: 0 2rem;
  margin-top: 48px;
  margin-bottom: 40px;
}

.info-container__lout ul.info-container--features {
  font-size: 18px;
}
span.sps {
  margin: 0 0.2ch;
}
</style>
