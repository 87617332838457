import angular from 'angular';
import _ from 'lodash';
import { store } from '../../store';

/**
 * @ngdoc service
 * @name frontendApp.messageService
 * @description
 * # messageService
 * Factory in the frontendApp.
 */
(function() {
  angular.module('frontendApp').factory('messageService', [
    '$rootScope',
    '$timeout',
    'gettextCatalog',
    '$log',
    /**
     *
     * @param $rootScope
     * @param $timeout
     * @param gettextCatalog
     * @param $log
     * @returns {{add: Function, closeAlertIdx: Function, handleResponse: Function, handleError: Function}}
     * @constructor
     */
    function($rootScope, $timeout, gettextCatalog, $log) {
      /**
       *
       * @type {{add: Function, closeAlertIdx: Function, handleResponse: Function, handleError: Function}}
       */
      var service = {
        messages: {},

        getMessages: function() {
          return _.values(service.messages);
        },
        /**
         * Displays new message
         * @param type
         * @param msg
         * @param timeout
         */

        add: function(type, msg, timeout) {
          store.dispatch('messages/addMessage', { type, msg, timeout });
        },
        /**
         * Adds message/messages from response
         * @param response
         * @param timeout
         * @param background: suppress messages
         * @returns {*}
         */
        handleResponse: function(response, timeout, background) {
          store.dispatch('api/handleResponse', {
            response,
            timeout,
            silent: background
          });
          return response;
        },
        /**
         * Adds error message
         * @param response
         * @param timeout
         * @returns {*}
         */
        handleError: function(response, timeout) {
          const data = angular.isDefined(response.data)
            ? response.data
            : response;
          store.dispatch('api/handleError', { response, timeout });
          return data;
        },
        /**
         * Shows a generic error message
         * @param timeout
         */
        genericError: function(timeout) {
          service.handleError(null, timeout || 10000);
        }
      };

      return service;
    }
  ]);
})();
