import * as Sentry from '@sentry/browser';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async getSafeCurrentUrl({ dispatch }) {
      // used for tracking
      const path = await dispatch('getSafeCurrentPath');
      const query = await dispatch('parseTrackingParams');
      const search = query ? new URLSearchParams(query).toString() : '';
      return search ? path + '?' + search : path;
    },
    async cleanQueryParams({ dispatch }, query) {
      const EmailRegex = /^([\w\-\.]+)@((\[([0-9]{1,3}\.){3}[0-9]{1,3}\])|(([\w\-]+\.)+)([a-zA-Z]{2,4}))$/g;
      const allowed_params = [
        'ref',
        'plan',
        'api',
        'utm_source',
        'utm_medium',
        'utm_campaign',
        'utm_term',
        'utm_content',
        'gclid',
        'interval',
        'open_subscribe',
        'trial_days',
        'login_success',
        'register_success'
      ];

      const cleanup = async value => {
        return await dispatch(
          'stripSensitiveData',
          value.replace(EmailRegex, 'EMAIL')
        );
      };

      let result = {};
      if (query) {
        for (let q of query) {
          if (!allowed_params.includes(q[0])) {
            // we don't want this in our analytics
            // redact the query string by replacing ?email=my@email.com to ?email=EMAIL
            result[q[0]] = q[0].toUpperCase();
          } else {
            // still check if we have uuid's, hashes or emails we don't want
            result[q[0]] = await cleanup(q[1]);
          }
        }
      }
      return result;
    },
    async addTrailingSlash({ dispatch }, path) {
      return path && path.slice(-1) !== '/' ? path + '/' : path;
    },
    async getSafeCurrentPath({ dispatch }) {
      return await dispatch(
        'addTrailingSlash',
        await dispatch('stripSensitiveData', await dispatch('getPathFromHash'))
      );
    },
    async stripSensitiveData({ dispatch }, str) {
      const UUIDRegex = /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/g;
      const HashRegex = /[a-fA-F0-9]{64}/g;

      if (typeof str !== 'undefined' && str !== null) {
        return str
          .toString()
          .replace(UUIDRegex, 'UUID')
          .replace(HashRegex, 'HASH');
      }

      return '';
    },
    async getSafeCurrentHref({ dispatch }) {
      return await dispatch('stripSensitiveData', window.location.href);
    },
    async getTrackingParams({ dispatch, rootGetters }, { user }) {
      let tracking_params = await dispatch('parseTrackingParams');
      tracking_params.referrer = await dispatch('getSafeCurrentReferer');
      tracking_params.path = await dispatch('getSafeCurrentPath');
      tracking_params.href = await dispatch('getSafeCurrentHref');
      tracking_params.url = await dispatch('getSafeCurrentUrl');
      Object.assign(tracking_params, rootGetters['conf/flags']);

      if (user && user.uuid) {
        tracking_params.sr_uuid = user.uuid;
      }

      for (const param of ['login_success', 'register_success']) {
        // clean the route of these parameters so we only track those virtual views once
        const value = await dispatch('getAndRemoveParamFromRoute', param);
        if (value !== null) {
          tracking_params[param] = value;
        }
      }

      return tracking_params;
    },
    async getQueryParamsFromHash({ dispatch }) {
      if (location.hash && location.hash.includes('?')) {
        return new URLSearchParams(location.hash.split(/\?(.+)/)[1]);
      }
      return new URLSearchParams();
    },
    async getPathFromHash({ dispatch }) {
      return new URL(
        (location.hash ? location.hash.slice(1) : '/') || '/',
        location.origin
      ).pathname;
    },
    async removeQueryParamFromHash({ dispatch }, param_name) {
      if (location.hash && location.hash.includes(param_name)) {
        const currentParams = await dispatch('getQueryParamsFromHash');
        const param_value = currentParams.get(param_name);
        currentParams.delete(param_name);
        const query = currentParams.toString();
        location.hash = `${await dispatch('getPathFromHash')}${
          query ? '?' + query : ''
        }`;
        return param_value;
      }
      return null;
    },
    async getAndRemoveParamFromRoute({ dispatch }, param_name) {
      // get a param value and remove it from the route (in the hash routing mode part of the url)
      return await dispatch('removeQueryParamFromHash', param_name);
    },
    async getSafeCurrentReferer({ dispatch }) {
      // used for tracking
      return await dispatch('stripSensitiveData', document.referrer);
    },
    async parseTrackingParams({ dispatch }) {
      // used for tracking

      // query params in the normal search section
      let query = new URLSearchParams(location.search);

      // query params in the hash part or the url
      const queryFromHash = await dispatch('getQueryParamsFromHash');
      for (let q of queryFromHash) {
        query.append(q[0], q[1]);
      }

      return await dispatch('cleanQueryParams', query);
    },
    async processTrackingUserResponse(
      { dispatch },
      { response, tracking_params, user }
    ) {
      let uuid, email;
      if (response && response.data) {
        uuid = response.data.sr_uuid;
        email = response.data.email;
      }

      if (user && user.uuid && user.email) {
        uuid = user.uuid;
        email = user.email;
      }

      if (uuid && email) {
        Sentry.configureScope(scope => {
          scope.setUser({
            id: uuid,
            username: email,
            email: email
          });

          scope.setTag('url', tracking_params.url);
        });
      } else {
        Sentry.configureScope(scope => {
          scope.setTag('url', tracking_params.url);
        });
      }
    },
    async trackEvent({ dispatch }, { action, category, params }) {
      params = params || {};
      const page = await dispatch('getSafeCurrentUrl');
      const variations = await dispatch(
        'experiments/getAllVariations',
        {},
        { root: true }
      );
      const user = await dispatch(
        'users/getUser',
        { anyUser: true },
        { root: true }
      );

      const tracking_params = await dispatch('getTrackingParams', {
        user
      });

      const event_data = {
        ...variations,
        ...tracking_params,
        ...params,
        category: category,
        target: category,
        action: action,
        'target-properties': params.label || null,
        label: params.label || null,
        value: params.value || null,
        page: page,
        logged_in: user ? user.logged_in : false,
        is_staff: user ? user.is_staff : false
      };

      let dataLayer = (window.dataLayer = window.dataLayer || []);

      dataLayer.push({
        ...event_data,
        event: 'interaction'
      });

      const url = '/user/trk-ev/';
      const response = await dispatch(
        'api/makeRequest',
        {
          url: url,
          method: 'POST',
          data: event_data,
          silent: true,
          silentError: true
        },
        { root: true }
      );

      await dispatch('processTrackingUserResponse', {
        response,
        tracking_params,
        user
      });

      if (event_data.is_staff) {
        console.info(
          `Tracking Event | Category: ${event_data.category} | Action: ${event_data.action} | Label: ${event_data.label} | Value: ${event_data.value}`,
          event_data,
          response.data
        );
      }
    }
  }
};
