import location from '@/utils/location';

const history = {
  cb: null,
  watch(cb) {
    this.cb = cb;
  },
  push(path) {
    if (path.startsWith('#')) {
      location.hash = path;
    } else if (this.cb) {
      this.cb(path);
    }
  },
  assign(url) {
    location.assign(url);
  }
};
export default history;
