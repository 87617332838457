import angular from 'angular';

/**
 * @ngdoc function
 * @name frontendApp.controller:LoginResetCtrl
 * @description
 * # LoginResetCtrl
 * Controller of the frontendApp
 */
angular.module('frontendApp').controller('LoginResetCtrl', [
  '$scope',
  '$route',
  'UserService',
  'utils',
  function($scope, $route, UserService, Utils) {
    $scope.messages = [];
    $scope.reset_password_data = { pass1: '', pass2: '' };
    $scope.reset = function() {
      UserService.resetUserPass(
        $route.current.params.token,
        $scope.reset_password_data.pass1,
        $scope.reset_password_data.pass2
      )
        .then(function(response) {
          $scope.messages = response.data.messages;
          $scope.reset_password_data = { pass1: '', pass2: '' };
        })
        .then(function() {
          Utils.navigateTo('/#/');
        });
    };
  }
]);
