<template>
  <li :key="$language.current">
    <svg
      class="primary--text"
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <polyline points="20 6 9 17 4 12"></polyline>
    </svg>
    <slot />
  </li>
</template>
<script>
export default {};
</script>
<style scoped>
li {
  list-style: none;
  display: block;
  margin-bottom: 1.5rem;
}
.mobile li {
  margin-bottom: 1rem;
}
li svg {
  display: inline-block;
  margin-bottom: -2px;
}
.ltr li {
  padding-left: 2rem;
}
.rtm li {
  padding-right: 2rem;
}
.ltr li svg {
  margin-right: 0.5em;
  margin-left: -2rem;
}
.rtl li svg {
  margin-left: 0.5em;
  margin-right: -2rem;
}
.mobile li svg {
  margin-top: 5px;
}
</style>
