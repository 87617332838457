import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  created() {
    this.getUser();
  },
  computed: {
    ...mapGetters('conf', ['getPageUrlForLang']),
    ...mapGetters('users', ['userLoaded', 'userLoggedIn']),
    /* User is not accessed in the mixin itself, but could be
       assumed to be here by components dependent on mixin */
    ...mapState('users', ['user', 'userLoaded'])
  },
  methods: {
    ...mapActions('users', ['getUser']),
    ...mapActions('modals', ['showSubscribeModal', 'showLoginModal']),
    ...mapActions('permissions', [
      'showLoginOrPage',
      'showLoginOrSubscribeModal'
    ])
  }
};
