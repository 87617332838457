<template>
  <div
    class="field-contact-selection srtext--text"
    :class="{ 'field-contact-selection--disabled': disabled }"
  >
    <ul>
      <li v-for="{ signer, index } in contactSlots" :key="index">
        <signer-entry
          v-if="signer && (signer.email || editTemplate)"
          :value="signer"
          :active="index === selectedSigner"
          :show-checkbox="Boolean(!disabled && index === 0)"
          :signer-index="index"
          :rules="[signerRule]"
          @click:details="handleOpen(index)"
          @input="handleChange(index, $event)"
        />

        <div
          v-else
          class="field-contact-add"
          :class="{ 'h-section--focusable': !editTemplate }"
        >
          <sr-button
            v-if="editTemplate"
            inline
            sr-style="text"
            @click="handleAdd(index)"
          >
            <translate>Add contact</translate>
          </sr-button>
          <sr-autocomplete
            v-else
            :name="index ? 'signers' : 'email'"
            :search-input.sync="contactEmails[index]"
            :disabled="disabled"
            :items="contacts"
            :limit="contactsLimit"
            :label="
              signer ? signerLabel(signer, index) : $gettext('Add contact')
            "
            :rules="signer ? [requireEmail, emailRule] : [emailRule]"
            :show-manual-entry="index > 0"
            suppress-empty-error
            suppress-first-error
            @commit="handleCommit($event, index)"
          >
            <template v-slot:append-outer>
              <help-hint>
                <translate>
                  Add the email address of your contacts that need to sign or
                  receive a copy of the document.
                </translate>
              </help-hint>

              <div v-if="ownEmailInContactFieldErrorUrl">
                <a
                  v-show="isSameEmail[index]"
                  v-translate
                  data-test-id="same-email-link"
                  class="small"
                  :href="ownEmailInContactFieldErrorUrl"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn more
                </a>
              </div>
            </template>
          </sr-autocomplete>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import signerLabel from '@/utils/signerLabel';
import { mapActions, mapGetters } from 'vuex';
import {
  validateSigner,
  validateUniqueEmail,
  checkSenderEmail
} from '@/utils/validateSigner';
import { emptySigner } from '@/utils/addSigner';
import { append, updateAtIdx, merge, patch, replace } from '@/utils/fp';

import SrAutocomplete from '@/components/elements/SrAutocomplete';

import SignerEntry from './SignerEntry';
import HelpHint from './HelpHint';

const MAX_CONTACTS_DROPDOWN = 10;

export default {
  components: {
    SrAutocomplete,
    SignerEntry,
    HelpHint
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: { type: Array, required: true },
    selectedSigner: { type: Number, default: -1 },
    editTemplate: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false }
  },
  data() {
    return {
      contactEmails: ['']
    };
  },
  computed: {
    ...mapGetters('users', ['userContacts']),
    ...mapGetters('signrequest', ['template']),
    ...mapGetters('conf', ['ownEmailInContactFieldErrorUrl']),
    senderEmail() {
      const [sender] = this.$props.value;
      return sender.email;
    },
    contactsLimit() {
      return MAX_CONTACTS_DROPDOWN;
    },
    contacts() {
      const addedEmails = this.value.map(contact => contact.email);
      return this.userContacts.filter(
        contact => !addedEmails.includes(contact.value)
      );
    },
    contactSlots() {
      const ret = this.value.map((signer, index) => ({ signer, index }));
      ret.push({ index: ret.length, signer: null });
      return ret;
    },
    isSameEmail() {
      return this.contactEmails.map(
        email => email && checkSenderEmail(this.value, email)
      );
    },
    signerRule() {
      return signer => validateSigner(this.value, signer);
    },
    requireEmail() {
      return value => Boolean(value) || this.$gettext('Please enter email');
    },
    emailRule() {
      return value => validateUniqueEmail(this.value, value);
    }
  },
  watch: {
    contactSlots(value, oldValue) {
      if (value.length === oldValue.length) {
        return;
      }
      const emails = this.contactEmails.slice(0, value.length);
      while (emails.length < value.length) {
        emails.push('');
      }
      this.contactEmails = emails;
    }
  },
  methods: {
    ...mapActions('events', ['trackAddNewContactEvent']),
    signerLabel(signer, index) {
      if (index === 0) {
        return this.$gettext('Your email');
      }
      return signerLabel(signer, index);
    },
    handleAdd(index) {
      this.handleCommit({ value: null, text: null }, index);
    },
    handleCommit({ value, text }, index) {
      const shouldAdd = index >= this.$props.value.length;
      if (shouldAdd) {
        this.trackAddNewContactEvent(index);
      }
      this.$emit(
        'input',
        shouldAdd
          ? append(
              this.$props.value,
              merge(emptySigner(this.template, index), {
                email: value,
                name: text
              })
            )
          : updateAtIdx(
              this.$props.value,
              index,
              patch({ email: value, name: text })
            )
      );
    },
    handleOpen(index) {
      this.$emit('click:details', index);
    },
    handleChange(index, value) {
      this.$emit(
        'input',
        updateAtIdx(this.$props.value, index, replace(value))
      );
    }
  }
};
</script>
<style scoped>
.field-contact-selection.h-section {
  padding: 0;
}

.field-contact-selection--disabled {
  opacity: 0.19;
}
.field-contact-selection > ul {
  margin: 0;
  padding: 0;
}
.field-contact-selection > ul > li {
  list-style: none;
  transition: background-color 0.5s;
}
.field-contact-selection > ul > li.active {
  background-color: #f3f3f3;
}

.field-contact-selection > ul > li::after {
  content: ' ';
  color: #d6d6d6;
  left: 0;
  right: 0;
  bottom: 0;
  transform: scaleY(0.5);
  display: block;
  background-color: currentColor;
  height: 1px;
}
.field-contact-selection > ul > li /deep/ .signer-entry--outer {
  padding: 0 25px;
}

.field-contact-add {
  padding: 0px 25px;
  min-height: 72px;
}
.field-contact-add .sr-button {
  margin: 1.5rem 1rem;
  font-size: 16px;
  line-height: 24px;
}

.pointer {
  cursor: pointer;
  text-decoration: none;
}

.field-contact-selection .field-contact-add /deep/ .sr-field {
  font-size: 16px;
  margin: 0 1rem;
}
.field-contact-selection /deep/ .sr-field::before,
.field-contact-add > .sr-button::before {
  position: absolute;
  content: '+';
  font-weight: normal;
  color: inherit;
  width: 0;
  opacity: 1;
  transition: opacity 0.1s;
}
.field-contact-selection li:first-child /deep/ .sr-field::before {
  content: '';
}

.field-contact-selection /deep/ .sr-field:focus-within::before,
.field-contact-selection /deep/ .sr-field.sr-field--has-error::before {
  opacity: 0;
}

.ltr .field-contact-selection /deep/ .sr-field:before {
  left: -1.1em;
  top: 1.2em;
}
.ltr .field-contact-add > .sr-button::before {
  left: -1.1em;
}

.rtl .field-contact-selection /deep/ .sr-field::before {
  right: -1.1em;
  top: 1.2em;
}
.rtl .field-contact-add > .sr-button::before {
  right: -1.1em;
}

.field-contact-add::v-deep label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
<style>
.v-menu__content.field-contact-add--menu {
  border-radius: 20px;
}
</style>
