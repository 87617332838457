<template>
  <v-dialog
    v-model="showModal"
    :max-width="size.width"
    class="v-dialog--iframe"
    :content-class="contentClass"
  >
    <v-card>
      <v-icon
        class="round close v-chip"
        role="button"
        :title="$gettext('Close')"
        @click="closeModal"
      >
        close
      </v-icon>

      <v-card-title v-if="withTitle">
        <h2>{{ modalOptions.title }}</h2>
      </v-card-title>

      <v-card-text>
        <iframe
          v-if="url"
          :title="modalOptions.title"
          :width="size.width"
          :height="size.height"
          :src="url"
        ></iframe>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import ModalMixin from '@/components/modals/ModalMixin';

export default {
  mixins: [ModalMixin('iframe')],
  props: {
    contentClass: { type: String, default: '' }
  },
  computed: {
    url() {
      const { url } = this.modalOptions || {};
      return url || null;
    },
    size() {
      const { size = 'default' } = this.modalOptions || {};
      return {
        youtube: { width: '1431px', height: '681px' },
        default: { width: '455px', height: '520px' }
      }[size];
    },
    withTitle() {
      const { size = 'default' } = this.modalOptions || {};
      return size === 'youtube';
    }
  }
};
</script>
<style scoped>
.v-card__text {
  padding: 0;
}
.round {
  background-color: rgba(17, 17, 17, 0.1);
  color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  font-size: 16px;
  padding: 0.2em;
}
.close {
  position: absolute;
  top: 1rem;
  z-index: 10;
  margin: 0;
}
.ltr .close {
  right: 1.5rem;
}
.rtl .close {
  left: 1.5rem;
}
iframe {
  border: none;
  display: block;
}
h2 {
  margin-top: 0;
}
</style>
