<template>
  <div class="payment" :class="{ mobile: $isMobile }">
    <v-flex class="payment-details  md7 xs12">
      <h1 v-if="!trialEligible" v-translate class="srtext--text">
        Your trial has ended.
      </h1>
      <h1 v-else-if="planUpgrade" v-translate class="srtext--text">
        You can upgrade your plan.
      </h1>
      <h1 v-else v-translate class="srtext--text">
        Start your free trial
      </h1>
      <p v-if="!trialEligible" class="srtext--text subtitle">
        <translate :translate-params="{ planName: plan.displayName }">
          Please subscribe to SignRequest %{ planName } plan to continue using
          %{ planName } features.
        </translate>
      </p>
      <p v-else class="srtext--text subtitle">
        <translate :translate-params="{ trialDays: plan.trialDays }">
          Fill in your payment details to start your trial right away. Don't
          worry, you won't be charged for the first %{ trialDays } days.
        </translate>
      </p>

      <v-flex class="payment-details-placeholder">
        <payment-details></payment-details>
      </v-flex>

      <v-layout column justify-end class="subscribe-button-placeholder">
        <sr-button
          ref="submitButton"
          class="x-large subscribe-button"
          sr-style="fancy"
          round
          :disabled="!isSubmitEnabled"
          @click="subscribe"
        >
          <Loading v-if="subscribing"></Loading>
          <div v-else>
            <span v-if="!trialEligible" v-translate>Subscribe</span>
            <span v-else v-translate>Start free trial</span>
          </div>
        </sr-button>
      </v-layout>
      <v-layout v-if="paymentMethod === 'sepa'" row>
        <p id="mandate-acceptance" v-translate class="mandate-acceptance">
          By providing your IBAN and confirming this payment, you are
          authorizing SignRequest B.V. and Stripe, our payment service provider,
          to send instructions to your bank to debit your account and your bank
          to debit your account in accordance with those instructions. You are
          entitled to a refund from your bank under the terms and conditions of
          your agreement with your bank. A refund must be claimed within 8 weeks
          starting from the date on which your account was debited.
        </p>
      </v-layout>
    </v-flex>

    <v-flex column class="plan-details md5 xs12">
      <plan-details></plan-details>
    </v-flex>
  </div>
</template>
<script>
import PaymentDetails from '@/components/subscribe/PaymentDetails';
import PlanDetails from '@/components/subscribe/PlanDetails';
import { mapState, mapActions, mapGetters } from 'vuex';
import Screen from '@/mixins/Screen';
import Loading from '@/components/Loading';
export default {
  components: {
    PaymentDetails,
    PlanDetails,
    Loading
  },
  mixins: [Screen],
  computed: {
    ...mapState('subscription', ['plan', 'subscribing', 'paymentMethod']),
    ...mapGetters('subscription', [
      'isSubmitEnabled',
      'trialEligible',
      'planUpgrade'
    ])
  },
  methods: {
    ...mapActions('subscription', ['subscribe'])
  }
};
</script>
<style scoped lang="scss">
.payment {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  border: 1px solid setting(colors, border-dark);
  padding: 0;
  border-radius: 15px;
  box-shadow: -2px 6px 44px 10px rgba(0, 0, 0, 0.06);
}
.mobile.payment,
.mobile.payment-wrapper {
  width: 100%;
  margin-top: 0;
}
.payment-details-placeholder {
  flex-grow: 0;
}
.payment-details {
  background-color: setting(colors, white);
  padding: 36px 42px 42px;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
}
.mobile .payment-details {
  padding: 20px;
}
.plan-details {
  background-color: setting(colors, background-light-gray);
  padding: 36px 0 42px;
  border-radius: 15px;
}
.mobile .plan-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: white;
}
h1 {
  margin-top: 0;
  font-size: 2.429rem;
  font-weight: 900;
  letter-spacing: 0.57px;
}
.subtitle {
  margin-top: 17px;
  font-size: 1.1rem;
  font-weight: 300;
  opacity: 0.8;
}
.subscribe-button-placeholder {
  flex-grow: 1;
}
.mobile .subscribe-button-placeholder {
  align-items: center;
}
.subscribe-button {
  width: 200px;
  height: 50px;
}
.mobile .subscribe-button {
  margin-top: 30px;
  width: 100%;
}
.mandate-acceptance {
  margin-top: 0.86rem;
  font-size: 10px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1rem;
  letter-spacing: 0.17px;
  color: rgba(0, 0, 0, 0.8);
}
</style>
