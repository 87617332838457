<template>
  <div>
    <div class="sr-layout">
      <div class="sr-layout__item one-half">
        <h4 id="zapier">Zapier</h4>
      </div>
      <div class="sr-layout__item one-half">
        <img
          class="pull-right margin-top"
          alt="Zapier"
          width="200"
          src="../images/zapier/zapier-logo.png"
        />
      </div>
    </div>

    <div class="sr-layout">
      <div class="sr-layout__item three-quarters palm-one-whole">
        <p v-translate>
          Zapier lets you easily connect SignRequest to other web apps you use,
          making it easy to automate tedious tasks.
        </p>
      </div>
      <div class="sr-layout__item one-quarter palm-one-whole">
        <p class="align--center">
          <a
            v-translate
            class="pull-right button button--success"
            :href="getPageUrlForLang('zapier_integration')"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more
          </a>
        </p>
      </div>
      <div ref="zapierScriptHolder" class="sr-layout__item one-whole">
        <div id="zaps" class="margin-top"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('conf', ['getPageUrlForLang'])
  },
  mounted() {
    const zapierScript = document.createElement('script');
    zapierScript.setAttribute(
      'src',
      'https://zapier.com/apps/embed/widget.js?services=signrequest&container=true&limit=5&html_id=zaps'
    );
    this.$refs.zapierScriptHolder.appendChild(zapierScript);
  }
};
</script>
