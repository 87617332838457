<template>
  <v-select
    :items="teamOptions"
    :label="label || this.$gettext('Team')"
    :value="value"
    :loading="!userLoaded"
    :hint="hint"
    :persistent-hint="true"
    @change="$emit('input', $event)"
  ></v-select>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  props: {
    value: { type: String, default: null },
    label: { type: String, default: null },
    hint: { type: String, default: null }
  },
  computed: {
    ...mapGetters('users', ['teamOptions']),
    ...mapState('users', ['userLoaded'])
  }
};
</script>
