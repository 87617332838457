import { getAngularDeps } from '@/plugins/angular-injector';
import Vue from 'vue';
import debouncePromise from '@/utils/debouncePromise';
import { prepend, merge, concat } from '@/utils/fp';
import { makeEncodedUrlParam } from '@/utils/encodedParams';
import getJSONCookie, { getCookie } from '@/utils/getJSONCookie';
import { redirectFilter } from '@/utils/encodedParams';
import location from '@/utils/location';
import {
  DISMISS_VERSION_SWITCH,
  ENABLE_BETA_HOMEBOX,
  ENABLE_BETA_SIGN_MODE
} from '@/utils/userTagNames';
import router from '@/store/router';
import config from '@/config';
import { request, requestWithParams } from '@/store/utils';

const EMAIL_CHECK_THROTTLE = 1000;

let getUserPromise = null;

function initialState() {
  return {
    signer_uuid_from_angular: null,
    user: {
      permissions: {},
      sigs: [],
      default_team_options: [],
      logged_in: false,
      team_user: {},
      available_team_users: [],
      email_settings: {},
      document_settings: {},
      slack_settings: {},
      contacts: [],
      templates: [],
      integrations: {},
      active_subscription: {},
      tags: null
    },
    userLoaded: false,
    teamLoading: false,
    signerUUID: null,
    teamUsers: [],
    teamUsersLoaded: false,
    teamDeleteAfter: null
  };
}

const empty = Object.freeze({});

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    userEmail(state) {
      return state.user.email;
    },
    userSignatures(state) {
      return state.user.sigs ? state.user.sigs : [];
    },
    userLoggedIn(state) {
      return Boolean(state.user.logged_in);
    },
    userContacts(state) {
      return state.user.contacts;
    },
    needs2FA(state) {
      return Boolean(state.user.needs_2fa);
    },
    userAgreedToTerms(state) {
      return Boolean(state.user.agreed_to_terms);
    },
    userLoaded(state) {
      return state.userLoaded;
    },
    userTemplates(state) {
      return state.user.templates;
    },
    hasTemplates(state) {
      return state.user.templates.length > 0;
    },
    hasDropbox(state) {
      const { dropbox } = state.user.integrations;
      return Boolean(dropbox && dropbox.enabled);
    },
    userWarning(state, getters, rootState, rootGetters) {
      const teamDomain = rootGetters['conf/teamDomain'];
      const hasSignerUUID = !!state.signer_uuid_from_angular;

      if (getters.userHasTeams && !teamDomain) {
        return 'swt_mess';
      }

      // don't show this warning when editor is open (hasSignerUUID is true)
      if (!hasSignerUUID && !getters.hasActiveTeam && teamDomain) {
        return 'nyt_mess';
      }

      return null;
    },
    hasUnpaidDue(state) {
      const { active_subscription: activeSub } = state.user;
      return Boolean(activeSub && activeSub.unpaid_since);
    },
    getTeamDeleteAfter(state) {
      return state.teamDeleteAfter || null;
    },
    isPayer(state, getters) {
      return getters.hasPermission('is_payer');
    },
    isPaid(state, getters) {
      return getters.hasPermission('pro');
    },
    hasTrialEnded(state, getters) {
      return getters.hasPermission('trial_ended', { isNegative: true });
    },
    isOverMonthLimit(state, getters) {
      return getters.hasPermission('over_month_limit', { isNegative: true });
    },
    user: state => state.user,
    teamUser: state => state.user.team_user || empty,
    userTeamSettings: state => state.user.team_user.team || empty,
    apiSettings: state => state.user.team_user.api_settings || empty,
    userTeamLoading: state => state.teamLoading,
    availableTeamUsers: state => state.user.available_team_users || [],
    userHasTeams(state, getters) {
      return getters.availableTeamUsers.length > 0;
    },
    hasActiveTeam(state, getters) {
      return Boolean(getters.teamUser.has_active_team);
    },
    mayObtainApiToken(state, getters) {
      return Boolean(getters.teamUser.may_obtain_api_token);
    },
    isTeamAdmin(state, getters) {
      return Boolean(getters.teamUser.is_admin);
    },
    isTeamOwner(state, getters) {
      return Boolean(getters.teamUser.is_owner);
    },
    canAccessDocuments(state, getters) {
      return getters.hasActiveTeam
        ? !getters.isTeamAdmin || Boolean(getters.teamUser.can_access_documents)
        : true;
    },
    teamMembers(state, getters) {
      return getters.userTeamSettings.members || [];
    },
    userOrTeamName(state, getters) {
      if (getters.hasActiveTeam) {
        return state.user.team_user.team.name;
      }
      if (state.user) {
        return state.user.full_name;
      }
      return '';
    },
    teamSettings(state, getters) {
      return getters.hasActiveTeam ? state.user.team_user.team : {};
    },
    teamDocumentsSettings(state, getters) {
      return getters.teamSettings ? getters.teamSettings.document_settings : {};
    },
    canUpdateNotificationSettings(state, getters) {
      return getters.hasPermission('pro');
    },
    canDownloadDocuments(state, getters) {
      return !!(
        state.user.team_user.has_active_team ||
        getters.hasPermission('teams') ||
        getters.hasPermission('pro')
      );
    },
    canExportDocuments(state, getters) {
      return getters.hasPermission('pro');
    },
    canCreateSignRequestFromTemplate(state, getters) {
      return getters.hasPermission('pro');
    },
    canSendBulkSend(state) {
      return (
        state.user &&
        state.user.active_subscription &&
        state.user.active_subscription.can_send_bulksend
      );
    },
    canCheckSendReminders(state, getters) {
      return getters.hasPermission('pro');
    },
    canCreateTemplates(state, getters) {
      return getters.hasPermission('can_create_template');
    },
    canBeUpgraded(state, getters) {
      return !getters.hasPermission('pro');
    },
    canUploadManyFiles(state, getters) {
      return getters.hasPermission('pro');
    },
    canAddAttachment(state, getters) {
      return getters.hasPermission('pro');
    },
    canChangeDocName(state, getters) {
      return getters.hasPermission('pro');
    },
    canChangeDocSubject(state, getters) {
      return getters.hasPermission('pro');
    },
    canReplaceFile(state, getters) {
      return getters.hasPermission('pro');
    },
    canChangeAutoExpire(state, getters) {
      return getters.hasPermission('pro');
    },
    canChangeSignOrder(state, getters) {
      return getters.hasPermission('pro');
    },
    canChangeTel(state, getters) {
      return getters.hasPermission('pro');
    },
    canChangePassword(state, getters) {
      return getters.hasPermission('pro');
    },
    canChangeSignLevel(state, getters) {
      return getters.hasPermission('pro');
    },
    canChangeRequireAttachment(state, getters) {
      return getters.hasPermission('pro');
    },
    canCreateTeam(state, getters) {
      return getters.hasPermission('pro');
    },
    canUpgradeTeam(state, getters) {
      /*
       * We dont use hasPermission() here because on a sandboxed
       * team all permissions are evaluated to true,
       * but this one should actually go and check if user has PRO account
       */

      return Boolean(getters.userPermissions.pro);
    },
    userPermissions(state) {
      return state.user.permissions;
    },
    hasPermission: (_state, getters) => (
      permission,
      { isNegative = false } = {}
    ) => {
      /* permission "sandbox" - is a permission to create a sandboxed
       * team, which everybody could, not a check if a person is
       * in a sandbox.
       *
       * permission "all" - is a permission that is set on a document
       * by backend for documents created by integration partners,
       * to bypass our subscription modal.
       * Context: `git grep doc.perm`
       *
       * For sandboxed users, all permissions are available.
       *
       * Watch out for negative permissions, like "trial_ended",
       * which, when present, disable functionality instead of enabling it
       * as they would also be true for sandboxed users/teams.
       * Here we assume that called would set `isNegative: true`
       * when checking for negative permission, so it would
       * be resolved to `false` on sandbox */
      if (permission === 'all' || permission === 'sandbox') {
        return true;
      }
      if (getters.onSandboxTeam) {
        return !isNegative;
      }

      return !!getters.userPermissions[permission];
    },
    planQuantity(state) {
      return state.user.permissions.quantity;
    },
    upgradeTo(state) {
      return state.user.permissions.upgrade_to;
    },
    onSandboxTeam(state) {
      return (
        state.user &&
        state.user.team_user &&
        state.user.team_user.team &&
        state.user.team_user.team.sandbox
      );
    },
    teamOptions(state) {
      const personalAccountOption = {
        value: 'personal',
        text: Vue.prototype.$gettext('Personal')
      };

      return [personalAccountOption, ...state.user.default_team_options];
    },
    numSignRequestsLeftInBundle(state) {
      if (
        state.user &&
        state.user.active_subscription &&
        state.user.active_subscription.bundle
      ) {
        return state.user.active_subscription.bundle
          .signrequests_left_in_bundle;
      }
      return 0;
    },
    hasTokenGeneratorDevice(state) {
      return (
        state.user.has_2fa &&
        state.user.two_factor &&
        state.user.two_factor.devices &&
        state.user.two_factor.devices.some(x => x.device_type === 'generator')
      );
    },
    hasTag(state) {
      return tagName => state.user.tags.includes(tagName);
    },
    betaHomeboxOverrideValue() {
      return router.currentRoute.query['new'];
    },
    hasBetaHomeboxEnabled(state, getters) {
      const urlOverrideValue = getters.betaHomeboxOverrideValue;
      if (urlOverrideValue) {
        return urlOverrideValue === 'yes';
      }
      return getters.hasTag(ENABLE_BETA_HOMEBOX);
    },
    hasBetaSignModeEnabled(state, getters) {
      const urlOverrideValue = router.currentRoute.query['new_sign'];
      if (urlOverrideValue) {
        return urlOverrideValue === 'yes';
      }
      const isIframe = self !== top;
      const isTeam = global.location.hostname.toString().split('.').length > 2;

      return (
        (!isIframe && !isTeam && getters.hasTag(ENABLE_BETA_HOMEBOX)) ||
        getters.hasTag(ENABLE_BETA_SIGN_MODE)
      );
    },
    teamUsers(state) {
      return state.teamUsers;
    },
    teamUsersLoaded(state) {
      return Boolean(state.teamUsersLoaded);
    }
  },
  mutations: {
    reset(state) {
      Object.assign(state, initialState());
    },
    setUser(state, user) {
      state.user = Object.freeze(user);
    },
    setTeamDeleted(state, value) {
      state.teamDeleteAfter = value;
    },
    setUserTemplates(state, templates) {
      state.user = Object.freeze({
        ...state.user,
        templates: Object.freeze(templates)
      });
    },
    agreeTerms(state) {
      state.user = Object.freeze({
        ...state.user,
        agreed_to_terms: true
      });
    },
    setTags(state, tags) {
      state.user = Object.freeze({
        ...state.user,
        tags
      });
    },
    setUserLoaded(state, signerUUID) {
      state.userLoaded = true;
      state.signerUUID = signerUUID;
    },
    setTeamUser(state, update) {
      state.user = Object.freeze({
        ...state.user,
        team_user: Object.freeze({
          ...state.user.team_user,
          ...update
        })
      });
    },
    setUserTeamLoading(state, flag) {
      state.teamLoading = Boolean(flag);
    },
    setTeamUsers(state, users) {
      state.teamUsers = users;
    },
    setTeamUsersLoaded(state, teamUsersLoaded) {
      state.teamUsersLoaded = teamUsersLoaded;
    },
    setNotificationSettings(state, { notificationType, settings }) {
      state[notificationType] = settings;
    },
    setDocumentSettings(state, { settings }) {
      state.document_settings = settings;
    },
    setSignerFromAngular(state, signer_uuid) {
      // @TODO remove in the next five years
      // this is necessary for the transitionary between vue and angular
      // presence of the signer uuid tells whether angular signing page is active
      state.signer_uuid_from_angular = signer_uuid;
    },
    addSignature(state, { sig, localId }) {
      let newSigs = state.user.sigs.map(iter =>
        iter.localId === localId ? merge(iter, sig) : iter
      );
      if (!newSigs.find(iter => iter.localId === localId)) {
        newSigs = prepend(sig, newSigs);
      }
      state.user = merge(state.user, {
        sigs: Object.freeze(newSigs)
      });
    },
    removeSignature(state, { uuid, localId }) {
      const newSigs = state.user.sigs.filter(
        iter => !(iter.uuid === uuid || (localId && iter.localId === localId))
      );
      state.user = merge(state.user, {
        sigs: Object.freeze(newSigs)
      });
    },
    addContacts(state, contacts) {
      state.user = merge(state.user, {
        contacts: concat(state.user.contacts, contacts)
      });
    }
  },
  actions: {
    async _getUser({ state, commit, dispatch }, { signerUUID }) {
      const resource = {
        url: signerUUID
          ? `/user/auth/session/?signer_uuid=${signerUUID}`
          : '/user/auth/session/'
      };
      const response = await dispatch('api/makeRequest', resource, {
        root: true
      });
      if (!response || !response.user) {
        return null;
      }

      commit('setUser', { ...response.user });
      commit('setUserLoaded', signerUUID);

      return state.user;
    },
    async getUser(
      { state, dispatch },
      { signerUUID = null, anyUser = false, forceReload = false } = {}
    ) {
      const isSameUUID = signerUUID === state.signerUUID || anyUser;
      if (!forceReload && state.userLoaded && isSameUUID) {
        return state.user;
      }

      getUserPromise = getUserPromise || dispatch('_getUser', { signerUUID });
      const ret = await getUserPromise;
      getUserPromise = null;
      return ret;
    },
    async copyFlags({ dispatch, getters }) {
      const enableBetaHomebox = getters.hasBetaHomeboxEnabled;
      const betaHomeboxOverrideValue = getters.betaHomeboxOverrideValue;

      const oldApiFallback =
        (router.currentRoute.query.api === 'v1' ||
          self !== top ||
          router.currentRoute.path.startsWith('/salesforce/')) &&
        router.currentRoute.query.source !== 'self' &&
        enableBetaHomebox &&
        !betaHomeboxOverrideValue;
      const isVueHomebox =
        !config.allowNewHomeboxOptOut || (!oldApiFallback && enableBetaHomebox);

      const isVueTemplate =
        isVueHomebox && router.currentRoute.query['new_template'] === 'yes';

      // At this point we do not have user data that contains tags
      // which means that `users/hasBetaHomeboxEnabled` value could flip
      // after we decide which version of app to show.
      // Sor we copy flag from angular to vuex to ensure they are in sync.
      dispatch(
        'conf/setFlags',
        {
          vueHomebox: isVueHomebox,
          vueTemplate: isVueTemplate,
          vueSignbox:
            !config.allowNewHomeboxOptOut ||
            (Boolean(getters.hasBetaSignModeEnabled) && isVueHomebox),
          oldApiFallback,
          versionSwitchDismissed:
            !config.allowNewHomeboxOptOut ||
            getCookie(DISMISS_VERSION_SWITCH) === 'yes' ||
            (getCookie('versionSwitchDismissed') === 'yes' && isVueHomebox)
        },
        { root: true }
      );

      await dispatch('getUser', {
        signerUUID: router.currentRoute.params.signer_uuid
      });

      const userLoggedIn = getters.userLoggedIn;
      if (userLoggedIn && betaHomeboxOverrideValue) {
        const action = enableBetaHomebox ? 'setTag' : 'unsetTag';
        await dispatch(action, ENABLE_BETA_HOMEBOX);
      }
    },
    async getTags({ commit, dispatch }) {
      const tags = getJSONCookie('sr_user_tags');
      commit('setTags', tags || [ENABLE_BETA_HOMEBOX]);
      return dispatch('copyFlags');
    },
    async getTeamUsers({ commit, dispatch }) {
      commit('setTeamUsersLoaded', false);
      const url = '/user/auth/get-team-users/';
      const teamUsers = await dispatch(
        'api/makeRequest',
        { url, method: 'POST', data: {} },
        { root: true }
      );
      commit('setTeamUsers', teamUsers);
      commit('setTeamUsersLoaded', true);
    },
    async deleteTeamUser({ commit, dispatch }, teamUserUuid) {
      commit('setTeamUsersLoaded', false);
      const data = { team_user_uuid: teamUserUuid };
      await dispatch('removeMemberReq', { data });
      await dispatch('getTeamUsers');
    },
    async updateTeamUser(
      { commit, dispatch, getters },
      { teamUserUuid, isOwner }
    ) {
      commit('setTeamUsersLoaded', false);
      const data = { team_user_uuid: teamUserUuid, is_owner: isOwner };
      const { status } = await dispatch('updateMemberReq', { data });
      if (status !== 'SUCCESS') {
        return;
      }
      const members = getters.teamMembers.map(member => {
        if (member.uuid == teamUserUuid) {
          member.is_owner = isOwner;
        }
        return member;
      });
      await dispatch('updateUserTeamSettings', { members });
    },
    switchTeamReq: request('POST', '/user/auth/team/switch-team/'),
    async switchTeam({ dispatch }, teamUser) {
      const data = {
        uuid: teamUser ? teamUser.uuid : ''
      };
      const { team } = await dispatch('switchTeamReq', { data });
      location.hostname = team
        ? `${team.subdomain}.${config.baseDomain}`
        : config.baseDomain;
    },
    async updateUser({ commit, dispatch }, user) {
      const url = '/user/auth/update-user/';

      await dispatch(
        'api/makeRequest',
        { url, method: 'POST', data: user },
        { root: true }
      );

      await dispatch('getUser', { forceReload: true });
    },
    async updateEmail({ commit, dispatch }, newEmail) {
      const url = '/user/auth/request-email-change/';
      const response = await dispatch(
        'api/makeRequest',
        { url, method: 'POST', data: { new_email: newEmail } },
        { root: true }
      );
      return response && response.status === 'SUCCESS';
    },
    async prepareForDeleteUser({ commit, dispatch }) {
      const url = '/user/auth/prepare-for-delete-user/';
      const response = await dispatch(
        'api/makeRequest',
        { url, method: 'POST' },
        { root: true }
      );

      if (response.status !== 'ERROR') {
        await dispatch('api/reloadHome', {}, { root: true });
      }
    },
    async deleteDevice({ commit, dispatch }, { deviceType, deviceUuid }) {
      const url = '/user/2fa/delete-device/';
      await dispatch(
        'api/makeRequest',
        {
          url,
          method: 'POST',
          data: {
            device_type: deviceType,
            device_uuid: deviceUuid
          }
        },
        { root: true }
      );
      await dispatch('getUser', { forceReload: true });
    },
    async disableTwoFactorAuth({ dispatch }) {
      const url = '/user/2fa/disable-2fa/';
      await dispatch(
        'api/makeRequest',
        {
          url,
          method: 'POST'
        },
        { root: true }
      );
      await dispatch('getUser', { forceReload: true });
    },
    async updateNotificationSettings(
      { dispatch, commit },
      { notificationType, settings }
    ) {
      const url = `/user/auth/update-notification-settings/${notificationType}/`;
      await dispatch(
        'api/makeRequest',
        {
          url,
          method: 'POST',
          data: settings
        },
        { root: true }
      );
      commit('setNotificationSettings', { notificationType, settings });
    },
    async updateDocumentSettings({ dispatch, commit }, { settings, category }) {
      const url = `/user/auth/update-document-settings/`;
      await dispatch(
        'api/makeRequest',
        {
          url,
          method: 'POST',
          data: {
            ...settings,
            category: category
          }
        },
        { root: true }
      );
      commit('setDocumentSettings', { settings });
    },
    async updateAgreedToTerms({ dispatch }, { agreed_to_terms }) {
      const url = `/user/auth/update-terms-setting/`;
      await dispatch(
        'api/makeRequest',
        {
          url,
          method: 'POST',
          data: {
            agreed_to_terms: agreed_to_terms
          }
        },
        { root: true }
      );
    },
    async deleteIntegration({ dispatch }, integrationName) {
      const url = `/user/auth/del-integration/${integrationName}/`;
      await dispatch(
        'api/makeRequest',
        {
          url,
          method: 'POST'
        },
        { root: true }
      );
      await dispatch('getUser', { forceReload: true });
    },
    async deleteContacts({ dispatch }, contacts) {
      const url = '/user/auth/del-contacts/';
      await dispatch(
        'api/makeRequest',
        {
          url,
          method: 'POST',
          data: {
            contacts: contacts.map(x => ({ value: x, selected: true }))
          }
        },
        { root: true }
      );
      await dispatch('getUser', { forceReload: true });
    },
    async loginRequest({ commit, dispatch }, { email, password }) {
      const url = '/user/auth/login/';
      return dispatch(
        'api/makeRequest',
        { url, method: 'POST', data: { email, password } },
        { root: true }
      );
    },
    async postLogin({ state, dispatch }, { switchHost, hostname, next }) {
      // this should be called after a successful login and/or register
      const { $location } = await getAngularDeps('$location');
      const { UserService } = await getAngularDeps('UserService');
      await UserService.refreshUser(state.signerUUID);
      await UserService.refreshUserTags();

      if (switchHost) {
        const current = new URL($location.absUrl());
        const nextPath = next || $location.url();
        const nextUrl = new URL(`#${nextPath}`, hostname || current);
        if (nextUrl.hostname !== current.hostname) {
          location.assign(nextUrl.toString());
          return true;
        }
      }

      if (next) {
        location.assign(next);
      } else {
        $location.search('login_success', '1');
      }

      return true;
    },
    async login({ dispatch }, { switchHost, next, ...params }) {
      const { status, redirect_host: hostname } = await dispatch(
        'loginRequest',
        params
      );

      if (status !== 'SUCCESS') {
        return false;
      }

      return dispatch('postLogin', { switchHost, hostname, next });
    },
    async registerRequest(
      { dispatch },
      { email, password1, password2, next, isNew = false }
    ) {
      const url = '/user/auth/register/';
      return dispatch(
        'api/makeRequest',
        {
          url,
          method: 'POST',
          data: { email, password1, password2, new: isNew, next },
          silent: true
        },
        { root: true }
      );
    },
    async register({ dispatch }, params) {
      const { email } = params;
      const { status, user_logged_in } = await dispatch('registerRequest', {
        ...params,
        isNew: true
      });

      if (status !== 'SUCCESS') {
        return false;
      }

      if (!user_logged_in) {
        await dispatch(
          'signrequest/navigateTo',
          `/confirm?config=${makeEncodedUrlParam({ email, activate: true })}`,
          { root: true }
        );
        return true;
      }

      return dispatch('postLogin', {});
    },
    checkEmail: debouncePromise(async function checkEmail(
      { dispatch },
      { email }
    ) {
      const params = new URLSearchParams();
      params.append('email', email);
      const url = `/user/auth/check-user/?${params}`;
      return dispatch(
        'api/makeRequest',
        { url: url.toString(), method: 'GET', expectCodes: [200, 404] },
        { root: true }
      );
    },
    EMAIL_CHECK_THROTTLE),
    async setTag({ dispatch }, tag_name) {
      const url = '/user/auth/set-tag';
      await dispatch(
        'api/makeRequest',
        { url, method: 'POST', data: { tag_name } },
        { root: true }
      );
    },
    async unsetTag({ dispatch }, tag_name) {
      const url = '/user/auth/unset-tag';
      await dispatch(
        'api/makeRequest',
        { url, method: 'POST', data: { tag_name } },
        { root: true }
      );
    },
    addTemplate({ commit, getters }, { uuid, name }) {
      commit('setUserTemplates', [
        Object.freeze({ name, uuid }),
        ...getters.userTemplates
      ]);
    },
    async resendValidationEmail({ dispatch }, { email }) {
      const url = '/user/auth/resend-validate-email/';
      await dispatch(
        'api/makeRequest',
        { url, method: 'POST', data: { email } },
        { root: true }
      );
    },
    acceptTermsAndConditions({ commit }) {
      commit('agreeTerms');
    },
    async deleteSignature({ dispatch, commit, getters }, { localId, uuid }) {
      commit('removeSignature', { uuid, localId });
      if (!getters.userLoggedIn) {
        return;
      }
      if (uuid === localId) {
        /* it was never saved to backend and assigned uuid */
        return;
      }
      const url = '/user/auth/del-sig/';
      await dispatch(
        'api/makeRequest',
        { url, method: 'POST', data: { uuid } },
        { root: true }
      );
    },
    async saveSignature({ dispatch, commit, getters }, sig) {
      commit('addSignature', { sig, localId: null });
      if (!getters.userLoggedIn) {
        return;
      }
      const url = '/user/auth/save-sig/';
      const data = {
        ...sig,
        uuid: null
      };
      const { signature } = await dispatch(
        'api/makeRequest',
        { url, method: 'POST', data },
        { root: true }
      );
      /* NOTE: for some reason, this request returns status=ERROR
         even when signature is successfully added and assigned uuid */
      if (!signature) {
        return;
      }
      commit('addSignature', { sig: signature, localId: sig.localId });
    },
    async addSignersToContacts({ dispatch, commit, getters }, signers) {
      const emails = getters.userContacts.map(contact =>
        contact.value.toLowerCase()
      );
      const notPresent = signer => !emails.includes(signer.email.toLowerCase());
      const signerToContact = signer => ({
        text: signer.name,
        value: signer.email
      });
      commit('addContacts', signers.filter(notPresent).map(signerToContact));
    },
    async socialLoginFailed({ dispatch }) {
      const errorMsg = {
        type: 'error',
        msg:
          'Login was unsuccessful due to the security measures of your organisation. ' +
          'To continue, please use your Single Sign-On (SSO) login or contact your SignRequest administrator.',
        timeout: 60000
      };
      dispatch('messages/addMessage', errorMsg, { root: true });
    },
    async acceptInvite({ dispatch }, { token, uuid, ...params }) {
      const url = '/user/auth/accept-invite/';
      const data = { token, uuid };
      return dispatch(
        'api/makeRequest',
        { url, method: 'POST', data, ...params },
        { root: true }
      );
    },
    getRedirectURLifSSOEnabled: debouncePromise(
      async function getRedirectURLifSSOEnabled({ dispatch }, email) {
        const url = '/user/auth/saml/enabled/';
        const data = {
          email: email
        };
        const response = await dispatch(
          'api/makeRequest',
          { url, method: 'POST', data },
          { root: true }
        );
        try {
          return response && response.redirect_url
            ? redirectFilter(response.redirect_url)
            : '';
        } catch (e) {
          return '';
        }
      },
      EMAIL_CHECK_THROTTLE
    ),
    updateUserTeamSettings({ commit, getters }, form) {
      commit('setTeamUser', { team: { ...getters.userTeamSettings, ...form } });
    },
    updateApiSettings({ commit, getters }, form) {
      commit('setTeamUser', {
        api_settings: { ...getters.apiSettings, ...form }
      });
    },
    saveTeamSettingsReq: request('POST', '/user/auth/team/update-team/'),
    async saveTeamSettings({ commit, dispatch, getters }, update) {
      commit('setUserTeamLoading', true);
      const data = {
        ...getters.userTeamSettings,
        ...getters.apiSettings,
        ...update
      };
      const response = await dispatch('saveTeamSettingsReq', { data });
      commit('setUserTeamLoading', false);
      const { status } = response;
      if (status !== 'SUCCESS') {
        return;
      }

      await dispatch('updateUserTeamSettings', update);
      if (getters.hasActiveTeam) {
        return;
      }

      await dispatch('getUser', { forceReload: true });
      const teamUser = getters.availableTeamUsers.find(
        iterUser => iterUser.team.subdomain === data.subdomain
      );
      if (!teamUser) {
        return;
      }
      await dispatch('switchTeam', teamUser);
    },
    async saveTeamLogo({ commit, dispatch, getters }, file) {
      const url = `/user/auth/team/upload-logo/${getters.userTeamSettings.subdomain}/`;
      const data = new FormData();
      data.append('logo', file);
      const response = await dispatch(
        'api/makeRequest',
        {
          method: 'POST',
          url,
          data
        },
        { root: true }
      );
      const { status, logo_url } = response;
      if (status === 'SUCCESS') {
        await dispatch('updateUserTeamSettings', { logo: logo_url });
      }
    },
    deleteTeamReq: request('POST', '/user/auth/team/prepare_for_delete/'),
    async deleteTeam({ dispatch, commit, getters }) {
      const data = {
        subdomain: getters.userTeamSettings.subdomain
      };
      const { status, delete_after } = await dispatch('deleteTeamReq', {
        data
      });
      if (status === 'SUCCESS') {
        commit('setTeamDeleted', delete_after);
      }
    },
    checkSubDomainReq: request('POST', '/user/auth/team/check-domain/'),
    async checkSubDomain({ dispatch }, subdomain) {
      return dispatch('checkSubDomainReq', { data: { subdomain } });
    },
    inviteMemberReq: request('POST', '/user/auth/team/invite-user/'),
    async inviteMember({ dispatch, getters }, email) {
      const data = {
        email,
        team_user_uuid: getters.teamUser.uuid
      };
      const { status, should_upgrade } = await dispatch('inviteMemberReq', {
        data
      });
      if (should_upgrade) {
        return dispatch('modals/showUpgradeModal', {}, { root: true });
      }
      if (status !== 'SUCCESS') {
        return null;
      }
      await dispatch('getUser', { forceReload: true });
      return true;
    },
    removeMemberReq: request('POST', '/user/auth/team/remove-team-member/'),
    async removeMember({ dispatch, getters }, uuid) {
      const data = {
        team_user_uuid: uuid
      };
      const { status } = await dispatch('removeMemberReq', { data });
      if (status !== 'SUCCESS') {
        return;
      }
      const members = getters.teamMembers.filter(
        iterMember => iterMember.uuid !== uuid
      );
      await dispatch('updateUserTeamSettings', { members });
    },
    updateMemberReq: request('POST', '/user/auth/team/update-team-member/'),
    createApiTokenReq: request('POST', '/user/auth/team/create-api-token/'),
    async createApiToken({ dispatch, getters }, name) {
      const data = {
        name,
        subdomain: getters.userTeamSettings.subdomain
      };
      const { status, token } = await dispatch('createApiTokenReq', { data });
      if (status !== 'SUCCESS') {
        return null;
      }
      const tokens = [...getters.apiSettings.tokens, token];
      await dispatch('updateApiSettings', { tokens });
      return true;
    },
    deleteApiTokenReq: request('POST', '/user/auth/team/delete-api-token/'),
    logoutUserReq: requestWithParams('POST', '/user/action/logout/', {
      expectCodes: [302],
      silent: true,
      silentError: true
    }),
    async logoutUser({ dispatch }) {
      await dispatch('logoutUserReq');
      window.location.href = '/';
    },
    async deleteApiToken({ dispatch, getters }, key) {
      const data = {
        key,
        subdomain: getters.userTeamSettings.subdomain
      };
      const { status } = await dispatch('deleteApiTokenReq', { data });
      if (status !== 'WARNING') {
        return;
      }
      const tokens = getters.apiSettings.tokens.filter(
        iterToken => iterToken.key !== key
      );
      await dispatch('updateApiSettings', { tokens });
    },
    findBoxContactsReq: request('GET', '/app-api/enduserapp/contacts'),
    async findBoxContacts(
      { dispatch },
      { query, includeGroups = true, includeSelf = true }
    ) {
      const data = {
        query,
        includeGroups,
        includeSelf
      };
      return dispatch('findBoxContactsReq', { data, ignoreRoot: true });
    }
  }
};
