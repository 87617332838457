<template>
  <v-form
    ref="form"
    v-model="valid"
    class="login-form"
    @submit.prevent="submit"
  >
    <v-divider v-show="!social" />
    <sr-input
      v-if="!social"
      v-model="email"
      base
      type="email"
      name="email"
      :readonly="Boolean(initialValue.email)"
      :disabled="Boolean(initialValue.email)"
      :label="$gettext('Your email')"
      :rules="[emailRule]"
      validate-on-blur
      autofocus
    />
    <v-divider />
    <div v-if="!redirectToSSOURL">
      <sr-input
        v-if="!social"
        v-model="password"
        base
        name="password"
        :label="$gettext('Password')"
        :append-icon="showPassword ? 'visibility' : 'visibility_off'"
        :type="showPassword ? 'text' : 'password'"
        :rules="[passwordRule]"
        validate-on-blur
        @click:append="showPassword = !showPassword"
      />
      <v-divider />
      <agree-to-terms v-if="registration" v-model="terms" />
      <v-checkbox
        v-if="!registration"
        key="remember"
        v-model="remember"
        name="remember"
        class="remember"
      >
        <template v-slot:label>
          <div class="font-weight-regular">
            <translate>Remember me</translate>
          </div>
          <div
            style="margin-left: auto; text-align: right"
            class="font-weight-regular"
          >
            <a href="/#/login/recover" @click="$emit('close')">
              <translate>Forgot password</translate>
            </a>
          </div>
        </template>
      </v-checkbox>
    </div>
    <p v-else v-translate class="text-xs-center">
      *Due to the security measures of your organisation you must use Single
      Sign-On (SSO) login.
    </p>

    <div class="text-xs-center">
      <sr-button
        class="button--fluid get-started"
        type="submit"
        sr-style="fancy"
        :round="true"
        data-test-id="login-button"
      >
        <span v-if="redirectToSSOURL" key="redirect-to-sso">
          Use SSO
        </span>
        <span v-else-if="registration" key="register" v-translate>
          Get started
        </span>
        <span v-else key="login" v-translate>Login</span>
      </sr-button>
    </div>
  </v-form>
</template>
<script>
import SrButton from '@/components/elements/SrButton.vue';
import { validateEmail, validatePassword } from '@/utils/format';
import { mapActions, mapGetters } from 'vuex';
import SrInput from '@/components/elements/SrInput';
import AgreeToTerms from './AgreeToTerms';
import { redirectFilter } from '@/utils/encodedParams';

export default {
  components: {
    AgreeToTerms,
    SrButton,
    SrInput
  },
  props: {
    initialValue: { type: Object, required: true },
    registration: { type: Boolean, default: false },
    switchHost: { type: Boolean, default: false },
    social: { type: Boolean, default: false },
    next: { type: String, default: null }
  },
  data() {
    return {
      email: '',
      password: '',
      terms: false,
      remember: false,
      showPassword: false,
      valid: true,
      redirectToSSOURL: '',
      ...this.$props.initialValue
    };
  },
  computed: {
    ...mapGetters('conf', ['getPageUrlForLang']),
    ...mapGetters('users', ['userLoggedIn']),
    isComplete() {
      return this.$props.registration
        ? this.email && this.password && this.terms
        : this.email && this.password;
    },
    termsAcceptRule() {
      return v => !!v || '';
    },
    emailRule() {
      return validateEmail.bind(this);
    },
    passwordRule() {
      if (!this.registration) {
        return !!this.password || `${this.$gettext('Enter password')}.`;
      }

      return validatePassword.bind(this);
    },
    redirectToSSO() {
      return this.getRedirectURLifSSOEnabled(this.email);
    }
  },
  watch: {
    initialValue: {
      immediate: true,
      deep: true,
      handler(initialValue) {
        if (this.email !== initialValue.email) {
          this.email = initialValue.email;
        }
      }
    },
    email: {
      immediate: true,
      deep: true,
      async handler(newEmail, oldEmail) {
        if (
          (newEmail && newEmail.includes('@') && newEmail.includes('.')) ||
          (oldEmail &&
            oldEmail.includes('@') &&
            oldEmail.includes('.') &&
            oldEmail != newEmail)
        ) {
          try {
            this.redirectToSSOURL = await this.redirectToSSO;
            this.$emit(
              'hideSocialAndSubheading',
              Boolean(this.redirectToSSOURL)
            );
          } catch (e) {
            return;
          }
        }
      }
    }
  },
  methods: {
    ...mapActions('signrequest', ['navigateTo']),
    ...mapActions('users', ['login', 'register', 'getRedirectURLifSSOEnabled']),
    async submit() {
      const redirectToSSOURL = await this.redirectToSSO;
      if (redirectToSSOURL) {
        this.redirectToSSOPage();
      } else {
        if (!this.$refs.form.validate()) {
          return;
        }

        const { registration, social, switchHost } = this.$props;
        if (social) {
          this.$emit('submitSocial');
          return;
        }

        const { email, password, next } = this;
        const request = registration
          ? this.register({
              email,
              next,
              password1: password,
              password2: password
            })
          : this.login({ email, password, switchHost, next });
        if (await request) {
          this.$emit('success');
        }
      }
    },
    async redirectToSSOPage() {
      this.navigateTo(redirectFilter(this.redirectToSSOURL));
    }
  }
};
</script>
<style scoped lang="scss">
.v-divider {
  margin: 0px;
  transform: scaleY(0.5);
}
.v-divider + .v-divider {
  display: none;
}
button {
  font-size: 18px;
  min-height: 55px;
  margin: 0;
}
.get-started {
  max-width: 250px;
}
.v-input--checkbox.error--text,
.v-input--checkbox ::v-deep label.error--text {
  color: inherit !important;
}
.remember {
  ::v-deep .v-input__control,
  ::v-deep label {
    width: 100%;
  }
}
</style>
