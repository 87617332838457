const EMPTY = Object.freeze({});
export default function(modalKey, focusFirst = 'focusFirst') {
  return {
    KEY: modalKey,
    data() {
      return {
        showModal: false
      };
    },
    computed: {
      modalShouldBeActive() {
        return Boolean(this.$store.getters['modals/findModal'](modalKey));
      },
      modalOptions() {
        return this.modalShouldBeActive
          ? this.$store.getters['modals/currentModal']
          : EMPTY;
      }
    },
    watch: {
      modalShouldBeActive: {
        immediate: true,
        handler(value, old) {
          this.showModal = value;
          if (value) {
            this.trapFocus();
          } else {
            this.releaseFocus();
          }
        }
      },
      showModal(value) {
        if (this.modalShouldBeActive && !value) {
          // this will trigger then modal closes
          // inform the vuex that the modal is closed

          const returnValue = this.returnValue || null;
          this.$store.dispatch('modals/closeModal', {
            returnValue,
            key: modalKey
          });
          this.returnValue = null;
        }
      }
    },
    methods: {
      closeModal() {
        this.showModal = false;
      },
      trapFocus() {
        document.addEventListener('focus', this.modalHandleFocus, true);
      },
      releaseFocus() {
        document.removeEventListener('focus', this.modalHandleFocus, true);
      },
      modalHandleFocus(event) {
        if (!this.$refs.modal) {
          return;
        }
        const isInside = this.$refs.modal.$refs.content.contains(event.target);
        if (!isInside) {
          this.modalFocusFirst();
        }
      },
      modalFocusFirst() {
        if (this[focusFirst]) {
          this[focusFirst]();
        }
      },
      modalResolve(returnValue) {
        this.$store.dispatch('modals/resolveModal', {
          returnValue,
          key: modalKey
        });
      }
    }
  };
}
