import angular from 'angular';
//  angularModalService.js
//
//  Service for showing modal dialogs.

/***** JSLint Config *****/
/*global angular  */
(function() {
  'use strict';

  var module = angular.module('angularModalService', []);

  module.factory('ModalService', [
    '$document',
    '$compile',
    '$controller',
    '$http',
    '$rootScope',
    '$q',
    '$timeout',
    'templateService',
    function(
      $document,
      $compile,
      $controller,
      $http,
      $rootScope,
      $q,
      $timeout,
      templateService
    ) {
      //  Get the body of the document, we'll add the modal to this.
      var body = $document.find('body');

      function ModalService() {
        var self = this;

        self.showModal = function(options) {
          //  Create a deferred we'll resolve when the modal is ready.
          var deferred = $q.defer();

          //  Validate the input parameters.
          var controllerName = options.controller;
          if (!controllerName) {
            deferred.reject('No controller has been specified.');
            return deferred.promise;
          }

          //  If a 'controllerAs' option has been provided, we change the controller
          //  name to use 'as' syntax. $controller will automatically handle this.
          if (options.controllerAs) {
            controllerName = controllerName + ' as ' + options.controllerAs;
          }

          //  Get the actual html of the template.
          templateService
            .getTemplate(options.templateUrl, options.template)
            .then(function(template) {
              //  Create a new scope for the modal.
              var modalScope = angular.extend(
                $rootScope.$new(),
                options.scope || {}
              );

              //  Create the inputs object to the controller - this will include
              //  the scope, as well as all inputs provided.
              //  We will also create a deferred that is resolved with a provided
              //  close function. The controller can then call 'close(result)'.
              //  The controller can also provide a delay for closing - this is
              //  helpful if there are closing animations which must finish first.
              var closeDeferred = $q.defer();
              var inputs = {
                $scope: modalScope,
                close: function(result, delay) {
                  if (delay === undefined || delay === null) {
                    delay = 0;
                  }
                  $timeout(function() {
                    closeDeferred.resolve(result);
                  }, delay);
                }
              };

              //  If we have provided any inputs, pass them to the controller.
              if (options.inputs) {
                for (var inputName in options.inputs) {
                  if (options.inputs.hasOwnProperty(inputName)) {
                    inputs[inputName] = options.inputs[inputName];
                  }
                }
              }

              //  Parse the modal HTML into a DOM element (in template form).
              var modalElementTemplate = angular.element(template);

              //  Compile then link the template element, building the actual element.
              //  Set the $element on the inputs so that it can be injected if required.
              var linkFn = $compile(modalElementTemplate);
              var modalElement = linkFn(modalScope);
              inputs.$element = modalElement;

              //  Create the controller, explicitly specifying the scope to use.
              var modalController = $controller(controllerName, inputs);

              //  Finally, append the modal to the dom.
              if (options.appendElement) {
                // append to custom append element
                options.appendElement.append(modalElement);
              } else {
                // append to body when no custom append element is specified
                body.append(modalElement);
              }

              //  We now have a modal object.
              var modal = {
                controller: modalController,
                scope: modalScope,
                element: modalElement,
                close: closeDeferred.promise
              };

              //  When close is resolved, we'll clean up the scope and element.
              modal.close.then(function(result) {
                //  Clean up the scope
                modalScope.$destroy();
                //  Remove the element from the dom.
                modalElement.remove();
              });

              deferred.resolve(modal);
            })
            .catch(function(error) {
              deferred.reject(error);
            });

          return deferred.promise;
        };
      }

      return new ModalService();
    }
  ]);
})();
