import { request } from '@/store/utils';

function initialState() {
  return {
    isLoading: false,
    searchResults: { results: [] },
    deleteResult: {},
    requiredActionDocuments: [],
    requiredActionsCount: 0,
    statusChoices: [],
    attachmentsEnabled: false,
    exportEnabled: false
  };
}

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    setLoading(state, value) {
      state.isLoading = value;
    },
    setDocumentStatusInState(
      state,
      { uuid, status = '', status_display = '' }
    ) {
      const newResults = state.searchResults.results.map(doc => {
        if (doc.uuid !== uuid) {
          return doc;
        }
        return {
          ...doc,
          status: status || doc.status,
          status_display: status_display || doc.status_display
        };
      });
      state.searchResults = {
        ...state.searchResults,
        results: newResults
      };
    },
    setSearchResults(state, results) {
      state.searchResults = results;
    },
    setDeleteResult(state, result) {
      state.deleteResult = result;
    },
    setRequiredActionDocuments(state, result) {
      state.requiredActionDocuments = result;
    },
    setRequiredActionsCount(state, count) {
      state.requiredActionsCount = count;
    },
    setStatusChoices(state, choices) {
      state.statusChoices = choices;
    }
  },
  getters: {
    isAttachmentsEnabled(state) {
      return state.attachmentsEnabled;
    },
    isExportEnabled(state) {
      return state.exportEnabled;
    },
    isLoading(state) {
      return state.isLoading;
    },
    requiredActionsCount(state) {
      return state.requiredActionsCount;
    },
    requiredActionDocuments(state) {
      return state.requiredActionDocuments;
    },
    statusChoices(state) {
      return state.statusChoices;
    }
  },
  actions: {
    async search(
      { commit, dispatch, getters },
      { page, limit, query, status }
    ) {
      commit('setLoading', true);
      const searchParams = new URLSearchParams({
        autocomplete: query,
        parent_doc__exists: false,
        page,
        limit,
        status
      }).toString();

      let url = `/api/v1/documents-search/?${searchParams}`;

      const response = await dispatch(
        'api/makeRequest',
        { url },
        { root: true }
      );

      if (response && response.results) {
        commit('setSearchResults', Object.freeze(response));
      }
      commit('setLoading', false);
    },
    async delete({ commit, dispatch }, { uuids }) {
      commit('setLoading', true);
      const result = await dispatch(
        'api/makeRequest',
        {
          url: '/docs/soft-del-docs/',
          method: 'POST',
          data: { doc_uuids: uuids }
        },
        { root: true }
      );

      commit('setDeleteResult', result);
      commit('setLoading', false);
    },
    async getRequiredActions({ commit, dispatch }) {
      commit('setLoading', true);
      const result = await dispatch(
        'api/makeRequest',
        { url: '/docs/required-user-actions/' },
        { root: true }
      );
      commit('setRequiredActionDocuments', result.results);
      commit('setRequiredActionsCount', result.results.length);
      commit('setLoading', false);
    },
    async getRequiredActionsCount({ commit, dispatch }) {
      commit('setLoading', true);
      const { count } = await dispatch(
        'api/makeRequest',
        { url: '/docs/required-user-actions-count/' },
        { root: true }
      );

      commit('setRequiredActionsCount', count);
      commit('setLoading', false);
      return { count };
    },
    async getStatusChoices({ commit, dispatch }) {
      const result = await dispatch(
        'api/makeRequest',
        { url: '/docs/get-search-options/' },
        { root: true }
      );

      commit('setStatusChoices', result.status_choices);
    },
    saveSigsRequest: request('POST', '/docs/user-sig/sigs/save/'),
    async declineDocument(
      { dispatch, commit },
      { message, documentUUID, signerUUID }
    ) {
      commit('setLoading', true);
      const data = {
        doc_uuid: documentUUID,
        signer_uuid: signerUUID,
        message,
        signer_declined: true
      };

      const { status } = await dispatch('saveSigsRequest', { data });

      if (!status) {
        dispatch(
          'messages/addMessage',
          { type: 'error', msg: 'E_OOPS' },
          { root: true }
        );
        return false;
      }
      commit('setDocumentStatusInState', {
        uuid: documentUUID,
        status: 'de',
        status_display: 'declined'
      });
      dispatch(
        'messages/addMessage',
        {
          type: 'warning',
          msg: 'W_DECLINED',
          timeout: 10000
        },
        { root: true }
      );

      dispatch('resetData');
      commit('setLoading', false);
      return true;
    },
    async cancelDocument({ state, dispatch, getters, commit }, { docUUID }) {
      var url = '/docs/cancel-doc/' + docUUID + '/';

      const { status } = await dispatch(
        'api/makeRequest',
        { url, method: 'POST', data: {} },
        { root: true }
      );
      if (status !== 'SUCCESS') {
        return null;
      }
      if (!status) {
        dispatch(
          'messages/addMessage',
          { type: 'error', msg: 'E_OOPS' },
          { root: true }
        );
        commit('setLoading', false);
        return false;
      }
      commit('setDocumentStatusInState', {
        uuid: documentUUID,
        status: 'ca',
        status_display: 'cancelled'
      });
      dispatch(
        'messages/addMessage',
        {
          type: 'warning',
          msg: 'W_CANCELED',
          timeout: 10000
        },
        { root: true }
      );
      return true;
    },
    async forwardDocument({ dispatch }, { reason, email, uuid, signerUUID }) {
      const url = `/docs/forward-document/${uuid}/${signerUUID}/`;
      const data = {
        email,
        forwarded_reason: reason
      };
      const { status } = await dispatch(
        'api/makeRequest',
        {
          url,
          method: 'POST',
          data
        },
        { root: true }
      );
      if (status !== 'SUCCESS') {
        return null;
      }
      return true;
    },
    async resendSignrequest({ dispatch }, { uuid }) {
      const url = `/api/v1/signrequests/${uuid}/resend_signrequest_email/`;
      const { detail } = await dispatch(
        'api/makeRequest',
        { url, method: 'POST' },
        { root: true }
      );
      if (detail !== 'OK') {
        return null;
      }
      return true;
    }
  }
};
