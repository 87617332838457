let config = 'sr';

export function setVariant(value) {
  config = value;
}

export function getVariant() {
  return config;
}

const nothing = { render: () => null };

export default function variant(boxComponent, srComponent) {
  return async () => (config === 'box' ? boxComponent : srComponent) || nothing;
}
