<template>
  <span class="arrow"></span>
</template>
<script>
import { kiteState } from './SrKiteArrow.vue';

export default {
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: { type: Boolean, default: true },
    priority: { type: Number, default: 0 },
    delay: { type: Number, default: 100 }
  },
  watch: {
    value() {
      kiteState.softUpdate(this.delay);
    },
    priority() {
      kiteState.softUpdate(this.delay);
    }
  },
  mounted() {
    kiteState.register(this);
  },
  beforeDestroy() {
    kiteState.unregister(this);
  }
};
</script>

<style scoped>
.arrow {
  display: none;
}
</style>
