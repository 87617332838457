<template>
  <div
    class="info-block bgray"
    :class="{ 'info-block--panel': panel, 'info-block--open': isOpen }"
  >
    <sr-button sr-style="text" inline @click="handleOpen">
      <h2 class="info-block--title bblack--text">
        {{ title }}
        <span class="arrow">
          >
        </span>
      </h2>
    </sr-button>
    <div v-show="isOpen" class="info-block--content">
      <slot />
    </div>
  </div>
</template>
<script>
import SrButton from '@/components/elements/SrButton';

export default {
  components: { SrButton },
  props: {
    panel: { type: Boolean, required: false, default: false },
    title: { type: String, required: true }
  },
  data() {
    return { isOpen: true };
  },
  methods: {
    handleOpen() {
      if (this.$props.panel) {
        this.$emit('open');
      } else {
        this.isOpen = !this.isOpen;
      }
    }
  }
};
</script>
<style scoped>
.sr-button {
  width: 100%;
}
.info-block {
  border-bottom: 1px solid;
  padding: 18px 16px 18px 24px;
  background: none !important;
}
.info-block h2 {
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: 0.3px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.arrow {
  font-family: SFCompact;
  opacity: 0.34;
  transform: rotate(90deg) scaleY(2);
}
.info-block.info-block--panel .arrow {
  transform: scaleY(2);
}
.info-block:not(.info-block--open) .arrow {
  transform: rotate(-90deg) scaleY(2);
}
.info-block.info-block--open .sr-button {
  margin-bottom: 24px;
}
.info-block--content {
  margin-bottom: 8px;
}
</style>
