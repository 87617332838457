import angular from 'angular';
import _ from 'lodash';
import $ from 'jquery';
import 'tooltipster';

/**
 * @ngdoc directive
 * @name frontendApp.directive:srTooltip
 * @description
 * # srTooltip
 */
angular
  .module('frontendApp')
  .directive('srTooltip', [
    '$q',
    '$http',
    '$templateCache',
    '$compile',
    'templateService',
    'deviceService',
    'utils',
    'localeService',
    function(
      $q,
      $http,
      $templateCache,
      $compile,
      templateService,
      deviceService,
      utils,
      localeService
    ) {
      return {
        // Restrict it to be an attribute in this case
        restrict: 'A',
        // responsible for registering DOM listeners as well as updating the DOM
        link: function(scope, element, attrs) {
          var defaults = {
            contentAsHtml: true,
            content: attrs.title || '',
            interactive: true,
            positionTracker: true,
            animation: 'fade',
            delay: 0,
            speed: 150,
            trigger: 'hover',
            theme: 'tooltipster-interactive tooltipster-dialog',
            contentCloning: false,
            autoClose: true,
            // selector of the next tooltip to open
            nextHelp: undefined,
            minWidth: 320
          };

          var opts = angular.extend(
            defaults,
            scope.$eval(attrs.srTooltip) || {}
          );

          if (attrs.srTooltipMobile && deviceService.is_mobile) {
            // add mobile specific tooltip opts
            angular.extend(opts, scope.$eval(attrs.srTooltipMobile));
          }

          if (_.isArray(opts.position) && localeService.isRTL()) {
            opts.position = _.map(opts.position, function(item) {
              if (item === 'left') {
                return 'right';
              } else if (item === 'right') {
                return 'left';
              } else {
                return item;
              }
            });
          }

          opts.template =
            opts.template ||
            utils.getHTML(element.find('[sr-tooltip-content]'));

          scope.openNext = function(selector) {
            $(selector).click();
          };

          scope.closeTooltip = function(selector) {
            //selector = selector || '#' + element.prop('id');
            //$(selector).tooltipster('hide');
            // why does the above not work?
            $('body').click();
          };

          var nextHtml =
            '' +
            '<p class="split">' +
            ' <div class="split__item">' +
            '   <a ng-href="" ng-click="closeTooltip();" class="button button--link button--link--grey" translate>Close</a>' +
            ' </div>' +
            ' <div class="split__item">' +
            '' +
            (opts.nextHelp
              ? '<a ng-href="" class="button" ng-click="openNext(\'' +
                opts.nextHelp +
                '\')" translate>Next</a>'
              : '') +
            ' </div>' +
            '</p>';

          if (opts.template || opts.templateUrl) {
            templateService
              .getTemplate(opts.templateUrl, opts.template)
              .then(function(template) {
                if (angular.isDefined(opts.nextHelp)) {
                  template = template + nextHtml;
                  scope.nextHelp = opts.nextHelp;
                }
                opts.content = $compile(template)(scope);
                $(element).tooltipster(opts);
              });
          } else {
            $(element).tooltipster(opts);

            //if (attrs.title){
            //  scope.$watch(attrs.title, function (n, o) {
            //    if (n !== o){
            //      $(element).tooltipster('content', attrs.title);
            //    }
            //  });
            //}
          }
        }
      };
    }
  ])
  .directive('srQuestion', [
    '$compile',
    function($compile) {
      return {
        transclude: true,
        restrict: 'E',
        link: function(scope, element, attrs, controller, transcludeFn) {
          // this cannot live in 'template' as that will compile the 'translate' directive and changes to the language
          // are not picked up
          var template = $(
            '<i class="icon icon--circle--question" ' +
              "sr-tooltip=\"{'trigger': 'hover', 'position': ['right', 'left', 'bottom', 'top']}\"" +
              "sr-tooltip-mobile=\"{'trigger': 'click', 'position': ['top', 'bottom']}\">" +
              '<script type="text/ng-template" sr-tooltip-content></script>' +
              '</i>'
          );
          // Note that the contents of <sr-question></sr-question> must be without whitespace for FF to work.
          transcludeFn(scope, function(transcludedContent) {
            template
              .find('script[sr-tooltip-content]')
              .html(transcludedContent);
            element.html($compile(template)(scope));
          });
        }
      };
    }
  ]);
