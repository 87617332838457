<template>
  <svg
    width="10px"
    height="10px"
    viewBox="0 0 10 10"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.2"
      stroke="currentColor"
    >
      <path d="M4,0 L4,8"></path>
      <path d="M0,4 L8,4"></path>
    </g>
  </svg>
</template>
<script>
export default {};
</script>
