<template>
  <div class="subscribe-successful" :class="{ mobile: $isMobile }">
    <SignerHandshake />
    <h1 v-if="userAlreadyOnPlan" class="srtext--text">
      <translate
        :translate-params="{
          planName: plan.displayName,
          trialOrPlan: trialOrPlanString
        }"
      >
        You are already enrolled in %{ planName } %{trialOrPlan}.
      </translate>
    </h1>
    <h1 v-else v-translate class="srtext--text">Congratulations!</h1>
    <div v-if="!userAlreadyOnPlan">
      <h3 v-if="!trialEligible">
        <translate :translate-params="{ planName: plan.displayName }">
          Your %{ planName } subscription has started.
        </translate>
      </h3>
      <h3 v-else class="srtext--text">
        <translate
          :translate-params="{
            trialDays: plan.trialDays,
            planName: plan.displayName
          }"
        >
          Your %{trialDays}-day free %{ planName } trial has started.
        </translate>
      </h3>
    </div>
    <sr-button
      class="x-large mt-5 start-signing"
      sr-style="fancy"
      round
      @click="$emit('startSigning')"
    >
      <span v-translate>Start signing</span>
      <i class="fa fa-arrow-right ml-2" aria-hidden="true"></i>
    </sr-button>
    <a href="/#/how-it-works" :target="targetPage" class="how-it-works">
      <span v-translate>How does it work?</span>
    </a>
  </div>
</template>
<script>
import SignerHandshake from '@/components/elements/SignerHandshake';
import { mapState, mapGetters } from 'vuex';
import Screen from '@/mixins/Screen';
export default {
  components: {
    SignerHandshake
  },
  mixins: [Screen],
  props: {
    userAlreadyOnPlan: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('subscription', ['plan']),
    ...mapGetters('subscription', ['trialEligible']),
    ...mapGetters('conf', ['getPageUrlForLang']),
    trialOrPlanString() {
      return this.userAlreadyOnPlan && this.trialEligible
        ? this.$gettext('trial')
        : this.$gettext('plan');
    },
    targetPage() {
      return this.$route.path == '/subscribe' ? '_self' : '_blank';
    }
  }
};
</script>
<style scoped lang="scss">
.subscribe-successful {
  max-width: 45.5rem;
  width: 45.5rem;
  padding: 70px;
  border-radius: 15px;
  border: 1px solid setting(colors, border-dark);
  box-shadow: -2px 6px 44px 10px rgba(0, 0, 0, 0.06);
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: setting(colors, white);
  margin: 0 auto;
}
.mobile.subscribe-successful {
  width: 100%;
}
h1 {
  margin-top: 0;
  font-size: 2.429rem;
  font-weight: 900;
  letter-spacing: 0.57px;
  text-align: center;
  max-width: 100%;
}
h3 {
  margin-top: 30px;
  font-size: 1.1rem;
  font-weight: 300;
  opacity: 0.8;
}
.start-signing {
  width: 250px;
  height: 50px;
  margin-top: 30px;
}
.how-it-works {
  font-size: 1.1rem;
  font-weight: bold;
  margin-top: 20px;
  color: #3972e1;
}
</style>
