<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <path
      fill-rule="evenodd"
      d="M7.66333309,4 C7.78266271,4 7.89710588,4.04456535 7.98149098,4.12389395 L10.868181,6.83760858 C10.9525887,6.91695844 11,7.02458886 11,7.13681491 L10.9996128,13.7307692 C10.9994971,14.4317187 10.3951246,15 9.64960754,15 L3.34993074,15 C2.60438458,15 2,14.431746 2,13.7307692 L2,5.26923077 C2,4.56825397 2.60438458,4 3.34993074,4 L7.66333309,4 Z M10.6633331,1 C10.7826627,1 10.8971059,1.04456535 10.981491,1.12389395 L13.868181,3.83760858 C13.9525887,3.91695844 14,4.02458886 14,4.13681491 L13.9996128,10.7307692 C13.9994971,11.4317187 13.3951246,12 12.6496075,12 L11.999,12 L12,7.14818186 C12,6.75221604 11.8347579,6.37631551 11.5468004,6.10502416 L8.68015682,3.40429381 C8.40339854,3.14355351 8.03555519,3 7.65525439,3 L5,3 L5,2.26923077 C5,1.56825397 5.60438458,1 6.34993074,1 L10.6633331,1 Z"
    />
  </svg>
</template>
<script>
export default {};
</script>
