<template>
  <v-tooltip v-model="shouldShow" color="white" bottom :z-index="22">
    <template v-slot:activator="{ on }">
      <slot />
    </template>
    <div class="warning-tooltip">
      <div v-if="userWarning === 'swt_mess'" key="3">
        <div>
          <strong v-translate>Tip!</strong>
          &nbsp;
          <translate>
            You can switch between your personal and team accounts here.
          </translate>
        </div>
      </div>
      <div v-if="userWarning === 'nyt_mess'" key="4">
        <div>
          <strong v-translate>Warning!</strong>
          &nbsp;
          <translate>
            You are not a member of this team. As a result your personal account
            is active and you are not acting on behalf of this team.
          </translate>
        </div>
      </div>
      <sr-button @click="handleDissmiss">
        <translate>Got it</translate>
      </sr-button>
    </div>
  </v-tooltip>
</template>
<script>
import { mapGetters } from 'vuex';
import { getCookie, setCookie } from '@/utils/getJSONCookie';
import SrButton from './elements/SrButton';
export default {
  components: { SrButton },
  data() {
    return {
      shouldShow: null
    };
  },
  computed: mapGetters('users', [
    'userWarning',
    'userHasToPay',
    'userHasToAskToPay',
    'userInvoiceUrl'
  ]),
  watch: {
    userWarning() {
      this.readCookie();
    }
  },
  mounted() {
    this.readCookie();
  },
  methods: {
    readCookie() {
      if (!this.userWarning) {
        return null;
      }
      const value = getCookie(this.userWarning);
      this.shouldShow = !value;
    },
    async handleDissmiss() {
      this.shouldShow = false;
      if (this.userWarning !== 'past_due') {
        setCookie(this.userWarning, 'y');
      }
    }
  }
};
</script>
<style scoped>
.warning-tooltip {
  padding: 1rem;
  font-size: 1rem;
  max-width: 25rem;
  color: #111111;
  display: flex;
  flex-direction: column;
}
.warning-tooltip button {
  align-self: end;
}

.v-tooltip__content {
  opacity: 1 !important;
}
</style>
