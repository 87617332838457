import angular from 'angular';

/**
 * @ngdoc service
 * @name frontendApp.DropboxService
 * @description
 * Utilities for performing A/B tests
 */
angular.module('frontendApp').service('DropboxService', [
  '$rootScope',
  '$window',
  '$q',
  function DropboxService($rootScope, $window, $q) {
    var service = this;

    service.isAvailable = function() {
      if (
        $window.Dropbox &&
        $window.Dropbox.isBrowserSupported &&
        $window.Dropbox.isBrowserSupported()
      ) {
        return (
          $rootScope.user &&
          $rootScope.user.integrations &&
          $rootScope.user.integrations.dropbox &&
          $rootScope.user.integrations.dropbox.enabled
        );
      }
      return false;
    };

    service.chooseFile = function() {
      var deferred = $q.defer();

      $window.Dropbox.choose({
        // Required. Called when a user selects an item in the Chooser.
        success: function(files) {
          var file = files[0];
          deferred.resolve(file);
          // file = {
          //   // Unique ID for the file, compatible with Dropbox API v2.
          //   id: "id:...",
          //
          //   // Name of the file.
          //   name: "filename.txt",
          //
          //   // URL to access the file, which varies depending on the linkType specified when the
          //   // Chooser was triggered.
          //   link: "https://...",
          //
          //   // Size of the file in bytes.
          //   bytes: 464,
          //
          //   // URL to a 64x64px icon for the file based on the file's extension.
          //   icon: "https://...",
          //
          //   // A thumbnail URL generated when the user selects images and videos.
          //   // If the user didn't select an image or video, no thumbnail will be included.
          //   thumbnailLink: "https://...?bounding_box=75&mode=fit",
          //
          //   // Boolean, whether or not the file is actually a directory
          //   isDir: false,
          // };
        },

        // Optional. Called when the user closes the dialog without selecting a file
        // and does not include any parameters.
        cancel: function() {
          deferred.reject();
        },
        // Optional. "preview" (default) is a preview link to the document for sharing,
        // "direct" is an expiring link to download the contents of the file. For more
        // information about link types, see Link types below.
        linkType: 'direct', // or "direct"

        // Optional. A value of false (default) limits selection to a single file, while
        // true enables multiple file selection.
        multiselect: false, // or true

        // Optional. This is a list of file extensions. If specified, the user will
        // only be able to select files with these extensions. You may also specify
        // file types, such as "video" or "images" in the list. For more information,
        // see File types below. By default, all extensions are allowed.
        extensions: ['.pdf', '.doc', '.docx', 'documents', 'text', 'images'],

        // Optional. A value of false (default) limits selection to files,
        // while true allows the user to select both folders and files.
        // You cannot specify `linkType: "direct"` when using `folderselect: true`.
        folderselect: false // or true
      });

      return deferred.promise;
    };
  }
]);
