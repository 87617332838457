<template>
  <div class="sr-control-button wrapper">
    <button
      :class="classes"
      :tabindex="0"
      role="button"
      :disabled="disabled"
      :aria-pressed="active"
      @click.prevent="!disabled && $emit('click', $event)"
    >
      <div class="content">
        <slot />
      </div>
    </button>
  </div>
</template>

<script>
// small button for controls (undo in signature field, etc)
// primary is the default, so does not exist in this dict

// should align to SrColorDrop and similar elements
export default {
  props: {
    active: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false }
  },
  computed: {
    classes() {
      const { active, disabled } = this;
      const color = disabled ? 'srtext--text' : 'primary--text';

      return { active, disabled, [color]: true };
    }
  }
};
</script>

<style scoped>
.wrapper {
  height: 2.5rem;
  display: flex;
  text-decoration: none;
  align-items: center;
  font-size: 0px;
}

button {
  display: block;
  position: relative;
  border-radius: 1rem;
  border: 0.5px solid rgba(0, 0, 0, 0);
}

button:disabled {
  opacity: 0.5;
}

button.active::after {
  content: '';

  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 1rem;
  background-color: currentColor;
  opacity: 0.15;
  width: 100%;
  height: 100%;
}

button:not(:disabled):hover,
button:not(:disabled):focus {
  text-decoration: none;
  border-color: currentColor;
}

.content {
  padding-bottom: 2px; /* tiny fix */
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  flex-grow: 0;
  font-size: 12px;
  font-weight: bold;
}

.content /deep/ .v-icon {
  display: inline-flex;
  font-size: 1rem;
  color: inherit;
  height: 1rem;
}
</style>
