<template>
  <div
    class="card-detail-panel"
    :class="{ selected: selected, mobile: $isMobile }"
    @click.stop="$emit('selected')"
  >
    <div v-if="!add" class="d-flex use-old-card">
      <UseOldAddNewRadio :checked="selected"></UseOldAddNewRadio>

      <div class="card-detail">
        <div class="card-number">
          <ul v-for="x in 3" :key="x" class="dots">
            <li v-for="y in 4" :key="y">
              <div class="dot"></div>
            </li>
          </ul>

          <span v-if="paymentMethod === 'card'" class="mr-2">
            {{ cardLastFourDigits }}
          </span>
          <span v-if="paymentMethod === 'sepa'" class="mr-2">
            {{ bankAccountLastFourDigits }}
          </span>
          <div v-if="paymentMethod === 'card'">
            <img
              v-if="creditCardIcon"
              :src="creditCardIcon"
              width="21"
              height="21"
              alt="card icon"
            />
            <i v-else class="fa fa-credit-card" aria-hidden="true"></i>
          </div>
        </div>
        <div v-if="paymentMethod === 'card'">
          <translate
            :translate-params="{ month: expiresMonth, year: expiresYear }"
          >
            Expires: %{month}/%{year}
          </translate>
        </div>
        <span
          v-if="selected && subscriptionErrorMessage"
          class="text--error ml-1 pa-2 subscription-error"
        >
          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
          {{ subscriptionErrorMessage }}
        </span>
      </div>
    </div>
    <div v-else class="add-new-card">
      <div class="add-new-card-header">
        <UseOldAddNewRadio :checked="selected"></UseOldAddNewRadio>
        <div
          v-show="paymentMethod === 'card'"
          v-translate
          class="add-new-label"
        >
          Add a new credit card
        </div>
        <div
          v-show="paymentMethod === 'sepa'"
          v-translate
          class="add-new-label"
        >
          Add a new bank account
        </div>
      </div>
      <v-flex align-start>
        <transition name="fade">
          <div v-if="selected" class="mt-4">
            <credit-card
              v-if="paymentMethod === 'card'"
              :font-size="'12px'"
            ></credit-card>
            <sepa v-if="paymentMethod === 'sepa'" :font-size="'12px'"></sepa>
          </div>
        </transition>
      </v-flex>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import CreditCard from '@/components/subscribe/CreditCard';
import Sepa from '@/components/subscribe/Sepa';
import UseOldAddNewRadio from '@/components/subscribe/UseOldAddNewRadio';
import Screen from '@/mixins/Screen';

const iconMap = {
  Visa: require('@/images/visa.png'),
  'American Express': require('@/images/american_express.png'),
  MasterCard: require('@/images/master_card.png'),
  Discover: require('@/images/discover.png'),
  JCB: require('@/images/jcb.png'),
  'Diners Club': require('@/images/diners.png')
};

export default {
  components: {
    CreditCard,
    Sepa,
    UseOldAddNewRadio
  },
  mixins: [Screen],
  props: {
    add: { type: Boolean, default: false },
    selected: { type: Boolean, default: false }
  },
  computed: {
    ...mapState('subscription', ['subscriptionDetails', 'paymentMethod']),
    ...mapGetters('subscription', [
      'cardValid',
      'cardLastFourDigits',
      'cardExpires',
      'cardBrand',
      'bankAccountLastFourDigits',
      'subscriptionErrorMessage'
    ]),
    expiresMonth() {
      if (this.cardExpires) {
        const expDate = new Date(this.cardExpires);
        return expDate.getMonth();
      } else return '';
    },
    expiresYear() {
      if (this.cardExpires) {
        const expDate = new Date(this.cardExpires);
        return expDate
          .getFullYear()
          .toString()
          .substr(-2);
      } else return '';
    },
    creditCardIcon() {
      return iconMap[this.cardBrand];
    }
  }
};
</script>
<style scoped lang="scss">
.card-detail-panel {
  width: 100%;
  min-height: 91px;
  background-color: #ffffff;
  border-radius: 18px;
  border: 1px solid rgba(63, 114, 218, 0.6);
  margin-top: 15px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  padding: 20px 10px;
  position: relative;
  pointer-events: all;
  cursor: pointer;

  &.selected {
    background-color: setting(colors, background-light-blue);
  }
}

.card-detail {
  font-size: 0.86rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: setting(colors, font-dark);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 20px;
  pointer-events: all;
  cursor: pointer;
}

.card-number {
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    vertical-align: unset;
    height: auto;
    min-height: 8px;
  }
}

.use-old-card {
  pointer-events: all;
  cursor: pointer;
  margin-left: 10px;
}

.dots {
  list-style: none;
  display: inline-block;
  margin: 3px;

  li {
    display: inline-block;

    .dot {
      height: 5px;
      width: 5px;
      margin: 0 1px;
      background-color: setting(colors, font-dark);
      border-radius: 50%;
    }
  }
}
.add-new-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 0.9rem;
  pointer-events: all;
  cursor: pointer;
}
.add-new-card-header {
  display: flex;
  align-items: flex-start;
  margin-left: 10px;
}
.add-new-label {
  font-size: 1.1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.27px;
  color: setting(colors, font-dark);
  margin-left: 10px;
  pointer-events: all;
}
.add {
  flex-direction: column;
}
</style>
