<template>
  <sr-list-input
    class="sr-attachment-field"
    v-bind="$attrs"
    :value="value || []"
    :placeholder="$gettext('Enter attachment name')"
    :remove-label="$gettext('Remove attachment')"
    @change="handleChange"
  >
    <template v-slot:append-outer>
      <help-hint>
        <div v-translate>
          By adding a name you can require your signer to upload an attachment
          during signing. Only after the attachment is uploaded the signer will
          be able to finalize the document
        </div>
      </help-hint>
    </template>
  </sr-list-input>
</template>
<script>
import SrListInput from '@/components/elements/SrListInput';
import HelpHint from './HelpHint';

export default {
  components: { SrListInput, HelpHint },
  inheritAttrs: false,
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: { type: Array, required: false, default: () => [] }
  },
  methods: {
    handleChange(value) {
      this.$emit('change', value);
    }
  }
};
</script>
