<template>
  <div>
    <h2>Document settings</h2>
    <DocumentSettings
      :documents-settings="user.document_settings"
      :category="'account'"
    ></DocumentSettings>
  </div>
</template>

<script>
import DocumentSettings from '@/components/elements/DocumentSettings';
import permissions from '@/mixins/permissions';

export default {
  components: { DocumentSettings },
  mixins: [permissions]
};
</script>
