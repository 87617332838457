<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <path
      d="M6.6174833,14 C7.16759465,14 7.58500371,13.7990654 7.86971047,13.3971963 L14.2104677,4.12383178 C14.311804,3.97429907 14.3853935,3.82827103 14.4312361,3.68574766 C14.4770787,3.5432243 14.5,3.40654206 14.5,3.27570093 C14.5,2.91121495 14.3709169,2.60747664 14.1127506,2.36448598 C13.8545843,2.12149533 13.5300668,2 13.1391982,2 C12.8786192,2 12.6566444,2.05023364 12.4732739,2.15070093 C12.2899035,2.25116822 12.1161841,2.42523364 11.9521158,2.6728972 L6.59576837,10.8247664 L3.90311804,7.70560748 C3.62806236,7.38785047 3.27821084,7.22897196 2.85356347,7.22897196 C2.46269488,7.22897196 2.13938382,7.35046729 1.88363029,7.59345794 C1.62787676,7.8364486 1.5,8.14252336 1.5,8.51168224 C1.5,8.67523364 1.52895323,8.83060748 1.58685969,8.97780374 C1.64476615,9.125 1.74851522,9.28037383 1.8981069,9.44392523 L5.41592428,13.4672897 C5.7344098,13.8224299 6.13492947,14 6.6174833,14 Z"
    />
  </svg>
</template>
<script>
export default {};
</script>
