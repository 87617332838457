import angular from 'angular';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/js/utils';
import '@/scripts/phoneCountries';

// mainly copied from: https://github.com/mareczek/international-phone-number
(function() {
  'use strict';
  angular
    .module('srInternationalPhoneNumber', [])
    .constant('ipnConfig', {
      // allowExtensions: false,
      // autoFormat: true,
      autoHideDialCode: true,
      autoPlaceholder: true,
      // customPlaceholder: null,
      initialCountry: '',
      geoIpLookup: null,
      nationalMode: true,
      placeholderNumberType: 'MOBILE',
      preferredCountries: [
        'us',
        'gb',
        'nl',
        'de',
        'es',
        'fr',
        'cn',
        'ru',
        'in',
        'id',
        'br'
      ],
      utilsScript: '' // imported by default here at the top here
    })
    .directive('internationalPhoneNumber', [
      '$timeout',
      'ipnConfig',
      function($timeout, ipnConfig) {
        return {
          restrict: 'A',
          require: '^ngModel',
          scope: {
            ngModel: '=',
            country: '='
          },
          link: function(scope, element, attrs, ctrl) {
            var init = function() {
              var handleWhatsSupposedToBeAnArray,
                read,
                watchOnce,
                watchOnceInner,
                formatInputValue;

              formatInputValue = function(val) {
                if (val[0] === '0' && val[1] === '0') {
                  val = '+' + val.slice(2);
                }
                if (val[0] !== '+' && val[0] !== '0') {
                  val = '+' + val;
                }
                return val;
              };

              handleWhatsSupposedToBeAnArray = function(value) {
                if (value instanceof Array) {
                  return value;
                } else {
                  return value
                    .toString()
                    .replace(/[ ]/g, '')
                    .split(',');
                }
              };
              const options = angular.copy(ipnConfig);
              angular.forEach(options, function(value, key) {
                let option;
                if (
                  !(attrs.hasOwnProperty(key) && angular.isDefined(attrs[key]))
                ) {
                  return;
                }
                option = attrs[key];
                if (key === 'preferredCountries') {
                  options.preferredCountries = handleWhatsSupposedToBeAnArray(
                    option
                  );
                  return options.preferredCountries;
                } else if (key === 'onlyCountries') {
                  options.onlyCountries = handleWhatsSupposedToBeAnArray(
                    option
                  );
                  return options.onlyCountries;
                } else if (typeof value === 'boolean') {
                  options[key] = option === 'true';
                  return options[key];
                } else {
                  options[key] = option;
                  return options[key];
                }
              });

              const itil = intlTelInput(element[0], options);

              read = function() {
                if (itil.isValidNumber()) {
                  element.addClass('ng-valid');
                  element.removeClass('ng-invalid');
                } else {
                  element.addClass('ng-invalid');
                  element.removeClass('ng-valid');
                }
                return ctrl.$setViewValue(itil.getNumber());
              };

              watchOnce = scope.$watch('ngModel', function(newValue) {
                return scope.$$postDigest(function() {
                  if (
                    newValue !== null &&
                    newValue !== void 0 &&
                    newValue.length > 0
                  ) {
                    newValue = formatInputValue(newValue);
                    ctrl.$modelValue = newValue;
                  }

                  watchOnceInner = scope.$watch('ngModel', function(n, o) {
                    if (n)
                      $timeout(function() {
                        itil.promise.then(function() {
                          itil.setNumber(formatInputValue(n));
                          read();
                          watchOnceInner();
                        });
                      }, 0);
                  });
                  return watchOnce();
                });
              });
              scope.$watch('country', function(newValue) {
                if (
                  newValue !== null &&
                  newValue !== void 0 &&
                  newValue !== ''
                ) {
                  return itil.setCountry(newValue);
                }
              });
              ctrl.$formatters.push(function(value) {
                if (!value) {
                  return value;
                }
                itil.setNumber(value);
                return element.val();
              });
              ctrl.$parsers.push(function(value) {
                if (!value) {
                  return value;
                }
                return value.replace(/[^\d]/g, '');
              });

              if (ctrl.$validators) {
                // from angular 1.3, TODO: upgrade
                ctrl.$validators.internationalPhoneNumber = function(value) {
                  var selectedCountry;
                  selectedCountry = itil.getSelectedCountryData();
                  if (
                    !value ||
                    (selectedCountry && selectedCountry.dialCode === value)
                  ) {
                    return true;
                  }
                  return itil.isValidNumber();
                };
              }

              element.on('countrychange', function() {
                if (watchOnceInner) {
                  watchOnceInner();
                }
              });

              element.on('blur keyup change', function(event) {
                const number = event.target.value;
                if (watchOnceInner && number) {
                  if (number === '0' || number.startsWith('00')) {
                    // keep it
                  } else {
                    watchOnceInner();
                  }
                }

                return scope.$apply(read);
              });
              return element.on('$destroy', function() {
                itil.destroy();
                return element.off('blur keyup change');
              });
            };
            var initOnce = scope.$root.$watch('settingsLoaded', function(n, o) {
              if (angular.isDefined(n) && n) {
                init();
                initOnce();
              }
            });
          }
        };
      }
    ]);
}.call(this));
