import angular from 'angular';

/**
 * @author Sławomir Żytko <slawomir.zytko@gmail.com>
 * @homepage https://github.com/szytko
 */
(function() {
  'use strict';

  angular.module('frontendApp').factory('SignatureTextService', [
    '$window',
    '$timeout',
    'EventDispatcher',
    'utils',
    'fontService',
    function SignatureTextFactory(
      $window,
      $timeout,
      EventDispatcher,
      utils,
      fontService
    ) {
      /**
       * Creates signature image from text
       *
       * @param {angular.element} canvasContainer
       * @param {HTMLCanvasElement} canvasElement
       * @param {{}} options
       * @constructor SignatureTextService
       */
      function SignatureTextService(canvasContainer, canvasElement) {
        this.canvas = canvasElement;
        this.canvas.width = 512;
        this.canvas.height = 200;

        this.canvasContext = this.canvas.getContext('2d');
        this.canvasSetupDone = false;

        // merges given options with defaults
        this.options = {
          fillStyle: '#000',
          fontSize: 100,
          fontFamily: 'Dawning of a New Day'
        };

        this.text = '';
        this.checkFontFamily();

        this._drawToken = null;
      }

      SignatureTextService.prototype.constructor = SignatureTextService;

      SignatureTextService.prototype.setFontFamily = function(fontFamily) {
        if (this.options.fontFamily !== fontFamily) {
          this.options.fontFamily = fontFamily;
          this.checkFontFamily();
        }
      };

      SignatureTextService.prototype.setFillStyle = function(fillStyle) {
        this.options.fillStyle = fillStyle;
        this.draw();
      };

      SignatureTextService.prototype.checkFontFamily = function() {
        var service = this;
        const { fontFamily } = service.options;

        if (fontService.fontHasLoaded(fontFamily)) {
          service.canvasSetupDone = true;
          service.draw();
        } else {
          service.canvasSetupDone = false;
          service.draw();

          // try loading it later!
          fontService.waitForFont(fontFamily).then(
            font => {
              service.checkFontFamily();
            },
            error => {
              console.error('Font could not be loaded:', error);
            }
          );
        }
      };

      /**
       * Clears canvas content
       */
      SignatureTextService.prototype.clearCanvas = function() {
        const { canvasContext, canvas } = this;
        canvasContext.clearRect(0, 0, canvas.width, canvas.height);
      };

      SignatureTextService.prototype._draw = function() {
        const { canvasContext, canvas } = this;
        const text = this.text || '';
        const textMargin = this.canvas.width * 0.1;
        const fontFamily = this.options.fontFamily;

        this.clearCanvas();

        canvasContext.textAlign = 'center';
        canvasContext.fillStyle = this.options.fillStyle;
        canvasContext.textBaseline = 'middle';

        // adjust the font size
        let fontSize = this.options.fontSize;
        while (fontSize > 0) {
          canvasContext.font = `${fontSize}px ${fontFamily}`;
          const textWidth = canvasContext.measureText(text).width;
          const targetWidth = canvas.width - textMargin;

          const fraction = textWidth / targetWidth;
          if (fraction <= 1) {
            break;
          }

          // divide if we can
          fontSize = Math.min(fontSize - 1, Math.floor(fontSize / fraction));
        }

        canvasContext.fillText(text, canvas.width / 2, canvas.height / 2);
      };

      SignatureTextService.prototype.draw = function() {
        if (this._drawToken) {
          return;
        }

        const service = this;
        service._drawToken = $window.setTimeout(() => {
          service._drawToken = null;
          service._draw();
        }, 10);
      };

      SignatureTextService.prototype.onTextChange = function(text) {
        if (angular.isUndefined(text)) {
          return false;
        }
        if (text !== this.text) {
          this.text = text;
          this.draw();
        }
      };

      SignatureTextService.prototype.getCanvas = function() {
        return this.canvas;
      };

      return SignatureTextService;
    }
  ]);
})();
