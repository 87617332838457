<template>
  <sr-field
    :label="label"
    :label-for="uid"
    :help="help"
    :horizontal="horizontal"
    class="sr-checkbox"
    @click="$emit('click', $event)"
  >
    <template v-slot:label>
      <input
        :id="uid"
        ref="input"
        :name="name"
        :checked="value === true ? true : false"
        :aria-checked="value === true ? 'true' : 'false'"
        :disabled="disabled"
        type="checkbox"
        v-bind="$attrs"
        :readonly="readonly"
        @input="handleInput"
        @focus="onFocus($event)"
        @blur="onBlur($event)"
      />
      <span class="sr-checkbox__label_text">{{ label }}</span>
    </template>
    <template v-slot:append-outer><slot name="append-outer" /></template>
    <template v-slot:append-inner><slot name="append-inner" /></template>
  </sr-field>
</template>
<script>
import SrField from './SrField';
import uniqueId from '@/mixins/uniqueId';
export default {
  components: { SrField },
  mixins: [uniqueId],
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    label: { type: String, default: '' },
    help: { type: String, default: '' },
    value: { type: Boolean, default: false },
    hasRippleEffect: { type: Boolean, default: true },
    horizontal: { type: Boolean, default: false },
    name: { type: String, required: true },
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false }
  },
  methods: {
    onFocus($event) {
      this.$emit('focus', $event);
    },
    onBlur($event) {
      this.$emit('blur', $event);
    },
    handleInput(event) {
      this.$emit('input', event.target.checked);
    }
  }
};
</script>
<style scoped>
.sr-checkbox /deep/ label {
  z-index: 0;
  display: inline-flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  line-height: 1.5;
}

/* Input itself is invisible */
input {
  color: inherit;
  appearance: none;
  outline: none;
  pointer-events: none;
  width: 1.125em;
  height: 1.125em;
  border-radius: 2px;
}

/* draw box for checkmark */
input::before {
  content: '';
  display: block;
  box-sizing: border-box;
  border: solid 2px; /* Safari */
  border-color: rgba(0, 0, 0, 0.6);
  border-radius: 2px;
  width: 1.125em;
  height: 1.125em;
  transition: border-color 0.2s, background-color 0.2s;
}
/* draw enother box rotated 45deg so it would make checkmark itself */
input::after {
  content: '';
  display: block;
  position: absolute;
  top: 0.2em;
  left: -0.03em;
  right: 0;
  width: 0.8em;
  height: 0.45em;
  border: solid 3px transparent;
  border-right: none;
  border-top: none;
  transform: translate(3px, 4px) rotate(-45deg);
}
/* checked input would be filled with current color,
   while checkmark would be white */
input:checked {
  background-color: currentColor;
}
input:checked::before {
  border-color: currentColor;
  background-color: currentColor;
}
input:checked::after {
  border-color: #ffffff;
}

input:active::before {
  border-color: currentColor;
}
input:checked:active::before {
  border-color: transparent;
  background-color: rgba(0, 0, 0, 0.6);
}
input:disabled {
  opacity: 0;
}
input:disabled + span {
  color: rgba(0, 0, 0, 0.38);
  cursor: initial;
}
input:disabled + span::before {
  border-color: currentColor;
}
input:checked:disabled::before {
  border-color: transparent;
  background-color: currentColor;
}

.rtl .sr-checkbox__label_text {
  margin-right: 8px;
}
.ltr .sr-checkbox__label_text {
  margin-left: 8px;
}
.sr-checkbox__label_text {
  display: inline-block;
  width: 100%;
  cursor: pointer;
  line-height: 1.7em;
  margin-top: -0.1em;
}
</style>
