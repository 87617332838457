<template>
  <div class="sr-container">
    <div class="sr-layout">
      <div
        class="sr-layout__item palm-display--none lap-one-third desk-one-quarter"
      >
        <div class="module">
          <navigation-list class="menu--short" sections />
        </div>
      </div>
      <div
        class="sr-layout__item palm-one-whole lap-two-thirds desk-three-quarters"
      >
        <div class="module">
          <div class="module__header primary">
            <span>{{ title }}</span>
          </div>
          <div class="module__body" style="min-height: 350px">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import NavigationList from '@/components/NavigationList.vue';
export default Vue.extend({
  components: {
    NavigationList
  },
  props: {
    title: { type: String, required: true }
  }
});
</script>
<style scoped>
.menu.menu--short {
  box-shadow: none !important;
  border: none;
}
.menu.menu--short .v-list__tile__title {
  font-weight: bold;
}
</style>
