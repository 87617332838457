import angular from 'angular';
import _ from 'lodash';
/**
 * @author Sławomir Żytko <slawomir.zytko@gmail.com>
 * @homepage https://github.com/szytko
 */
(function() {
  'use strict';

  angular
    .module('frontendApp')
    .factory('EventDispatcher', EventDispatcherFactory);

  EventDispatcherFactory.$inject = [];

  function EventDispatcherFactory() {
    var EventDispatcher = function() {
      var dispatcher = this;

      this.eventSubscriptions = [];

      /**
       * Subscribes given callback for event
       * @param eventName
       * @param eventCallback
       */
      this.subscribe = function(eventName, eventCallback) {
        var subscribers = dispatcher.eventSubscriptions[eventName];
        if (angular.isUndefined(subscribers)) {
          subscribers = dispatcher.eventSubscriptions[eventName] = [];
        }

        subscribers.push(eventCallback);
      };

      /**
       * Unsubscribes event
       * @param eventName
       */
      this.unsubscribe = function(eventName) {
        dispatcher.eventSubscriptions = _.omit(
          dispatcher.eventSubscriptions,
          eventName
        );
      };

      /**
       * Triggers indicated event with given data
       * @param eventName
       * @param data
       */
      this.trigger = function(eventName, data) {
        var subscribers = dispatcher.eventSubscriptions[eventName];
        if (angular.isUndefined(subscribers)) {
          return;
        }

        _.each(subscribers, function(callback) {
          if (angular.isFunction(callback)) {
            callback.call(null, data);
          }
        });
      };
    };

    return EventDispatcher;
  }
})();
