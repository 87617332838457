<template>
  <div class="login-container">
    <v-card flat>
      <social-confirm
        v-if="selectedProvider"
        :selected-provider="selectedProvider"
        :next="next"
        @cancel="handleSocialCancel"
      />
      <div v-else>
        <v-card-text class="pt-0">
          <h2
            v-if="isProFeature"
            key="pro"
            v-translate
            data-test-id="pro"
            class="primary--text"
          >
            This is a PRO feature
          </h2>
          <div v-if="doRegister" key="register" class="register">
            <img
              :src="require('./images/sign_handshake.svg')"
              class="sign-handshake"
              :alt="$gettext('Signer handshake')"
            />
            <span v-if="haveEmail" key="e">
              <h1 v-translate class="font-weight-black">
                Create your account
              </h1>
              <h4 v-translate class="body-1 mb-4">
                This resource is not available without a registered account.
              </h4>
            </span>
            <h1
              v-else
              key="n"
              v-translate
              class="font-weight-black"
              :class="$vuetify.breakpoint.xsOnly ? 'px-2' : 'px-4'"
            >
              Create your SignRequest account
            </h1>
          </div>
          <div v-else>
            <h1 v-translate class="font-weight-black">
              Log in to continue
            </h1>
            <p v-if="haveEmail" key="e" v-translate>
              Your email address is already linked to a SignRequest account.
            </p>
            <p v-else key="n" v-translate>
              Already have a SignRequest account? Sign in and start sending
              right away.
            </p>
          </div>
        </v-card-text>

        <div v-if="isAllowedSocial" class="mb-4">
          <v-card-text class="pt-0">
            <ul class="list social-list">
              <li
                v-for="(socialProvider, index) in showProviders"
                :key="socialProvider.provider"
              >
                <social-button
                  ref="social"
                  :provider="socialProvider"
                  :next="next"
                  :expanded="
                    index === 0 && !showMore && $vuetify.breakpoint.smAndUp
                  "
                  :register="doRegister"
                  @click="handleSocial($event, socialProvider)"
                />
              </li>
              <li v-if="hasMoreSocial" class="shore-more-or-less">
                <button
                  v-if="showMore"
                  key="less"
                  data-test-id="less"
                  @click="showMore = false"
                >
                  <img
                    :src="require('./images/more_vert-24px.svg')"
                    :alt="$gettext('Show less')"
                  />
                </button>
                <button
                  v-else
                  key="more"
                  data-test-id="more"
                  @click="showMore = true"
                >
                  <img
                    :src="require('./images/more_horiz-24px.svg')"
                    :alt="$gettext('Show more')"
                  />
                </button>
              </li>
            </ul>
          </v-card-text>
        </div>

        <login-form
          :registration="doRegister"
          :social="!isAllowed('password')"
          :initial-value="initialFormValue"
          :switch-host="switchHost"
          :next="next"
          @success="handleSuccess"
          @submitSocial="handleSocialSubmit"
          @hideSocialAndSubheading="hideSocialAndSubheading = $event"
          @close="onFinish"
        />
        <p
          v-if="!hideSocialAndSubheading"
          class="font-weight-regular subheading text-xs-center"
        >
          <template v-if="doRegister">
            <span key="login-prompt">
              <translate>Have an account?</translate>
              <space />
              <router-link v-if="useRouterLinks" to="/login">
                <translate>Login</translate>
              </router-link>
              <a
                v-else
                v-translate
                href="/"
                @click.prevent="doRegister = false"
              >
                Login
              </a>
            </span>
          </template>
          <template v-else>
            <span key="register-prompt">
              <translate>Don't have an account?</translate>
              <space />
              <router-link v-if="useRouterLinks" to="/register">
                <translate>Register for free</translate>
              </router-link>
              <a v-else v-translate href="/" @click.prevent="doRegister = true">
                Register for free
              </a>
            </span>
          </template>
        </p>
      </div>
    </v-card>
  </div>
</template>
<script>
import LoginForm from './LoginForm';
import SocialButton from './SocialButton';
import { mapActions } from 'vuex';
import SocialConfirm from './SocialConfirm';
import socialProviders from '@/utils/socialProviders';
import Space from '@/components/Space';

export default {
  components: { Space, SocialConfirm, LoginForm, SocialButton },
  props: {
    onFinish: { type: Function, default: _ => {} },
    modalOptions: { type: Object, required: true },
    useRouterLinks: { type: Boolean, default: false }
  },
  data() {
    return this.initialState({});
  },
  computed: {
    isAllowed() {
      return function(provider) {
        if (this.selectedProvider) {
          return provider === this.selectedProvider;
        }
        const { backends } = this.modalOptions || {};
        if (!backends) {
          return true;
        }
        return Boolean(backends[provider]);
      };
    },
    isAllowedSocial() {
      return this.allowedProviders.length > 0 && !this.hideSocialAndSubheading;
    },
    hasMoreSocial() {
      return this.allowedProviders.length > this.socialInitialShowCount;
    },
    haveEmail() {
      return Boolean(this.initialFormValue.email);
    },
    allowedProviders() {
      return socialProviders.filter(socialProvider =>
        this.isAllowed(socialProvider.provider)
      );
    },
    showProviders() {
      return this.showMore
        ? this.allowedProviders
        : this.allowedProviders.slice(0, this.socialInitialShowCount);
    },
    next() {
      const { next = '/#/' } = this.modalOptions || {};
      const separator = next.includes('?') ? '&' : '?';
      const successParam = this.doRegister
        ? 'register_success=1'
        : 'login_success=1';
      return [next, successParam].join(separator);
    },
    isProFeature() {
      const { doPermLogin } = this.modalOptions || {};
      return Boolean(doPermLogin);
    },
    switchHost() {
      const { switchHost } = this.modalOptions;
      return Boolean(switchHost);
    },
    initialFormValue() {
      return {
        email: this.modalOptions && this.modalOptions.email
      };
    }
  },
  watch: {
    modalOptions: {
      immediate: true,
      handler(value) {
        Object.assign(this, this.initialState({ modalOptions: value }));
      }
    }
  },
  methods: {
    ...mapActions('events', [
      'trackSocialLoginModalEvent',
      'trackSocialLoginSuccessModalEvent'
    ]),
    initialState({ modalOptions }) {
      return {
        selectedProvider: null,
        doRegister: Boolean(modalOptions && modalOptions.doRegister),
        isLoginSuccessFul: false,
        showMore: false,
        socialInitialShowCount: 3,
        hideSocialAndSubheading: false
      };
    },
    handleSocial(event, provider) {
      // in login mode it would work like a link,
      // so we don't even need to do anything.
      if (!this.doRegister) {
        return;
      }
      event.preventDefault();
      this.selectedProvider = provider;
    },
    handleSocialSubmit() {
      const [selected] = this.$refs.social;
      this.trackSocialLoginModalEvent(`social used: ${selected}}`);
      selected && selected.click();
    },
    handleSuccess() {
      this.isLoginSuccessFul = true;
      this.trackSocialLoginSuccessModalEvent('closed');
      this.onFinish(this.isLoginSuccessFul);
    },
    handleSocialCancel() {
      this.selectedProvider = null;
    }
  }
};
</script>
<style scoped lang="scss">
.v-card__text {
  padding-left: 0;
  padding-right: 0;
}
h1 {
  display: block;
  font-size: 34px;
  line-height: normal;
  color: #000000;
  @media only screen and (max-width: 599px) {
    font-size: 24px;
  }
}
.v-card__title {
  justify-content: center;
}
li {
  margin-top: 0.85714286rem;
  padding: 0 5px;
}
ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.shore-more-or-less {
  text-align: center;
  font-size: 16px;
  opacity: 0.6;
  color: #111111;

  button {
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 41px;
    height: 41px;
    border-radius: 50%;
    background-color: #e9eaf0;
    &:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
}

.sign-handshake {
  width: 104px;
  height: 117px;
  object-fit: contain;
}

.register {
  text-align: center;
}
.login-container {
  padding: 40px 25px;
}
</style>
