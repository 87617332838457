export default (orientation = 'vertical') => ({
  props: {
    listRole: { type: String, required: false, default: 'menu' }
  },
  data() {
    return {
      keyMenuIndex: Infinity
    };
  },
  computed: {
    keyMenuIsOpen() {
      return this.keyMenuIndex !== Infinity;
    }
  },
  watch: {
    keyMenuIndex(value) {
      this.$emit('change:menuindex', value);
    },
    keyMenuIsOpen(value) {
      this.$emit('change:menuopen', value);
    }
  },
  methods: {
    getMenu() {
      const menuSelector = {
        menu: ':scope > [role="menu"]',
        listbox: ':scope > [role="listbox"]'
      }[this.listRole];
      return this.$el.querySelector(menuSelector) || this.$el;
    },
    getOptions() {
      const optionSelector = {
        menu: '[role="menuitem"]',
        listbox: '[role="option"]'
      }[this.listRole];
      return this.getMenu().querySelectorAll(optionSelector);
    },
    keyMentSelectedOption() {
      return this.getOptions()[this.keyMenuIndex];
    },
    keyMenuToggle() {
      if (this.keyMenuIsOpen) {
        this.keyMenuClose();
      } else {
        this.keyMenuOpened();
      }
    },
    keyMenuClose() {
      this.keyMenuIndex = Infinity;
    },
    keyMenuOpen() {
      this.getMenu().focus();
    },
    keyMenuOpened() {
      this.keyMenuIndex = -1;
    },
    keyMenuKeyboard(event) {
      const ieKey = key => key.slice(5);

      const [prevKey, nextKey] = {
        vertical: ['ArrowUp', 'ArrowDown'],
        horizontal: ['ArrowLeft', 'ArrowRight']
      }[orientation];
      let indexOffset = 0;
      // ie has ArrowDown -> Down, so slice 5
      if (
        [prevKey, nextKey, ieKey(prevKey), ieKey(nextKey)].includes(event.key)
      ) {
        event.preventDefault();
      }

      const options = this.getOptions();
      if ([prevKey, ieKey(prevKey)].includes(event.key)) {
        indexOffset = -1;
      } else if ([nextKey, ieKey(nextKey)].includes(event.key)) {
        indexOffset = 1;
      }
      const shouldMove = indexOffset !== 0;
      // if we are going up from initial state, we have to choose first from
      // the end
      if (this.keyMenuIndex === -1 && indexOffset == -1) {
        indexOffset = 0;
      }

      const nextIndex =
        (options.length + this.keyMenuIndex + indexOffset) % options.length;

      const element = options[nextIndex];
      if (!element) {
        return;
      }
      const isButton =
        (element.tagName === 'A' && element.href) ||
        element.tagName === 'BUTTON';
      /* Hitting enter on buttons results in firing click event anyway,
       * and we don't want double-clicking to happen.
       * Naturally we should not even have clickable elements that are not buttons,
       * but here we are */
      if (event.key === 'Enter' && !isButton && element) {
        return element.click();
      }

      if (!shouldMove) {
        return;
      }

      element.focus();
      // jsdom does not have scroll into view
      if (element.scrollIntoView) {
        element.scrollIntoView(false);
      }

      this.keyMenuIndex = nextIndex;
    }
  }
});
