import { mapGetters, mapActions } from 'vuex';

// Similar to mapGetters, but allows to pass an action to call once the
// object is mutated. This allow property to be used as v-model.
export function mapModel(ns: string, getter: string, setter: string) {
  const getters = mapGetters(ns, [getter]);
  const setters = mapActions(ns, [setter]);
  const name = getter;

  const computedProp = {
    get() {
      return getters[getter].call(this);
    },
    set(value) {
      setters[setter].call(this, value);
    }
  };

  return { [name]: computedProp };
}

// Sometimes we want to edit (pass it as a v-model) a single property of an immutable object.
// This will create a property you can edit, but editting will rewrite the immutable object.
// Works very well with mapModel properties, however can be used with other properties as well.
type Field = { name: string; filter: (v: unknown) => unknown };
export function mapModelField(modelName: string, field: string | Field) {
  const { name, filter } = (field as Field).name
    ? (field as Field)
    : { name: field as string, filter: v => v };

  const computedProp = {
    get() {
      if (this.hasOwnProperty(modelName)) {
        throw new Error(`${modelName} does not exist on this.`);
      }
      return this[modelName][name];
    },
    set(value: unknown) {
      const oldObject = this[modelName];
      this[modelName] = Object.freeze({
        ...oldObject,
        [name]: filter(value)
      });
    }
  };

  return { [name]: computedProp };
}

export function mapModelFields(modelName: string, fields: (string | Field)[]) {
  return Object.assign({}, ...fields.map(f => mapModelField(modelName, f)));
}
