<template>
  <div>
    <label v-translate>Role</label>
    <div class="level-boxes primary--text">
      <div
        v-for="level of items"
        :key="level.value"
        class="level-boxes__entry"
        :aria-checked="value === level.value"
      >
        <sr-button
          sr-style="text"
          inline
          :title="level.text"
          @click="$emit('change', level.value)"
        >
          <level-icon :value="level.value" />
          {{ getLabel(level.value) }}
        </sr-button>
      </div>
    </div>
  </div>
</template>
<script>
import SrButton from '@/components/elements/SrButton';
import Checkmark from '@/components/icons/Checkmark.vue';
import EmailNotification from '@/components/icons/EmailNotification.vue';
import FileCopy from '@/components/icons/FileCopy.vue';
import Sign from '@/components/icons/Sign.vue';
import TwoPeople from '@/components/icons/TwoPeople.vue';

const LEVELS = {
  approve: Checkmark,
  notify: EmailNotification,
  cc: FileCopy,
  signature: Sign,
  in_person: TwoPeople
};

function getLabel(value) {
  return () =>
    ({
      approve: this.$gettext('Approver'),
      notify: this.$gettext('Notified'),
      cc: this.$gettext('Get a Copy'),
      signature: this.$gettext('Signer'),
      in_person: this.$gettext('In-Person')
    }[value]);
}

export const LevelIcon = {
  props: {
    value: { type: String, required: true }
  },
  computed: { getLabel },
  render(h) {
    const { value } = this.$props;
    return h(LEVELS[value], { attrs: { alt: this.getLabel(value) } });
  }
};

export default {
  components: { LevelIcon, SrButton },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    items: { type: Array, required: true },
    label: { type: String, required: true },
    value: { type: String, required: true }
  },
  computed: {
    getLabel
  }
};
</script>
<style scoped>
label {
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: 0.3px;
}

.level-boxes {
  display: flex;
  margin: 0 -6px;
  flex-wrap: wrap;
}
.level-boxes__entry {
  height: 72px;
  min-height: 72px;
  width: 97px;
  min-width: 97px;
  border: 1px solid #e8e8e8;
  color: #909090;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
  margin: 6px;
  border-radius: 6px;

  display: flex;
  align-content: center;
  justify-content: center;
}
.level-boxes__entry[aria-checked] {
  color: inherit;
  border: solid 2px #b2cff2;
  background-color: #f2f7fd;
}
.level-boxes__entry /deep/ svg {
  display: block;
  width: 40px !important;
  height: 36px !important;
  fill: currentColor;
}
.level-boxes__entry .sr-button {
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.82;
  letter-spacing: 0.25px;
  margin-top: 5px;
}
.level-boxes__entry /deep/ .sr-button--content {
  flex-direction: column;
}
</style>
