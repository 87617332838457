<template>
  <v-dialog
    v-model="showModal"
    max-width="32.5rem"
    :content-class="contentClass"
  >
    <v-card>
      <v-form class="ph-skip-checkbox" @submit="handleSave">
        <v-card-text>
          <h1 v-translate class="srtext--text">
            Select the checkbox
          </h1>
        </v-card-text>
        <v-card-text class="v-card__text__input primary--text">
          <sr-button sr-style="text" type="submit">
            <checkbox :checked="true" />
          </sr-button>
          <sr-button sr-style="text" @click="handleClose">
            <checkbox :checked="false" />
          </sr-button>
        </v-card-text>
        <v-card-text class="v-card__text__buttons">
          <sr-button
            sr-style="cancel"
            round
            data-test-id="skip"
            @click="handleClose"
          >
            <translate>Skip</translate>
          </sr-button>

          <sr-button sr-style="fancy" round type="submit">
            <translate>Save</translate>
          </sr-button>
        </v-card-text>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import ModalMixin from '@/components/modals/ModalMixin';
import SrButton from '@/components/elements/SrButton';
import Checkbox from '@/components/document/Checkbox';

export default {
  components: {
    Checkbox,
    SrButton
  },
  mixins: [ModalMixin('skipCheckbox')],
  props: {
    contentClass: { type: String, default: '' }
  },
  methods: {
    async handleSave(event) {
      event.preventDefault();
      this.returnValue = true;
      this.closeModal();
    },
    handleClose() {
      this.returnValue = null;
      this.closeModal();
    }
  }
};
</script>
<style scoped>
/deep/ .v-dialog {
  border-radius: 2rem;
}
/deep/ .v-dialog.signrequest-lib {
  border-radius: 12px;
}

h1 {
  font-size: 24px;
  opacity: 0.9;
  font-weight: 900;
}
.v-card__text {
  position: relative;
  padding: 25px 30px;
}
.v-card__text__input {
  display: flex;
  justify-content: space-around;
  transform: scale(0.5);
  max-height: 120px;
  transform-origin: top center;
}
.v-card__text__input /deep/ button.sr-button {
  position: relative;
  max-width: 50%;
  min-height: 200px;
  color: inherit !important;
}
.v-card__text__input /deep/ .sr-button--content {
  max-width: 100%;
  height: 100%;
}

.v-card__text__buttons {
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  justify-content: space-between;
}
.v-card__text__buttons button.sr-button {
  font-size: 18px;
  padding: 0em 3.5em;
  margin: 0px;
  height: 3em;
  width: calc(50% - 10px);
}
</style>
