<template>
  <help-hint visible bottom color="color">
    <span v-translate>
      Sandbox teams allow you to test out the entire SignRequest product without
      incurring charges. Everything works the same as a paid plan, except that
      all documents will be watermarked.
    </span>
  </help-hint>
</template>
<script>
import HelpHint from '@/components/signrequest/HelpHint';
export default {
  components: { HelpHint },
  props: {
    color: { type: String, required: true }
  }
};
</script>
