import angular from 'angular';

/**
 * @ngdoc function
 * @name frontendApp.controller:InvoiceCtrl
 * @description
 * # InvoiceCtrl
 * Controller of the frontendApp
 */
angular.module('frontendApp').controller('InvoiceCtrl', [
  '$scope',
  '$window',
  'UserService',
  'InvoiceService',
  function($scope, $window, UserService, InvoiceService) {
    $scope.invoices = [];

    var downloadOrViewInvoice = function($event, invoice, view) {
      $event.preventDefault();
      $event.stopPropagation();
      view = view || false;
      var url = invoice.download_url;
      if (view) {
        url += '?inline=1';
      }
      $window.location.href = url;
      return false;
    };

    $scope.downloadInvoice = function($event, invoice) {
      return downloadOrViewInvoice($event, invoice, false);
    };

    $scope.viewInvoice = function($event, invoice) {
      return downloadOrViewInvoice($event, invoice, true);
    };

    $scope.downloadInvoiceAttachment = function($event, invoice) {
      $event.preventDefault();
      $event.stopPropagation();
      if (invoice.has_attachment) {
        $window.location.href = invoice.download_attachment_url;
      }
      return false;
    };

    UserService.waitForUser().then(function(user) {
      // cant have invoices if you are logged out
      if (!user.logged_in) {
        return;
      }
      InvoiceService.getInvoices().then(function(resp) {
        if (resp.data.invoices && resp.data.invoices.length) {
          $scope.invoices = resp.data.invoices;
        }
      });
    });
  }
]);
