<template>
  <v-progress-circular
    :indeterminate="true"
    :size="14"
    :width="1.5"
  ></v-progress-circular>
</template>

<script>
export default {};
</script>
