<template>
  <v-dialog
    v-if="modalOptions"
    ref="modal"
    v-model="showModal"
    lazy
    max-width="436"
    :content-class="contentClass"
  >
    <v-card
      role="dialog"
      :aria-hidden="!showModal"
      :aria-modal="showModal"
      aria-labelledby="decline-modal-label"
      :class="{
        mobile: $isMobile
      }"
    >
      <v-icon class="round close" role="button" @click="close(false)">
        close
      </v-icon>
      <h2 id="decline-modal-title" class="v-card__title headline">
        <translate>Please confirm</translate>
      </h2>
      <v-card-text>
        <span id="decline-modal-label" class="srtext--text">
          <translate>
            Please confirm that you want to decline this document.
          </translate>
        </span>
      </v-card-text>
      <sr-input
        ref="message"
        v-model="message"
        name="message"
        :label="$gettext('Optional: give a reason for declining')"
      />
      <v-card-actions>
        <sr-button sr-style="cancel" round @click="close(false)">
          <translate>Cancel</translate>
        </sr-button>
        <sr-button sr-style="danger" round @click="close(true)">
          <translate>Decline</translate>
        </sr-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import SrButton from '@/components/elements/SrButton';
import SrInput from '@/components/elements/SrInput';
import ModalMixin from '@/components/modals/ModalMixin';
import Screen from '@/mixins/Screen';

export default {
  components: { SrButton, SrInput },
  mixins: [ModalMixin('decline'), Screen],
  props: {
    contentClass: { type: String, default: '' }
  },
  data() {
    return { message: '' };
  },
  computed: {
    resultCancel() {
      return Object.freeze({ message: '', declined: false });
    },
    resultConfirm() {
      return Object.freeze({ message: this.message || '', declined: true });
    },
    returnValue: {
      get() {
        return this.resultCancel;
      },
      set() {
        // this setter is eating all input
      }
    }
  },
  watch: {
    showModal(value, oldValue) {
      if (value && !oldValue) {
        this.message = '';
        this.$nextTick(() => this.focusFirst());
      }
    }
  },
  methods: {
    focusFirst() {
      this.$refs.message.focus();
    },
    close(decline) {
      const ret = decline ? this.resultConfirm : this.resultCancel;
      this.modalResolve(ret);
    }
  }
};
</script>
<style scoped>
button.sr-button {
  font-size: 18px;
  padding: 0 3rem;
  margin: 0px;
  height: 4rem;
  width: calc(50% - 0.75rem);
}

/deep/ .v-dialog {
  border-radius: 25px;
}
.v-card {
  padding: 0 3rem;
  contain: content;
}
.mobile.v-card {
  padding: 0 2rem;
}
.v-card__title {
  padding: 0;
  padding-top: 4rem;
  padding-bottom: 2rem;
  font-weight: 900;
  margin-top: 0;
}
.v-card__text {
  font-size: 17px;
  padding: 0;
  padding-bottom: 1.5rem;
  font-weight: 300;
  line-height: 1.47;
  letter-spacing: 0.28px;
}
.sr-field {
  margin: 0 -3rem;
  padding: 0 3rem;
  outline-offset: -0.5rem !important;
}
.mobile .sr-field {
  margin: 0 -2rem;
  padding: 0 2rem;
}

.sr-field::before,
.sr-field::after {
  position: absolute;
  content: '';
  border-top: 1px solid #d6d6d6;
  opacity: 0.84;
  left: 0;
  right: 0;
  transform: scaleY(0.5);
}
.sr-field::after {
  top: 0;
}

.sr-field::before {
  bottom: 0;
}

.v-card__actions {
  padding: 3rem 0;
  justify-content: space-between;
}
.close {
  position: absolute;
  width: 1em;
  height: 1em;
  top: 2rem;
  color: #111111;
  opacity: 0.8;
}
.ltr .close {
  right: 2rem;
}
.rtl .close {
  left: 2rem;
}
</style>
