export const replace = <T>(value: T) => (_v: T) => value;

export const merge = <T>(...args: T[]) =>
  Object.freeze(Object.assign({}, ...args) as T);
export const patch = <T>(...args: Partial<T>[]) => input =>
  merge(input, ...args);

export const append = <T>(list: T[], element: T) =>
  Object.freeze([...list, element]);
export const prepend = <T>(element: T, list: T[]) =>
  Object.freeze([element, ...list]);
export const concat = <T>(listA: T[], listB: T[]) =>
  Object.freeze([...listA, ...listB]);

export function updateAtIdx<T>(
  list: Readonly<T[]>,
  idx: number,
  update: (v: T) => T
) {
  return Object.freeze(
    list.map((element, elementIdx) =>
      idx === elementIdx ? update(element) : element
    )
  );
}

export function removeAtIdx<T>(list: Readonly<T[]>, idx: number) {
  return Object.freeze(
    list.filter((_element, elementIdx) => idx !== elementIdx)
  );
}

// list two lists together
// and pad missing spots in shorter
// list
export function zipPad(listA, listB) {
  if (listA.length < listB.length) {
    return zipPad(listB, listA).map(([iterB, iterA]) => [iterA, iterB]);
  }
  return listA.map((iterA, idx) => [iterA, listB[idx]]);
}
