import angular from 'angular';

// some templates we need to require and cache as they are ng-included
angular.module('templates', []).run([
  '$templateCache',
  function($templateCache) {
    $templateCache.put(
      'partials/account/account-settings.html',
      require('../partials/account/account-settings.html')
    );
    $templateCache.put(
      'partials/account/balance.html',
      require('../partials/account/balance.html')
    );
    $templateCache.put(
      'partials/account/billing-details.html',
      require('../partials/account/billing-details.html')
    );
    $templateCache.put(
      'partials/account/bundle-details.html',
      require('../partials/account/bundle-details.html')
    );
    $templateCache.put(
      'partials/account/document-status.html',
      require('../partials/account/document-status.html')
    );
    $templateCache.put(
      'partials/account/style-guide.html',
      require('../partials/account/style-guide.html')
    );
    $templateCache.put(
      'partials/account/referrals.html',
      require('../partials/account/referrals.html')
    );
    $templateCache.put(
      'partials/account/signatures.html',
      require('../partials/account/signatures.html')
    );
    $templateCache.put(
      'partials/account/subscription-overview.html',
      require('../partials/account/subscription-overview.html')
    );
    $templateCache.put(
      'partials/account/teams.html',
      require('../partials/account/teams.html')
    );
    $templateCache.put(
      'partials/account/sessions.html',
      require('../partials/account/sessions.html')
    );
    $templateCache.put(
      'partials/account/oauths.html',
      require('../partials/account/oauths.html')
    );
    $templateCache.put(
      'partials/account/teams-sandbox-explainer.html',
      require('../partials/account/teams-sandbox-explainer.html')
    );
    $templateCache.put(
      'partials/account/templates.html',
      require('../partials/account/templates.html')
    );
    $templateCache.put(
      'partials/account/users.html',
      require('../partials/account/users.html')
    );

    $templateCache.put(
      'partials/home_ctas/boost_productivity_with_business.html',
      require('../partials/home_ctas/boost_productivity_with_business.html')
    );
    $templateCache.put(
      'partials/home_ctas/get_started.html',
      require('../partials/home_ctas/get_started.html')
    );
    $templateCache.put(
      'partials/home_ctas/scale_to_meet_your_needs.html',
      require('../partials/home_ctas/scale_to_meet_your_needs.html')
    );
    $templateCache.put(
      'partials/home_ctas/upgrade_to_pro.html',
      require('../partials/home_ctas/upgrade_to_pro.html')
    );

    $templateCache.put(
      'partials/modals/account-switch.html',
      require('../partials/modals/account-switch.html')
    );

    $templateCache.put('partials/logo.html', require('../partials/logo.html'));
    $templateCache.put('partials/menu.html', require('../partials/menu.html'));
    $templateCache.put(
      'partials/features.html',
      require('../partials/features.html')
    );
    $templateCache.put(
      'partials/subscribe.html',
      require('../partials/subscribe.html')
    );
    $templateCache.put(
      'partials/upload_file.html',
      require('../partials/upload_file.html')
    );

    $templateCache.put(
      'views/signform.html',
      require('../views/signform.html')
    );
    $templateCache.put(
      'views/home-hero.html',
      require('../views/home-hero.html')
    );
    $templateCache.put('views/main.html', require('../views/main.html'));
    $templateCache.put('views/logosvg.html', require('../views/logosvg.html'));
    $templateCache.put(
      'views/attachments-editor.html',
      require('../views/attachments-editor.html')
    );
    $templateCache.put(
      'views/bulkSendResults.html',
      require('../views/bulkSendResults.html')
    );
  }
]);
