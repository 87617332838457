<template>
  <div class="sr-input--element">
    <span class="srtext--text">Document settings</span>
    <DocumentSettings
      :documents-settings="documentsSettings"
      :category="'team'"
      :readonly="readonly"
    ></DocumentSettings>
  </div>
</template>

<script>
import DocumentSettings from '@/components/elements/DocumentSettings';
import permissions from '@/mixins/permissions';
import { mapGetters } from 'vuex';

export default {
  components: { DocumentSettings },
  props: {
    readonly: { type: Boolean, default: false }
  },
  computed: {
    ...mapGetters('users', ['teamDocumentsSettings']),
    documentsSettings() {
      return this.teamDocumentsSettings
        ? this.teamDocumentsSettings
        : {
            documents_as_attachments: false
          };
    }
  }
};
</script>
<style scoped>
.sr-input--element {
  font-size: 16px;
  font-weight: normal;
  -webkit-appearance: none;
  letter-spacing: 0.27px;
}
</style>
