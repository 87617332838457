<template>
  <v-form
    :class="[showHelp && 'form-show-help']"
    data-test-id="settings-form"
    autocomplete="off"
    @submit.prevent="$emit('submit')"
  >
    <homebox-screen>
      <h2 v-if="variant === 'box'">
        <sr-button
          inline
          sr-style="text"
          :title="$gettext('Close Options Panel')"
          @click="handleClose"
        >
          <circle-back-icon />
        </sr-button>
        <translate>Options</translate>
      </h2>

      <div class="h-section" @click="handleProInput">
        <sr-input
          ref="name"
          v-model="name"
          name="name"
          :label="$gettext('File Name')"
          :placeholder="$gettext('Enter a file name')"
          :readonly="!(canChangeDocName || allIsPermittedForDocument)"
        >
          <template v-slot:append-outer>
            <sr-file-select
              v-if="currentFileLoaded && variant === 'sr'"
              class="h-section__replace-button"
              data-test-id="replace"
              :disabled="!(canReplaceFile || allIsPermittedForDocument)"
              @file="
                replaceFile({
                  localId: selectedFileInfo.localId,
                  file: $event
                })
              "
            >
              <button
                v-translate
                class="srlink--text"
                type="button"
                @click="handleReplaceFile"
              >
                replace
              </button>
            </sr-file-select>
          </template>
        </sr-input>
      </div>

      <hr class="h-divider" />

      <div class="h-section" @click="handleProInput">
        <sr-input
          v-model="subject"
          name="subject"
          :label="$gettext('Email subject')"
          :placeholder="$gettext('Enter email subject')"
          :readonly="!(canChangeDocSubject || allIsPermittedForDocument)"
          @change="trackChangeEmailSubject()"
        >
          <template v-slot:append-outer>
            <help-hint>
              <translate>
                Please note that SignRequest optimizes email subject lines for
                email deliverability (ensure emails are not treated as spam).
                Only change this when absolutely necessary for your use case.
              </translate>
            </help-hint>
          </template>
        </sr-input>
      </div>

      <hr class="h-divider" />

      <div class="h-section settings-autocomplete" @click="handleProInput">
        <sr-combobox
          ref="auto_expire_days"
          v-model="auto_expire_days"
          name="auto_expire_days"
          :items="expireItems"
          :return-object="false"
          :label="$gettext('Expire after')"
          :match-fn="matchDays"
          :readonly="!(canChangeAutoExpire || allIsPermittedForDocument)"
        >
          <template v-slot:value>
            <span v-if="auto_expire_days">
              <translate
                key="0"
                :translate-n="Number(auto_expire_days)"
                translate-plural="%{ auto_expire_days } days"
              >
                %{ auto_expire_days } day
              </translate>
            </span>
            <span v-else>
              <translate key="1">Never</translate>
            </span>
          </template>

          <template v-slot:append-outer>
            <help-hint>
              <translate>
                The document will we be automatically expired after the number
                of days specified here.
              </translate>
            </help-hint>
          </template>
          <template v-slot:additem="{ value }">
            <translate
              v-if="!Number.isNaN(Number(value))"
              key="0"
              :translate-n="Number(value)"
              :translate-params="{ n: Number(value) }"
              translate-plural="%{ n } days"
            >
              %{ n } day
            </translate>
            <span v-else>
              <translate key="1">Never</translate>
            </span>
          </template>
        </sr-combobox>
      </div>

      <hr class="h-divider" />

      <div
        v-if="variant === 'sr'"
        class="h-section"
        @click="handleCheckSendReminders"
      >
        <sr-checkbox-input
          v-model="send_reminders"
          name="send_reminders"
          :label="$gettext('Send automatic reminders')"
          :readonly="!(canCheckSendReminders || allIsPermittedForDocument)"
          :disabled="!(canCheckSendReminders || allIsPermittedForDocument)"
        >
          <template v-slot:append-outer>
            <help-hint>
              <span>
                <translate>
                  When checked, SignRequest will send an automatic reminders to
                  all signers that haven't signed yet.
                </translate>
                <br />
                <translate>
                  Reminders are sent for first 2 times every 3 days (day 3 and
                  6) and an additional 2 times with a 5 day interval (day 11 and
                  16).
                </translate>
              </span>
            </help-hint>
          </template>
        </sr-checkbox-input>
      </div>
      <hr class="h-divider" />

      <div v-if="variant === 'sr'" class="h-section h-section__attachments">
        <div
          v-for="attachment in signrequestAttachments"
          :key="attachment.uuid"
          class="file-list--item primary--text"
        >
          <div class="file-list--bg"></div>
          <div class="filename" :title="attachment.name">
            {{ attachment.name }}
          </div>
          <a
            role="button"
            class="file-list--item--remove"
            :title="$gettext('Remove attachment')"
            @click="
              removeAttachment({
                localId: selectedFileInfo.localId,
                uuid: attachment.uuid
              })
            "
          >
            <remove />
          </a>
        </div>

        <sr-file-select
          class="h-section__add-attachment"
          data-test-id="add-attachment"
          :disabled="!currentFileLoaded"
          @file="
            addAttachment({
              localId: selectedFileInfo.localId,
              file: $event
            })
          "
        >
          <sr-button
            sr-style="link"
            :disabled="!currentFileLoaded"
            @click="handleAttach"
          >
            <plus />
            <translate>Add attachment</translate>
          </sr-button>
        </sr-file-select>
      </div>

      <hr v-if="template" class="h-divider" />
      <div
        v-if="template && variant === 'sr'"
        class="h-section h-section__public-link"
      >
        <sr-button
          data-test-id="public-link"
          sr-style="link"
          @click.prevent="handlePublicLink"
        >
          <link-icon />
          <translate>Show public signing link</translate>
        </sr-button>
      </div>

      <hr class="h-divider" />

      <div v-if="!template" class="h-section h-section__savetemplate">
        <sr-button
          data-test-id="save-template"
          sr-style="link"
          :disabled="!currentFileLoaded"
          @click.prevent="handleTemplate"
        >
          <save-icon />
          <translate>Save as template</translate>
        </sr-button>
      </div>
      <hr class="h-divider" />
    </homebox-screen>
  </v-form>
</template>

<script>
import { getVariant } from '@/utils/variant';
import HomeboxScreen from './HomeboxScreen';
import HelpHint from './HelpHint';
import CircleBackIcon from '@/components/icons/CircleBackButton.vue';
import SrFileSelect from '@/components/elements/SrFileSelect';
import SrInput from '@/components/elements/SrInput';
import SrCheckboxInput from '@/components/elements/SrCheckboxInput';
import SrCombobox from '@/components/elements/SrCombobox';
import Remove from './Remove';
import SaveIcon from './SaveIcon';
import LinkIcon from './LinkIcon';
import Plus from './Plus';
import { mapActions, mapGetters } from 'vuex';
import { mapModel, mapModelFields } from '@/utils/vuexExtra';
import permissions from '@/mixins/permissions';
import FocusTrap from '@/mixins/FocusTrap';
import { shortFilename } from '@/utils/format';

const numberOrNull = value => Number(value) || 0;
export default {
  components: {
    HomeboxScreen,
    SrInput,
    SrCheckboxInput,
    SrCombobox,
    SrFileSelect,
    HelpHint,
    CircleBackIcon,
    Remove,
    SaveIcon,
    LinkIcon,
    Plus
  },
  filters: { shortFilename },
  mixins: [
    permissions,
    FocusTrap('firstInputFocus', 'handleClose', 'shouldTrapFocus')
  ],
  props: {
    template: { type: Boolean, default: false }
  },
  computed: {
    ...mapGetters('signrequest', [
      'signrequestForm',
      'selectedFileInfo',
      'signrequestAttachments',
      'currentFileLoaded'
    ]),
    ...mapGetters('users', [
      'canAddAttachment',
      'canCheckSendReminders',
      'canCreateTemplates',
      'canChangeDocSubject',
      'canChangeDocName',
      'canChangeAutoExpire',
      'canReplaceFile'
    ]),
    ...mapGetters('signrequest', ['allIsPermittedForDocument']),
    ...mapGetters('modals', ['modalOpen']),
    ...mapModel('conf', 'showHelp', 'updateShowHelp'),
    ...mapModel('signrequest', 'signrequestForm', 'updateSignrequestForm'),
    ...mapModelFields('signrequestForm', [
      'name',
      'subject',
      { name: 'auto_expire_days', filter: numberOrNull },
      'send_reminders'
    ]),
    variant: getVariant,
    // actual modals, like subscribe modal should be above sidepanel
    shouldTrapFocus() {
      return !this.modalOpen;
    },
    expireItems() {
      return [
        { value: 0, text: this.$gettext('Never') },
        { value: 7, text: this.$gettext('7 days') },
        { value: 14, text: this.$gettext('14 days') },
        { value: 30, text: this.$gettext('30 days') },
        { value: 90, text: this.$gettext('90 days') }
      ];
    }
  },
  methods: {
    ...mapActions('modals', ['showPublicLinkModal']),
    ...mapActions('signrequest', [
      'replaceFile',
      'addAttachment',
      'removeAttachment',
      'createTemplate'
    ]),
    ...mapActions('events', [
      'trackEnableDisableAutoRemindersEvent',
      'trackSavingAsTemplateEvent',
      'trackChangeEmailSubjectEvent'
    ]),
    trackChangeEmailSubject() {
      this.trackChangeEmailSubjectEvent();
    },
    trackChangeAutoReminders() {
      this.trackEnableDisableAutoRemindersEvent();
    },
    firstInputFocus() {
      this.$refs.name.focus();
    },
    matchDays(input, option) {
      if (!input.trim()) {
        return !option.manual;
      }
      if (Number.isNaN(Number(input)) && !option.manual) {
        return true;
      }
      return String(option.value).includes(input) || option.manual;
    },
    handleReplaceFile(event) {
      if (!this.canReplaceFile && !this.allIsPermittedForDocument) {
        this.showLoginOrSubscribeModal({ feature: 'replace_file' });
      }
    },
    handleAttach(event) {
      if (this.canAddAttachment || this.allIsPermittedForDocument) {
        // do nothing, event would bubble up and trigger file selection
      } else {
        event.stopPropagation();
        this.showLoginOrSubscribeModal({ feature: 'add_attachment' });
      }
    },
    handleCheckSendReminders() {
      if (this.canCheckSendReminders || this.allIsPermittedForDocument) {
        this.trackChangeAutoReminders();
      } else {
        this.showLoginOrSubscribeModal({ feature: 'send_reminders' });
      }
    },
    handleTemplate(event) {
      if (this.canCreateTemplates || this.allIsPermittedForDocument) {
        this.createTemplate();
        this.trackSavingAsTemplateEvent();
      } else {
        this.showLoginOrSubscribeModal({ feature: 'create_template' });
      }
    },
    handlePublicLink() {
      this.showPublicLinkModal();
    },
    handleProInput(event) {
      if (event.target.readOnly) {
        this.showLoginOrSubscribeModal({ feature: event.target.name });
      }
    },
    handleClose() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
h2 {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.3px;
  margin: 24px;
}
h2 svg {
  display: inline-block;
  height: 34px;
  width: 34px;
}
h2 > .sr-button {
  vertical-align: middle;
}

form /deep/ .h-section--title {
  padding-bottom: 0.5rem;
}
.ltr form /deep/ [name='name'] {
  margin-right: 4rem;
}
.rtl form /deep/ [name='name'] {
  margin-left: 4rem;
}
.ltr.lang-nl form /deep/ [name='name'] {
  margin-right: 5rem;
}
.h-section__replace-button {
  position: absolute;
  top: calc(50% - 0.9em);
}
.ltr .h-section__replace-button {
  right: 0;
}
.rtl .h-section__replace-button {
  left: 0;
}
.h-section__replace-button button {
  font-size: 14px;
  text-decoration: none;
}
.h-section__replace-button button:focus {
  text-decoration: underline dashed;
}
.h-section__attachments,
.h-section__savetemplate {
  min-height: 72px;
}
.h-section.settings-autocomplete /deep/ .sr-autocomplete--value {
  top: unset;
}
.file-list--item {
  position: relative;
  display: inline-block;
  padding: 0.25em 1em;
  margin-top: 1em;
  max-width: 100%;
}
.ltr .file-list--item {
  margin-right: 1em;
}
.rtl .file-list--item {
  margin-left: 1em;
}
.file-list--bg {
  opacity: 0.1;
  background: currentColor;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 1.5em;
}
div.filename {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: calc(100% - 1em);
  display: inline-block;
  position: relative;
  line-height: 1.2em;
  vertical-align: text-top;
}
.file-list--item--remove {
  height: 1.2em;
  width: 1em;
  display: inline-block;
  font-size: 10px;
  margin-top: 0.5em;
  vertical-align: text-top;
  position: relative;
}
.file-list--item--remove svg {
  display: block;
}
.h-section__add-attachment {
  display: block;
}
.h-section__add-attachment button,
.h-section__public-link button,
.h-section__savetemplate button {
  padding: 0;
  margin: 18px 0;
  font-weight: bold;
}
.h-section ::v-deep .sr-button svg {
  font-size: inherit;
  height: 1em;
  width: 1em;
}
.h-section ::v-deep .sr-button .sr-button--content span {
  margin: 0 1em;
}
.ltr .h-section__add-attachment button svg {
  position: relative;
  left: 5px;
}
.h-section /deep/ .sr-field.sr-checkbox {
  position: relative;
}
.h-section /deep/ .sr-field.sr-checkbox .help {
  position: absolute;
  top: 2rem;
}
.h-section /deep/ .sr-field.sr-checkbox .sr-field--control {
  display: none;
}
.ltr .h-section /deep/ .sr-field.sr-checkbox .help {
  right: 1rem;
}
.rtl .h-section /deep/ .sr-field.sr-checkbox .help {
  left: 1rem;
}
.ltr .h-section /deep/ .sr-field {
  margin-right: 1rem;
}
.rtl .h-section /deep/ .sr-field {
  margin-left: 1rem;
}
hr + hr {
  display: none;
}
</style>
<style>
.v-menu__content.auto_expire_days--menu {
  border-radius: 15px;
}
</style>
