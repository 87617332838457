'use strict';

import angular from 'angular';
import config from '@/config';

const env = angular.module('config', []).constant('ENV', {
  cookie_domain: config.cookieDomain,
  debug: config.debug,
  stripe_publishable_key: config.stripeKey,
  version: config.appVersion,
  trial_subscribe_version: 2
});
export default env;
