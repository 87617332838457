import config from '@/config';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async track({ commit, dispatch }, { action, category, params }) {
      const eventData = { ...params, app_version: config.appVersion };
      await dispatch(
        'trackEvents/trackEvent',
        {
          action,
          category,
          params: eventData
        },
        { root: true }
      );
    },
    async trackDocumentStorageEvent({ dispatch }, action) {
      dispatch('track', {
        action,
        category: 'Document Storage'
      });
    },
    async trackFeatureOptInBannerShownEvent({ dispatch }, tag_name) {
      dispatch('track', {
        action: 'Shown feature opt in banner',
        category: 'Feature toggle',
        params: { tag_name }
      });
    },
    async trackSocialLoginModalEvent({ dispatch }, tag_name) {
      dispatch('track', {
        action: 'Login modal social submit',
        category: 'Login',
        params: { tag_name }
      });
    },
    async trackSocialLoginSuccessModalEvent({ dispatch }, tag_name) {
      dispatch('track', {
        action: 'Login modal social submit success',
        category: 'Login',
        params: { tag_name }
      });
    },
    async trackFeatureOptOutBannerShownEvent({ dispatch }, tag_name) {
      dispatch('track', {
        action: 'Shown feature opt out banner',
        category: 'Feature toggle',
        params: { tag_name }
      });
    },
    async trackFeatureOptInBannerDismissedEvent({ dispatch }, tag_name) {
      dispatch('track', {
        action: 'Dismissed feature opt in banner',
        category: 'Feature toggle',
        params: { tag_name }
      });
    },
    async trackFeatureOptOutBannerDismissedEvent({ dispatch }, tag_name) {
      dispatch('track', {
        action: 'Dismissed feature opt out banner',
        category: 'Feature toggle',
        params: { tag_name }
      });
    },
    async trackDocumentUploadedEvent({ dispatch }, params) {
      dispatch('track', {
        params,
        action: 'Added',
        category: 'Document'
      });
    },
    async trackDocumentRemovedEvent({ dispatch }) {
      dispatch('track', {
        action: 'Removed',
        category: 'Document'
      });
    },
    async trackAddNewContactEvent({ dispatch }, contact) {
      dispatch('track', {
        action: 'Adding new contact',
        category: 'Document',
        params: contact
      });
    },
    async trackChangeEmailEvent({ dispatch }, email) {
      dispatch('track', {
        action: 'Changing email address',
        category: 'Document',
        params: email
      });
    },
    async trackTextMessageVerificationEvent({ dispatch }, mobile) {
      dispatch('track', {
        action: `Text message verification`,
        category: 'Document',
        params: { mobile }
      });
    },
    async trackOpenSettingsPageEvent({ dispatch }) {
      dispatch('track', {
        action: `Opening Settings page`,
        category: 'Document'
      });
    },
    async trackChangeEmailSubjectEvent({ dispatch }) {
      dispatch('track', {
        action: `Changing email subject`,
        category: 'Document'
      });
    },
    async trackEnableDisableAutoRemindersEvent({ dispatch }) {
      dispatch('track', {
        action: `Enable / Disable automatic reminders`,
        category: 'Document'
      });
    },
    async trackAddingAttachmentEvent({ dispatch }) {
      dispatch('track', {
        action: `Adding attachment`,
        category: 'Document'
      });
    },
    async trackSavingAsTemplateEvent({ dispatch }) {
      dispatch('track', {
        action: `Saving template`,
        category: 'Document'
      });
    },
    async trackLoginFormShownEvent({ dispatch }, params) {
      dispatch('track', {
        params,
        action: 'Shown',
        category: 'Login Form'
      });
    },
    async trackRegisterFormShownEvent({ dispatch }, params) {
      dispatch('track', {
        params,
        action: 'Shown',
        category: 'Register Form'
      });
    },
    async trackExperiment({ dispatch }, { name, params }) {
      dispatch('track', {
        params,
        action: name,
        category: 'Experiment'
      });
    },
    async trackModalEvent({ dispatch }, { action, name, params }) {
      dispatch('track', {
        params,
        action: `${name} Modal ${action}`,
        category: `${name} Modal`
      });
    },
    async trackModalOpenedEvent({ dispatch }, { name, params }) {
      return dispatch('trackModalEvent', {
        name,
        params,
        action: 'Opened'
      });
    },
    async trackModalClosedEvent({ dispatch }, { name, params }) {
      return dispatch('trackModalEvent', {
        name,
        params,
        action: 'Closed'
      });
    },
    async trackSubscribeEvent({ dispatch }, { name, params }) {
      return dispatch('track', {
        params,
        action: `${name} Subscribed`,
        category: name
      });
    },
    async trackDocumentSettingsEvent({ dispatch }, { settings, category }) {
      dispatch('track', {
        action: 'Changing document settings',
        category: category,
        params: settings
      });
    }
  }
};
