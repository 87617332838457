<template>
  <svg
    :class="{ 'checkbox--invert': invert }"
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <rect height="16" width="16" />
    <path
      fill-rule="evenodd"
      d="M13.4361148,1.03584031e-16 C14.3276335,-6.01853049e-17 14.6509198,0.0928256111 14.9768457,0.267132704 C15.3027716,0.441439796 15.5585602,0.697228371 15.7328673,1.0231543 C15.9071744,1.34908022 16,1.67236646 16,2.5638852 L16,13.4361148 C16,14.3276335 15.9071744,14.6509198 15.7328673,14.9768457 C15.5585602,15.3027716 15.3027716,15.5585602 14.9768457,15.7328673 C14.6509198,15.9071744 14.3276335,16 13.4361148,16 L2.5638852,16 C1.67236646,16 1.34908022,15.9071744 1.0231543,15.7328673 C0.697228371,15.5585602 0.441439796,15.3027716 0.267132704,14.9768457 C0.0928256111,14.6509198 4.01235366e-17,14.3276335 -6.90560205e-17,13.4361148 L6.90560205e-17,2.5638852 C-4.01235366e-17,1.67236646 0.0928256111,1.34908022 0.267132704,1.0231543 C0.441439796,0.697228371 0.697228371,0.441439796 1.0231543,0.267132704 C1.34908022,0.0928256111 1.67236646,6.01853049e-17 2.5638852,-1.03584031e-16 L13.4361148,1.03584031e-16 Z M11.5579065,3.5 C11.3775056,3.5 11.2238307,3.53767523 11.096882,3.6130257 C10.9699332,3.68837617 10.8496659,3.81892523 10.7360802,4.0046729 L7.02783964,10.1185748 L5.1636971,7.77920561 C4.97327394,7.54088785 4.73106904,7.42172897 4.43708241,7.42172897 C4.16648107,7.42172897 3.94265033,7.51285047 3.7655902,7.69509346 C3.58853007,7.87733645 3.5,8.10689252 3.5,8.38376168 C3.5,8.50642523 3.52004454,8.62295561 3.56013363,8.7333528 C3.60022272,8.84375 3.672049,8.96028037 3.77561247,9.08294393 L6.2110245,12.1004673 C6.43151448,12.3668224 6.70879733,12.5 7.04287305,12.5 C7.42371938,12.5 7.71269488,12.3492991 7.90979955,12.0478972 L12.2995546,5.09287383 C12.3697105,4.9807243 12.420657,4.87120327 12.4523942,4.76431075 C12.4841314,4.65741822 12.5,4.55490654 12.5,4.4567757 C12.5,4.18341121 12.4106347,3.95560748 12.2319042,3.77336449 C12.0531737,3.5911215 11.8285078,3.5 11.5579065,3.5 Z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    invert: { type: Boolean, default: false }
  }
};
</script>
<style scoped>
svg rect {
  fill: white;
}
svg.checkbox--invert rect {
  fill: none;
}
</style>
