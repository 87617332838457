import config from '@/config';

function almostRandomId() {
  const time = new Date().getTime().toString();
  const hash = Math.random()
    .toString(16)
    .slice(2);

  return [time, hash].join(':');
}

export default {
  namespaced: true,
  state: {
    messages: [],
    actions: []
  },
  mutations: {
    addMessage(state, message) {
      state.messages.push(Object.freeze(message));
    },
    removeMessage(state, key) {
      state.messages = state.messages.filter(x => x.key !== key);
    },
    addAction(state, action) {
      state.actions.push(Object.freeze(action));
    },
    removeAction(state, key) {
      state.actions = state.actions.filter(x => x.key !== key);
    },
    setActionStatus(state, { key, status }) {
      for (let i = 0; i < state.actions.length; i++) {
        const act = state.actions[i];

        if (act.key === key) {
          const naction = Object.assign({}, act, { status });
          state.actions.splice(i, 1, Object.freeze(naction));
          return;
        }
      }
    }
  },
  getters: {
    messages(state) {
      return state.messages.slice(0);
    },
    topAction(state) {
      if (state.actions.length > 0) {
        return state.actions[state.actions.length - 1];
      }

      return null;
    }
  },
  actions: {
    async addMessage({ commit, dispatch }, { type, msg, timeout }) {
      const key = almostRandomId();
      const message = { key, type, msg, timeout };
      commit('addMessage', message);

      if (message.timeout > 0 && !config.enableStrictAccessibilityMode) {
        setTimeout(() => {
          commit('removeMessage', message.key);
        }, message.timeout);
      }

      return message;
    },

    async removeMessage({ commit }, messageKey) {
      commit('removeMessage', messageKey);
    },

    async displayRequest({ commit }, props) {
      const { promise } = props;

      const key = Math.random().toString();
      const action = Object.assign({ status: 'started', key }, props);

      action.closePromise = new Promise((resolve, reject) => {
        action.closeResolve = resolve;
        action.closeReject = reject;
      });

      action.closePromise = action.closePromise.finally(() => {
        commit('removeAction', key);
      });

      commit('addAction', action);

      return promise.then(
        success => {
          commit('setActionStatus', { key, status: 'success' });
          setTimeout(action.closeResolve, 500);
        },
        error => {
          commit('setActionStatus', { key, status: 'error' });
          setTimeout(action.closeResolve, 500);
        }
      );
    }
  }
};
