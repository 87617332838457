// API is a bit inconsistent and input differs from output:
// template.signrequest has a list of attachments as {name, uuid}
// for each signer, while POST /docs/signrequest/ expects
// a list of strings.
export function fixAttachments({ required_attachments, ...rest }) {
  return {
    ...rest,
    required_attachments: required_attachments
      ? required_attachments.map(({ name }) => name)
      : null
  };
}
export function initialSigner(idx) {
  return Object.freeze({
    email: '',
    label: '',
    name: '',
    level: idx ? 'signature' : 'cc',
    order: 1,
    password: '',
    verify_phone_number: '',
    required_attachments: null
  });
}

export function emptySigner(template, idx) {
  const signerDefaults =
    (template && template.signrequest && template.signrequest.signers) || [];
  const defaults = fixAttachments(signerDefaults[idx] || initialSigner(idx));
  return {
    verify_phone_number: '',
    password: '',
    ...defaults
  };
}
