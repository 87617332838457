
// this file is autogenerated by utils/makeLazyTranslations.js
// do not edit
export function load(code) {
    switch(code) {
  
      case 'bn': return import(/* webpackChunkName: "locale_bn" */ './bn.json');
    
      case 'da': return import(/* webpackChunkName: "locale_da" */ './da.json');
    
      case 'de': return import(/* webpackChunkName: "locale_de" */ './de.json');
    
      case 'el': return import(/* webpackChunkName: "locale_el" */ './el.json');
    
      case 'en-au': return import(/* webpackChunkName: "locale_en-au" */ './en-au.json');
    
      case 'en-ca': return import(/* webpackChunkName: "locale_en-ca" */ './en-ca.json');
    
      case 'en-gb': return import(/* webpackChunkName: "locale_en-gb" */ './en-gb.json');
    
      case 'en': return import(/* webpackChunkName: "locale_en" */ './en.json');
    
      case 'es-419': return import(/* webpackChunkName: "locale_es-419" */ './es-419.json');
    
      case 'es': return import(/* webpackChunkName: "locale_es" */ './es.json');
    
      case 'fi': return import(/* webpackChunkName: "locale_fi" */ './fi.json');
    
      case 'fr-ca': return import(/* webpackChunkName: "locale_fr-ca" */ './fr-ca.json');
    
      case 'fr': return import(/* webpackChunkName: "locale_fr" */ './fr.json');
    
      case 'he': return import(/* webpackChunkName: "locale_he" */ './he.json');
    
      case 'hi': return import(/* webpackChunkName: "locale_hi" */ './hi.json');
    
      case 'hu': return import(/* webpackChunkName: "locale_hu" */ './hu.json');
    
      case 'it': return import(/* webpackChunkName: "locale_it" */ './it.json');
    
      case 'ja': return import(/* webpackChunkName: "locale_ja" */ './ja.json');
    
      case 'ko': return import(/* webpackChunkName: "locale_ko" */ './ko.json');
    
      case 'nl': return import(/* webpackChunkName: "locale_nl" */ './nl.json');
    
      case 'no': return import(/* webpackChunkName: "locale_no" */ './no.json');
    
      case 'pl': return import(/* webpackChunkName: "locale_pl" */ './pl.json');
    
      case 'pt': return import(/* webpackChunkName: "locale_pt" */ './pt.json');
    
      case 'ru': return import(/* webpackChunkName: "locale_ru" */ './ru.json');
    
      case 'sv': return import(/* webpackChunkName: "locale_sv" */ './sv.json');
    
      case 'tr': return import(/* webpackChunkName: "locale_tr" */ './tr.json');
    
      case 'zh-tw': return import(/* webpackChunkName: "locale_zh-tw" */ './zh-tw.json');
    
      case 'zh': return import(/* webpackChunkName: "locale_zh" */ './zh.json');
    } 
};

export function getLangs() { return ["bn","da","de","el","en-au","en-ca","en-gb","en","es-419","es","fi","fr-ca","fr","he","hi","hu","it","ja","ko","nl","no","pl","pt","ru","sv","tr","zh-tw","zh"] ; }
  