import angular from 'angular';

/**
 * @ngdoc directive
 * @name signRequestApp.directive:srShowMore
 * @description
 * # srShowMore
 */
angular.module('frontendApp').directive('srShowMore', [
  function() {
    return {
      restrict: 'A',
      scope: { srShowMore: '=' },
      template:
        '<span ng-show="moreData">' +
        '<span ng-show="!showData"><span ng-bind="snippet"></span>... </span>' +
        '<button style="padding: 0;" class="button button--link" ng-click="showData = true;" ng-show="!showData" translate>show</button>' +
        '<span ng-bind="moreData" ng-cloak ng-show="showData"></span>' +
        '</a>',
      link: function(scope) {
        scope.moreData = scope.srShowMore;
        scope.showData = false;
        scope.snippet = '';
        if (scope.moreData) {
          scope.snippet = scope.moreData.substring(
            0,
            scope.moreData.length / 4
          );
        }
      }
    };
  }
]);
