<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="13"
    viewBox="0 0 13 13"
  >
    <g
      fill="none"
      fill-rule="evenodd"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path
        d="M10.778 12H2.222A1.222 1.222 0 0 1 1 10.778V2.222C1 1.547 1.547 1 2.222 1h6.722L12 4.056v6.722c0 .675-.547 1.222-1.222 1.222z"
      />
      <path d="M9.556 12V7.111H3.444V12M3.444 1v3.056h4.89" />
    </g>
  </svg>
</template>
<script>
export default {};
</script>
