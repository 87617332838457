import angular from 'angular';

/**
 * @ngdoc directive
 * @name frontendApp.directive:quickRegister
 * @description
 * # quickRegister with a pass and given email
 */
angular.module('frontendApp').directive('quickRegister', [
  '$rootScope',
  'UserService',
  '$window',
  '$location',
  function($rootScope, UserService, $window, $location) {
    return {
      restrict: 'E',
      template: require('../../views/directives/quick-register.html'),
      scope: {
        email: '='
      },
      link: function(scope, element, attrs, ngModel) {
        scope.quickRegister = function(event) {
          if (event) {
            event.preventDefault();
          }
          UserService.registerUser(
            scope.email,
            scope.password,
            scope.password
          ).then(function(response) {
            if (response.data.status === 'SUCCESS') {
              if (response.data.user_logged_in) {
                $window.location.href = '/';
              } else {
                $location.path('/#/');
              }
              UserService.trackEvent(
                'Quick Register Success',
                'Quick Register',
                {}
              );
            }
          });
        };
      }
    };
  }
]);
