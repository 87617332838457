<template>
  <v-tooltip
    class="help-hint"
    :right="!bottom && !$vuetify.rtl"
    :left="!bottom && $vuetify.rtl"
    :bottom="bottom"
    light
    color="white"
    open-on-click
  >
    <template v-slot:activator="{ on }">
      <div class="help" role="button" :class="{ 'help--visible': visible }">
        <div class="round" :class="colorClass" v-on="on">
          <help-icon />
        </div>
      </div>
    </template>
    <div class="help-tooltip">
      <slot />
    </div>
  </v-tooltip>
</template>

<script>
import HelpIcon from './HelpIcon';
export default {
  components: { HelpIcon },
  props: {
    color: { type: String, default: 'primary' },
    bottom: { type: Boolean, default: false },
    visible: { type: Boolean, default: false }
  },
  computed: {
    colorClass() {
      return `${this.color}--text`;
    }
  }
};
</script>

<style scoped>
.round {
  padding: 0;
  margin: 0;
  border-radius: 50%;
  height: 1em;
  min-width: 1em;
  border-width: 1px;
  border-style: solid;
  background: none !important;
  text-align: center;
}
.help {
  max-width: 0;
  display: none;
  line-height: 1;
  font-size: 1.4rem;
}
.help.help--visible {
  display: inline-block;
  max-width: unset;
  font-size: inherit;
  vertical-align: middle;
}
.sr-field.sr-field--vertical .help {
  top: calc(50% - 1em);
  position: absolute;
}
.ltr .sr-field .help {
  right: 1rem;
}
.rtl .sr-field .help {
  left: 1rem;
}

form.form-show-help .help {
  display: inherit;
}
.help-tooltip {
  padding: 1rem;
  font-size: 1rem;
  max-width: 25rem;
  color: #111111;
}
svg {
  margin-bottom: 0.1em;
  height: 0.7em;
  width: auto;
}
</style>
