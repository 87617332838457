<template>
  <v-dialog v-model="showModal" max-width="35rem" :content-class="contentClass">
    <v-card>
      <v-card-title class="headline srtext--text">
        E-Sign disclosure
      </v-card-title>
      <v-card-text class="esign-modal--content">
        {{ modalOptions.text }}
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import ModalMixin from '@/components/modals/ModalMixin';

export default {
  mixins: [ModalMixin('esign')],
  props: {
    contentClass: { type: String, default: '' }
  }
};
</script>
<style scoped>
.v-card {
  padding: 0px 40px;
  padding-bottom: 2em;
}
.v-card__title {
  padding: 2em 0;
  font-weight: 900;
}

.v-card__text {
  font-size: 17px;
  padding: 0;
}
.ltr .v-card__text.esign-modal--content {
  padding-left: 1em;
  padding-bottom: 1em;
  border-left: 1px solid gray;
}
</style>
