function getQueryParamsFromHash() {
  if (location.hash && location.hash.includes('?')) {
    return new URLSearchParams(location.hash.split(/\?(.+)/)[1]);
  }
  return new URLSearchParams();
}

function getPathFromHash() {
  return location.hash ? location.hash.split(/\?(.+)/)[0] : '';
}

function removeQueryParamFromHash(param_name) {
  if (location.hash && location.hash.includes(param_name)) {
    const currentParams = getQueryParamsFromHash();
    const param_value = currentParams.get(param_name);
    currentParams.delete(param_name);
    const query = currentParams.toString();
    location.hash = `${getPathFromHash()}${query ? '?' + query : ''}`;
    return param_value;
  }
  return null;
}

function getAndRemoveParamFromRoute(param_name) {
  // get a param value and remove it from the route (in the hash routing mode part of the url)
  return removeQueryParamFromHash(param_name);
}

export default {
  getQueryParamsFromHash,
  getAndRemoveParamFromRoute
};
