import angular from 'angular';
import { store } from '@/store';
import router from '@/router';

const mod = angular.module('frontendApp');

mod.factory('vueLoadingBridge', [
  '$vuex',
  '$q',
  function($vuex, $q) {
    const req = {};

    // a simple $http interceptor
    // to track the state in vuex
    function register(config) {
      const id = Math.random().toString();
      req[id] = true;
      config._loaderId = id;

      if (!config.ignoreLoadingBar) {
        $vuex.commit('api/increment');
      }
      return config;
    }

    function unregister(config) {
      if (!config || !config._loaderId) {
        console.warn('Invalid request config.', config);
        return;
      }

      const id = config._loaderId;
      if (!req[id]) {
        console.warn('Double call to unregister.');
        return;
      }

      req[id] = false;
      delete req[id];

      if (!config.ignoreLoadingBar) {
        $vuex.commit('api/decrement');
      }
    }

    return {
      request(config) {
        return register(config);
      },
      response(response) {
        unregister(response.config);
        return response;
      },
      responseError(rejection) {
        unregister(rejection.config);
        return $q.reject(rejection);
      }
    };
  }
]);

mod.config([
  '$httpProvider',
  function($httpProvider) {
    $httpProvider.interceptors.push('vueLoadingBridge');
  }
]);

mod.service('$vuex', [
  '$rootScope',
  function($rootScope) {
    $rootScope.$vuex = store;
    return store;
  }
]);

mod.service('$vueRouter', [
  '$rootScope',
  function($rootScope) {
    $rootScope.$vueRouter = router;
    return router;
  }
]);

mod.service('ngAsyncWrapper', [
  '$rootScope',
  '$q',
  function($rootScope, $q) {
    return function(fn) {
      return function() {
        const result = fn.apply(this, arguments);

        return $q
          .when(result)
          .catch(error => {
            console.error('Error inside async function.', error);
            return $q.reject(error);
          })
          .finally(() => $rootScope.$applyAsync());
      };
    };
  }
]);
