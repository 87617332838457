export default function debouncePromise(fn, timeout) {
  let timer = null;
  let lastReject = null;
  return function debounced(...args) {
    clearTimeout(timer);
    lastReject = lastReject && lastReject(null);
    return new Promise((resolve, reject) => {
      timer = setTimeout(() => fn(...args).then(resolve, reject), timeout);
      lastReject = reject;
    });
  };
}
