<template>
  <v-dialog
    v-model="showModal"
    max-width="32.5rem"
    :content-class="contentClass"
  >
    <v-card
      role="dialog"
      aria-hidden="!showModal"
      aria-modal="showModal"
      aria-labelledby="text-entry-modal-title"
    >
      <v-form class="ph-text-entry" @submit="handleSave">
        <v-card-text>
          <h1 id="text-entry-modal-title" class="srtext--text">
            <translate v-if="isDate" key="d">Add date</translate>
            <translate v-else key="t">Add text</translate>
          </h1>
        </v-card-text>
        <v-card-text class="v-card__text__input srtext--text">
          <textarea
            v-if="multiline"
            ref="textarea"
            v-model="text"
            dir="auto"
            name="text"
            :cols="maxColumns"
            wrap="hard"
            :placeholder="placeholder"
          />
          <sr-input
            v-else
            ref="input"
            v-model="text"
            name="text"
            dir="auto"
            :placeholder="placeholder"
          />
          <v-checkbox
            v-if="couldChangeMultiline"
            v-model="multiline"
            :label="$gettext('Multiline')"
          />
        </v-card-text>
        <v-card-text class="v-card__text__buttons">
          <sr-button sr-style="cancel" round @click="handleClose">
            <translate>Cancel</translate>
          </sr-button>

          <sr-button
            sr-style="fancy"
            round
            type="submit"
            :disabled="!currentText"
          >
            <translate>Save</translate>
          </sr-button>
        </v-card-text>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import ModalMixin from '@/components/modals/ModalMixin';
import SrButton from '@/components/elements/SrButton';
import SrInput from '@/components/elements/SrInput';

function wrapLine(text, maxColumns) {
  const lines = [];
  let line = text;
  while (line) {
    if (line.length <= maxColumns) {
      lines.push(line);
      break;
    } else {
      // find a space and break there if possible
      // do maxColumns + 1 in case word is exactly maxColumns in length
      let lastSpace = line.slice(0, maxColumns + 1).lastIndexOf(' ');
      if (lastSpace !== -1) {
        lines.push(line.slice(0, lastSpace));
        line = line.slice(lastSpace + 1);
      } else {
        lines.push(line.slice(0, maxColumns));
        line = line.slice(maxColumns);
      }
    }
  }

  return lines.join('\n');
}

function wrapText(text, maxColumns) {
  return text
    .split('\n')
    .map(line => wrapLine(line, maxColumns))
    .join('\n');
}

export default {
  components: {
    SrButton,
    SrInput
  },
  mixins: [ModalMixin('textEntry')],
  props: {
    contentClass: { type: String, default: '' }
  },
  data: function() {
    return {
      text: '',
      multiline: false
    };
  },
  computed: {
    maxColumns() {
      const { maxColumns = 0 } = this.modalOptions || {};
      return maxColumns;
    },
    couldChangeMultiline() {
      const { couldChangeMultiline } = this.modalOptions || {};
      return Boolean(couldChangeMultiline);
    },
    initialText() {
      const { text } = this.modalOptions || {};
      return text || null;
    },
    initialMultiline() {
      const { multiline } = this.modalOptions || {};
      return Boolean(multiline);
    },
    isDate() {
      const { fullType } = this.modalOptions || {};
      return fullType === 'date';
    },
    placeholder() {
      return this.isDate
        ? this.$gettext('Add date here')
        : this.$gettext('Add text here');
    },
    currentText() {
      return this.multiline
        ? wrapText(this.text, this.maxColumns)
        : this.text.replace(/\n/g, ' ');
    }
  },
  watch: {
    showModal(value, oldValue) {
      if (value && !oldValue) {
        this.$nextTick(() => this.focus());
      }
    },
    initialText(value) {
      this.text = value || '';
    },
    initialMultiline(value) {
      this.multiline = Boolean(value);
    },
    text(value) {
      const { textarea } = this.$refs;
      if (!textarea) {
        return;
      }
      this.$nextTick(() => {
        textarea.style.height = 'auto'; // trigger resize in dom
        textarea.style.height = `${textarea.scrollHeight}px`;
      });
    }
  },
  methods: {
    focus() {
      const { textarea, input } = this.$refs;
      if (textarea) {
        textarea.focus();
      }
      if (input) {
        input.focus();
      }
    },
    async handleSave(event) {
      event.preventDefault();
      const text = this.currentText;
      if (!text) {
        return;
      }
      this.returnValue = { text, multiline: this.multiline };
      this.text = '';
      this.closeModal();
    },
    handleClose() {
      this.text = '';
      this.returnValue = null;
      this.closeModal();
    }
  }
};
</script>
<style scoped>
/deep/ .v-dialog {
  border-radius: 2rem;
}

h1 {
  font-size: 24px;
  opacity: 0.9;
  font-weight: 900;
}
.v-card__text {
  position: relative;
  padding: 25px 30px;
}
.v-card__text__input {
  font-size: 16px;
  position: relative;
  padding: 0 30px;
}
.v-card__text__input input::placeholder {
  opacity: 0.6;
  font-weight: 500;
  color: inherit;
}
.v-card__text__input::before,
.v-card__text__input::after {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  color: inherit;
  border-top: 1px solid #d6d6d6;
  content: ' ';
  transform: scaleY(0.5);
}
.v-card__text__input::before {
  top: 0;
}
.v-card__text__input::after {
  bottom: 0;
}
textarea {
  width: 100%;
  resize: none;
  overflow: hidden;
  overflow-wrap: break-word;
  margin: 2rem 0;
}
.v-card__text__buttons {
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  justify-content: space-between;
}
.v-card__text__buttons button {
  font-size: 18px;
  padding: 0em 3.5em;
  margin: 0px;
  height: 3em;
  width: calc(50% - 10px);
}
</style>
