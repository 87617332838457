import angular from 'angular';

/**
 * @ngdoc directive
 * @name signRequestApp.directive:srHasPerm
 * @description
 * # srHasPerm: only allows changing the input when user has the required permission
 * <input sr-has-perm="pro" >
 */
angular.module('frontendApp').directive('srHasPerm', [
  'UserService',
  function(UserService) {
    return {
      require: 'ngModel',
      link: function(scope, element, attrs, ngModelCtrl) {
        ngModelCtrl.$parsers.push(function(viewValue) {
          if (
            !UserService.hasPermOrModal(attrs.srHasPerm, attrs.srHasPermFeature)
          ) {
            // not allowed to make changes, restore the original model value
            ngModelCtrl.$setViewValue(ngModelCtrl.$modelValue);
            ngModelCtrl.$rollbackViewValue();
            element.blur();
            ngModelCtrl.$setPristine();

            return ngModelCtrl.$modelValue;
          } else {
            // we are allowed to make changes
            return viewValue;
          }
        });
      }
    };
  }
]);
