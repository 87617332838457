// https://github.com/pellepim/jstimezonedetect
var jstz = (function() {
  'use strict';
  var HEMISPHERE_SOUTH = 's',
    consts = {
      DAY: 86400000,
      HOUR: 3600000,
      MINUTE: 60000,
      SECOND: 1000,
      BASELINE_YEAR: 2014,
      MAX_SCORE: 864000000, // 10 days
      AMBIGUITIES: {
        'America/Denver': ['America/Mazatlan'],
        'America/Chicago': ['America/Mexico_City'],
        'America/Asuncion': ['America/Campo_Grande', 'America/Santiago'],
        'America/Montevideo': ['America/Sao_Paulo', 'America/Santiago'],
        'Asia/Beirut': [
          'Asia/Amman',
          'Asia/Jerusalem',
          'Europe/Helsinki',
          'Asia/Damascus',
          'Africa/Cairo',
          'Asia/Gaza',
          'Europe/Minsk',
          'Africa/Windhoek'
        ],
        'Pacific/Auckland': ['Pacific/Fiji'],
        'America/Los_Angeles': ['America/Santa_Isabel'],
        'America/New_York': ['America/Havana'],
        'America/Halifax': ['America/Goose_Bay'],
        'America/Godthab': ['America/Miquelon'],
        'Asia/Dubai': ['Asia/Yerevan'],
        'Asia/Jakarta': ['Asia/Krasnoyarsk'],
        'Asia/Shanghai': ['Asia/Irkutsk', 'Australia/Perth'],
        'Australia/Sydney': ['Australia/Lord_Howe'],
        'Asia/Tokyo': ['Asia/Yakutsk'],
        'Asia/Dhaka': ['Asia/Omsk'],
        'Asia/Baku': ['Asia/Yerevan'],
        'Australia/Brisbane': ['Asia/Vladivostok'],
        'Pacific/Noumea': ['Asia/Vladivostok'],
        'Pacific/Majuro': ['Asia/Kamchatka', 'Pacific/Fiji'],
        'Pacific/Tongatapu': ['Pacific/Apia'],
        'Asia/Baghdad': ['Europe/Minsk', 'Europe/Moscow'],
        'Asia/Karachi': ['Asia/Yekaterinburg'],
        'Africa/Johannesburg': ['Asia/Gaza', 'Africa/Cairo']
      }
    },
    /**
     * Gets the offset in minutes from UTC for a certain date.
     * @param {Date} date
     * @returns {Number}
     */
    get_date_offset = function get_date_offset(date) {
      var offset = -date.getTimezoneOffset();
      return offset !== null ? offset : 0;
    },
    get_offsets = function get_offsets() {
      var offsets = [];

      for (var month = 0; month <= 11; month++) {
        for (var date = 1; date <= 28; date++) {
          var currentOffset = get_date_offset(
            new Date(consts.BASELINE_YEAR, month, date)
          );
          if (!offsets) {
            offsets.push();
          } else if (offsets && offsets[offsets.length - 1] !== currentOffset) {
            offsets.push(currentOffset);
          }
        }
      }

      return offsets;
    },
    /**
     * This function does some basic calculations to create information about
     * the user's timezone. It uses REFERENCE_YEAR as a solid year for which
     * the script has been tested rather than depend on the year set by the
     * client device.
     *
     * Returns a key that can be used to do lookups in jstz.olson.timezones.
     * eg: "720,1,2".
     *
     * @returns {String}
     */
    lookup_key = function lookup_key() {
      var diff = 0;
      var offsets = get_offsets();

      if (offsets.length > 1) {
        diff = offsets[0] - offsets[1];
      }

      if (offsets.length > 3) {
        return offsets[0] + ',1,weird';
      } else if (diff < 0) {
        return offsets[0] + ',1';
      } else if (diff > 0) {
        return offsets[1] + ',1,' + HEMISPHERE_SOUTH;
      }

      return offsets[0] + ',0';
    },
    /**
     * Tries to get the time zone key directly from the operating system for those
     * environments that support the ECMAScript Internationalization API.
     */
    get_from_internationalization_api = function get_from_internationalization_api() {
      var format, timezone;
      if (
        !Intl ||
        typeof Intl === 'undefined' ||
        typeof Intl.DateTimeFormat === 'undefined'
      ) {
        return;
      }

      format = Intl.DateTimeFormat();

      if (
        typeof format === 'undefined' ||
        typeof format.resolvedOptions === 'undefined'
      ) {
        return;
      }

      timezone = format.resolvedOptions().timeZone;

      if (timezone && (timezone.indexOf('/') > -1 || timezone === 'UTC')) {
        return timezone;
      }
    },
    /**
     * Starting point for getting all the DST rules for a specific year
     * for the current timezone (as described by the client system).
     *
     * Returns an object with start and end attributes, or false if no
     * DST rules were found for the year.
     *
     * @param year
     * @returns {Object} || {Boolean}
     */
    dst_dates = function dst_dates(year) {
      var yearstart = new Date(year, 0, 1, 0, 0, 1, 0).getTime();
      var yearend = new Date(year, 12, 31, 23, 59, 59).getTime();
      var current = yearstart;
      var offset = new Date(current).getTimezoneOffset();
      var dst_start = null;
      var dst_end = null;

      while (current < yearend - 86400000) {
        var dateToCheck = new Date(current);
        var dateToCheckOffset = dateToCheck.getTimezoneOffset();

        if (dateToCheckOffset !== offset) {
          if (dateToCheckOffset < offset) {
            dst_start = dateToCheck;
          }
          if (dateToCheckOffset > offset) {
            dst_end = dateToCheck;
          }
          offset = dateToCheckOffset;
        }

        current += 86400000;
      }

      if (dst_start && dst_end) {
        return {
          s: find_dst_fold(dst_start).getTime(),
          e: find_dst_fold(dst_end).getTime()
        };
      }

      return false;
    },
    /**
     * Probably completely unnecessary function that recursively finds the
     * exact (to the second) time when a DST rule was changed.
     *
     * @param a_date - The candidate Date.
     * @param padding - integer specifying the padding to allow around the candidate
     *                  date for finding the fold.
     * @param iterator - integer specifying how many milliseconds to iterate while
     *                   searching for the fold.
     *
     * @returns {Date}
     */
    find_dst_fold = function find_dst_fold(a_date, padding, iterator) {
      if (typeof padding === 'undefined') {
        padding = consts.DAY;
        iterator = consts.HOUR;
      }

      var date_start = new Date(a_date.getTime() - padding).getTime();
      var date_end = a_date.getTime() + padding;
      var offset = new Date(date_start).getTimezoneOffset();

      var current = date_start;

      var dst_change = null;
      while (current < date_end - iterator) {
        var dateToCheck = new Date(current);
        var dateToCheckOffset = dateToCheck.getTimezoneOffset();

        if (dateToCheckOffset !== offset) {
          dst_change = dateToCheck;
          break;
        }
        current += iterator;
      }

      if (padding === consts.DAY) {
        return find_dst_fold(dst_change, consts.HOUR, consts.MINUTE);
      }

      if (padding === consts.HOUR) {
        return find_dst_fold(dst_change, consts.MINUTE, consts.SECOND);
      }

      return dst_change;
    },
    windows7_adaptations = function windows7_adaptions(
      rule_list,
      preliminary_timezone,
      score,
      sample
    ) {
      if (score != 'N/A') {
        return score;
      }
      if (preliminary_timezone === 'Asia/Beirut') {
        if (sample.name === 'Africa/Cairo') {
          if (
            rule_list[6].s === 1398376800000 &&
            rule_list[6].e === 1411678800000
          ) {
            return 0;
          }
        }
        if (sample.name === 'Asia/Jerusalem') {
          if (
            rule_list[6].s === 1395964800000 &&
            rule_list[6].e === 1411858800000
          ) {
            return 0;
          }
        }
      } else if (preliminary_timezone === 'America/Santiago') {
        if (sample.name === 'America/Asuncion') {
          if (
            rule_list[6].s === 1412481600000 &&
            rule_list[6].e === 1397358000000
          ) {
            return 0;
          }
        }
        if (sample.name === 'America/Campo_Grande') {
          if (
            rule_list[6].s === 1413691200000 &&
            rule_list[6].e === 1392519600000
          ) {
            return 0;
          }
        }
      } else if (preliminary_timezone === 'America/Montevideo') {
        if (sample.name === 'America/Sao_Paulo') {
          if (
            rule_list[6].s === 1413687600000 &&
            rule_list[6].e === 1392516000000
          ) {
            return 0;
          }
        }
      } else if (preliminary_timezone === 'Pacific/Auckland') {
        if (sample.name === 'Pacific/Fiji') {
          if (
            rule_list[6].s === 1414245600000 &&
            rule_list[6].e === 1396101600000
          ) {
            return 0;
          }
        }
      }

      return score;
    },
    /**
     * Takes the DST rules for the current timezone, and proceeds to find matches
     * in the jstz.olson.dst_rules.zones array.
     *
     * Compares samples to the current timezone on a scoring basis.
     *
     * Candidates are ruled immediately if either the candidate or the current zone
     * has a DST rule where the other does not.
     *
     * Candidates are ruled out immediately if the current zone has a rule that is
     * outside the DST scope of the candidate.
     *
     * Candidates are included for scoring if the current zones rules fall within the
     * span of the samples rules.
     *
     * Low score is best, the score is calculated by summing up the differences in DST
     * rules and if the consts.MAX_SCORE is overreached the candidate is ruled out.
     *
     * Yah follow? :)
     *
     * @param rule_list
     * @param preliminary_timezone
     * @returns {*}
     */
    best_dst_match = function best_dst_match(rule_list, preliminary_timezone) {
      var score_sample = function score_sample(sample) {
        var score = 0;

        for (var j = 0; j < rule_list.length; j++) {
          // Both sample and current time zone report DST during the year.
          if (!!sample.rules[j] && !!rule_list[j]) {
            // The current time zone's DST rules are inside the sample's. Include.
            if (
              rule_list[j].s >= sample.rules[j].s &&
              rule_list[j].e <= sample.rules[j].e
            ) {
              score = 0;
              score += Math.abs(rule_list[j].s - sample.rules[j].s);
              score += Math.abs(sample.rules[j].e - rule_list[j].e);

              // The current time zone's DST rules are outside the sample's. Discard.
            } else {
              score = 'N/A';
              break;
            }

            // The max score has been reached. Discard.
            if (score > consts.MAX_SCORE) {
              score = 'N/A';
              break;
            }
          }
        }

        score = windows7_adaptations(
          rule_list,
          preliminary_timezone,
          score,
          sample
        );

        return score;
      };
      var scoreboard = {};
      var dst_zones = jstz.olson.dst_rules.zones;
      var dst_zones_length = dst_zones.length;
      var ambiguities = consts.AMBIGUITIES[preliminary_timezone];

      for (var i = 0; i < dst_zones_length; i++) {
        var sample = dst_zones[i];
        var score = score_sample(dst_zones[i]);

        if (score != 'N/A') {
          scoreboard[sample.name] = score;
        }
      }

      for (var tz in scoreboard) {
        if (scoreboard.hasOwnProperty(tz)) {
          for (var j = 0; j < ambiguities.length; j++) {
            if (ambiguities[j] === tz) {
              return tz;
            }
          }
        }
      }

      return preliminary_timezone;
    },
    /**
     * Takes the preliminary_timezone as detected by lookup_key().
     *
     * Builds up the current timezones DST rules for the years defined
     * in the jstz.olson.dst_rules.years array.
     *
     * If there are no DST occurences for those years, immediately returns
     * the preliminary timezone. Otherwise proceeds and tries to solve
     * ambiguities.
     *
     * @param preliminary_timezone
     * @returns {String} timezone_name
     */
    get_by_dst = function get_by_dst(preliminary_timezone) {
      var get_rules = function get_rules() {
        var rule_list = [];
        for (var i = 0; i < jstz.olson.dst_rules.years.length; i++) {
          var year_rules = dst_dates(jstz.olson.dst_rules.years[i]);
          rule_list.push(year_rules);
        }
        return rule_list;
      };
      var check_has_dst = function check_has_dst(rules) {
        for (var i = 0; i < rules.length; i++) {
          if (rules[i] !== false) {
            return true;
          }
        }
        return false;
      };
      var rules = get_rules();
      var has_dst = check_has_dst(rules);

      if (has_dst) {
        return best_dst_match(rules, preliminary_timezone);
      }

      return preliminary_timezone;
    },
    /**
     * Uses get_timezone_info() to formulate a key to use in the olson.timezones dictionary.
     *
     * Returns an object with one function ".name()"
     *
     * @returns Object
     */
    determine = function determine(using_intl) {
      var preliminary_tz = false;
      var needle = lookup_key();
      if (using_intl || typeof using_intl === 'undefined') {
        preliminary_tz = get_from_internationalization_api();
      }

      if (!preliminary_tz) {
        preliminary_tz = jstz.olson.timezones[needle];

        if (typeof consts.AMBIGUITIES[preliminary_tz] !== 'undefined') {
          preliminary_tz = get_by_dst(preliminary_tz);
        }
      }

      return {
        name: function() {
          return preliminary_tz;
        },
        using_intl: using_intl || typeof using_intl === 'undefined',
        needle: needle,
        offsets: get_offsets()
      };
    };

  return {
    determine: determine
  };
})();

jstz.olson = jstz.olson || {};

/**
 * The keys in this dictionary are comma separated as such:
 *
 * First the offset compared to UTC time in minutes.
 *
 * Then a flag which is 0 if the timezone does not take daylight savings into account and 1 if it
 * does.
 *
 * Thirdly an optional 's' signifies that the timezone is in the southern hemisphere,
 * only interesting for timezones with DST.
 *
 * The mapped arrays is used for constructing the jstz.TimeZone object from within
 * jstz.determine();
 */
jstz.olson.timezones = {
  '-720,0': 'Etc/GMT+12',
  '-660,0': 'Pacific/Pago_Pago',
  '-660,1,s': 'Pacific/Apia', // Why? Because windows... cry!
  '-600,1': 'America/Adak',
  '-600,0': 'Pacific/Honolulu',
  '-570,0': 'Pacific/Marquesas',
  '-540,0': 'Pacific/Gambier',
  '-540,1': 'America/Anchorage',
  '-480,1': 'America/Los_Angeles',
  '-480,0': 'Pacific/Pitcairn',
  '-420,0': 'America/Phoenix',
  '-420,1': 'America/Denver',
  '-360,0': 'America/Guatemala',
  '-360,1': 'America/Chicago',
  '-360,1,s': 'Pacific/Easter',
  '-300,0': 'America/Bogota',
  '-300,1': 'America/New_York',
  '-270,0': 'America/Caracas',
  '-240,1': 'America/Halifax',
  '-240,0': 'America/Santo_Domingo',
  '-240,1,s': 'America/Asuncion',
  '-210,1': 'America/St_Johns',
  '-180,1': 'America/Godthab',
  '-180,0': 'America/Buenos_Aires',
  '-180,1,s': 'America/Montevideo',
  '-120,0': 'America/Noronha',
  '-120,1': 'America/Noronha',
  '-60,1': 'Atlantic/Azores',
  '-60,0': 'Atlantic/Cape_Verde',
  '0,0': 'UTC',
  '0,1': 'Europe/London',
  '0,1,weird': 'Africa/Casablanca',
  '60,1': 'Europe/Berlin',
  '60,0': 'Africa/Lagos',
  '60,1,weird': 'Africa/Casablanca',
  '120,1': 'Asia/Beirut',
  '120,1,weird': 'Africa/Cairo',
  '120,0': 'Africa/Johannesburg',
  '180,0': 'Asia/Baghdad',
  '180,1': 'Europe/Moscow',
  '210,1': 'Asia/Tehran',
  '240,0': 'Asia/Dubai',
  '240,1': 'Asia/Baku',
  '270,0': 'Asia/Kabul',
  '300,1': 'Asia/Yekaterinburg',
  '300,0': 'Asia/Karachi',
  '330,0': 'Asia/Calcutta',
  '345,0': 'Asia/Katmandu',
  '360,0': 'Asia/Dhaka',
  '360,1': 'Asia/Omsk',
  '390,0': 'Asia/Rangoon',
  '420,1': 'Asia/Krasnoyarsk',
  '420,0': 'Asia/Jakarta',
  '480,0': 'Asia/Shanghai',
  '480,1': 'Asia/Irkutsk',
  '525,0': 'Australia/Eucla',
  '525,1,s': 'Australia/Eucla',
  '540,1': 'Asia/Yakutsk',
  '540,0': 'Asia/Tokyo',
  '570,0': 'Australia/Darwin',
  '570,1,s': 'Australia/Adelaide',
  '600,0': 'Australia/Brisbane',
  '600,1': 'Asia/Vladivostok',
  '600,1,s': 'Australia/Sydney',
  '630,1,s': 'Australia/Lord_Howe',
  '660,1': 'Asia/Kamchatka',
  '660,0': 'Pacific/Noumea',
  '690,0': 'Pacific/Norfolk',
  '720,1,s': 'Pacific/Auckland',
  '720,0': 'Pacific/Majuro',
  '765,1,s': 'Pacific/Chatham',
  '780,0': 'Pacific/Tongatapu',
  '780,1,s': 'Pacific/Apia',
  '840,0': 'Pacific/Kiritimati'
};

export default jstz;
