import { VContainer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"pa0 info-container",class:{
    'info-container__free': !_vm.isPaid,
    'info-container__upsell': _vm.userLoggedIn && !_vm.isPaid,
    'info-container__lout': !_vm.userLoggedIn
  },attrs:{"data-test-id":"info-container"}},[(!_vm.hideMarketing)?[(_vm.isPaid)?_c('h1',{directives:[{name:"translate",rawName:"v-translate"}],key:"p",staticClass:"srtext--text"},[_vm._v("\n      Legally binding digital signatures\n    ")]):(_vm.userLoggedIn)?_c('h1',{directives:[{name:"translate",rawName:"v-translate"}],key:"f",staticClass:"srtext--text"},[_vm._v("\n      Use SignRequest to its full potential\n    ")]):_c('h1',{key:"a",staticClass:"srtext--text"},[_vm._v("\n      "+_vm._s(_vm.$gettext('Boost efficiency with reliable e-signing').replace(
          '-',
          '\u2011'
        ))+"\n    ")]),(_vm.isPaid)?_c('h2',{directives:[{name:"translate",rawName:"v-translate"}],key:"p2",staticClass:"primary--text"},[_vm._v("\n      "+_vm._s(_vm.$gettext('Boost efficiency with reliable e-signing').replace(
          '-',
          '\u2011'
        ))+"\n    ")]):(_vm.userLoggedIn)?_c('h2',{directives:[{name:"translate",rawName:"v-translate"}],key:"f2",staticClass:"primary--text"},[_vm._v("\n      Upgrade to a Professional or Business plan today\n    ")]):_vm._e(),(_vm.isPaid)?_c('h3',{key:"p3"},[_c('a',{attrs:{"href":_vm.getPageUrlForLang('features')}},[_vm._v("\n        See all SignRequest features →\n      ")])]):_vm._e(),(!_vm.$isMobile)?[(!_vm.isPaid && _vm.userLoggedIn)?_c('Features'):(!_vm.userLoggedIn)?_c('Reasons'):_vm._e()]:_vm._e(),_c('div',[(!_vm.isPaid && !_vm.$isMobile)?_c('free-trial',{staticClass:"info-container--trial"}):_vm._e(),(!_vm.isPaid && !_vm.$isMobile)?_c('sr-button',{key:"h",staticClass:"info-container--howto",attrs:{"href":_vm.getPageUrlForLang('how-it-works'),"sr-style":"flat-white","round":"","border":false}},[_c('span',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("How it works")])]):_vm._e()],1)]:_vm._e(),_c('img',{staticClass:"info-container--illustration",attrs:{"src":require('./dude.svg'),"alt":_vm.$gettext('Signing illustration')}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }