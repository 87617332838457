import angular from 'angular';

/**
 * @ngdoc directive
 * @name frontendApp.directive:match
 * @description
 * # match
 */
angular
  .module('frontendApp')
  .directive('match', [
    function() {
      return {
        require: 'ngModel',
        restrict: 'A',
        scope: {
          match: '='
        },
        link: function(scope, elem, attrs, ctrl) {
          scope.$watch(
            function() {
              return (
                (ctrl.$pristine && angular.isUndefined(ctrl.$modelValue)) ||
                scope.match === ctrl.$modelValue
              );
            },
            function(currentValue) {
              ctrl.$setValidity('match', currentValue);
            }
          );
        }
      };
    }
  ])
  .directive('passwordStrength', [
    'utils',
    function(utils) {
      return {
        require: 'ngModel',
        restrict: 'A',
        link: function(scope, elem, attrs, ctrl) {
          ctrl.$validators.passwordStrength = function(modelValue, viewValue) {
            if (ctrl.$isEmpty(modelValue)) {
              // consider empty models to be valid (as we always use this together with required)
              return true;
            }
            return utils.PASSWORD_STRENGTH_REGEX.test(viewValue);
          };
        }
      };
    }
  ]);
