import angular from 'angular';
/**
 * @author Slawomir Zytko <slawek@amsterdam-standard.pl>
 * @copyright Amsterdam Standard Sp. Z o.o.
 */
(function() {
  'use strict';

  angular.module('frontendApp').factory('validation', [
    function() {
      var Validation = function() {
        /**
         * Validates email using Regexp
         * @param value
         * @returns {boolean}
         */
        this.email = function(value) {
          var regexp = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
          return regexp.test(value);
        };
      };

      return new Validation();
    }
  ]);
})();
