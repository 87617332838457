<template>
  <div class="sr-combobox-chip--chip-items">
    <span
      v-for="(item, index) in value"
      :key="index"
      class="sr-combobox-chip--chip sr-combobox-chip--chip-removable"
      :class="{ 'sr-combobox-chip--chip-removable': removable }"
    >
      <span class="sr-combobox-chip--chip-content">
        <slot name="chip" :item="item">
          {{ item }}
        </slot>
        <sr-button
          v-if="removable"
          circle
          round
          :border="false"
          @click="handleDelete(index)"
        >
          <div class="sr-combobox-chip--chip-remove">
            <i
              aria-hidden="true"
              class="sr-combobox-chip--chip-remove-icon material-icons"
            >
              cancel
            </i>
          </div>
        </sr-button>
      </span>
    </span>
  </div>
</template>
<script>
import SrButton from '@/components/elements/SrButton';
export default {
  components: { SrButton },
  props: {
    value: {
      type: Array,
      required: false,
      default: null
    },
    removable: { type: Boolean, required: false, default: false }
  },
  methods: {
    handleDelete(index) {
      this.$emit('delete', index);
    }
  }
};
</script>
<style scoped>
.sr-combobox-chip--chip-items {
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  line-height: 18px;
}
.sr-combobox-chip--chip {
  height: 24px;
  margin: 4px 4px 4px 0;
  align-items: center;
  border-radius: 28px;
  display: inline-flex;
  font-size: 13px;
  margin: 4px 4px 4px 0;
  outline: none;
  position: relative;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  vertical-align: middle;
  background: #e0e0e0;
}
.sr-combobox-chip--chip-content {
  cursor: default;
  height: 32px;
  justify-content: space-between;
  padding: 0 12px;
  white-space: nowrap;
  z-index: 1;
  align-items: center;
  border-radius: 28px;
  display: inline-flex;
  vertical-align: middle;
}
.sr-combobox-chip--chip-removable .sr-combobox-chip--chip-content {
  padding: 0 4px 0 12px;
}
.sr-combobox-chip--chip-removable .sr-combobox-chip--chip-content > button,
.sr-combobox-chip--chip-removable
  .sr-combobox-chip--chip-content
  > button:hover::before {
  background-color: transparent !important;
  color: unset !important;
}
.sr-combobox-chip--chip-remove {
  align-items: center;
  display: flex;
  font-size: 20px;
  text-decoration: none;
  user-select: none;
}
.sr-combobox-chip--chip-remove-icon {
  font-size: 20px;
  cursor: pointer;
  opacity: 0.5;
}
</style>
