import angular from 'angular';
/**
 * @author Slawomir Zytko <slawek@amsterdam-standard.pl>
 * @copyright Amsterdam Standard Sp. Z o.o.
 */
angular.module('frontendApp').factory('accountUpload', accountUpload);

accountUpload.$inject = ['$resource'];

function accountUpload($resource) {
  return $resource(
    '/user/auth/upload-status/:result_id',
    {
      result_id: '@result_id'
    },
    {
      getStatus: {
        isArray: false,
        method: 'GET'
      }
    }
  );
}
