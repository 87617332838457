<template>
  <v-layout class="mr-0 payment-method-placeholder">
    <sr-button
      v-show="paymentMethod === 'card'"
      class="payment-method-button"
      :tabindex="-1"
      @click="changeMethod('sepa')"
    >
      <span>
        <i class="fa fa-arrow-right" aria-hidden="true"></i>
        <translate>Pay with direct debit</translate>
      </span>
    </sr-button>
    <sr-button
      v-show="paymentMethod === 'sepa'"
      class="payment-method-button"
      @click="changeMethod('card')"
    >
      <span>
        <i class="fa fa-arrow-right" aria-hidden="true"></i>
        <translate>Pay with credit card</translate>
      </span>
    </sr-button>
  </v-layout>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import SrButton from '@/components/elements/SrButton';
export default {
  components: {
    SrButton
  },
  computed: {
    ...mapState('subscription', ['paymentMethod'])
  },
  methods: {
    ...mapActions('subscription', ['changePaymentMethod']),
    changeMethod(method) {
      this.changePaymentMethod({ method: method });
    }
  }
};
</script>
<style scoped lang="scss">
.payment-method-placeholder {
  flex-grow: 0;
}
.payment-method-button {
  width: 100%;
  margin-left: auto;
  font-size: 15px;
  font-weight: normal;
  letter-spacing: 0.25px;
  line-height: normal;
  text-align: right;
  text-decoration: underline;
  box-shadow: none !important;
  background-color: transparent !important;
  padding: 0;

  i {
    -webkit-text-stroke: 2px white;
  }

  span {
    color: #3670e1 !important;
    white-space: normal;
  }
}

.payment-method:hover {
  text-decoration: underline;
  background-color: transparent !important;
}
</style>
