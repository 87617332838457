import angular from 'angular';

/**
 * @ngdoc service
 * @name frontendApp.deviceService
 * @description
 * # confService
 * Service in the frontendApp.
 */
angular.module('frontendApp').service('deviceService', [
  'screenSize',
  function(screenSize) {
    // see https://github.com/jacopotarantino/angular-match-media
    screenSize.rules = {
      // defaults from angular-match-media
      lg: '(min-width: 1200px)',
      md: '(min-width: 992px) and (max-width: 1199px)',
      sm: '(min-width: 768px) and (max-width: 991px)',
      xs: '(max-width: 767px)',
      retina:
        'only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx)',
      // these breakpoints we use in css
      palm: 'screen and (max-width: 767px)',
      lap: 'screen and (min-width: 768px) and (max-width: 959px)',
      lap_and_down: 'screen and (max-width: 959px)',
      lap_and_up: 'screen and (min-width: 768px)',
      desk: 'screen and (min-width: 960px)',
      desk_wide: 'screen and (min-width: 1200px)'
    };

    var isMobileDevice = function() {
      var isMobileDevice = this;
      this.getUserAgent = function() {
        return navigator.userAgent;
      };
      this.Android = function() {
        return (
          /Android/i.test(isMobileDevice.getUserAgent()) &&
          !isMobileDevice.Windows()
        );
      };
      this.BlackBerry = function() {
        return /BlackBerry|BB10|PlayBook/i.test(isMobileDevice.getUserAgent());
      };
      this.iPhone = function() {
        return (
          /iPhone/i.test(isMobileDevice.getUserAgent()) &&
          !isMobileDevice.iPad() &&
          !isMobileDevice.Windows()
        );
      };
      this.iPod = function() {
        return /iPod/i.test(isMobileDevice.getUserAgent());
      };
      this.iPad = function() {
        return /iPad/i.test(isMobileDevice.getUserAgent());
      };
      this.iOS = function() {
        return (
          isMobileDevice.iPad() ||
          isMobileDevice.iPod() ||
          isMobileDevice.iPhone()
        );
      };
      this.Opera = function() {
        return /Opera Mini/i.test(isMobileDevice.getUserAgent());
      };
      this.Windows = function() {
        return /Windows Phone|IEMobile|WPDesktop/i.test(
          isMobileDevice.getUserAgent()
        );
      };
      this.KindleFire = function() {
        return /Kindle Fire|Silk|KFAPWA|KFSOWI|KFJWA|KFJWI|KFAPWI|KFAPWI|KFOT|KFTT|KFTHWI|KFTHWA|KFASWI|KFTBWI|KFMEWI|KFFOWI|KFSAWA|KFSAWI|KFARWI/i.test(
          isMobileDevice.getUserAgent()
        );
      };
      this.any = function() {
        return (
          isMobileDevice.Android() ||
          isMobileDevice.BlackBerry() ||
          isMobileDevice.iOS() ||
          isMobileDevice.Opera() ||
          isMobileDevice.Windows()
        );
      };
    };

    // Using dynamic method `on`, which will set the variables initially and then update the variable on window resize
    var service = {
      is_desktop: screenSize.on('md, lg', function(match) {
        service.is_desktop = match;
      }),
      is_mobile: screenSize.on('xs, sm', function(match) {
        service.is_mobile = match;
      }),
      is_palm: screenSize.on('palm', function(match) {
        service.is_palm = match;
      }),
      is_retina: screenSize.on('retina', function(match) {
        service.is_retina = match;
      }),
      screenSize: screenSize,
      isMobileDevice: new isMobileDevice()
    };

    return service;
  }
]);
