import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileAction } from 'vuetify/lib/components/VList';
import { VListTileAvatar } from 'vuetify/lib/components/VList';
import { VListTileContent } from 'vuetify/lib/components/VList';
import { VListTileSubTitle } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("Two-Factor Authentication")]),(_vm.hasTokenGeneratorDevice)?_c('p',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("\n    Tokens will be generated by your token generator.\n  ")]):_vm._e(),(_vm.user.has_2fa)?[_c('SrButton',{staticClass:"ml-0",attrs:{"sr-style":"outline","small":""},on:{"click":function($event){return _vm.openEnableTwoFactorAuthModal({ add_only: true, step: 'backup' })}}},[_c('translate',[_vm._v("Backup tokens")])],1),_c(VList,[_vm._l((_vm.user.two_factor.devices),function(device){return [_c(VListTile,{key:device.uuid,staticClass:"py-2"},[_c(VListTileAvatar,[_c('span',{staticClass:"fa icon--32",class:[
                device.device_type === 'generator' ? 'fa-mobile' : 'fa-phone'
              ]})]),_c(VListTileContent,[(device.device_type === 'phone')?_c(VListTileTitle,[_c('translate',[_vm._v("Phone")]),_c('Space'),_c('span',[_vm._v(_vm._s(device.number))])],1):(device.device_type === 'generator')?_c(VListTileTitle,{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("\n              Token Generator\n            ")]):_vm._e(),_c(VListTileSubTitle,[_c('translate',[_vm._v("Last used:")]),(device.active)?_c('translate',[_vm._v("Active now")]):[_vm._v("\n                "+_vm._s(_vm._f("datetime")(device.last_usage))+"\n              ")]],2)],1),_c(VListTileAction,[_c('SrButton',{attrs:{"sr-style":"danger","small":"","loading":_vm.loading},on:{"click":function($event){return _vm.confirmDeviceDelete(device.device_type, device.uuid)}}},[_c('translate',[_vm._v("Delete")])],1)],1)],1)]})],2),_c(VLayout,{attrs:{"mt-4":""}},[_c(VFlex,[_c('SrButton',{attrs:{"small":""},on:{"click":function($event){return _vm.openEnableTwoFactorAuthModal({ add_only: true })}}},[_c('translate',[_vm._v("Add Two-Factor Authentication Device")])],1)],1),_c(VSpacer),_c(VFlex,{attrs:{"shrink":""}},[_c('SrButton',{attrs:{"small":"","sr-style":"danger"},on:{"click":_vm.confirmTwoFactorAuthDisable}},[_c('translate',[_vm._v("Disable Two-factor Authentication")])],1)],1)],1)]:_c('div',[_c(VLayout,{attrs:{"wrap":""}},[_c(VFlex,{attrs:{"xs12":""}},[_c('p',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("\n          Two-factor authentication is not enabled for your account. Enable\n          two-factor authentication for enhanced account security.\n        ")])])],1),_c(VLayout,{attrs:{"justify-center":""}},[_c('SrButton',{on:{"click":_vm.openEnableTwoFactorAuthModal}},[_c('translate',[_vm._v("Enable Two-Factor Authentication")])],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }