<template>
  <sr-field
    :label="label"
    :label-for="uid"
    :help="help"
    :is-empty="!value && !base"
    :horizontal="horizontal"
    :has-placeholder="Boolean(placeholder)"
    :validations="(displayError && validations) || null"
    :class="{ 'scrollbar-auto': scrollbar }"
  >
    <input
      v-if="element === 'input'"
      :id="uid"
      ref="input"
      class="sr-input--element srtext--text"
      :name="name"
      :value="value"
      :disabled="disabled"
      :type="type"
      :placeholder="placeholder"
      v-bind="$attrs"
      :readonly="readonly"
      :aria-describedby="ariaDescribeBy"
      @input="handleInput"
      @focus="onFocus($event)"
      @blur="onBlur($event)"
      @keypress="$emit('keypress', $event)"
      @keydown="$emit('keydown', $event)"
    />
    <textarea
      v-if="element === 'textarea'"
      :id="uid"
      ref="input"
      class="sr-input--element srtext--text"
      :name="name"
      :value="value"
      :disabled="disabled"
      :type="type"
      :placeholder="placeholder"
      v-bind="$attrs"
      :readonly="readonly"
      :aria-describedby="ariaDescribeBy"
      @input="handleInput"
      @focus="onFocus($event)"
      @blur="onBlur($event)"
      @keypress="$emit('keypress', $event)"
      @keydown="$emit('keydown', $event)"
    />
    <template v-slot:label>
      <slot name="label" />
    </template>
    <template v-slot:append-prefix>
      <slot name="append-prefix" />
    </template>
    <template v-slot:append-outer>
      <slot name="append-outer" />
    </template>
    <template v-slot:append-inner>
      <slot name="append-inner" />
    </template>
  </sr-field>
</template>
<script>
import SrField from './SrField';
import validatable from 'vuetify/lib/mixins/validatable';
import uniqueId from '@/mixins/uniqueId';

export default {
  components: { SrField },
  mixins: [validatable, uniqueId],
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    label: { type: String, default: '' },
    help: { type: String, default: '' },
    element: { type: String, default: 'input' },
    placeholder: { type: String, default: '' },
    value: { type: [String, Number], default: '' },
    name: { type: String, required: true },
    type: { type: String, default: 'text' },
    base: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    horizontal: { type: Boolean, default: false },
    minHeight: { type: Number, default: 0 },
    maxHeight: { type: Number, default: Infinity },
    scrollbar: { type: Boolean, default: false },
    suppressEmptyError: { type: Boolean, default: false },
    suppressFirstError: { type: Boolean, default: false }
  },
  data() {
    return {
      isFirstFocus: true
    };
  },
  computed: {
    ariaDescribeBy() {
      return (
        (this.displayError && this.validations && `${this.uid}--error`) || null
      );
    },
    displayError() {
      if (this.$props.suppressFirstError && this.isFirstFocus) {
        return false;
      }
      if (this.$props.suppressEmptyError && !this.value) {
        return false;
      }

      return this.hasError && this.hasState;
    }
  },
  updated() {
    this.autoresize();
  },
  methods: {
    onFocus($event) {
      if (!this.value) {
        this.isFirstFocus = true;
      }

      this.isFocused = true;
      this.$emit('focus', $event);
    },
    onBlur($event) {
      this.isFocused = false;
      this.isFirstFocus = false;
      this.$emit('blur', $event);
    },
    handleInput(event) {
      if (!this.$props.readonly) {
        this.$emit('input', event.target.value);
      }
      this.$nextTick(() => this.autoresize());
    },
    autoresize() {
      const { input } = this.$refs;
      input.style.minHeight = 'auto';
      input.style.minHeight =
        Math.min(Math.max(input.scrollHeight, this.minHeight), this.maxHeight) +
        'px';
    },
    focus() {
      this.$refs.input.focus();
    }
  }
};
</script>
<style scoped>
.sr-input--element {
  font-size: 16px;
  font-weight: normal;
  -webkit-appearance: none;
  letter-spacing: 0.27px;
  border: none;
  box-shadow: none;
}

.sr-field--horizontal .sr-input--element {
  border: 1px solid rgba(214, 214, 214, 0.84);
  padding: 26px 14px;
}
.sr-field--vertical textarea.sr-input--element {
  flex-basis: auto;
}

.sr-field--horizontal textarea.sr-input--element {
  overflow: hidden;
  overflow-wrap: break-word;
  resize: none;
  min-height: 94px;
  line-height: initial;
  transition: min-height 0.3s;
}
textarea.sr-input--element {
  margin-bottom: 1em;
}
.signrequest-lib textarea.sr-input--element {
  margin-bottom: unset;
}

.scrollbar-auto textarea {
  overflow: auto;
}

.sr-input--element:focus {
  outline: none;
}
input.sr-input--element[type='number'] {
  max-width: calc(100% - 1.5rem);
}
.sr-input--element::placeholder {
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  opacity: 0.8;
}
.sr-field--horizontal input.sr-input--element {
  max-height: 19px;
}
.sr-field--horizontal ::v-deep label {
  margin-top: 1rem;
}
</style>
