<template>
  <div class="payment-method-form">
    <v-layout row>
      <v-flex xs12>
        <PaymentInput
          :label="$gettext('IBAN')"
          :element="stripeIBAN"
          @element-completed="elementCompleted"
          @stripeInputError="stripeIBANError = $event"
          @input="elementInputEvent"
        ></PaymentInput>
      </v-flex>
    </v-layout>
    <v-layout class="subscribe-errors">
      <v-flex v-for="accountError in accountErrors" :key="accountError" xs12>
        <span class="text--error ml-1">
          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
          {{ accountError }}
        </span>
      </v-flex>
      <v-flex v-if="stripeIBANError">
        <span class="text--error ml-1">
          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
          {{ stripeIBANError }}
        </span>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import PaymentInput from '@/components/subscribe/PaymentInput';

export default {
  components: { PaymentInput },
  props: {
    fontSize: { type: String, default: '14px' }
  },
  data() {
    return {
      stripeIBAN: null,
      stripeIBANCompleted: false,
      accountErrors: [],
      stripeIBANError: null
    };
  },
  computed: {
    ...mapState('subscription', ['plan', 'billingDetails']),
    ...mapState('users', ['user']),
    ...mapGetters('conf', ['countryISOCode']),
    ...mapGetters('subscription', [
      'stripeTokenErrorMessage',
      'subscriptionErrorMessage'
    ])
  },
  watch: {
    stripeTokenErrorMessage(message) {
      if (message) this.accountErrors.push(this.$gettext(message));
    },
    subscriptionErrorMessage(message) {
      if (message) this.accountErrors.push(this.$gettext(message));
    },
    billingDetails() {
      this.stripeIBAN.update({
        placeholderCountry: this.billingDetails.details.country
      });
    }
  },
  mounted: function() {
    this.mountStripeElements();
  },
  methods: {
    ...mapActions('subscription', [
      'changeStripeElement',
      'changeStripeElementsInputCompleted',
      'changeSourceData',
      'getStripe'
    ]),
    mountStripeElements: async function() {
      const stripe = await this.getStripe();
      const elements = stripe.elements();
      const style = {
        base: {
          fontSize: this.fontSize,
          fontFamily: '"Lato", "Open Sans", Helvetica, Arial, sans-serif'
        }
      };
      const options = {
        style,
        supportedCountries: ['SEPA'],
        placeholderCountry: this.billingDetails.details.country
      };
      const iban = elements.create('iban', options);
      this.stripeIBAN = iban;
    },
    elementCompleted() {
      const sourceData = {
        type: 'sepa_debit',
        account_holder_name: this.user.full_name,
        currency: this.plan.currency.code,

        owner: {
          name: this.user.full_name,
          email: this.user.email
        },
        mandate: {
          // Automatically send a mandate notification email to your customer
          // once the source is charged.
          notification_method: 'email'
        }
      };
      this.changeStripeElement({ element: Object.freeze(this.stripeIBAN) });
      this.changeSourceData({ data: sourceData });
      this.changeStripeElementsInputCompleted({ completed: true });
    },
    displayInputError(errorMessage) {
      if (!this.accountErrors.includes(errorMessage))
        this.accountErrors.push(errorMessage);
    },
    elementInputEvent() {
      this.accountErrors = [];
    }
  }
};
</script>
