<template>
  <div data-test-id="sandbox">
    <h2 v-translate>Sandbox settings</h2>
    <p v-translate>
      This team is in sandbox mode. Sandbox teams allow you to test out the
      entire SignRequest product without incurring charges. Everything works the
      same as a paid plan, except that all documents will be watermarked.
    </p>
    <p v-if="readonly" v-translate>
      <em translate>
        You must be an owner or admin of the team in order to convert this to a
        production team.
      </em>
    </p>
    <div v-else class="align--right">
      <sr-button
        :loading="loading"
        data-test-id="sandbox-off"
        @click="handleClick"
      >
        <translate>Convert to production team</translate>
      </sr-button>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import SrButton from '@/components/elements/SrButton';

export default {
  components: { SrButton },
  props: {
    readonly: { type: Boolean, default: false },
    loading: { type: Boolean, default: false }
  },
  methods: {
    ...mapActions('modals', ['openConfirmationModal']),
    async handleClick() {
      const confirmed = await this.openConfirmationModal({
        title: this.$gettext(
          'Are you sure you want to convert this team to a production team?'
        ),
        body: this.$gettext(
          'Watermarks will no longer be added to new documents and your activities will begin to incur charges. This cannot be undone.'
        )
      });
      if (!confirmed) {
        return;
      }
      this.$emit('save');
    }
  }
};
</script>
<style scoped>
h2 {
  margin: 0;
}
.sr-button {
  margin: 1rem 0;
}
</style>
