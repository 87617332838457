const URL_PARAMS_RE = /:[a-zA-Z]+/g;
function makeURL(templateURL, params) {
  return templateURL.replace(URL_PARAMS_RE, function(match) {
    const key = match.slice(1);
    const value = params[key];
    delete params[key];
    return value;
  });
}

export function request(method, url) {
  const isTemplateUrl = url.includes(':');
  return async function sendRequest({ dispatch }, params) {
    const ret = await dispatch(
      'api/makeRequest',
      {
        method,
        url: isTemplateUrl ? makeURL(url, params) : url,
        ...params
      },
      { root: true }
    );
    return ret || {};
  };
}

export function requestWithParams(method, url, extraParams) {
  const isTemplateUrl = url.includes(':');
  return async function sendRequest({ dispatch }, params) {
    const ret = await dispatch(
      'api/makeRequest',
      {
        method,
        url: isTemplateUrl ? makeURL(url, params) : url,
        ...extraParams
      },
      { root: true }
    );
    return ret || {};
  };
}
