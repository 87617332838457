<template>
  <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
    <g stroke="currentColor" stroke-width="1.2" fill="none" fill-rule="evenodd">
      <rect x="10.6" y="10.6" width="17.8" height="17.8" rx="2"></rect>
      <polyline
        v-show="checked"
        stroke-linecap="round"
        stroke-linejoin="round"
        points="25 16.5 18.125 23.5 15 20.31818182"
      ></polyline>
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    checked: { type: Boolean, required: true }
  }
};
</script>
<style scoped>
svg {
  height: 100%;
  display: block;
}
</style>
