<template>
  <div data-test-id="api-settings">
    <h2 v-translate>API Settings</h2>

    <span v-if="disabled" key="d" v-translate>
      You do not have a team activated.
    </span>

    <p>
      <translate>
        If you are using our API to integrate and automate SignRequest with your
        software you can change settings here. For more information about the
        API:
      </translate>
      <a v-translate rel="noopener noreferrer" href="/api/v1/docs/">
        visit the developers documentation
      </a>
    </p>

    <div v-if="readonly">
      <p>
        <em v-translate>
          You must be an owner or admin of the team in order to change API
          settings
        </em>
      </p>
    </div>
    <div v-else class="sr-layout">
      <form
        name="apiSettingsForm"
        class="form sr-layout__item one-whole"
        @submit.prevent="$emit('save')"
      >
        <sr-input
          v-model="events_callback_url"
          horizontal
          name="events_callback_url"
          :label="$gettext('Events callback url')"
          :placeholder="
            $gettext(
              'E.g. https://www.your_domain.com/signrequest-event-receiver/'
            )
          "
        >
          <template v-slot:append-outer>
            <sr-button :loading="loading" class="form--save" type="submit">
              <translate>Save</translate>
            </sr-button>
          </template>
        </sr-input>
      </form>
      <form
        name="createApiToken"
        class="form sr-layout__item one-whole"
        @submit.prevent="handleCreateToken"
      >
        <sr-input
          v-model="token_name"
          horizontal
          name="token_name"
          :label="$gettext('API Tokens')"
          :placeholder="$gettext('Token name (optional)')"
        >
          <template v-slot:append-outer>
            <sr-button class="token--create" type="submit">
              <translate>Create token</translate>
            </sr-button>
          </template>
        </sr-input>
      </form>
      <div class="token--list sr-layout__item">
        <div v-for="(token, idx) of tokens" :key="idx" class="token--item">
          <div class="token--name srtext--text">
            {{ token.name || $gettext('Unnamed') }}
          </div>
          <div>{{ token.key }}</div>
          <sr-button
            sr-style="danger"
            data-test-id="remove-token"
            @click="handleRemove(token)"
          >
            <translate>Remove</translate>
          </sr-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import makeFields from '@/utils/makeFields';

import SrButton from '@/components/elements/SrButton';
import SrInput from '@/components/elements/SrInput';

export default {
  components: { SrButton, SrInput },
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: { type: Object, required: true },
    loading: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false }
  },
  data() {
    return { token_name: '' };
  },
  computed: {
    ...makeFields('events_callback_url', 'tokens')
  },
  methods: {
    ...mapActions('modals', ['openConfirmationModal']),
    ...mapActions('users', ['deleteApiToken', 'createApiToken']),
    async handleRemove(token) {
      const confirmed = await this.openConfirmationModal({
        confirmationButtonText: this.$gettext('Remove'),
        confirmationButtonColor: 'error'
      });
      if (!confirmed) {
        return;
      }
      this.deleteApiToken(token.key);
    },
    async handleCreateToken() {
      const isOk = await this.createApiToken(this.token_name);
      if (isOk) {
        this.token_name = '';
      }
    }
  }
};
</script>
<style scoped>
.sr-button {
  margin: 1rem 0;
}
.sr-button.form--save,
.sr-button.token--create {
  margin-top: 10px;
}
.token--item {
  padding: 1rem 0;
  position: relative;
  display: flex;
  align-items: center;
}
.token--item > div {
  padding: 0 1rem;
}
.token--name {
  font-weight: normal;
}
.ltr .token--name {
  padding-left: 0;
}
.rtl .token--name {
  padding-right: 0;
}
.ltr .form--save,
.ltr .token--create,
.ltr .token--item button {
  margin-left: auto;
}
.rtl .form--save,
.rtl .token--create,
.rtl .token--item button {
  margin-right: auto;
}
.token--item::after {
  content: ' ';
  border-bottom: 1px solid #ddd;
  transform: scaleY(0.5);
  width: 100%;
  display: block;
  bottom: 0;
  position: absolute;
}
p {
  margin: 2rem 0;
}
</style>
