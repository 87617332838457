import angular from 'angular';
import _ from 'lodash';
/**
 * @author Slawomir Zytko <slawek@amsterdam-standard.pl>
 * @copyright Amsterdam Standard Sp. Z o.o.
 */
(function() {
  'use strict';

  angular
    .module('frontendApp')
    .factory('feedbackService', FeedbackServiceFactory);

  FeedbackServiceFactory.$inject = ['feedback', 'validation'];

  function FeedbackServiceFactory(Feedback, Validation) {
    var InvalidFormError = function(errors) {
      this.errors = errors;
    };

    /**
     *
     * @param model   Scope model
     * @constructor
     */
    var FeedbackService = function(model) {
      this.model = model;
    };

    /**
     *
     * @returns {boolean}
     */
    FeedbackService.prototype.validate = function() {
      var errors = {
        content: !(
          angular.isUndefined(this.model.content) ||
          this.model.content.length == 0
        ),
        contact:
          !(
            angular.isUndefined(this.model.contact) ||
            this.model.contact.length == 0
          ) && Validation.email(this.model.contact)
      };

      if (
        _.find(errors, function(v) {
          return v === false;
        }) === false
      ) {
        throw new InvalidFormError(errors);
      }

      return true;
    };

    /**
     * @throws InvalidFormError
     * @returns {$promise|*|B.$promise}
     */
    FeedbackService.prototype.send = function() {
      this.validate();
      return Feedback.send({}, this.model).$promise;
    };

    return FeedbackService;
  }
})();
