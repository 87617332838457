<template>
  <v-form
    ref="form"
    v-model="formValid"
    data-test-id="signrequest-form"
    :class="{ 'form-show-help': showHelp, 'form-disabled': copy }"
    @submit.prevent="handleSubmit"
  >
    <sr-drop-capture
      v-model="srDragging"
      data-test-id="drop-capture"
      @files="handleFiles"
    >
      <homebox-screen @click="handleClick">
        <template v-slot:title>
          <files-to-sign
            ref="fileButton"
            v-slot:title
            :show-demo="!userLoggedIn"
            :sr-dragging="srDragging"
            :can-upload-more="canUploadMore"
            :readonly="template"
            @click:sf="$emit('showSalesforce')"
            @click:templates="$emit('showTemplates')"
            @click:settings="$emit('showSettings')"
          />
          <p v-if="hasMisplacedPlaceholder" key="m" class="small">
            <translate>
              It looks like some of the fields might not be assigned to the
              right contact. Would you like to reassign these fields
              automatically?
            </translate>
            <sr-button
              inline
              sr-style="link"
              @click="autoFixPlaceholderIndexes"
            >
              Click here
            </sr-button>
            .
          </p>
          <p v-if="copy" key="c" class="small">
            <translate>
              Settings for this document will be copied from the first one. If
              you want separate settings for this document
            </translate>
            <sr-button inline sr-style="link" @click="copy = false">
              click here
            </sr-button>
            .
          </p>
        </template>

        <div class="form-fields">
          <contact-selection
            key="signers"
            v-model="signers"
            class="h-section"
            :selected-signer="selectedSigner"
            :disabled="copy"
            :edit-template="template"
            @click:details="$emit('showDetails', $event)"
          />

          <div
            class="h-section h-section--textarea h-section--focusable srtext--text"
          >
            <v-textarea
              v-model="message"
              name="message"
              :placeholder="$gettext('Message')"
              :disabled="copy"
              :hint="
                $gettext(
                  'Optionally add a message to include in the email to your contacts.'
                )
              "
            >
              <template v-slot:append-outer>
                <help-hint>
                  <translate>
                    Optionally add a message to include in the email to your
                    contacts.
                  </translate>
                </help-hint>
              </template>
            </v-textarea>
          </div>
        </div>

        <template v-slot:buttons>
          <sr-button
            round
            sr-style="link"
            class="small"
            @click.stop="showHelp = !showHelp"
          >
            <translate>Help</translate>
          </sr-button>
          <sr-button
            round
            type="submit"
            sr-style="fancy"
            :disabled="(formDisabled || !formValid) && !template"
            :loading="isCreating"
          >
            <translate v-if="isBulkSend" key="b">
              Bulk Send
            </translate>
            <translate v-else-if="template" key="t">
              Save
            </translate>
            <translate
              v-else-if="isLast && senderNeedsToSign && othersNeedToSign"
              key="mos"
            >
              Sign and send
            </translate>
            <translate v-else-if="isLast && othersNeedToSign" key="os">
              Send
            </translate>
            <translate v-else-if="isLast" key="ms">Sign</translate>
            <translate v-else key="n">Next</translate>
          </sr-button>
          <sr-button
            round
            class="small"
            sr-style="link"
            data-test-id="settings"
            :disabled="copy"
            @click.stop="$emit('showSettings')"
          >
            <translate>Settings</translate>
          </sr-button>
        </template>
      </homebox-screen>
    </sr-drop-capture>
  </v-form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { mapModel } from '@/utils/vuexExtra';

import permissions from '@/mixins/permissions';
import makeFields from '@/utils/makeFields';
import SrDropCapture from '../elements/SrDropCapture';
import HomeboxScreen from './HomeboxScreen';
import FilesToSign from './FilesToSign';
import ContactSelection from './ContactSelection';
import HelpHint from './HelpHint';

export default {
  components: {
    SrDropCapture,
    FilesToSign,
    ContactSelection,
    HomeboxScreen,
    HelpHint
  },
  mixins: [permissions],
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: { type: Object, required: true },
    template: { type: Boolean, default: false },
    isBulkSend: { type: Boolean, default: false },
    formDisabled: { type: Boolean, default: false },
    selectedSigner: { type: Number, default: -1 }
  },
  data() {
    return {
      formValid: true,
      srDragging: false
    };
  },
  computed: {
    ...mapGetters('users', ['userLoggedIn', 'canUploadManyFiles']),
    ...mapGetters('conf', ['getPageUrlForLang']),
    ...mapGetters('signrequest', [
      'isCreating',
      'hasFile',
      'isLast',
      'hasMisplacedPlaceholder',
      'senderNeedsToSign',
      'othersNeedToSign',
      'allIsPermittedForDocument'
    ]),
    ...mapModel('conf', 'showHelp', 'updateShowHelp'),
    ...makeFields('message', 'signers', 'copy'),
    canUploadMore() {
      return (
        !this.hasFile ||
        this.canUploadManyFiles ||
        this.allIsPermittedForDocument
      );
    }
  },
  methods: {
    ...mapActions('signrequest', [
      'fromFileList',
      'nextForm',
      'autoFixPlaceholderIndexes'
    ]),
    handleClick(event) {
      this.$emit('focus');
    },
    handleFiles(files) {
      if (this.$props.template) {
        // can't add more files in a template editing mode
      } else if (
        !this.canUploadMore ||
        (files.length > 1 && !this.canUploadManyFiles)
      ) {
        this.showLoginOrSubscribeModal({ feature: 'multi_doc' });
      } else {
        this.fromFileList(files);
      }
    },
    handleSubmit() {
      if (this.isLast) {
        this.$emit('submit');
      } else {
        this.nextForm();
      }
    }
  }
};
</script>

<style scoped>
.lang-de .h-section .buttons button,
.lang-ru .h-section .buttons button,
.lang-nl .h-section .buttons button {
  font-size: 1rem;
}
.lang-de .h-section .buttons button.small,
.lang-ru .h-section .buttons button.small,
.lang-nl .h-section .buttons button.small {
  font-size: 0.84rem;
  min-width: 25%;
  margin: 6px auto;
}

.lang-de .h-section .buttons button[type='submit'],
.lang-nl .h-section .buttons button[type='submit'] {
  min-width: 50%;
}

.v-form /deep/ .h-section.h-section--buttons {
  position: sticky;
  z-index: 2;
  bottom: 0;
  background: white;
  border-radius: 0 0 25px 25px;
}
.h-section .buttons button[type='submit'] {
  min-width: 40%;
  margin: auto;
}
.h-section .buttons button.small {
  min-width: 30%;
}

/* textarea neeeds to be realigned */
.h-section--textarea {
  margin-bottom: 1rem;
}

.h-section--textarea /deep/ .v-text-field {
  padding-top: 0px;
}

.h-section--textarea /deep/ .v-input__control {
  /* shrink makes no sense */
  flex-shrink: 0;
}

.h-section--textarea .v-textarea {
  margin: 0px 1rem;
  position: relative;
}

.h-section--textarea /deep/ textarea {
  padding: 1rem 0px;
  font-weight: normal;
}

.h-section--textarea .v-textarea /deep/ textarea::placeholder {
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  opacity: 0.8;
}
.h-section--textarea .v-textarea /deep/ textarea[disabled] {
  opacity: 0.19;
  color: inherit;
}

.h-section--textarea .v-textarea /deep/ .v-messages {
  min-height: 3rem;
}

.h-section--textarea .v-textarea /deep/ .v-input__append-outer {
  position: absolute;
  top: 1rem;
  margin: 0;
}
.ltr .h-section--textarea .v-textarea /deep/ .v-input__append-outer {
  right: 1rem;
}
.rtl .h-section--textarea .v-textarea /deep/ .v-input__append-outer {
  left: 1rem;
}

.form-disabled .form-fields {
  position: relative;
}

.form-disabled .form-fields:before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background: gray;
  opacity: 0.15;
  display: block;
  content: ' ';
  width: 100%;
  height: 100%;
}
</style>
