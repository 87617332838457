<template>
  <div :class="{ mobile: $isMobile }">
    <div v-if="hasActiveTeam" class="align--center">
      <sr-button :href="createTeamUrl" sr-style="outline">
        <translate>Create a new team</translate>
      </sr-button>
    </div>

    <div class="sr-layout">
      <div class="sr-layout__item one-whole">
        <h2 v-if="hasActiveTeam" key="n">
          <translate>Change settings for:</translate>
          <span class="sps"></span>
          <span>{{ userOrTeamName }}</span>
        </h2>
        <h2 v-else-if="createSandbox" key="s" class="srtext--text">
          <translate>Create a new sandbox team</translate>
          <div class="teams--sandbox-explain">
            <SandboxExplainer color="srtext" />
          </div>
        </h2>
        <h2 v-else key="r">
          <translate>Create a new team</translate>
        </h2>
        <span
          v-if="onSandboxTeam"
          class="badge yellow float--right teams--badge"
        >
          <translate>Sandbox</translate>
          <SandboxExplainer color="white" />
        </span>
      </div>

      <div class="teams--swtich-block sr-layout__item">
        <small>
          <em v-translate>
            You can switch your account if you need to change settings of a
            different existing team.
          </em>
          <br />
          <em v-if="hasActiveTeam" v-translate>
            You can create a new team by switching to your personal account.
          </em>
        </small>
        <sr-menu container-class="teams--switch-menu">
          <template v-slot:activator="{ on }">
            <sr-button class="teams--switch-account" v-on="on">
              <translate>Switch account</translate>
            </sr-button>
          </template>
          <navigation-list teams />
        </sr-menu>
      </div>
      <v-form
        ref="settingsForm"
        name="teamSettingsForm"
        class="form sr-layout__item one-whole"
        @submit.prevent="handleSave"
      >
        <sr-input
          v-model="name"
          horizontal
          name="name"
          :label="$gettext('Team name')"
          :rules="[nameRule]"
        />
        <sr-input
          v-model="url"
          horizontal
          base
          name="url"
          :label="$gettext('Website')"
          :rules="[optional(urlRule)]"
          validate-on-blur
        />
        <sr-input
          v-model="phone"
          horizontal
          base
          name="phone"
          :label="$gettext('Phone number')"
        >
          <template v-slot:append-inner>
            <em key="phone" v-translate>
              The phone number, if not blank, will be shown to customers when
              they have questions about a document they need to sign.
            </em>
          </template>
        </sr-input>
        <sr-color
          v-model="primary_color"
          horizontal
          name="primary_color"
          :label="$gettext('Theme color')"
        />
        <sr-logo
          v-model="logo"
          horizontal
          name="logo"
          :label="$gettext('Logo')"
          :placeholder="$gettext('Upload logo')"
          :readonly="!hasActiveTeam"
          @file="saveTeamLogo"
        />
        <sr-subdomain
          v-model="subdomain"
          horizontal
          name="subdomain"
          :label="$gettext('SignRequest subdomain')"
          :readonly="hasActiveTeam"
        />
        <TeamDocumentSettings v-if="hasActiveTeam"></TeamDocumentSettings>
        <div v-if="hasActiveTeam" class="align--center">
          <sr-button
            sr-style="link"
            data-test-id="show-advanced"
            @click="showAdvanced = !showAdvanced"
          >
            <translate>Advanced</translate>
            <span class="link-arrow">→</span>
          </sr-button>
        </div>
        <div v-show="showAdvanced" data-test-id="advanced-settings">
          <sr-input
            v-model="default_redirect_url"
            horizontal
            name="default_redirect_url"
            :label="$gettext('Landing page')"
            :help="
              $gettext(
                `By default signers are shown a SignRequest success page after signing a document. You can however change this to for example your own custom 'thank you' page by adding a url.`
              )
            "
            :rules="[optional(urlRule)]"
            validate-on-blur
          />
          <sr-input
            v-model="default_redirect_url_declined"
            horizontal
            name="default_redirect_url_declined"
            :label="$gettext('Declined landing page')"
            :help="
              $gettext(
                'You can add a separate url for users to be redirected to when a document is declined. By default this is the landing page if specified above.'
              )
            "
            :rules="[optional(urlRule)]"
            validate-on-blur
          />
          <sr-input
            v-model="default_email_subject_line"
            horizontal
            name="default_email_subject_line"
            :label="$gettext('Default email subject')"
            :help="
              [
                $gettext(
                  'The default email subject line for SignRequest emails.'
                ),
                $gettext(
                  'Please note that SignRequest optimizes email subject lines for email deliverability (ensure emails are not treated as spam). Only change this when absolutely necessary for your use case.'
                )
              ].join(' ')
            "
          />
          <sr-input
            v-model="default_message"
            element="textarea"
            :min-height="72"
            horizontal
            name="default_message"
            :label="$gettext('Default message')"
            :help="
              $gettext(
                'The default message for a SignRequest. You can still change this manually.'
              )
            "
          />
          <sr-combobox
            v-model="default_font_family"
            horizontal
            show-caret
            :enable-manual="false"
            name="default_font_family"
            :label="$gettext('Font family')"
            :help="
              $gettext(
                'By default SignRequest automatically uses the font in the document (when available). This can be overridden by selecting a font family here.'
              )
            "
            :items="available_fonts || []"
          >
            <template v-slot:append-inner>
              <em key="font" v-translate>
                By selecting a font family you declare to have the required
                licence rights for usage of this font.
              </em>
            </template>
          </sr-combobox>
          <sr-field
            horizontal
            :label="$gettext('Signature options')"
            :help="
              $gettext('Control which type of signatures a signer can add.')
            "
          >
            <v-checkbox
              v-model="default_disable_upload_signatures"
              name="default_disable_upload_signatures"
              :label="
                $gettext('Disable signatures created from uploaded images')
              "
            />
            <v-checkbox
              v-model="default_disable_text_signatures"
              name="default_disable_text_signatures"
              :label="$gettext('Disable signatures created by typing')"
            />
            <v-checkbox
              v-model="default_disable_draw_signatures"
              name="default_disable_draw_signatures"
              :label="$gettext('Disable signatures created by drawing')"
            />
            <v-checkbox
              v-model="force_signature_color_enabled"
              name="force_signature_color_enabled"
              :label="
                $gettext('Force specific signature color (typing and drawing)')
              "
            />
          </sr-field>
          <sr-color
            v-show="force_signature_color_enabled"
            v-model="default_force_signature_color"
            horizontal
            name="default_force_signature_color"
            :label="$gettext('Signature color')"
            :help="
              $gettext(
                'Typed and drawn signatures will be restricted to this color.'
              )
            "
          />
          <sr-field
            horizontal
            :label="$gettext('Blockchain options')"
            :help="$gettext('This is a Beta feature.')"
          >
            <v-checkbox
              v-model="default_disable_blockchain_proof"
              name="default_disable_blockchain_proof"
              :label="$gettext('Disable blockchain proof integrations')"
            />
            <v-checkbox
              v-model="default_show_blockchain_proof"
              name="default_show_blockchain_proof"
              :label="$gettext('Show blockchain proof in Signing log')"
            />
          </sr-field>
          <sr-field
            horizontal
            :label="$gettext('Electronic Signature')"
            :help="
              $gettext(
                'SignRequest allows you to furnish a legal disclosure. If you choose to use this, each new recipient will read and agree to the terms of the disclosure before they can take action on any document.  SignRequest is providing this for your convenience only. If you have questions about using a Disclosure, please contact your legal counsel.'
              )
            "
          >
            <v-checkbox
              v-model="esign_disclosure_consent_required"
              name="esign_disclosure_consent_required"
              :label="$gettext('Show E-Sign disclosure')"
            />
          </sr-field>
          <sr-input
            v-show="esign_disclosure_consent_required"
            v-model="esign_disclosure_company_name"
            horizontal
            :label="$gettext('Company Name')"
            :help="
              $gettext(
                'The name of the company you would like to use in the Disclosure text.'
              )
            "
            name="esign_disclosure_company_name"
          />
          <sr-input
            v-show="esign_disclosure_consent_required"
            v-model="esign_disclosure_template"
            element="textarea"
            horizontal
            :min-height="380"
            :label="$gettext('Disclosure text')"
            :help="
              $gettext(
                'If you would like to use your company name, please add the [[CompanyName]] placeholder everywhere you want to use it.'
              )
            "
            name="esign_disclosure_template"
          />

          <div class="align--right">
            <sr-button
              sr-style="danger"
              class="teams--remove"
              data-test-id="delete-team"
              @click="handleDelete"
            >
              <translate>Delete team</translate>
            </sr-button>
          </div>
        </div>

        <div class="align--right">
          <sr-button
            class="teams--save"
            :loading="userTeamLoading"
            :disabled="userTeamLoading"
            type="submit"
          >
            <translate v-if="hasActiveTeam" key="s">Save</translate>
            <translate v-else key="c">Create</translate>
          </sr-button>
        </div>
      </v-form>
    </div>
    <TeamMembers
      :disabled="!hasActiveTeam"
      :readonly="!(isTeamAdmin || isTeamOwner)"
    />
    <TeamApiSettings
      v-model="apiSettings"
      :disabled="!hasActiveTeam"
      :readonly="!mayObtainApiToken"
      :loading="userTeamLoading"
      @save="handleSave"
    />
    <TeamSandbox
      v-if="sandbox"
      :readonly="!(isTeamAdmin || isTeamOwner)"
      :loading="userTeamLoading"
      @save="handleSaveProd"
    />
  </div>
</template>
<script>
import config from '@/config';
import { mapGetters, mapActions } from 'vuex';
import { mapModel, mapModelFields } from '@/utils/vuexExtra';
import { validateUrl } from '@/utils/format';

import NavigationList from '@/components/NavigationList.vue';
import SrButton from '@/components/elements/SrButton';
import SrMenu from '@/components/elements/SrMenu';
import SrField from '@/components/elements/SrField';
import SrInput from '@/components/elements/SrInput';
import SrColor from '@/components/elements/SrColor';
import SrLogo from '@/components/elements/SrLogo';
import SrSubdomain from '@/components/elements/SrSubdomain';
import SrCombobox from '@/components/elements/SrCombobox';
import SandboxExplainer from './SandboxExplainer';
import TeamApiSettings from './TeamApiSettings';
import TeamDocumentSettings from './TeamDocumentSettings';
import TeamMembers from './TeamMembers';
import TeamSandbox from './TeamSandbox';
import Screen from '@/mixins/Screen';
import permissions from '@/mixins/permissions';

export default {
  components: {
    NavigationList,
    SrButton,
    SrMenu,
    SrField,
    SrInput,
    SrColor,
    SrLogo,
    SrSubdomain,
    SrCombobox,
    SandboxExplainer,
    TeamMembers,
    TeamSandbox,
    TeamApiSettings,
    TeamDocumentSettings
  },
  mixins: [Screen, permissions],
  props: {
    createSandbox: { type: Boolean, default: false }
  },
  data() {
    return { showAdvanced: false };
  },
  computed: {
    ...mapGetters('users', [
      'hasActiveTeam',
      'mayObtainApiToken',
      'onSandboxTeam',
      'userOrTeamName',
      'apiSettings',
      'userTeamSettings',
      'userTeamLoading',
      'isTeamOwner',
      'isTeamAdmin',
      'canCreateTeam',
      'canUpgradeTeam'
    ]),
    ...mapModel('users', 'apiSettings', 'updateApiSettings'),
    ...mapModel('users', 'userTeamSettings', 'updateUserTeamSettings'),
    ...mapModelFields('userTeamSettings', [
      'sandbox',
      'name',
      'subdomain',
      'url',
      'phone',
      'logo',
      'primary_color',
      'default_redirect_url',
      'default_redirect_url_declined',
      'default_email_subject_line',
      'default_message',
      'default_font_family',
      'available_fonts',
      'default_disable_upload_signatures',
      'default_disable_text_signatures',
      'default_disable_draw_signatures',
      'force_signature_color_enabled',
      'default_force_signature_color',
      'default_disable_blockchain_proof',
      'default_show_blockchain_proof',
      'esign_disclosure_consent_required',
      'esign_disclosure_company_name',
      'esign_disclosure_template'
    ]),
    nameRule() {
      return value => (value ? true : this.$gettext('This field is required'));
    },
    urlRule() {
      return validateUrl.bind(this);
    },
    optional() {
      return fn => value => (value ? fn(value) : true);
    },
    createTeamUrl() {
      return `//${config.baseDomain}/#teams/`;
    }
  },
  watch: {
    async primary_color(value) {
      this.setThemeFromAngular({
        primaryColor: value
      });
    }
  },
  methods: {
    ...mapActions('modals', ['openConfirmationModal']),
    ...mapActions('conf', ['setThemeFromAngular']),
    ...mapActions('users', ['saveTeamSettings', 'deleteTeam', 'saveTeamLogo']),
    handleSave() {
      if (!this.canCreateTeam && !this.hasActiveTeam && !this.createSandbox) {
        this.showLoginOrSubscribeModal({ feature: 'create_team' });
      } else if (this.$refs.settingsForm.validate()) {
        this.saveTeamSettings(
          this.hasActiveTeam
            ? {}
            : { is_new: true, sandbox: this.createSandbox }
        );
      }
    },
    handleSaveProd() {
      if (this.canUpgradeTeam) {
        this.saveTeamSettings({ sandbox: false });
      } else {
        this.showLoginOrSubscribeModal({
          feature: 'convert_team_to_production'
        });
      }
    },
    async handleDelete() {
      const confirmed = await this.openConfirmationModal({
        body: this.$gettext('Are you sure you want to delete this team?'),
        confirmationButtonText: this.$gettext('Delete'),
        confirmationButtonColor: 'error'
      });
      if (confirmed) {
        this.deleteTeam();
      }
    }
  }
};
</script>
<style scoped>
.module__body {
  max-width: calc(100vw - 3rem);
}
h2 {
  display: inline-block;
  margin: 1.5rem 0;
}
button[type='submit'] {
  margin-top: 2rem;
}
.teams--sandbox-explain {
  display: inline-block;
  max-height: 1em;
  margin: 0 0.7ch;
}
.teams--badge {
  margin: 1.5rem 0;
}
.teams--badge ::v-deep .help {
  margin: 0 0.7ch;
}
.teams--remove,
.teams--switch-account {
  margin: 0.5rem 0;
}
.teams--swtich-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  flex-wrap: wrap;
}
.sr-button.teams--save {
  margin: 1rem 0;
}
.sr-button.sr-button--link {
  font-weight: normal;
}
.sr-button span.link-arrow {
  margin: 0 0.5ch;
}
em {
  line-height: initial;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}
span.sps {
  margin: 0 0.25ch;
}
.sr-field--control .v-input--checkbox {
  margin-top: 0;
}
.sr-field--control .v-input--checkbox ::v-deep .v-messages {
  min-height: unset;
}
.sr-field--control .v-input--checkbox ::v-deep label {
  top: -1px;
  font-size: 16px;
  font-weight: 300;
}
::v-deep .teams--switch-menu {
  z-index: 1;
  padding: 0;
  border-radius: 0;
}
.ltr .sr-menu ::v-deep .teams--switch-menu {
  right: 0;
}
.rtl .sr-menu ::v-deep .teams--switch-menu {
  left: 0;
}

::v-deep .teams--switch-menu .v-card {
  padding: 0;
}
</style>
