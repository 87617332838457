<template>
  <app-wrapper id="users">
    <v-container class="pa-0">
      <v-layout class="margin-bottom">
        <v-flex xs12 class="align--center">
          <span v-translate>You can add users on a team page.</span>
          <a
            v-if="howToMakeTeamUrl"
            v-translate
            :href="howToMakeTeamUrl"
            data-test-id="how-to-make-team"
          >
            To make a team account have a look here.
          </a>
        </v-flex>
      </v-layout>
      <v-layout v-if="isLoading && userLoggedIn" justify-center>
        <Loading></Loading>
      </v-layout>
      <CheckLogin>
        <v-layout>
          <v-flex xs12>
            <div class="list-group">
              <div
                v-for="teamUser in teamUsers"
                :key="teamUser.email"
                class="list-group__item"
              >
                <div class="flag">
                  <div class="flag__object">
                    <v-gravatar
                      :email="teamUser.email"
                      default-img="mm"
                      :size="48"
                    />
                  </div>
                  <div class="flag__body">
                    <v-layout>
                      <v-flex xs6>
                        <strong>{{ teamUser.name }}</strong>
                      </v-flex>
                      <v-flex xs6>
                        <v-layout
                          v-for="teamMember in teamUser.team_users"
                          :key="teamMember.uuid"
                          row
                        >
                          <v-flex xs6>
                            {{ teamMember.team.name }} - {{ teamMember.role }}
                          </v-flex>
                          <v-flex xs6>
                            <a
                              v-if="
                                !membersBeingDeleted[teamMember.uuid] &&
                                  !teamMember.is_owner
                              "
                              href=""
                              @click.prevent="deleteTeamMembership(teamMember)"
                            >
                              <span class="icon icon--trash text--error"></span>
                            </a>
                            <Loading
                              v-if="membersBeingDeleted[teamMember.uuid]"
                            ></Loading>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </div>
                </div>
              </div>
            </div>
          </v-flex>
        </v-layout>
      </CheckLogin>
    </v-container>
  </app-wrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import permissions from '@/mixins/permissions';
import AppWrapper from './AppWrapper';
import Loading from '@/components/Loading';
import CheckLogin from '@/components/CheckLogin';

export default {
  components: { CheckLogin, Loading, AppWrapper },
  mixins: [permissions],
  data() {
    return {
      membersBeingDeleted: {}
    };
  },
  computed: {
    ...mapGetters('users', ['userLoggedIn', 'teamUsers', 'teamUsersLoaded']),
    ...mapGetters('conf', ['howToMakeTeamUrl']),
    isLoading() {
      return !this.teamUsersLoaded;
    }
  },
  created() {
    if (this.userLoggedIn) {
      this.getTeamUsers();
    }
  },
  methods: {
    ...mapActions('users', ['getTeamUsers', 'deleteTeamUser']),
    ...mapActions('modals', ['openConfirmationModal']),
    async deleteTeamMembership(teamMember) {
      const confirmed = await this.openConfirmationModal({
        title: this.$gettext('Are you sure?'),
        body: this.$gettext('This cannot be undone.'),
        confirmationButtonText: this.$gettext('Delete'),
        confirmationButtonColor: 'error'
      });
      if (confirmed) {
        if (this.membersBeingDeleted[teamMember.uuid]) return;
        this.membersBeingDeleted[teamMember.uuid] = true;
        await this.deleteTeamUser(teamMember.uuid);
        this.membersBeingDeleted[teamMember.uuid] = false;
      }
    }
  }
};
</script>
