const $gettext = text => text;

export const PLAN_DISPLAY_NAMES = {
  PRO: $gettext('Professional'),
  SME: $gettext('Business')
};

export const PLAN_BENEFITS = {
  PRO: [
    $gettext('1 team'),
    $gettext('5 templates'),
    $gettext('Custom branding'),
    $gettext('Integrations')
  ],
  SME: [
    $gettext('Unlimited teams'),
    $gettext('Unlimited templates'),
    $gettext('Integrations')
  ]
};

export const STRIPE_ERRORS = {
  default: $gettext(
    'There was a problem with updating your payment details. Please, try again or use different payment method.'
  ),
  account_number_invalid: $gettext(
    'The bank account number provided is invalid.'
  ),
  balance_insufficient: $gettext(
    'The transfer or payout could not be completed because the associated account does not have a sufficient balance available.'
  ),
  bank_account_exists: $gettext(
    'The bank account provided already exists on the specified Customer object. '
  ),
  bank_account_unusable: $gettext(
    'The bank account provided cannot be used for payouts. A different bank account must be used.'
  ),
  card_declined: $gettext('The card has been declined.'),
  coupon_expired: $gettext(
    'The coupon provided for a subscription or order has expired. Either create a new coupon, or use an existing one that is valid.'
  ),
  email_invalid: $gettext(
    'The email address is invalid. Check that the email address is properly formatted and only includes allowed characters.'
  ),
  expired_card: $gettext(
    'The card has expired. Check the expiration date or use a different card.'
  ),
  incorrect_address: $gettext(
    'The card’s address is incorrect. Check the card’s address or use a different card.'
  ),
  incorrect_cvc: $gettext(
    'The card’s security code is incorrect. Check the card’s security code or use a different card.'
  ),
  incorrect_number: $gettext(
    'The card number is incorrect. Check the card’s number or use a different card.'
  ),
  incorrect_zip: $gettext(
    'The card’s postal code is incorrect. Check the card’s postal code or use a different card.'
  ),
  incomplete_number: $gettext('The card number is incomplete'),
  incomplete_cvc: $gettext('The card’s security code is incomplete'),
  incomplete_expiry: $gettext('The card’s expiration date is incomplete'),
  instant_payouts_unsupported: $gettext(
    'The debit card provided as an external account does not support instant payouts. Provide another debit card or use a bank account instead.'
  ),
  invalid_card_type: $gettext(
    'The card provided as an external account is not a debit card. Provide a debit card or use a bank account instead.'
  ),
  invalid_cvc: $gettext(
    'The card’s security code is invalid. Check the card’s security code or use a different card.'
  ),
  invalid_expiry_month: $gettext(
    'The card’s expiration month is incorrect. Check the expiration date or use a different card.'
  ),
  invalid_expiry_month_past: $gettext(
    'The card’s expiration month is in the past.'
  ),
  invalid_expiry_year: $gettext(
    'The card’s expiration year is incorrect. Check the expiration date or use a different card.'
  ),
  invalid_expiry_year_past: $gettext(
    'The card’s expiration year is in the past'
  ),
  invalid_number: $gettext(
    'The card number is invalid. Check the card details or use a different card.'
  ),
  invalid_iban_country_code: $gettext('The IBAN country code is not valid.'),
  invalid_iban: $gettext('The IBAN is not valid.'),
  invalid_iban_start: $gettext('The IBAN start is not valid.'),
  incomplete_iban: $gettext('The IBAN is not complete.'),
  postal_code_invalid: $gettext('The postal code provided was incorrect.'),
  processing_error: $gettext(
    'An error occurred while processing the card. Check the card details are correct or use a different card.'
  ),
  resource_missing: $gettext(
    'There was a problem with updating your payment details. Please, try again or use different payment method.'
  ),
  routing_number_invalid: $gettext(
    'The bank routing number provided is invalid.'
  ),
  sepa_unsupported_account: $gettext(
    'Your account does not support SEPA payments.'
  ),
  setup_intent_authentication_failure: $gettext(
    'We are unable to authenticate your payment method. Please choose a different payment method and try again.'
  ),
  testmode_charges_only: $gettext(
    'Your account has not been activated and can only make test charges. Activate your account in the Dashboard to begin processing live charges.'
  )
};
