<template>
  <span class="align--center">
    <span v-translate>Loading...</span>
    <i class="fa fa-refresh fa-spin mx-2" aria-hidden="true"></i>
  </span>
</template>
<script>
export default {
  name: 'Loading'
};
</script>
