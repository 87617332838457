<template>
  <div v-if="requiredActionsCount" class="action--count">
    <div class="height_fix"></div>
    <div class="action--count__content">{{ requiredActionsCount }}</div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('users', ['user']),
    ...mapGetters('documents', ['requiredActionsCount'])
  },
  watch: {
    user(newUser, oldUser) {
      if (newUser.logged_in && (!oldUser || oldUser.uuid !== newUser.uuid)) {
        this.getRequiredActionsCount();
      }
    }
  },
  mounted() {
    if (this.user.logged_in) {
      this.getRequiredActionsCount();
    }
  },
  methods: mapActions('documents', ['getRequiredActionsCount'])
};
</script>
<style scoped>
.action--count {
  border-radius: 50%;
  width: 10px;
  padding: 8px;
  font-size: 8px;
  line-height: 1em;
  background: red;
  position: absolute;
  margin-top: -23px;
}
.ltr .action--count {
  left: 2.1rem;
}
.rtl .action--count {
  right: 2.1rem;
}

.height_fix {
  margin-top: 100%;
}

.action--count__content {
  position: absolute;
  left: 0;
  top: 50%;
  height: 100%;
  width: 100%;
  text-align: center;
  margin-top: -0.5em;
  color: #fff;
  font-weight: bold;
}
</style>
