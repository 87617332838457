import angular from 'angular';
(function() {
  'use strict';

  /**
   * @ngdoc directive
   * @name frontendApp.directive:signatureUpload
   * @description
   * # signatureUpload
   */
  angular.module('frontendApp').directive('signatureUpload', [
    'docService',
    '$window',
    'Upload',
    'messageService',
    'accountUpload',
    '$timeout',
    '$cookies',
    'srSignatureSelectModal',
    'utils',
    function(
      docService,
      $window,
      Upload,
      messageService,
      accountUpload,
      $timeout,
      $cookies,
      srSignatureSelectModal,
      utils
    ) {
      return {
        template: require('../../views/directives/signature-upload.html'),
        restrict: 'E',
        scope: {
          api: '='
        },
        link: function postLink(scope, element) {
          scope.file_input_accept_string = utils.IMAGE_FILE_INPUT_ACCEPT_STRING;

          scope.api = { signer_uuid: null };
          scope.finish_callback = function() {};

          scope.api.toggleShowSigUpload = function(callback) {
            scope.show_upload = !scope.show_upload;
            if (typeof callback === typeof Function) {
              scope.finish_callback = callback;
            } else {
              scope.finish_callback = function() {};
            }
          };
          scope.dropSupported = false;
          scope.uploading = false;
          scope.converting = false;
          scope.hasFlash = window.FileAPI.hasFlash;
          scope.$watch(
            function() {
              return $window.FileAPI.hasFlash;
            },
            function(n, o) {
              if (n !== o) {
                scope.hasFlash = $window.FileAPI.hasFlash;
              }
            }
          );
          var getUploadStatus = function(resultId) {
            accountUpload.getStatus({ result_id: resultId }).$promise.then(
              function(response) {
                if (response.status === 'PENDING') {
                  $timeout(function() {
                    getUploadStatus(resultId);
                  }, 1000);
                } else if (response.status === 'SUCCESS') {
                  scope.converting = false;
                  scope.finish_callback(response.signature);
                  //scope.closeModal();
                } else {
                  messageService.add('error', response.statusText);
                }
              },
              function(response) {
                messageService.add('error', response.statusText);
              }
            );
          };
          scope.onFileSelect = function($files) {
            var onProgress = function(evt) {
                scope.uploadedPercentage = parseInt(
                  (100.0 * evt.loaded) / evt.total
                );
              },
              onSuccess = function(data) {
                scope.uploading = false;
                scope.converting = true;
                getUploadStatus(data.result_id);
              },
              onError = function(data) {
                messageService.handleError(data);
                scope.uploading = false;
                scope.closeModal();
              };

            scope.uploading = true;
            var file = $files[0];
            scope.upload = Upload.upload({
              url: '/user/auth/upload-sig/',
              method: 'POST',
              data: { file: file, signer_uuid: scope.api.signer_uuid }
            })
              .progress(onProgress)
              .success(onSuccess)
              .error(onError);
          };
          scope.show_upload = false;

          scope.closeModal = function() {
            srSignatureSelectModal.deactivate();
          };
        }
      };
    }
  ]);
})();
