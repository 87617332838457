<template>
  <v-container ref="container" fluid column data-test-id="people">
    <v-layout column content>
      <h1 v-translate class="srtext--text">
        Trusted by over 300,000 users in more than 26 countries
      </h1>
      <v-layout features row>
        <v-layout column feature>
          <quote-icon />
          <h2 v-translate class="srtext--text">
            Turnaround time has improved more than 80%
          </h2>
          <p v-translate>
            Implementing SignRequest in our complex environment with various
            departments and locations was easy and saved us a lot of time and
            money.”
          </p>
          <hr />
          <v-layout feature-author>
            <img
              class="feature--photo"
              :src="require('./images/face1/face.jpg')"
              :srcset="
                `${require('./images/face1/face@2x.jpg')} 2x, ${require('./images/face1/face@3x.jpg')} 3x`
              "
              alt="Prof. Luis E. Méndez Márquez"
              width="65px"
              height="65px"
            />
            <div>
              <h3 class="srtext--text">Prof. Luis E. Méndez Márquez</h3>
              <h4 v-translate class="srtext--text">
                University of Puerto Rico
              </h4>
            </div>
          </v-layout>
        </v-layout>
        <v-layout column feature>
          <quote-icon />
          <h2 v-translate class="srtext--text">
            100% increase in conversions
          </h2>
          <p v-translate>
            Adding SignRequest has been easy from a tech perspective and very
            valuable for our conversion rate. Highly recommended.”
          </p>
          <hr />
          <v-layout feature-author>
            <img
              class="feature--photo"
              :src="require('./images/face2/face.jpg')"
              :srcset="
                `${require('./images/face2/face@2x.jpg')} 2x, ${require('./images/face2/face@3x.jpg')} 3x`
              "
              alt="Michael Schmitz"
              width="65px"
              height="65px"
            />
            <div>
              <h3 class="srtext--text">Michael Schmitz</h3>
              <h4 v-translate class="srtext--text">
                CEO at The Flight-Refund GmbH
              </h4>
            </div>
          </v-layout>
        </v-layout>
        <v-layout column feature>
          <quote-icon />
          <h2 v-translate class="srtext--text">
            My clients always tell me how easy it is to use
          </h2>
          <p v-translate>
            All the scanning, printing and filing documents, I am not willing to
            do it that way. This platform is the simplest and most effective one
            I have encountered.”
          </p>
          <hr />
          <v-layout feature-author>
            <img
              class="feature--photo"
              :src="require('./images/face3/face.jpg')"
              :srcset="
                `${require('./images/face3/face@2x.jpg')} 2x, ${require('./images/face3/face@3x.jpg')} 3x`
              "
              alt="Carolyn Eckman"
              width="65px"
              height="65px"
            />
            <div>
              <h3 class="srtext--text">Carolyn Eckman</h3>
              <h4 class="srtext--text">Carolyn Eckman Consulting</h4>
            </div>
          </v-layout>
        </v-layout>
      </v-layout>
    </v-layout>
  </v-container>
</template>
<script>
const QuoteIcon = {
  render(h) {
    return h('div', { class: 'feature--quote' }, [
      h('img', {
        attrs: {
          src: require('@/icons/right-quotation-mark.png'),
          width: '16px',
          height: '16px',
          alt: this.$gettext('Quote')
        }
      })
    ]);
  }
};

export default {
  components: { QuoteIcon }
};
</script>
<style scoped>
.mobile .container {
  padding: 0;
}
.mobile .content {
  min-width: 100vw;
  overflow-x: hidden;
}
.column {
  flex-direction: column;
  align-items: center;
}
.features {
  align-items: stretch;
  justify-content: center;
  padding: 0;
  transition: left 0.5s;
  position: relative;
  margin-bottom: 85px;
}

.mobile .features {
  justify-content: unset;
  align-items: unset;
  flex-flow: column;
  margin-bottom: 40px;
}
@media screen and (max-width: 1240px) {
  .features {
    flex-flow: column;
  }
  .feature.feature {
    margin: 10px 0;
  }
}

.feature {
  position: relative;
  align-items: start;
  max-width: 336px;
  min-width: 336px;
  width: 336px;
  margin: 0 1rem;
  flex-grow: 0;
  padding: 32px;
  border-radius: 20px;
  box-shadow: -2px 5px 36px 8px rgba(0, 0, 0, 0.06);
  background-color: #fff;
}
.mobile .feature {
  max-width: 302px;
  min-width: 302px;
  margin: 10px 0;
  padding: 32px 28px;
}
.mobile .feature p {
  display: none;
}
.feature--quote {
  min-width: 100%;
  width: 100%;
  max-height: 16px;
  height: 16px;
}
.feature-author {
  justify-items: start;
  align-items: center;
  margin-top: 24px;
  flex-grow: 0;
}
img.feature--photo {
  height: 54px;
  width: 56px;
  min-width: 56px;
  margin-inline-end: 1em;
}

h1 {
  opacity: 0.9;
  font-size: 20px;
  font-weight: bold;
  margin-top: 54px;
  margin-bottom: 60px;
}
.mobile h1 {
  font-size: 20px;
  text-align: center;
  line-height: 1.29;
  margin: 0;
  margin-top: 20px;
  margin-bottom: 8px;
  max-width: 302px;
}

h2,
h3 {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.45;
  letter-spacing: 0.33px;
}
h2 {
  margin-top: 1em;
  min-height: 47px;
  height: 47px;
  max-width: 100%;
}
.mobile h2 {
  font-size: 16.3px;
  font-weight: normal;
  line-height: 1.45;
}
h3 {
  margin-top: 0;
}
h4 {
  opacity: 0.6;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.29;
  letter-spacing: 0.23px;
  margin-top: 0.5em;
}
p {
  margin-top: 24px;
  font-size: 16px;
  line-height: 1.75;
  color: rgba(0, 0, 0, 0.8);
  flex-grow: 1;
  max-width: 100%;
}
hr {
  width: 100%;
  transform: scaleY(0.5);
  margin-top: 12px;
}
</style>
