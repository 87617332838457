import * as Sentry from '@sentry/browser';
import config from '@/config';

Sentry.init({
  dsn: config.sentryFrontendDsn,
  release: config.appVersion
});

import Vue from 'vue';
import Router from 'vue-router';
import storeRouter from '@/store/router';

import './styles/generic/_normalize.scss';
import './plugins/vuetify';

import './scripts/app';
import './scripts/app_deps';
import './shared/scripts';

import './styles/vendor';
import './styles/main.scss';

import App from './App.vue';
import router from './router';
import { store } from './store';
import './registerServiceWorker';
import './filters';
import GetTextPlugin from 'vue-gettext';
import 'url-polyfill';
import 'whatwg-fetch'; // fetch is only used for prepare mode

Vue.config.productionTip = false;
storeRouter.setRouter(router);

Vue.use(GetTextPlugin, {
  translations: {},
  defaultLanguage: 'en',
  silent: process.env.NODE_ENV === 'production'
});
Vue.use(Router);

import SrSpace from './components/elements/SrSpace.vue';
Vue.component('SrSpace', SrSpace);

import SrLoader from './components/elements/SrLoader.vue';
Vue.component('SrLoader', SrLoader);

import SrButton from './components/elements/SrButton.vue';
Vue.component('SrButton', SrButton);

import SrButtonGroup from './components/elements/SrButtonGroup.vue';
Vue.component('SrButtonGroup', SrButtonGroup);

import AppWrapper from './components/AppWrapper.vue';
Vue.component('AppWrapper', AppWrapper);

import Gravatar from 'vue-gravatar';
Vue.component('v-gravatar', Gravatar);

new Vue({
  components: { SrButton },
  router,
  store,
  render: h => h(App)
}).$mount('#vue-app');
