import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-gravatar',{attrs:{"alt":"profile-picture","email":_vm.member.preferred_email,"default-img":"mm","size":48}}),_c('div',{staticClass:"member--info"},[_c('strong',[_vm._v(_vm._s(_vm.member.name))]),(_vm.member.role == 'inactive')?_c('span',[_vm._v("- "+_vm._s(_vm.member.role))]):_vm._e()]),_c('div',{staticClass:"align-select"},[(_vm.readonly)?_c('strong',{attrs:{"data-test-id":"member-permission"}},[_vm._v("\n      "+_vm._s(_vm.teamMemberDisplayValue)+"\n    ")]):_c(VSelect,{staticClass:"team-member-options",attrs:{"value":_vm.teamMemberValueSelected,"name":"teamMemberOptions","items":_vm.teamMemberOptions,"solo":"","menu-props":( _obj = {}, _obj[_vm.$vuetify.rtl ? 'nudge-left' : 'nudge-right'] = -16, _obj['nudge-top'] =  7, _obj.contentClass = 'team-member-options--menu', _obj )},on:{"input":_vm.handleChangeOption},scopedSlots:_vm._u([{key:"item",fn:function(ref){
      var item = ref.item;
return [(item.value === _vm.teamMemberValueSelected)?_c('div',{staticClass:"srtext--text"},[_c('i',{staticClass:"icon icon--tick",attrs:{"aria-hidden":"true"}}),(_vm.member.is_admin)?_c('span',{attrs:{"data-test-id":"member-permission"}},[_vm._v("\n            "+_vm._s(item.text)+"\n          ")]):_c('span',{attrs:{"data-test-id":"member-permission"}},[_vm._v("\n            "+_vm._s(item.selected)+"\n          ")])]):_c('div',[_c('span',{staticClass:"menu-icon-margin"}),_c('span',{attrs:{"data-test-id":"member-permission"}},[_vm._v(_vm._s(item.text))])])]}},{key:"selection",fn:function(ref){
      var item = ref.item;
return [_c('strong',{staticClass:"selected-option"},[_vm._v(_vm._s(_vm.teamMemberDisplayValue))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }