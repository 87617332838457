<template>
  <app-wrapper
    v-if="userLoaded && docCount !== undefined"
    id="document-search"
    class="d-block"
  >
    <v-container class="pa-0">
      <v-layout justify-center>
        <div class="xs12 pb-4">
          <v-btn href="/#/?open_upload=1" outline color="primary">
            <i class="icon icon--document v-icon--left" aria-hidden="true"></i>
            <span v-translate>Add document to sign</span>
          </v-btn>
        </div>
      </v-layout>
      <v-layout>
        <v-flex xs3 px-1>
          <DocumentStatusSelector
            v-model="statusFilter"
          ></DocumentStatusSelector>
        </v-flex>
        <v-flex xs9 px-1>
          <v-text-field
            v-model="query"
            :placeholder="$gettext('Search for email or filename')"
            solo
          ></v-text-field>
        </v-flex>
      </v-layout>
      <DocumentsRequiringAction></DocumentsRequiringAction>

      <DocumentUpgradeForStorageMessage
        v-if="!canDownloadDocuments"
      ></DocumentUpgradeForStorageMessage>

      <v-layout v-show="documents && docCount > 0" justify-end>
        <v-btn
          color="error"
          small
          :disabled="!selectedDocuments.length"
          class="mx-0"
          @click="confirmDelete"
        >
          <translate>Delete selected</translate>
        </v-btn>
      </v-layout>
      <v-layout justify-end row>
        <div>
          <a
            v-show="!allDocsSelected"
            v-translate
            href="#"
            @click.prevent="selectAllDocs"
          >
            select all
          </a>
          <a
            v-show="allDocsSelected"
            v-translate
            href="#"
            @click.prevent="deselectAllDocs"
          >
            deselect all
          </a>
        </div>
      </v-layout>
      <DocumentSelect v-model="selectedDocuments" :documents="documents" />
      <v-layout justify-space-between mt-4>
        <div v-if="documents.length">
          <v-btn
            :href="exportDocumentsLink"
            @click="confirmUserHasExportPermissions"
          >
            <i class="fa fa-file-excel-o" aria-hidden="true"></i>
            &nbsp;&nbsp;
            <span v-translate>Export</span>
          </v-btn>
          <v-btn
            :href="exportSignersLink"
            @click="confirmUserHasExportPermissions"
          >
            <i class="fa fa-file-excel-o" aria-hidden="true"></i>
            &nbsp;&nbsp;
            <span v-translate>Export signers</span>
            &nbsp;
            <v-tooltip max-width="350" nudge-right="15" right>
              <template v-slot:activator="{ on }">
                <v-icon size="13" style="color: inherit" v-on="on">
                  help_outline
                </v-icon>
              </template>
              <div v-translate>
                This exports each signer on a separate row. This also includes
                the data a signer inputs when 'External ID' is set on a template
                tag. This can be added in the advanced section when editing a
                template tag. The columns are variable based on the External
                ID's found in the export.
              </div>
            </v-tooltip>
          </v-btn>
        </div>
        <div class="text-xs-right">
          <div>
            <Pagination
              v-model="page"
              :result-count="docCount"
              :results-per-page="limit"
            ></Pagination>
          </div>
          <PageLimitSelector
            v-if="docCount && docCount > 10"
            v-model="limit"
            class="mt-3 mr-2"
          ></PageLimitSelector>
        </div>
      </v-layout>
    </v-container>
  </app-wrapper>
</template>

<script>
import Pagination from './Pagination';
import _ from 'lodash';
import PageLimitSelector from './PageLimitSelector';
import DocumentsRequiringAction from './DocumentsRequiringAction';
import DocumentStatusSelector from '@/components/DocumentStatusSelector';
import DocumentUpgradeForStorageMessage from '@/components/DocumentUpgradeForStorageMessage';
import DocumentSelect from '@/components/DocumentSelect';
import { mapActions, mapGetters, mapState } from 'vuex';
import permissions from '@/mixins/permissions';

import AppWrapper from './AppWrapper';

export default {
  components: {
    DocumentSelect,
    DocumentUpgradeForStorageMessage,
    DocumentsRequiringAction,
    Pagination,
    PageLimitSelector,
    DocumentStatusSelector,
    AppWrapper
  },
  mixins: [permissions],
  data: function() {
    return {
      page: 1,
      limit: 10,
      query: '',
      selectedDocuments: [],
      statusFilter: '*',
      lockedDocShown: false
    };
  },
  computed: {
    ...mapGetters('users', [
      'user',
      'canDownloadDocuments',
      'canExportDocuments'
    ]),
    ...mapState('documents', ['searchResults']),
    documents() {
      let docs = this.searchResults.results;

      if (!this.canDownloadDocuments) {
        docs = this.applyDocumentLocks(docs);
      }

      return docs;
    },
    docCount() {
      return this.searchResults.count;
    },
    allDocsSelected() {
      return (
        this.searchResults.results.length === this.selectedDocuments.length
      );
    },
    exportDocumentsLink() {
      const searchParams = new URLSearchParams({
        limit: this.limit,
        page: this.page,
        query: this.query,
        format: 'xls'
      }).toString();

      let searchUrl = `/api/v1/documents-search/?${searchParams}`;

      return searchUrl;
    },
    exportSignersLink() {
      return this.exportDocumentsLink + '&signer_data=1';
    },
    selectedDocumentsCount() {
      return this.selectedDocuments.length;
    },
    documentsToBeDeletedMessage() {
      const translatedDocumentCount = this.$ngettext(
        '%{ count } document will be deleted.',
        '%{ count } documents will be deleted.',
        this.selectedDocumentsCount
      );
      return this.$gettextInterpolate(translatedDocumentCount, {
        count: this.selectedDocumentsCount
      });
    }
  },
  watch: {
    page: function() {
      this.clearSelectedDocuments();
      this.getDocuments();
    },
    limit: function() {
      this.clearSelectedDocuments();
      this.resetPageAndGetDocuments();
    },
    query: function() {
      this.clearSelectedDocuments();
      if (this.page !== 1) {
        this.page = 1;
      } else {
        this.searchDocuments();
      }
    },
    statusFilter: function() {
      this.clearSelectedDocuments();
      this.resetPageAndGetDocuments();
    }
  },
  created() {
    this.getDocuments();
  },
  methods: {
    ...mapActions('events', ['trackDocumentStorageEvent']),
    ...mapActions('documents', { deleteDocuments: 'delete' }),
    ...mapActions('modals', ['openConfirmationModal']),
    getDocuments() {
      this.$store.dispatch('documents/search', {
        page: this.page,
        limit: this.limit,
        query: this.query,
        status: this.statusFilter
      });
    },
    searchDocuments: _.debounce(function() {
      this.getDocuments();
    }, 250),
    clearSelectedDocuments() {
      this.selectedDocuments = [];
    },
    async deleteSelectedDocuments() {
      await this.deleteDocuments({ uuids: this.selectedDocuments });

      this.getDocuments();
      this.clearSelectedDocuments();
    },
    selectAllDocs() {
      this.selectedDocuments = this.searchResults.results.map(doc => doc.uuid);
    },
    deselectAllDocs() {
      this.clearSelectedDocuments();
    },
    confirmUserHasExportPermissions(event) {
      if (!this.canExportDocuments) {
        event.preventDefault();
        this.showLoginOrSubscribeModal({ feature: 'export_document' });
      }
    },
    docShouldBeLocked(doc, now_timestamp) {
      // status signed or signed & downloaded, older than 24 hours and not created by the api
      return !!(
        ~['si', 'sd'].indexOf(doc.status) &&
        !doc.api_used &&
        doc.finished_on_timestamp &&
        now_timestamp - doc.finished_on_timestamp > 86400
      );
    },
    applyDocumentLocks(docs) {
      let numDocsAllowed =
        this.statusFilter || this.query || this.page !== 1 ? 0 : 1;
      const nowTimestamp = Math.round(Date.now() / 1000);

      return docs.map(doc => {
        const newDoc = Object.assign({}, doc);
        if (this.docShouldBeLocked(newDoc, nowTimestamp)) {
          if (numDocsAllowed <= 0) {
            newDoc.locked = true;

            if (this.lockedDocShown) {
              this.trackDocumentStorageEvent('Locked Documents Shown');
            }

            this.lockedDocShown = true;
          }
          numDocsAllowed--;
        }
        return newDoc;
      });
    },
    resetPageAndGetDocuments() {
      if (this.page !== 1) {
        this.page = 1;
      } else {
        this.getDocuments();
      }
    },
    async confirmDelete() {
      const confirmed = await this.openConfirmationModal({
        title: this.$ngettext(
          'Are you sure you want to delete the selected document?',
          'Are you sure you want to delete the selected documents?',
          this.selectedDocumentsCount
        ),
        body: `${this.documentsToBeDeletedMessage} ${this.$gettext(
          'This cannot be undone.'
        )}`,
        confirmationButtonText: this.$gettext('Delete'),
        confirmationButtonColor: 'error'
      });

      if (confirmed) {
        this.deleteSelectedDocuments();
      }
    }
  }
};
</script>
