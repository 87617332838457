<template>
  <v-container ref="container" fluid column data-test-id="plans">
    <v-layout column content>
      <h1 v-translate class="srtext--text">
        We have a plan for you
      </h1>
      <p v-translate class="srtext--text">
        For personal use or a heavy signing load. Highly customized and
        integrated or quick and easy. SignRequest has a suitable plan.
      </p>
      <v-layout features row>
        <v-layout column feature>
          <h2 v-translate class="srtext--text">Professional</h2>
          <span>
            <span class="currency primary--text">{{ planCurrencySymbol }}</span>
            <span class="price primary--text">9</span>
          </span>
          <span v-translate class="period">per month / 1 user</span>
          <span v-translate class="srlink--text documents">
            Unlimited documents
          </span>
          <free-trial class="plan-try" />
          <sr-button round :href="getPageUrlForLang('plans')" sr-style="link">
            <translate>Learn more</translate>
            <span class="link-arrow">→</span>
          </sr-button>
        </v-layout>
        <hr />
        <v-layout column feature>
          <h2 v-translate class="srtext--text">Business</h2>
          <span>
            <span class="currency primary--text">{{ planCurrencySymbol }}</span>
            <span class="price primary--text">15</span>
          </span>
          <span v-translate class="period">
            per month / 1 user
          </span>
          <span v-translate class="srlink--text documents">
            Unlimited documents
          </span>
          <free-trial class="plan-try" />
          <sr-button round :href="getPageUrlForLang('plans')" sr-style="link">
            <translate>Learn more</translate>
            <span class="link-arrow">→</span>
          </sr-button>
        </v-layout>
      </v-layout>
    </v-layout>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import permissions from '@/mixins/permissions';
import SrButton from '@/components/elements/SrButton';
import FreeTrial from './FreeTrial';

export default {
  components: { SrButton, FreeTrial },
  mixins: [permissions],
  props: {
    showFree: { type: Boolean, required: true }
  },
  computed: mapGetters('conf', ['getPageUrlForLang', 'planCurrencySymbol']),
  created() {
    this.getPlanCurrency();
  },
  methods: mapActions('conf', ['getPlanCurrency'])
};
</script>
<style scoped>
.container {
  padding: 80px 24px;
}
.column {
  flex-direction: column;
  align-items: center;
}
.features {
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  margin-top: 40px;
  box-shadow: -2px 6px 44px 10px rgba(0, 0, 0, 0.06);
}

.features hr {
  width: 0;
  height: 100%;
  border-left: 1px solid #d7d7d7;
  margin: 0;
  transform: scaleX(0.5);
}
.mobile .features hr {
  display: none;
}
.feature {
  width: 333px;
  height: 328px;
  margin: 0;
  flex-grow: 0;
  padding: 3.357em 2em 1.5em 2em;
  align-items: center;
  background-color: #fff;
  border-radius: 25px;
}

.feature .price {
  font-size: 2.14rem;
  font-weight: 900;
  letter-spacing: -0.47px;
  margin: 0 0.5ch;
  margin-inline-end: 0;
}

.lang-nl .feature .price::after {
  content: ',-';
}
.feature .currency {
  font-size: 1.357rem;
  vertical-align: super;
  font-weight: 900;
  letter-spacing: -0.25px;
}
.feature .period {
  font-size: 16px;
}
.feature .documents {
  font-size: 16px;
  font-weight: bold;
}
.mobile .layout .row {
  flex-direction: column;
}

h1 {
  opacity: 0.9;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.3;
  letter-spacing: normal;
  margin-top: 0;
}
.mobile h1 {
  font-size: 2rem;
  text-align: center;
}
h2 {
  font-size: 2.142rem;
  font-weight: 600;
  margin-top: 0;
}
h3 {
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  color: rgba(0, 0, 0, 0.8);
  margin-top: 1.5em;
  max-width: 450px;
  text-align: center;
}
a.subheader {
  font-size: 16px;
  font-weight: normal;
  line-height: 2.75;
  letter-spacing: 0.75px;
}
a.subheader span.link-arrow {
  font-size: 1rem;
  margin: 0 0.5em;
}

.sr-button {
  padding: 0 2em;
  margin-top: 8px;
}
.sr-button.plan-try {
  min-width: 150;
  min-height: 43px;
  margin-top: 30px;
}
.sr-button.sr-button--link {
  font-weight: normal;
  font-size: 16px;
}
.ltr .sr-button.sr-button--link {
  margin-left: 1em;
}
.rtl .sr-button.sr-button--link {
  margin-right: 1em;
}

.sr-button span.link-arrow {
  margin: 0 0.5ch;
}
</style>
