<template>
  <svg
    width="8px"
    height="13px"
    viewBox="0 0 8 13"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-456.000000, -726.000000)">
        <g transform="translate(80.000000, 161.000000)">
          <g transform="translate(364.000000, 556.000000)">
            <g transform="translate(13.000000, 10.000000)">
              <g>
                <g>
                  <path
                    d="M-1.5720758e-13,2.00475214 C0.485436876,0.624796581 1.89538362,-0.203841533 3.33717631,0.0434642455 C4.778969,0.290770024 5.83217936,1.54190507 5.83,3.00475214 C5.83,5.00475214 2.83,6.00475214 2.83,6.00475214"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <circle
                    fill="currentColor"
                    cx="3.05"
                    cy="10.2547521"
                    r="1.25"
                  ></circle>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
export default {};
</script>
