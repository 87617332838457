<template>
  <label ref="container">
    <input
      v-if="!editable"
      ref="input"
      type="checkbox"
      :aria-label="title"
      @click="handleClick"
      @change="handleChange"
    />
    <img
      v-show="imageDataUri"
      ref="img"
      :src="imageDataUri"
      :alt="stateTitle"
      class="ph-signature"
    />
    <checkbox v-show="!imageDataUri" :checked="checked || editable" />
    <sr-kite v-if="!editable" :priority="10" :value="!dataUri" />
  </label>
</template>
<script>
import { mapActions } from 'vuex';
import { scale } from '@/components/elements/SrServerTextSignature';
import Checkbox from '@/components/document/Checkbox';
import getCheckboxImage from './getCheckboxImage';
import SrKite from './SrKite.vue';

export default {
  components: {
    Checkbox,
    SrKite
  },
  props: {
    required: { type: Boolean, default: false },
    editable: { type: Boolean, required: true },
    actionRequired: { type: Boolean, default: true },
    label: { type: String, default: '' },

    initialValue: { type: Boolean, default: false },
    dataUri: { type: String, default: '' }
  },
  data() {
    return {
      checked: Boolean(this.$props.dataUri)
    };
  },
  computed: {
    stateTitle() {
      return this.checked
        ? this.$gettext('checked')
        : this.$gettext('unchecked');
    },
    title() {
      return this.$gettext('Checkbox %name %state')
        .replace('%name', this.label)
        .replace('%state', this.stateTitle);
    },
    imageDataUri() {
      return this.dataUri === 'data:' ? null : this.dataUri;
    }
  },
  async mounted() {
    if (this.dataUri || !this.initialValue) {
      return;
    }
    this.fill();
  },
  methods: {
    ...mapActions('modals', ['openCheckboxModal']),
    clear() {
      this.$emit('fill', {
        data_uri: this.$props.required ? null : 'data:'
      });
    },
    fill() {
      const { container } = this.$refs;
      const { height, width } = container.getBoundingClientRect();
      const sign = getCheckboxImage();
      const scaledSize = scale({
        sHeight: sign.height,
        sWidth: sign.width,
        dHeight: height,
        dWidth: width
      });

      this.$emit('fill', {
        data_uri: sign.data_uri,
        ...scaledSize
      });
    },
    async askUser() {
      this.checked = await this.openCheckboxModal({});
      const { input } = this.$refs;
      if (input) {
        input.focus();
      }
      return this.checked;
    },
    async select() {
      if (await this.askUser()) {
        this.fill();
      } else {
        this.clear();
      }
    },
    async handleClick(event) {
      if (!this.$props.actionRequired) {
        event.preventDefault();
        return;
      }
      const value = async () => {
        // Required checkbox could not be skipped, which means
        // there is no need to call the modal.
        // Do nothing and pass it through to checkbox
        // so it would change state.
        if (this.$props.required) {
          return !this.checked;
        }
        return this.askUser();
      };
      if (await value()) {
        this.fill();
      } else {
        this.clear();
      }
    },
    handleChange(event) {
      this.checked = event.target.checked;
    }
  }
};
</script>
<style scoped>
label {
  position: relative;
  display: block;
  color: inherit;
}
input {
  position: absolute;
  border: none;
  -webkit-appearance: none;
  width: 0;
  height: 0;
}

svg {
  height: 100%;
  display: block;
}
</style>
