<template>
  <app-wrapper v-if="shouldShow" id="home-box">
    <v-container fill-height :class="wrapperClass">
      <v-layout row-or-col wrap justify-space-between>
        <v-flex v-if="prepare || template" align-self-start form-container>
          <homebox
            :form-disabled="!hasFile"
            :template="template"
            :is-bulk-send="isBulkSend"
            @submit="saveDocument"
          />
        </v-flex>
        <v-flex
          class="doc-container"
          :class="{ 'doc-container--full': !prepare }"
        >
          <multi-document-editor
            v-if="hasPrepareMode"
            :prepare="prepare || template"
            :readonly="(!canSign || canApprove) && !(prepare || template)"
          />
          <metro
            v-else-if="(prepare && !isPaid && !$isMobile) || hideMarketing"
          />
          <info-block v-else-if="prepare && !$isMobile" />
        </v-flex>
      </v-layout>
    </v-container>
    <div v-if="showHomepageContent" class="homepage-content">
      <MobileInfoBlock v-show="$isMobile">
        <metro v-if="prepare && !isPaid && $isMobile" />
      </MobileInfoBlock>
      <BlueLine />
      <LandingFeatures />
      <People />
      <Plan v-if="!isPaid" :show-free="!userLoggedIn" />
      <Integrations />
    </div>
  </app-wrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import makeLazyComponent from '@/components/makeLazyComponent';
import Screen from '@/mixins/Screen';

import AppWrapper from '@/components/AppWrapper';
import BlueLine from '@/components/home/BlueLine';
import InfoBlock from '@/components/home/InfoBlock';
import MobileInfoBlock from '@/components/home/MobileInfoBlock';

import Metro from '@/components/home/Metro';
import LandingFeatures from '@/components/home/LandingFeatures';
import People from '@/components/home/People';
import Plan from '@/components/home/Plan';
import Integrations from '@/components/home/Integrations';

import Homebox from '@/components/signrequest/Homebox';

import { redirectFilter } from '@/utils/encodedParams';

import config from '@/config';

const MultiDocumentEditor = makeLazyComponent(() =>
  import(
    /* webpackChunkName: "pdf" */ '../components/document/MultiDocumentEditor.vue'
  )
);

export default {
  components: {
    AppWrapper,
    Homebox,
    BlueLine,
    InfoBlock,
    MobileInfoBlock,
    Metro,
    MultiDocumentEditor,
    LandingFeatures,
    People,
    Plan,
    Integrations
  },
  mixins: [Screen],
  data: function() {
    const { hideMarketing } = config;
    return {
      hideMarketing
    };
  },
  computed: {
    ...mapGetters('signrequest', [
      'senderNeedsToSign',
      'somebodyNeedsToSign',
      'othersNeedPlaceholders',
      'isPopup',
      'isBulkSend',
      'popupParams',
      'hasFile',
      'hasPrepareMode',
      'canSign',
      'canApprove',
      'hasHiddenPlaceholder',
      'placeholdersForOthers'
    ]),
    ...mapGetters('users', ['isPaid', 'userLoggedIn', 'isOverMonthLimit']),
    ...mapGetters('conf', ['isVueHomebox', 'isVueSignbox', 'isVueTemplate']),
    prepare() {
      return this.$route.name === 'home';
    },
    template() {
      return this.$route.name === 'template';
    },
    shouldShow() {
      if (this.template) {
        return this.isVueTemplate;
      }
      if (this.prepare) {
        return this.isVueHomebox;
      }

      return this.isVueSignbox;
    },
    showHomepageContent() {
      return (
        this.prepare &&
        !this.hasPrepareMode &&
        !this.isPaid &&
        !this.hideMarketing
      );
    },
    wrapperClass() {
      return {
        'sr-container': true,
        'sr-container--full': this.hasPrepareMode
      };
    },
    documentQuery() {
      if (this.prepare || this.template || !this.isVueSignbox) {
        return null;
      }
      return {
        uuid: this.$route.params.uuid,
        signer: this.$route.params.signer_uuid
      };
    },
    templateUUID() {
      if (!this.template) {
        return null;
      }
      return this.$route.params.uuid;
    }
  },
  watch: {
    documentQuery: {
      immediate: true,
      handler(value, old) {
        const isSameQuery =
          value &&
          old &&
          value.uuid === old.uuid &&
          value.signer === old.signer;
        if (value && !isSameQuery) {
          this.loadDoc(value);
        } else if (!value && old && old.uuid) {
          this.resetData();
        }
      }
    },
    templateUUID: {
      immediate: true,
      handler(value, old) {
        if (value && value !== old) {
          this.loadTemplate(value);
        } else if (!value && old) {
          this.resetData();
        }
      }
    }
  },
  beforeDestroy() {
    this.resetData();
  },
  methods: {
    ...mapActions('users', ['getUser']),
    ...mapActions('signrequest', [
      'restoreDoc',
      'restoreTemplate',
      'startBulkSend',
      'resetData',
      'create',
      'navigateTo',
      'saveTemplate',
      'bulkSend',
      'addPlaceholder'
    ]),
    ...mapActions('modals', ['openConfirmationModal', 'showTooMuchDocuments']),
    ...mapActions('conf', ['setFlags']),
    async confirmCreating() {
      const isUnPrepared =
        this.othersNeedPlaceholders && this.placeholdersForOthers.length === 0;
      if (isUnPrepared) {
        return this.openConfirmationModal({
          title: this.$gettext("You're about to send an unprepared document"),
          body: this.$gettext(
            'Place fields so your signers know which information you require.'
          ),
          confirmationButtonText: this.$gettext('Send')
        });
      }
      if (this.hasHiddenPlaceholder) {
        return this.openConfirmationModal({
          title: this.$gettext('Unassigned field'),

          body: this.$gettext(
            'It looks like some of the fields are not assigned to a contact.'
          ),
          confirmationButtonText: this.$gettext('Send')
        });
      }
      return true;
    },
    async saveDocument() {
      if (this.isBulkSend) {
        return this.bulkSend({ uuid: this.templateUUID });
      }
      if (this.template) {
        return this.saveTemplate();
      }
      if (!(await this.confirmCreating())) {
        return;
      }
      if (this.isOverMonthLimit) {
        return this.showTooMuchDocuments();
      }
      // variables are copied to a local scope
      // becasue since signrequest is sent, all data is kicked
      // out of store.
      const { popupParams, isPopup, senderNeedsToSign } = this;
      const newWindow =
        isPopup && senderNeedsToSign ? window.open('/#/loading') : window;
      const docUrl = await this.create();
      if (docUrl && !senderNeedsToSign && isPopup) {
        setTimeout(() => window.close(), 3000);
      } else if (docUrl) {
        const url = new URL(docUrl, location);
        // enable new signing page
        url.searchParams.set('new_sign', 'yes');
        this.setFlags({ vueSignbox: true });
        Object.entries(popupParams).forEach(([key, value]) => {
          if (value) {
            url.searchParams.append(key, value);
          }
        });
        this.navigateTo(`${url.pathname}${url.search}`);
      } else if (window !== newWindow) {
        newWindow.close();
      }
      if (!docUrl) {
        this.getUser({ signerUUID: null, forceReload: true });
        document.body.scrollIntoView(true);
      }
    },
    loadDoc({ uuid, signer }) {
      this.restoreDoc({
        uuid,
        signer,
        signerToken: this.$route.query.signer_token || null,
        next: redirectFilter(this.$route.query.next, 'close') || null,
        hideDecline: Boolean(this.$route.query.hide_decline)
      });
      this.getUser({ signerUUID: signer });
    },
    async loadTemplate(uuid) {
      if (!this.shouldShow) {
        return;
      }
      this.restoreTemplate({ uuid });
      if (this.$route.query.bulksend) {
        this.startBulkSend({ uuid });
      }
    }
  }
};
</script>

<style scoped>
#home-box {
  max-width: calc(100vw - 10px);
}
#home-box.mobile {
  max-width: unset;
}
.layout.row-or-col {
  flex-flow: row;
  padding: 0 9px;
}

.mobile .layout.row-or-col {
  flex-direction: column;
}
.mobile .sr-container:not(.sr-container--full) > .layout.row-or-col {
  flex-direction: column-reverse;
}

.sr-container {
  align-items: start;
  padding: 0;
  padding-bottom: 3.42rem;
}
.form-container {
  position: sticky;
  z-index: 20; /* in sync with div.v-card.homebox b/c of pos: sticky; */
  top: 2rem;
  flex: 0 0 32rem;
  max-width: 32rem;
  margin: 0 9px;
}
.mobile .form-container {
  flex: 0 100 32rem;
  max-width: calc(100vw - 18px - 18px);
  min-width: calc(100vw - 18px - 18px);
  top: unset;
  left: 18px;
  right: 18px;
  margin: 16px 9px;
}
.form-container ::v-deep .homebox > form {
  min-height: 32rem;
}

.doc-container {
  flex: 100 0;
  flex-basis: calc(100% - 32rem - 18px);
  margin: 0 9px;
}

.doc-container.doc-container--full {
  max-width: 100%;
}

.mobile .doc-container {
  flex-basis: 100%;
  margin: 0;
}

.homepage-content /deep/ > *:nth-child(even) {
  background-color: #f7f9fe;
}
.homepage-content /deep/ > *:nth-child(odd) {
  background-color: white;
}
</style>
<style>
body.DocCtrl.vue-sign .site__app_header {
  display: block;
}
</style>
