import angular from 'angular';

/**
 * @ngdoc directive
 * @name frontendApp.directive:signatureTextPreview
 * @description
 * # signatureTextPreview
 */
angular.module('frontendApp').component('signatureTextPreview', {
  template: require('../../views/directives/signature-text-preview.html'),
  bindings: {
    signatureText: '<',
    fontFamily: '<',
    selectedFontFamily: '<',
    selectFontFamily: '<',
    fillStyle: '<',
    saveSig: '<'
  },
  controller: [
    '$window',
    'SignatureTextService',
    'srSignatureSelectModal',
    '$element',
    '$scope',
    'gettextCatalog',

    function(
      $window,
      SignatureTextService,
      srSignatureSelectModal,
      $element,
      $scope,
      gettextCatalog
    ) {
      var ctrl = this;
      ctrl.show_text = false;

      ctrl.$postLink = function() {
        ctrl.closeModal = function() {
          srSignatureSelectModal.deactivate();
        };

        ctrl.canvasIsReady = function() {
          if (!ctrl.service) {
            return false;
          }
          return ctrl.service.canvasSetupDone;
        };

        ctrl.canvas = {};
        ctrl.service = new SignatureTextService(
          $element.find('.type-signature__container')[0],
          $element.find('.signature-canvas')[0]
        );

        $scope.$watch('$ctrl.signatureText', function(text) {
          ctrl.service.onTextChange(
            text || gettextCatalog.getString('Type your name')
          );
        });

        ctrl.service.setFontFamily(ctrl.fontFamily);
        ctrl.service.setFillStyle(ctrl.fillStyle);

        $scope.$watch('$ctrl.fillStyle', function(fillStyle) {
          ctrl.service.setFillStyle(fillStyle);
        });

        if (ctrl.fontFamily === ctrl.selectedFontFamily) {
          ctrl.selectFontFamily(ctrl.service);
        }

        ctrl.selectAndSave = function(service) {
          ctrl.selectFontFamily(service);
          if (ctrl.canvasIsReady()) {
            ctrl.saveSig();
          }
        };
      };
    }
  ]
});
