<template>
  <div>
    <v-input
      ref="vinput"
      class="v-text-field label-fix v-label--active srtext--text"
      :error-messages="errorMessages"
    >
      <v-label
        v-if="label"
        :for="uid"
        class="tel-label v-label v-label--active"
        :color="labelColor"
        absolute
        :focused="isFocused"
        value
      >
        {{ label }}
      </v-label>

      <input
        :id="uid"
        ref="inputField"
        type="tel"
        :name="name"
        :readonly="readonly"
        @input="onInput"
        @blur="onBlur"
        @focus="onFocus"
      />
      <template v-slot:append>
        <slot name="append-outer" />
      </template>
    </v-input>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import uniqueId from '@/mixins/uniqueId';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/js/utils';
import '@/scripts/phoneCountries';

export default {
  mixins: [uniqueId],
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: { type: String, required: false, default: '' },
    label: { type: String, default: '' },
    name: { type: String, default: '' },
    readonly: { type: Boolean, default: false }
  },

  data() {
    return { isFocused: false, errorCode: -1 };
  },

  computed: {
    ...mapGetters('conf', ['countryISOCode']),
    config() {
      return {
        // allowExtensions: false,
        // autoFormat: true,
        autoHideDialCode: true,
        autoPlaceholder: 'polite',
        // customPlaceholder: null,
        initialCountry: '',
        geoIpLookup: null,
        nationalMode: true,
        placeholderNumberType: 'MOBILE',
        preferredCountries: [
          this.countryISOCode,
          'us',
          'gb',
          'nl',
          'de',
          'es',
          'fr',
          'cn',
          'ru',
          'in',
          'id',
          'br'
        ].filter(code => code),
        utilsScript: '' // imported by default here at the top here
      };
    },

    errorMessages() {
      const errorMap = [
        this.$gettext('Invalid number'),
        this.$gettext('Invalid country code'),
        this.$gettext('Too short'),
        this.$gettext('Too long'),
        this.$gettext('Invalid number')
      ];

      if (this.errorCode < 0) {
        return [];
      } else {
        return [errorMap[Math.min(this.errorCode, 4)]];
      }
    },

    labelCls() {
      if (this.errorMessages.length) {
        return { 'red--text': true };
      } else {
        return { 'primary--text': this.isFocused };
      }
    },

    labelColor() {
      if (this.errorMessages.length) {
        return 'red';
      } else {
        return 'primary';
      }
    }
  },

  watch: {
    value(v) {
      if (this.intl) {
        const old = this.intl.getNumber();
        if (old !== v) {
          this.intl.setNumber(v);
        }
      }
      this.checkValidity();
    },
    isFocused: {
      immediate: true,
      handler(v) {
        // sync vinput state
        if (this.$refs.vinput) {
          this.$refs.vinput.isFocused = v;
        }
      }
    }
  },

  mounted() {
    const field = this.$refs.inputField;
    this.intl = intlTelInput(field, this.config);
    this.intl.setNumber(this.value);
  },

  beforeDestroy() {
    this.intl.destroy();
  },

  methods: {
    onBlur(e) {
      //this.$refs.vinput.$emit('blur', e);
      this.isFocused = false;
    },

    onFocus(e) {
      this.isFocused = true;
      //this.$refs.vinput.$emit('focus', e);
    },

    onInput(e) {
      if (this.intl && !this.$props.readonly) {
        const value = this.intl.getNumber();
        this.$emit('input', value);
      }
    },

    checkValidity() {
      let errorCode = -1;
      if (this.value && this.intl && !this.intl.isValidNumber()) {
        errorCode = this.intl.getValidationError();
      }

      if (errorCode !== this.errorCode) {
        this.errorCode = errorCode;
      }
    }
  }
};
</script>

<style scoped>
.v-text-field /deep/ label {
  font-weight: normal;
  opacity: 0.8;
}

/* disable the style from _forms.scss */
.v-text-field /deep/ label + div.intl-tel-input {
  margin-top: unset;
  display: unset;
}

.v-text-field /deep/ .v-input__control .v-input__slot {
  margin-bottom: 0px;
}
</style>
