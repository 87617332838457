<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32px"
    height="35px"
    viewBox="0 0 32 35"
  >
    <path
      fill="none"
      fill-rule="evenodd"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M29.06 3.337A8.013 8.013 0 0 0 23.402 1c-2.123 0-4.158.84-5.658 2.337l-1.542 1.537-1.542-1.537a8.02 8.02 0 0 0-11.316 0 7.96 7.96 0 0 0 0 11.281l1.542 1.537 11.316 11.281 11.316-11.28 1.542-1.538a7.962 7.962 0 0 0 2.345-5.64 7.962 7.962 0 0 0-2.345-5.64z"
    />
  </svg>
</template>
<script>
export default {};
</script>
