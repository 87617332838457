import angular from 'angular';

/**
 * @ngdoc function
 * @name frontendApp.controller:EmailChangeCtrl
 * @description
 * # EmailChangeCtrl
 * Everything we need to change the email
 */
angular
  .module('frontendApp')
  .controller('EmailChangeCtrl', [
    '$scope',
    '$route',
    'UserService',
    'utils',
    '$location',
    function($scope, $route, UserService, Utils, $location) {
      $scope.errors = [];
      $scope.new_email = '';
      $scope.email_change_complete = false;
      $scope.form_send_success = false;

      $scope.changeEmailRequest = function() {
        UserService.changeEmailRequest($scope.new_email).then(function(
          response
        ) {
          if (response.data.status == 'SUCCESS') {
            $scope.form_send_success = true;
          } else {
            $scope.form_errors = true;
            $scope.errors = response.data.messages;
          }
        });
      };
    }
  ])
  .controller('EmailChangeConfirmCtrl', [
    '$scope',
    '$route',
    'UserService',
    'utils',
    '$location',
    function($scope, $route, UserService, Utils, $location) {
      $scope.errors = [];
      $scope.new_email = '';
      $scope.email_change_complete = false;
      $scope.form_send_success = false;

      $scope.confirmEmailRequest = function() {
        var token = $location.search().token;
        var email_type = $location.search().email_type;
        UserService.validateEmailRequest(email_type, token).then(function(
          response
        ) {
          if (response.data.status == 'SUCCESS') {
            $scope.form_send_success = true;
            $scope.email_change_complete = response.data.email_change_complete;
            if ($scope.email_change_complete) {
              UserService.refreshUser();
            }
          } else {
            $scope.form_errors = true;
            $scope.errors = response.data.messages;
          }
        });
      };
    }
  ]);
