import Vue from 'vue';

const AUTOZOOM_LIMIT = 2;

function initial() {
  return {
    autoZoomed: false,
    inProgress: false,
    startValue: 1,
    zoom: 1
  };
}

export default new Vue({
  data: initial,
  methods: {
    reset() {
      Object.assign(this, initial());
    },
    start() {
      this.startValue = this.zoom;
      this.inProgress = true;
    },
    end() {
      this.inProgress = false;
    },
    autoZoom({ containerWidth, width, margin }) {
      if (this.autoZoomed) {
        return;
      }

      const autoZoom = (containerWidth - margin) / width;
      const rounded = Math.min(
        Math.floor(autoZoom * 100) / 100,
        AUTOZOOM_LIMIT
      );
      this.zoom = rounded;
    }
  }
});
