<template>
  <div>
    <subscribe @subscriptionCompleted="onSubscriptionCompleted"></subscribe>
  </div>
</template>
<script>
import Subscribe from '@/components/subscribe/Subscribe';
import { mapState, mapActions } from 'vuex';

export default {
  components: { Subscribe },
  computed: {
    ...mapState('subscription', ['subscriptionLoaded', 'subscriptionSucceeded'])
  },
  watch: {
    async subscriptionSucceeded(success) {
      this.navigateTo('/subscribe?subscribe_success=1');
    }
  },
  mounted() {
    this.initPage();
  },
  methods: {
    ...mapActions('signrequest', ['navigateTo']),
    ...mapActions('subscription', ['initSubscription']),
    ...mapActions('events', ['trackSubscribeEvent']),

    async initPage() {
      if (!this.$route.query.open_subscribe) {
        // if there are no parameters in url, but this should not ever happen
        this.navigateTo('/subscribe/?open_subscribe=PRO');
      } else {
        this.initSubscription({
          plan: this.$route.query.open_subscribe,
          interval: this.$route.query.interval,
          trial_days: this.$route.query.trial_days
        });
      }
    },
    async onSubscriptionCompleted() {
      this.trackSubscribeEvent({
        name: 'Subscribe Page',
        params: {
          trigger: 'landed_on_subscribe_page'
        }
      });
      window.location.hash = '#/';
    }
  }
};
</script>
<style lang="scss">
body.SubscribeCtrl {
  background-color: setting(colors, background-light-gray);

  header {
    .sr-container {
      max-width: 100%;
      margin: 0 !important;
    }

    .app-header--usermenu {
      display: none;
    }
  }

  footer.site__footer,
  .version--container {
    display: none;
  }

  .site__header.mobile ~ .site__main {
    margin-top: 0;
    margin-bottom: 0;
    height: 0;
  }
}
</style>
