<template>
  <div class="site__box" :class="{ 'ay11-kb': accessibilityOn }">
    <header
      class="site__header"
      :class="{
        mobile: $isMobile
      }"
    >
      <AnnouncementBanner v-if="showAnnouncementBanner">
        <p v-translate>
          Effective September 20, 2022 new and existing users who continue to
          use the SignRequest services and platform acknowledge the revised
          <a :href="privacyURL">Privacy Notice</a>
          , which incorporates the updated Data Processing Addendum (DPA). You
          may learn more about the DPA
          <a :href="dpaURL">here</a>
          . If you do not agree to the updated Privacy Notice, you must
          discontinue your use of SignRequest and may delete your account by
          emailing support@signrequest.com.
        </p>
      </AnnouncementBanner>
      <VersionBanner v-if="!hasPrepareMode" />
      <LangBanner v-if="!hasPrepareMode" />

      <LoadingBar />

      <div :class="wrapperClass" class="site__app_header">
        <AppHeader />
      </div>
      <MessageBanner v-if="!hasPrepareMode" :class="wrapperClass" />
    </header>
    <ModalWrapper />
    <MessageArea />
    <SrKiteArrow />

    <div class="site__main" role="main">
      <router-view />
      <app-loader />
    </div>
    <FloatingHelpButton />
  </div>
</template>

<script>
import LoadingBar from './components/LoadingBar';
import ModalWrapper from './components/ModalWrapper';
import AppHeader from './components/AppHeader';
import MessageArea from './components/MessageArea';
import VersionBanner from './components/VersionBanner';
import LangBanner from './components/LangBanner';
import MessageBanner from './components/MessageBanner';
import SrKiteArrow from './components/elements/SrKiteArrow.vue';
import AppLoader from './components/AppLoader';
import FloatingHelpButton from './components/elements/FloatingHelpButton';
import AnnouncementBanner from '@/components/AnnouncementBanner';

// these two are necessary to initialize vuetify
import Themeable from 'vuetify/lib/mixins/themeable';
import AppTheme from 'vuetify/lib/components/VApp/mixins/app-theme';
import Screen from './mixins/Screen';
import TabDetect from './mixins/TabDetect';

import { mapGetters } from 'vuex';

export default {
  name: 'App',
  components: {
    ModalWrapper,
    MessageArea,
    MessageBanner,
    LoadingBar,
    AppHeader,
    VersionBanner,
    LangBanner,
    SrKiteArrow,
    AppLoader,
    FloatingHelpButton,
    AnnouncementBanner
  },
  mixins: [Themeable, AppTheme, Screen, TabDetect],
  computed: {
    ...mapGetters('conf', ['getPageUrlForLang', 'localTheme', 'languageCode']),
    ...mapGetters('signrequest', ['hasPrepareMode']),
    wrapperClass() {
      return {
        'sr-container': true,
        'sr-container--full': this.hasPrepareMode
      };
    },
    showAnnouncementBanner() {
      return this.$route.path === '/';
    },
    privacyURL() {
      return this.getPageUrlForLang('privacy');
    },
    dpaURL() {
      return this.getPageUrlForLang('dpa');
    }
  },
  watch: {
    languageCode: {
      immediate: true,
      handler(newLang) {
        if (newLang) {
          this.$language.current = newLang;
          this.$vuetify.rtl = newLang === 'he';
        }
      }
    },
    localTheme: {
      immediate: true,
      handler(newTheme) {
        if (newTheme && newTheme.primaryColor) {
          this.$vuetify.theme.primary = newTheme.primaryColor;
        }
      }
    },
    $isMobile: {
      immediate: true,
      handler(value) {
        document.body.classList[value ? 'add' : 'remove']('mobile');
      }
    }
  },
  beforeCreate() {
    const noop = () => null;
    this.$router.replace(location.hash.slice(1)).then(noop, noop);
  },
  created() {
    this.$store.dispatch('users/getTags');
    this.$store.dispatch('conf/loadConfig');
  }
};
</script>

<style scoped>
.site__box {
  width: fit-content;
}
.site__header {
  margin: 0;
  z-index: 19;
  display: inline-block;
  /* 10px tax is collected in the name of gods of scroll */
  width: calc(100vw - 10px);
  border: 0;
  position: sticky;
  left: 0;
  right: 0;
}
.site__header.mobile {
  box-shadow: 0 0 3px 0px rgba(0, 0, 0, 0.1);
  background: #fff;
  width: 100vw;
  z-index: 21;
}
.site__header.mobile,
body.DocCtrl.vue-sign .site__header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background: #f8f8f8;
  display: block;
}

.site__header:not(.mobile) > .sr-container {
  /* box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1) !important; */
  margin-top: 2.42rem;
  margin-bottom: 2.42rem;
}
/* In case we have two of them on same page, but only angular
   version has page content */
.site__main:empty {
  margin-bottom: 0;
}
</style>
<style>
.sr-container--full {
  margin: 0 18px;
  max-width: calc(100% - 18px - 18px) !important;
}
.mobile .sr-container--full {
  padding: 0;
  margin: 0;
  min-width: 100%;
}
.mobile .site__app_header.sr-container--full {
  padding: 0 18px;
}
.ay11-kb a:focus {
  outline-color: rgba(54, 108, 216, 0.7);
  outline-width: 4px;
  outline-style: solid;
  outline-offset: 0.5rem;
}
</style>
