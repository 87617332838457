<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="420"
    height="420"
    viewBox="0 0 420 420"
    stroke="currentColor"
    fill="none"
  >
    <path stroke-width="18" d="M209,15a195,195 0 1,0 2,0z" />
    <path
      stroke-width="18"
      d="M210,15 V405 M210,15 m195-195H15M59,90a260,260 0 0,0 302,0 m0,240 a260,260 0 0,0-302,0M195,20a250,250 0 0,0 0,382 m30,0 a250,250 0 0,0 0-382"
    />
  </svg>
</template>
<script>
export default {};
</script>
<style scoped>
svg {
  display: block;
  width: 1em;
  height: 1em;
}
</style>
