class Point {
  constructor(x, y, time) {
    this.x = x;
    this.y = y;
    this.time = time || new Date().getTime();
  }
}

class VelocityPoint extends Point {
  constructor(x, y, ax, ay, time) {
    super(x, y, time);

    this.ax = ax;
    this.ay = ay;
  }

  velocity() {
    return Math.hypot(this.ax, this.ay);
  }
}

class Bezier {
  constructor(startPoint, control1, control2, endPoint) {
    this.startPoint = startPoint;
    this.control1 = control1;
    this.control2 = control2;
    this.endPoint = endPoint;
  }

  // Returns approximated length.
  length() {
    const { startPoint, control1, control2, endPoint } = this;
    const steps = 10;

    let px = null;
    let py = null;
    let length = 0;

    const pointAt = (t, start, c1, c2, end) => {
      return (
        start * (1.0 - t) * (1.0 - t) * (1.0 - t) +
        3.0 * c1 * (1.0 - t) * (1.0 - t) * t +
        3.0 * c2 * (1.0 - t) * t * t +
        end * t * t * t
      );
    };

    for (let i = 0; i <= steps; i++) {
      const t = i / steps;
      const cx = pointAt(t, startPoint.x, control1.x, control2.x, endPoint.x);
      const cy = pointAt(t, startPoint.y, control1.y, control2.y, endPoint.y);

      if (i > 0) {
        const xdiff = cx - px;
        const ydiff = cy - py;
        length += Math.hypot(xdiff, ydiff);
      }

      px = cx;
      py = cy;
    }

    return length;
  }

  forEachPoint(f) {
    const drawSteps = Math.floor(this.length()) || 1;

    for (let i = 0; i < drawSteps; i++) {
      // Calculate the Bezier (x, y) coordinate for this step.
      const t = i / drawSteps;
      const tt = t * t;
      const ttt = tt * t;
      const u = 1 - t;
      const uu = u * u;
      const uuu = uu * u;

      let x = uuu * this.startPoint.x;
      x += 3 * uu * t * this.control1.x;
      x += 3 * u * tt * this.control2.x;
      x += ttt * this.endPoint.x;

      let y = uuu * this.startPoint.y;
      y += 3 * uu * t * this.control1.y;
      y += 3 * u * tt * this.control2.y;
      y += ttt * this.endPoint.y;

      f({ x, y, ttt });
    }
  }

  static fitControlPoints(s1, s2, s3) {
    const dx1 = s1.x - s2.x;
    const dy1 = s1.y - s2.y;
    const dx2 = s2.x - s3.x;
    const dy2 = s2.y - s3.y;
    const m1 = { x: (s1.x + s2.x) / 2.0, y: (s1.y + s2.y) / 2.0 };
    const m2 = { x: (s2.x + s3.x) / 2.0, y: (s2.y + s3.y) / 2.0 };
    const dxm = m1.x - m2.x;
    const dym = m1.y - m2.y;
    const l1 = Math.hypot(dx1, dy1);
    const l2 = Math.hypot(dx2, dy2);
    const k = l2 / (l1 + l2);
    const cm = { x: m2.x + dxm * k, y: m2.y + dym * k };
    const tx = s2.x - cm.x;
    const ty = s2.y - cm.y;

    return {
      c1: new Point(m1.x + tx, m1.y + ty),
      c2: new Point(m2.x + tx, m2.y + ty)
    };
  }
}

class PointPath {
  constructor() {
    this._path = [];
    this._pathLength = 0;
    this.closed = false;
    this.painter = null;
  }

  lastPoint() {
    if (this._path.length > 0) {
      return this._path[this._path.length - 1];
    }

    return null;
  }

  pushPoint(pt) {
    this._path.push(pt);

    // add size
    if (this._path.length > 1) {
      const [a, b] = this._path.slice(this._path.length - 2);
      const dx = b.x - a.x;
      const dy = b.y - a.y;
      const total = Math.sqrt(dx * dx + dy * dy);
      if (total > 0) {
        this._pathLength += total;
      }
    }
  }

  closePath() {
    // we have to re-add the last point
    // because it marks the end of time
    // (even if mouse didn't move before release)
    if (this._path.length) {
      const last = this.lastPoint();
      const closingPoint = new Point(last.x, last.y);
      this.pushPoint(closingPoint);
    }

    this.closed = true;
  }

  setPainter(painter) {
    this.painter = painter;
  }

  getTotalLength() {
    return this._pathLength;
  }
}

export default class SmoothSignature {
  constructor(canvas, options) {
    const self = this;
    const opts = options || {};

    this.onchange = opts.onchange || (() => null);
    this.canvasOptions = {
      updateSpeed: opts.updateSpeed || 15,
      painterType: 'smooth',
      penColor: opts.penColor || 'black',

      baseLine: opts.hasOwnProperty('baseLine') ? opts.baseLine : true,
      // baseline offset from the top
      baselineTop: opts.baselineTop || 0.75,

      // baseline margin from left and right
      baselineOffset: opts.baselineOffset || 0.05
    };

    this.painterOptions = {
      syntheticPointDelay: opts.syntheticPointDelay || 25,
      minWidth: opts.minWidth || 1,
      maxWidth: opts.maxWidth || 4.7,
      easing: opts.easing || 0.75,
      div: opts.div || 0.08,
      speedWidthRatio: 20
    };

    this.backgroundColor = opts.backgroundColor || 'rgba(0,0,0,0)';

    this.canvas = canvas;
    this.context = canvas.getContext('2d');

    this.paths = [];

    // we need add these inline so they are available to unbind while still having
    //  access to 'self' we could use _.bind but it's not worth adding a dependency
    this._handleMouseDown = function(event) {
      if (event.which === 1) {
        self._mouseButtonDown = true;
        self._strokeBegin(event);
      }
    };

    this._handleMouseMove = function(event) {
      if (self._mouseButtonDown) {
        self._strokeUpdate(event);
      }
    };

    this._handleMouseUp = function(event) {
      if (event.which === 1 && self._mouseButtonDown) {
        self._mouseButtonDown = false;
        self._strokeEnd(event);
      }
    };

    this._handleTouchStart = function(event) {
      if (event.targetTouches.length === 1) {
        const touch = event.changedTouches[0];
        self._strokeBegin(touch);
        self._mouseButtonDown = true;
      }
    };

    this._handleTouchMove = function(event) {
      // Prevent scrolling.
      event.preventDefault();

      const touch = event.targetTouches[0];
      if (self._mouseButtonDown) {
        self._strokeUpdate(touch);
      }
    };

    this._handleTouchEnd = function(event) {
      const touch = event.targetTouches[0];
      event.preventDefault();
      self._mouseButtonDown = false;
      self._strokeEnd(touch);
    };

    this.clear();
    this._init();
  }

  isEmpty() {
    return this.paths.length === 0;
  }

  hasSignature() {
    const required = 42; // magic constant in pixels
    let have = 0;

    for (let path of this.paths) {
      have += path.getTotalLength();
      if (have > required) return true;
    }

    return false;
  }

  clear() {
    this.paths = [];
    this._fullRedraw();
  }

  undo() {
    if (this.paths.length) {
      this.paths = this.paths.slice(0, this.paths.length - 1);
    }

    this._fullRedraw();

    // call update directly, this avoid animation loop
    this._updatePaths();
  }

  _drawBaseline() {
    const canvas = this.canvas;
    const ctx = this.context;

    const { baselineTop, baselineOffset, penColor } = this.canvasOptions;
    const { width, height } = canvas;

    ctx.save();

    ctx.strokeStyle = penColor;
    ctx.lineWidth = 2;

    ctx.beginPath();
    ctx.moveTo(width * baselineOffset, baselineTop * height);
    ctx.lineTo(width * (1 - baselineOffset), baselineTop * height);
    ctx.stroke();

    ctx.restore();
  }

  _fullRedraw(noBaseline = false) {
    const canvas = this.canvas;
    const ctx = this.context;
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    if (!noBaseline && this.canvasOptions.baseLine) {
      this._drawBaseline();
    }

    ctx.fillStyle = this.canvasOptions.penColor;
    this.paths.forEach(path => {
      path.setPainter(null);
    });

    this.onchange(this);
  }

  toDataURL(imageType, quality) {
    this._fullRedraw(true);
    this._updatePaths();

    const canvas = this.canvas;
    return canvas.toDataURL.apply(canvas, arguments);
  }

  updatePenColor(color) {
    if (this.canvasOptions.penColor !== color) {
      this.canvasOptions.penColor = color;
      this._fullRedraw();
    }
  }

  isSmooth() {
    return this.canvasOptions.painterType === 'smooth';
  }

  updateSmooth(smooth) {
    this.canvasOptions.painterType = smooth ? 'smooth' : 'simple';
    this._fullRedraw();
  }

  toggleSmooth() {
    this.updateSmooth(!this.isSmooth());
  }

  _updatePaths(limit) {
    const { painterType } = this.canvasOptions;

    for (let path of this.paths) {
      if (!path.painter) {
        if (painterType === 'smooth') {
          path.setPainter(new SmoothPainter(this.context, this.painterOptions));
        } else {
          path.setPainter(new SimplePainter(this.context, this.painterOptions));
        }
      }

      const r = path.painter.update(path, limit);
      if (limit && !r) break;
    }
  }

  _strokeUpdate(event) {
    const canvas = this.canvas;
    const rect = canvas.getBoundingClientRect();

    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;

    const x = (event.clientX - rect.left) * scaleX;
    const y = (event.clientY - rect.top) * scaleY;

    const currentPath = this.paths[this.paths.length - 1];
    currentPath.pushPoint(new Point(x, y));

    this.onchange(this);
  }

  _strokeBegin(event) {
    if (this.paths.length) {
      const lastPath = this.paths[this.paths.length - 1];
      if (!lastPath.closed) {
        lastPath.closePath();
      }
    }
    this.paths.push(new PointPath());
    this._strokeUpdate(event);
  }

  _strokeEnd(_event) {
    const lastPath = this.paths[this.paths.length - 1];
    lastPath.closePath();
    this.onchange(this);
  }

  _init() {
    const me = this;

    // mouse events
    this._mouseButtonDown = false;
    this.canvas.addEventListener('mousedown', this._handleMouseDown);
    this.canvas.addEventListener('mousemove', this._handleMouseMove);
    document.addEventListener('mouseup', this._handleMouseUp);

    // touch events
    // Pass touch events to canvas element on mobile IE11 and Edge.
    this.canvas.style.msTouchAction = 'none';
    this.canvas.style.touchAction = 'none';

    this.canvas.addEventListener('touchstart', this._handleTouchStart);
    this.canvas.addEventListener('touchmove', this._handleTouchMove);
    this.canvas.addEventListener('touchend', this._handleTouchEnd);

    // main render loop!
    function paintFrame() {
      // paint all paths
      const { updateSpeed } = me.canvasOptions;
      me._updatePaths(updateSpeed);

      if (!me._destroyed) {
        window.requestAnimationFrame(paintFrame);
      }
    }

    paintFrame();
  }

  destroy() {
    this._destroyed = true;

    this.canvas.removeEventListener('mousedown', this._handleMouseDown);
    this.canvas.removeEventListener('mousemove', this._handleMouseMove);
    document.removeEventListener('mouseup', this._handleMouseUp);

    this.canvas.removeEventListener('touchstart', this._handleTouchStart);
    this.canvas.removeEventListener('touchmove', this._handleTouchMove);
    this.canvas.removeEventListener('touchend', this._handleTouchEnd);
  }
}

class SmoothPainter {
  constructor(ctx, options) {
    this.ctx = ctx;
    const {
      minWidth,
      maxWidth,
      div,
      easing,
      speedWidthRatio,
      syntheticPointDelay
    } = options;

    this.minWidth = minWidth;
    this.maxWidth = maxWidth;
    this.div = div;
    this.easing = easing;
    this.speedWidthRatio = speedWidthRatio;
    this.syntheticPointDelay = syntheticPointDelay;

    this._lastIndex = 0;
    this._interpolatePath = new PointPath();

    this._inputIndex = 0;
    this._pointSchedule = new PointPath();
  }

  _strokeWidth(point) {
    const velocity = point.velocity() / this.speedWidthRatio;
    return Math.max(this.maxWidth / (velocity + 1), this.minWidth);
  }

  _drawCurve(curve) {
    const startPoint = curve.startPoint;
    const endPoint = curve.endPoint;
    const startWidth = this._strokeWidth(startPoint);
    const endWidth = this._strokeWidth(endPoint);

    const ctx = this.ctx;
    const widthDelta = endWidth - startWidth;

    ctx.beginPath();

    curve.forEachPoint(({ x, y, ttt }) => {
      const width = startWidth + ttt * widthDelta;
      ctx.moveTo(x, y);
      ctx.arc(x, y, width, 0, 2 * Math.PI, false);
    });

    ctx.closePath();
    ctx.fill();
  }

  _processPoint() {
    const points = this._interpolatePath._path;

    if (points.length > 2) {
      let pts = [];

      // To reduce the initial lag make it work with 3 points
      // by copying the first point to the beginning.
      if (points.length === 3) {
        pts = [points[0], points[0], points[1], points[2]];
      } else {
        pts = points.slice(points.length - 4, points.length);
      }

      const tmp1 = Bezier.fitControlPoints(pts[0], pts[1], pts[2]);
      const tmp2 = Bezier.fitControlPoints(pts[1], pts[2], pts[3]);

      const c2 = tmp1.c2;
      const c3 = tmp2.c1;

      const curve = new Bezier(pts[1], c2, c3, pts[2]);
      this._drawCurve(curve);
    }
  }

  _updateInternal(path, limit) {
    // no change
    if (path._path.length === this._lastIndex) {
      return true;
    }

    const end = limit ? this._lastIndex + limit : path._path.length;
    const diff = path._path.slice(this._lastIndex, end);
    this._lastIndex = this._lastIndex + diff.length;

    for (let pt of diff) {
      // interpolate this point against the last one
      let lastPt = this._interpolatePath.lastPoint();
      if (!lastPt) {
        // fake the last point
        lastPt = new VelocityPoint(pt.x, pt.y, 0, 0, pt.time);
      }

      const ax = (lastPt.ax + (lastPt.x - pt.x) * this.div) * this.easing;
      const ay = (lastPt.ay + (lastPt.y - pt.y) * this.div) * this.easing;
      const x = lastPt.x - ax;
      const y = lastPt.y - ay;

      const interPoint = new VelocityPoint(x, y, ax, ay, pt.time);
      this._interpolatePath.pushPoint(interPoint);
      this._processPoint();
    }

    return false;
  }

  update(path, limit) {
    const pointSchedule = this._pointSchedule;
    const syntheticPointDelay = this.syntheticPointDelay;

    function interpolateTime(basePoint, nextTime) {
      const elapsed = nextTime - basePoint.time;
      const pointsToAdd = Math.floor(elapsed / syntheticPointDelay);

      for (let i = 0; i < pointsToAdd; i++) {
        const time = basePoint.time + (i + 1) * syntheticPointDelay;
        const pt = new Point(basePoint.x, basePoint.y, time);

        // and if necessary
        const last = pointSchedule.lastPoint();
        if (last.time < pt.time) {
          pointSchedule.pushPoint(pt);
        }
      }
    }

    for (let i = this._inputIndex; i < path._path.length; i++) {
      const pt = path._path[i];

      if (i !== 0) {
        interpolateTime(path._path[i - 1], pt.time);
      }
      pointSchedule.pushPoint(pt);
    }

    this._inputIndex = path._path.length;

    if (!path.closed) {
      interpolateTime(path.lastPoint(), new Date().getTime());
    }

    return this._updateInternal(pointSchedule, limit);
  }
}

class SimplePainter {
  constructor(ctx, { minWidth, maxWidth }) {
    this.ctx = ctx;
    this.width = (minWidth + maxWidth) / 2;

    this._lastIndex = 0;
    this._interpolatePath = new PointPath();
  }

  _drawLine(a, b) {
    const ctx = this.ctx;
    const drawSteps = Math.hypot(b.x - a.x, b.y - a.y);
    if (drawSteps < 0.5) {
      return;
    }

    ctx.beginPath();
    for (let i = 0; i <= drawSteps; i++) {
      const pc = i / drawSteps;
      const x = a.x + (b.x - a.x) * pc;
      const y = a.y + (b.y - a.y) * pc;

      ctx.moveTo(x, y);
      ctx.arc(x, y, this.width, 0, 2 * Math.PI, false);
    }
    ctx.closePath();
    ctx.fill();
  }

  update(path, limit) {
    // no change
    if (path._path.length === this._lastIndex) {
      return true;
    }

    const end = limit ? this._lastIndex + limit : path._path.length;
    const diff = path._path.slice(this._lastIndex, end);
    this._lastIndex = this._lastIndex + diff.length;

    for (let pt of diff) {
      const lastPt = this._interpolatePath.lastPoint();
      const dest = new Point(pt.x, pt.y, pt.time);

      if (lastPt) {
        this._drawLine(lastPt, dest);
      }

      this._interpolatePath.pushPoint(dest);
    }

    return false;
  }
}
