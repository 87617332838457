<template>
  <v-dialog
    v-model="showModal"
    lazy
    max-width="290"
    :content-class="contentClass"
  >
    <v-card>
      <v-card-title v-translate class="headline">
        Please select a Team
      </v-card-title>
      <v-card-text>
        <translate>
          The template will be transferred to the selected team
        </translate>
        <TeamSelector v-model="selectedTeam" class="mt-2"></TeamSelector>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn flat @click="closeModal">
          <translate>Cancel</translate>
        </v-btn>
        <v-btn
          color="primary"
          :disabled="!selectedTeam"
          @click="moveTemplateAndCloseModal"
        >
          <translate>Move</translate>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import TeamSelector from '@/components/elements/TeamSelector';
import ModalMixin from '@/components/modals/ModalMixin';

export default {
  components: { TeamSelector },
  mixins: [ModalMixin('templateMove')],
  props: {
    contentClass: { type: String, default: '' }
  },
  data: function() {
    return { selectedTeam: null };
  },
  computed: {
    returnValue() {
      return { selectedTeam: this.selectedTeam };
    },
    templateToMove() {
      return this.modalOptions.uuid;
    }
  },
  methods: {
    ...mapActions('templates', ['moveTemplate', 'getUserTemplates']),
    async moveTemplateAndCloseModal() {
      await this.moveTemplate({
        uuid: this.templateToMove,
        team: this.selectedTeam
      });
      await this.getUserTemplates();
      this.closeModal();
    },
    closeModal() {
      this.showModal = false;
    }
  }
};
</script>
