<template>
  <v-form
    ref="form"
    v-model="formValid"
    class="ph-form"
    @submit.prevent="handleSubmit"
  >
    <v-divider />
    <sr-combobox
      ref="signer"
      v-model="signer_index_str"
      name="signer_index"
      data-test-id="placeholder-signer-index"
      :items="contactItems"
      :label="$gettext('Select contact or enter a new one')"
      :rules="[signerRule]"
      show-caret
      @blur="handleBlur"
    >
      <template v-slot:selection>
        <span v-if="signer" class="signer-name">
          {{ signer.text }}
        </span>
      </template>
      <template v-slot:item="{ item }">
        <span class="signer-menu-item">
          <div
            v-if="item.hasIndex"
            class="dot"
            :style="`background: ${signerColor(item.value)};`"
          ></div>
          <span>{{ item.text }}</span>
        </span>
      </template>
    </sr-combobox>

    <v-divider />

    <sr-input
      v-if="fields.comment"
      v-model="comment"
      type="textarea"
      name="comment"
      :label="$gettext('Description')"
    />
    <v-divider />

    <v-card-text class="v-card__text__checkboxes">
      <div class="v-card__text__checkboxes__input">
        <checkbox
          v-if="fields.required"
          v-model="required"
          name="required"
          :label="$gettext('Required')"
        />

        <checkbox
          v-if="fields.multiline"
          v-model="multiline"
          name="multiline"
          :label="$gettext('Multiline')"
        />

        <checkbox
          v-if="fields.prefill"
          v-model="prefill"
          name="prefill"
          :label="$gettext('Prefill date')"
        />
      </div>
      <div class="v-card__text__checkboxes__help">
        <help-hint v-if="fields.required">
          <translate v-if="type === 'c'" key="c">
            When checked the user has no choice but to check the box. Otherwise
            the signer can choose to leave the checkbox empty.
          </translate>
          <translate v-else key="s">
            When checked the signer is required to fill this field.
          </translate>
        </help-hint>
        <help-hint v-if="fields.multiline">
          <translate>
            When multiline functionality is enabled signers can add multiple
            lines of text instead of only a single line. Handy for comment
            boxes.
          </translate>
        </help-hint>
        <help-hint v-if="fields.prefill">
          <translate>
            When checked the date will automatically be set to the current date
            while signing.
          </translate>
          <br />
          <br />
          <translate>
            The date format of the sender's language will be used.
          </translate>
        </help-hint>
      </div>
    </v-card-text>
    <div v-if="fields.external_id" class="ph-form--advanced">
      <sr-button
        inline
        sr-style="link"
        class="ph-form--control primary---text"
        @click="showAdvanced = !showAdvanced"
      >
        <translate>Advanced</translate>
        <v-icon v-if="showAdvanced" class="primary--text" aria-hidden="true">
          keyboard_arrow_up
        </v-icon>
        <v-icon v-else class="primary--text">keyboard_arrow_down</v-icon>
      </sr-button>
      <v-divider v-if="showAdvanced" />
      <sr-input
        v-if="showAdvanced"
        v-model="external_id"
        name="external_id"
        :label="$gettext('External ID')"
        :help="
          $gettext(
            'This ID is used to identify signer input data in exports, the REST API and integrations.'
          )
        "
      />
      <v-divider v-if="showAdvanced" />
    </div>
    <slot />
  </v-form>
</template>

<script>
import variant from '@/utils/variant';
import { mapActions, mapGetters } from 'vuex';
import { append, updateAtIdx, merge } from '@/utils/fp';
import { validateEmail } from '@/utils/format';
import { emptySigner } from '@/utils/addSigner';
import signerLabel from '@/utils/signerLabel';
import { getSignerColor } from '@/utils/signerColors';

import SrInput from '@/components/elements/SrInput';
import SrCheckboxInput from '@/components/elements/SrCheckboxInput';
import SrButton from '@/components/elements/SrButton';
import SrCombobox from '@/components/elements/SrCombobox';
import HelpHint from '@/components/signrequest/HelpHint';
import BoxCheckbox from '@/components/elements/BoxCheckbox';

function initialState() {
  return {
    comment: '',
    external_id: '',
    signer_index: null,
    required: true,
    multiline: false,
    prefill: false,
    showAdvanced: false,
    formChangeBuffer: [],
    formValid: true
  };
}
const needPlaceholder = level => level === 'signature' || level === 'in_person';

export function label() {
  return {
    s: this.$gettext('Who needs to sign here?'),
    t: this.$gettext('Who needs to add text?'),
    d: this.$gettext('Who needs to add a date?'),
    c: this.$gettext('Who can choose here?')
  }[this.type];
}

export default {
  components: {
    SrButton,
    SrInput,
    SrCombobox,
    HelpHint,
    Checkbox: variant(BoxCheckbox, SrCheckboxInput)
  },
  props: {
    type: { type: String, required: false, default: '' },
    fast: { type: Boolean, default: false }
  },
  data: initialState,
  computed: {
    ...mapGetters('users', ['user', 'userContacts']),
    ...mapGetters('signrequest', ['template', 'signrequestForm']),
    label,
    signerColor() {
      return getSignerColor;
    },
    localForm() {
      return this.formChangeBuffer.reduce(
        (form, fn) => fn(form),
        this.signrequestForm
      );
    },
    formValue() {
      return {
        comment: this.comment,
        external_id: this.external_id,
        signer_index: this.signer_index,
        required: this.required,
        multiline: this.multiline,
        prefill: this.prefill
      };
    },
    contacts() {
      const senderName = this.user.full_name
        ? this.$gettextInterpolate(
            this.$gettext('%{full_name} (me)'),
            this.user
          )
        : this.$gettext('Me');
      return this.localForm.signers.map((signer, idx) => ({
        value: idx,
        text: idx === 0 ? senderName : signerLabel(signer, idx),
        hasIndex: true,
        need: needPlaceholder(signer.level)
      }));
    },
    contactItems() {
      return Object.freeze(
        [
          ...this.contacts,
          Boolean(this.userContacts.length) && {
            header: this.$gettext('Existing contacts')
          },
          ...this.userContacts
        ].filter(Boolean)
      );
    },
    firstSigningContact() {
      return this.contacts.filter(entry => entry.need)[0];
    },
    signer() {
      return this.contacts[this.signer_index] || null;
    },
    signer_index_str: {
      set(input) {
        const contactIndex = this.localForm.signers.findIndex(
          signer => signer.email === input
        );
        const value = contactIndex < 0 ? input : contactIndex;
        if (typeof value === 'string') {
          const newIndex = this.signrequestForm.signers.length;
          const newSigner = merge(emptySigner(this.template, newIndex), {
            name: value,
            email: value
          });
          this.formChangeBuffer = Object.freeze([
            form => merge(form, { signers: append(form.signers, newSigner) })
          ]);
          this.signer_index = newIndex;
        } else if (value !== this.signer_index) {
          const signerPatch = signer =>
            needPlaceholder(signer.level)
              ? signer
              : merge(signer, { level: 'signature' });

          this.formChangeBuffer = Object.freeze([
            form =>
              merge(form, {
                signers: updateAtIdx(form.signers, value, signerPatch)
              })
          ]);
          this.signer_index = value;
        } else {
          this.signer_index = value;
        }
        if (this.$refs.signer.isMenuActive) {
          this.$refs.signer.internalSearch = '';
          this.$refs.signer.blur();
        }
      },
      get() {
        return Number(this.signer_index);
      }
    },
    fields() {
      return Object.freeze(
        {
          s: {},
          t: {
            comment: true,
            required: true,
            multiline: true,
            external_id: true
          },
          d: { required: true, prefill: true, external_id: true },
          c: { comment: true, required: true, external_id: true }
        }[this.type] || {}
      );
    }
  },
  watch: {
    formValid(value) {
      this.$emit('change:valid', value);
    },
    formValue(value) {
      if (this.$props.fast) {
        this.$emit('change', value);
        this.flushFormChanges();
      }
    }
  },
  methods: {
    ...mapActions('signrequest', ['updateSignrequestForm']),
    focus() {
      this.$refs.signer.focus();
    },
    reset(formValue) {
      const canUseLast =
        this.signer &&
        this.localForm.signers[this.signer_index] &&
        needPlaceholder(this.localForm.signers[this.signer_index].level);

      const { value: firstSigner = -1 } = this.firstSigningContact || {};
      const initial = Object.assign({}, initialState(), {
        signer_index: canUseLast ? this.signer_index : Number(firstSigner)
      });
      Object.entries(initial).forEach(([key, value]) => {
        this[key] =
          formValue && formValue.hasOwnProperty(key) ? formValue[key] : value;
      });
    },
    signerRule(value) {
      if (value === 0 || !this.type) {
        return true;
      }
      if (typeof value === 'string') {
        return validateEmail.call(this, value);
      }
      const signer = this.localForm.signers[value];
      if (!signer) {
        return this.label;
      }
      if (!signer.email) {
        return true;
      }
      return validateEmail.call(this, signer.email);
    },
    flushFormChanges() {
      this.updateSignrequestForm({ signers: this.localForm.signers });
      this.formChangeBuffer = [];
    },
    handleBlur() {
      const signerValue = this.$refs.signer.internalSearch;
      if (signerValue) {
        this.signer_index_str = signerValue;
      }
    },
    handleSubmit(event) {
      this.$refs.form.validate();

      if (this.formValid) {
        this.$emit('change', Object.freeze(this.formValue));
        this.flushFormChanges();
      }
    }
  }
};
</script>

<style scoped>
h1 {
  margin: 0;
  display: block;
  font-size: 24px;
  font-weight: 900;
  opacity: 0.9;
  color: #111111;
}

.v-select.v-input--is-focused .signer-name {
  display: none;
}

.v-select.v-input.v-input--is-empty /deep/ ::placeholder,
.v-select.v-input--is-dirty.v-input--is-focused /deep/ ::placeholder {
  color: #111111 !important;
  opacity: 0.48;
  font-weight: 600;
}

.v-card__text {
  padding: 55px 40px 40px 40px;
  display: flex;
  justify-content: space-between;
}
.v-card__text__buttons {
  padding: 0px 40px 40px 40px;
}
.v-card__text__checkboxes {
  padding: 20px 40px 8px 40px;
  flex-direction: row;
  justify-content: flex-start;
}
.v-card__text__checkboxes__input {
  width: 100%;
}

/deep/ .v-dialog {
  overflow-y: visible;
}
/deep/ .v-dialog > * {
  border-radius: 2rem;
}

.v-form > .v-input,
.v-form > .sr-field,
.v-form > .sr-menu,
.ph-form--advanced > * {
  margin: 6px 40px;
}
.sr-autocomplete /deep/ .sr-autocomplete--value {
  top: 6px;
}

.v-divider {
  margin: 0px;
  transform: scaleY(0.5);
}
.v-divider + .v-divider {
  display: none;
}

.v-text-field /deep/ .v-label {
  width: 100%;
  opacity: 0.6;
  color: #111111;
}
.v-input--checkbox /deep/ .v-label {
  color: #111111;
  opacity: 1;
  font-weight: normal;
}

.v-input--checkbox /deep/ .v-icon {
  color: rgba(0, 0, 0, 0.15);
}
/deep/ .v-input--checkbox {
  position: relative;
  right: 3px;
  margin-top: 0;
}

.ph-form /deep/ .v-input__control > .v-input__slot::before,
.ph-form /deep/ .v-input__control > .v-input__slot::after {
  border-width: 0;
}
.ph-form /deep/ .v-input__slot {
  margin-bottom: 0;
}

/deep/ .sr-checkbox .sr-field--control {
  display: none;
}

/deep/ .sr-field.sr-checkbox {
  min-height: 40px;
}

.ph-form--advanced {
  font-size: 16px;
  margin-bottom: 2.2em;
}
.ph-form--advanced a {
  display: block;
  margin-top: 0.5em;
  margin-bottom: 1.5em;
}
.ph-form--advanced a:hover {
  text-decoration: none;
}
.ph-form--advanced .v-icon {
  vertical-align: middle;
}

.ph-form /deep/ .help[role='button'] {
  display: block;
  margin: 0 1.6rem;
  font-size: 1.4rem;
}
.sr-field /deep/ .help[role='button'] {
  margin: 0 -11px;
  top: 2px;
}
.v-card__text__checkboxes /deep/ .help[role='button'] {
  height: 40px;
  padding-top: 10px;
}

.label--text {
  min-width: 20%;
}
.v-card__text__buttons .sr-button {
  font-size: 18px;
  padding: 0em 3.5em;
  margin: 0px;
  height: 3em;
  width: calc(50% - 10px);
}
.dot {
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 100%;
  vertical-align: middle;
}
.ltr .dot {
  margin-right: 0.5ch;
}
.rtl .dot {
  margin-left: 0.5ch;
}
</style>
<style>
.v-menu__content.signer-index--menu {
  border-radius: 15px;
}
</style>
