<template>
  <div class="sr-signature-button primary--text">
    <button
      ref="button"
      class="img-btn"
      :class="{ selected }"
      :disabled="disabled"
      :aria-pressed="selected"
      :tabindex="disabled ? -1 : 0"
      :title="title"
      @click.prevent="handleClick"
      @keypress.13.prevent="handleKeypress"
    >
      <img v-if="srcData" :src="srcData" :alt="$gettext('Signature')" />
      <slot />

      <div v-show="!disabled" class="fake-checkbox">
        <span class="inner"></span>
      </div>
    </button>

    <button
      v-if="deletable"
      class="delete-btn"
      href="javascript:void(0)"
      :tabindex="disabled ? -1 : 0"
      :title="deleteTitle"
      @click.prevent="$emit('delete')"
    >
      <!-- delete should be outside <a> for tabindex reasons -->
      <trash-icon class="trash-icon" />
    </button>
  </div>
</template>

<script>
import TrashIcon from '../signrequest/TrashIcon.vue';

export default {
  components: { TrashIcon },
  props: {
    title: { type: String, default: null },
    deleteTitle: { type: String, default: null },
    srcData: { type: String, default: null },
    selected: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    deletable: { type: Boolean, default: false }
  },
  mounted() {
    this.$emit('ready');
  },
  methods: {
    focus() {
      this.$refs.button.focus();
    },
    handleSelect() {
      if (this.disabled) return;
      this.$emit('select');
    },
    handleClick(evt) {
      this.handleSelect();

      if (evt && evt.target && evt.target.blur) {
        evt.target.blur();
      }
    },
    handleKeypress() {
      this.handleSelect();
    }
  }
};
</script>

<style lang="scss" scoped>
.sr-signature-button {
  display: block;
  position: relative;
  width: 100%;
}

.img-btn {
  display: block;
  border-radius: 5px;

  color: setting(colors, border-dark);
  border: 0.5px solid currentColor;
  transition: all 0.2s;
  font-size: 0px;

  width: 100%;
  min-height: 100%;
}

.img-btn:hover:not(:disabled),
.img-btn.selected:not(:disabled),
.img-btn:focus:not(:disabled) {
  color: inherit;
}

.img-btn:focus:not(:disabled) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.img-btn:disabled {
  filter: grayscale(100%);
  cursor: default;
}

.signrequest-lib .img-btn {
  border-color: #d3d3d3;
  border-style: inset;
}
.signrequest-lib .img-btn.selected {
  outline-color: #0061d5;
  outline-width: 3px;
  outline-style: solid;
}

.fake-checkbox {
  position: absolute;
  width: 12px;
  height: 12px;

  bottom: 4px;
  right: 5px;

  border: 0.5px solid currentColor;
  border-radius: 50%;
  box-sizing: content-box;
  display: block;
}

.rtl .fake-checkbox {
  right: unset;
  left: 5px;
}

.fake-checkbox::after {
  content: '';
  background-color: currentColor;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  transform: scale(0.6);
  display: block;
  position: absolute;
  opacity: 0;
}

.img-btn:hover .fake-checkbox::after,
.img-btn:focus .fake-checkbox::after,
.img-btn.selected .fake-checkbox::after {
  opacity: 1;
}

.signrequest-lib .fake-checkbox {
  display: none;
}

.img-btn ::v-deep canvas {
  width: 100%;
  height: auto;
}

.img-btn ::v-deep img {
  width: 100%;
  height: auto;
}

.img-btn ::v-deep canvas,
.img-btn ::v-deep img,
.img-btn .fake-checkbox {
  pointer-events: none;
}

.delete-btn {
  position: absolute;
  color: setting(colors, border-dark);

  width: 18px;
  height: 20px;

  bottom: 2px; /* i hate this */
  left: 3px; /* and i hate this one pixel especially */

  display: flex;
  opacity: 0.75;

  align-items: center;
  justify-content: center;
  transition: all 0.2s;
}

.rtl .delete-btn {
  right: unset;
  right: 2px; /* yep, still hate it */
}

.delete-btn .trash-icon {
  display: inline-flex;
  width: 100%;
}

.delete-btn:hover,
.delete-btn:focus {
  color: setting(colors, error);
  transform: scale(1.2);
}
</style>
