import angular from 'angular';

/**
 * @ngdoc function
 * @name frontendApp.controller:LoginRecoverCtrl
 * @description
 * # LoginRecoverCtrl
 * Controller of the frontendApp
 */
angular.module('frontendApp').controller('LoginRecoverCtrl', [
  '$scope',
  'UserService',
  function($scope, UserService) {
    $scope.email = '';
    $scope.recover = function() {
      UserService.recoverUserPass($scope.email).then(function(response) {
        $scope.email = '';
        $scope.recoverForm.$setUntouched();
        $scope.recoverForm.$setPristine();
      });
    };
  }
]);
