<template>
  <div>
    <div
      v-if="subscriptionLoaded"
      class="subscribe-container"
      :class="{ mobile: $isMobile }"
    >
      <subscribe-successful
        v-if="userAlreadyOnPlan || subscriptionSucceeded"
        :user-already-on-plan="userAlreadyOnPlan"
        @startSigning="$emit('subscriptionCompleted')"
      ></subscribe-successful>
      <subscription-form v-else />
    </div>
    <v-layout v-else justify-center><Loading></Loading></v-layout>
  </div>
</template>

<script>
import SubscriptionForm from '@/components/subscribe/SubscriptionForm';
import SubscribeSuccessful from '@/components/subscribe/SubscribeSuccessful';
import Screen from '@/mixins/Screen';
import Loading from '@/components/Loading';

import { mapGetters, mapState } from 'vuex';

export default {
  components: {
    SubscriptionForm,
    SubscribeSuccessful,
    Loading
  },
  mixins: [Screen],
  computed: {
    ...mapState('subscription', [
      'userAlreadyOnPlan',
      'subscriptionSucceeded',
      'subscriptionLoaded'
    ]),
    ...mapGetters('conf', ['planCurrency', 'planPricing']),
    ...mapGetters('users', ['userLoggedIn', 'needs2FA'])
  }
};
</script>
<style lang="scss">
.subscribe-container {
  display: flex;
  margin: 0 auto;
  max-width: 52rem;
  overflow: hidden;
}

.mobile.subscribe-container {
  width: 100%;
  height: 100%;
  align-items: stretch;
}
.subscribe-errors {
  margin-top: 20px;
  line-height: 1rem;
  font-size: 0.86rem;

  .flex {
    margin-top: 5px;
  }
}
</style>
