<template>
  <div
    tabIndex="0"
    :title="title"
    role="button"
    class="sign-input"
    :class="{ 'sign-input--empty': !dataUri }"
    @click="handleClick"
    @keypress.enter="handleClick"
  >
    <label v-show="!dataUri" aria-hidden="true" class="primary--text">
      {{ label }}
    </label>
    <img
      v-show="dataUri"
      ref="img"
      aria-hidden="true"
      :src="dataUri"
      alt="signed"
      class="ph-signature"
    />
    <div
      v-if="required && !dataUri"
      class="sign-input--required"
      aria-hidden="true"
    >
      *
    </div>
    <sr-kite :priority="10" :value="!dataUri && required" />
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import SrKite from './SrKite.vue';
import { shortDate, formatShortDate } from '@/utils/getDefaultDate';

const RTL_REGEX = /[\u0591-\u07FF]/;

export function scale({
  sHeight,
  sWidth,
  dHeight,
  dWidth,
  rtl,
  bottom = true,
  upscale = false
}) {
  const scaleH = sHeight / dHeight;
  const scaleW = sWidth / dWidth;
  if (dHeight >= sHeight && dWidth >= sWidth && !upscale) {
    // If source image fits both height and width
    // put it as left-bottom (or right bottom for rtl).
    // Do no scaling -- source width and height are used as is.
    return {
      x: rtl ? dWidth - sWidth : 0,
      y: bottom ? dHeight - sHeight : 0,
      height: sHeight,
      width: sWidth
    };
  } else if (scaleH > scaleW) {
    // If source image better fits height of placeholder then
    // it's width, put it at left or right border depending on rtl flag.
    // Think of portrait image fitted into landscape frame.
    // With server-generated text placeholders, single line goes here
    // and is allowed to overflow to the left, because dWidth is set
    // to include all the space from left border of placeholder till the
    // right of the page.
    // Note: rtl should be handled by a caller.
    return {
      x: rtl ? dWidth - sWidth / scaleH : 0,
      y: 0,
      height: sHeight / scaleH,
      width: sWidth / scaleH
    };
  } else {
    // If it fits placeholder width, put source image at the bottom
    // of a frame regardless of rtl/ltr. Nobody writes from top to bottom, right?
    // Think of landscape image fitter into portrait frame.
    // With server-generated text placeholders, multine goes here
    // and is allowed to overflow till the bottom of the page.
    return {
      x: 0,
      y: bottom ? dHeight - sHeight / scaleW : 0,
      height: sHeight / scaleW,
      width: sWidth / scaleW
    };
  }
}

export default {
  components: { SrKite },
  MAX_ZOOM: 5,
  props: {
    text: { type: String, default: '' },
    width: { type: Number, required: true },
    height: { type: Number, required: true },
    scaleFactor: { type: Number, required: false, default: 1 },
    spaceRight: { type: Number, required: true },
    spaceLeft: { type: Number, required: true },
    spaceDown: { type: Number, required: true },
    actionRequired: { type: Boolean, default: true },
    required: { type: Boolean, default: false },
    editable: { type: Boolean, default: true },
    multiline: { type: Boolean, default: true },
    prefill: { type: [String, Boolean], default: false },
    fullType: { type: String, required: true },
    label: { type: String, default: '' },
    dateValue: { type: String, default: '' },
    dataUri: { type: String, default: '' }
  },
  computed: {
    ...mapGetters('conf', ['languageCode']),
    parentElement() {
      let el = this.$el;
      while (el && !el.classList.contains('page-card')) {
        el = el.parentNode;
      }
      return el;
    },
    defaultDate: shortDate(null, 'defaultDateFill'),
    title() {
      const emptyTitle = this.required
        ? this.$gettext('Enter % here, required').replace('%', this.label)
        : this.$gettext('Enter % here').replace('%', this.label);
      const filledTitle = `${this.label}: ${this.text}`;
      return this.text ? filledTitle : emptyTitle;
    },
    defaultDateFill() {
      return true;
    },
    placeholderDate() {
      if (this.dateValue && this.prefill) {
        // we prefill from prefill_tags with dateValue
        return formatShortDate(this.dateValue, this.languageCode, true);
      } else if (this.prefill) {
        // we prefill with current default date
        return this.defaultDate;
      } else {
        // we just create placeholder and do not prefill and ignore fillText on mount
        return null;
      }
    }
  },
  async mounted() {
    if (this.dataUri || !this.prefill) {
      return;
    }
    if (this.fullType === 'date' && this.placeholderDate) {
      this.fillText(this.placeholderDate);
    }
    if (this.fullType === 'text') {
      this.fillText(this.text);
    }
  },
  methods: {
    ...mapActions('modals', ['openTextEntryModal']),
    ...mapActions('signrequest', ['textToImage']),
    async fillText(text, multiline) {
      const sign = await this.rasterize(
        text,
        this.parentElement.getBoundingClientRect(),
        { ...this.$props, multiline }
      );
      if (!sign) {
        return null;
      }
      this.$emit('fill', sign);
    },
    async rasterize(text, parent, props) {
      const ret = await this.textToImage({ text });
      if (!ret || !ret.image) {
        return null;
      }
      const {
        height,
        width,
        spaceDown,
        spaceLeft,
        spaceRight,
        scaleFactor,
        multiline
      } = props;
      const sign = ret.image;
      const rtl = RTL_REGEX.test(sign.text);
      const scaledSize = scale({
        sHeight: sign.height * scaleFactor,
        sWidth: sign.width * scaleFactor,
        dHeight: parent.height * (multiline ? height + spaceDown : height),
        dWidth:
          parent.width *
          (multiline ? width : width + (rtl ? spaceLeft : spaceRight)),
        rtl,
        bottom: false
      });
      return {
        text: sign.text,
        overflowRTL: rtl && !multiline,
        data_uri: sign.data_uri || sign.src,
        ...scaledSize
      };
    },
    handleClick() {
      const { actionRequired, dataUri } = this.$props;
      if (!actionRequired || dataUri) {
        return null;
      }
      this.openModal();
    },
    select() {
      this.openModal();
    },
    async openModal() {
      const defaultText = this.fullType === 'date' ? this.defaultDate : '';
      const entry = await this.openTextEntryModal({
        fullType: this.$props.fullType,
        text: this.$props.text || defaultText,
        multiline: this.$props.multiline,
        couldChangeMultiline: this.$props.editable,
        maxColumns: this.$props.width * 96
      });
      this.$el.focus();
      if (!entry) {
        return;
      }
      this.fillText(entry.text, entry.multiline);
    }
  }
};
</script>
<style scoped>
.sign-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: left;
  font-size: 16px;
  letter-spacing: 0.27px;
}
.sign-input:focus {
  outline: none;
}
.sign-input.sign-input--empty {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ph-outer--small .sign-input {
  font-size: 12px;
}
.sign-input--required {
  display: block;
  position: absolute;
  top: calc(50% - 0.3em);
  left: 93%;
  line-height: 1em;
}
.sign-input label {
  pointer-events: none;
}
</style>
