import angular from 'angular';
import * as Sentry from '@sentry/browser';

/**
 * @ngdoc service
 * @name frontendApp.windowService
 * @description
 * # windowService that communicates with signrequest-js frontend api
 * Service in the frontendApp.
 */
angular.module('frontendApp').service('windowService', [
  '$window',
  '$rootScope',
  '$route',
  'utils',
  function($window, $rootScope, $route, utils) {
    var service = this;
    service.sr_window_id = null;
    service.next = null;

    service.init = function() {
      $rootScope.$on('$routeChangeSuccess', function(
        event,
        newRoute,
        oldRoute
      ) {
        if (!service.sr_window_id && $route.current) {
          if ($route.current.params.sr_window_id) {
            service.sr_window_id = $route.current.params.sr_window_id;
            service.next = $route.current.params.next || null;
            if (!service.next || service.next === '') {
              var close = $route.current.params.close;
              if (close && close !== 'false' && close !== '0') {
                service.next = 'close';
              }
            }
            service.emit('opened', {});
          }
        }
      });
    };

    $window.onbeforeunload = function() {
      service.emit('closed', {});
    };

    /**
     * Emits an event to the parent window. Pickup up by subscribers in signrequest-js
     * @param event_type
     * @param payload
     */
    service.emit = function(event_type, payload) {
      if (!$window.postMessage || !service.sr_window_id) {
        return;
      }

      const srLocation = $window.opener || $window.parent;
      if (srLocation && srLocation !== $window && document.referrer) {
        // we are in a popup or iframe
        payload = payload || {};
        try {
          srLocation.postMessage(
            JSON.stringify({
              event_type: event_type,
              payload: payload,
              sr_window_id: service.sr_window_id
            }),
            document.referrer
          );
        } catch (error) {
          Sentry.captureMessage(
            `Failed to postMessage for a referrer:"${document.referrer}"`
          );
          Sentry.captureException(error);
        }
      }
    };

    service.openLoadingPage = function() {
      var newWindow = $window.open('/#/loading');
      // 'pop under' behaviour, not working most of the time
      if ($window.blur) {
        newWindow.blur();
      }
      // focus this one again... hopefully (depends on browser)
      // hence the 'alert' hack if we receive an error
      if ($window.focus) {
        $window.focus();
      }
      return newWindow;
    };

    /**
     * When we open a loading page from the popup we want to keep the popup open to pass event through to the
     * original window that opened the popup.
     *
     * If we receive a closed event here that means that we are done signing and that the page we opened has closed
     * and that means we want to close ourselves (the popup) also (after publishing the event)
     */
    var onMessage = function(event) {
      if (utils.parseUrl(event.origin).hostname !== $window.location.hostname) {
        return;
      }
      if (typeof event.data !== 'string') {
        // we stringify our data ourselves so is never valid when not a string
        return;
      }
      try {
        var data = JSON.parse(event.data);
      } catch (err) {
        // not valid json, don't do anything
        return;
      }
      if (data.sr_window_id !== service.sr_window_id) {
        // message belongs to different listener or is just not valid
        return;
      }
      if (!data.event_type) {
        return;
      }
      if (data.event_type === 'closed') {
        // we want to close ourselves also, this triggers the service.emit('closed', {}) in the beforeunload
        $window.close();
      } else if (data.event_type !== 'opened') {
        // we should already passed the opened event in the popup so we don't pass that through

        // pass all other events to the parent popup window (which passes this to the signrequest-js opener)
        service.emit(data.event_type, data.payload);
      }
    };

    if ($window.addEventListener) {
      $window.addEventListener('message', onMessage.bind(this), false);
    } else {
      $window.attachEvent('onmessage', onMessage.bind(this));
    }
  }
]);
