<template>
  <v-dialog
    v-model="showModal"
    max-width="32.5rem"
    transition="fade-transition"
    :content-class="contentClass"
  >
    <v-card v-show="showModal" class="rounded-card">
      <Login :on-finish="onFinish" :modal-options="modalOptions" />
    </v-card>
  </v-dialog>
</template>

<script>
import ModalMixin from '@/components/modals/ModalMixin';
import Login from '../login/Login';
import { mapActions } from 'vuex';

export default {
  components: {
    Login
  },
  mixins: [ModalMixin('login')],
  props: {
    contentClass: { type: String, default: '' }
  },
  data() {
    return {
      returnValue: false
    };
  },
  watch: {
    showModal: {
      immediate: true,
      handler(showModal) {
        if (showModal) {
          const shownEventProps = { is_modal: true };
          if (this.modalOptions.doRegister) {
            this.trackRegisterFormShownEvent(shownEventProps);
          } else {
            this.trackLoginFormShownEvent(shownEventProps);
          }
        }
      }
    }
  },
  methods: {
    ...mapActions('events', [
      'trackLoginFormShownEvent',
      'trackRegisterFormShownEvent'
    ]),
    onFinish(isLoginSuccessful) {
      this.returnValue = isLoginSuccessful;
      this.closeModal();
    }
  }
};
</script>
<style scoped>
.rounded-card {
  border-radius: 15px;
}
</style>
