import 'intl-tel-input';

// This piece of code removes localized names of countries
// from phone input because A11Y wants to see them marked
// with lang attribute (we change language in the middle of string)
// and we don't know what language it's translated into.
// No, just doing <span lang="country.iso2" >name</span>
// is not going to solve it because country code can and is different
// from lang code (eg: uk_UA, zh_HK, fr_CH, be_NL and so on).
// The names are also specified in English, so we have to indicate that,
// as the language of the app can differ.
window.intlTelInputGlobals.getCountryData().forEach(entry => {
  const startIdx = entry.name.indexOf('(');
  const endIds = entry.name.indexOf(')');
  if (startIdx > 0 && endIds > 0) {
    entry.name = entry.name.substr(0, startIdx);
  }
  // yes, seriously, intl-tel-input just builds DOM
  // with insertAdjacentHTML
  entry.name = `<span lang="en">${entry.name}</span>`;
});
