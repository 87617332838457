import angular from 'angular';

/**
 * @ngdoc service
 * @name frontendApp.referralLinksService
 * @description
 * # referralLinksService
 * Service in the frontendApp.
 */
angular.module('frontendApp').service('referralLinksService', [
  '$http',
  'messageService',
  function referralLinksService($http, messageService) {
    this.getReferral = function() {
      return $http.get('/user/ref/get-referral/').then(
        function(resp) {
          return messageService.handleResponse(resp);
        },
        function(resp) {
          return messageService.handleError(resp);
        }
      );
    };

    this.createReferralLink = function() {
      return $http.post('/user/ref/create-referral-link/').then(
        function(resp) {
          return messageService.handleResponse(resp);
        },
        function(resp) {
          return messageService.handleError(resp);
        }
      );
    };

    this.createReferralClaim = function(claim_form) {
      return $http.post('/user/ref/add-referred-user/', claim_form).then(
        function(resp) {
          return messageService.handleResponse(resp);
        },
        function(resp) {
          return messageService.handleError(resp);
        }
      );
    };

    this.deleteReferralLink = function(identifier) {
      return $http
        .post('/user/ref/delete-referral-link/' + identifier + '/')
        .then(
          function(resp) {
            return messageService.handleResponse(resp);
          },
          function(resp) {
            return messageService.handleError(resp);
          }
        );
    };
  }
]);
