import { getVariant } from '@/utils/variant';
import accessibilityStatus from '@/mixins/TabDetectState';

export default {
  data() {
    return {
      accessibilityUsed: false,
      elListener: null
    };
  },
  computed: {
    accessibilityOn() {
      return getVariant() === 'sr' && this.accessibilityUsed;
    }
  },
  created() {
    this.elListener = document.querySelector('[data-app]');
    this.elListener.addEventListener('keydown', this.detectTab);
  },
  destroyed() {
    this.elListener.removeEventListener('keydown', this.detectTab);
    this.accessibilityUsed = false;
    accessibilityStatus.setState(false);
  },
  methods: {
    detectTab(e) {
      if (e.key === 'Tab') {
        accessibilityStatus.setState(true);
        this.accessibilityUsed = true;
      }
    }
  }
};
