<template>
  <sr-menu ref="menu" container-class="primary-text" top>
    <template v-slot:activator="{ on }">
      <sr-button
        class="p-lang primary--text"
        :class="{ 'p-lang--name': showName }"
        :sr-style="showName ? 'outline' : 'invert'"
        :round="!($isMobile && !showName)"
        :circle="$isMobile && !showName"
        :title="$gettext('Select language')"
        v-on="on"
      >
        <lang-label
          v-if="showName && language"
          :code="languageCode"
          v-bind="language"
        />
        <WorldIcon v-if="!showName" />
        <v-icon v-if="!$isMobile && !showName" class="primary--text">
          keyboard_arrow_down
        </v-icon>
      </sr-button>
    </template>
    <sr-button
      v-for="(lang, code) of langs"
      :key="code"
      role="menuitem"
      class="p-lang--entry"
      tabindex="-1"
      sr-style="text"
      @click="handleClick(code)"
    >
      <img
        class="p-lang--flag"
        alt="flag"
        :src="lang.flag"
        :aria-hidden="true"
      />
      <lang-label :code="code" v-bind="lang" />
    </sr-button>
  </sr-menu>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import Screen from '@/mixins/Screen';
import SrButton from '@/components/elements/SrButton';
import SrMenu from '@/components/elements/SrMenu';
import WorldIcon from './WorldIcon';
import LangLabel from './LangLabel';
import langs from '@/utils/langs';

export default {
  components: { SrButton, SrMenu, WorldIcon, LangLabel },
  mixins: [Screen],
  props: {
    showName: { type: Boolean, default: false }
  },
  computed: {
    ...mapGetters('conf', ['languageCode']),
    langs: () => langs,
    language() {
      return langs[this.languageCode];
    }
  },
  methods: {
    ...mapActions('conf', ['switchLang']),
    handleClick(lang) {
      this.switchLang({ lang });
      this.$refs.menu.close();
    }
  }
};
</script>
<style scoped>
.sr-menu {
  pointer-events: initial;
}
.sr-menu .sr-button.p-lang {
  font-size: 18px;
}
.site__footer .sr-menu .sr-button.p-lang {
  margin: 6px 0;
}
.sr-menu .sr-button.p-lang.p-lang--name {
  font-size: 16px;
  letter-spacing: 0.27px;
  font-weight: normal;
  min-height: 50px !important;
  padding: 0 1.5rem !important;
}
.sr-menu .sr-button--round.sr-button.p-lang {
  min-height: 36px;
  padding: 0px 9px;
  min-width: 66px;
}
.sr-menu::v-deep .sr-menu--content {
  min-width: 145px;
  max-height: calc(100vh - 40px);
  overflow-y: scroll;
}
.mobile .sr-menu /deep/ .sr-menu--content {
  top: 2rem !important;
}

.ltr .p-container--buttons .sr-menu::v-deep .sr-menu--content {
  right: 10px;
}
.rtl .p-container--buttons .sr-menu::v-deep .sr-menu--content {
  left: 10px;
}

.sr-menu::v-deep .sr-menu--arrow {
  display: none;
}

.sr-menu.sr-menu-open .v-icon {
  transform: rotate(180deg);
}

.sr-button.p-lang--entry {
  border-bottom: 1px solid #ddd;
  padding: 12px;
  font-size: 14px;
  pointer-events: initial;
  cursor: pointer;
  display: block;
  height: auto;
  min-width: unset;
  margin: 0;
  width: 100%;
}
.sr-button.p-lang--entry /deep/ .sr-button--content {
  justify-content: start;
}

.p-lang--entry [dir],
.p-lang--entry [dir] > * {
  display: inline-block;
}

.p-lang--entry .p-lang--flag {
  vertical-align: middle;
  margin: 0 12px;
  margin-bottom: 1px;
}
</style>
