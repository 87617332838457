import angular from 'angular';

/**
 * @ngdoc directive
 * @name frontendApp.directive:srSetting
 * @description
 * # srSetting
 */
angular.module('frontendApp').directive('srSetting', [
  function() {
    return {
      template: require('../../views/directives/account-setting.html'),
      restrict: 'A',
      link: function postLink(scope, element, attrs) {
        scope.srSetting = attrs.srSetting;
      }
    };
  }
]);
