<template>
  <div class="signer-entry">
    <div
      class="signer-icon-box"
      :style="{ color: getSignerColor(signerIndex) }"
    >
      <level-icon :value="value.level" />
    </div>
    <sr-button
      sr-style="text"
      inline
      class="signer-label"
      @click="$emit('select')"
    >
      {{ displayLabel }}
      {{ placeholderLabel ? ' - ' : '' }}
      <span class="signer-label-placeholder">{{ placeholderLabel }}</span>
    </sr-button>
    <input v-show="withOrder && canSign" v-model="order" name="order" />
  </div>
</template>
<script>
import makeFields from '@/utils/makeFields';
import signerLabel from '@/utils/signerLabel';
import { getSignerColor } from '@/utils/signerColors';
import { signs } from '@/utils/signerLevel';

import SrButton from '@/components/elements/SrButton';
import { LevelIcon } from './BoxSignerLevel';

export default {
  components: { LevelIcon, SrButton },
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    signerIndex: { type: Number, required: true },
    withOrder: { type: Boolean, required: false, default: false },
    value: { type: Object, required: true }
  },
  computed: {
    ...makeFields('order'),
    canSign() {
      return signs(this.value.level);
    },
    getSignerColor: () => getSignerColor,
    displayLabel() {
      return this.signerIndex
        ? signerLabel(this.value, this.signerIndex)
        : this.value.email || this.$gettext('Sender');
    },
    placeholderLabel() {
      if (this.signerIndex > 0 && !this.value.email) {
        return this.$gettext('Placeholder');
      }
      return null;
    }
  }
};
</script>
<style scoped>
.signer-entry {
  display: flex;
  align-items: center;
  padding: 6px 8px;
  margin: 0 -8px;
}
.signer-icon-box {
  background: currentColor;
  width: 32px;
  height: 32px;
  border-radius: 6.9px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.signer-icon-box svg {
  fill: white;
}
.signer-label {
  margin: 12px;
  flex-grow: 1;
}
.sr-button.signer-label /deep/ .sr-button--content {
  margin-left: 10px;
  justify-content: start;
}

.signer-label-placeholder {
  font-style: italic;
  color: #767676;
  margin-left: 6px;
}

input {
  width: 36px;
  height: 32px;
  border-radius: 6px;
  padding: 0px;
  text-align: center;
  box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px #bcbcbc;
}
input:focus {
  border: 1px solid #0061d5;
  outline: 0;
  box-shadow: none;
}
input:hover {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.15);
}
input:disabled {
  background-color: #f4f4f4;
}
</style>
