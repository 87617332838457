import angular from 'angular';

/**
 * @ngdoc directive
 * @name frontendApp.directive:srFlowType
 * @description
 * # srMatchheight
 */
angular.module('frontendApp').directive('srFlowType', [
  '$timeout',
  'docService',
  function($timeout, docService) {
    return {
      restrict: 'A',
      scope: true,
      link: function(scope, element, attrs) {
        scope.container = angular.element(element);
        scope.text_element = scope.container.find(attrs.srFlowType);
        scope.initial_font_size = parseInt(scope.text_element.css('font-size'));
        scope.initial_line_height = parseInt(
          scope.text_element.css('line-height')
        );
        scope.initial_width = attrs.srFlowTypeInitialWidth
          ? parseInt(attrs.srFlowTypeInitialWidth)
          : docService.initial_placeholder_width;

        scope.font_css = { 'font-size': scope.initial_font_size + 'px' };
        scope.hide_text = false;

        scope.$parent.$on('update-fonts', function() {
          $timeout(function() {
            if (scope.initial_width === 0) {
              scope.initial_width = parseInt(scope.text_element.css('width'));
              return;
            }
            var current_width = parseInt(scope.text_element.css('width'));
            var ratio = Math.min(
              1,
              (current_width / scope.initial_width) * 1.5
            );
            // scope.hide_text = (current_width < 60);
            scope.font_css['font-size'] =
              scope.initial_font_size * ratio + 'px';
            scope.font_css['line-height'] =
              scope.initial_line_height * ratio + 'px';
          }, 0);
        });
      }
    };
  }
]);
