import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{ref:"modal",attrs:{"lazy":"","max-width":"500","content-class":_vm.contentClass},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c(VCard,{class:{
      mobile: _vm.$isMobile
    },attrs:{"role":"dialog","aria-hidden":!_vm.showModal,"aria-modal":_vm.showModal,"aria-labelledby":"terms-gmodal-title"}},[_c('sr-button',{staticClass:"close",attrs:{"sr-style":"text","data-test-id":"close-member-upgrade-btn"},on:{"click":_vm.closeModal}},[_c(VIcon,{staticClass:"round",attrs:{"aria-label":"$gettext('Close confirmation dialog')"}},[_vm._v("\n        close\n      ")])],1),_c(VCardTitle,{staticClass:"headline",attrs:{"id":"terms-gmodal-title"}},[_c('span',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("\n        Please confirm\n      ")])]),(
        _vm.subscriptionDetails &&
          _vm.subscriptionDetails.details &&
          _vm.billingDetails &&
          _vm.billingDetails.details
      )?_c(VCardText,[_c('p',[_c('strong',[_vm._v("\n          "+_vm._s(_vm.plan_type_display)+"\n        ")])]),_c('p',[_c('small',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("Extra costs for text messages may apply")]),_c('small',[_vm._v("\n          ("+_vm._s(_vm.subscriptionDetails.details.product_pricing
              .send_text_message_display)+"/SMS)\n        ")]),_c('br'),_c('small',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("Excluding VAT (if applicable)")]),(_vm.billingDetails.details.vat_rate + 1)?_c('span',[_c('br'),_c('small',[_c('span',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("VAT rate")]),_vm._v("\n            :\n            "),_c('strong',[_c('span',[_vm._v(_vm._s(_vm.billingDetails.details.vat_rate * 100))]),_vm._v("\n              %\n            ")]),(_vm.billingDetails.details.country_name_for_vat)?_c('span',[_vm._v("\n              (\n              "),_c('span',[_vm._v(_vm._s(_vm.billingDetails.details.country_name_for_vat))]),_vm._v("\n              )\n            ")]):_vm._e()])]):_vm._e(),_c('br'),(_vm.subscriptionDetails.details.interval === 'year')?_c('small',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("\n          Your subscription can be cancelled per year\n        ")]):_vm._e(),(_vm.subscriptionDetails.details.interval === 'month')?_c('small',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("\n          Your subscription can be cancelled per month\n        ")]):_vm._e()])]):_c(VCardText,[_c('Loading')],1),_c(VCardActions,[_c('sr-button',{attrs:{"sr-style":"fancy","round":"","data-test-id":"submit-member-upgrade-btn","loading":_vm.isLoading},on:{"click":_vm.doMemberUpgrade}},[_c('translate',[_vm._v("Subscribe")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }