import angular from 'angular';
/**
 * @author Slawomir Zytko <slawek@amsterdam-standard.pl>
 * @copyright Amsterdam Standard Sp. Z o.o.
 */
(function() {
  'use strict';

  angular
    .module('frontendApp')
    .service('feedbackModalService', FeedbackModalService);

  FeedbackModalService.$inject = ['srModalFactory'];

  function FeedbackModalService(srModalFactory) {
    var modal = srModalFactory.create({
      controller: 'FeedbackModalCtrl',
      controllerAs: 'modal',
      templateUrl: 'partials/modals/feedback.html'
    });

    return modal;
  }
})();
