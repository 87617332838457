var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inline primary--text",attrs:{"tabindex":"-1"}},[_c('div',{staticClass:"ph-outer",class:[
      {
        'ph-outer--can-focus': _vm.canFocus,
        'ph-outer--active': _vm.actionRequired || _vm.editable,
        'ph-outer--small': _vm.small,
        'ph-outer--empty': _vm.editable && !_vm.dataUri,
        'ph-outer--only': _vm.signerIndex === null,
        'ph-outer--unassigned': _vm.signerIndex === -1
      },
      ("ph-outer--" + _vm.fullType)
    ],style:(_vm.signerColorStyle)},[_c('div',{staticClass:"ph-background"}),(_vm.editable && !_vm.dataUri)?_c('sr-placeholder-label',{staticClass:"ph-text",class:{ 'ph-text--multiline': _vm.multiline },style:(_vm.smallStyle),attrs:{"label":_vm.label,"signer-index":_vm.signerIndex,"is-standard":!_vm.comment,"type":_vm.fullType,"tabindex":"0"}}):(_vm.fullType === 'checkbox')?_c('sr-checkbox',{ref:"content",attrs:{"label":_vm.label,"editable":_vm.editable,"required":_vm.required,"action-required":_vm.actionRequired,"initial-value":_vm.prefill && _vm.checkboxValue,"data-uri":_vm.dataUri},on:{"fill":_vm.handleFill}}):_c('div',{staticClass:"ph-text"},[(_vm.fullType === 'signature')?_c('sr-signature',{ref:"content",attrs:{"data-uri":_vm.dataUri,"comment":_vm.comment,"action-required":_vm.actionRequired,"width":_vm.width + _vm.spaceRight,"height":_vm.height},on:{"fill":_vm.handleFill}}):_vm._e(),(
          _vm.textRasterFallback && (_vm.fullType === 'text' || _vm.fullType === 'date')
        )?_c('sr-server-text-signature',{ref:"content",attrs:{"full-type":_vm.fullType,"data-uri":_vm.dataUri,"label":_vm.label,"date-value":_vm.dateValue,"text":_vm.text,"action-required":_vm.actionRequired,"required":_vm.required,"editable":_vm.editable,"multiline":_vm.multiline,"prefill":_vm.prefill,"space-down":_vm.spaceDown,"space-right":_vm.spaceRight,"space-left":_vm.spaceLeft,"height":_vm.height,"width":_vm.width},on:{"fill":_vm.handleFill}}):_vm._e()],1),_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }