<template>
  <v-container
    v-if="banner"
    class="pa0 info-container info-container--banner"
    data-test-id="info-container"
  >
    <h1 v-translate class="black--text mt-0">
      {{
        $gettext('Boost efficiency with reliable e-signing').replace(
          '-',
          '\u2011'
        )
      }}
    </h1>
    <h2 v-translate class="primary--text">
      Find out how to start e-signing in no time
    </h2>

    <a
      href="https://www.youtube.com/watch?v=KtFrqeCSqv4"
      data-youtube="KtFrqeCSqv4"
      :title="
        $gettext(
          'Find out what this new design has to offer in the video below'
        )
      "
      rel="noopener noreferrer"
      @click.prevent="handleVideo"
    >
      <img
        :src="require('@/images/homepage-video-plug.jpg')"
        :alt="$gettext('Play video')"
      />
    </a>

    <p>
      <a rel="noopener noreferrer" :href="getPageUrlForLang('features')">
        <translate>See all features →</translate>
      </a>
    </p>
  </v-container>
  <v-container v-else class="pa0 info-container" :class="containerClasses">
    <h1
      v-if="hasFile"
      key="1"
      v-translate
      class="font-weight-bold black--text mt-0"
    >
      Great, just fill in your info and start signing!
    </h1>
    <h1 v-else key="2" v-translate class="font-weight-bold black--text mt-0">
      Get stuff signed.
    </h1>

    <h2 v-translate class="srlink--text mt-3">
      Get PDFs signed online for free.
    </h2>

    <add-file v-if="!hasFile">
      <sr-button class="x-large" sr-style="fancy" round>
        <v-icon class="icon">add_circle</v-icon>
        <sr-space />
        <translate>Add file to sign</translate>
      </sr-button>
    </add-file>
    <div class="info-container--bg2" style="visibility: hidden" />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AddFile from '@/components/elements/AddFile';
import SrButton from '../elements/SrButton';

export default {
  components: { AddFile, SrButton },
  props: {
    banner: { type: Boolean, default: true },
    hasFile: { type: Boolean, default: false }
  },
  computed: {
    ...mapGetters('conf', ['getPageUrlForLang']),
    containerClasses() {
      return {
        'info-container--step1': !this.$props.hasFile,
        'info-container--step2': this.$props.hasFile
      };
    }
  },
  methods: {
    ...mapActions('modals', ['openIframeModal']),
    handleVideo(event) {
      const { youtube: id } = event.currentTarget.dataset;
      this.openIframeModal({
        title: event.currentTarget.title,
        url: `https://youtube.com/embed/${id}?autoplay=1&rel=0&modestbranding=1&showinfo=0`,
        size: 'youtube'
      });
    }
  }
};
</script>

<style scoped>
.info-container {
  text-align: center;
  height: 100%;
  padding-top: 2.5rem;
  background-size: contain;
  min-height: 34rem;
  max-height: 45rem;
}
.info-container--step1 {
  background-image: url(/images/landing-background.png);
  background-position: right bottom;
}
.info-container--step2 {
  background-image: url(/images/landing-background-form.png);
  background-position: right bottom;
}

h1 {
  font-size: 60px;
  max-width: 580px;
  margin: auto;
  display: block;
  opacity: 0.9;
  line-height: 1.17;
  font-weight: 900;
}

h2 {
  font-size: 26px;
  letter-spacing: 1px;
  margin: auto;
}
p {
  font-size: 18px;
}
p a {
  font-size: 18px;
  font-weight: normal;
}

.info-container--banner h2 {
  margin-top: 0.5em;
  margin-bottom: 1em;
  line-height: 1.58;
  font-weight: 300;
  line-height: 1.69;
  letter-spacing: 1.08px;
}

button.x-large {
  font-size: 18px;
  height: 80px;
  padding: 0px 40px;
  font-weight: bold;
  letter-spacing: 0.3px;
  top: 2rem;
  display: none;
}

@media only screen and (min-width: 600px) {
  button.x-large {
    display: initial;
    min-width: 445px;
  }
  .info-container {
    min-height: 47.5rem;
  }
  .info-container--banner h2 {
    max-width: 19em;
  }
}
@media only screen and (max-width: 599px) {
  .info-container {
    min-height: 22rem;
  }
  .info-container--step2 {
    background-position: right bottom;
  }

  h1 {
    font-size: 30px;
  }
  h2 {
    font-size: 22px;
  }
}
</style>
