<template>
  <div
    ref="container"
    class="sr-kite-container"
    :class="{
      mobile: $isMobile
    }"
  >
    <div class="sr-kite-arrow-container" :style="arrowStyle">
      <div
        role="button"
        :title="$gettext('Scroll to next field')"
        class="sr-kite-box"
        :class="rotationClass"
        @click="handleClick"
      >
        <pointing-arrow />
      </div>
    </div>
  </div>
</template>
<script>
import orderBy from 'lodash/orderBy';
import Vue from 'vue';
import variant from '@/utils/variant';
import Screen from '@/mixins/Screen';
import ArrowDown from '../icons/ArrowDown.vue';
import SrArrowDown from '../icons/SrArrowDown.vue';
import zoomState from '@/components/document/zoomState';
import scrollState from '@/components/document/scrollState';

// make an invisible singleton instance!
// check SrKite.vue to see how it is populated
export const kiteState = new Vue({
  data() {
    return {
      known: Object.freeze([]),
      sequence: Object.freeze([])
    };
  },
  methods: {
    register(component) {
      const n = Array.from(this.known);
      n.push(component);
      this.known = Object.freeze(n);
      this.softUpdate(component.delay);
    },
    unregister(component) {
      let n = Array.from(this.known);
      n = n.filter(x => x !== component);
      this.known = Object.freeze(n);
      this.softUpdate(component.delay);
    },
    softUpdate(delay) {
      /* shitty implementation of a priority queue
         in theory, it should be fast as long
         we don't have more than 10 elements

         array itself is not reactive, but the values inside array are!
         so changes .value and .priority will trigger this computed property
      */
      const update = () => {
        let active = this.known.filter(comp => {
          return comp.value && comp.priority >= 0;
        });

        active = active.map(comp => ({ comp, priority: comp.priority }));
        active = orderBy(active, ['priority'], 'desc').map(({ comp }) => comp);
        this.sequence = Object.freeze(active);
      };

      // in the first version, sequence was a computed value
      // but we want to delay updates... so now it's data
      if (this._updateToken) {
        clearTimeout(this._updateToken);
      }

      // should not be slow enough for user to notice
      // but slow enough for vuex state changes to propagate
      this._updateToken = setTimeout(update, delay || 100);
    }
  }
});

export default {
  components: { PointingArrow: variant(ArrowDown, SrArrowDown) },
  mixins: [Screen],
  data() {
    return {
      targetRect: null,
      windowRect: null
    };
  },

  computed: {
    target() {
      if (!kiteState.sequence) return null;
      if (!kiteState.sequence.length) return null;

      return kiteState.sequence[0];
    },
    position() {
      // height of header
      const header = document.querySelector('header');
      const headerHeight = header ? header.offsetHeight : 60;
      const { targetRect, windowRect } = this;
      if (!targetRect) {
        return null;
      }

      // check bounds
      let rotation = 'top';
      let x = targetRect.left + targetRect.width / 2;
      let y = targetRect.top + targetRect.height / 2;

      // console.log('targetx', windowRect, targetRect, windowRect);
      if (windowRect) {
        // force rotation whenever near the edge
        const edge = 128;
        if (x - edge < windowRect.left) {
          rotation = 'right';
        }
        if (x + edge > windowRect.right) {
          rotation = 'left';
        }
        if (y - edge < windowRect.top) {
          rotation = 'bottom';
        }
        if (y + edge > windowRect.bottom) {
          rotation = 'top';
        }
      }

      // the rotation is clear now
      // we can define position more precisely
      if (rotation === 'left') {
        x -= targetRect.width / 2;
      } else if (rotation === 'right') {
        x += targetRect.width / 2;
      } else if (rotation === 'top') {
        y -= targetRect.height / 2;
      } else if (rotation === 'bottom') {
        y += targetRect.height / 2;
      }

      if (windowRect) {
        // correct positions for out of scroll
        x = Math.max(x, windowRect.left);
        x = Math.min(x, windowRect.right);
        y = Math.max(y, windowRect.top + headerHeight);
        y = Math.min(y, windowRect.bottom);
      }

      return { x, y, rotation };
    },
    rotationClass() {
      return this.position ? this.position.rotation : 'top';
    },
    arrowStyle() {
      const { position } = this;
      if (!position) {
        return { display: 'none' };
      }

      const { x, y } = position;
      return {
        left: `${x}px`,
        top: `${y}px`
      };
    },
    zoom() {
      return zoomState.zoom;
    },
    scrollY() {
      return scrollState.scrollY;
    },
    screenHeight() {
      return scrollState.screenHeight;
    }
  },

  watch: {
    target() {
      this.updatePosition();
    },
    scrollY() {
      this.updatePosition();
    },
    screenHeight() {
      this.updatePosition();
    },
    zoom() {
      setTimeout(() => {
        this.updatePosition();
      }, 200);
    }
  },

  mounted() {
    this.updatePosition();
  },

  methods: {
    handleClick() {
      const target = this.findTarget();
      if (target) {
        target.scrollIntoView({ block: 'center' });
      }
    },
    findTarget() {
      if (!this.target) {
        return null;
      }

      /** @type {HTMLElement} */
      let elm = this.target.$el;
      if (!elm) return null;

      // take first parent = container
      if (elm.parentElement) {
        elm = elm.parentElement;
      }

      // in sr-button immediate parent is not the container!
      // check if this is sr-button and take one element up (<button>)
      if (elm.className.indexOf('sr-button--content') !== -1) {
        elm = elm.parentElement;
      }
      return elm;
    },
    findTargetRect() {
      const target = this.findTarget();
      if (!target) {
        return null;
      }
      const bb = target.getBoundingClientRect();
      return Object.freeze(bb);
    },
    updatePosition() {
      this.targetRect = this.findTargetRect();

      if (this.$refs.container) {
        const bb = this.$refs.container.getBoundingClientRect();
        this.windowRect = Object.freeze(bb);
      }
    }
  }
};
</script>
<style scoped>
.sr-kite-container {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
  z-index: 100;
}

.sr-kite-arrow-container {
  width: 42px; /* size of an arrow */
  height: 42px; /* changing value anywhere else screws up transforms */
  position: absolute;

  transition: all 0.2s linear;
}

.sr-kite-point {
  /* indicator for debugging purposes */
  position: absolute;
  width: 16px;
  height: 16px;
  top: -8px;
  left: -8px;
  background-color: red;
}

.sr-kite-box {
  position: absolute;
  width: 100%;
  height: 100%;

  top: -100%;
  left: -50%;

  transform-origin: 50% 100%;
  transition: all 0.5s linear;
  opacity: 1;
  pointer-events: auto;
}

.sr-kite-box:hover {
  /* temporary hide the kite if hovered above it */
  opacity: 0;
}
.mobile .sr-kite-box:hover {
  /* ios does funny things with hover and clicks */
  opacity: 1;
}

.sr-kite-box.right {
  transform: rotate(90deg);
}

.sr-kite-box.left {
  transform: rotate(-90deg);
}

.sr-kite-box.top {
  transform: rotate(0deg);
}

.sr-kite-box.bottom {
  transform: rotate(180deg);
}

.sr-kite-box svg {
  position: relative;
  top: -16px; /* padding on the arrow itself (post-transform) */
  animation: bounce-to-point 2s infinite;
}

.signrequest-lib svg {
  background: #26c281;
  width: 48px;
  height: 48px;
  padding: 10px;
  border-radius: 50%;
}

@keyframes bounce-to-point {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-15px);
  }
  60% {
    transform: translateY(-5px);
  }
}
</style>
