<template>
  <div
    class="sign-input"
    :class="{ 'sign-input--empty': !dataUri }"
    tabIndex="0"
    :title="title"
    role="button"
    @click="handleClick"
    @keypress.enter="handleClick"
  >
    <label v-show="!dataUri" class="primary--text">{{ label }}</label>
    <img
      v-show="dataUri"
      ref="img"
      :src="dataUri"
      alt="signed"
      class="ph-signature"
    />
    <sr-kite :priority="10" :value="!dataUri" />
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { scale } from './SrServerTextSignature';
import SrKite from './SrKite.vue';

export default {
  components: { SrKite },
  props: {
    actionRequired: { type: Boolean, default: true },
    width: { type: Number, required: true },
    height: { type: Number, required: true },
    comment: { type: String, default: '' },
    dataUri: { type: String, default: '' }
  },
  computed: {
    ...mapGetters('signrequest', ['signatureOptions']),
    label() {
      return this.comment || this.$gettext('Signature');
    },
    title() {
      return this.$gettext('Place % here').replace('%', this.label);
    },
    parentElement() {
      let el = this.$el;
      while (
        el &&
        el.classList &&
        el !== document.body &&
        !el.classList.contains('page-card')
      ) {
        el = el.parentNode;
      }

      return el;
    }
  },
  methods: {
    ...mapActions('modals', ['openSignatureModal']),
    handleClick() {
      const { actionRequired, dataUri } = this.$props;
      if (!actionRequired || dataUri) {
        return null;
      }
      this.openModal();
    },
    select() {
      this.openModal();
    },
    async openModal() {
      const parent = this.parentElement.getBoundingClientRect();

      const sign = await this.openSignatureModal(this.signatureOptions);
      this.$el.focus();
      if (!sign) {
        return;
      }

      const scaledSize = scale({
        sHeight: sign.height,
        sWidth: sign.width,
        dHeight: this.height * parent.height,
        dWidth: this.width * parent.width,
        rtl: false, // Image signatures don't have direction anyway
        upscale: true
      });

      this.$emit('fill', {
        data_uri: sign.data_uri,
        overflowRTL: false,
        ...scaledSize
      });
    }
  }
};
</script>
<style scoped>
.sign-input,
input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.sign-input {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0.27px;
}
.sign-input:focus {
  outline: none;
}
.sign-input.sign-input--empty {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sign-input label {
  pointer-events: none;
}
</style>
