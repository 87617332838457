import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.modalOptions)?_c(VDialog,{ref:"modal",attrs:{"lazy":"","max-width":"436","content-class":_vm.contentClass},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c(VCard,{class:{
      mobile: _vm.$isMobile
    },attrs:{"role":"dialog","aria-hidden":!_vm.showModal,"aria-modal":_vm.showModal,"aria-labelledby":"decline-modal-label"}},[_c(VIcon,{staticClass:"round close",attrs:{"role":"button"},on:{"click":function($event){return _vm.close(false)}}},[_vm._v("\n      close\n    ")]),_c('h2',{staticClass:"v-card__title headline",attrs:{"id":"decline-modal-title"}},[_c('translate',[_vm._v("Please confirm")])],1),_c(VCardText,[_c('span',{staticClass:"srtext--text",attrs:{"id":"decline-modal-label"}},[_c('translate',[_vm._v("\n          Please confirm that you want to decline this document.\n        ")])],1)]),_c('sr-input',{ref:"message",attrs:{"name":"message","label":_vm.$gettext('Optional: give a reason for declining')},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}),_c(VCardActions,[_c('sr-button',{attrs:{"sr-style":"cancel","round":""},on:{"click":function($event){return _vm.close(false)}}},[_c('translate',[_vm._v("Cancel")])],1),_c('sr-button',{attrs:{"sr-style":"danger","round":""},on:{"click":function($event){return _vm.close(true)}}},[_c('translate',[_vm._v("Decline")])],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }