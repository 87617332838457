import angular from 'angular';
import { formatVersion } from '@/utils/version';
import config from '@/config';

/**
 * @ngdoc function
 * @name frontendApp.controller:LocaleCtrl
 * @description
 * # LocaleCtrl
 * Controller of the frontendApp
 */
angular.module('frontendApp').controller('LocaleCtrl', [
  '$scope',
  '$route',
  'confFactory',
  'confService',
  'localeService',
  'ngAsyncWrapper',
  function(
    $scope,
    $route,
    confFactory,
    confService,
    localeService,
    ngAsyncWrapper
  ) {
    $scope.confService = confService;
    $scope.localeService = localeService;
    $scope.appVersion = formatVersion(config.appVersion);

    $scope.checkForcedLang = function() {
      // when we have ?lang=en param we want to change to that language no matter what the settings of the user is
      var unwatch = $scope.$on(
        '$routeChangeSuccess',
        ngAsyncWrapper(async function() {
          var lang = $route.current.params.lang;
          var forced = false;
          if (lang && localeService.availableLangs.hasOwnProperty(lang)) {
            // we want to force a language
            if (!localeService.langIsActive(lang)) {
              forced = true;
              localeService.switchLang(lang, true);
            }
          }
          unwatch();

          if (!forced) {
            // check backend
            const settings = await confFactory.getSettings();
            if (settings.error || settings.aborted) {
              // do nothing
            } else if (
              !localeService.langIsActive(settings.trans.locale_code)
            ) {
              localeService.switchLang(settings.trans.locale_code, false);
            }
          }
        })
      );
    };

    // check cookie for language
    localeService.checkCookieLang();
    // check if we have a forced language as query param
    $scope.checkForcedLang();
  }
]);
