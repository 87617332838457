import * as Sentry from '@sentry/browser';
import angular from 'angular';
import _ from 'lodash';
import BRAND_CSS from '../brand';
import { store } from '../../store';
import { AB_TESTING_COOKIE_PREFIX } from '../../store/modules/experiments';

/**
 * @ngdoc service
 * @name frontendApp.userservice
 * @description
 * # userservice
 * Service in the frontendApp.
 */
angular
  .module('frontendApp')

  .service('UserService', [
    '$rootScope',
    '$vuex',
    '$http',
    '$window',
    '$location',
    'messageService',
    '$q',
    'srSubscribeModal',
    'srSubscribeTooMuchDocumentsModal',
    'srLoginModal',
    'utils',
    '$log',
    'ENV',
    'srTwoFactorLoginModal',
    'ngAsyncWrapper',
    'ipCookie',
    function UserService(
      $rootScope,
      $vuex,
      $http,
      $window,
      $location,
      messageService,
      $q,
      srSubscribeModal,
      srSubscribeTooMuchDocumentsModal,
      srLoginModal,
      utils,
      $log,
      ENV,
      srTwoFactorLoginModal,
      ngAsyncWrapper,
      ipCookie
    ) {
      var service = this;

      this._getUser = ngAsyncWrapper(async function(signer_uuid = null) {
        let user = await $vuex.dispatch('users/getUser', {
          forceReload: true,
          signerUUID: signer_uuid
        });
        if (!user) {
          return;
        }

        // was tracked before and this is a refresh of a user
        // we need this here for waitForTracked to always resolve
        $rootScope.user = {
          ...user,
          _tracked: Boolean($rootScope.user && $rootScope.user._tracked)
        };

        // user.needs_2fa is only true when logged in but not 2fa verified yet
        if ($rootScope.user && $rootScope.user.needs_2fa) {
          await service.openTwoFactorLoginModal();
          user = this.refreshUser(signer_uuid);
        } else if (service.userOverMonthLimit()) {
          $vuex.dispatch('modals/showTooMuchDocuments', {
            trigger: 'missing_pro_permissions',
            feature: 'unlimited_documents'
          });
        }

        //checks if user agreed to terms
        if (user && user.logged_in && user.agreed_to_terms == false) {
          const resp = await $vuex.dispatch('signrequest/checkTerms');
          if (resp !== true) {
            const url = '/user/action/logout/?next=/';
            global.location.assign(url);
          }
        }

        return user;
      });

      this._user_waiters = [];
      this.getUser = ngAsyncWrapper(async function(...args) {
        service._user_loading = true;
        const ret = await service._getUser(...args);
        service._user_waiters.forEach(resolve => resolve(ret));
        service._user_waiters = [];
        service._user_loading = false;
        return ret;
      });

      this.refreshUser = ngAsyncWrapper(async function(...args) {
        return this.getUser(...args, { forceReload: true });
      });

      this.refreshUserTags = ngAsyncWrapper(async function() {
        await $vuex.dispatch('users/getTags');
        $rootScope.isVueHomebox = $vuex.getters['conf/isVueHomebox'];
        $rootScope.oldApiFallback = $vuex.getters['conf/isOldApiFallback'];
      });

      /* Wait for user could be called:
         - when no user is loaded yet, before first getUser();
         - while getUser() is in progreess;
         - after getUser() resolved.
        In all of this cases NO request is triggered.
        In last case it should resolve immediately to current user */
      this.waitForUser = ngAsyncWrapper(async function() {
        if (!service._user_loading && $rootScope.user) {
          return $rootScope.user;
        }
        return new Promise(resolve => {
          service._user_waiters.push(resolve);
        });
      });

      this.getUserPermissions = function() {
        return $vuex.getters['users/userPermissions'];
      };

      this.userHasPerm = function(perm) {
        return $vuex.getters['users/hasPermission'](perm);
      };

      this.hasTrialEnded = function() {
        return Boolean($vuex.getters['users/hasTrialEnded']);
      };

      this.userOverMonthLimit = function() {
        return Boolean($vuex.getters['users/isOverMonthLimit']);
      };

      this.bulkSendAllowed = function() {
        return $vuex.getters['users/canSendBulkSend'];
      };

      this.hasPermOrModal = function(
        perm,
        feature,
        has_perm_callback,
        callback_arg
      ) {
        perm = perm || 'pro';
        if (!angular.isFunction(has_perm_callback)) {
          has_perm_callback = function() {};
        }
        if (service.userHasPerm(perm)) {
          has_perm_callback(callback_arg);
          return true;
        }
        if (!$rootScope.user.logged_in) {
          let next = '/#' + $location.url(); // redirect to the current route
          return $vuex.dispatch('modals/showLoginModal', { next: next });
        } else {
          const trigger = 'missing_pro_permissions';
          // We open the subscribe modal with a delay because input[number] element
          // will trigger "changed" earlier than the up/down buttons inside the element
          // will trigger "clicked"
          // This causes the following chain of events:
          // input[number] "changed" -> open modal -> button inside input "clicked" ->
          // click-outside modal detected -> close modal
          setTimeout(function() {
            $vuex.dispatch('modals/showSubscribeModal', {
              feature,
              trigger,
              new_subscribe: 'yes'
            });
          }, 500);
          return false;
        }
      };

      this.checkActiveTeamPermOrModal = function(has_perm_callback) {
        // checks if a team is active and if it is we check if the user may use this feature
        if (!angular.isFunction(has_perm_callback)) {
          has_perm_callback = function() {};
        }
        if ($vuex.getters['users/hasActiveTeam']) {
          // a team is active so check if it may be used
          return service.hasPermOrModal('teams', 'add_doc', has_perm_callback);
        } else {
          has_perm_callback();
          return true;
        }
      };

      this.openTwoFactorLoginModal = function() {
        // returns a promise that gets resolved when the modal closes;
        return srTwoFactorLoginModal.activate({}, { hideClose: true });
      };

      this.waitForTracked = function() {
        // use this to wait for user tracking to be finished
        var deferred = $q.defer();
        var unbind_wait_for_user = $rootScope.$watch('user._tracked', function(
          user_tracked
        ) {
          if (angular.isDefined(user_tracked) && user_tracked) {
            unbind_wait_for_user();
            deferred.resolve($rootScope.user);
          }
        });
        return deferred.promise;
      };

      this.loginUser = function(email, password) {
        return $vuex
          .dispatch('users/loginRequest', { email, password })
          .then(data => ({ data }));
      };

      var get2faDevices = function() {
        if (
          $rootScope.user &&
          $rootScope.user.has_2fa &&
          $rootScope.user.two_factor.devices.length
        ) {
          return _.filter($rootScope.user.two_factor.devices, {
            confirmed: true
          });
        }
        return [];
      };

      this.userHasTokenGeneratorDevice = function() {
        return _.some(get2faDevices(), { device_type: 'generator' });
      };

      this.userHasPhoneTokenDevice = function() {
        return _.some(get2faDevices(), { device_type: 'phone' });
      };

      this.userHasBackupTokenDevice = function() {
        return !!(
          $rootScope.user &&
          $rootScope.user.has_2fa &&
          $rootScope.user.two_factor.backup_device
        );
      };

      this.userHasPaidUsage = function() {
        return $vuex.getters['users/hasPermission']('pro');
      };

      this.add2faTOTPDevice = function(token) {
        // if token is given we are validating this device
        // data contains a qr_url to scan otherwise
        token = token || '';
        return $http.post('/user/2fa/add-totp-device/', { token: token }).then(
          function(resp) {
            return messageService.handleResponse(resp);
          },
          function(resp) {
            return messageService.handleError(resp);
          }
        );
      };

      this.add2faPhoneDevice = function(conf) {
        // conf: {number,method:'call'||'sms'} to add a number; {device_uuid,token} to verify the number
        return $http.post('/user/2fa/add-phone-device/', conf).then(
          function(resp) {
            return messageService.handleResponse(resp);
          },
          function(resp) {
            return messageService.handleError(resp);
          }
        );
      };

      this.create2faBackupCodes = function() {
        return $http.post('/user/2fa/create-backup-codes/').then(
          function(resp) {
            return messageService.handleResponse(resp);
          },
          function(resp) {
            return messageService.handleError(resp);
          }
        );
      };

      this.verify2faDevice = function(token, device_type, device_uuid) {
        return $http
          .post('/user/2fa/verify-device/', {
            token: token,
            device_type: device_type,
            device_uuid: device_uuid
          })
          .then(
            function(resp) {
              return messageService.handleResponse(resp);
            },
            function(resp) {
              return messageService.handleError(resp);
            }
          );
      };

      this.generate2faChallenge = function(device, method) {
        return $http
          .post('/user/2fa/generate-challenge/', {
            device_type: device.device_type,
            device_uuid: device.uuid,
            method: method
          })
          .then(
            function(resp) {
              return messageService.handleResponse(resp);
            },
            function(resp) {
              return messageService.handleError(resp);
            }
          );
      };

      this.processTrackingUserResponse = function(response, tracking_params) {
        if ($rootScope.user) {
          $rootScope.user._tracked = true;
        }
        $vuex
          .dispatch('trackEvents/processTrackingUserResponse', {
            response,
            tracking_params,
            user: $rootScope.user
          })
          .then(data => ({ data }));
      };

      this.last_tracked_url = null;

      this.trackUserPageView = function(signer_uuid) {
        service.waitForUser().then(async function() {
          let tracking_params = await $vuex.dispatch(
            'trackEvents/getTrackingParams',
            { user: $rootScope.user }
          );
          let dataLayer = (window.dataLayer = window.dataLayer || []);

          // we only want to track a pageview if it's not the same as the last so we can call trackUserPageView
          // in function where we want to be sure this generates a page view (but only one when
          // also $routeChangeSuccess might track it)
          if (service.last_tracked_url !== tracking_params.url) {
            service.last_tracked_url = tracking_params.url;

            // tracks the pageview using google tagmanager
            dataLayer.push({
              event: 'content-view',
              'content-name': tracking_params.url,
              ...tracking_params
            });

            $http
              .post('/user/trk/', {
                ...tracking_params,
                signer_uuid: signer_uuid || null
              })
              .then(function(response) {
                service.processTrackingUserResponse(response, tracking_params);

                dataLayer.push({
                  event: 'User Loaded',
                  sr_uuid: response.data.sr_uuid || null,
                  user: response.data.user || null
                });

                if (
                  ENV.debug ||
                  ($rootScope.user ? $rootScope.user.is_staff : false)
                ) {
                  $log.info(
                    'Tracking Page View: ' + tracking_params.url,
                    tracking_params,
                    response.data
                  );
                }
              });
          }
        });
      };

      this.getCurrentAbTestVariations = function() {
        // get all cookies values added to this user for ab tests
        var current = {};
        _.forEach(ipCookie(), function(v, k) {
          if (k.indexOf(AB_TESTING_COOKIE_PREFIX) === 0) {
            current[k.replace(AB_TESTING_COOKIE_PREFIX, 'ab_')] = v;
          }
        });
        return current;
      };

      /**
       * Track events that happen in the frontend to GA and our DB
       * @param eventAction
       * @param eventCategory
       * @param eventParams
       */
      this.trackEvent = function(eventAction, eventCategory, eventParams) {
        return $vuex.dispatch('trackEvents/trackEvent', {
          action: eventAction,
          category: eventCategory,
          params: eventParams
        });
      };

      this.getBillingDetails = function() {
        return $http.get('/orders/billing/get-or-set-billing-details').then(
          function(resp) {
            return messageService.handleResponse(resp);
          },
          function(resp) {
            return messageService.handleError(resp);
          }
        );
      };

      this.trackSocialAction = function(action_type) {
        return $http.post('/user/auth/social-share/', {
          action_type: action_type
        });
      };

      this.registerUser = function(email, pass1, pass2, next) {
        // store where we want to go next when they click the register link in the email, for example OAuth consent
        next = next || '';
        return $vuex
          .dispatch('users/registerRequest', {
            email: email,
            password1: pass1,
            password2: pass2,
            next: next
          })
          .then(data => ({ data }));
      };

      this.resetUserPass = function(token, pass1, pass2) {
        return $http
          .post('/user/auth/reset-pass/', {
            token: token,
            new_password1: pass1,
            new_password2: pass2
          })
          .then(
            function(resp) {
              return messageService.handleResponse(resp);
            },
            function(resp) {
              return messageService.handleError(resp);
            }
          );
      };

      this.recoverUserPass = function(email) {
        return $http.post('/user/auth/recover-pass/', { email: email }).then(
          function(resp) {
            return messageService.handleResponse(resp);
          },
          function(resp) {
            return messageService.handleError(resp);
          }
        );
      };

      this.validateAlternativeEmail = function(email, token) {
        return $http
          .post('/user/auth/validate-email/', { email: email, token: token })
          .then(
            function(resp) {
              return messageService.handleResponse(resp);
            },
            function(resp) {
              return messageService.handleError(resp);
            }
          );
      };

      this.validateSignRequestEmail = function(email, token, signrequest) {
        return $http
          .post('/user/auth/send-signrequest/', {
            email: email,
            token: token,
            signrequest: signrequest
          })
          .then(
            function(resp) {
              return messageService.handleResponse(resp);
            },
            function(resp) {
              return messageService.handleError(resp);
            }
          );
      };

      this.validateSignRequestOauth = function(signrequest) {
        return $http
          .post('/user/auth/send-signrequest/', { signrequest: signrequest })
          .then(
            function(resp) {
              return messageService.handleResponse(resp);
            },
            function(resp) {
              return messageService.handleError(resp);
            }
          );
      };

      this.addAlternativeEmail = function(email) {
        return $http.post('/user/auth/add-email/', { email: email }).then(
          function(resp) {
            return messageService.handleResponse(resp);
          },
          function(resp) {
            return messageService.handleError(resp);
          }
        );
      };
      this.delAlternativeEmail = function(email) {
        return $http.post('/user/auth/del-email/', { email: email }).then(
          function(resp) {
            return messageService.handleResponse(resp);
          },
          function(resp) {
            return messageService.handleError(resp);
          }
        );
      };

      this.inviteMember = function(email, team_user_uuid) {
        return $http
          .post('/user/auth/team/invite-user/', {
            email: email,
            team_user_uuid: team_user_uuid
          })
          .then(
            function(resp) {
              return messageService.handleResponse(resp);
            },
            function(resp) {
              return messageService.handleError(resp);
            }
          );
      };

      this.removeMember = function(team_user_uuid) {
        return $http
          .post('/user/auth/team/remove-team-member/', {
            team_user_uuid: team_user_uuid
          })
          .then(
            function(resp) {
              return messageService.handleResponse(resp);
            },
            function(resp) {
              return messageService.handleError(resp);
            }
          );
      };

      this.activateUser = function(token) {
        const { trackFacebookCompleteRegistrationEvent } = this;
        return $http.get('/user/auth/activate/' + token + '/').then(
          async function(resp) {
            await trackFacebookCompleteRegistrationEvent();
            return messageService.handleResponse(resp);
          },
          function(resp) {
            return messageService.handleError(resp);
          }
        );
      };

      this.trackFacebookCompleteRegistrationEvent = function() {
        const trackingPixel = new Image();
        const fbTracked = new Promise(resolve => {
          trackingPixel.onload = resolve;
        });
        const timeout = new Promise(resolve => {
          setTimeout(resolve, 500);
        });

        trackingPixel.src =
          'https://www.facebook.com/tr?id=369959303387713&ev=CompleteRegistration';

        return Promise.race([fbTracked, timeout]);
      };

      this.cancelDeleteUser = function() {
        return $http.post('/user/auth/cancel-delete-user/').then(
          function(resp) {
            return messageService.handleResponse(resp);
          },
          function(resp) {
            return messageService.handleError(resp);
          }
        );
      };

      this.updateNotificationSettings = function(notification_type, settings) {
        return $http
          .post(
            '/user/auth/update-notification-settings/' +
              notification_type +
              '/',
            settings
          )
          .then(
            function(resp) {
              return messageService.handleResponse(resp);
            },
            function(resp) {
              return messageService.handleError(resp);
            }
          );
      };

      const color_re = new RegExp('#366cd8', 'g');
      const css_brand_tag = document.createElement('style');
      css_brand_tag.type = 'text/css';
      css_brand_tag.id = 'brand_css';
      document.getElementsByTagName('head')[0].appendChild(css_brand_tag);

      this.setColorTemplate = function(color) {
        if (color && color !== '#366cd8') {
          css_brand_tag.textContent = BRAND_CSS.replace(color_re, color);

          ngAsyncWrapper(async function() {
            await store.dispatch('conf/setThemeFromAngular', {
              primaryColor: color
            });
          })();
        } else {
          css_brand_tag.textContent = '';
        }
      };

      var _setThemeFromTeam = function(team) {
        $rootScope.branded = false;
        $rootScope.subdomain = '';
        if (team) {
          if (team.subdomain) {
            $rootScope.branded = true;
            $rootScope.subdomain = team.subdomain;
          }
          $rootScope.extra_css = team.extra_css;
          $rootScope.primary_color = team.primary_color;
          $rootScope.team_delete_after = team.delete_after;
          $rootScope.logo = team.logo;
        } else {
          $rootScope.extra_css = '';
          $rootScope.primary_color = '';
          $rootScope.logo = '';
        }
        service.setColorTemplate($rootScope.primary_color);
      };

      this.setThemeFromTeam = _setThemeFromTeam;

      // team services
      this.switchTeam = function(team_user) {
        return $vuex.dispatch('users/switchTeam', team_user);
      };

      this.createUpdateTeam = function(team) {
        return $http.post('/user/auth/team/update-team/', team).then(
          function(resp) {
            return messageService.handleResponse(resp);
          },
          function(resp) {
            return messageService.handleError(resp);
          }
        );
      };

      this.deleteTeam = function(team) {
        return $http.post('/user/auth/team/prepare_for_delete/', team).then(
          function(resp) {
            return messageService.handleResponse(resp);
          },
          function(resp) {
            return messageService.handleError(resp);
          }
        );
      };

      this.cancelDeleteTeam = function(team) {
        return $http.post('/user/auth/team/cancel_delete/', team).then(
          function(resp) {
            return messageService.handleResponse(resp);
          },
          function(resp) {
            return messageService.handleError(resp);
          }
        );
      };

      this.checkSubDomain = function(team) {
        return $http.post('/user/auth/team/check-domain/', team).then(
          function(resp) {
            return messageService.handleResponse(resp);
          },
          function(resp) {
            return messageService.handleError(resp);
          }
        );
      };

      this.createApiToken = function(conf) {
        return $http.post('/user/auth/team/create-api-token/', conf).then(
          function(resp) {
            return messageService.handleResponse(resp);
          },
          function(resp) {
            return messageService.handleError(resp);
          }
        );
      };

      this.deleteApiToken = function(conf) {
        return $http.post('/user/auth/team/delete-api-token/', conf).then(
          function(resp) {
            return messageService.handleResponse(resp);
          },
          function(resp) {
            return messageService.handleError(resp);
          }
        );
      };

      // signature services
      this.newSig = function() {
        return { uuid: null, data_uri: '', width: 0, height: 0, index: 0 };
      };

      this.saveSig = function(sig) {
        return $http.post('/user/auth/save-sig/', sig).then(
          function(resp) {
            return messageService.handleResponse(resp);
          },
          function(resp) {
            // return messageService.handleError(resp);
          }
        );
      };

      this.delSig = function(sig) {
        return $http.post('/user/auth/del-sig/', sig).then(
          function(resp) {
            return messageService.handleResponse(resp);
          },
          function(resp) {
            return messageService.handleError(resp);
          }
        );
      };

      this.createPayment = function(conf) {
        conf = conf || {};
        return $http
          .post('/orders/payments/create-payment/', {
            team_user_uuid: conf.team_user_uuid || null,
            amount: conf.amount || 0.01,
            next: conf.next || null
          })
          .then(
            function(resp) {
              let data = resp.data;
              if (data.redirect_url) {
                $window.location = utils.redirectFilter(data.redirect_url);
              }
              return messageService.handleResponse(resp);
            },
            function(resp) {
              return messageService.handleError(resp);
            }
          );
      };

      this.getPaymentInfo = function(uuid) {
        return $http.get('/orders/payments/get-payment-info/' + uuid).then(
          function(resp) {
            return messageService.handleResponse(resp);
          },
          function(resp) {
            return messageService.handleError(resp);
          }
        );
      };

      this.checkUser = function(email) {
        return $http
          .get('/user/auth/check-user/?email=' + encodeURIComponent(email))
          .then(
            function(resp) {
              return resp;
            },
            function(resp) {
              return resp;
            }
          );
      };

      this.changeEmailRequest = function(new_email) {
        return $http
          .post('/user/auth/request-email-change/', { new_email: new_email })
          .then(
            function(resp) {
              return resp;
            },
            function(resp) {
              return resp;
            }
          );
      };

      this.validateEmailRequest = function(email_type, token) {
        return $http
          .post('/user/auth/validate-email-change/', {
            email_type: email_type,
            token: token
          })
          .then(
            function(resp) {
              return resp;
            },
            function(resp) {
              return resp;
            }
          );
      };
      this.logout = function() {
        $http.post('/user/action/logout/', {}).then(
          function(resp) {
            window.location.href = '/';
          },
          function(resp) {
            window.location.href = '/';
          }
        );
      };

      this.reactivateAccount = function(token) {
        return $http
          .post('/user/auth/validate-account-reactivation/', {
            token: token
          })
          .then(
            function(resp) {
              return resp;
            },
            function(resp) {
              return resp;
            }
          );
      };

      this.getAllSessions = function() {
        return $http.get('/user/auth/sessions/').then(
          function(resp) {
            return messageService.handleResponse(resp);
          },
          function(resp) {
            return messageService.handleError(resp);
          }
        );
      };

      this.getAllOAuths = function() {
        return $http.get('/user/auth/oauths/').then(
          function(resp) {
            return messageService.handleResponse(resp);
          },
          function(resp) {
            return messageService.handleError(resp);
          }
        );
      };

      this.deleteOAuth = function(uid) {
        return $http.post('/user/auth/oauths/delete/', { uid: uid }).then(
          function(resp) {
            return messageService.handleResponse(resp);
          },
          function(resp) {
            return messageService.handleError(resp);
          }
        );
      };

      this.associateOAuth = function() {
        return $http
          .post('/user/auth/oauths/token/', { post: 1 }) // we not some payload, to trigger the POST in the backend
          .then(
            function(resp) {
              return messageService.handleResponse(resp);
            },
            function(resp) {
              return messageService.handleError(resp);
            }
          );
      };
    }
  ]);
