import _ from 'lodash';

export default function weightedValueArray(variations) {
  return _.reduce(
    variations,
    function(acc, weight, val) {
      _.times(weight, function() {
        acc.push(val);
      });

      return acc;
    },
    []
  );
}
