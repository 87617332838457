<template>
  <v-dialog
    v-model="showModal"
    max-width="32.5rem"
    :content-class="contentClass"
  >
    <v-card>
      <v-card-text>
        <h1 v-if="hasRequiredAttachments" key="r" class="srtext--text">
          Please upload the following
        </h1>
        <h1 v-else key="f" class="srtext--text">
          Upload attachments
        </h1>
      </v-card-text>
      <v-card-text
        v-for="attachment in requiredAttachments"
        :key="attachment.uuid"
      >
        <div
          v-if="attachment.uploads && attachment.uploads.length > 0"
          class="primary--text attachment--file"
        >
          <div
            v-for="upload in attachment.uploads"
            :key="upload.uuid"
            class="file-list--item"
          >
            <div class="file-list--bg"></div>
            <div class="filename" :title="upload.name">
              {{ upload.name }}
            </div>
            <a
              data-test-id="remove"
              role="button"
              class="file-list--item--remove"
              :title="$gettext('Remove attachment')"
              @click="
                removeSignerAttachment({
                  attachmentUUID: upload.uuid
                })
              "
            >
              <remove />
            </a>
          </div>
          <sr-file-select @file="handleUpload(attachment.uuid, $event)">
            <a class="attachment--requirement primary--text" role="button">
              <translate>+ Add file</translate>
            </a>
          </sr-file-select>
          <label>{{ attachment.name || $gettext('Attachment') }}</label>
        </div>
        <sr-file-select v-else @file="handleUpload(attachment.uuid, $event)">
          <a class="attachment--first-requirement primary--text" role="button">
            <translate>+ Add</translate>
            <span></span>
            {{ attachment.name || $gettext('attachment') }}
          </a>
        </sr-file-select>
      </v-card-text>

      <v-card-text class="v-card__text__buttons">
        <sr-button sr-style="cancel" round @click="handleClose">
          <translate>Cancel</translate>
        </sr-button>

        <sr-button
          data-test-id="save"
          sr-style="fancy"
          round
          :disabled="!requiredAttachmentsFilled"
          @click="handleClose"
        >
          <translate>Save</translate>
        </sr-button>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import ModalMixin from '@/components/modals/ModalMixin';
import SrFileSelect from '@/components/elements/SrFileSelect';
import SrButton from '@/components/elements/SrButton';

import Remove from '@/components/signrequest/Remove';

export default {
  components: {
    Remove,
    SrButton,
    SrFileSelect
  },
  mixins: [ModalMixin('upload-attachments')],
  props: {
    contentClass: { type: String, default: '' }
  },
  computed: {
    ...mapGetters('signrequest', [
      'selectedFileInfo',
      'hasRequiredAttachments',
      'requiredAttachments',
      'requiredAttachmentsFilled'
    ])
  },
  methods: {
    ...mapActions('signrequest', [
      'addSignerAttachment',
      'removeSignerAttachment'
    ]),
    ...mapActions('events', ['trackAddingAttachmentEvent']),
    handleUpload(uuid, file) {
      this.addSignerAttachment({ file, uuid });
      this.trackAddingAttachmentEvent();
    },
    handleClose() {
      this.closeModal();
    }
  }
};
</script>
<style scoped>
/deep/ .v-dialog {
  border-radius: 2rem;
}

h1 {
  font-size: 24px;
  opacity: 0.9;
  font-weight: 900;
}
.v-card__text {
  position: relative;
  padding: 25px 30px;
}

.v-card__text:not(:last-child)::after {
  width: 100%;
  border-bottom: 1px solid #d6d6d6;
  transform: scaleY(0.5);
  content: ' ';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}
a.attachment--requirement {
  display: block;
  padding: 0.25em 0.5rem;
  white-space: nowrap;
  font-size: 16px;
  font-weight: bold;
}

a.attachment--first-requirement {
  font-size: 16px;
  font-weight: bold;
}
a.attachment--first-requirement:hover,
a.attachment--requirement:hover {
  text-decoration: none;
}
.v-card__text__buttons {
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  justify-content: space-between;
}
.v-card__text__buttons button {
  font-size: 18px;
  padding: 0em 3.5em;
  margin: 0px;
  height: 3em;
  width: calc(50% - 10px);
}
.attachment--file {
  position: relative;
  display: flex;
  max-width: 100%;
  top: 0.5em;
}
.attachment--file label {
  position: absolute;
  color: rgba(0, 0, 0, 0.6);
  font-size: 13px;
  top: -2em;
}
.file-list--item {
  position: relative;
  padding: 0.25em 1rem;
  margin: 0 0.5em;
  min-width: 45px;
  font-size: 16px;
  font-weight: bold;
}
.ltr .file-list--item:first-child {
  margin-left: 0;
}
.rtl .file-list--item:first-child {
  margin-right: 0;
}

.file-list--item .file-list--bg {
  opacity: 0.1;
  background: currentColor;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 1.5em;
}
.attachment--file .filename {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  position: relative;
  line-height: 1.2em;
  vertical-align: text-top;
  max-width: calc(100% - 20px);
}
.file-list--item--remove {
  height: 1.2em;
  width: 1em;
  display: inline-block;
  font-size: 10px;
  margin-top: 0.5em;
  vertical-align: text-top;
  position: relative;
}
.ltr .file-list--item--remove {
  margin-left: 1em;
}
.rtl .file-list--item--remove {
  margin-right: 1em;
}

.file-list--item--remove svg {
  display: block;
}
</style>
