import angular from 'angular';

angular.module('frontendApp').service('orgUserService', [
  '$http',
  function($http) {
    this.getUsers = function() {
      var url = 'user/auth/get-team-users/';

      return $http.get(url).then(
        function(resp) {
          return resp;
        },
        function(resp) {
          return resp;
        }
      );
    };
  }
]);
