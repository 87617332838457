import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.banner)?_c(VContainer,{staticClass:"pa0 info-container info-container--banner",attrs:{"data-test-id":"info-container"}},[_c('h1',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"black--text mt-0"},[_vm._v("\n    "+_vm._s(_vm.$gettext('Boost efficiency with reliable e-signing').replace(
        '-',
        '\u2011'
      ))+"\n  ")]),_c('h2',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"primary--text"},[_vm._v("\n    Find out how to start e-signing in no time\n  ")]),_c('a',{attrs:{"href":"https://www.youtube.com/watch?v=KtFrqeCSqv4","data-youtube":"KtFrqeCSqv4","title":_vm.$gettext(
        'Find out what this new design has to offer in the video below'
      ),"rel":"noopener noreferrer"},on:{"click":function($event){$event.preventDefault();return _vm.handleVideo.apply(null, arguments)}}},[_c('img',{attrs:{"src":require('@/images/homepage-video-plug.jpg'),"alt":_vm.$gettext('Play video')}})]),_c('p',[_c('a',{attrs:{"rel":"noopener noreferrer","href":_vm.getPageUrlForLang('features')}},[_c('translate',[_vm._v("See all features →")])],1)])]):_c(VContainer,{staticClass:"pa0 info-container",class:_vm.containerClasses},[(_vm.hasFile)?_c('h1',{directives:[{name:"translate",rawName:"v-translate"}],key:"1",staticClass:"font-weight-bold black--text mt-0"},[_vm._v("\n    Great, just fill in your info and start signing!\n  ")]):_c('h1',{directives:[{name:"translate",rawName:"v-translate"}],key:"2",staticClass:"font-weight-bold black--text mt-0"},[_vm._v("\n    Get stuff signed.\n  ")]),_c('h2',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"srlink--text mt-3"},[_vm._v("\n    Get PDFs signed online for free.\n  ")]),(!_vm.hasFile)?_c('add-file',[_c('sr-button',{staticClass:"x-large",attrs:{"sr-style":"fancy","round":""}},[_c(VIcon,{staticClass:"icon"},[_vm._v("add_circle")]),_c('sr-space'),_c('translate',[_vm._v("Add file to sign")])],1)],1):_vm._e(),_c('div',{staticClass:"info-container--bg2",staticStyle:{"visibility":"hidden"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }