<template>
  <v-btn-toggle :value="value" mandatory @change="$emit('input', $event)">
    <v-btn v-for="l in limits" :key="l" :value="l" flat>
      {{ l }}
    </v-btn>
  </v-btn-toggle>
</template>

<script>
export default {
  name: 'PageLimitSelector',
  props: {
    value: { type: Number, default: 10 }
  },
  data: function() {
    return {
      limits: [10, 30, 50, 100]
    };
  }
};
</script>
