<template>
  <v-dialog
    ref="modal"
    v-model="showModal"
    lazy
    max-width="443"
    :content-class="contentClass"
  >
    <v-form ref="form" v-model="formValid" @submit.prevent="handleSubmit">
      <v-card
        role="dialog"
        :aria-hidden="!showModal"
        :aria-modal="showModal"
        aria-labelledby="terms-gmodal-title"
        :class="{
          mobile: $isMobile
        }"
      >
        <sr-button
          class="close"
          sr-style="text"
          data-test-id="close-password-btn"
          @click="closeModal"
        >
          <v-icon
            aria-label="$gettext('Close confirmation dialog')"
            class="round"
          >
            close
          </v-icon>
        </sr-button>
        <v-card-title id="terms-gmodal-title" class="headline">
          <span key="enter" v-translate>
            Enter password
          </span>
        </v-card-title>
        <v-card-text>
          <p>
            <translate>This document is password protected</translate>
            <a
              v-translate
              :href="getPageUrlForLang('password_protected')"
              target="_blank"
              rel="noopener noreferrer"
              class="learn-more-left-margin"
            >
              Learn more
            </a>
          </p>
        </v-card-text>
        <sr-input
          ref="password"
          v-model="password"
          name="password"
          :label="$gettext('Enter document password')"
          :rules="[fieldRequired]"
          class="password-input"
        />
        <v-card-actions>
          <sr-button
            sr-style="cancel"
            :loading="isLoading"
            round
            data-test-id="cancel-password-btn"
            @click="closeModal"
          >
            <translate>
              Cancel
            </translate>
          </sr-button>
          <sr-button
            :loading="isLoading"
            sr-style="fancy"
            round
            data-test-id="verify-password-btn"
            type="submit"
          >
            <translate>Verify</translate>
          </sr-button>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import SrButton from '@/components/elements/SrButton';
import ModalMixin from '@/components/modals/ModalMixin';
import Screen from '@/mixins/Screen';
import SrInput from '@/components/elements/SrInput';

export default {
  components: { SrButton, SrInput },
  mixins: [ModalMixin('enterPassword'), Screen],
  props: {
    contentClass: { type: String, default: '' }
  },
  data: function() {
    return {
      password: '',
      returnValue: false,
      formValid: false
    };
  },
  computed: {
    ...mapGetters('conf', ['getPageUrlForLang']),
    ...mapGetters('api', ['isLoading']),
    fieldRequired() {
      return value => (value ? true : this.$gettext('This field is required'));
    }
  },
  watch: {
    showModal(value, oldValue) {
      if (value) {
        this.$nextTick(() => this.focusFirst());
      }
    }
  },
  methods: {
    ...mapActions('modals', ['verifyDocumentPassword']),
    focusFirst() {
      this.$refs.password.focus();
    },
    async handleSubmit() {
      if (this.formValid) {
        await this.verifyDocumentPassword({
          password: this.password,
          signer_uuid: this.modalOptions.signer_uuid
        });
        // backend doesn't return status code and we don't want to so we retry request while we get with a correct password
        this.returnValue = true;
        this.closeModal();
      }
    }
  }
};
</script>

<style scoped>
button.sr-button {
  font-size: 18px;
  padding: 0 3rem;
  margin: 0px;
  height: 4rem;
  width: calc(50% - 0.75rem);
}

>>> .v-dialog {
  border-radius: 25px;
}
.v-card {
  padding: 0 3rem;
}
.mobile.v-card {
  padding: 0 2rem;
}
.v-card__title {
  padding: 0;
  padding-top: 4rem;
  padding-bottom: 2rem;
  font-weight: 900;
  font-size: 24px;
  line-height: 1.33;
}
.v-card__text {
  font-size: 17px;
  padding: 0;
}
.v-card__text p {
  margin-top: 0;
  margin-bottom: 24px;
  line-height: 1.33;
}
.v-card__text label,
.v-card__text span.form--error {
  display: block;
  font-size: 16px;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.27px;
}
.v-card__text label a {
  text-decoration: underline;
}

.v-card__actions {
  padding: 0px;
  padding-top: 38px;
  padding-bottom: 36px;
  justify-content: space-between;
}

button.sr-button.close {
  font-size: 14px;
  position: absolute;
  min-width: 1em;
  width: 1em;
  height: 1em;
  top: 2rem;
  color: #111111;
  opacity: 0.8;
  margin: 0;
  padding: 0;
}
.ltr .close {
  right: 2rem;
}
.rtl .close {
  left: 2rem;
}
.learn-more-left-margin {
  margin-left: 2px;
}

>>> .password-input.sr-field {
  margin: 0 -3rem;
  padding: 0 3rem;
  outline-offset: -0.5rem !important;
}
>>> .password-input.sr-field::before,
>>> .password-input.sr-field::after {
  position: absolute;
  content: '';
  border-top: 1px solid #d6d6d6;
  opacity: 0.84;
  left: 0;
  right: 0;
  transform: scaleY(0.5);
}
>>> .password-input.sr-field::after {
  top: 0;
}
>>> .password-input.sr-field::before {
  bottom: 0;
}
</style>
