<template>
  <v-dialog
    ref="modal"
    v-model="showModal"
    max-width="32.5rem"
    :content-class="contentClass"
  >
    <v-card
      role="dialog"
      :aria-hidden="!showModal"
      :aria-modal="showModal"
      aria-labelledby="ph-modal-title"
    >
      <v-card-text>
        <h1 id="ph-modal-title">
          {{ label }}
        </h1>
      </v-card-text>

      <edit-placeholder-form
        ref="form"
        :type="type"
        @change="handleChange"
        @change:valid="handleValid"
      >
        <v-card-text class="v-card__text__buttons">
          <sr-button sr-style="cancel" round type="reset" @click="handleReset">
            <translate>Cancel</translate>
          </sr-button>

          <sr-button
            sr-style="fancy"
            border
            round
            type="submit"
            :disabled="!formValid"
          >
            <translate>Save</translate>
          </sr-button>
        </v-card-text>
      </edit-placeholder-form>
    </v-card>
  </v-dialog>
</template>

<script>
import ModalMixin from '@/components/modals/ModalMixin';
import SrButton from '@/components/elements/SrButton';

import EditPlaceholderForm, {
  label
} from '@/components/document/placeholder/EditPlaceholderForm';

export default {
  components: { EditPlaceholderForm, SrButton },
  mixins: [ModalMixin('edit-placeholder')],
  props: {
    contentClass: { type: String, default: '' }
  },
  data() {
    return { returnValue: null, formValid: true };
  },
  computed: {
    type() {
      return this.modalOptions ? this.modalOptions.type : null;
    },
    label
  },
  watch: {
    showModal() {
      this.$refs.form.reset();
    },
    modalOptions(value) {
      this.$refs.form.reset(value);
    }
  },
  methods: {
    focusFirst() {
      this.$refs.form.focus();
    },
    handleReset() {
      this.returnValue = null;
      this.closeModal();
    },
    handleChange(value) {
      this.returnValue = value;
      this.closeModal();
    },
    handleValid(value) {
      this.formValid = value;
    }
  }
};
</script>

<style scoped>
h1 {
  margin: 0;
  display: block;
  font-size: 24px;
  font-weight: 900;
  opacity: 0.9;
  color: #111111;
}

.v-select.v-input--is-focused .signer-name {
  display: none;
}

.v-select.v-input.v-input--is-empty /deep/ ::placeholder,
.v-select.v-input--is-dirty.v-input--is-focused /deep/ ::placeholder {
  color: #111111 !important;
  opacity: 0.48;
  font-weight: 600;
}

.v-card__text {
  padding: 55px 40px 40px 40px;
  display: flex;
  justify-content: space-between;
}
.v-card__text__buttons {
  padding: 0px 40px 40px 40px;
}
.v-card__text__checkboxes {
  padding: 20px 40px 8px 40px;
  flex-direction: row;
  justify-content: flex-start;
}
.v-card__text__checkboxes__input {
  width: 100%;
}

/deep/ .v-dialog {
  overflow-y: visible;
}
/deep/ .v-dialog > * {
  border-radius: 2rem;
}

.v-form > .v-input,
.v-form > .sr-field,
.v-form > .sr-menu,
.ph-form--advanced > * {
  margin: 6px 40px;
}
.sr-autocomplete /deep/ .sr-autocomplete--value {
  top: 6px;
}

.v-divider {
  margin: 0px;
  transform: scaleY(0.5);
}
.v-divider + .v-divider {
  display: none;
}

.v-text-field /deep/ .v-label {
  width: 100%;
  opacity: 0.6;
  color: #111111;
}
.v-input--checkbox /deep/ .v-label {
  color: #111111;
  opacity: 1;
  font-weight: normal;
}

.v-input--checkbox /deep/ .v-icon {
  color: rgba(0, 0, 0, 0.15);
}
/deep/ .v-input--checkbox {
  position: relative;
  right: 3px;
  margin-top: 0;
}

.ph-form /deep/ .v-input__control > .v-input__slot::before,
.ph-form /deep/ .v-input__control > .v-input__slot::after {
  border-width: 0;
}
.ph-form /deep/ .v-input__slot {
  margin-bottom: 0;
}

.ph-form--advanced {
  font-size: 16px;
  margin-bottom: 2.2em;
}
.ph-form--advanced a {
  display: block;
  margin-top: 0.5em;
  margin-bottom: 1.5em;
}
.ph-form--advanced a:hover {
  text-decoration: none;
}
.ph-form--advanced .v-icon {
  vertical-align: middle;
}

.ph-form /deep/ .help[role='button'] {
  display: block;
  font-size: 1.4rem;
}
.sr-field /deep/ .help[role='button'] {
  margin: 0 -11px;
  top: 2px;
}
.v-card__text__checkboxes /deep/ .help[role='button'] {
  height: 40px;
  padding-top: 10px;
}

.label--text {
  min-width: 20%;
}
.v-card__text__buttons .sr-button {
  font-size: 18px;
  padding: 0em 3.5em;
  margin: 0px;
  height: 3em;
  width: calc(50% - 10px);
}
.dot {
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 100%;
  vertical-align: middle;
}
.ltr .dot {
  margin-right: 0.5ch;
}
.rtl .dot {
  margin-left: 0.5ch;
}
</style>
<style>
.v-menu__content.signer-index--menu {
  border-radius: 15px;
}
</style>
