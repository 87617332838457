<template>
  <v-dialog v-model="showModal" max-width="567px" :content-class="contentClass">
    <v-card>
      <v-card-title v-translate class="headline srtext--text">
        Public signing link
      </v-card-title>
      <v-card-text class="publink-modal--content">
        <p v-translate>
          Copy this link and place it in your website in order for visitors to
          sign a document based on this template.
        </p>
        <p v-translate>
          Note that the person clicking on the link will get the configuration
          of the 'sender' defined on the template. You (the template owner) gets
          the configuration of the first contact. This makes it possible to get
          a SignRequest yourself when they have signed the document. The
          template owner always gets a copy of the document even if no
          configuration is defined.
        </p>
        <p>
          <a
            class="button--link break--all"
            :href="`${link}&no_phone=1`"
            target="_blank"
            rel="noopener noreferrer"
          >
            <small>{{ link }}</small>
          </a>
        </p>
        <p v-translate>
          In case you happen to know the email address and/or mobile phone
          number of the person who needs to sign you can make it easier for them
          by prefilling them using the 'email' and 'phone' parameter in the
          link. Example:
        </p>
        <p>
          <a
            class="button--link break--all"
            :href="`${link}&email=${email}`"
            target="_blank"
            rel="noopener noreferrer"
          >
            <small>
              {{ link }}
              <strong>&email={{ email }}&phone=+12345678910</strong>
            </small>
          </a>
        </p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import ModalMixin from '@/components/modals/ModalMixin';

export default {
  mixins: [ModalMixin('publink')],
  props: {
    contentClass: { type: String, default: '' }
  },
  computed: {
    link() {
      return this.modalOptions.link;
    },
    email() {
      return this.modalOptions.email;
    }
  }
};
</script>
<style scoped>
.v-card {
  padding: 0px 49px;
  padding-bottom: 2em;
}
.v-card__title {
  padding: 36px 0;
  padding-top: 46px;
  font-size: 24px;
  font-weight: 900;
  letter-spacing: 0.4px;
  justify-content: center;
}

.v-card__text {
  font-size: 17px;
  padding: 0;
  margin-top: -1em;
  margin-bottom: 46px;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.27px;
  line-height: normal;
}
.v-card__text p {
  margin: 0;
  margin-top: 1em;
}
>>> .v-dialog {
  border-radius: 25px;
}
</style>
