import angular from 'angular';

/**
 * @ngdoc function
 * @name frontendApp.controller:LoginCtrl
 * @description
 * # LoginCtrl
 * Controller of the frontendApp
 */
angular.module('frontendApp').controller('LoginCtrl', [
  '$scope',
  '$route',
  '$location',
  '$window',
  'UserService',
  'srLoginModal',
  '$rootScope',
  'gettextCatalog',
  'utils',
  '$timeout',
  '$q',
  '$httpParamSerializer',
  'ngAsyncWrapper',
  '$vuex',
  function(
    $scope,
    $route,
    $location,
    $window,
    UserService,
    srLoginModal,
    $rootScope,
    gettextCatalog,
    utils,
    $timeout,
    $q,
    $httpParamSerializer,
    ngAsyncWrapper,
    $vuex
  ) {
    $scope.loading = false;

    function cleanupRoute() {
      angular.forEach(
        [
          'login',
          'register',
          'next',
          'email',
          'message_type',
          'message',
          'force_reload',
          'no_close'
        ],
        function(param) {
          $location.search(param, null).replace();
        }
      );
    }

    var MESSAGE_TYPES = {
      already_registered: gettextCatalog.getString(
        'Your email is already registered by SignRequest, please login'
      ),
      needs_register: gettextCatalog.getString(
        'This resource is not available without a registered account.'
      ),
      needs_login: gettextCatalog.getString(
        'You need to be logged in to access this resource.'
      )
    };

    var serviceMap = {
      google: 'google-oauth2',
      facebook: 'facebook',
      linkedin: 'linkedin-oauth2',
      salesforce: 'salesforce-oauth2',
      dropbox: 'dropbox-oauth2',
      microsoft: 'microsoft-graph',
      salesforce_sandbox: 'salesforce-oauth2-sandbox'
    };

    if (typeof $scope.doRegister !== 'boolean') {
      $scope.doRegister = !!($route.current && $route.current.params.register);
    }

    $scope.doPermLogin = !!$scope.doPermLogin;
    $scope.hideClose = !!$scope.hideClose;
    // if set to true we allow to redirect to the default team when logging in.
    $scope.allowDefaultTeamRedirect = !!$scope.allowDefaultTeamRedirect;

    if (!angular.isDefined($scope.next)) {
      var next_from_route = utils.getAndRemoveParamFromRoute('next');
      if (next_from_route) {
        $scope.next = next_from_route;
      }
    }
    $scope.message =
      $scope.message || utils.getAndRemoveParamFromRoute('message');
    $scope.message_type =
      $scope.message_type || utils.getAndRemoveParamFromRoute('message_type');
    $scope.email = $scope.email || utils.getAndRemoveParamFromRoute('email');
    $scope.force_reload =
      $scope.force_reload || utils.getAndRemoveParamFromRoute('force_reload');

    $scope.title = $scope.title || '';
    $scope.disableRegister = $scope.disableRegister || false;
    $scope.footerMessage = $scope.footerMessage || '';

    if (!utils.EMAIL_REGEXP.test($scope.email)) {
      $scope.email = '';
    }

    if (angular.isDefined($scope.message)) {
      $scope.message = gettextCatalog.getString($scope.message);
    } else {
      if (angular.isDefined($scope.message_type)) {
        $scope.message = MESSAGE_TYPES[$scope.message_type] || '';
      }
    }

    $scope.trackModalEvent = function(action) {
      var deferred = $q.defer();
      var category = $scope.doRegister ? 'Register Modal' : 'Login Modal';
      if ($scope.hideClose) {
        category = category + ' Forced';
      }
      UserService.trackEvent(action, category, {
        message: $scope.message,
        message_type: $scope.message_type,
        force_reload: $scope.force_reload,
        hideClose: $scope.hideClose,
        doPermLogin: $scope.doPermLogin,
        allowDefaultTeamRedirect: $scope.allowDefaultTeamRedirect
      }).then(function() {
        deferred.resolve();
      });
      return deferred.promise;
    };

    $scope.trackModalShownEvent = function() {
      if ($scope.doRegister) {
        $vuex.dispatch('events/trackRegisterFormShownEvent', {
          is_modal: true
        });
      } else {
        $vuex.dispatch('events/trackLoginFormShownEvent', { is_modal: true });
      }
    };

    $timeout(function() {
      $scope.trackModalEvent('opened');
      $scope.trackModalShownEvent();
    });

    $scope.closeModal = function() {
      $route.current.params.register = false;
      $route.current.params.login = false;
      cleanupRoute();
      $scope.trackModalEvent('closed');
      srLoginModal.deactivate();
    };

    $scope.getNext = function() {
      var next = $scope.next;
      if (!next) {
        next = '/#' + $location.url(); // redirect to the current route
      }
      next = utils.addUrlParam(
        next,
        $scope.doRegister ? 'register_success' : 'login_success',
        '1'
      );
      return next;
    };

    $scope.getNextForOauth = function() {
      var next = $scope.getNext();
      if ($rootScope.subdomain && next.substring(0, 4) !== 'http') {
        // redirect to subdomain
        next = window.location.protocol + '//' + window.location.host + next;
      }
      return next;
    };

    $scope.activateRegister = function() {
      $scope.doRegister = true;
      $scope.trackModalEvent('activated');
      $scope.trackModalShownEvent();
    };
    $scope.activateLogin = function() {
      $scope.doRegister = false;
      $scope.trackModalEvent('activated');
      $scope.trackModalShownEvent();
    };

    var getOauthUrlForService = function(service) {
      var url =
        '/user/social/login/' +
        serviceMap[service] +
        '/?' +
        $httpParamSerializer({
          next: $scope.getNextForOauth(),
          token: $scope.token
        });
      return url;
    };

    $scope.goToOauthRegister = function($event) {
      $event.preventDefault();
      if (
        $scope.register_data.terms &&
        $scope.selectedOauth &&
        !$scope.loading
      ) {
        $scope.loading = true;
        $scope.trackModalEvent('done').then(
          function() {
            cleanupRoute();
            utils.navigateTo(getOauthUrlForService($scope.selectedOauth));
          },
          function() {
            $scope.loading = false;
          }
        );
      }
      return false;
    };

    $scope.selectOauthService = function($event, service) {
      $event.preventDefault();
      $scope.trackModalEvent('used ' + service);
      if ($scope.doRegister) {
        $scope.selectedOauth = service;
      } else {
        if ($scope.loading) {
          return;
        }
        $scope.loading = true;
        $scope.trackModalEvent('done').then(
          function() {
            cleanupRoute();
            utils.navigateTo(getOauthUrlForService(service));
          },
          function() {
            $scope.loading = false;
          }
        );
      }
      return false;
    };

    $scope.hideOauth = function(service) {
      if ($scope.selectedOauth && $scope.selectedOauth !== service) {
        return true;
      }
      if (
        $scope.filterOauths &&
        angular.isDefined($scope.oauths) &&
        !$scope.oauths[service]
      ) {
        return true;
      }

      if (angular.isDefined($scope.backends) && $scope.backends.count > 0) {
        return !(
          // service can be the key or the values of serviceMap
          ($scope.backends[service] || $scope.backends[serviceMap[service]])
        );
      }
      return false;
    };

    $scope.goToNextOrReload = ngAsyncWrapper(async function(
      redirect_team_host
    ) {
      cleanupRoute();
      await $scope.trackModalEvent('done');
      if (
        redirect_team_host &&
        redirect_team_host === $window.location.origin
      ) {
        redirect_team_host = null;
      }

      await UserService.refreshUser();

      let next = $scope.getNext();
      if (redirect_team_host && next.substring(0, 4) !== 'http') {
        next = redirect_team_host + next;
      }

      // sometimes this wont reload the page when next is the current page
      utils.navigateTo(next);

      if ($scope.force_reload) {
        // when we really need to we need to pass force_reload
        // we use a timeout here as else $window.location = next will not be handled yet before
        // $window.location.reload() executes
        $timeout(function() {
          $window.location.reload();
        });
      } else {
        $timeout(function() {
          UserService.trackUserPageView();
        });
      }

      $scope.loading = false;
    });

    var navigateToConfirmEmail = function(email) {
      cleanupRoute();
      $scope.trackModalEvent('done').then(function() {
        utils.navigateTo(
          '/#/confirm?config=' +
            utils.makeEncodedUrlParam({ email: email, activate: true })
        );
      });
    };

    $scope.register_data = { email: '', pass1: '', pass2: '' };
    if ($scope.doRegister && $scope.email) {
      $scope.register_data.email = $scope.email;
    }
    $scope.register = function(event) {
      if ($scope.loading) {
        return;
      }
      $scope.loading = true;
      event.preventDefault();
      $scope.trackModalEvent('used password');
      UserService.registerUser(
        $scope.register_data.email,
        $scope.register_data.pass1,
        $scope.register_data.pass2,
        $scope.next
      ).then(
        function(response) {
          if (response.data.status === 'SUCCESS') {
            if (response.data.user_logged_in) {
              // no confirmation needed
              UserService.refreshUser();
            } else {
              navigateToConfirmEmail($scope.register_data.email);
            }

            $scope.closeModal();
          } else {
            $scope.loading = false;
          }
        },
        function() {
          $scope.loading = false;
        }
      );
    };
    $scope.login_data = { email: '', pass: '' };
    if (!$scope.doRegister && $scope.email) {
      $scope.login_data.email = $scope.email;
    }
    $scope.login = function(event) {
      if ($scope.loading) {
        return;
      }
      $scope.loading = true;
      event.preventDefault();
      $scope.trackModalEvent('used password');
      UserService.loginUser(
        $scope.login_data.email,
        $scope.login_data.pass
      ).then(
        ngAsyncWrapper(async function(response) {
          await UserService.refreshUserTags();
          if (response.data.inactive) {
            navigateToConfirmEmail($scope.login_data.email);
            $scope.closeModal();
          }
          if (response.data.status === 'SUCCESS') {
            let redirect_team_host = null;
            if (
              $scope.allowDefaultTeamRedirect &&
              response.data.redirect_host
            ) {
              redirect_team_host = response.data.redirect_host;
            }
            await $scope.goToNextOrReload(redirect_team_host);
            $scope.closeModal();
          } else {
            $scope.loading = false;
          }
        }),
        function() {
          $scope.loading = false;
        }
      );
    };

    $scope.recoverPass = function(event) {
      event.preventDefault();
      $scope.trackModalEvent('recover password');
      utils.navigateTo('/#/login/recover');
      $scope.closeModal();
    };
  }
]);
