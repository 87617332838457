import angular from 'angular';
import Vue from 'vue';

/**
 * @ngdoc directive
 * @name frontendApp.directive:sr-language
 * @description
 * # docPage
 */
angular
  .module('frontendApp')
  /**
   * <sr-language></sr-language>
   */
  .component('srLanguage', {
    controllerAs: 'ctrl',
    controller: [
      '$scope',
      '$element',
      function($scope, $element) {
        const LangMenu = require('@/components/document/LangMenu');
        const { store } = require('@/store');

        new Vue({
          store,
          render: h => h(LangMenu.default, { props: { showName: true } })
        }).$mount($element.get(0));
      }
    ]
  });
