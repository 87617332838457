<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fill-rule="evenodd">
      <rect width="24" height="24" fill="#222" fill-opacity=".08" rx="12" />
      <path
        fill="#222"
        fill-rule="nonzero"
        d="M10.9059011,17.1 C11.2078682,17.1 11.4513557,17.0059807 11.6363636,16.8179421 C11.8213716,16.6299035 11.9138756,16.3937621 11.9138756,16.1095177 C11.9138756,15.95209 11.8819777,15.8121543 11.8181818,15.6897106 C11.754386,15.5672669 11.6757044,15.4579421 11.5821372,15.3617363 L10.2041467,13.9448875 L9.18684645,12.9844881 L16.9451488,12.9967203 C17.264128,12.9967203 17.521438,12.9478457 17.7170786,12.7576206 C17.9127192,12.5673955 18,12.3148553 18,12 C18,11.6851447 17.9127192,11.4315113 17.7170786,11.2390997 C17.521438,11.0466881 17.264128,10.9967203 16.9451488,10.9967203 L9.18625257,11.0194857 L10.2041467,10.0551125 L11.5821372,8.63826367 C11.6757044,8.54643087 11.754386,8.4392926 11.8181818,8.31684887 C11.8819777,8.19440514 11.9138756,8.05228296 11.9138756,7.89048232 C11.9138756,7.60623794 11.8213716,7.37009646 11.6363636,7.18205788 C11.4513557,6.99401929 11.2078682,6.9 10.9059011,6.9 C10.7825625,6.9 10.6602871,6.92733119 10.539075,6.98199357 C10.4178628,7.03665595 10.3040936,7.11864952 10.1977671,7.22797428 L6.29984051,11.2292605 C6.09994684,11.434791 6,11.6927974 6,12.0032797 C6,12.3137621 6.09994684,12.569582 6.29984051,12.7707395 L10.2041467,16.7785852 C10.4167996,16.9928617 10.6507177,17.1 10.9059011,17.1 Z"
      />
    </g>
  </svg>
</template>
<script>
export default {};
</script>
