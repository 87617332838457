/*

`makeLazyComponent(fn, exportName='default')` accepts a function that returns a promise
resolving to a module containing a component. If export name is not specified, default export
is used.

While chunk with a component is being fetched and parsed,
`<div class="lazy-component--loading />` would be rendered instead.

Usage example:

const LazyStyleGuide = makeLazyComponent(
  () => import(/* webpackChunkName: "styleguide" *\/ '../components/StyleGuide')
);

*/
export default function makeLazyComponent(loadFn, exportName = 'default') {
  return {
    inheritAttrs: false,
    props: {},
    data() {
      return {
        component: null
      };
    },
    created() {
      loadFn().then(loadedModule => {
        this.component = loadedModule[exportName];
      });
    },
    render(h) {
      return this.component
        ? h(this.component, { props: this.$attrs, on: this.$listeners })
        : h('div', { class: 'lazy-component--loading' }, []);
    }
  };
}
