import angular from 'angular';

/**
 * @ngdoc function
 * @name frontendApp.controller:ReactivateAccountCtrl
 * @description
 * # ReactivateAccountCtrl
 * Everything we need to reactivate an account
 */
angular.module('frontendApp').controller('ReactivateAccountCtrl', [
  '$scope',
  '$route',
  'UserService',
  'utils',
  '$location',
  function($scope, $route, UserService, Utils, $location) {
    $scope.errors = [];
    $scope.form_send_success = false;

    $scope.reactivateAccount = function() {
      var token = $location.search().token;
      UserService.reactivateAccount(token).then(function(response) {
        if (response.data.status == 'SUCCESS') {
          $scope.form_send_success = true;
        } else {
          $scope.form_errors = true;
          $scope.errors = response.data.messages;
        }
      });
    };
  }
]);
