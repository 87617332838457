<template>
  <v-layout column class="billing-form">
    <v-flex xs12 class="subscribe-input-group">
      <label v-translate class="subscribe-input-label">Country</label>
      <sr-autocomplete
        name="country-select"
        :items="countries"
        :enable-manual="false"
        @commit="updateCountry"
      >
        <template v-slot:value>{{ countryName }}</template>
      </sr-autocomplete>
    </v-flex>

    <v-flex v-if="vatRegionSelectorVisible" xs12 class="subscribe-input-group">
      <label v-translate class="subscribe-input-label">
        Country VAT region
      </label>
      <v-select
        v-model="vatRegion"
        :items="vatRegions"
        :color="selectColor"
        dense
        solo
        @change="updateVatRegion"
      ></v-select>
    </v-flex>

    <v-flex xs12 class="subscribe-input-group">
      <label v-translate class="subscribe-input-label">Company name</label>
      <input
        v-model="companyName"
        :placeholder="$gettext('Optional')"
        class="subscribe-input-field"
        @blur="updateCompanyName"
      />
    </v-flex>
  </v-layout>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import SrAutocomplete from '@/components/elements/SrAutocomplete';
export default {
  components: { SrAutocomplete },
  data() {
    return {
      country: null,
      vatRegion: null,
      companyName: null
    };
  },
  computed: {
    ...mapState('subscription', [
      'countryOptions',
      'billingDetails',
      'paymentMethod'
    ]),
    ...mapGetters('subscription', ['hasPaymentMethodData']),
    countries() {
      return this.countryOptions.countries;
    },
    exceptions() {
      return this.countryOptions.exceptions;
    },
    countriesDict() {
      let dictionary = {};
      if (this.countries) {
        Object.assign(
          dictionary,
          ...this.countries.map(x => ({ [x.value]: x.text }))
        );
      }

      return dictionary;
    },
    countryName() {
      return this.country in this.countriesDict
        ? this.countriesDict[this.country]
        : '';
    },
    vatRegionSelectorVisible() {
      return this.country in this.exceptions;
    },
    vatRegions() {
      return this.country in this.exceptions
        ? this.exceptions[this.country]
        : [];
    },
    selectColor() {
      return '#757b88';
    }
  },
  mounted() {
    this.country = this.billingDetails.details.country;
  },
  methods: {
    ...mapActions('subscription', [
      'setBillingDetails',
      'updatePlanPricing',
      'changeStripeElementsInputCompleted'
    ]),
    async updateCountry({ value, text }) {
      this.country = value;
      await this.setBillingDetails({
        billingDetails: {
          country: this.country,
          exception_name: null
        }
      });
      this.updatePlanPricing();
      if (this.paymentMethod === 'sepa') {
        this.changeStripeElementsInputCompleted({ completed: false });
      }
    },
    async updateVatRegion() {
      await this.setBillingDetails({
        billingDetails: {
          country: this.country,
          exception_name: this.vatRegion
        }
      });
    },
    updateCompanyName() {
      this.setBillingDetails({
        billingDetails: { company_name: this.companyName }
      });
    }
  }
};
</script>
<style scoped lang="scss">
.billing-form {
  margin-top: 10px;
}

.mobile .billing-form {
  margin-top: 30px;
}

::v-deep .sr-autocomplete {
  border: 1px setting(colors, border) solid;
  height: 50px;
  max-height: 50px;
  margin-top: 5px;
  display: flex;
  align-items: center;

  &:focus {
    border-color: setting(colors, border-dark);
  }

  .srtext--text {
    margin-left: 5px;
    margin-right: 5px;
  }
  [role='option'] {
    font-size: 0.96rem;
    padding-left: 5px;
    padding-right: 5px;
    margin: 6px 0;
  }

  .sr-field {
    width: 100%;
  }

  .sr-field--error {
    display: none;
  }

  .sr-menu--content {
    align-self: flex-start;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    max-height: 183px;
    border-radius: 0;
    overflow-x: hidden;
  }
}
::v-deep .sr-autocomplete--value {
  top: 1em;
  left: 5px;
  right: 5px;
  color: rgba(0, 0, 0, 0.8);
}
::v-deep .v-select {
  margin-top: 5px;

  &:focus {
    border-color: setting(colors, border-dark);
  }
}
::v-deep
  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control {
  > .v-input__slot {
    border: 1px solid setting(colors, border);
    box-shadow: none;
    padding-left: 5px;
    font-weight: normal;
    margin-bottom: 0;
  }
  > .v-text-field__details {
    display: none;
  }
}

::v-deep .theme--light.v-select .v-select__selections {
  color: rgba(0, 0, 0, 0.8);
}

.subscribe-input-group {
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.8);
}

.mobile .subscribe-input-group {
  margin-top: 0;
}

.subscribe-input-field {
  font-size: 1.1rem;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.8);
  padding: 11px 5px;
}
</style>
