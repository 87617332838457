<template>
  <ul class="info-container--reasons srtext--text">
    <feature-line>
      <strong v-translate>Facilitates growth</strong>
      <span class="sps" />
      <p v-translate>
        Save time and resources, increase conversion with digital signatures
      </p>
    </feature-line>
    <feature-line>
      <strong v-translate>Easy to use</strong>
      <span class="sps" />
      <p v-translate>We focus on simplicity</p>
    </feature-line>
    <feature-line>
      <strong v-translate>Legally binding</strong>
      <span class="sps" />
      <p v-translate>SignRequest e-signatures are secure and binding</p>
      <span />
    </feature-line>
  </ul>
</template>
<script>
import FeatureLine from './FeatureLine';

export default {
  components: { FeatureLine }
};
</script>
<style scoped>
ul.info-container--reasons {
  font-size: 22.8px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.96;
  letter-spacing: normal;
  display: block;
  margin: auto;
  margin-top: 5rem;
  margin-bottom: 4rem;
}
.mobile ul.info-container--reasons {
  font-size: 18px;
  margin: 0 1rem;
  margin-top: 48px;
}

.info-container__lout ul.info-container--reasons {
  font-size: 18px;
}
span.sps {
  margin: 0 0.2ch;
}
.mobile span.sps {
  display: none;
}
p {
  display: inline;
  margin: 0;
}
.mobile p {
  margin-top: 5px;
  margin-bottom: 10px;
  display: block;
  font-size: 16px;
  line-height: 1.63;
}
strong {
  font-size: 20;
  line-height: 1.3;
  font-weight: 900;
}
.mobile strong {
  display: inline-block;
  max-width: calc(100% - 28px);
  vertical-align: top;
  line-height: 1.63;
}
</style>
