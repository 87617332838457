import angular from 'angular';

/**
 * @ngdoc service
 * @name frontendApp.salesforceSerive
 * @description Service to the salesforce features backend
 * # salesforceSerive
 * Service in the frontendApp.
 */

angular.module('frontendApp').service('salesforceService', [
  '$rootScope',
  '$http',
  '$window',
  '$location',
  'messageService',
  function salesforceService(
    $rootScope,
    $http,
    $window,
    $location,
    messageService
  ) {
    this.getDataFromId = function(
      objectId,
      objectType,
      uid,
      customObjectData,
      object_id_list,
      object_id_list_type,
      attachment_id_list
    ) {
      var url =
        '/integrations/salesforce/sfget_data?objectId=' +
        objectId +
        '&objectType=' +
        objectType +
        '&uid=' +
        uid;
      if (angular.isDefined(customObjectData)) {
        url =
          url +
          '&custom=1' +
          '&contactApiName=' +
          customObjectData.contactApiName +
          '&accountApiName=' +
          customObjectData.accountApiName;
      }
      if (angular.isDefined(object_id_list)) {
        url =
          url +
          '&object_id_list=' +
          object_id_list +
          '&object_id_list_type=' +
          object_id_list_type;
      }
      if (angular.isDefined(attachment_id_list)) {
        url = url + '&attachment_id_list=' + attachment_id_list;
      }

      return $http.get(url).then(
        function(resp) {
          return resp;
        },
        function(resp) {
          return messageService.handleError(resp);
        }
      );
    };

    this.postDocId = function(
      docID,
      docuType,
      filename,
      objectType,
      objectId,
      uid,
      extra_docs,
      hr2day,
      description
    ) {
      var url = '/integrations/salesforce/sfdoc_only';
      var payload = {
        doc_id: docID,
        doc_type: docuType,
        filename: filename,
        object_type: objectType,
        object_id: objectId,
        uid: uid,
        extra_docs: extra_docs,
        hr2day: hr2day,
        description: description
      };

      return $http.post(url, payload).then(
        function(resp) {
          return resp;
        },
        function(resp) {
          return resp;
        }
      );
    };

    this.getEmailList = function(contact_ids, object_type, uid) {
      var url =
        '/integrations/salesforce/sfget_emails?uid=' +
        uid +
        '&contact_ids=' +
        contact_ids +
        '&object_type=' +
        object_type;
      return $http.get(url).then(
        function(resp) {
          return resp;
        },
        function(resp) {
          return messageService.handleError(resp);
        }
      );
    };

    this.postSignRequest = function(payload) {
      var url = '/integrations/salesforce/sfpostdoc';
      return $http.post(url, payload).then(
        function(resp) {
          return resp;
        },
        function(resp) {
          return resp;
        }
      );
    };

    this.createSignRequestObject = function(objectType, objectId) {
      var url = '/integrations/salesforce/createsignRequestobject';
      var payload = {
        object_type: objectType,
        object_id: objectId
      };
      return $http.post(url, payload).then(
        function(resp) {
          return resp;
        },
        function(resp) {
          return resp;
        }
      );
    };
  }
]);
