<template>
  <v-dialog
    ref="modal"
    v-model="showModal"
    lazy
    max-width="500"
    :content-class="contentClass"
  >
    <v-card
      role="dialog"
      :aria-hidden="!showModal"
      :aria-modal="showModal"
      aria-labelledby="terms-gmodal-title"
      :class="{
        mobile: $isMobile
      }"
    >
      <sr-button
        class="close"
        sr-style="text"
        data-test-id="close-member-upgrade-btn"
        @click="closeModal"
      >
        <v-icon
          aria-label="$gettext('Close confirmation dialog')"
          class="round"
        >
          close
        </v-icon>
      </sr-button>
      <v-card-title id="terms-gmodal-title" class="headline">
        <span v-translate>
          Please confirm
        </span>
      </v-card-title>
      <v-card-text
        v-if="
          subscriptionDetails &&
            subscriptionDetails.details &&
            billingDetails &&
            billingDetails.details
        "
      >
        <p>
          <strong>
            {{ plan_type_display }}
          </strong>
        </p>
        <p>
          <small v-translate>Extra costs for text messages may apply</small>
          <small>
            ({{
              subscriptionDetails.details.product_pricing
                .send_text_message_display
            }}/SMS)
          </small>
          <br />
          <small v-translate>Excluding VAT (if applicable)</small>
          <span v-if="billingDetails.details.vat_rate + 1">
            <br />
            <small>
              <span v-translate>VAT rate</span>
              :
              <strong>
                <span>{{ billingDetails.details.vat_rate * 100 }}</span>
                %
              </strong>
              <span v-if="billingDetails.details.country_name_for_vat">
                (
                <span>{{ billingDetails.details.country_name_for_vat }}</span>
                )
              </span>
            </small>
          </span>
          <br />
          <small
            v-if="subscriptionDetails.details.interval === 'year'"
            v-translate
          >
            Your subscription can be cancelled per year
          </small>
          <small
            v-if="subscriptionDetails.details.interval === 'month'"
            v-translate
          >
            Your subscription can be cancelled per month
          </small>
        </p>
      </v-card-text>
      <v-card-text v-else>
        <Loading></Loading>
      </v-card-text>
      <v-card-actions>
        <sr-button
          sr-style="fancy"
          round
          data-test-id="submit-member-upgrade-btn"
          :loading="isLoading"
          @click="doMemberUpgrade"
        >
          <translate>Subscribe</translate>
        </sr-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import SrButton from '@/components/elements/SrButton';
import ModalMixin from '@/components/modals/ModalMixin';
import Loading from '@/components/Loading';
import Screen from '@/mixins/Screen';

export default {
  components: { SrButton, Loading },
  mixins: [ModalMixin('confirmMemberUpgrade'), Screen],
  props: {
    contentClass: { type: String, default: '' }
  },
  data: function() {
    return {
      plan_type_display: ''
    };
  },
  computed: {
    ...mapGetters('subscription', [
      'subscriptionDetails',
      'billingDetails',
      'trialDays',
      'hasPaymentMethodData'
    ]),
    ...mapGetters('users', ['hasPermission']),
    ...mapGetters('api', ['isLoading']),
    ...mapGetters('conf', ['getTrialSubscribeVersion'])
  },
  watch: {
    showModal: {
      immediate: false,
      async handler(showModal) {
        if (showModal) {
          await this.getSubscription();
          await this.getBillingDetails();
          const planOptions = await this.getPlanOptions({
            quote_plan: this.modalOptions.plan,
            quote_quantity: this.modalOptions.quantity,
            interval: this.subscriptionDetails.details.interval
          });
          this.plan_type_display = planOptions.quoted_plan_display;
        }
      }
    }
  },
  methods: {
    ...mapActions('trackEvents', ['trackEvent']),
    ...mapActions('subscription', [
      'setSubscriptionDetails',
      'setSubscription',
      'getSubscription',
      'getBillingDetails',
      'getPlanOptions'
    ]),
    ...mapActions('users', ['getUser']),
    ...mapActions('messages', ['addMessage']),
    ...mapActions('trackEvents', ['trackEvent']),
    trackMemberUpgradeEvent(event_name) {
      return this.trackEvent({
        action: 'Billing Details ' + event_name,
        category: 'Billing Details',
        params: {
          trial_subscribe: this.billingDetails.details.has_valid_trial,
          trial_subscribe_version: this.getTrialSubscribeVersion,
          plan: this.subscriptionDetails.details.plan_type,
          plan_interval: this.subscriptionDetails.details.interval,
          plan_quantity: this.subscriptionDetails.details.quantity,
          current_plan: this.subscriptionDetails.details.current_plan,
          current_plan_interval: this.subscriptionDetails.details
            .current_interval,
          current_plan_quantity: this.subscriptionDetails.details
            .current_quantity,
          currency: this.billingDetails.details.currency,
          paid_usage: this.hasPermission('pro'),
          has_payment_method: this.hasPaymentMethodData
        }
      });
    },
    async doMemberUpgrade() {
      const newPlan = {
        name: this.modalOptions.plan,
        quantity: this.modalOptions.quantity,
        interval: this.subscriptionDetails.details.interval,
        trialDays: this.trialDays
      };
      this.trackMemberUpgradeEvent('Member Upgrade Confirm Opened');
      await this.setSubscription({ plan: newPlan });
      if (this.subscriptionDetails.status === 'SUCCESS') {
        this.addMessage({
          type: 'success',
          msg: this.$gettext('Saved')
        });
        await this.getUser();
      }
      this.closeModal();
    }
  }
};
</script>

<style scoped>
button.sr-button {
  font-size: 18px;
  padding: 0 3rem;
  margin: 0px;
  height: 4rem;
  width: calc(50% - 0.75rem);
}

>>> .v-dialog {
  border-radius: 25px;
}
.v-card {
  padding: 0 3rem;
}
.mobile.v-card {
  padding: 0 2rem;
}
.v-card__title {
  padding: 0;
  padding-top: 4rem;
  padding-bottom: 2rem;
  font-weight: 900;
  font-size: 24px;
  line-height: 1.33;
}
.v-card__text {
  font-size: 17px;
  padding: 0;
}
.v-card__text p {
  margin-top: 0;
  margin-bottom: 24px;
  line-height: 1.33;
}
.v-card__text label,
.v-card__text span.form--error {
  display: block;
  font-size: 16px;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.27px;
}
.v-card__text label a {
  text-decoration: underline;
}

.v-card__actions {
  padding: 0px;
  padding-top: 38px;
  padding-bottom: 36px;
  justify-content: center;
}
.v-btn-toggle .v-btn {
  opacity: 1;
  padding: 10px 20px;
}
.v-btn-toggle--selected,
.v-btn-toggle--selected .v-btn {
  box-shadow: unset;
}
.v-btn-toggle .v-btn:first-child {
  border-radius: 9px 0px 0 9px;
}
.v-btn-toggle .v-btn:last-child {
  border-radius: 0px 9px 9px 0px;
}

button.sr-button.close {
  font-size: 14px;
  position: absolute;
  min-width: 1em;
  width: 1em;
  height: 1em;
  top: 2rem;
  color: #111111;
  opacity: 0.8;
  margin: 0;
  padding: 0;
}
.ltr .close {
  right: 2rem;
}
.rtl .close {
  left: 2rem;
}
</style>
