import angular from 'angular';

/**
 * @ngdoc directive
 * @name frontendApp.directive:docPage
 * @description
 * # docPage
 */
angular.module('frontendApp').directive('docPage', [
  'docService',
  '$window',
  'gettextCatalog',
  'messageService',
  '$timeout',
  function(docService, $window, gettextCatalog, messageService, $timeout) {
    return {
      template: require('../../views/directives/doc-page.html'),
      restrict: 'E',
      replace: true,
      scope: {
        page: '=',
        doc: '=',
        sigPad: '='
      },
      link: function postLink(scope, element, attrs) {
        scope.page.loaded = false;
        scope.page.error_loading = false;
        scope.page.img_loaded = false;

        scope.getPageAltText = function() {
          return (
            gettextCatalog.getString('Page') + ' ' + (scope.page.index + 1)
          );
        };

        var page_img = element.find('.page-img');
        page_img.on('load', function() {
          scope.$apply(function() {
            scope.page.img_loaded = true;
            // initialWidth and initialHeight are used for zooming
            scope.page.initialWidth = page_img.width();
            scope.page.initialHeight = page_img.height();

            scope.setPageWidthHeight();
            scope.initEditorPos();
            page_img.off('load');
          });
        });
        page_img.on('error', function() {
          scope.$apply(function() {
            scope.page.error_loading = true;
            page_img.off('error');
          });
        });

        var window = angular.element($window);
        window.on('resize', function() {
          scope.setPageWidthHeight();
          scope.$emit('update-fonts');
        });

        scope.$on('$destroy', function() {
          window.off('resize');
          page_img.off('load');
        });

        scope.setPageWidthHeight = function() {
          // width and height are used to place the editor and scale the signature image
          scope.page.width = page_img.width();
          scope.page.height = page_img.height();
        };

        scope.initEditorPos = function() {
          angular.forEach(scope.page.editors, function(editor, key) {
            editor.x_pos = editor.x_pos || editor.sig.x_pos;
            editor.y_pos = editor.y_pos || editor.sig.y_pos;
            editor.updateCss();
            editor.loaded = true;
          });
          scope.page.loaded = true;
        };

        scope.page.editors = scope.page.editors || [];

        scope.page.removeEditor = function(editor) {
          editor.deleteEditor();
          scope.doc.checkForSignatures();
        };

        scope.page.checkOpenEditors = function() {
          var openEditors = false;
          angular.forEach(scope.page.editors, function(editor, index) {
            if (editor.show) {
              openEditors = true;
            }
            editor.show = false;
            if (
              !editor.sig.data_uri &&
              editor.placeholder.signer_index === null
            ) {
              // if they don't have a signature, delete them
              scope.page.editors.splice(index, 1);
            }
          });
          return openEditors;
        };

        scope.editPage = function(event) {
          // do nothing when the page isn't loaded yet
          if (!scope.page.loaded) {
            messageService.add(
              'info',
              gettextCatalog.getString(
                'Please wait for the document to finish loading.'
              ),
              5000
            );
          } else {
            if (!scope.page.signer.action_needed && !scope.doc.prepare_doc) {
              var no_change_string = gettextCatalog.getString(
                'You cannot make changes anymore.'
              );
              if (docService.isDeclined(scope.doc)) {
                messageService.add(
                  'warning',
                  no_change_string +
                    ' ' +
                    gettextCatalog.getString(
                      'The document has already been declined.'
                    ),
                  5000
                );
              } else if (docService.isSigned(scope.doc)) {
                messageService.add(
                  'warning',
                  no_change_string +
                    ' ' +
                    gettextCatalog.getString(
                      'The document has already been signed.'
                    ),
                  5000
                );
              } else {
                messageService.add('warning', no_change_string, 5000);
              }
            } else if (
              scope.page.signer.approve_only &&
              !scope.doc.prepare_doc
            ) {
              messageService.add(
                'info',
                gettextCatalog.getString(
                  "You do not need to add a signature to this document, you only need to approve it by clicking the 'approve' button."
                ),
                5000
              );
            } else if (scope.doc.is_prepared) {
              // activate signing wizzard or something??
              scope.doc.scrollToNextPlaceholder();
              scope.page.checkOpenEditors();
            } else {
              // if we have open editors just return and do nothing besides closing (not showing) all editors
              if (!scope.page.checkOpenEditors()) {
                if (
                  (scope.page.signer.action_needed || scope.doc.prepare_doc) &&
                  scope.page.loaded
                ) {
                  // first get the x, y pos within the container of the click event
                  var x = event.pageX - element.offset().left;
                  var y = event.pageY - element.offset().top;

                  // If the user clicks at the very edge of the screen, we redirect the click a little more the the center
                  if (x < 120) {
                    x = 120;
                  }

                  if (x > element.width() - 120) {
                    x = element.width() - 120;
                  }

                  if (y < 57) {
                    y = 57;
                  }

                  // nasty hack to fix the chromium bug
                  // https://code.google.com/p/chromium/issues/detail?id=423802
                  var chrome_match = $window.navigator.appVersion.match(
                    /Chrome\/(.*?) /
                  );
                  if (chrome_match && chrome_match[1] === '38.0.2125.102') {
                    x -= $window.scrollX;
                    y -= $window.scrollY;
                  }

                  // convert to percentages relative to container
                  var x_pos = x / scope.page.width;
                  var y_pos = y / scope.page.height;

                  // x_pos, y_pos, show, loaded, sig (not here)
                  var editor = docService.newEditor({
                    x_pos: x_pos,
                    y_pos: y_pos,
                    show: true,
                    loaded: true,
                    is_placeholder: scope.doc.prepare_doc
                  });
                  editor.init = true;
                  scope.page.editors.push(editor);
                }
              }
            }
          }
        };
      }
    };
  }
]);
