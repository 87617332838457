<template>
  <div class="sr-list-field">
    <sr-input
      v-for="(part, idx) of parts"
      :key="idx"
      v-bind="$attrs"
      :value="parts[idx]"
      @input="handleInput(idx, $event)"
    >
      <template v-slot:append-outer>
        <sr-button
          class="sr-field-button--remove"
          sr-style="text"
          inline
          :title="removeLabel"
          @click="handleRemove(idx)"
        >
          <circle-close-icon />
        </sr-button>
      </template>
    </sr-input>
    <sr-button sr-style="link" inline class="sr-list--add" @click="handleAdd">
      <translate>Add attachment</translate>
    </sr-button>
  </div>
</template>
<script>
import { removeAtIdx } from '@/utils/fp';
import SrButton from '@/components/elements/SrButton';
import SrInput from '@/components/elements/SrInput';
import CircleCloseIcon from '@/components/icons/CircleCloseButton.vue';

export default {
  components: { SrInput, SrButton, CircleCloseIcon },
  inheritAttrs: false,
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    removeLabel: { type: String, required: true },
    value: { type: Array, required: true }
  },
  computed: {
    parts() {
      const ret = [...this.value];
      if (!ret.length || this.buffer) {
        ret.push('');
      }
      return ret;
    }
  },
  methods: {
    handleAdd() {
      this.$emit('change', [...this.value, '']);
    },
    handleRemove(idx) {
      this.$emit('change', removeAtIdx(this.value, idx));
    },
    handleInput(idx, part) {
      const newValue = [...this.value];
      if (idx > newValue.length) {
        newValue.push(part);
      } else {
        newValue[idx] = part;
      }
      this.$emit('change', newValue);
    }
  }
};
</script>
<style scoped>
.sr-button.sr-field-button--remove {
  position: absolute;
  top: 1em;
}
.ltr .sr-field-button--remove {
  right: 10px;
}
.rtl .sr-field-button--remove {
  left: 10px;
}
.sr-list-field > .sr-field {
  min-height: unset;
}
.sr-field /deep/ .sr-field--error:empty {
  display: none;
}
.sr-button.sr-list--add {
  margin-top: 10px;
}
</style>
