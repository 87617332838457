import angular from 'angular';
/**
 * @author Slawomir Zytko <slawek@amsterdam-standard.pl>
 * @copyright Amsterdam Standard Sp. Z o.o.
 */
(function() {
  'use strict';

  angular.module('frontendApp').controller('FeedbackCtrl', FeedbackCtrl);

  FeedbackCtrl.$inject = ['$scope', 'feedbackModalService'];

  function FeedbackCtrl($scope, feedbackModal) {
    $scope.feedbackModal = feedbackModal;
  }
})();
