<template>
  <div class="homepage-mobile-info">
    <slot />
    <features v-if="!isPaid && userLoggedIn" />
    <reasons v-else-if="!userLoggedIn" />
    <free-trial
      v-if="!isPaid"
      sr-style="fancy"
      round
      class="info-container--trial"
      :class="{ 'info-container--trial__login': !userLoggedIn }"
    />
    <sr-button
      v-if="!userLoggedIn"
      key="h"
      class="info-container--howto"
      :href="getPageUrlForLang('how-it-works')"
      sr-style="flat-white"
      round
      :border="false"
    >
      <span v-translate>How it works</span>
    </sr-button>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import permissions from '@/mixins/permissions';

import Reasons from './Reasons';
import Features from './Features';
import SrButton from '@/components/elements/SrButton';
import FreeTrial from '@/components/home/FreeTrial';

export default {
  components: { SrButton, Reasons, Features, FreeTrial },

  computed: {
    ...mapGetters('conf', ['getPageUrlForLang']),
    ...mapGetters('users', ['isPaid', 'userLoggedIn'])
  }
};
</script>
<style scoped>
.homepage-mobile-info {
  display: flex;
  flex-flow: column;
  margin-top: -15px;
  margin-bottom: 40px;
  background-color: #f7f9fe !important;
}
.info-container--trial,
.info-container--howto {
  margin: 0.3rem auto;
  min-width: 14rem;
  height: 3.6rem;
  min-height: 3.6rem;
  font-size: 16px;
  font-weight: bold;
  width: 152px;
}
</style>
