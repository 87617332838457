export function formatVersion(version) {
  if (version === 'development') {
    return version;
  }

  const numbers = [
    version.slice(0, 2),
    version.slice(2, 4),
    version.slice(4, 6),
    version.slice(6, 8)
  ];
  const offset = [700, 0, 0, 0];

  return numbers.map((hex, idx) => parseInt(hex, 16) + offset[idx]).join(' ');
}
