const angularConfig = {};

const injectorPromise = new Promise((resolve, reject) => {
  angularConfig.resolve = resolve;
  angularConfig.reject = reject;
});

export async function setAngularInjector($injector) {
  angularConfig.resolve({ $injector });
  angularConfig.$injector = $injector;
}

export async function getAngularDeps(...deps) {
  const { $injector } = await injectorPromise;

  const ret = {};
  deps.forEach(key => {
    ret[key] = $injector.get(key);
  });

  return ret;
}
