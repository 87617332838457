import { load as loadLocale } from '@/translations/loader';

import US from '../images/flags/16/US.png';
import GB from '../images/flags/16/GB.png';
import NL from '../images/flags/16/NL.png';
import JP from '../images/flags/16/JP.png';
import FR from '../images/flags/16/FR.png';
import DE from '../images/flags/16/DE.png';
import IL from '../images/flags/16/IL.png';
import DK from '../images/flags/16/DK.png';
import FI from '../images/flags/16/FI.png';
import HU from '../images/flags/16/HU.png';
import IT from '../images/flags/16/IT.png';
import NO from '../images/flags/16/NO.png';
import PL from '../images/flags/16/PL.png';
import PT from '../images/flags/16/PT.png';
import ES from '../images/flags/16/ES.png';
import SE from '../images/flags/16/SE.png';
import RU from '../images/flags/16/RU.png';

// https://github.com/date-fns/date-fns/tree/master/src/locale
import {
  enUS,
  enGB,
  nl,
  ja,
  fr,
  de,
  he,
  da,
  fi,
  hu,
  it,
  nb,
  pl,
  pt,
  es,
  sv,
  ru
} from 'date-fns/locale';

function resolveLangToLocale(lang) {
  // lang is user input which could come from box and can be for example
  // en, nl, nl-nl, nl-be or es-419
  // localeKey is the code actually used in langs as a key
  // and should match lowercased Language header of po file
  let localeKey = lang;
  // if nl_BE is not found we default to nl
  if (!langs[localeKey]) {
    localeKey = localeKey.slice(0, 2);
  }
  return langs[localeKey] ? localeKey : null;
}

export async function loadTranslation(lang) {
  const localeKey = resolveLangToLocale(lang);
  if (!localeKey) {
    return { localKey: null, strings: null };
  }

  const strings = await loadLocale(localeKey);
  return { localeKey, strings };
}

export function getTimeLocale(lang) {
  const localeKey = resolveLangToLocale(lang);
  if (!localeKey) {
    return null;
  }
  return langs[localeKey].dateFnsLocaleObj;
}

// all possible values of conf/languageCode
// should be present as keys in the map below
const langs = {
  en: {
    name: 'English',
    shortName: 'US',
    flag: US,
    dir: 'ltr',
    dateFnsLocaleObj: enUS
  },
  'en-gb': {
    name: 'English',
    shortName: 'GB',
    flag: GB,
    dir: 'ltr',
    dateFnsLocaleObj: enGB
  },
  nl: {
    name: 'Nederlands',
    shortName: 'NL',
    flag: NL,
    dir: 'ltr',
    dateFnsLocaleObj: nl
  },
  ja: {
    name: '日本語',
    shortName: 'JP',
    flag: JP,
    dir: 'ltr',
    dateFnsLocaleObj: ja
  },
  fr: {
    name: 'Français',
    shortName: 'FR',
    flag: FR,
    dir: 'ltr',
    dateFnsLocaleObj: fr
  },
  de: {
    name: 'Deutsch',
    shortName: 'DE',
    flag: DE,
    dir: 'ltr',
    dateFnsLocaleObj: de
  },
  he: {
    name: 'עִברִית',
    shortName: 'IL',
    flag: IL,
    dir: 'rtl',
    dateFnsLocaleObj: he
  },
  da: {
    name: 'Dansk',
    shortName: 'DA',
    flag: DK,
    dir: 'ltr',
    dateFnsLocaleObj: da
  },
  fi: {
    name: 'Suomalainen',
    shortName: 'FI',
    flag: FI,
    dir: 'ltr',
    dateFnsLocaleObj: fi
  },
  hu: {
    name: 'Magyar',
    shortName: 'HU',
    flag: HU,
    dir: 'ltr',
    dateFnsLocaleObj: hu
  },
  it: {
    name: 'Italiano',
    shortName: 'IT',
    flag: IT,
    dir: 'ltr',
    dateFnsLocaleObj: it
  },
  no: {
    name: 'Norvegese',
    shortName: 'NO',
    flag: NO,
    dir: 'ltr',
    dateFnsLocaleObj: nb
  },
  pl: {
    name: 'Polski',
    shortName: 'PL',
    flag: PL,
    dir: 'ltr',
    dateFnsLocaleObj: pl
  },
  pt: {
    name: 'Português',
    shortName: 'PT',
    flag: PT,
    dir: 'ltr',
    dateFnsLocaleObj: pt
  },
  es: {
    name: 'Español',
    shortName: 'ES',
    flag: ES,
    dir: 'ltr',
    dateFnsLocaleObj: es
  },
  sv: {
    name: 'Svenska',
    shortName: 'SV',
    flag: SE,
    dir: 'ltr',
    dateFnsLocaleObj: sv
  },
  ru: {
    name: 'Pусский',
    shortName: 'RU',
    flag: RU,
    dir: 'ltr',
    dateFnsLocaleObj: ru
  }
};
export default langs;
