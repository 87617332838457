<template>
  <sr-button key="t" sr-style="fancy" round :href="href">
    <span v-if="forever" key="f" v-translate>Free forever</span>
    <span v-else-if="userLoggedIn" key="l" v-translate>Start free trial</span>
    <span v-else key="o" v-translate>Try for free</span>
  </sr-button>
</template>
<script>
import { mapGetters } from 'vuex';
import permissions from '@/mixins/permissions';
import Screen from '@/mixins/Screen';
import SrButton from '../elements/SrButton';

export default {
  components: { SrButton },
  mixins: [Screen],
  props: {
    forever: { type: Boolean, default: false }
  },
  computed: {
    ...mapGetters('conf', ['getPageUrlForLang']),
    ...mapGetters('users', ['isPaid', 'userLoggedIn']),
    href() {
      return this.userLoggedIn
        ? this.getPageUrlForLang('choose_trial')
        : this.getPageUrlForLang('choose_plan');
    }
  }
};
</script>
