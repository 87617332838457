export function formatQs(url, data) {
  if (!data || Object.keys(data).length === 0) {
    return url;
  }
  const urlParams = new URLSearchParams();
  for (let key in data) {
    urlParams.append(key, data[key]);
  }
  url += '?';
  url += urlParams.toString();
  return url;
}
