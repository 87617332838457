<template>
  <svg
    width="13px"
    height="14px"
    viewBox="0 -1 14 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      stroke="currentColor"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <polyline points="0 2.4 1.22222222 2.4 11 2.4"></polyline>
      <path
        d="M9.77777778,2.4 L9.77777778,10.8 C9.77777778,11.4627417 9.23057025,12 8.55555556,12 L2.44444444,12 C1.76942975,12 1.22222222,11.4627417 1.22222222,10.8 L1.22222222,2.4 M3.05555556,2.4 L3.05555556,1.2 C3.05555556,0.5372583 3.60276308,0 4.27777778,0 L6.72222222,0 C7.39723692,0 7.94444444,0.5372583 7.94444444,1.2 L7.94444444,2.4"
      ></path>
    </g>
  </svg>
</template>
<script>
export default {};
</script>
