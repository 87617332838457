import angular from 'angular';

/**
 * @ngdoc function
 * @name frontendApp.controller:UserSessionsCtrl
 * @description
 * # UserSessionsCtrl
 * Controller of the frontendApp
 */
angular.module('frontendApp').controller('UserOAuthsCtrl', [
  '$scope',
  '$route',
  'UserService',
  'srLoginModal',
  function($scope, $route, UserService, srLoginModal) {
    $scope.token = $route.current.params.token;
    $scope.email = $route.current.params.email;
    $scope.backend = $route.current.params.backend;
    $scope.backendSerialized = {};
    $scope.backendSerialized['password'] = false;

    $scope.getNewToken = function() {
      return UserService.associateOAuth().then(function(resp) {
        $scope.token = resp.data.token;
      });
    };

    $scope.openLoginModal = function() {
      if (!angular.isDefined($scope.token)) {
        $scope.getNewToken().then(function() {
          var data = {
            logged_in: false,
            registered: true,
            filterOauths: true,
            token: $scope.token,
            backends: $scope.backendSerialized,
            needs_2fa: false
          };
          srLoginModal.activate({}, data);
        });
      } else {
        var data = {
          logged_in: false,
          registered: true,
          filterOauths: true,
          token: $scope.token,
          backends: $scope.backendSerialized,
          needs_2fa: false
        };
        srLoginModal.activate({}, data);
      }
    };

    $scope.updateAuths = function() {
      UserService.waitForUser().then(function() {
        if ($scope.user.logged_in) {
          UserService.getAllOAuths().then(function(response) {
            $scope.oauths = response.data;
          });
        } else {
          $scope.openLoginModal();
        }
      });
    };

    $scope.deleteOauth = function(oauth) {
      UserService.deleteOAuth(oauth.uid).then(function(response) {
        $scope.oauths = response.data;
      });
    };

    $scope.parseName = function(oauth) {
      var mapping = {
        'salesforce-oauth2': 'Salesforce',
        'google-oauth2': 'Google',
        'linkedin-oauth2': 'LinkedIn',
        'salesforce-oauth2-sandbox': 'Salesforce Sandbox',
        facebook: 'Facebook',
        'dropbox-oauth2': 'DropBox',
        'microsoft-graph': 'Microsoft'
      };

      if (!mapping.hasOwnProperty(oauth)) {
        return oauth;
      }

      return mapping[oauth];
    };

    if (angular.isDefined($scope.backend)) {
      $scope.backendSerialized[$scope.backend] = true;
      $scope.backendSerialized['count'] = 1;
      $scope.oauths = $scope.backendSerialized;
    }

    $scope.updateAuths();
  }
]);
