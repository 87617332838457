import angular from 'angular';

/**
 * @ngdoc function
 * @name frontendApp.controller:SfCtrl
 * @description
 * # SfCtrl
 * Controller of the frontendApp
 */
angular.module('frontendApp').controller('SfCtrl', [
  '$scope',
  '$location',
  '$http',
  function($scope, $location, $http) {
    $scope.consumerKey =
      '3MVG9Rd3qC6oMalUMeD26Xl5FFpd8NrdYpsORMtiMfLUA_ifiMNxy62y4FKC8IzBOfEDphqSgbteLFEDtcROy';

    $scope.sfLogin = function(user, pass) {
      var req = {
        method: 'POST',
        url: 'https://login.salesforce.com/services/oauth2/authorize',
        headers: {
          'Content-Type': undefined
        },
        params: {
          response_type: 'token ',
          client_id: $scope.consumerKey,
          redirect_uri: 'example.com'
        }
      };

      $http(req).then(
        function(response) {
          $scope.token = JSON.parse(response)['token'];
          $scope.sfLoggedIn = true;
        },
        function(error) {
          $scope.sfLoggedIn = false;
        }
      );
    };

    $scope.getInitialInfo = function() {
      if (!$scope.sfLoggedIn) {
        return false;
      }
    };
  }
]);
