import sample from 'lodash/sample';
import snakeCase from 'lodash/snakeCase';
import forEach from 'lodash/forEach';
import { getCookie, getCookies, setCookie } from '@/utils/getJSONCookie';
import weightedValueArray from '@/utils/weightedValueArray';

export const AB_TESTING_COOKIE_PREFIX = 'testVariation__';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async getVariation({ dispatch }, { name, variations }) {
      const cookieName = AB_TESTING_COOKIE_PREFIX + snakeCase(name);
      const existingValue = getCookie(cookieName);

      if (existingValue) {
        return existingValue;
      } else {
        const variationsArray = Array.isArray(variations)
          ? variations
          : weightedValueArray(variations);
        const variation = sample(variationsArray);
        setCookie(cookieName, variation);
        dispatch(
          'events/trackExperiment',
          { name, params: { variation } },
          { root: true }
        );

        return variation;
      }
    },
    async getAllVariations() {
      const variations = {};
      forEach(getCookies(), function(v, k) {
        if (k.startsWith(AB_TESTING_COOKIE_PREFIX)) {
          variations[k.replace(AB_TESTING_COOKIE_PREFIX, 'ab_')] = v;
        }
      });
      return variations;
    },
    async getRegisterModalVariation({ dispatch }) {
      const variations = ['classic', 'new_design'];
      return dispatch('getVariation', {
        name: 'RegisterModalTest',
        variations
      });
    }
  }
};
