import angular from 'angular';
import _ from 'lodash';
import $ from 'jquery';
import config from '@/config';

/**
 * @ngdoc function
 * @name frontendApp.controller:MainCtrl
 * @description
 * # MainCtrl
 * Controller of the frontendApp
 */
angular.module('frontendApp').controller('MainCtrl', [
  '$scope',
  '$rootScope',
  '$window',
  '$location',
  '$http',
  'srUploadModal',
  'srSubscribeTooMuchDocumentsModal',
  'ENV',
  'ipCookie',
  '$timeout',
  'docService',
  'messageService',
  'gettextCatalog',
  '$route',
  'srApiUploadModal',
  'confService',
  'srVideoModal',
  'srSocialShareModal',
  'utils',
  'UserService',
  'windowService',
  '$q',
  'srSubscribeModal',
  '$vuex',
  function(
    $parentScope,
    $rootScope,
    $window,
    $location,
    $http,
    srUploadModal,
    srSubscribeTooMuchDocumentsModal,
    ENV,
    ipCookie,
    $timeout,
    docService,
    messageService,
    gettextCatalog,
    $route,
    srApiUploadModal,
    confService,
    srVideoModal,
    srSocialShareModal,
    utils,
    UserService,
    windowService,
    $q,
    srSubscribeModal,
    $vuex
  ) {
    let $scope = $rootScope; // TODO: think of different approach

    $scope.confService = confService;
    $scope.docService = docService;

    if (utils.isLanguageEnglish()) {
      $scope.ctaTemplate = 'partials/home_ctas/get_started.html';
      UserService.trackEvent(
        'Shown get started with SignRequest',
        'Homepage CTA'
      );
    } else {
      $scope.ctaTemplate = 'views/logosvg.html';
    }

    $scope.maybeShowSocialShare = function() {
      if (
        $scope.user &&
        angular.isObject($scope.user.social_actions) &&
        $scope.user.social_actions.show_social_share
      ) {
        $scope.user.social_actions.show_social_share = false;
        srSocialShareModal.activate();
      }
    };

    $scope.getWhoDisplay = function(type) {
      if (type === 'm') {
        return gettextCatalog.getString('Only me');
      }
      if (type === 'mo') {
        return gettextCatalog.getString('Me and others');
      }
      if (type === 'o') {
        return gettextCatalog.getString('Only others');
      }
      return '';
    };

    $scope.openHelp = function(selector) {
      // be sure to show all fields
      if (selector !== '#help-step-demo') {
        $scope.signrequest.who =
          $scope.signrequest.who === 'm' ? 'mo' : $scope.signrequest.who;
      }
      angular.element(selector).click();
    };

    $scope.setWho = function(value) {
      $scope.signrequest.who = value;
      $scope.signrequest.whoChanged = true;
    };

    $scope.openAfterDocumentUploadModal = function() {
      UserService.hasPermOrModal('pro', 'multi_doc', function() {
        srUploadModal.activate(
          {},
          {
            parent_doc: $scope.doc.uuid
          }
        );
      });
    };

    $scope.openUploadModal = function(trigger_open_file) {
      // srUploadModal.getEventDispatcher().unsubscribe('sr.modal.shown');
      srUploadModal.activate(
        {},
        {
          urlApiV1: $scope.urlApiV1,
          triggerOpenFileChoice: !!trigger_open_file,
          makeDemo: $scope.makeDemo
        }
      );
    };

    $scope.checkPermsAndOpenUploadModal = function(trigger_open_file) {
      UserService.checkActiveTeamPermOrModal(function() {
        $scope.openUploadModal(trigger_open_file);
      });
    };

    if ($route.current.params.open_upload && !$scope.isVueHomebox) {
      $scope.openUploadModal(true);
      // remove the param
      $location.search({}).replace();
    }

    $scope.checkToShowHelpPopup = function() {
      if (
        $scope.user &&
        !$scope.user.logged_in &&
        !$route.current.params.login &&
        !$route.current.params.register &&
        !$vuex.getters['modals/modalOpen']
      ) {
        // open the help for a non logged in user
        $scope.openHelp('#help-step-1');
      }
    };

    $scope.doc = null;
    $scope.updateDocuments = function(callback) {
      docService.getNewDocuments().then(function(response) {
        // these only include new docs without a `signrequest` or signrequest that are still in the prepare state
        // if $route.current.params.doc_uuid if give we only get 1 back
        if (response.data.latest_doc && response.data.latest_doc.uuid) {
          // if we already have document we can assume they already know the drill and we can remove the unused fields
          // when 'Only me' is active
          var doc = response.data.latest_doc;
          if (!angular.isDefined(doc)) {
            $scope.extra_docs = [];
            return;
          }
          $scope.doc = doc;
          $scope.extra_docs = $scope.doc.extra_docs;
          $scope.updateConfFromSignRequest();
          $scope.signrequest.whoChanged = true;
          if (doc.status.code === 'co') {
            $scope.getConverted(null, $scope.doc);
          }
          angular.forEach($scope.extra_docs, function(extra_doc) {
            if (extra_doc.status.code === 'co') {
              $scope.getConverted(null, extra_doc);
            }
          });
        } else {
          $scope.checkToShowHelpPopup();
        }
      });
    };

    $scope.deleteDocument = function(doc) {
      doc.can_be_deleted = false;
      docService.deleteDocument(doc.uuid).then(function() {
        $scope.doc = null;
        doc.can_be_deleted = true;
        $scope.signrequest.is_demo = false;
        $scope.resetBox();
        $scope.urlApiV1();
      });
    };

    $scope.getConverted = function(finished_doc_callback, doc_to_update) {
      if (!$scope.doc) {
        $scope.doc = doc_to_update;
      }

      if (doc_to_update) {
        var finised_callback = function() {
          if (angular.isFunction(finished_doc_callback)) {
            $scope.updateConfFromSignRequest(finished_doc_callback);
          } else {
            $scope.updateConfFromSignRequest();
          }
        };

        if (doc_to_update.template && doc_to_update.template.created) {
          // already converted
          finised_callback();
        } else {
          docService.getConverted(doc_to_update).then(
            function(data) {
              // success
              // if (data.uuid == $scope.doc.uuid) {
              //   $scope.doc = data
              // }
              doc_to_update.status = data.status; // we need to update the status specifically, since angular will somehow not refresh the dom if the objects is updated

              finised_callback();
            },
            function() {
              // error
              $scope.updateDocuments();
            }
          );
        }
      }
    };

    $scope.openIntroVideoModal = function() {
      srVideoModal.activate(
        {},
        {
          title: gettextCatalog.getString('How SignRequest works'),
          description_1: gettextCatalog.getString(
            'Sign yourself or get documents signed.'
          ),
          description_2:
            gettextCatalog.getString('Easy, secure, legally binding and') +
            ' ' +
            gettextCatalog.getString('free'),
          video_url:
            '//www.youtube.com/embed/suRdEXAe5M8?list=PLk8mTVm3xbH_e7DSsMW2Z8ITCoDABrzdv&amp;showinfo=0&amp;rel=0&amp;modestbranding=1'
        }
      );
    };

    ////// SignRequest //////

    $scope.signrequest = {
      doc: $scope.doc, // assign again when POSTing
      from_email: '',
      subject: '',
      message: '',
      signers: '', // parsed to array on the server (including extra validity check)
      signer_options: [],
      who: 'mo', // options are; m (only me), mo (me and others), o (only others)
      required_attachments: [],
      disable_attachments: false,
      disable_text_signatures: false,
      disable_draw_signatures: false,
      disable_upload_signatures: false,
      disable_text: false,
      disable_date: false,
      disable_emails: false,
      send_reminders: false,
      terms: false, // agreed to the terms of use and privacy policy,
      whoChanged: false, // set to true in WhoCtrl, needed so we initially show the contacts field
      default_level: 'signature', // the default signature level that added signers get, can be changed by API
      prepare_doc: false,
      is_demo: false, // if true and who == 'm' we predefine the placeholders in the demo doc
      integration_data: $scope.integration_data || {}, // integration_data is persistent
      template_uuid: null,
      prefill_tags: []
    };

    $scope.showSendButton = function() {
      return !(
        $scope.user &&
        $scope.user.team_user &&
        $scope.user.team_user.team &&
        $scope.user.team_user.team.require_sending_prepared_docs
      );
    };

    $scope.showPrepareButton = function() {
      if (!$scope.showSendButton()) {
        return true;
      }
      if (
        $scope.signrequest &&
        $scope.signrequest.whoChanged &&
        $scope.signrequest.who === 'm'
      ) {
        return false;
      }

      return true;
    };

    $scope.setCtaTemplate = function() {
      var eventCategory = 'Homepage CTA';
      if (!$scope.user || !utils.isLanguageEnglish()) {
        $scope.ctaTemplate = 'views/logosvg.html';
        return false;
      } else if ($scope.user.registered_on && !UserService.userHasPaidUsage()) {
        $scope.ctaTemplate = 'partials/home_ctas/upgrade_to_pro.html';
        UserService.trackEvent('Shown upgrade to pro', eventCategory);
      } else if ($scope.user.permissions.plan === 'PRO') {
        $scope.ctaTemplate =
          'partials/home_ctas/boost_productivity_with_business.html';
        UserService.trackEvent(
          'Shown boost productivity with business',
          eventCategory
        );
      } else if ($scope.user.permissions.plan === 'SME') {
        $scope.ctaTemplate = 'partials/home_ctas/scale_to_meet_your_needs.html';
        UserService.trackEvent('Shown scale to meet your needs', eventCategory);
      }
    };

    UserService.waitForUser().then(function(user) {
      // check if the user agreed to the terms previously, if so, set to true
      // $scope.getUserEmails();
      if (angular.isDefined(user)) {
        if (user.agreed_to_terms) {
          $scope.signrequest.terms = true;
        }
        $scope.signrequest.from_email = user.email;
        $scope.setCtaTemplate();
        // if (!user.logged_in && !ipCookie('intro_shown') && !$route.current.params.is_demo) {
        //   ipCookie('intro_shown', true, {domain: ENV.cookie_domain, expires: utils.longCookieExpires()});
        //   $scope.openIntroVideoModal();
        // }
      }
    });

    $scope.resetBox = function() {
      var integration_data = {};
      if (
        angular.isDefined($route.current.params.objectId) &&
        angular.isDefined($route.current.params.objectType)
      ) {
        //Integration data should be persistend even when another doc is uplaoded
        integration_data = $scope.signrequest.integration_data;
      }
      $scope.doc = null;
      $scope.extra_docs = [];
      $scope.signrequest.doc = $scope.doc;
      $scope.signrequest.subject = '';
      $scope.signrequest.signers = '';
      $scope.signrequest.message = '';
      $scope.signrequest.who = 'mo';
      $scope.signrequest.signer_options = [];
      $scope.signrequest.required_attachments = [];
      $scope.signrequest.disable_attachments = false;
      $scope.signrequest.disable_text_signatures = false;
      $scope.signrequest.disable_draw_signatures = false;
      $scope.signrequest.disable_upload_signatures = false;
      $scope.signrequest.disable_text = false;
      $scope.signrequest.disable_date = false;
      $scope.signrequest.disable_emails = false;
      $scope.signrequest.send_reminders = false;
      $scope.signrequest.default_level = 'signature';
      $scope.signrequest.is_demo = false;
      $scope.signrequest.prepare_doc = false;
      $scope.signrequest.integration_data = integration_data;
      $scope.signrequest.template_uuid = null;
      $scope.signrequest.prefill_tags = [];
    };

    $scope.updateConfFromSignRequest = function(callback) {
      UserService.waitForUser().then(function() {
        if ($scope.doc && $scope.doc.signrequest) {
          angular.forEach(
            [
              'message',
              'who',
              'disable_attachments',
              'disable_text_signatures',
              'disable_draw_signatures',
              'disable_upload_signatures',
              'disable_text',
              'disable_date',
              'disable_emails',
              'send_reminders'
            ],
            function(item) {
              if (
                $scope.signrequest.hasOwnProperty(item) &&
                $scope.doc.signrequest.hasOwnProperty(item)
              ) {
                $scope.signrequest[item] = $scope.doc.signrequest[item];
              }
            }
          );
          if (!$scope.signrequest.from_email) {
            $scope.signrequest.from_email = $scope.doc.signrequest.from_email;
          }
          if (!$scope.signrequest.from_email) {
            $scope.openHelp('#help-step-demo');
          }
        }
        $scope.signrequest.whoChanged = true;

        if ($scope.doc && $scope.doc.template && $scope.doc.template.uuid) {
          $scope.signrequest.template_uuid = $scope.doc.template.uuid;
        }
        $scope.signrequest.signer_options =
          $scope.signrequest.signer_options || [];
        if ($scope.doc && $scope.doc.signrequest) {
          var signers = _.filter($scope.doc.signrequest.signers, function(
            signer
          ) {
            return signer.email !== $scope.signrequest.from_email;
          });
          if (
            $scope.doc.signrequest.signers &&
            $scope.doc.signrequest.signers.length
          ) {
            $scope.user.contacts = _.unionBy(
              $scope.user.contacts,
              _.map(signers, function(item) {
                return { text: item.email, value: item.email };
              }),
              'value'
            );
            $scope.signrequest.signers = _.map(signers, 'email').join(',');
          }
        }
        $scope.serializeSigners();
        if ($scope.doc && $scope.doc.signrequest) {
          _.each($scope.doc.signrequest.signers, function(signer) {
            // set text message verification and order from signers
            var conf = _.find($scope.signrequest.signer_options, {
              email: signer.email
            });
            if (conf) {
              conf.verify_phone_number =
                signer.verify_phone_number || conf.verify_phone_number;
              conf.order = angular.isNumber(signer.order)
                ? signer.order
                : conf.order;
              conf.level = signer.level || conf.level;
            }
          });
        }
        if (angular.isFunction(callback)) {
          callback();
        }
      });
    };

    $scope.levelOptions = function(signer) {
      if ($scope.signrequest.from_email === signer.email) {
        return docService.levelOptionsForOwner;
      } else {
        return docService.levelOptionsForRecipient;
      }
    };

    $scope.$watch('user.email', function(new_val, old_val) {
      $scope.signrequest.from_email = new_val;
    });

    $scope.$watch(
      function() {
        const hasPrepareMode = $vuex.getters['signrequest/hasPrepareMode'];
        return Boolean(hasPrepareMode);
      },
      function(newVal) {
        $scope.isVuePrepareFile = newVal;
      }
    );

    $scope.hideMarketing = config.hideMarketing;
    $scope.emailValid = true;
    $scope.signersValid = true;
    $scope.senderIsContect = false;
    $scope.termsChecked = false; // only who error when they clicked send

    $scope.show_advanced = false;
    $scope.toggleAdvanced = function() {
      $scope.show_advanced = !$scope.show_advanced;
    };
    $scope.disableAdvanced = function() {
      return $scope.signrequest.signer_options.length <= 1;
    };

    $scope.getDefaultsForSignerIndex = function(signer_index) {
      if (angular.isDefined(signer_index)) {
        if (
          $scope.doc &&
          $scope.doc.template &&
          $scope.doc.template.signrequest
        ) {
          var signer_conf = _.find($scope.doc.template.signrequest.signers, {
            signer_index: signer_index
          });
          if (signer_conf) {
            var conf = angular.copy(signer_conf);
            // signer_index should not be part of the configuration for clarity as that index is only used to get the
            // configuration here
            delete conf.signer_index;
            return conf;
          }
        } else if ($scope.signrequest.who === 'm') {
          return { level: 'cc' };
        }
      }
      return { level: $scope.signrequest.default_level };
    };

    $scope.serializeSigners = function(whoChanged) {
      var parsed_signers = utils.parseValues($scope.signrequest.signers);
      if ($scope.signrequest.from_email) {
        parsed_signers.unshift($scope.signrequest.from_email);
      }

      if (!parsed_signers) {
        $scope.signrequest.signer_options =
          $scope.signrequest.signer_options || [];
      } else {
        _.forEach(parsed_signers, function(email, signer_index) {
          if (
            _.findIndex($scope.signrequest.signer_options, function(obj) {
              return obj.email === email;
            }) === -1
          ) {
            // new signer
            if (!$scope.signrequest.from_email) {
              // in case we do not have a from_email yet but already get a signer back from the backend
              // we need to get the second configuration (skip the owner conf)
              signer_index += 1;
            }
            var conf = $scope.getDefaultsForSignerIndex(signer_index);
            conf.email = email;
            var new_signer = docService.newSigner(conf);
            if (email === $scope.signrequest.from_email) {
              $scope.signrequest.signer_options.unshift(new_signer);
            } else {
              $scope.signrequest.signer_options.push(new_signer);
            }
          }
        });
        _.forEach($scope.signrequest.signer_options, function(obj) {
          var signer_index = _.findIndex(parsed_signers, function(email) {
            return email === obj.email;
          });
          if (signer_index === -1) {
            // remove signer
            $scope.signrequest.signer_options = _.filter(
              $scope.signrequest.signer_options,
              function(item) {
                return item.email !== obj.email;
              }
            );
          } else if ($scope.signrequest.from_email === obj.email) {
            // check that sender never needs to sign when using 'Only others'
            if ($scope.signrequest.who === 'o') {
              obj.level = 'cc';
            } else {
              // default from the template (if we are using one)
              var owner_conf = $scope.getDefaultsForSignerIndex(0);
              obj.level = owner_conf.level;
              obj.order = angular.isNumber(owner_conf.order)
                ? owner_conf.order
                : obj.order;
              if (obj.level === 'cc') {
                // never allow copy only for the owner when not 'only others'
                obj.level = $scope.signrequest.default_level;
              }
            }
          } else {
            // this is a receiver and is always set to cc when we changed to only me
            if (angular.isDefined(whoChanged)) {
              if (whoChanged === 'm') {
                if (obj.level !== 'cc') {
                  // we switch back to this when they switch the 'who'
                  obj.previous_selected_level = obj.level;
                  obj.level = 'cc';
                }
              } else if (obj.level === 'cc') {
                // should be approve only or signature here
                obj.level = obj.previous_selected_level || obj.level;
              }
            }
          }
        });
      }
    };

    // $scope.addEmail = function(item, escape){return '<div><a href="#/account">' + gettextCatalog.getString('Add email address') + '</a></div>';};

    $scope.$watch('signrequest.signers', function(new_val, old_val) {
      if (new_val !== old_val) {
        $scope.serializeSigners();
      }
    });

    $scope.$watch('signrequest.who', function(new_val, old_val) {
      if (new_val !== old_val) {
        $scope.serializeSigners(new_val);
      }
    });

    $scope.$watch('signrequest.from_email', function(new_val, old_val) {
      if (new_val !== old_val) {
        $scope.serializeSigners();
      }
    });
    $scope.fromEmailValidated = function() {
      if (!$scope.user) {
        return false;
      }
      return _.some($scope.user.alt_emails, {
        // check if the email is verified in the alt_emails
        email: $scope.signrequest.from_email,
        validated: true
      });
    };

    $scope.getWindowServiceParams = function() {
      var params = {};
      if (windowService.sr_window_id) {
        params.sr_window_id = windowService.sr_window_id;
      }
      if (windowService.next) {
        params.next = windowService.next;
      }
      if (windowService.close) {
        params.close = windowService.close;
      }
      return params;
    };

    $scope.emailAllowed = true;
    $scope.checkEmail = async function(next_api_params) {
      var email_to_check =
        next_api_params && next_api_params.from_email
          ? next_api_params.from_email
          : $scope.signrequest.from_email;
      if (
        angular.isDefined($scope.user) &&
        !$scope.user.logged_in &&
        email_to_check
      ) {
        const resp = await UserService.checkUser(email_to_check);
        if (
          resp.data &&
          angular.isDefined(resp.data.registered) &&
          resp.data.registered
        ) {
          var loginData = {
            filterOauths: true,
            oauths: resp.data.backends,
            message: gettextCatalog.getString(
              'Your email is already registered by SignRequest, please login'
            ),
            hideClose: false,
            email: resp.data.email || ''
          };
          if (($scope.doc && $scope.doc.uuid) || next_api_params) {
            var next_params = {};
            if (next_api_params) {
              // used by frontend api which means someone inserted an email which is registered and we need
              // them to login
              angular.extend(next_params, $scope.getWindowServiceParams());
              angular.extend(next_params, next_api_params);
              loginData.hideClose = true;
            }
            if ($scope.doc && $scope.doc.uuid) {
              next_params.doc_uuid = $scope.doc.uuid;
            }
            if (next_params.doc_uuid) {
              next_params.api = 'v1';
              loginData.force_reload = true;
            }
            loginData.next = '/#/?' + utils.urlParamsFromObject(next_params);
          }
          return $scope.openLoginModal(loginData);
        }
      }
    };

    $scope.validateEmails = function(form) {
      $scope.emailValid = utils.EMAIL_REGEXP.test(
        $scope.signrequest.from_email
      );
      if ($scope.signrequest.who !== 'm') {
        if (!$scope.signrequest.signers) {
          $scope.signersValid = false;
        }

        $scope.senderIsContect = false;
        _.forEach(utils.parseValues($scope.signrequest.signers), function(
          email
        ) {
          $scope.signersValid = utils.EMAIL_REGEXP.test(email);
          $scope.senderIsContect =
            $scope.senderIsContect ||
            ($scope.signrequest.from_email === email &&
              !angular.isDefined($scope.prefilled_signers));
        });
      } else {
        // we are sending to only the owner
        $scope.signersValid = true;
      }
    };

    $scope.validateSignerOptions = function() {
      let valid = true;

      angular.forEach(
        angular.element('input[international-phone-number]'),
        function(el) {
          el = angular.element(el);
          if (el.val()) {
            const itil = $window.intlTelInputGlobals.getInstance(el[0]);
            if (!itil.isValidNumber()) {
              messageService.add(
                'error',
                gettextCatalog.getString('Invalid phone number') +
                  ': ' +
                  itil.getNumber(),
                10000
              );
              valid = false;
            }
          }
        }
      );

      return valid;
    };

    $scope.loadingDoSignRequest = false;

    $scope.doSignRequest = function(form) {
      /* eslint no-empty: off */
      if (UserService.userOverMonthLimit()) {
        srSubscribeTooMuchDocumentsModal.activate(
          {},
          {
            trigger: 'missing_pro_permissions',
            feature: 'unlimited_documents'
          }
        );
        return;
      }
      if ($scope.loadingDoSignRequest) return;

      var openInNewWindow = false;
      var newWindow = null;

      if (!$scope.validateSignerOptions()) {
        return;
      }

      $scope.validateEmails(form);
      if ($scope.signersValid && $scope.emailValid && !$scope.senderIsContect) {
        $scope.signrequest.doc = $scope.doc;
        $scope.signrequest.terms = angular.element('#input--terms')[0].checked;
        if (!$scope.signrequest.terms) {
          $scope.termsChecked = true;
          return;
        }
        try {
          // we are in a popup and we can close ourselves
          if ($route.current.params.popupWidth || windowService.sr_window_id) {
            // salesforce || signrequest-js opened a popup
            openInNewWindow = true;
          }
        } catch (err) {}

        if (openInNewWindow) {
          if (
            $scope.signrequest.who !== 'o' ||
            $scope.signrequest.prepare_doc
          ) {
            // in case of 'only others' and we are not preparing the doc we don't need the loading page
            newWindow = windowService.openLoadingPage();
          }
        }
        $rootScope.is_demo = false;

        $scope.loadingDoSignRequest = true;
        docService.doSignRequest($scope.signrequest).then(
          function(response) {
            $scope.loadingDoSignRequest = false;

            if (
              response.data &&
              angular.isDefined(response.data.registered) &&
              response.data.registered
            ) {
              var loginMessage = gettextCatalog.getString(
                'Your email is already registered by SignRequest, please login'
              );
              var loginData = {
                filterOauths: true,
                oauths: response.data.backends,
                message: loginMessage,
                next: '/#',
                hideClose: false
              };
              if ($scope.doc && $scope.doc.uuid) {
                var next_params = { doc_uuid: $scope.doc.uuid, api: 'v1' };
                angular.extend(next_params, $scope.getWindowServiceParams());
                loginData.next =
                  '/#/?' + utils.urlParamsFromObject(next_params);
              }

              if (newWindow) {
                newWindow.close();
                if (!$window.document.hasFocus()) {
                  // we need to login and we need to focus the popup window again with an ugly alert
                  // this is the only way to reliably focus a window unfortunately
                  $window.alert(loginMessage);
                }
              }
              $scope.openLoginModal(loginData);
            }

            if (response.data.status === 'SUCCESS') {
              var payload = { doc_uuid: null };
              if ($scope.doc && $scope.doc.uuid) {
                payload.doc_uuid = $scope.doc.uuid;
              }
              windowService.emit('sent', payload);
              if (!response.data.redirect_url) {
                windowService.emit('finished', payload);
              }
              $scope.resetBox();
              if (angular.isDefined($scope.user)) {
                $scope.user.agreed_to_terms = true;
              }
            } else {
              if (newWindow) {
                newWindow.close();
                if (!$window.document.hasFocus() && response.data.message) {
                  // we've received an error so we need to focus the popup window again with an ugly alert
                  // this is the only way to reliably focus a window unfortunately
                  $window.alert(response.data.message);
                }
              }
              return;
            }

            if (response.data.redirect_url) {
              if (response.data.is_demo) {
                $rootScope.is_demo = true;
              } else {
                $rootScope.is_demo = false;
              }

              if (response.data.force_reload) {
                // user reload needed on document signing page as we have an in person signer
                $scope.getUserAfterRouteChange();
              }

              if (openInNewWindow && newWindow) {
                var url = response.data.redirect_url;
                if ($route.current.params.next || windowService.next) {
                  url = utils.addUrlParam(
                    url,
                    'next',
                    $route.current.params.next || windowService.next
                  );
                }
                if (windowService.sr_window_id) {
                  url = utils.addUrlParam(
                    url,
                    'sr_window_id',
                    windowService.sr_window_id
                  );
                }
                newWindow.location.assign('/#/' + url);
                if ($window.focus) {
                  newWindow.focus();
                }
              } else {
                $location.path(response.data.redirect_url); // we redirect the owner to sign himself
              }
            } else {
              if (openInNewWindow && newWindow) {
                // we don't need to sign but we've still opened the loading page, close it again
                newWindow.close();
              }
              $scope.updateDocuments();
              $scope.maybeShowSocialShare();
            }

            if (openInNewWindow) {
              if (newWindow) {
                if (windowService.sr_window_id) {
                  // we want to keep it open as we need to pass through the 'signed' etc. events
                } else {
                  // used by salesforce
                  // close the popup right away
                  $window.close();
                }
              } else {
                // wait for the confirm message and close the popup in case of 'only others' and not preparing
                $timeout($window.close, 3000);
              }
            }
          },
          function(error) {
            // some network error
            $scope.loadingDoSignRequest = false;
          }
        );
      }
    };

    $scope.docIsPrepared = function() {
      // if we went to the prepare view and we land here again we have a prepare_url from the server
      return $scope.doc && $scope.doc.prepare_url;
    };

    $scope.goToPrepare = function() {
      if ($scope.docIsPrepared()) {
        $location.path($scope.doc.prepare_url);
      }
    };

    $scope.makeDemo = function() {
      $scope.signrequest.is_demo = true;
      $scope.urlApiV1();
      if ($scope.user) {
        if (!$scope.user.logged_in) {
          // remove signatures for not logged in users when doing a demo
          $scope.user.sigs = [];
          $scope.user.contacts = [];
          $scope.user.agreed_to_terms = false;
        }
      }
    };

    ////// URL api //////
    $scope.urlApiV1 = async function(params) {
      // by default the params come from the url but can be passed manually also
      params = params || {};

      if ($scope.signrequest.is_demo || $route.current.params.is_demo) {
        $scope.signrequest.is_demo = true;
        params = {
          api: 'v1',
          who: 'm',
          from_email:
            $scope.signrequest.from_email ||
            $route.current.params.from_email ||
            '',
          signers: '',
          doc_url:
            'https://signrequest.com/static/demo/SignRequestDemoDocument.pdf'
        };
        if ($scope.user && $scope.user.email) {
          params.from_email = $scope.user.email;
          if ($route.current.params.doc_uuid) {
            params.doc_uuid = $route.current.params.doc_uuid;
          }
        }
        if (params.from_email && $route.current.params.do_redirect) {
          params.do_redirect = true;
        }
      } else {
        params = angular.extend(params, $route.current.params);
      }

      const user = await UserService.waitForTracked();
      if (params.api === 'v1') {
        if (
          angular.isDefined(params.doc_url) ||
          angular.isDefined(params.doc_uuid) ||
          angular.isDefined(params.template_uuid) ||
          angular.isDefined(params.public_template_id)
        ) {
          await $scope.checkEmail(params);
          if (
            params.doc_url ||
            params.template_uuid ||
            params.public_template_id
          ) {
            srApiUploadModal.activate();
          }
          if (
            params.doc_uuid &&
            !params.frontend_id &&
            angular.isDefined(user) &&
            !user.logged_in
          ) {
            // we need to be logged in when we are using doc_uuid
            const loginData = {
              next: '/#/?' + utils.urlParamsFromObject(params),
              hideClose: true
            };
            await $scope.openLoginModal(loginData);
          }
          const resp = await docService.getApiDocument({
            doc_url: params.doc_url,
            as_template: params.as_template,
            doc_uuid: params.doc_uuid,
            frontend_id: params.frontend_id,
            template_uuid: params.template_uuid,
            public_template_id: params.public_template_id,
            from_email: params.from_email,
            parent_doc: params.parent_doc
          });
          let docConvertedCallback = function docConvertedCallback() {
            srApiUploadModal.deactivate();
            if (
              angular.isArray(resp.data.extra_docs) &&
              resp.data.extra_docs.length
            ) {
              $scope.updateDocuments();
            }
          };
          if (params.from_email && (params.do_redirect || params.sign_now)) {
            // if we are going to redirect them to the signing page directly do not close the srApiUploadModal just yet
            $scope.signrequest.terms = true;
            docConvertedCallback = function() {
              $scope.doSignRequest($scope.srForm);
              srApiUploadModal.deactivate();
            };
          } else {
            srApiUploadModal.deactivate();
          }
          if ($scope.signrequest.is_demo || params.public_template_id) {
            if (!params.from_email) {
              $scope.openHelp('#help-step-demo');
            }
            $('html, body').animate({ scrollTop: 0 }, 'slow');
          }
          $scope.getConverted(docConvertedCallback, resp.data.doc);

          const fields = [
            'from_email',
            'message',
            'subject',
            'signers',
            'who',
            'disable_attachments',
            'default_level',
            'prefill_tags'
          ];
          for (let item of fields) {
            if ($scope.signrequest.hasOwnProperty(item) && params[item]) {
              let param_val = params[item];
              if (item === 'prefill_tags') {
                param_val = utils.parseObjectFromEncodedUrlParam(param_val);
              }

              if (param_val) {
                if (item === 'disable_attachments') {
                  param_val = Boolean(
                    param_val !== '0' && param_val !== 'false'
                  );
                }

                $scope.signrequest[item] = param_val;

                if (item === 'signers') {
                  // workaround: add the emails to the contacts or else they would be invalid and not show up
                  angular.forEach(utils.parseValues(param_val), function(
                    email
                  ) {
                    let listener = $scope.$watch('user.contacts', function() {
                      if (angular.isDefined($scope.user)) {
                        $scope.user.contacts.push({
                          text: email,
                          value: email
                        });
                        listener(); // unregister the watcher, only needed once
                      }
                    });
                  });
                }
              }
            }
          }
          if (params.required_attachments) {
            angular.forEach(
              utils.parseValues(params.required_attachments),
              function(name) {
                $scope.signrequest.required_attachments.push({
                  name: name
                });
              }
            );
          }
          $scope.signrequest.whoChanged = true;
          if (!~$location.path().indexOf('salesforce') && true) {
            $location.search({}).replace(); // remove all params if not salesforce
          }
          if ($scope.oldApiFallback) {
            $location.search('api', 'v1');
          }
        }
      } else {
        // check if user has documents waiting
        if (!$route.current.params.newdoconly) {
          $scope.updateDocuments();
          if ($scope.previousRoute && $scope.previousRoute.$$route) {
            if ($scope.previousRoute.$$route.controller === 'DocCtrl') {
              // we came from the signing page, check if we want to show the share popup
              $scope.maybeShowSocialShare();
            }
          }
        }
      }
    };

    // get the initial documents for this user or form frontend api
    !$scope.isVueHomebox && $scope.urlApiV1();
    // $rootScope.hideHeader = true;
    // $rootScope.hideFooter = true;
    //$scope.updateDocuments($scope.urlApiV1); // also check api v1
  }
]);
