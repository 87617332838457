<template>
  <v-form
    ref="form"
    v-model="formValid"
    class="ph-text-entry"
    @submit.prevent="handleSubmit"
  >
    <v-card-text class="v-card__text__input srtext--text">
      <sr-input
        ref="email"
        v-model="email"
        :label="
          variant === 'sr'
            ? $gettext('Email of the new signer')
            : $gettext('Recipient')
        "
        :placeholder="$gettext('Email')"
        name="email"
        :rules="[emailRule]"
      />
    </v-card-text>
    <v-card-text class="v-card__text__textarea srtext--text">
      <sr-input
        ref="reason"
        v-model="reason"
        name="reason"
        element="textarea"
        :label="
          variant === 'sr'
            ? $gettext('Reason for changing signing responsibility')
            : $gettext('Message')
        "
        :max-height="100"
        scrollbar
        :placeholder="
          $gettext(
            'Optional - Please note that this message will be visible to all parties.'
          )
        "
      />
    </v-card-text>
    <slot />
  </v-form>
</template>
<script>
import { validateEmail } from '@/utils/format';
import SrInput from '@/components/elements/SrInput';
import { getVariant } from '@/utils/variant';
export default {
  components: {
    SrInput
  },
  props: {
    emailValue: { type: String, default: '', required: false },
    reasonValue: { type: String, default: '', required: false }
  },
  data() {
    return {
      email: '',
      reason: '',
      formValid: false
    };
  },
  computed: {
    emailRule() {
      return validateEmail.bind(this);
    },
    variant() {
      return getVariant();
    }
  },
  watch: {
    email() {
      this.$emit('change', 'email', this.email);
    },
    reason() {
      this.$emit('change', 'reason', this.reason);
    },
    formValid() {
      this.$emit('change', 'formValid', this.formValid);
    }
  },
  mounted() {
    this.email = this.emailValue;
    this.reason = this.reasonValue;
    this.$refs.form.resetValidation();
  },
  methods: {
    focusFirst() {
      const { email } = this.$refs;
      if (email) {
        email.focus();
      }
    },
    handleSubmit() {
      this.$emit('submit');
    }
  }
};
</script>
<style scoped>
.signrequest-lib .v-card__text__input {
  padding: 0;
  margin-top: 24px;
}
.signrequest-lib .v-card__text__textarea {
  min-height: unset;
  height: unset;
  padding: 0;
}
.signrequest-lib .v-card__text__input::after,
.signrequest-lib .v-card__text__input::before {
  content: none;
}
</style>
