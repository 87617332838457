import $ from 'jquery';
import angular from 'angular';
import _ from 'lodash';

/**
 * @ngdoc function
 * @name frontendApp.controller:BillingCtrl
 * @description
 * # BillingCtrl
 * Controller of the frontendApp
 */
angular
  .module('frontendApp')

  .controller('SubscribeCtrl', [
    '$scope',
    '$filter',
    'confService',
    'BillingService',
    'StripeCheckout',
    'gettextCatalog',
    '$timeout',
    'UserService',
    'srLoginModal',
    '$location',
    'utils',
    'ENV',
    'messageService',
    'srConfirmSubscriptionModal',
    '$route',
    '$vuex',
    function(
      $scope,
      $filter,
      confService,
      BillingService,
      StripeCheckout,
      gettextCatalog,
      $timeout,
      UserService,
      srLoginModal,
      $location,
      utils,
      ENV,
      messageService,
      srConfirmSubscriptionModal,
      $route,
      $vuex
    ) {
      $scope.confService = confService;
      var ctrl = this;
      ctrl.form = {};

      ctrl.numUsers = '';
      ctrl.numCountries = '';
      ctrl.numLanguages = '';

      ctrl.bundle_price = 0;
      ctrl.price_per_signrequest = 0;

      ctrl.bulk_bundle_price = 0;
      ctrl.bulk_price_per_signrequest = 0;
      ctrl.bulk_bundle = parseInt($route.current.params.bundle_size) || 100;

      ctrl.subscription_details_loaded = false;

      ctrl.onSubscribe = ctrl.onSubscribe || $scope.onSubscribe; // might be injected by a modal using this controller

      ctrl.doUpgrade = false;
      ctrl.upgradePlan = null;
      ctrl.upgradeQuantity = null;
      if ($route.current.params.do_upgrade && !$scope.doUpgrade) {
        //
        ctrl.doUpgrade = true;
        ctrl.upgradePlan = $route.current.params.plan;
        ctrl.upgradeQuantity = Math.max(
          parseFloat($route.current.params.quantity),
          1
        );
      }

      if (ctrl.trialSubscribe) {
        // use the configuration from the component
        ctrl.trialPlan = ~['PRO', 'SME'].indexOf(ctrl.trialPlan)
          ? ctrl.trialPlan
          : 'PRO';
        ctrl.trialInterval = ~['year', 'month'].indexOf(ctrl.trialInterval)
          ? ctrl.trialInterval
          : 'month';
        ctrl.trialDays = ctrl.trialDays ? ctrl.trialDays : 14;
      } else {
        // use the configuration from the route when on the /billing page
        ctrl.trialPlan = $location.search().plan;
        ctrl.trialInterval = $location.search().interval;
        ctrl.trialDays = 14;

        // clean the params
        ctrl.trialPlan = ~['PRO', 'SME'].indexOf(ctrl.trialPlan)
          ? ctrl.trialPlan
          : null;
        ctrl.trialInterval = ~['year', 'month'].indexOf(ctrl.trialInterval)
          ? ctrl.trialInterval
          : null;
      }

      ctrl.pricing = $scope.pricing || null;
      if (!ctrl.pricing) {
        BillingService.getPlanOptions().then(function(resp) {
          ctrl.pricing = resp.data.pricing;
        });
      }

      ctrl.getPricing = function(subType, interval) {
        if (!ctrl.user || !ctrl.user.logged_in) {
          return '';
        }

        if (!interval) {
          interval = ctrl.subscription_details.interval;
        }
        if (
          ctrl.subscription_details &&
          ctrl.subscription_details.currency &&
          ctrl.pricing
        ) {
          return ctrl.pricing[ctrl.subscription_details.currency.code][subType][
            interval
          ];
        }
        return '';
      };

      ctrl.getIntervalDisplay = function(interval) {
        if (!interval) {
          interval = ctrl.subscription_details.interval;
        }
        if (ctrl.subscription_details) {
          return (
            {
              year: gettextCatalog.getString('year'),
              month: gettextCatalog.getString('month')
            }[interval] || ''
          );
        }
        return '';
      };

      ctrl.getPricingDisplay = function(subType, amount, interval) {
        if (ctrl.subscription_details) {
          subType = subType || ctrl.subscription_details.plan_type;
          amount = amount || ctrl.subscription_details.quantity;
          interval = interval || ctrl.subscription_details.interval;
        }
        if (subType && amount) {
          if (
            ctrl.subscription_details &&
            ctrl.subscription_details.currency &&
            ctrl.pricing
          ) {
            var pricing =
              ctrl.subscription_details.currency.symbol +
              ' ' +
              ctrl.getPricing(subType, interval) * amount;

            if (interval === 'month') {
              pricing = pricing + ' / ' + ctrl.getIntervalDisplay(interval);
            } else {
              pricing = pricing + ' / month (payed yearly)';
            }

            return pricing;
          }
        }
        return '';
      };

      ctrl.getPlanFeatures = function() {
        if (ctrl.trialSubscribe) {
          return [
            gettextCatalog.getString('Branded account, logo and color'),
            gettextCatalog.getString('Custom templates'),
            gettextCatalog.getString('Store and manage documents'),
            gettextCatalog
              .getString('After 14 days just')
              .replace('14', ctrl.trialDays) +
              ' ' +
              ctrl.getPricingDisplay(),
            gettextCatalog.getString('Cancel online at any time')
          ];
        }
        return [];
      };
      if (ctrl.trialSubscribe) {
        // in modal
        if (ctrl.trialPlan === 'SME') {
          ctrl.availableSubscriptions = [
            {
              name: 'Business',
              description: 'Business',
              type: 'SME',
              text: gettextCatalog.getString(
                'Unlimited teams, templates and premium features'
              ),
              subtext: gettextCatalog.getString('After 14 days'),
              // 'per month, incl. 4 users' is changed to 5 in translations
              footer: gettextCatalog.getString('per month, incl. 4 users'),
              chooseAmount: false,
              amount: 5
            }
          ];
        } else {
          ctrl.availableSubscriptions = [
            {
              name: 'PRO',
              description: 'Professional',
              type: 'PRO',
              text: gettextCatalog.getString('Get more advanced features'),
              subtext: gettextCatalog.getString('After 14 days'),
              footer: '',
              chooseAmount: false,
              amount: 1
            }
          ];
        }
      } else {
        // billing page
        ctrl.availableSubscriptions = [
          {
            name: 'START',
            type: 'FREE',
            description: 'Free',
            text: gettextCatalog.getString(
              'Get started and experience the benefits'
            ),
            footer: null,
            chooseAmount: false,
            chooseBundle: false,
            amount: 1,
            api: false,
            widthClass: 'desk-one-third'
          },
          {
            name: 'PRO',
            type: 'PRO',
            description: 'Professional',
            text: gettextCatalog.getString('Get more advanced features'),
            footer: gettextCatalog.getString('per month, per user'),
            chooseAmount: true,
            chooseBundle: false,
            amount: 1,
            api: false,
            widthClass: 'desk-one-third'
          },
          {
            name: 'SME',
            type: 'SME',
            description: 'Business',
            text: gettextCatalog.getString(
              'Unlimited teams, templates and premium features'
            ),
            footer: gettextCatalog.getString('per month, per user'),
            chooseAmount: true,
            chooseBundle: false,
            amount: 1,
            api: false,
            widthClass: 'desk-one-third'
          }
        ];
      }

      ctrl.waitForSub = function(callback) {
        if (!ctrl.subscription_details) {
          // wait for subscription details to be loaded
          $timeout(function() {
            ctrl.waitForSub(callback);
          }, 20);
        } else {
          callback();
        }
      };

      ctrl.trackBillingEvent = function(event_name) {
        var tracking_category = 'Billing Details';
        ctrl.waitForSub(function() {
          UserService.trackEvent(
            tracking_category + ' ' + event_name,
            tracking_category,
            {
              trial_subscribe: $scope.trialSubscribe,
              trial_subscribe_version: ENV.trial_subscribe_version,
              plan: ctrl.subscription_details.plan_type,
              plan_interval: ctrl.subscription_details.interval,
              plan_quantity: ctrl.subscription_details.quantity,
              current_plan: ctrl.subscription_details.current_plan,
              current_plan_interval: ctrl.subscription_details.current_interval,
              current_plan_quantity: ctrl.subscription_details.current_quantity,
              currency: ctrl.billing_details.currency,
              paid_usage: UserService.userHasPaidUsage(),
              has_payment_method: ctrl.hasValidPaymentMethod()
            }
          );
        });
      };

      ctrl.selectSubscription = function(subType) {
        ctrl.waitForSub(function() {
          ctrl.subscription_details.plan_type = subType;
          ctrl.onPlanChange();
          if (
            (subType !== 'SME' && subType !== 'API_BUNDLE') ||
            ctrl.trialSubscribe
          ) {
            ctrl.scrollToId('billingForm');
          }
          if (ctrl.planIsUpdated()) {
            ctrl.trackBillingEvent('Changed Plan ' + subType);
          }
        });
      };

      ctrl.setPlanInterval = function(interval) {
        ctrl.waitForSub(function() {
          if (!ctrl.canChangeInterval()) {
            return;
          }
          ctrl.subscription_details.interval = interval;
          ctrl.onPlanChange();
          if (ctrl.planIsUpdated()) {
            ctrl.trackBillingEvent('Changed Interval ' + interval);
          }
        });
      };

      ctrl.canChangeInterval = function(to_interval) {
        if (ctrl.subscription_details && ctrl.subscription_details.interval) {
          if (
            ctrl.subscription_details.has_valid_stripe_subscription &&
            ctrl.subscription_details.current_interval === 'year' &&
            ctrl.subscription_details.has_valid_trial === false
          ) {
            return to_interval === 'year';
          }
          return true;
        }
        return false;
      };

      ctrl.showYearUpgrade = function() {
        if (ctrl.subscription_details) {
          return ctrl.subscription_details.show_year_upgrade;
        }
        return false;
      };

      ctrl.doYearUpgrade = function() {
        ctrl.trackBillingEvent('Clicked Year Upgrade');
        ctrl.setPlanInterval('year');
        ctrl.openConfirmSubscriptionModal();
      };

      ctrl.doPlanQuantityUpgrade = function() {
        ctrl.subscription_details.quantity = ctrl.upgradeQuantity;
        ctrl.subscription_details.plan_type = ctrl.upgradePlan;
        ctrl.onPlanChange();
        ctrl.trackBillingEvent('Clicked Quantity Upgrade');
        ctrl.openConfirmSubscriptionModal();
      };

      ctrl.doMemberUpgrade = function(quantity, plan) {
        // we're coming from the invite member modal
        ctrl.waitForSub(function() {
          ctrl.subscription_details.quantity = quantity;
          ctrl.subscription_details.plan_type = plan;
          ctrl.onPlanChange();
          ctrl.trackBillingEvent('Member Upgrade Confirm Opened');
          ctrl.openConfirmSubscriptionModal();
        });
      };

      ctrl.getYearUpgradeBenefitDisplay = function() {
        if (
          ctrl.subscription_details &&
          ctrl.subscription_details.year_upgrade_benefit_display
        ) {
          return ctrl.subscription_details.year_upgrade_benefit_display;
        }
        return '';
      };

      ctrl.openConfirmSubscriptionModal = function() {
        srConfirmSubscriptionModal.activate(
          {},
          {
            pricing: ctrl.pricing,
            doUpgrade: false,
            subscription_details: ctrl.subscription_details,
            billing_details: ctrl.billing_details,
            billing_details_loaded: ctrl.billing_details_loaded,
            billing_details_initial_country:
              ctrl.billing_details_initial_country,
            country_options: ctrl.country_options,
            country_exception_options: ctrl.country_exception_options,
            country_exceptions: ctrl.country_exceptions,
            onSubscribe: function(conf) {
              // conf holds: {user: response.data.user, subscription_details: ctrl.subscription_details}
              srConfirmSubscriptionModal.deactivate();
              ctrl.subscription_details = conf.subscription_details;
              ctrl.user = conf.user;
              ctrl.scrollToId('subscription-overview');
              $location.search({}).replace();
            }
          }
        );
      };

      ctrl.scrollToId = function(id) {
        var elToScroll = ctrl.trialSubscribe ? '.mfp-wrap' : 'html,body';
        var scrollToEl = $('#' + id);
        if (scrollToEl) {
          $timeout(function() {
            var pos = scrollToEl.position();
            if (pos) {
              $(elToScroll).animate(
                {
                  scrollTop: pos.top - 20
                },
                500,
                'easeInOutQuad',
                function() {}
              );
            }
          });
        }
      };

      ctrl.buyBulkBundle = function() {
        BillingService.buyBundle(ctrl.bulk_bundle, 'bulk_send').then(function(
          resp
        ) {
          UserService.refreshUser();
          $route.reload();
        });
      };

      const handleSCAfromResp = async function handleSCAfromResp(resp) {
        // Handles pending setup / payment intents we potentially get from the backend

        const intent =
          resp.data.payment_intent_client_secret ||
          resp.data.setup_intent_client_secret;
        if (!intent) {
          return;
        }

        const stripe = window.Stripe(ENV.stripe_publishable_key);
        const stripeHandler = resp.data.payment_intent_client_secret
          ? stripe.handleCardPayment
          : stripe.handleCardSetup;

        ctrl.trackBillingEvent('SCA started');
        const { error } = await stripeHandler(intent);

        if (error) {
          ctrl.trackBillingEvent('SCA error');
          messageService.add('error', error.message);
        } else {
          ctrl.trackBillingEvent('SCA success');
        }
      };

      UserService.waitForUser().then(function(user) {
        ctrl.user = user;
        ctrl.loading_subscription = true;
        ctrl.loading_stripe = true;
        ctrl.loading_quote = !!ctrl.loading_quote; // might be loadin

        if (!ctrl.user.logged_in) {
          let next = $scope.next;
          if (!next) {
            next = '/#' + $location.url(); // redirect to the current route
          }
          $vuex.dispatch('modals/showLoginModal', { next: next });
        } else {
          if ($scope.billing_details) {
            // injected into the scope by the modal
            ctrl.subscription_details = $scope.subscription_details;
            ctrl.billing_details = $scope.billing_details;
            ctrl.billing_details_loaded = $scope.billing_details_loaded;
            ctrl.billing_details_initial_country =
              $scope.billing_details_initial_country;
            ctrl.country_options = $scope.country_options;
            ctrl.country_exception_options = $scope.country_exception_options;
            ctrl.country_exceptions = $scope.country_exceptions;
            ctrl.loading_subscription = false;
            ctrl.loading_stripe = false;
          } else {
            ctrl.billing_details = {};
            ctrl.billing_details_loaded = false;
            ctrl.billing_details_initial_country = null; // loaded initially from the server
            ctrl.country_options = [];
            ctrl.country_exception_options = [];
            ctrl.country_exceptions = null;

            BillingService.getBillingDetails().then(function(resp) {
              ctrl.getSubscriptionDetails(); // we need to wait for the billing details to be returned
              ctrl.billing_details = resp.data.details;
              ctrl.billing_details_initial_country = resp.data.details.country;
              ctrl.billing_details_loaded = true;
              ctrl.updateCountryExceptions();
            });

            BillingService.getCountryOptions().then(function(resp) {
              resp.data.countries.forEach(function(option, index) {
                // else we do not get the intended order in selectize
                option.$order = index;
              });
              ctrl.country_options = resp.data.countries;
              ctrl.country_exceptions = resp.data.exceptions;

              ctrl.updateCountryExceptions();
            });
          }

          ctrl.updateCountryExceptions = function(country_code) {
            if (!country_code && ctrl.billing_details) {
              country_code = ctrl.billing_details.country;
            }
            // update the exception options when we have country select that has some
            if (angular.isObject(ctrl.country_exceptions)) {
              var opts = ctrl.country_exceptions[country_code] || [];
              if (!opts.length) {
                ctrl.billing_details.exception_name = '';
              }
              // selectize needs time to update first
              $timeout(function() {
                ctrl.country_exception_options = opts;
              }, 10);
            }
          };

          ctrl.showCountrySelect = function() {
            // for the trial subscribe we only show the country if we don't have one
            if (!ctrl.trialSubscribe) {
              return true;
            }
            if (
              ctrl.billing_details_loaded &&
              !ctrl.billing_details_initial_country
            ) {
              return true;
            }
            // we have one from the server and are in trial subscribe, don't show
            return false;
          };

          ctrl.creditcard_has_been_shown = false; // set to true when the stripe popup is closed
          ctrl.showPaymentMethods = function() {
            if (!ctrl.trialSubscribe) {
              return true;
            }
            if (ctrl.creditcard_has_been_shown) {
              // if so we want to show all other options (SEPA) and add / change the creditcard
              return true;
            }
          };

          $scope.$watch(
            'ctrl.billing_details.country',
            ctrl.updateCountryExceptions
          );

          ctrl.updateBillingDetails = function(background) {
            if (ctrl.loading_subscription) {
              return;
            }
            ctrl.loading_subscription = true;
            var update_sub = false;
            if (!background && ctrl.planIsUpdated()) {
              background = true;
              update_sub = true;
            }

            BillingService.setBillingDetails(
              ctrl.billing_details,
              background
            ).then(function(resp) {
              ctrl.billing_details = resp.data.details;
              if (update_sub) {
                ctrl.doSubUpdate();
              } else {
                ctrl.onPlanChange();
                ctrl.loading_subscription = false;
              }
            });
          };

          ctrl.saveOrSubscribe = function() {
            if (ctrl.planIsUpdated()) {
              // no subscribe, just a save...
              ctrl.trackBillingEvent('Saved');
            }
            ctrl.updateBillingDetails();
          };

          ctrl.checkFormIsValid = function() {
            if (
              ctrl.form.accountBillingForm &&
              !ctrl.form.accountBillingForm.$valid
            ) {
              angular.forEach(ctrl.form.accountBillingForm.$error, function(
                field
              ) {
                angular.forEach(field, function(errorField) {
                  errorField.$setTouched();
                });
              });
              ctrl.scrollToId('billingForm');
              return false;
            }
            return true;
          };

          ctrl.minQuantity = function() {
            if (
              ctrl.subscription_details &&
              ctrl.subscription_details.plan_type
            ) {
              if (
                ctrl.subscription_details.has_valid_stripe_subscription &&
                ctrl.subscription_details.current_quantity
              ) {
                return ctrl.subscription_details.current_quantity;
              }
            }
            return 1;
          };

          ctrl.onChangeQuantity = function(event) {
            ctrl.onPlanChange();
            if (ctrl.planIsUpdated()) {
              ctrl.trackBillingEvent('Changed Quantity');
            }
          };

          ctrl.onChangeBundle = function(event) {
            ctrl.onPlanChange();
            ctrl.setBundlePrice(ctrl.subscription_details.bundle);

            if (ctrl.planIsUpdated()) {
              ctrl.trackBillingEvent('Changed Bundle');
            }
          };

          ctrl.setBundlePrice = function(amount) {
            BillingService.getBundlePrice(amount, 'api').then(function(resp) {
              ctrl.bundle_price = resp['data']['total_price'];
              ctrl.price_per_signrequest =
                resp['data']['price_in_cent_per_signrequest'] / 100;
            });
          };

          ctrl.onChangeBulkBundle = function(event) {
            ctrl.setBulkBundlePrice(ctrl.bulk_bundle);
          };

          ctrl.setBulkBundlePrice = function(amount) {
            BillingService.getBundlePrice(amount, 'bulk_send').then(function(
              resp
            ) {
              ctrl.bulk_bundle_price = resp['data']['total_price'];
              ctrl.bulk_price_per_signrequest =
                resp['data']['price_in_cent_per_signrequest'] / 100;
            });
          };

          ctrl.onChangedPhone = function() {
            if (ctrl.billing_details.phone) {
              ctrl.updateBillingDetails(true);
              ctrl.trackBillingEvent('Changed Phone');
            }
          };

          ctrl.onPlanChange = function() {
            ctrl.subscription_details.quantity = Math.max(
              ctrl.minQuantity(),
              ctrl.subscription_details.quantity || 1
            );
            ctrl.setPlanTypeDisplay();
          };

          ctrl.loading_stripe = false;
          ctrl.subscription_details = $scope.subscription_details || {
            current_plan: '',
            current_quantity: null,
            current_interval: '',
            current_plan_type_display: ''
          };

          ctrl.getSubscriptionDetails = function() {
            ctrl.loading_stripe = true;
            ctrl.loading_subscription = true;
            BillingService.getSubscriptionDetails().then(function(resp) {
              ctrl.setSubDetailsFromResp(resp);
              ctrl.loading_stripe = false;
              ctrl.loading_subscription = false;
              if (ctrl.doUpgrade && ctrl.upgradePlan && ctrl.upgradeQuantity) {
                ctrl.doPlanQuantityUpgrade();
              }

              ctrl.subscription_details_loaded = true;
            });
          };

          ctrl.setSubDetailsFromResp = function(resp, keep_selected_plan) {
            var current_plan_type_display = angular.copy(
              ctrl.subscription_details.plan_type_display
            );
            var current_selected_plan = angular.copy(
              ctrl.subscription_details.plan_type
            );
            var current_chosen_quantity = angular.copy(
              ctrl.subscription_details.quantity
            );
            var current_interval = angular.copy(
              ctrl.subscription_details.interval
            );

            ctrl.subscription_details = resp.data.details;
            if (!current_plan_type_display) {
              current_plan_type_display = angular.copy(
                ctrl.subscription_details.plan_type_display
              );
            }
            ctrl.subscription_details.current_plan =
              ctrl.subscription_details.plan_type;
            ctrl.subscription_details.current_quantity =
              ctrl.subscription_details.quantity;
            ctrl.subscription_details.current_interval =
              ctrl.subscription_details.interval;
            ctrl.subscription_details.current_plan_type_display = current_plan_type_display;

            ctrl.setBulkBundlePrice(ctrl.bulk_bundle);

            var initialBundleSize = $route.current.params.bundle_size
              ? parseInt($route.current.params.bundle_size)
              : 1000;
            ctrl.subscription_details.bundle = initialBundleSize;
            ctrl.setBundlePrice(initialBundleSize);

            if (keep_selected_plan) {
              // if we are updating a card before subscribing we don't want to 'deselect' the plan and quantity
              // to the server value
              ctrl.subscription_details.plan_type = current_selected_plan;
              ctrl.subscription_details.plan_type_display = current_plan_type_display;
              ctrl.subscription_details.quantity = current_chosen_quantity;
              ctrl.subscription_details.interval = current_interval;
            }

            // only used when we want to set a new subscription from the route
            if (!keep_selected_plan) {
              if ($route.current.params.plan_type)
                ctrl.subscription_details.plan_type =
                  $route.current.params.plan_type;

              if (ctrl.trialPlan) {
                ctrl.subscription_details.plan_type = ctrl.trialPlan;
              }

              if (ctrl.trialInterval && ctrl.canChangeInterval()) {
                ctrl.subscription_details.interval = ctrl.trialInterval;
              }

              // unset so we update from the server next time
              ctrl.trialPlan = null;
              ctrl.trialInterval = null;
              ctrl.onPlanChange();
            }
          };

          ctrl.setPlanTypeDisplay = function() {
            if (ctrl.planIsUpdated()) {
              ctrl.subscription_details.loading_quote = true;
              // they are subscribing and we change the plan display
              BillingService.getPlanOptions(
                ctrl.subscription_details.plan_type,
                ctrl.subscription_details.quantity,
                ctrl.subscription_details.interval
              ).then(function(resp) {
                if (resp.data.quoted_plan_display) {
                  ctrl.subscription_details.plan_type_display =
                    resp.data.quoted_plan_display;
                }
                ctrl.subscription_details.loading_quote = false;
              });
            } else {
              // we don't need to do anything as we already have the correct display in current_plan_type_display
              ctrl.subscription_details.plan_type_display =
                ctrl.subscription_details.current_plan_type_display;
            }
          };

          ctrl.planIsUpdated = function() {
            if (!ctrl.subscription_details) {
              return false;
            }
            if (!ctrl.subscription_details.current_plan) {
              return false;
            }
            if (!ctrl.subscription_details.current_quantity) {
              return false;
            }
            if (!ctrl.subscription_details.current_interval) {
              return false;
            }
            var plan_changed =
              ctrl.subscription_details.plan_type !==
              ctrl.subscription_details.current_plan;
            var quantity_changed =
              ctrl.subscription_details.quantity !==
              ctrl.subscription_details.current_quantity;
            var interval_changed =
              ctrl.subscription_details.interval !==
              ctrl.subscription_details.current_interval;
            return (
              plan_changed ||
              quantity_changed ||
              interval_changed ||
              ctrl.subscription_details.bundle
            );
          };

          ctrl.getSubscribeButtonDisplay = function() {
            if (ctrl.planIsUpdated()) {
              if (ctrl.trialSubscribe) {
                return gettextCatalog.getString('START FREE TRIAL');
              } else {
                return gettextCatalog.getString('Subscribe');
              }
            } else {
              if (ctrl.add_bank_account) {
                return gettextCatalog.getString('Approve');
              } else {
                return gettextCatalog.getString('Save');
              }
            }
          };

          ctrl.showQuantity = function() {
            if (!ctrl.subscription_details.plan_type) {
              return false;
            }
            return (
              ctrl.subscription_details.plan_type === 'SME' ||
              ctrl.subscription_details.plan_type === 'ENT'
            );
          };

          ctrl.hasValidPaymentMethod = function() {
            if (!ctrl.subscription_details.card_details) {
              return false;
            }
            return (
              ctrl.subscription_details.card_details.card_is_valid ||
              ctrl.subscription_details.bank_account_iban_last_4
            );
          };

          ctrl.doSubUpdate = function(do_not_check_card) {
            if (!do_not_check_card) {
              if (
                !ctrl.hasValidPaymentMethod() &&
                ctrl.subscription_details.plan_type !== 'FREE' &&
                ctrl.subscription_details.plan_type !== 'TRIAL'
              ) {
                // first update the card
                ctrl.doCardUpdate(true);
                return;
              }
            }
            if (ctrl.loading_stripe) {
              return;
            }
            if (!ctrl.checkFormIsValid()) {
              ctrl.loading_subscription = false;
              return;
            }
            ctrl.loading_subscription = true;
            BillingService.setSubscriptionDetails(
              ctrl.subscription_details.plan_type,
              ctrl.subscription_details.quantity,
              ctrl.subscription_details.interval,
              ctrl.trialDays,
              ctrl.subscription_details.bundle
            ).then(async function(resp) {
              // SCA might be needed for this payment source
              await handleSCAfromResp(resp);

              ctrl.setSubDetailsFromResp(resp);
              // update user for new permissions
              UserService.refreshUser().then(function(user) {
                ctrl.loading_subscription = false;
                ctrl.onSubscribe({
                  user: user,
                  subscription_details: ctrl.subscription_details
                }); // let component user know we've subscribed
                ctrl.trackBillingEvent('Updated Subscription');
              });
            });
          };

          ctrl.new_bank_account = {
            bank_account_holder_name: '',
            bank_account_number: '',
            address_line1: '',
            address_line2: '', //optional
            address_city: '',
            address_state: '',
            address_zip: ''
          };
          ctrl.iban_not_valid = false;
          ctrl.add_bank_account = false;

          ctrl.addBankAccount = function() {
            ctrl.iban_not_valid = false;
            if (ctrl.add_bank_account) {
              ctrl.add_bank_account = false;
              ctrl.trackBillingEvent('Closed Bank');
              return;
            }
            ctrl.scrollToId('payment-method');
            // prefetch library so we have it available in doBankAccountUpdate
            utils.requireLibrary('https://js.stripe.com/v2/', 'Stripe');

            ctrl.new_bank_account.bank_account_holder_name =
              ctrl.subscription_details.bank_account_holder_name;
            if (!ctrl.new_bank_account.bank_account_holder_name) {
              ctrl.new_bank_account.bank_account_holder_name = [
                ctrl.billing_details.first_name,
                ctrl.billing_details.last_name
              ]
                .join(' ')
                .replace(/^\s+|\s+$/g, '');
            }
            if (!ctrl.new_bank_account.address_line1) {
              ctrl.new_bank_account.address_line1 =
                ctrl.billing_details.address;
            }
            if (!ctrl.new_bank_account.address_city) {
              ctrl.new_bank_account.address_city = ctrl.billing_details.city;
            }
            if (!ctrl.new_bank_account.address_zip) {
              ctrl.new_bank_account.address_zip =
                ctrl.billing_details.postal_code;
            }
            ctrl.add_bank_account = true;
            ctrl.trackBillingEvent('Opened Bank');
          };

          ctrl.setIbanInvalid = function() {
            ctrl.form.accountBillingForm.iban.$setValidity('ngIban', false);
            ctrl.form.accountBillingForm.iban.$setDirty();
            ctrl.iban_not_valid = true;
            ctrl.scrollToId('payment-method');
          };

          ctrl.doBankAccountUpdate = function() {
            if (!ctrl.add_bank_account) {
              ctrl.addBankAccount();
              return;
            }
            if (!ctrl.new_bank_account.bank_account_number) {
              ctrl.setIbanInvalid();
              return;
            }

            ctrl.loading_stripe = true;
            utils
              .requireLibrary('https://js.stripe.com/v2/', 'Stripe')
              .then(function(Stripe) {
                Stripe.setPublishableKey(ENV.stripe_publishable_key);
                Stripe.bankAccount.createToken(
                  {
                    account_number: ctrl.new_bank_account.bank_account_number,
                    currency: ctrl.billing_details.currency.toLowerCase(),
                    account_holder_name:
                      ctrl.new_bank_account.bank_account_holder_name,
                    address_line1: ctrl.new_bank_account.address_line1,
                    address_line2: ctrl.new_bank_account.address_line2, //optional
                    address_city: ctrl.new_bank_account.address_city,
                    address_state: ctrl.new_bank_account.address_state,
                    address_zip: ctrl.new_bank_account.address_zip,
                    usage: 'source'
                  },
                  function(status, token) {
                    if (status === 200) {
                      BillingService.updateBankAccount(
                        token.id,
                        ctrl.new_bank_account,
                        ctrl.planIsUpdated()
                      ).then(
                        function(resp) {
                          if (resp.data.details) {
                            ctrl.setSubDetailsFromResp(resp, true);
                            ctrl.add_bank_account = false;
                            ctrl.loading_stripe = false;
                            if (ctrl.planIsUpdated()) {
                              ctrl.doSubUpdate(true);
                            }
                            ctrl.trackBillingEvent('Added Bank');
                          } else {
                            ctrl.loading_stripe = false;
                          }
                        },
                        function() {
                          ctrl.loading_stripe = false;
                          messageService.genericError();
                        }
                      );
                    } else {
                      $timeout(function() {
                        ctrl.loading_stripe = false;
                        messageService.handleError(token.error);
                      });
                    }
                  }
                );
              });
          };

          // You should configure a handler when the view is loaded,
          // just as you would if you were using checkout.js directly.
          var handler;
          ctrl.loading_stripe = true;
          StripeCheckout.load().then(function() {
            handler = StripeCheckout.configure({
              name: 'SignRequest',
              image: 'https://signrequest.com/images/favicons/favicon.png'
            });
            ctrl.loading_stripe = false;
          });

          ctrl.doCardUpdate = function(do_sub_update) {
            do_sub_update = do_sub_update || false;
            var button_text;
            if (do_sub_update) {
              button_text = gettextCatalog.getString('Subscribe');
            } else {
              if (ctrl.subscription_details.card_details.card_is_valid) {
                button_text = gettextCatalog.getString('Change');
              } else {
                button_text = gettextCatalog.getString('Add');
              }
            }

            var ok_silent = do_sub_update;
            if (ctrl.loading_stripe) {
              return;
            }
            ctrl.loading_stripe = true;
            var options = {
              panelLabel: button_text,
              locale: 'auto',
              email: ctrl.user.email
            };
            // The default handler API is enhanced by having open()
            // return a promise. This promise can be used in lieu of or
            // in addition to the token callback (or you can just ignore
            // it if you like the default API).
            //
            // The rejection callback doesn't work in IE6-7.
            handler.open(options).then(
              function(result) {
                BillingService.updateCard(result[0].id, ok_silent).then(
                  async function(resp) {
                    // SCA might be needed for this payment source
                    await handleSCAfromResp(resp);
                    ctrl.loading_stripe = false;
                    if (do_sub_update) {
                      if (resp.data.details && resp.data.details.card_details) {
                        // only update the card details from the server
                        ctrl.subscription_details.card_details =
                          resp.data.details.card_details;
                      }
                      ctrl.doSubUpdate(true);
                    } else {
                      if (resp.data.details) {
                        ctrl.setSubDetailsFromResp(resp, true);
                      }
                    }
                    ctrl.trackBillingEvent('Added Credit Card');
                  }
                );
              },
              function() {
                ctrl.loading_stripe = false;
                ctrl.loading_subscription = false;
                ctrl.creditcard_has_been_shown = true;
                ctrl.trackBillingEvent('Closed Credit Card');
              }
            );
            ctrl.trackBillingEvent('Opened Credit Card');
          };
        }
      });
    }
  ])

  /**
   * Shows the subscription overview
   <subscription-overview
   subscription-details="ctrl.subscription_details"
   billing-details="ctrl.billing_details"
   plan-is-updated="ctrl.planIsUpdated"
   ></subscription-overview>
   */
  .component('subscriptionOverview', {
    template: require('../../partials/account/subscription-overview.html'),
    controllerAs: 'ctrl',
    bindings: {
      subscriptionDetails: '<',
      billingDetails: '<',
      planIsUpdated: '<'
    }
  })

  /**
   * Shows the subscribe form
   * Optionally trial-subscribe can be set to true in order to only show the PRO plan and have them subscribe
   * with trial days
   * If trial subscribe is true we assume we are in a modal.
   * <subscribe-form user="user" trial-subscribe="true"></loading>
   */
  .component('subscribeForm', {
    template: require('../../partials/subscribe.html'),
    controllerAs: 'ctrl',
    bindings: {
      trialSubscribe: '<',
      trialPlan: '<',
      trialDays: '<',
      trialInterval: '<',
      planDisplayName: '<',
      user: '=',
      onSubscribe: '&'
    },
    controller: 'SubscribeCtrl'
  })

  /**
   * Shows the bundle form
   * Optionally trial-subscribe can be set to true in order to only show the PRO plan and have them subscribe
   * with trial days
   * If trial subscribe is true we assume we are in a modal.
   * <bundle-form user="user" trial-subscribe="true"></loading>
   */
  .component('bundleForm', {
    template: require('../../partials/bundle.html'),
    controllerAs: 'ctrl',
    bindings: {
      trialSubscribe: '<',
      trialPlan: '<',
      trialInterval: '<',
      user: '=',
      onSubscribe: '&'
    },
    controller: 'SubscribeCtrl'
  });
