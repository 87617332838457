<template>
  <v-card class="dashed" :class="{ dragging }" flat>
    <v-card-text>
      <div class="choice choice-drag">
        <h4>
          <span v-show="!dragging" v-translate>
            Drag and drop your files here
          </span>
          <span v-show="dragging" v-translate>Release!</span>
        </h4>
      </div>

      <div class="choice choice-or">
        <h4>
          <span v-translate>or</span>
        </h4>
      </div>

      <div class="choice choice-button">
        <sr-file-select @file="$listeners.file">
          <sr-button sr-style="fancy" round>
            <translate>Upload file</translate>
          </sr-button>
        </sr-file-select>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import SrButton from '../elements/SrButton';
import SrFileSelect from '../elements/SrFileSelect';

export default {
  components: { SrButton, SrFileSelect },
  props: {
    dragging: { type: Boolean, default: false }
  },
  methods: {
    focus() {
      this.$el.querySelector('button').focus();
    }
  }
};
</script>

<style scoped>
.v-card.dashed {
  display: block;
  border: 1px dashed #a0a0a0;
  transition: 0s;
  border-radius: 4px;
  text-align: center;
}

.choice {
  margin: 1rem;
}

.choice h4 {
  margin: 0px;
}

.choice.choice-or h4 {
  position: relative;
  z-index: 1;

  padding: 0 5%;
  background-color: white;
  text-align: center;
  text-transform: uppercase;
  color: #111111;
  opacity: 0.5;
}

.choice.choice-button /deep/ button {
  font-size: 18px;
  height: 3em;
  min-width: calc(50% - 12px);
}

.dragging .choice {
  opacity: 0.5;
}

.dragging .choice.choice-drag {
  opacity: 1;
}
</style>
