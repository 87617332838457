<template>
  <div>
    <box-info-block :title="$gettext('Documents')">
      <div v-if="hasFile" class="file-info">
        <file-icon class="file-info--icon" :extension="fileExtension" />
        <span>{{ selectedFileInfo.fileName }}</span>
      </div>
    </box-info-block>

    <box-info-block :title="$gettext('Recipients')" class="bblack--text">
      <box-contact-list
        v-model="signers"
        :with-order="showOrder"
        :is-template="template"
        @select="$emit('showDetails', $event)"
      />
      <box-checkbox
        v-show="signingContactsCount > 1"
        v-model="showOrder"
        name="show-order"
        :label="$gettext('Specify Signing Order')"
      />
    </box-info-block>

    <box-info-block :title="$gettext('Signatures & Fields')">
      <h3 v-translate>Click a field to drop onto the document…</h3>
      <div
        v-for="type of ['s', 'd', 't', 'c']"
        :key="type"
        class="placeholder-wrap"
        draggable="true"
        :data-type="type"
        @dragstart="handleDrag"
      >
        <sr-placeholder :type="type" :signer-index="-1" />
      </div>
    </box-info-block>

    <box-info-block :title="$gettext('Email Notifications')">
      <sr-input
        v-model="subject"
        name="subject"
        :label="$gettext('Subject')"
        :placeholder="$gettext('Optional Email Subject')"
      />
      <sr-input
        v-model="message"
        name="message"
        element="textarea"
        :label="$gettext('Message')"
        :placeholder="$gettext('Optional Email Message')"
      />
      <box-checkbox
        v-model="send_reminders"
        class="box-form--reminders"
        name="send_reminders"
        :label="$gettext('Send automatic reminders')"
      />
    </box-info-block>

    <box-info-block
      :title="$gettext('Options')"
      panel
      @open="$emit('showSettings')"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import makeFields from '@/utils/makeFields';
import { patch, updateAtIdx } from '@/utils/fp';
import { mapModel, mapModelFields } from '@/utils/vuexExtra';

import BoxInfoBlock from './BoxInfoBlock';
import BoxContactList from './BoxContactList';
import BoxCheckbox from '@/components/elements/BoxCheckbox';
import SrInput from '@/components/elements/SrInput';
import SrPlaceholder, {
  DEFAULT_SIZE
} from '@/components/elements/SrPlaceholder';

export default {
  components: {
    BoxInfoBlock,
    BoxCheckbox,
    BoxContactList,
    SrPlaceholder,
    SrInput
  },
  props: {
    template: { type: Boolean, default: false },
    value: { type: Object, required: true }
  },
  data() {
    return { showOrder: false };
  },
  computed: {
    ...mapGetters('signrequest', [
      'selectedFileInfo',
      'hasFile',
      'signingContactsCount'
    ]),
    ...mapModel('signrequest', 'signrequestForm', 'updateSignrequestForm'),
    ...mapModelFields('signrequestForm', ['subject', 'send_reminders']),
    ...makeFields('message', 'signers'),
    fileExtension() {
      const [ext] = this.selectedFileInfo.fileName
        .toLowerCase()
        .split('.')
        .slice(-1);
      return ext || 'pdf';
    }
  },
  methods: {
    handleDrag(event) {
      const { type } = event.target.dataset;
      const { layerX, layerY } = event;
      const { width, height } = DEFAULT_SIZE[type];
      const data = { type, layerX, layerY, width, height };
      event.dataTransfer.setData('application/json', JSON.stringify(data));
    }
  }
};
</script>
<style scoped>
.file-info {
  display: flex;
  align-items: center;
  height: 44px;
  padding: 8px;
  width: 104%;
  margin-left: -3%;
  border: solid 1px transparent;
}
.file-info /deep/ svg {
  margin: 0 -4px;
  width: 24px;
  height: 24px;
}
.file-info span {
  display: flex;
  align-items: center;
  margin: 0 8px;
}
.file-info .file-info--icon {
  display: flex;
}
.file-info:hover {
  border-radius: 6px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #e8e8e8;
  background-color: #fff;
  cursor: pointer;
}
.file-info span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
h3 {
  color: #767676;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: 0.3px;
  margin-top: 30px;
}
.box-checkbox {
  margin-top: 12px;
}
.placeholder-wrap {
  position: relative;
  height: 45px;
  margin-top: 11px;
}
.sr-field {
  margin-bottom: 16px;
}
.box-form--reminders {
  margin-top: 8px;
}
</style>
