<template>
  <v-dialog
    ref="modal"
    v-model="showModal"
    :content-class="
      [`restrict-width restrict-width-${currentTab}`, contentClass].join(' ')
    "
  >
    <sr-drop-capture
      v-model="dragging"
      :disabled="!signatureOptions.upload"
      @file="onFile"
    >
      <canvas ref="convertCanvas" style="display: none;"></canvas>

      <v-card
        role="dialog"
        :aria-hidden="!showModal"
        :aria-modal="showModal"
        aria-labelledby="sign-modal-title"
      >
        <form @submit.prevent="onSave">
          <div class="card-headline section-padding">
            <a
              class="closebutton error--text"
              role="button"
              tabindex="0"
              :title="$gettext('Close modal')"
              @keypress.enter="selectSignature(null)"
              @click="selectSignature(null)"
            >
              <v-icon>close</v-icon>
            </a>

            <transition name="fade" mode="out-in">
              <h2
                v-if="currentTab == 'saved'"
                id="sign-modal-title"
                key="saved"
                v-translate
              >
                Select a saved Signature
              </h2>
              <h2
                v-if="currentTab == 'text'"
                id="sign-modal-title"
                key="text"
                v-translate
              >
                Type your Signature
              </h2>
              <h2
                v-if="currentTab == 'draw'"
                id="sign-modal-title"
                key="draw"
                v-translate
              >
                Draw your Signature
              </h2>
              <h2
                v-if="currentTab == 'upload'"
                id="sign-modal-title"
                key="upload"
                v-translate
              >
                Upload your Signature
              </h2>
            </transition>

            <div class="tabs">
              <ul
                role="tablist"
                :aria-label="$gettext('Signature options')"
                class="primary--text"
                :data-id-tab="currentTab"
              >
                <li
                  v-for="tab in tabOptions"
                  v-show="!tab.disabled"
                  :id="`signature-modal--${tab.key}-tab`"
                  ref="tabs"
                  :key="tab.key"
                  :class="{ active: tab.key === currentTab }"
                  role="tab"
                  :tabindex="currentTab == tab.key ? 0 : -1"
                  :aria-selected="currentTab == tab.key"
                  :aria-controls="`signature-modal--${tab.key}-content`"
                  @keydown.prevent="handleNothing"
                  @keyup="handleTabNav($event, tab.key)"
                >
                  <a
                    :data-tab-target="tab.key"
                    @click.prevent="currentTab = tab.key"
                  >
                    {{ tab.title }}
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div class="card-content">
            <div v-if="showLoading && isLoading" class="section-padding">
              <v-progress-linear :indeterminate="true"></v-progress-linear>
            </div>

            <v-slide-x-reverse-transition hide-on-leave>
              <div
                v-show="!dragging && currentTab == 'saved'"
                id="signature-modal--saved-content"
                key="saved"
                :aria-hidden="currentTab !== 'saved'"
                role="tabpanel"
                tabIndex="0"
                aria-labelledby="signature-modal--saved-tab"
                data-test-id="tab-saved"
              >
                <p
                  v-if="!canSignWithAnything"
                  key="a"
                  v-translate
                  class="saved-signatures--warning srtext--text section-padding"
                >
                  Not all signature types are allowed for this document!
                </p>
                <p
                  v-if="!isLoading && userSignatures.length === 0"
                  key="e"
                  v-translate
                  class="saved-signatures--empty section-padding"
                >
                  You don't have any signatures yet. Create a new one.
                </p>

                <ul class="content-list" aria-describedby="sign-modal-title">
                  <li
                    v-for="(sig, index) in allSignatures"
                    :key="sig.uuid"
                    class="content-item"
                  >
                    <sr-signature-button
                      ref="savedSignature"
                      deletable
                      :title="
                        $gettextInterpolate(
                          $gettext('Use saved signature %{ index }'),
                          { index: index + 1 }
                        )
                      "
                      :delete-title="
                        $gettextInterpolate(
                          $gettext('Delete saved signature %{ index }'),
                          { index: index + 1 }
                        )
                      "
                      :src-data="sig.data_uri"
                      :selected="selectedId === sig.uuid"
                      :disabled="sig.disabled"
                      @select="selectSignature(sig.uuid)"
                      @delete="handleDeleteSignature(sig)"
                    ></sr-signature-button>
                  </li>
                </ul>
              </div>
            </v-slide-x-reverse-transition>
            <v-slide-x-reverse-transition hide-on-leave>
              <div
                v-show="!dragging && currentTab === 'text'"
                id="signature-modal--text-content"
                key="text"
                :aria-hidden="currentTab !== 'text'"
                role="tabpanel"
                tabIndex="0"
                aria-labelledby="signature-modal--text-tab"
                data-test-id="tab-text"
              >
                <div class="content-toolbar section-padding">
                  <v-text-field
                    ref="text"
                    v-model="currentText"
                    name="signed_as"
                    flat
                    color="primary"
                    :placeholder="$gettext('Type your name')"
                    :aria-label="$gettext('Type your name')"
                    solo
                  ></v-text-field>

                  <sr-color-drop
                    v-model="penColor"
                    class="icons"
                    name="color"
                    :colors="availableColors"
                  />
                </div>

                <ul class="content-list section-padding">
                  <li
                    v-for="fontFamily in signatureFonts"
                    :key="fontFamily"
                    class="content-item"
                  >
                    <sr-text-signature-button
                      ref="textSignature"
                      :font-family="fontFamily"
                      :pen-color="penColor"
                      :colors="availableColors"
                      :disabled="!(signatureOptions && signatureOptions.text)"
                      :src-text="currentText || $gettext('Type your name')"
                      :selected="selectedFont === fontFamily"
                      :title="
                        $gettextInterpolate(
                          $gettext('Use %{ fontFamily } font for signature'),
                          { fontFamily }
                        )
                      "
                      @select="onSelectFont"
                    ></sr-text-signature-button>
                  </li>
                </ul>
              </div>
            </v-slide-x-reverse-transition>
            <v-slide-x-reverse-transition hide-on-leave>
              <div
                v-show="!dragging && currentTab === 'draw'"
                id="signature-modal--draw-content"
                key="draw"
                :aria-hidden="currentTab !== 'draw'"
                role="tabpanel"
                tabIndex="0"
                aria-labelledby="signature-modal--draw-tab"
                data-test-id="tab-draw"
                class="section-padding"
              >
                <signature-field
                  ref="draw"
                  v-model="canvasHasSignature"
                  :pen-color="penColor"
                  :colors="availableColors"
                  @update-color="onUpdateColor"
                />
              </div>
            </v-slide-x-reverse-transition>
            <v-slide-x-reverse-transition hide-on-leave>
              <div
                v-show="dragging || currentTab === 'upload'"
                id="signature-modal--upload-content"
                key="upload"
                :aria-hidden="currentTab !== 'upload'"
                role="tabpanel"
                tabIndex="0"
                aria-labelledby="signature-modal--upload-tab"
                data-test-id="tab-upload"
                class="section-padding"
              >
                <sr-drop-area
                  ref="upload"
                  :dragging="dragging"
                  @file="onFile"
                ></sr-drop-area>
              </div>
            </v-slide-x-reverse-transition>
          </div>

          <div class="card-buttons">
            <sr-button
              sr-style="cancel"
              data-test-id="cancel"
              round
              @click="selectSignature(null)"
            >
              <translate>Cancel</translate>
            </sr-button>
            <v-spacer></v-spacer>
            <sr-button
              ref="save"
              sr-style="fancy"
              round
              :disabled="!saveButtonEnabled"
              data-test-id="submit"
              type="submit"
            >
              <translate>Save</translate>
            </sr-button>
          </div>
        </form>
      </v-card>
    </sr-drop-capture>
  </v-dialog>
</template>

<script>
import ModalMixin from './ModalMixin';
import SrButton from '../elements/SrButton';
import SrSignatureButton from '../elements/SrSignatureButton';
import SrTextSignatureButton from '../elements/SrTextSignatureButton';
import SrColorDrop from '../elements/SrColorDrop';
import SignatureField from '../elements/SignatureField';
import SrDropArea from '../elements/SrDropArea';
import SrDropCapture from '../elements/SrDropCapture';

import { mapActions, mapGetters } from 'vuex';

export function loadImage(dataUrl) {
  return new Promise((resolve, reject) => {
    const image = new Image();

    image.onload = e => {
      resolve(image);
    };

    image.onerror = e => {
      reject(e);
    };

    image.src = dataUrl;
  });
}

export function readFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = e => {
      resolve(e.target.result);
    };
    reader.onerror = e => {
      console.warn('Error reading file:', e);
      reject(e);
    };

    reader.readAsDataURL(file);
  });
}

export default {
  components: {
    SrButton,
    SrSignatureButton,
    SrTextSignatureButton,
    SrColorDrop,
    SignatureField,
    SrDropArea,
    SrDropCapture
  },
  mixins: [ModalMixin('signature')],
  props: {
    contentClass: { type: String, default: '' }
  },
  data() {
    return {
      returnValue: null,
      dragging: false,
      currentTab: 'saved',
      localText: null,
      penColor: '#5b5baf',
      selectedId: null,
      selectedFont: null,
      canvasHasSignature: false,
      nextLocalId: 0
    };
  },
  computed: {
    ...mapGetters('users', ['userSignatures', 'user']),
    ...mapGetters('fonts', ['signatureFonts']),
    ...mapGetters('api', ['isLoading']),
    currentText: {
      set(value) {
        this.localText = value;
      },
      get() {
        return this.localText === null ? this.user.full_name : this.localText;
      }
    },
    signatureOptions() {
      return this.modalOptions || {};
    },
    canSignWithAnything() {
      const { text, upload, draw } = this.signatureOptions;
      return Boolean(text && upload && draw);
    },
    tabOptions() {
      const { text, upload, draw } = this.signatureOptions;
      return [
        { key: 'saved', title: this.$gettext('Saved'), disabled: false },
        { key: 'text', title: this.$gettext('Type'), disabled: !text },
        { key: 'draw', title: this.$gettext('Draw'), disabled: !draw },
        { key: 'upload', title: this.$gettext('Upload'), disabled: !upload }
      ];
    },
    allSignatures() {
      const { text, upload, draw } = this.signatureOptions;
      return this.userSignatures.map(sig => ({
        ...sig,
        disabled:
          (!text && sig.from_text) ||
          (!upload && sig.from_upload) ||
          (!draw && !sig.from_text && !sig.from_upload)
      }));
    },
    saveButtonEnabled() {
      if (this.currentTab === 'saved') {
        return !!this.selectedId;
      }

      if (this.currentTab === 'text') {
        return Boolean(this.selectedFont && this.currentText.trim());
      }

      if (this.currentTab === 'draw') {
        return this.canvasHasSignature;
      }

      if (this.currentTab === 'upload') {
        return false;
      }

      return false;
    },
    showLoading() {
      const { currentTab } = this;
      return currentTab !== 'draw';
    },
    availableColors() {
      const { forceSignatureColor } = this.signatureOptions;
      if (forceSignatureColor) {
        return [
          {
            code: forceSignatureColor,
            label: this.$gettext('Standard color')
          }
        ];
      }

      return [
        {
          code: '#5b5baf',
          name: this.$gettext('Blue')
        },
        {
          code: '#d8181f',
          name: this.$gettext('Red')
        },
        {
          code: '#000000',
          name: this.$gettext('Black')
        }
      ];
    }
  },
  watch: {
    currentTab(value, lastValue) {
      if (lastValue !== value && this.showModal) {
        this.$nextTick(() => this.autoFocus());
      }
    },
    showModal(value, oldValue) {
      if (value && !oldValue) {
        this.selectedId = null;
        this.currentTab = this.guessCurrentTab();
        this.$nextTick(() => this.autoFocus());
      }
    },
    availableColors: {
      immediate: true,
      handler(colors) {
        // this list might update, after user loads, etc.
        // if the current color is outside the list
        // fix it to the first element
        if (colors && colors.length && colors.indexOf(this.penColor) === -1) {
          this.penColor = colors[0].code;
        }
      }
    }
  },
  created() {
    this.initSignatureFonts();
  },
  methods: {
    ...mapActions('users', ['deleteSignature', 'saveSignature']),
    ...mapActions('fonts', ['initSignatureFonts']),
    ...mapActions('messages', ['addMessage']),
    ...mapActions('signrequest', ['stampImage']),
    focusFirst() {
      this.$refs.tabs[0].focus();
    },
    autoFocus() {
      const elToFocus =
        {
          saved: (this.$refs.savedSignature || [])[0],
          text: this.$refs.text,
          upload: this.$refs.upload,
          draw: this.$refs.draw
        }[this.currentTab] || this.$el;
      if (elToFocus) {
        elToFocus.focus();
      }
    },
    guessCurrentTab() {
      const { text, draw } = this.signatureOptions;
      if (this.allSignatures.length > 0) {
        return 'saved';
      }
      if (text) {
        return 'text';
      }
      if (draw) {
        return 'draw';
      }

      return 'upload';
    },
    selectSignature(uuid) {
      this.selectedId = uuid;
      this.setReturnValue({
        sign:
          (uuid && this.allSignatures.find(sign => sign.uuid === uuid)) || null,
        close: true
      });
    },
    handleDeleteSignature({ uuid, localId }) {
      this.selectedId = null;
      const sigToDelete = this.userSignatures.find(sig => sig.uuid === uuid);
      if (sigToDelete && sigToDelete.from_upload) {
        this.currentTab = 'upload';
      }
      this.deleteSignature({ uuid, localId });
    },
    addLocal(data) {
      const nextUUID = `_local_${this.nextLocalId++}`;
      const sig = Object.freeze({
        ...data,
        localId: nextUUID,
        uuid: nextUUID
      });
      this.saveSignature(sig);
      return sig;
    },
    onSelectFont({ fontFamily }) {
      this.selectedFont = fontFamily;
      this.$nextTick(() => this.$refs.save.focus());
    },
    onUploadReady({ data_uri, width, height }) {
      const sign = this.addLocal({
        data_uri,
        from_upload: true,
        width,
        height
      });
      this.currentTab = 'saved';
      this.selectedId = sign.uuid;

      this.setReturnValue({ sign, close: false });
    },
    async onFile(file) {
      let image;
      try {
        const dataUrl = await readFile(file);
        image = await loadImage(dataUrl);
      } catch (e) {
        return this.complain();
      }

      const canvas = this.$refs.convertCanvas;
      canvas.width = image.width;
      canvas.height = image.height;

      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(image, 0, 0);

      const srcData = canvas.toDataURL('image/png');
      this.onUploadReady({
        data_uri: srcData,
        width: image.width,
        height: image.height
      });
    },
    onSave() {
      if (!this.saveButtonEnabled) {
        return;
      }
      let sign;
      if (this.currentTab === 'draw') {
        sign = this.addLocal(this.$refs.draw.getSignature());
      } else if (this.currentTab === 'text') {
        const fontIndex = this.signatureFonts.indexOf(this.selectedFont);
        sign = this.addLocal(
          this.$refs.textSignature[fontIndex].getSignature()
        );
      }
      if (sign) {
        this.setReturnValue({ sign, close: true });
      } else {
        this.closeModal();
      }
    },
    onUpdateColor(color) {
      this.penColor = color;
    },
    async setReturnValue({ sign, close }) {
      const shouldStamp =
        sign &&
        (this.signatureOptions.useStampedSignatues || global.force_use_stamp);
      this.returnValue = await (shouldStamp ? this.stampImage(sign) : sign);
      if (close) {
        this.closeModal();
      }
    },
    handleNothing(event) {
      // this does nothing.
    },
    handleTabNav(event, tabKey) {
      let indexOffset = 0;
      if (event.key === 'Enter') {
        this.currentTab = tabKey;
      } else if (event.key === 'ArrowRight') {
        indexOffset = 1;
      } else if (event.key === 'ArrowLeft') {
        indexOffset = -1;
      }
      if (indexOffset == 0) {
        return;
      }
      const currentIndex = this.tabOptions.findIndex(tab => tab.key === tabKey);
      const nextIndex =
        (this.tabOptions.length + currentIndex + indexOffset) %
        this.tabOptions.length;
      this.$refs.tabs[nextIndex].focus();
    },
    complain() {
      this.addMessage({
        type: 'error',
        msg: this.$gettext('Please upload signature image')
      });
    }
  }
};
</script>

<style scoped>
/deep/ .v-dialog {
  border-radius: 25px;
}

/deep/ .v-dialog.restrict-width {
  max-width: 30rem;
}

/deep/ .v-dialog.restrict-width-draw {
  max-width: 50rem;
}

.section-padding {
  /* it has its own class because it is arbitrary in the design */
  padding-left: 3rem;
  padding-right: 3rem;
}

.card-headline {
  padding-top: 4rem;
  padding-bottom: 1rem;
  font-weight: 900;
}

.card-headline h2 {
  margin-top: 0px;
  font-size: 24px;
}

.card-headline a.closebutton {
  margin: 30px; /* matches v-card padding */
  position: absolute;
  top: 0%;
  right: 0%;
  left: unset;
  line-height: 24px;
}

.rtl .card-headline a.closebutton {
  right: unset;
  left: 0%;
}

.card-headline a.closebutton:hover .v-icon,
.card-headline a.closebutton:focus .v-icon {
  color: inherit;
  transform: scale(1.2);
}

.content-toolbar {
  border-bottom: solid 0.5px #d6d6d6;
  border-top: solid 0.5px #d6d6d6;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.content-toolbar .v-text-field {
  flex-shrink: 1;
}

.content-toolbar /deep/ .v-text-field__details {
  display: none;
}

.content-toolbar /deep/ .v-input__control > .v-input__slot {
  margin-bottom: 0px;
  padding: 0px;
}

ul.content-list {
  display: flex;
  flex-wrap: wrap;

  /* compensate for li padding */
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  margin-top: 0rem;
}

ul.content-list li {
  display: flex;
  padding: 0.5rem;

  max-width: 50%;
  flex-basis: 50%;
}

.saved-signatures--warning,
.saved-signatures--empty {
  text-align: center;
  margin-top: 0;
  margin-bottom: 1em;
}

.card-buttons {
  padding-top: 1rem;
  padding-bottom: 3rem;

  /* same as section-padding, but compensate for sr-button margin */
  padding-left: 2.5rem;
  padding-right: 2.5rem;

  display: flex;
}

.card-buttons .sr-button {
  font-size: 18px;
  height: 3em;
  width: calc(50% - 12px);
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.tabs {
  width: 100%;
}

.tabs ul {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.tabs ul li {
  display: inline;
  margin-right: auto;
  margin-left: 0;
  flex-grow: 1;
  max-width: 60%;
  margin-bottom: 0.5rem;
}

.rtl .tabs ul li {
  margin-right: 0;
  margin-left: auto;
}

.tabs ul li a {
  color: rgba(0, 0, 0, 0.8);
  text-decoration: none;
  font-weight: normal;
  font-size: 15px;

  /* remove padding space between anchors */
  /* i dont know how but it works */
  display: inline-block;

  /* this one pixel is really important */
  padding-left: 1px;
  padding-right: 2px;
}

.tabs ul li.active a {
  color: inherit;
  border-bottom: 2px solid currentColor;
}
.tabs ul li.active:focus-within a {
  border-bottom: 2px dotted currentColor;
}

.tabs ul li:focus-within a {
  border-bottom: 2px solid currentColor;
}

.tabs ul li a:hover,
.tabs ul li.active a {
  color: inherit;
  cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
