<template>
  <div>
    <label class="container">
      <input type="radio" :checked="checked" name="radio" />
      <span class="checkmark"></span>
    </label>
  </div>
</template>
<script>
export default {
  props: {
    checked: { type: Boolean, default: false }
  }
};
</script>
<style scoped lang="scss">
.container {
  display: block;
  position: relative;
  padding: 0;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid #3670e1 !important;
}

.container input:checked ~ .checkmark {
  background-color: transparent;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
  top: 5px;
  left: 5px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #3670e1 !important;
}
</style>
