<template>
  <svg
    class="primary--text"
    width="34px"
    height="18px"
    viewBox="0 0 34 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <rect
        :fill="value ? 'currentColor' : '#DADADA'"
        x="0"
        y="0"
        width="34"
        height="18"
        rx="9"
      ></rect>
      <circle fill="#FFFFFF" :cx="value ? 25 : 9" cy="9" r="7"></circle>
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    value: { type: Boolean, default: false }
  }
};
</script>
<style scoped>
svg {
  vertical-align: middle;
  margin: 0px 1em;
}
</style>
