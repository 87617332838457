import angular from 'angular';
import Hammer from 'hammerjs';

/**
 * @ngdoc directive
 * @name frontendApp.directive:draggable
 * @description
 * # draggable
 */
angular.module('frontendApp').directive('draggable', [
  '$document',
  function($document) {
    return {
      restrict: 'A',
      scope: { draggableApi: '=', isDraggable: '&', onTap: '&' },
      link: function(scope, element, attr) {
        //var is_draggable = attr.draggableInit === 'true';

        // all positions are a percentage position within this container
        scope.container = angular.element('#' + attr.draggableContainerId);

        var x = parseFloat(attr.draggableStartX || 0),
          y = parseFloat(attr.draggableStartY || 0);
        // create a simple instance
        // by default, it only adds horizontal recognizers
        var mc = new Hammer(element[0], { touchAction: 'none' });

        // let the pan gesture support all directions.
        // this will block the vertical scrolling on a touch-device while on the element
        mc.get('pan').set({ direction: Hammer.DIRECTION_ALL });

        // used to store the initial location of the touch event on the img, needed to correct for these values
        // for the position on drag
        var x_touch_pos_img = 0,
          y_touch_pos_img = 0;
        function mousedown(event) {
          if (scope.isDraggable()) {
            var img_offset = angular.element(event.target).offset();
            x_touch_pos_img = event.center.x - img_offset.left;
            y_touch_pos_img = event.center.y - img_offset.top;
          }
        }

        scope.draggableApi = {
          // this sets the actual position on the editor container, watched in doc-page-editor.js
          css: {
            top: y * 100 + '%',
            left: x * 100 + '%',
            position: 'absolute',
            cursor: 'default'
          },
          // this gets stored to the DB
          position: {
            left: x,
            top: y
          }
        };

        scope.draggableApi.setPosition = function(
          x_pos,
          y_pos,
          doApply,
          skipPageBoundsCheck
        ) {
          if (!skipPageBoundsCheck) {
            // skipPageBoundsCheck means that we are not dragging the element (and want to check for page bounds)
            // we set the position programmatically (in which case we always honor the server values)

            // signature can't exit the document page
            if (x_pos < 0) {
              x_pos = 0;
            }
            if (element.width() !== scope.container.width()) {
              //Initially these are the same value, the signature still needs rendering
              var maxRightPosition =
                1 - element.width() / scope.container.width();
              if (x_pos > maxRightPosition) {
                x_pos = maxRightPosition;
              }
            }

            if (y_pos < 0) {
              y_pos = 0;
            }

            var maxBottomPosition =
              1 - element.height() / scope.container.height();
            if (element.height() && y_pos > maxBottomPosition) {
              y_pos = maxBottomPosition;
            }
          }

          y = typeof y_pos === 'undefined' ? y : y_pos;
          x = typeof x_pos === 'undefined' ? x : x_pos;
          scope.draggableApi.css.top = y * 100 + '%';
          scope.draggableApi.css.left = x * 100 + '%';
          scope.draggableApi.position.top = y;
          scope.draggableApi.position.left = x;
          if (angular.isDefined(doApply) && doApply) {
            scope.$apply();
          }
        };

        function mousemove(event) {
          if (scope.isDraggable()) {
            var con_offset = scope.container.offset();
            x =
              (event.center.x - con_offset.left - x_touch_pos_img) /
              scope.container.width();
            y =
              (event.center.y - con_offset.top - y_touch_pos_img) /
              scope.container.height();
            scope.draggableApi.setPosition(x, y, true);
          }
        }

        element.css({
          position: 'relative'
        });

        //TODO: Not in use right now; remove after we are sure works with normal ng-click
        function ontap(event) {
          // using hammer here as the ng-click is not working on ios devices
          // this toggles the editor for example
          if (angular.isFunction(scope.onTap)) {
            // event.srcEvent is the browser event
            var fn = scope.onTap();
            if (angular.isFunction(fn)) {
              fn(event.srcEvent);
            }
          }
        }

        mc.on('panstart', mousedown);
        mc.on('panmove', mousemove);
        mc.on('tap', ontap);

        scope.$on('$destroy', function() {
          mc.off('panstart', mousedown);
          mc.off('panmove', mousemove);
          mc.off('tap', ontap);
        });
      }
    };
  }
]);
