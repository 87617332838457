<template>
  <v-checkbox
    key="terms"
    :value="value"
    height="15"
    name="terms"
    :rules="[termsAcceptRule]"
    :class="{ center }"
    @change="$emit('input', $event)"
  >
    <template v-slot:label>
      <span class="font-weight-regular">
        <translate>I agree to the</translate>
        {{ ' ' }}
        <a
          v-translate
          :href="getPageUrlForLang('terms')"
          target="_blank"
          rel="noopener noreferrer"
          data-test-id="terms-link"
        >
          Terms of Service
        </a>
        {{ ' ' }}
        <translate>and</translate>
        {{ ' ' }}
        <a
          v-translate
          :href="getPageUrlForLang('privacy')"
          target="_blank"
          rel="noopener noreferrer"
          data-test-id="privacy-link"
        >
          Privacy Notice
        </a>
        .
      </span>
    </template>
  </v-checkbox>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    value: { type: Boolean, default: false },
    center: { type: Boolean, default: false }
  },
  computed: {
    ...mapGetters('conf', ['getPageUrlForLang']),
    termsAcceptRule() {
      return v => !!v || '';
    }
  }
};
</script>

<style scoped lang="scss">
.center {
  ::v-deep .v-input__control {
    width: 100%;
  }
  ::v-deep .v-input__slot {
    align-items: center;
    justify-content: center;
  }
}
</style>
