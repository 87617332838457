<template>
  <v-dialog
    ref="modal"
    v-model="showModal"
    max-width="444px"
    :content-class="contentClass"
  >
    <v-card
      role="dialog"
      :aria-hidden="!showModal"
      :aria-modal="showModal"
      aria-labelledby="forward-modal-title"
      :class="{
        mobile: $isMobile
      }"
    >
      <sr-button
        id="close-icon"
        class="close"
        sr-style="text"
        data-test-id="close"
        @click="closeModal()"
      >
        <v-icon aria-label="$gettext('Close forward dialog')" class="round">
          close
        </v-icon>
      </sr-button>
      <v-card-text>
        <h1 id="forward-modal-title" class="srtext--text">
          <translate>Forward document</translate>
        </h1>
        <p class="srtext--text">
          <translate>
            Forwarding the document to someone else will send a notification to
            whom you're assigning the document to. The original sender will also
            receive a notification. You will be added as a Copy Only (CC)
            recipient.
          </translate>
          <a
            v-if="forwardHelpUrl"
            v-translate
            :href="forwardHelpUrl"
            class="learn-more-a"
            target="_blank"
            rel="noopener noreferrer"
            data-test-id="forward-help-url"
          >
            Learn more
          </a>
        </p>
      </v-card-text>
      <forward-form
        ref="form"
        :email-value="email"
        :reason-value="reason"
        @submit="handleSubmit"
        @change="handleChange"
      >
        <forward-buttons :form-valid="formValid" @closeModal="closeModal" />
      </forward-form>
    </v-card>
  </v-dialog>
</template>
<script>
import ModalMixin from '@/components/modals/ModalMixin';
import Screen from '@/mixins/Screen';
import SrButton from '@/components/elements/SrButton';
import ForwardForm from '@/components/document/forward/ForwardForm.vue';
import variant from '@/utils/variant';
import { mapGetters } from 'vuex';
import BoxForwardFooterButtons from './BoxForwardFooterButtons.vue';
import ForwardFooterButtons from './ForwardFooterButtons.vue';
export default {
  components: {
    SrButton,
    ForwardForm,
    ForwardButtons: variant(BoxForwardFooterButtons, ForwardFooterButtons)
  },
  mixins: [ModalMixin('forward'), Screen],
  props: {
    contentClass: { type: String, default: '' }
  },
  data() {
    return {
      email: '',
      reason: '',
      formValid: false
    };
  },
  computed: {
    ...mapGetters('conf', ['forwardHelpUrl'])
  },
  watch: {
    showModal(value, oldValue) {
      if (value && !oldValue) {
        this.$nextTick(() => this.focusFirst());
      }
    }
  },
  mounted() {
    this.focusFirst();
  },
  methods: {
    handleChange(field, value) {
      this[field] = value;
    },
    focusFirst() {
      this.$refs.form.focusFirst();
    },
    async handleSubmit(event) {
      if (!this.formValid) {
        return;
      }
      this.modalResolve({
        email: this.email,
        reason: this.reason
      });
    }
  }
};
</script>
<style scoped>
/deep/ .signrequest-lib.v-dialog {
  border-radius: 4px;
}
/deep/ .v-dialog {
  border-radius: 25px;
}
.signrequest-lib .v-card {
  padding: 30px;
  contain: content;
}
.signrequest-lib h1 {
  font-size: 16px;
  opacity: 0.9;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 16px;
}
h1 {
  font-size: 24px;
  opacity: 0.9;
  font-weight: 900;
  margin-top: 30px;
  margin-bottom: 36px;
}
.signrequest-lib p {
  margin: 0;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: 0.28px;
  font-size: 12px;
}
p {
  margin: 0;
  font-size: 17px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: 0.28px;
}
.signrequest-lib .v-card__text,
.signrequest-lib /deep/ .v-card__text {
  padding: 0 !important;
}
/deep/ .v-card__text {
  position: relative;
  padding: 25px 40px;
  padding-bottom: 1.5rem;
}
.mobile /deep/ .v-card .v-card__text {
  padding: 0 2rem;
  padding: 25px 28px;
}
.signrequest-lib /deep/ .v-card__text__input {
  font-size: 16px;
  position: relative;
  padding: 0;
  margin-top: 15px;
  padding-top: 10px;
}
/deep/ .v-card__text__input {
  font-size: 16px;
  position: relative;
  padding: 0 40px;
}
/deep/ .v-card__text__textarea {
  min-height: 157px;
  height: 157px;
  padding-top: 1rem;
}
/deep/ .v-card__text__input textarea {
  flex-basis: unset; /* it's cryptic, as to why, but having
                      * flex-basis on textareas screws up
                      * line-breaking in placeholder */
}
/deep/ .v-card__text__input input::placeholder {
  opacity: 0.6;
  font-weight: 500;
  color: inherit;
}
/deep/ .v-card__text__input::before,
/deep/ .v-card__text__input::after,
.v-card__text__buttons::before {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  color: inherit;
  border-top: 1px solid #d6d6d6;
  content: ' ';
  transform: scaleY(0.5);
}
/deep/ .v-card__text__input::before,
.v-card__text__buttons::before {
  top: 0;
}
/deep/ .v-card__text__input::after {
  bottom: 0;
}
.signrequest-lib /deep/ .sr-input--element {
  border: solid 1px #bcbcbc;
  width: 100%;
  margin: 10px 0;
  font-size: 14px;
}
/deep/ textarea {
  position: relative;
  width: 100%;
  resize: none;
  overflow: hidden;
  overflow-wrap: break-word;
  margin: 11px 0;
}
.signrequest-lib .v-card__text__buttons /deep/ button {
  font-size: 14px;
  height: 3em;
  margin: 6px 8px;
  padding: 0 16px;
  width: unset;
}
.v-card__text__buttons /deep/ button {
  font-size: 18px;
  padding: 0 3.5em;
  margin: 0;
  height: 3em;
  width: 300px;
}
.v-card__text__buttons {
  padding-top: 47px;
  padding-bottom: 36px;
  display: flex;
  justify-content: center;
}
.signrequest-lib .close {
  position: absolute;
  z-index: 1;
  width: 1em;
  height: 1em !important;
  right: 2rem;
  color: #111111;
  opacity: 0.8;
  top: 14px;
  border: unset !important;
  border-radius: 6px !important;
}
.close {
  position: absolute;
  min-width: 1em;
  width: 1em;
  height: 1em;
  top: 2rem;
  color: #111111;
  opacity: 0.8;
  margin: 0;
  padding: 0;
  z-index: 1;
}
.ltr.signrequest-lib .close {
  right: 14px;
}
.rtl.signrequest-lib .close {
  left: 14px;
}
.ltr .close {
  right: 2rem;
}
.rtl .close {
  left: 2rem;
}
</style>
