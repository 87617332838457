<template>
  <component
    :is="href ? 'a' : 'button'"
    :type="href ? null : 'button'"
    :href="href"
    :disabled="disabled"
    :class="classList"
    data-sr-button
    v-on="$listeners"
  >
    <span class="sr-button--content">
      <slot />
    </span>
    <loading-animation v-if="loading" />
  </component>
</template>

<script>
import variant from '@/utils/variant';
import BoxLoader from './BoxLoader';
import SrButtonLoader from './SrButtonLoader';
// primary is the default, so does not exist in this dict
const styleDict = {
  cancel: { color: 'srcancel', vClass: 'primary--text sr-button--cancel' },
  success: { color: 'success', vClass: 'white--text' },
  danger: { color: 'error', vClass: 'white--text' },
  warning: { color: 'warning', vClass: 'white--text' },
  outline: {
    color: null,
    vClass: 'primary--text',
    outline: true
  },
  'flat-white': {
    color: null,
    vClass: 'primary--text sr-button--flat-white'
  },
  subtle: {
    color: null,
    vClass: 'primary--text sr-button--subtle'
  },
  text: { color: null, vClass: 'sr-button--text srtext--text', flat: true },
  'text-danger': {
    color: null,
    vClass: 'error--text sr-button--text sr-button--text--danger'
  },
  link: { color: null, vClass: 'sr-button--link primary--text' },
  fancy: { color: null, vClass: 'primary--text sr-button--fancy' },
  invert: { color: 'white', vClass: 'primary--text' },
  simple: { color: null, vClass: null }
};
export default {
  components: {
    LoadingAnimation: variant(BoxLoader, SrButtonLoader)
  },
  props: {
    srStyle: { type: String, default: 'primary' },
    round: { type: Boolean, default: false },
    border: { type: Boolean, default: true },
    circle: { type: Boolean, default: false },
    href: { type: String, default: undefined },
    disabled: { type: Boolean, default: false },
    muted: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    boxSmall: { type: Boolean, default: false },
    inline: { type: Boolean, default: false },
    loading: { type: Boolean, default: false }
  },
  computed: {
    classList() {
      return [
        'sr-button',
        this.btnProps.color,
        this.btnProps.vClass,
        {
          'sr-button--inline': this.$props.inline,
          'sr-button--outline': this.btnProps.outline,
          'sr-button--round': this.round,
          'sr-button--noborder': !this.border,
          'sr-button--circle': this.circle,
          'sr-button--muted': this.muted,
          'sr-button--small': this.small,
          'sr-button--loading': this.loading,
          'sr-button--custom-color': this.isCustomColor
        }
      ];
    },
    btnProps() {
      const entry = styleDict[this.srStyle] || {
        color: 'primary',
        vClass: ['white', 'white--text']
      };
      return entry;
    },
    isCustomColor() {
      return this.$vuetify.theme.primary !== '#366cd8';
    }
  },
  methods: {
    focus() {
      this.$el.focus();
    },
    updateValue(v) {
      this.$emit('input', v);
    }
  }
};
</script>
<style scoped>
.sr-button {
  padding: 0 16px;
  margin: 6px 8px;
  min-width: 88px;
  height: 36px;
  font-weight: 600;
  font-size: 14px;
  display: inline-flex;
  position: relative;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
}
.signrequest-lib .sr-button:not(.sr-button--inline):not(.sr-button--circle) {
  height: 40px;
}
.ay11-kb .sr-button:focus {
  outline-color: rgba(54, 108, 216, 0.7);
  outline-width: 4px;
  outline-style: solid;
  outline-offset: 0.5rem;
  opacity: 1;
}
.sr-button::before {
  border-radius: inherit;
  color: inherit;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  opacity: 0.12;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
}
.sr-button:hover::before {
  background-color: currentColor;
}
.sr-button.sr-button--small {
  height: 28px;
  padding: 0 8px;
  font-size: 13px;
}
a.sr-button:hover {
  text-decoration: none;
}
.sr-button .sr-button--content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  white-space: nowrap;
}
.sr-button[role='menuitem'] .sr-button--content {
  justify-content: flex-start;
}
.sr-button.sr-button--loading .sr-button--content {
  opacity: 0;
}
.sr-button /deep/ .sr-button--loader {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.sr-button--round {
  border-radius: 2.2em;
  border-width: 1px;
  border-style: solid;
}
.signrequest-lib .sr-button--round {
  border-radius: 6px;
}
.sr-button--noborder {
  border-style: none !important;
}
.sr-button.sr-button--circle {
  padding: 0;
  margin: 0;
  border-radius: 50%;
  width: 2em;
  height: 2em;
  min-width: 2em;
  border-width: 1px;
  border-style: solid;
}
.sr-button--muted {
  opacity: 0.19;
}
.sr-button--fancy {
  background: linear-gradient(90deg, #2a43f8, #3196ec);
  border-style: none;
}
.sr-button--fancy:hover {
  background: linear-gradient(90deg, #334bfc, #43a3f5);
}
.sr-button.sr-button--fancy::before {
  content: unset;
}
.sr-button--fancy.sr-button--custom-color {
  background-color: currentColor !important;
  background-image: none;
}
.sr-button--fancy .sr-button--content {
  color: white;
}
.sr-button[disabled] {
  color: rgba(0, 0, 0, 0.26) !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
  border-color: currentColor !important;
}
.sr-button.sr-button--fancy:not(.sr-button--custom-color)[disabled][disabled][disabled][disabled] {
  opacity: 0.2;
}
.sr-button--flat-white {
  background: white !important;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.08);
}
.sr-button.sr-button--flat-white[disabled] {
  background: white !important;
  border: none !important;
}
.sr-button--subtle::before {
  background: currentColor;
}
.sr-button--outline {
  background: transparent !important;
  border: 1px solid currentColor;
}
.sr-button--outline[disabled] {
  background: transparent !important;
}
.sr-button--cancel.sr-button--custom-color {
  background-color: white !important;
  border-color: currentColor !important;
}
.sr-button--text {
  opacity: 0.6;
  background: none !important;
}
.sr-button--text,
.sr-button--link {
  font-weight: inherit;
}
.sr-button--text--danger::before {
  background-color: transparent !important;
}
.sr-button--text /deep/ .v-icon {
  color: #111;
}
.sr-button--text:hover /deep/ .v-icon {
  color: inherit;
}
.sr-button--link,
.sr-button--link[disabled][disabled][disabled][disabled] {
  background: none !important;
  background-color: unset !important;
  border-width: 0;
}
.sr-button--link:focus {
  border: none !important;
}
.sr-button--link:hover::before,
.sr-button--inline:hover::before {
  background-color: unset !important;
}
.sr-button--inline {
  margin: 0;
  padding: 0;
  min-width: unset;
  font-size: inherit;
  height: unset;
  vertical-align: initial;
  opacity: 1;
}
.signrequest-lib .sr-button {
  border-radius: 6px;
  min-width: 32px;
  min-height: 32px;
  outline: unset !important;
}
.signrequest-lib
  .sr-button:not(.sr-button--link):not(.sr-button--inline):not(.sr-button--noborder) {
  border: 1px solid #d3d3d3;
}
.signrequest-lib .sr-button.sr-button--link:hover,
.signrequest-lib .sr-button.sr-button--inline:hover,
.signrequest-lib .sr-button.sr-button--noborder:hover,
.signrequest-lib .sr-button.sr-button--text:hover,
.signrequest-lib .sr-button.sr-button--link:focus,
.signrequest-lib .sr-button.sr-button--inline:focus,
.signrequest-lib .sr-button.sr-button--noborder:focus,
.signrequest-lib .sr-button.sr-button--text:focus,
.signrequest-lib .sr-button.sr-button--link:focus-within,
.signrequest-lib .sr-button.sr-button--inline:focus-within,
.signrequest-lib .sr-button.sr-button--noborder:focus-within,
.signrequest-lib .sr-button.sr-button--text:focus-within,
.signrequest-lib .sr-button.sr-button--link:active,
.signrequest-lib .sr-button.sr-button--inline:active,
.signrequest-lib .sr-button.sr-button--noborder:active,
.signrequest-lib .sr-button.sr-button--text:active {
  border: unset;
}
.signrequest-lib .sr-button:hover,
.signrequest-lib .sr-button:focus-within,
.signrequest-lib .sr-button:focus {
  border: 1px solid #909090;
  background-color: #f4f4f4;
}
.signrequest-lib .sr-button:hover::before {
  background-color: unset;
}
.signrequest-lib .sr-button:active {
  background-color: #e8e8e8;
  box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.12);
  border: solid 1px #909090;
}
.signrequest-lib .sr-button.primary {
  border: solid 1px transparent;
  background-color: currentColor;
}
.signrequest-lib .sr-button.primary .sr-button--content {
  color: #fff;
}
.signrequest-lib .sr-button.primary:hover {
  filter: brightness(115%);
}
.signrequest-lib .sr-button.primary:focus-within {
  border: solid 1px currentColor;
  box-shadow: inset 0px 0px 0px 1px #fff;
}
.signrequest-lib .sr-button.primary:active:before {
  filter: brightness(85%);
}
.signrequest-lib .sr-button:focus,
.signrequest-lib .sr-button:focus-within {
  box-shadow: 0 0 1px 3px #6ca1f9;
  border: 1px solid transparent;
}
</style>
