<template>
  <div>
    <h2 id="integrations" translate>Integrations</h2>
    <AccountIntegrationGoogleDrive></AccountIntegrationGoogleDrive>
    <AccountIntegrationDropbox></AccountIntegrationDropbox>
    <AccountIntegrationSlack></AccountIntegrationSlack>
    <AccountIntegrationZapier></AccountIntegrationZapier>
  </div>
</template>

<script>
import AccountIntegrationGoogleDrive from '@/components/AccountIntegrationGoogleDrive';
import AccountIntegrationDropbox from '@/components/AccountIntegrationDropbox';
import AccountIntegrationSlack from '@/components/AccountIntegrationSlack';
import AccountIntegrationZapier from '@/components/AccountIntegrationZapier';

export default {
  components: {
    AccountIntegrationDropbox,
    AccountIntegrationGoogleDrive,
    AccountIntegrationSlack,
    AccountIntegrationZapier
  }
};
</script>
