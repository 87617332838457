<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g fill-rule="evenodd">
      <path
        d="M13.4361148,1.03584031e-16 C14.3276335,-6.01853049e-17 14.6509198,0.0928256111 14.9768457,0.267132704 C15.3027716,0.441439796 15.5585602,0.697228371 15.7328673,1.0231543 C15.9071744,1.34908022 16,1.67236646 16,2.5638852 L16,13.4361148 C16,14.3276335 15.9071744,14.6509198 15.7328673,14.9768457 C15.5585602,15.3027716 15.3027716,15.5585602 14.9768457,15.7328673 C14.6509198,15.9071744 14.3276335,16 13.4361148,16 L2.5638852,16 C1.67236646,16 1.34908022,15.9071744 1.0231543,15.7328673 C0.697228371,15.5585602 0.441439796,15.3027716 0.267132704,14.9768457 C0.0928256111,14.6509198 4.01235366e-17,14.3276335 -6.90560205e-17,13.4361148 L6.90560205e-17,2.5638852 C-4.01235366e-17,1.67236646 0.0928256111,1.34908022 0.267132704,1.0231543 C0.441439796,0.697228371 0.697228371,0.441439796 1.0231543,0.267132704 C1.34908022,0.0928256111 1.67236646,6.01853049e-17 2.5638852,-1.03584031e-16 L13.4361148,1.03584031e-16 Z M13,4 L3,4 C2.44771525,4 2,4.44771525 2,5 L2,5 L2,13 C2,13.5522847 2.44771525,14 3,14 L3,14 L13,14 C13.5522847,14 14,13.5522847 14,13 L14,13 L14,5 C14,4.44771525 13.5522847,4 13,4 L13,4 Z"
      />
      <g transform="translate(3 5.5)">
        <rect width="1.5" height="1.5" x="2.833" rx=".5" />
        <rect width="1.5" height="1.5" x="5.667" rx=".5" />
        <rect width="1.5" height="1.5" x="8.5" rx=".5" />
        <rect width="1.5" height="1.5" y="2.5" rx=".5" />
        <rect width="1.5" height="1.5" x="2.83" y="2.5" rx=".5" />
        <rect width="1.5" height="1.5" x="5.67" y="2.5" rx=".5" />
        <rect width="1.5" height="1.5" x="8.5" y="2.5" rx=".5" />
        <rect width="1.5" height="1.5" y="5" rx=".5" />
        <rect width="1.5" height="1.5" x="2.83" y="5" rx=".5" />
      </g>
    </g>
  </svg>
</template>
<script>
export default {};
</script>
