import angular from 'angular';

/**
 * @ngdoc function
 * @name frontendApp.controller:ReferralProgramCtrl
 * @description
 * # ReferralProgramCtrl
 * Controller of the frontendApp
 */
angular.module('frontendApp').controller('ReferralProgramCtrl', [
  '$scope',
  'UserService',
  'referralLinksService',
  'srClaimReferredUserModal',
  function(
    $scope,
    UserService,
    referralLinksService,
    srClaimReferredUserModal
  ) {
    $scope.loading = false;

    $scope.referralPercentage = 25;

    $scope.referral = null;

    $scope.refreshReferral = function() {
      UserService.waitForUser().then(function(user) {
        if (!user.logged_in) {
          return;
        }
        $scope.loading = true;
        referralLinksService.getReferral().then(function(resp) {
          $scope.loading = false;
          $scope.referral = resp.data;
        });
      });
    };

    $scope.refreshReferral();

    $scope.addReferralLink = function() {
      $scope.loading = true;
      referralLinksService.createReferralLink().then(function() {
        $scope.refreshReferral();
      });
    };

    $scope.removeReferralLink = function(identifier) {
      $scope.loading = true;
      referralLinksService.deleteReferralLink(identifier).then(function() {
        $scope.refreshReferral();
      });
    };

    $scope.claimUser = function() {
      srClaimReferredUserModal.activate();
    };

    $scope.getRequestPayoutMailtoLink = function() {
      var link = 'mailto:support+referral@signrequest.com';
      if ($scope.user && $scope.user.email) {
        link += '?subject=Referral payout request for: ' + $scope.user.email;
      }
      link +=
        '&body=Hi SignRequest, %0D%0A%0D%0AI would like to request a referral payout! %0D%0A%0D%0AI have filled in all my (company) details in my account and would like to receive the payout using the following paypal/bankaccount: ... %0D%0A%0D%0AThanks!';
      return link;
    };
  }
]);
