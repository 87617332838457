const formUpdate = {
  get() {
    return null;
  },
  set(update) {
    const combined = Object.freeze({
      ...this.$props.value,
      ...update
    });
    this.$emit('input', combined);
  }
};
function makeField(field) {
  const { name, filter } = field.name
    ? field
    : { name: field, filter: value => value };
  return {
    get() {
      return this.$props.value[name];
    },
    set(value) {
      this.formUpdate = {
        [name]: filter(value)
      };
    }
  };
}

export default function makeFields(...fields) {
  return fields.reduce(
    (acc, field) => ({ ...acc, [field.name || field]: makeField(field) }),
    { formUpdate }
  );
}
