<template>
  <span @click="onClick">
    <slot />
  </span>
</template>
<script>
export default {
  props: {
    disabled: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false }
  },
  methods: {
    async onClick() {
      if (this.$props.disabled) {
        return null;
      }
      const fileUpload = document.createElement('input');
      fileUpload.value = null;
      fileUpload.hidden = true;
      fileUpload.type = 'file';
      fileUpload.multiple = this.$props.multiple;
      fileUpload.onchange = this.onChange;
      fileUpload.onclick = function(event) {
        event.stopPropagation();
      };
      this.$el.appendChild(fileUpload);
      fileUpload.click();
    },
    onChange(event) {
      const file = event.target.files[0];
      this.$emit('file', file || null);
      this.$emit('files', Array.prototype.slice.call(event.target.files));
      this.$el.removeChild(event.target);
    }
  }
};
</script>
