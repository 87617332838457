<template>
  <div v-if="userLoaded">
    <div v-if="userLoggedIn">
      <slot></slot>
    </div>
    <v-layout v-if="!userLoggedIn">
      <v-flex xs12 class="align--center margin-bottom">
        <h2 v-translate class="margin-bottom">Please login first.</h2>
        <sr-button
          data-cy="check-login-button"
          @click.prevent="showLoginModal()"
        >
          <span v-translate>Login</span>
        </sr-button>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import permissions from '@/mixins/permissions';

export default {
  name: 'CheckLogin',
  mixins: [permissions],
  methods: {
    ...mapActions('modals', ['showLoginModal'])
  }
};
</script>
