<template>
  <div class="subscribe-input-group">
    <label class="subscribe-input-label">{{ label }}</label>
    <div ref="stripeElementPlaceholder" class="subscribe-input-field"></div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { STRIPE_ERRORS } from '@/components/subscribe/constants';

export default {
  props: {
    label: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    type: { type: String, default: 'text' },
    element: { type: Object, default: null }
  },
  watch: {
    element() {
      this.element.mount(this.$refs.stripeElementPlaceholder);

      this.element.addEventListener('change', event => {
        if (event.complete && !event.error) {
          this.$emit('element-completed', true);
        } else {
          this.$emit('element-completed', false);
          this.changeStripeElementsInputCompleted({ completed: false });
        }
        this.toggleError(event);
      });

      this.element.addEventListener('focus', event => {
        this.resetErrors();
        this.$emit('input');
      });
    }
  },

  methods: {
    ...mapActions('subscription', [
      'resetErrors',
      'changeStripeElementsInputCompleted'
    ]),
    toggleError: function(event) {
      if (event.error) {
        const stripeErrorCode = event.error.code;
        const errorMessage =
          stripeErrorCode in STRIPE_ERRORS
            ? STRIPE_ERRORS[stripeErrorCode]
            : STRIPE_ERRORS['default'];
        this.$emit('stripeInputError', errorMessage);
      } else {
        this.$emit('stripeInputError', null);
      }
    }
  }
};
</script>
