<template>
  <div class="signer-entry--outer" :class="{ active }">
    <v-layout
      class="signer-entry"
      row
      wrap
      justify-end
      @click.stop="$emit('click:details')"
    >
      <v-flex shrink class="signer-color">
        <div class="dot" :style="`background: ${signerColor};`"></div>
      </v-flex>

      <v-flex grow class="signer-info">
        <span :class="{ 'signer-info-email': email, 'signer-label': !email }">
          {{ displayLabel }}
        </span>
        <sr-checkbox-input
          v-if="showCheckbox && !hasError"
          v-model="checkboxValue"
          type="checkbox"
          name="checkbox"
          class="signer-entry--checkbox primary--text"
          :has-ripple-effect="false"
          :label="$gettext('I need to sign myself')"
          @click.stop="handleClickCheckbox"
        />
      </v-flex>

      <v-spacer />

      <v-flex shrink class="text-xs-right signer-level signer-level-icon">
        <sr-button
          inline
          sr-style="text"
          :title="$gettext('Adjust signer settings')"
        >
          <level-icon :level="level" class="icon-size-fix level-icon mx-2" />
          <v-icon aria-hidden="true">keyboard_arrow_right</v-icon>
        </sr-button>
      </v-flex>
    </v-layout>
    <v-messages
      v-if="shouldValidate"
      :value="validations"
      :class="{ 'error--text': hasError }"
    />
  </div>
</template>

<script>
import validatable from 'vuetify/lib/mixins/validatable';
import makeFields from '@/utils/makeFields';
import { getSignerColor } from '@/utils/signerColors';
import signerLabel from '@/utils/signerLabel';
import SrCheckboxInput from '@/components/elements/SrCheckboxInput';
export const Level = {
  getLevelTitle(level) {
    return {
      signature: this.$gettext('Needs to sign'),
      approve: this.$gettext('Needs to approve'),
      cc: this.$gettext('Will receive a copy'),
      notify: this.$gettext('Will only get notified'),
      in_person: this.$gettext('Needs to sign in person')
    }[level];
  },
  props: {
    level: { type: String, required: true }
  },
  render(h) {
    const { level } = this.$props;
    return h('span', { attrs: this.$attrs }, [
      Level.getLevelTitle.call(this, level)
    ]);
  }
};
export const LevelIcon = {
  props: {
    level: { type: String, required: true }
  },
  render(h) {
    const { level } = this.$props;
    const levels = {
      signature: 'icon--signature',
      approve: 'icon--check',
      cc: 'icon--document-stack',
      notify: 'icon--paper-airplane',
      in_person: 'icon--users'
    };
    const classes = ['icon'];
    if (levels[level]) {
      classes.push(levels[level]);
    }
    return h('i', { class: classes });
  }
};
export default {
  components: { LevelIcon, SrCheckboxInput },
  mixins: [validatable],
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: { type: Object, required: true },
    signerIndex: { type: Number, default: 0 },
    active: { type: Boolean, default: false },
    showCheckbox: { type: Boolean, default: false }
  },
  computed: {
    ...makeFields('level', 'email', 'label'),
    displayLabel() {
      return this.signerIndex
        ? signerLabel(this.value, this.signerIndex)
        : this.email || this.$gettext('Sender');
    },
    checkboxValue: {
      get() {
        return this.level === 'signature';
      },
      set(newValue) {
        this.level = newValue ? 'signature' : 'cc';
      }
    },
    signerColor() {
      return getSignerColor(this.signerIndex);
    },
    shouldValidate() {
      return this.hasInput && !this.$props.active;
    }
  },
  watch: {
    internalValue(value) {
      this.hasInput = Boolean(value.email);
    }
  },
  methods: {
    handleClickCheckbox(event) {
      // does nothing and prevents
      // clicks on a checkbox from opening
      // signer details
      event.stopPropagation();
    }
  }
};
</script>

<style scoped>
i {
  overflow: hidden;
}
.lowercase {
  text-transform: lowercase;
}
.signer-color {
  display: flex;
  align-items: center;
  width: 16px;
}
.dot {
  width: 5px;
  height: 5px;
  border-radius: 100%;
}
.signer-entry {
  cursor: pointer;
  min-height: 72px;
  height: 72px; /* IE11 was here and asked his 90ies back */
  align-items: center;
}
.signer-info {
  font-size: 16px;
  font-weight: normal;
  max-width: calc(80% + 1.4rem);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  /* overflow hidden cuts outline, because outline is drawn outside of element */
  margin: -0.7rem;
  padding: 0.7rem;
}
.signer-info .signer-info-email {
  cursor: pointer;
}
.signer-info .sr-field.sr-checkbox {
  min-height: unset;
}
/deep/ .sr-field.sr-checkbox .sr-field--control {
  display: none;
}

.signer-entry--checkbox.sr-checkbox /deep/ label {
  color: inherit !important;
}
.signer-level-icon a {
  display: inline-block;
  margin-top: 2px;
}
.signer-level-icon .level-icon {
  /* make level-icon match v-icon color */
  color: rgba(0, 0, 0, 0.54);
  line-height: 1em;
  font-size: 16px;
}
.signer-level-icon .v-icon {
  font-size: 16px;
  vertical-align: inherit;
  margin-top: 1px;
  opacity: 0.54;
}
.signer-level-icon .icon-size-fix::before {
  font-size: 16px;
}
.rtl .signer-level-icon .v-icon {
  transform: rotate(180deg);
}
.signer-level-icon a:hover {
  text-decoration: none;
}
div /deep/ .v-messages {
  min-height: 0;
}
.ltr div /deep/ .v-messages {
  left: 1.1rem;
}
.rtl div /deep/ .v-messages {
  right: 1.1rem;
}
.signer-entry--checkbox.sr-checkbox {
  /* it's the design! */
  transform: scale(0.8);
  transform-origin: top left;
  outline-offset: 0;
  padding: 0 5px;
  margin: 0 -5px;
  margin-top: -3px;
}
.rtl .signer-entry--checkbox.sr-checkbox {
  transform-origin: top right;
}
</style>
