<template>
  <sr-signature-button
    class="sr-text-signature-button"
    :title="title"
    :selected="selected"
    :disabled="disabled"
    @select="handleSelect"
    @ready="redraw"
  >
    <canvas ref="canvas" width="512" height="200"></canvas>
  </sr-signature-button>
</template>

<script>
import SrSignatureButton from './SrSignatureButton.vue';

export default {
  components: { SrSignatureButton },
  props: {
    title: { type: String, default: '' },
    srcText: { type: String, default: '' },
    selected: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    fontFamily: { type: String, required: true },
    defaultSize: { type: Number, default: 100 },
    penColor: { type: String, default: 'red' },
    baseline: { type: Number, default: 0.5 },
    textBaseline: { type: String, default: 'middle' }
  },
  computed: {
    isFontLoaded() {
      const fontLoadedFunc = this.$store.getters['fonts/fontLoadedFunc'];
      return fontLoadedFunc(this.fontFamily);
    }
  },
  watch: {
    isFontLoaded() {
      this.redraw();
    },
    srcText() {
      this.redraw();
    },
    penColor() {
      this.redraw();
    }
  },
  methods: {
    redraw() {
      const canvas = this.$refs.canvas;

      if (!canvas) {
        return;
      }

      const ctx = canvas.getContext('2d');
      const text = this.srcText || '';
      const textMargin = canvas.width * 0.1;
      const fontFamily = this.fontFamily;

      ctx.clearRect(0, 0, canvas.width, canvas.height);

      ctx.textAlign = 'center';
      ctx.fillStyle = this.penColor;
      ctx.textBaseline = this.textBaseline;

      // adjust the font size
      let fontSize = this.defaultSize;
      while (fontSize > 0) {
        ctx.font = `${fontSize}px ${fontFamily}`;
        const textWidth = ctx.measureText(text).width;
        const targetWidth = canvas.width - textMargin;

        const fraction = textWidth / targetWidth;
        if (fraction <= 1) {
          break;
        }

        // divide if we can
        fontSize = Math.min(fontSize - 1, Math.floor(fontSize / fraction));
      }

      const yPos = canvas.height * this.baseline;
      ctx.fillText(text, canvas.width / 2, yPos);
    },
    getSignature() {
      const { canvas } = this.$refs;
      return {
        from_text: true,
        data_uri: canvas.toDataURL('image/png'),
        width: canvas.width,
        height: canvas.height
      };
    },
    handleSelect() {
      const { fontFamily } = this.$props;
      this.$emit('select', { fontFamily });
    }
  }
};
</script>
