import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-wrapper',{attrs:{"id":"users"}},[_c(VContainer,{staticClass:"pa-0"},[_c(VLayout,{staticClass:"margin-bottom"},[_c(VFlex,{staticClass:"align--center",attrs:{"xs12":""}},[_c('span',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("You can add users on a team page.")]),(_vm.howToMakeTeamUrl)?_c('a',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"href":_vm.howToMakeTeamUrl,"data-test-id":"how-to-make-team"}},[_vm._v("\n          To make a team account have a look here.\n        ")]):_vm._e()])],1),(_vm.isLoading && _vm.userLoggedIn)?_c(VLayout,{attrs:{"justify-center":""}},[_c('Loading')],1):_vm._e(),_c('CheckLogin',[_c(VLayout,[_c(VFlex,{attrs:{"xs12":""}},[_c('div',{staticClass:"list-group"},_vm._l((_vm.teamUsers),function(teamUser){return _c('div',{key:teamUser.email,staticClass:"list-group__item"},[_c('div',{staticClass:"flag"},[_c('div',{staticClass:"flag__object"},[_c('v-gravatar',{attrs:{"email":teamUser.email,"default-img":"mm","size":48}})],1),_c('div',{staticClass:"flag__body"},[_c(VLayout,[_c(VFlex,{attrs:{"xs6":""}},[_c('strong',[_vm._v(_vm._s(teamUser.name))])]),_c(VFlex,{attrs:{"xs6":""}},_vm._l((teamUser.team_users),function(teamMember){return _c(VLayout,{key:teamMember.uuid,attrs:{"row":""}},[_c(VFlex,{attrs:{"xs6":""}},[_vm._v("\n                          "+_vm._s(teamMember.team.name)+" - "+_vm._s(teamMember.role)+"\n                        ")]),_c(VFlex,{attrs:{"xs6":""}},[(
                              !_vm.membersBeingDeleted[teamMember.uuid] &&
                                !teamMember.is_owner
                            )?_c('a',{attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.deleteTeamMembership(teamMember)}}},[_c('span',{staticClass:"icon icon--trash text--error"})]):_vm._e(),(_vm.membersBeingDeleted[teamMember.uuid])?_c('Loading'):_vm._e()],1)],1)}),1)],1)],1)])])}),0)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }