import angular from 'angular';
import _ from 'lodash';

/**
 * @ngdoc function
 * @name frontendApp.controller:SignTemplateCtrl
 * @description
 * # SignTemplateCtrl
 * Controller of the frontendApp
 */
angular.module('frontendApp').controller('SignTemplateCtrl', [
  '$scope',
  '$route',
  'UserService',
  'docService',
  'utils',
  '$location',
  'gettextCatalog',
  '$rootScope',
  function(
    $scope,
    $route,
    UserService,
    docService,
    utils,
    $location,
    gettextCatalog,
    $rootScope
  ) {
    $scope.loading = true;
    $scope.converting = false;
    $scope.public_template_id = $route.current.params.public_template_id;
    var search = $location.search();
    // we need to support people putting a plus in the url like so:
    // ?p=1&email=mkrens+bla@gmail.com&phone=+31612345678
    // which the browser micht / will change to:
    // ?p=1&email=mkrens%20bla@gmail.com&phone=%2031612345678
    $scope.from_email = utils.replaceSpacesFromUrlParam(
      search.email || search.from_email || ''
    );
    $scope.verify_phone_number = utils.replaceSpacesFromUrlParam(
      search.phone || search.verify_phone_number || ''
    );
    $scope.allow_no_phone = search.no_phone;
    $scope.emailValid = true;
    $scope.doc = {};

    if (search.prefill_tags) {
      $scope.prefill_tags = utils.parseObjectFromEncodedUrlParam(
        search.prefill_tags
      );
    } else {
      $scope.prefill_tags = [];
    }

    if (search.signers) {
      // these can be included to have extra signers for cc signers for example
      $scope.signers = utils.parseObjectFromEncodedUrlParam(search.signers);
    } else {
      $scope.signers = [];
    }

    $scope.getSigners = function() {
      var signers = [];
      var foundOwner = false;
      _.each($scope.signers, function(signer, index) {
        // the signer options can be stuff like:
        // needs_to_sign, order, approve_only, in_person, verify_phone_number, notify_only, required_attachments
        if (signer.email && utils.EMAIL_REGEXP.test(signer.email)) {
          if (!signer.level) {
            // we allow using needs_to_sign, approve_only etc. directly instead of the 'level' we use internally
            // so the signrequest-js interface matches the Rest API. We convert that to the level here
            signer.level = docService.getLevelFromSignerConfig(signer);
          }
          if (signer.email === $scope.from_email) {
            foundOwner = true;
            if ($scope.verify_phone_number) {
              signer.verify_phone_number = $scope.verify_phone_number;
            } else {
              delete signer.verify_phone_number;
            }
          }
          signers.push(signer);
        }
      });
      if (!foundOwner) {
        if ($scope.verify_phone_number) {
          signers.unshift({
            email: $scope.from_email,
            verify_phone_number: $scope.verify_phone_number
          });
        } else {
          signers.unshift({ email: $scope.from_email });
        }
      }
      return signers;
    };

    $scope.loading_continue = search.continue;

    $scope.clearParams = function() {
      _.each(search, function(v, param_name) {
        if (
          !~[
            'next',
            'hide_title',
            'hide_login',
            'hide_logo',
            'new_sign'
          ].indexOf(param_name)
        ) {
          $location.search(param_name, null);
        }
      });
    };

    $scope.disableEmail = function() {
      if ($scope.loading) {
        return true;
      }
      if (
        $scope.user &&
        $scope.user.logged_in &&
        $scope.user.email === $scope.from_email &&
        $scope.emailValid
      ) {
        // $scope.user.email === $scope.from_email is not true when a email parameter is given that's not the same as
        // the logged in user
        return true;
      }
      return false;
    };

    docService.getPublicTemplate($scope.public_template_id).then(
      function(resp) {
        $scope.doc = resp.data.doc;

        UserService.waitForUser().then(function() {
          $scope.loading = false;

          if ($scope.user.logged_in && !$scope.from_email) {
            $scope.from_email = $scope.user.email;
          }

          // continue right away when we can
          if ($scope.from_email && $scope.formIsValid() && search.continue) {
            $scope.continue();
          } else {
            $scope.loading_continue = false;
          }
        });
      },
      function() {
        // error
        utils.navigateTo('/');
      }
    );

    $scope.checkEmail = function() {
      $scope.emailValid = utils.EMAIL_REGEXP.test($scope.from_email);
    };

    $scope.phoneValid = function() {
      if (
        $scope.moreInfoForm &&
        // ...$validators.internationalPhoneNumber might not be registered yet when the page loads
        $scope.moreInfoForm.verify_phone_number.$validators
          .internationalPhoneNumber
      ) {
        return (
          $scope.moreInfoForm.verify_phone_number.$validators.internationalPhoneNumber(
            $scope.moreInfoForm.verify_phone_number
          ) &&
          ($scope.verify_phone_number || $scope.allow_no_phone)
        );
      }
      return false;
    };

    $scope.formIsValid = function() {
      $scope.checkEmail();
      return (
        $scope.emailValid && ($scope.phoneValid() || $scope.allow_no_phone)
      );
    };

    $scope.continue = function($event) {
      if ($event) {
        $event.preventDefault();
      }
      if ($scope.formIsValid()) {
        $scope.loading = true;
        UserService.waitForUser().then(function() {
          // get converted document from template
          docService
            .getApiDocument({
              public_template_id: $scope.public_template_id,
              from_email: $scope.from_email
            })
            .then(
              function(resp) {
                var signer_options = $scope.getSigners();
                // create signrequest
                docService
                  .doSignRequest({
                    doc: resp.data.doc,
                    who: 'mo',
                    from_email: $scope.from_email,
                    signers: _.map(signer_options, 'email').join(','),
                    prefill_tags: $scope.prefill_tags,
                    signer_options: signer_options
                  })
                  .then(
                    function(response) {
                      $scope.clearParams();
                      utils.navigateTo(response.data.redirect_url);
                      $scope.loading = false;
                    },
                    function() {
                      // error
                      $scope.loading = false;
                    }
                  );
              },
              function() {
                // error
                utils.navigateTo('/');
              }
            );
        });
      }
      return false;
    };
  }
]);
