<template>
  <div class="list-group doc-search-list">
    <div v-for="(doc, index) in documents" :key="doc.uuid">
      <div class="list-group__item" :class="{ locked: doc.locked }">
        <div v-if="doc.locked" class="locked-item" @click="openDocument(doc)">
          <v-tooltip max-width="350px" bottom>
            <template v-slot:activator="{ on }">
              <span v-on="on">
                <span v-translate>Upgrade to unlock</span>
                <Space></Space>
                <i class="fa fa-lock" aria-hidden="true"></i>
              </span>
            </template>
            <span v-translate>
              Document storage is part of our paid plans.
            </span>
            <Space></Space>
            <span v-translate>
              SignRequest automatically deletes finished documents.
            </span>
            <Space></Space>
            <span v-translate>
              Upgrade now for the full SignRequest experience.
            </span>
          </v-tooltip>
        </div>
        <div class="document--item double-line-height">
          <div class="document--icon">
            <i
              v-if="doc.nr_extra_docs === 0"
              class="icon icon--document--25"
              aria-hidden="true"
            ></i>
            <i
              v-if="doc.nr_extra_docs"
              class="icon icon--document-stack--25"
              aria-hidden="true"
            ></i>
          </div>
          <div
            class="document--info sr-layout sr-layout--flush"
            @click="openDocument(doc)"
          >
            <div class="sr-layout__item three-quarters cap-overflow">
              <span class="cap-overflow__item">
                <strong dir="auto">{{ doc.name }}</strong>
                <br />
                <span class="status lighter-font" :class="statusClass(doc)">
                  {{ doc.status_display }}
                </span>
              </span>
            </div>
            <div class="sr-layout__item one-quarter align--right cap-overflow">
              <span class="status pull-right lighter-font cap-overflow__item">
                <span class="pull-right">
                  {{ getFirstSignerDisplay(doc) }}
                </span>
                <br />
                <span class="status pull-right lighter-font">
                  {{ doc.created | date }}
                </span>
              </span>
            </div>
          </div>
          <div class="document--checkbox">
            <div class="checkbox">
              <label :for="`input--delete-doc--${index}`">
                <input
                  :id="`input--delete-doc--${index}`"
                  :checked="value.includes(doc.uuid)"
                  :value="doc.uuid"
                  name="delete"
                  type="checkbox"
                  class="warning"
                  @change="handleDocumentSelect"
                />
                <span></span>
              </label>
            </div>
          </div>
        </div>

        <div
          v-for="extra_doc in doc.extra_docs"
          :key="extra_doc.uuid"
          class="document--item triple-line-height"
        >
          <div class="document--icon">
            <div class="placeholder--25"></div>
          </div>
          <div class="document--info sr-layout sr-layout--flush">
            <div class="sr-layout__item three-quarters cap-overflow">
              <span class="cap-overflow__item  lighter-font">
                {{ extra_doc.name }}
                <br />
                <span
                  class="status lighter-font"
                  :class="statusClass(extra_doc)"
                >
                  {{ extra_doc.status_display }}
                </span>
              </span>
            </div>
          </div>
          <br />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import permissions from '@/mixins/permissions';
import { mapActions, mapGetters } from 'vuex';
import Space from './Space';
import location from '@/utils/location';

export default {
  components: { Space },
  mixins: [permissions],
  props: {
    documents: { type: Array, required: true },
    value: { type: Array, required: true }
  },
  computed: { ...mapGetters('users', ['canDownloadDocuments']) },
  methods: {
    ...mapActions('events', ['trackDocumentStorageEvent']),
    handleDocumentSelect(event) {
      const { target } = event;
      let selectedDocs;
      if (target.checked) {
        selectedDocs = this.value.concat(target.value);
      } else {
        selectedDocs = [...this.value].filter(uuid => uuid !== target.value);
      }

      this.$emit('input', selectedDocs);
    },
    getFirstSignerDisplay(doc) {
      const signers = _.filter(doc.signer_emails, function(email) {
        return email !== doc.from_email;
      });
      if (signers.length === 0) {
        return this.$gettext('Only me');
      } else if (signers.length > 1) {
        const count = signers.length - 1;
        return signers[0] + ' (+' + count.toString() + ')';
      } else {
        return signers[0];
      }
    },
    statusClass(doc) {
      if (typeof doc === 'object') {
        const docStatus = doc.status.code || doc.status;
        switch (docStatus) {
          case 'si': // signed
          case 'do': // downloaded
          case 'sd': // signed and downloaded
            return 'status--success';
          case 'de': // declined
          case 'ca': // cancelled
          case 'ec': // error convert
          case 'es': // error sending
          case 'xp': // expired
            return 'status--error';
          default:
            return 'status--waiting';
        }
      }
    },
    openDocument(doc) {
      const url = `#/document-status/${doc.uuid}`;
      if (doc.locked && !this.canDownloadDocuments) {
        this.showLoginOrSubscribeModal({ feature: 'download_document' });
        this.trackDocumentStorageEvent(
          'Document Locked Subscribe Modal Opened'
        );
      } else {
        location.assign(url);
      }
    }
  }
};
</script>
<style scoped lang="scss">
.locked-item {
  background: rgba(255, 255, 255, 0.8);
  color: setting(colors, font-dark);
  cursor: pointer;
  height: 85%;
  padding-top: $b/2;
  position: absolute;
  text-align: center;
  transition: border 0.2s, color 0.2s, background 0.2s;
  width: 100%;
}

.doc-search-list {
  cursor: pointer;
}
.document--item {
  display: flex;
  padding: 0;
  justify-content: space-between;
  align-items: center;
}
.document--info {
  flex-grow: 1;
  margin: 0 1.5rem;
  max-width: calc(100% - 5.5rem);
}
.list-group__item {
  display: inline-block;
}
</style>
