<template>
  <div
    @dragleave.prevent="onDragleave"
    @dragenter.prevent="onDragenter"
    @dragover.prevent="onDragover"
    @drop.prevent="onDrop"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    value: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false }
  },
  data() {
    return {
      level: 0
    };
  },
  methods: {
    updateModel() {
      this.$emit('input', this.level > 0);
    },
    async onDragenter(event) {
      if (this.disabled) return;

      this.level += 1;
      this.updateModel();
    },
    onDragover(event) {
      if (this.disabled) return;

      if (event.dataTransfer) {
        event.dataTransfer.dropEffect = 'copy';
      }
    },
    onDrop(event) {
      if (this.disabled) return;

      this.level = 0;
      this.updateModel();

      const files = event.dataTransfer ? event.dataTransfer.files : null;
      const file = files ? files[0] : null;

      if (file) {
        this.$emit('file', file);
      }
      this.$emit('files', Array.prototype.slice.call(files));
    },
    onDragleave(event) {
      if (this.disabled) return;

      this.level -= 1;
      this.updateModel();
    }
  }
};
</script>
