<script>
import permissions from '@/mixins/permissions';
import { mapActions, mapGetters } from 'vuex';
import SrFileSelect from '../elements/SrFileSelect';
export default {
  mixins: [permissions],
  computed: {
    ...mapGetters('users', ['canUploadManyFiles']),
    ...mapGetters('signrequest', ['allIsPermittedForDocument'])
  },
  methods: {
    ...mapActions('signrequest', ['fromFileList']),
    handleFiles(files) {
      const canDo = files.length === 1 || this.canUploadManyFiles;
      if (canDo || this.allIsPermittedForDocument) {
        this.fromFileList(files);
      } else {
        this.showLoginOrSubscribeModal({ feature: 'multi_doc' });
      }
    }
  },
  render(h) {
    return h(
      SrFileSelect,
      { attrs: this.$attrs, on: { files: this.handleFiles } },
      this.$slots.default
    );
  }
};
</script>
