import Vue from 'vue';
import angular from 'angular';

/**
 * @ngdoc function
 * @name frontendApp.controller:AcceptInviteCtrl
 * @description
 * # AcceptInviteCtrl
 * Controller of the frontendApp
 */
angular.module('frontendApp').controller('AcceptInviteCtrl', [
  '$scope',
  '$location',
  '$route',
  'UserService',
  '$vuex',
  async function($scope, $location, $route, UserService, $vuex) {
    $scope.loading = true;

    function flush(timeout = 100) {
      return new Promise(resolve => setTimeout(resolve, timeout));
    }

    const $gettext = msgid => Vue.prototype.$gettext(msgid);

    await $vuex.dispatch('users/getUser', { anyUser: true }, { root: true });

    const resp = await $vuex.dispatch('users/acceptInvite', {
      token: $route.current.params.token,
      uuid: $route.current.params.uuid,
      silent: true,
      silentError: true,
      expectCodes: [404]
    });

    $location.path('/');

    if (!resp.user_logged_in && resp.status === 'SUCCESS') {
      $vuex.dispatch(
        'modals/showLoginModal',
        {},
        { doRegister: !resp.has_account }
      );
      await flush();
    }

    if (resp.status === 'SUCCESS') {
      for (let msg of resp.messages) {
        $vuex.dispatch('messages/addMessage', {
          type: 'success',
          msg,
          timeout: 7000
        });
      }
    } else {
      // server returned a 404
      // which, surprise, is not a status=error

      $vuex.dispatch('messages/addMessage', {
        type: 'error',
        msg: $gettext('Invite expired, please contact the team owner'),
        timeout: 3000
      });
    }
  }
]);
