<template>
  <div>
    <div v-if="type === 'signature'" class="ph-text--signature-label">
      <signature />
    </div>
    <sr-checkbox
      v-else-if="type === 'checkbox'"
      :label="label"
      editable
      class="ph-text--checkbox-label"
    />
    <span
      v-else
      :class="['ph-text--label', { 'ph-text--standard-label': isStandard }]"
    >
      {{ label }}
    </span>
  </div>
</template>
<script>
import Signature from '@/components/document/Signature';
import SrCheckbox from './SrCheckbox';

export default {
  components: {
    SrCheckbox,
    Signature
  },
  props: {
    label: { type: String, required: true },
    isStandard: { type: Boolean, required: false, default: false },
    type: { type: String, default: 'signature' }
  }
};
</script>
<style scoped>
.ph-text--label.ph-text--standard-label::after {
  content: '…';
}
.ph-text--label {
  font-size: 16px;
}
.ph-outer--small .ph-text--label {
  font-size: 12px;
}
.ph-text--checkbox-label {
  height: 100%;
}
.ph-text--signature-label,
.ph-text--signature-label svg {
  display: block;
  width: 100%;
  height: 100%;
}
</style>
