<template>
  <div>
    <vue-phone-number-input
      :value="userValue"
      :preferred-countries="preferredCountries"
      no-validator-state
      no-use-browser-locale
      :translations="translations"
      @update="handleUpdate"
    />
    <slot name="append-outer" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import VuePhoneNumberInput from 'vue-phone-number-input';

export default {
  components: { VuePhoneNumberInput },
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: { type: String, required: false, default: '' }
  },
  data() {
    return { userValue: this.$props.value || null };
  },

  computed: {
    translations() {
      return Object.freeze({
        countrySelectorLabel: this.$gettext('Country code'),
        countrySelectorError: this.$gettext('Choose country'),
        phoneNumberLabel: this.$gettext('Phone number'),
        example: this.$gettext('Example:')
      });
    },
    preferredCountries() {
      return [
        this.countryISOCode,
        'us',
        'gb',
        'nl',
        'de',
        'es',
        'fr',
        'cn',
        'ru',
        'in',
        'id',
        'br'
      ]
        .filter(code => code)
        .map(code => code.toUpperCase());
    }
  },
  methods: {
    handleUpdate({ nationalNumber, phoneNumber, formattedNumber }) {
      this.userValue = nationalNumber || phoneNumber;
      const newValue = formattedNumber || phoneNumber || null;
      if (newValue === this.value) {
        return;
      }

      this.$emit('input', newValue);
    }
  }
};
</script>
<style scoped>
div {
  padding-top: 12px;
}
</style>
