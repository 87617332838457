<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32px"
    height="35px"
    viewBox="-1 0 32 35"
  >
    <g
      fill="none"
      fill-rule="evenodd"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    >
      <path d="M14 33s13-6.4 13-16V4.2L14 1 1 4.2V17c0 9.6 13 16 13 16z" />
      <path d="M21.755 11.08l-9.014 9.6-5.079-5.09" />
    </g>
  </svg>
</template>
<script>
export default {};
</script>
