<template>
  <div class="login-page-container" :class="{ mobile: $isMobile }">
    <div class="login-page-well">
      <Login
        :modal-options="{ doRegister, next }"
        :use-router-links="true"
      ></Login>
    </div>
  </div>
</template>

<script>
import Login from '@/components/login/Login';
import Screen from '@/mixins/Screen';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: { Login },
  mixins: [Screen],
  props: {
    doRegister: { type: Boolean, default: true }
  },
  data: function() {
    return {
      next: undefined
    };
  },
  computed: mapGetters('users', ['userLoggedIn']),
  beforeMount() {
    if (this.$route.query.open_subscribe) {
      const queryString = new URLSearchParams(this.$route.query).toString();
      this.next = `/#/?${queryString}`;
    }
    if (this.$route.params.nextUrl) {
      this.next = `/#${this.$route.params.nextUrl}`;
    }
  },
  async mounted() {
    await this.getUser();

    if (this.userLoggedIn) {
      if (this.$route.query.open_subscribe) {
        const queryString = new URLSearchParams(this.$route.query).toString();
        return this.navigateTo(`/?${queryString}`);
      }

      this.addMessage({
        type: 'success',
        msg: this.$gettext('You are already logged in')
      });

      return this.navigateTo('/');
    }

    const shownEventProps = {
      is_modal: false
    };

    if (this.doRegister) {
      this.trackRegisterFormShownEvent(shownEventProps);
    } else {
      this.trackLoginFormShownEvent(shownEventProps);
    }
  },
  methods: {
    ...mapActions('signrequest', ['navigateTo']),
    ...mapActions('events', [
      'trackLoginFormShownEvent',
      'trackRegisterFormShownEvent'
    ]),
    ...mapActions('messages', ['addMessage']),
    ...mapActions('users', ['getUser'])
  }
};
</script>

<style lang="scss" scoped>
.login-page-container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* compensate for header */
  margin-top: -64px;
  min-height: 99vh;
}
.mobile.login-page-container {
  margin-top: 0;
  min-height: calc(100vh - 56px);
  align-items: stretch;
}
.login-page-well {
  flex-basis: 32.5rem;
  display: inline-block;
  border-radius: 15px;
  box-shadow: -2px 6px 44px 10px rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
}
.mobile .login-page-well {
  min-width: unset;
  max-height: unset;
  border-radius: 0;
}
</style>

<style lang="scss">
body.RegisterCtrl {
  background-color: #f7f9fe;
  header {
    .sr-container {
      max-width: 100%;
      margin: 0 !important;
    }
    .app-header--usermenu {
      display: none;
    }
  }
  footer.site__footer {
    display: none;
  }
  .site__header.mobile ~ .site__main {
    margin-top: 0;
    margin-bottom: 0;
    height: 100%;
  }
}
</style>
