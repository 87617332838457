import angular from 'angular';
import _ from 'lodash';

/**
 * @ngdoc function
 * @name frontendApp.controller:SalesforceController
 * @description
 * # LegalityCtrl
 * Controller of the frontendApp
 */
angular.module('frontendApp').controller('SalesforceController', [
  '$scope',
  '$rootScope',
  '$route',
  '$controller',
  '$window',
  '$location',
  'UserService',
  'salesforceService',
  'gettextCatalog',
  'docService',
  'utils',
  function(
    $scope,
    $rootScope,
    $route,
    $controller,
    $window,
    $location,
    UserService,
    salesforceService,
    gettextCatalog,
    docService,
    utils
  ) {
    $scope.isSalesforce = true;
    $controller('MainCtrl', { $scope: $scope });
    if ($scope.isVueHomebox) {
      return;
    }
    $scope.selectedDoc = { selected: undefined };
    if (
      angular.isDefined($route.current.params.objectId) &&
      angular.isDefined($route.current.params.objectType)
    ) {
      $scope.signrequestID = $route.current.params.objectId;
    }
    $scope.prefill = false;
    $scope.prefill_select = false;
    if (angular.isDefined($route.current.params.prefilled_signers)) {
      $scope.prefilled_signers = utils.parseObjectFromEncodedUrlParam(
        $route.current.params.prefilled_signers
      );
      $scope.prefilled_signers.forEach(function(user) {
        if (user.email) {
          user.value = user.email;
          user.text = user.text || user.email;
        } else if (user.object_id) {
          $scope.prefill_select = true;
          $scope.prefill_selected = {};
          $scope.prefill_selected.selected = '';
        }
      });
      $scope.prefill = true;
    }

    if ($route.current.params.msg) {
      $scope.signrequest.message = utils.parseObjectFromEncodedUrlParam(
        $route.current.params.msg
      );
    }

    if ($route.current.params.subject) {
      $scope.signrequest.subject = utils.parseObjectFromEncodedUrlParam(
        $route.current.params.subject
      );
    }

    $scope.isSandbox = false;
    if ($route.current.params.referrer) {
      $scope.isSandbox = /(\.|--|^)cs\d{1,3}\./.test(
        utils.parseUrl($route.current.params.referrer).hostname
      );
    }

    $scope.isSandbox = $scope.isSandbox || !!$route.current.params.sandbox;

    if ($route.current.params.newdoconly) {
      $scope.hideOldDocs = true;
    }

    let community;
    if ($route.current.params.community) {
      community = utils.redirectFilter($route.current.params.community);
      if (community.endsWith('/')) {
        community = community.slice(0, -1);
      }
    }

    if ($route.current.params.prefill_tags) {
      $scope.prefill_tags = utils.parseObjectFromEncodedUrlParam(
        $route.current.params.prefill_tags
      );
    }

    if (
      $route.current.params.fileType &&
      $route.current.params.fileType != ''
    ) {
      $scope.file_type = $route.current.params.fileType;
    }

    if ($route.current.params.auto_select) {
      $scope.auto_select =
        $route.current.params.auto_select === '1' ? true : false;
    }

    if ($route.current.params.select_all_docs) {
      $scope.select_all_docs = $route.current.params.select_all_docs;
    }

    //      if ($route.current.params.who){
    //        $scope.signrequest = $route.current.params.who;
    //      }

    $scope.integration = 'salesforce';

    $scope.customClassHolder = '';

    $scope.directSend = function() {
      var params = $route.current.params;

      salesforceService
        .postDocId(params.docId, params.filename, $scope.signrequestID)
        .then(function(response) {
          $route.current.params.doc_uuid = response.data.uuid;
          $scope.updateDocuments();
        });

      ////// SignRequest //////

      $scope.signrequest.who = params.who;
      $scope.signrequest.to = params.to;
      $scope.signrequest.doc = $scope.doc;
      $scope.signrequest.from_email = $scope.user.email;

      docService.doSignRequest($scope.signrequest).then(function(response) {
        if (response.data.status === 'SUCCESS') {
          $scope.resetBox();
        }

        var openInNewWindow = true;

        $scope.user.agreed_to_terms = true;
        if (response.data.redirect_url) {
          if (angular.isDefined(openInNewWindow) && openInNewWindow) {
            $window.open('/#/' + response.data.redirect_url);
            $location.path('/salesforce/done');
          } else {
            $location.path(response.data.redirect_url); // we redirect the owner to sign himself
          }

          if (response.data.force_reload) {
            // user reload needed
            $window.location.reload();
          }
        } else {
          $scope.updateDocuments();
        }
      });
    };

    $scope.processUser = function(callback) {
      $scope.loading = true;
      $scope.signer_uuid = $route.current
        ? $route.current.params.signer_uuid
        : null;

      UserService.waitForTracked().then(function() {
        $scope.user.contacts = $scope.user.contacts || [];
        $scope.loading = false;
        if (angular.isFunction(callback)) {
          callback();
        }
      });
    };

    $scope.step = $route.current.params.step;
    $scope.showFilesPicker = true;
    $scope.loadingData = true;

    $scope.processUser(function() {
      var sfSocialAuth = _.find($scope.user.social_auth, function(item) {
        if (
          (item.backend === 'salesforce-oauth2' && !$scope.isSandbox) ||
          (item.backend === 'salesforce-oauth2-sandbox' && $scope.isSandbox) ||
          (item.backend === 'salesforce-oauth2-community' && $scope.isSandbox)
        ) {
          if (angular.isDefined($route.current.params.userId)) {
            return item.uid.indexOf($route.current.params.userId) > -1;
          } else {
            return true;
          }
        }
        return false;
      });
      if (
        !$scope.user.logged_in ||
        !angular.isDefined(sfSocialAuth) ||
        sfSocialAuth === undefined
      ) {
        // we need a salesforce social user
        let sandbox = $scope.isSandbox ? '-sandbox' : '';
        let url;
        if (community) {
          url = new URL(
            `/integrations/salesforce/login/salesforce-oauth2${sandbox}/`,
            location
          );
          url.searchParams.set('community', community);
        } else {
          url = new URL(
            `/user/social/login/salesforce-oauth2${sandbox}/`,
            location
          );
        }
        url.searchParams.set('next', `/${$window.location.hash}`);
        $window.location.assign(url);
      } else {
        // redirect to default team if needed
        if (
          $scope.user.logged_in &&
          $scope.user.default_team !== 'personal' &&
          $window.location.href.indexOf($scope.user.default_team) === -1 &&
          $window.location.href.indexOf('https://signrequest.com') === 0
        ) {
          const teamHostname = `${$scope.user.default_team}.signrequest.com`;
          $window.location.href = utils.replaceHostname(
            $window.location.href,
            teamHostname
          );
        } else if (
          angular.isDefined($route.current.params.objectId) &&
          angular.isDefined($route.current.params.objectType)
        ) {
          $scope.signrequest.integration_data = {
            name: 'salesforce',
            object_type: $route.current.params.objectType,
            object_id: $route.current.params.objectId,
            uid: sfSocialAuth.uid,
            get_params: $route.current.params,
            is_sandbox: $scope.isSandbox,
            prefill_tags: $scope.prefill_tags
          };
          var customObjectData;
          if (
            angular.isDefined($route.current.params.accountApiName) &&
            angular.isDefined($route.current.params.contactApiName)
          ) {
            customObjectData = {
              name: $route.current.params.objectType,
              accountApiName: $route.current.params.accountApiName,
              contactApiName: $route.current.params.contactApiName
            };
          }
          if ($scope.prefill && !$scope.prefill_select) {
            $scope.user.contacts = $scope.prefilled_signers;
          }
          $scope.object_id_list = $route.current.params.object_id_list;
          $scope.object_id_list_type =
            $route.current.params.object_id_list_type;
          $scope.attachment_id_list = $route.current.params.attachment_id_list;

          var oppdata = salesforceService.getDataFromId(
            $route.current.params.objectId,
            $route.current.params.objectType,
            sfSocialAuth.uid,
            customObjectData,
            $scope.object_id_list,
            $scope.object_id_list_type,
            $scope.attachment_id_list
          );
          $scope.documents = [];

          oppdata.then(
            function(data) {
              $scope.oppdata = data;
              $scope.all_contacts =
                data.data.contacts_from_sf + $scope.user.contacts;
              $scope.user.contacts = $scope.all_contacts;
              $scope.prefill_select_options = data.data.contacts_from_sf;

              if (
                (angular.isDefined($scope.file_type) &&
                  $scope.file_type === 'Attachments') ||
                !angular.isDefined($scope.file_type)
              ) {
                angular.forEach(data.data.attachments, function(attachment) {
                  var att = {};
                  att.text = attachment.Name;
                  att.value = attachment.Id;
                  att.docutype = 'Attachment';
                  $scope.documents.push(att);
                });

                angular.forEach(data.data.quotes, function(quote) {
                  var att = {};
                  att.text = quote.Name;
                  att.value = quote.Id;
                  att.docutype = 'QuoteDocument';
                  $scope.documents.push(att);
                });

                angular.forEach(data.data.documents, function(docu) {
                  var doc = {};
                  doc.text = docu.Name;
                  doc.value = docu.Id;
                  doc.docutype = 'Document';
                  $scope.documents.push(doc);
                });
              }

              if (
                (angular.isDefined($scope.file_type) &&
                  $scope.file_type == 'Files') ||
                !angular.isDefined($scope.file_type)
              ) {
                angular.forEach(data.data.files, function(file) {
                  var fil = {};
                  fil.text = file.Name;
                  fil.value = file.Id;
                  fil.docutype = 'File';
                  $scope.documents.push(fil);
                });
              }

              if (
                angular.isDefined($scope.object_id_list_type) &&
                $scope.object_id_list_type == 'HR2Day'
              ) {
                angular.forEach(data.data.attachments, function(file) {
                  var fil = {};
                  fil.text = file.Name;
                  fil.value = file.Id;
                  fil.docutype = 'Attachment';
                  fil.description = file.Description;
                  $scope.documents.push(fil);
                });
              }

              $scope.from_email = data.data.from_email;
              if ($scope.documents.length === 1) {
                if ($scope.auto_select === true) {
                  $scope.selectedDoc.selected = $scope.documents[0].value;
                }
              }
              if (
                angular.isDefined($scope.select_all_docs) &&
                $scope.select_all_docs
              ) {
                $scope.selectedDoc.selected = $scope.documents[0].value;
              }

              if ($scope.prefill && !$scope.prefill_select) {
                $scope.signrequest.who = $route.current.params.who || 'mo';
                $scope.prefilled_signers.forEach(function(user) {
                  if (!$scope.signrequest.signers) {
                    $scope.signrequest.signers = user.email;
                  } else {
                    $scope.signrequest.signers += ',' + user.email;
                  }
                  if (user.email === $scope.signrequest.from_email) {
                    $scope.signrequest.signer_options[
                      _.findIndex($scope.signrequest.signer_options, {
                        email: user.email
                      })
                    ] = _.extend(
                      _.find($scope.signrequest.signer_options, {
                        email: user.email
                      }),
                      user
                    );
                  } else {
                    $scope.signrequest.signer_options.push(user);
                  }
                });
              }

              if ($route.current.params.send_reminders) {
                $scope.signrequest.send_reminders = Number(
                  $route.current.params.send_reminders
                );
              }
              $scope.loadingData = false;
            },
            function() {
              // error
              $scope.loadingData = false;
            }
          );
        }
      }
    });

    $scope.$watch('prefill_selected.selected', function(new_val, old_val) {
      if (new_val !== old_val) {
        if ($scope.prefill_select) {
          $scope.signrequest.who = $route.current.params.who || 'mo';
          $scope.prefill_select = false;
          var emails = undefined;
          $scope.prefilled_signers.forEach(function(user) {
            if (user.object_id) {
              user.email = new_val;
              user.text = new_val;
              user.value = new_val;
              user.object_id = undefined;
            }
            $scope.user.contacts.push(user);
            $scope.signrequest.signer_options.push(user);
            if (!emails) {
              emails = user.email;
            } else {
              emails += ',' + user.email;
            }
          });
          $scope.signrequest.signers = emails;
          if (angular.isDefined($scope.signrequest.signers)) {
            var integrationSigners = [];
            angular.forEach(
              utils.parseValues($scope.signrequest.signers),
              function(email) {
                integrationSigners.push(
                  _.find($scope.oppdata.data.contacts_from_sf, { value: email })
                );
              }
            );
            $scope.signrequest.integration_data.signers = integrationSigners;
          }
        }
      }
    });

    $scope.$watch('signrequest.signers', function(new_val, old_val) {
      if (new_val !== old_val) {
        $scope.user.contacts = $scope.all_contacts;
        if (angular.isDefined($scope.signrequest.signers)) {
          var integrationSigners = [];
          angular.forEach(
            utils.parseValues($scope.signrequest.signers),
            function(email) {
              integrationSigners.push(
                _.find($scope.user.contacts, { value: email })
              );
            }
          );
          $scope.signrequest.integration_data.signers = integrationSigners;
        }
        $scope.integration_data = $scope.signrequest.integration_data;
      }
    });

    $scope.$watch('doc', function(doc, oldDoc) {
      if (!doc && oldDoc) {
        // if we had a document selected but deleted it; reset the file picker.
        $scope.selectedDoc.selected = undefined;
      }
      if (doc !== oldDoc) {
        // manually uploaded doc, we need to show it
        $scope.hideOldDocs = false;
      }
    });

    $scope.$watchCollection('selectedDoc.selected', function(doc) {
      if (angular.isDefined(doc) && $scope.selectedDoc.selected) {
        $scope.hideFilesPicker = true;
        $scope.hideOldDocs = false;
        $scope.filename = _.find($scope.documents, {
          value: $scope.selectedDoc.selected
        }).text;
        $scope.description = _.find($scope.documents, {
          value: $scope.selectedDoc.selected
        }).description;

        $scope.signrequest.integration_data.sf_doc_id =
          $scope.selectedDoc.selected;

        var docutype = _.find($scope.documents, { value: doc }).docutype;
        salesforceService
          .postDocId(
            $scope.selectedDoc.selected,
            docutype,
            $scope.filename,
            $route.current.params.objectType,
            $route.current.params.objectId,
            $scope.signrequest.integration_data.uid,
            $scope.select_all_docs ? $scope.documents : [],
            $route.current.params.object_id_list_type === 'HR2Day',
            $scope.description
          )
          .then(function(response) {
            $route.current.params.doc_uuid = response.data.uuid;
            $scope.updateDocuments();
          });
      }
    });
  }
]);
