import angular from 'angular';

/**
 * @ngdoc service
 * @name frontendApp.InvoiceService
 * @description
 * # InvoiceService
 * Service in the frontendApp.
 */
angular.module('frontendApp').service('InvoiceService', [
  '$http',
  'messageService',
  function InvoiceService($http, messageService) {
    this.getInvoices = function() {
      return $http.get('/orders/invoices/get-invoices').then(
        function(resp) {
          return messageService.handleResponse(resp);
        },
        function(resp) {
          return messageService.handleError(resp);
        }
      );
    };
  }
]);
