// this HTML parser is not real
function parse(data) {
  let idx = 0;
  let start = 0;
  let mode = 'text';
  let tag = null;
  const ret = [];
  while (idx < data.length) {
    const cur = data[idx];
    if (cur === '<') {
      ret.push({ tag, text: data.slice(start, idx) });
      start = idx + 1;
      mode = 'tag';
    } else if (cur === '/' && mode === 'tag') {
      mode = 'close';
    } else if (cur === '>' && mode === 'tag') {
      tag = data.slice(start, idx);
      start = idx + 1;
      mode = 'text';
    } else if (cur === '>' && mode === 'close') {
      tag = null;
      start = idx + 1;
      mode = 'text';
    }
    idx++;
  }
  if (idx > start) {
    ret.push({ tag, text: data.slice(start, idx) });
  }
  return ret;
}

export default {
  props: {
    message: { type: String, required: true }
  },
  render(h) {
    const makeSlot = (tag, text) => {
      const slot = this.$scopedSlots[tag];
      if (!slot) {
        throw Error(`Slot does not exist in template: "${tag}"`);
      }
      return slot({ text });
    };

    return h('span', { on: this.$listeners }, [
      parse(this.message).map(({ text, tag }) => {
        return tag ? makeSlot(tag, text) : h('span', {}, [text]);
      })
    ]);
  }
};
