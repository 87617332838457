import angular from 'angular';

/**
 * @ngdoc service
 * @name frontendApp.billingservice
 * @description
 * # userservice
 * Service in the frontendApp.
 */
angular.module('frontendApp').service('BillingService', [
  '$http',
  'messageService',
  function BillingService($http, messageService) {
    this.getBillingDetails = function() {
      return $http.get('/orders/billing/get-or-set-billing-details').then(
        function(resp) {
          return messageService.handleResponse(resp);
        },
        function(resp) {
          return messageService.handleError(resp);
        }
      );
    };

    this.getSubscriptionDetails = function() {
      return $http.get('/orders/billing/get-or-set-subscription-details').then(
        function(resp) {
          return messageService.handleResponse(resp);
        },
        function(resp) {
          return messageService.handleError(resp);
        }
      );
    };

    this.getBundlePrice = function(amount, bundle_type) {
      return $http
        .get(
          '/orders/billing/get-bundle-price?bundle_size=' +
            amount +
            '&bundle_type=' +
            bundle_type
        )
        .then(
          function(resp) {
            return messageService.handleResponse(resp);
          },
          function(resp) {
            return messageService.handleError(resp);
          }
        );
    };

    this.buyBundle = function(amount, bundle_type) {
      return $http
        .post('/orders/billing/buy-bundle', {
          bundle_type: bundle_type,
          amount: amount
        })
        .then(
          function(resp) {
            return messageService.handleResponse(resp);
          },
          function(resp) {
            return messageService.handleError(resp);
          }
        );
    };

    this.setSubscriptionDetails = function(
      plan_type,
      quantity,
      interval,
      trial_days,
      bundle
    ) {
      quantity = quantity || 1;
      interval = interval || 'month';
      bundle = bundle || 0;
      return $http
        .post('/orders/billing/get-or-set-subscription-details', {
          plan_type: plan_type,
          quantity: quantity,
          interval: interval,
          trial_days: trial_days,
          bundle: bundle
        })
        .then(
          function(resp) {
            return messageService.handleResponse(resp);
          },
          function(resp) {
            return messageService.handleError(resp);
          }
        );
    };

    const handleSilentResponse = (resp, ok_silent) => {
      let data = resp.data;
      const status = data.status ? data.status.toLowerCase() : 'success';
      if (ok_silent && status === 'success') {
        return resp;
      }
      return messageService.handleResponse(resp);
    };

    this.setBillingDetails = function(billing_details, ok_silent) {
      ok_silent = ok_silent || false;
      return $http
        .post('/orders/billing/get-or-set-billing-details', billing_details)
        .then(
          function(resp) {
            return handleSilentResponse(resp, ok_silent);
          },
          function(resp) {
            return messageService.handleError(resp);
          }
        );
    };

    this.getCountryOptions = function() {
      return $http.get('/orders/billing/get-country-options').then(
        function(resp) {
          return messageService.handleResponse(resp);
        },
        function(resp) {
          return messageService.handleError(resp);
        }
      );
    };

    this.getPlanOptions = function(quote_plan, quote_quantity, interval) {
      var params = {};
      if (quote_plan && quote_quantity) {
        params = {
          quote: quote_plan,
          quantity: quote_quantity,
          interval: interval
        };
      }
      return $http
        .get('/orders/billing/get-plan-options', { params: params })
        .then(
          function(resp) {
            return messageService.handleResponse(resp);
          },
          function(resp) {
            return messageService.handleError(resp);
          }
        );
    };

    this.updateCard = function(stripe_token, ok_silent) {
      ok_silent = ok_silent || false;
      return $http
        .post('/orders/billing/update-card', { stripe_token: stripe_token })
        .then(
          function(resp) {
            return handleSilentResponse(resp, ok_silent);
          },
          function(resp) {
            return messageService.handleError(resp);
          }
        );
    };

    this.updateBankAccount = function(
      stripe_token,
      new_bank_account,
      ok_silent
    ) {
      ok_silent = ok_silent || false;
      return $http
        .post('/orders/billing/update-bank-account', {
          stripe_token: stripe_token,
          new_bank_account: new_bank_account
        })
        .then(
          function(resp) {
            let data = resp.data;
            var status = data.status ? data.status.toLowerCase() : 'success';
            if (ok_silent && status === 'success') {
              return resp;
            }
            return messageService.handleResponse(resp);
          },
          function(resp) {
            return messageService.handleError(resp);
          }
        );
    };

    //
    // this.checkVatId = function (vat_id) {
    //   return $http.post('/orders/billing/check-vat-id', {vat_id: vat_id})
    //     .then(function (resp){
    //       return messageService.handleResponse(resp);
    //     },function (resp){
    //       return messageService.handleError(resp);
    //     });
    // };
  }
]);
