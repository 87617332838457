<template>
  <AccountScreen v-if="isEnabled" key="teams" :title="$gettext('Teams')">
    <CheckLogin>
      <Team :create-sandbox="sandbox" />
    </CheckLogin>
  </AccountScreen>
</template>
<script>
import Vue from 'vue';
import AccountScreen from '@/components/AccountScreen.vue';
import CheckLogin from '@/components/CheckLogin.vue';
import Team from '@/components/team/Team.vue';
export default Vue.extend({
  components: { AccountScreen, CheckLogin, Team },
  computed: {
    isEnabled() {
      return this.$route.query.new_teams !== 'no';
    },
    sandbox() {
      return this.$route.query.sandbox === 'true';
    }
  }
});
</script>
