<template>
  <div :class="{ mobile: $vuetify.breakpoint.smAndDown }">
    <slot />
  </div>
</template>

<script>
/* This does nothing, yet. */
export default {};
</script>
