<template>
  <v-card-text class="v-card__text__buttons">
    <sr-button sr-style="simple" @click="$emit('closeModal')">
      <translate>Cancel</translate>
    </sr-button>
    <sr-button type="submit" :disabled="!formValid">
      <translate>Send</translate>
    </sr-button>
  </v-card-text>
</template>
<script>
import SrButton from '@/components/elements/SrButton.vue';
export default {
  components: {
    SrButton
  },
  props: {
    formValid: { type: Boolean, default: false }
  }
};
</script>
<style scoped>
/deep/ .sr-button {
  height: 34px !important;
}
.v-card__text.v-card__text__buttons {
  display: flex;
  justify-content: flex-end;
}
.v-card__text.v-card__text__buttons button:last-of-type {
  margin-right: 0;
}
.signrequest-lib .v-card__text__buttons::before {
  content: none;
}
</style>
