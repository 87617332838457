import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSlideYTransition } from 'vuetify/lib/components/transitions';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VForm,{staticClass:"homebox--details-form",class:[_vm.showHelp && 'form-show-help'],attrs:{"data-test-id":"signer-details","autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.$emit('submit')}}},[_c('homebox-screen',{attrs:{"title-divider":true}},[_c(VSlideYTransition,{attrs:{"origin":"top right 0"}},[_c(VContainer,{staticClass:"pa-0 details-container"},[_c('h2',[_c('sr-button',{attrs:{"inline":"","sr-style":"text","title":_vm.$gettext('Close Recipient Panel')},on:{"click":_vm.handleClose}},[_c('circle-back-icon')],1),_c('translate',[_vm._v("Modify Recipient")])],1),_c('div',{staticClass:"h-section"},[(_vm.template)?_c('sr-input',{ref:"label",attrs:{"name":"label","type":"text","label":_vm.$gettext('Recipient Name'),"placeholder":_vm.namePlaceholder,"suppress-first-error":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('gettext-slots',{attrs:{"message":_vm.$gettext(
                    '<strong>Recipient Name</strong> - (or placeholder name)'
                  )},scopedSlots:_vm._u([{key:"strong",fn:function(ref){
                  var text = ref.text;
return [_c('strong',[_vm._v(_vm._s(text))])]}}],null,false,2129123597)})]},proxy:true}],null,false,314198099),model:{value:(_vm.label),callback:function ($$v) {_vm.label=$$v},expression:"label"}}):_vm._e(),_c('sr-input',{ref:"email",attrs:{"name":"email","type":"email","autocomplete":"off","label":_vm.$gettext('Recipient Email'),"placeholder":_vm.$gettext('Email address, or search by name'),"readonly":!_vm.removable,"rules":[_vm.emailRule],"suppress-first-error":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [(_vm.template)?_c('gettext-slots',{attrs:{"message":_vm.$gettext(
                    '<strong>Recipient email</strong> - (leave blank for placeholder)'
                  )},scopedSlots:_vm._u([{key:"strong",fn:function(ref){
                  var text = ref.text;
return [_c('strong',[_vm._v(_vm._s(text))])]}}],null,false,2129123597)}):_vm._e()]},proxy:true}]),model:{value:(_vm.emailField),callback:function ($$v) {_vm.emailField=$$v},expression:"emailField"}})],1),_c('div',{staticClass:"h-section",on:{"click":_vm.handleProLevel}},[_c('box-signer-level',{attrs:{"name":"level","items":_vm.levels,"label":_vm.$gettext('Role'),"readonly":!(_vm.canChangeSignLevel || _vm.allIsPermittedForDocument)},model:{value:(_vm.level),callback:function ($$v) {_vm.level=$$v},expression:"level"}})],1),_c(VSlideYTransition,[(_vm.value.level !== 'cc' && _vm.value.level !== 'notify')?_c(VContainer,{staticClass:"pa-0"},[(_vm.canUseAttachments && _vm.isAttachmentsEnabled)?_c('box-toggle-field',{attrs:{"label":_vm.$gettext('Require Attachment'),"help":_vm.$gettext(
                  'Recipient will be asked to upload a document when signing.'
                )},model:{value:(_vm.required_attachments),callback:function ($$v) {_vm.required_attachments=$$v},expression:"required_attachments"}},[_c('box-attachments-field',{attrs:{"name":"required_attachments","readonly":!(_vm.canChangeRequireAttachment || _vm.allIsPermittedForDocument)},model:{value:(_vm.required_attachments),callback:function ($$v) {_vm.required_attachments=$$v},expression:"required_attachments"}})],1):_vm._e(),_c('box-toggle-field',{attrs:{"label":_vm.$gettext('Require 2-Factor Authentication'),"help":_vm.$gettext(
                  'Recipient will recieve an SMS text message to verify their identity before signing.'
                )},model:{value:(_vm.verify_phone_number),callback:function ($$v) {_vm.verify_phone_number=$$v},expression:"verify_phone_number"}},[_c('box-tel-input',{staticClass:"srtext--text",attrs:{"name":"verify_phone_number"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('help-hint',[_c('div',[_c('translate',[_vm._v("\n                        For additional security you can request your signers\n                        to also input a unique code they receive by a text\n                        message. (Additional fees apply.)\n                      ")]),_c('br'),_c('br'),_c('translate',[_vm._v("\n                        Important: this is not required and does not function\n                        as a notification that a SignRequest is sent by email.\n                      ")])],1)])]},proxy:true}],null,false,949381299),model:{value:(_vm.verify_phone_number),callback:function ($$v) {_vm.verify_phone_number=$$v},expression:"verify_phone_number"}})],1),(!_vm.me)?_c('box-toggle-field',{attrs:{"label":_vm.$gettext('Require Password'),"help":_vm.$gettext(
                  'Recipient must enter a password of your choice to access the documents.'
                )},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}},[_c('sr-input',{attrs:{"name":"password","type":"text","autocomplete":"new-password","placeholder":_vm.$gettext('Enter password'),"readonly":!(_vm.canChangePassword || _vm.allIsPermittedForDocument)},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('help-hint',[_c('div',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("\n                      As an extra verification measure you can add a password\n                      here. You should send this password separately to the\n                      signer, for example with a text message or during a\n                      phone call. The document contents will only be visible\n                      after the signer clicks on the link and enters the\n                      password.\n                    ")])])]},proxy:true}],null,false,2925330619),model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1):_vm._e()],1):_vm._e()],1),(_vm.removable)?_c('div',{staticClass:"h-section h-section__remove"},[_c('sr-button',{attrs:{"inline":"","sr-style":"text-danger","data-test-id":"remove"},on:{"click":function($event){return _vm.$emit('remove')}}},[_c('translate',[_vm._v("Remove Recipient")])],1)],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }