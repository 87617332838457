<template>
  <div v-if="documents.length > 0">
    <h3 v-translate>Documents that require your action</h3>

    <div class="list-group">
      <a
        v-for="doc in documents"
        :key="doc.uuid"
        :href="`#/document/${doc.uuid}/${doc.signer.uuid}/?next=/documents`"
        class="list-group__item"
      >
        <div class="flag">
          <div class="flag__object">
            <i class="icon icon--document--25" aria-hidden="true"></i>
          </div>
          <div class="flag__body">
            <p dir="auto">
              <strong>{{ doc.name }}</strong>
              <br />
              <span class="status">
                <a
                  :href="
                    `#/document/${doc.uuid}/${doc.signer.uuid}/?next=/documents`
                  "
                  class="button button--small button--line button--line--grey"
                >
                  <span v-if="!doc.signer.approve_only" v-translate>
                    Open document
                  </span>
                  <span v-if="doc.signer.approve_only" v-translate>
                    Approve now
                  </span>
                </a>
              </span>
            </p>
          </div>
        </div>
      </a>
    </div>
    <h3 v-if="documents" v-translate>All documents</h3>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'DocumentsRequiringAction',
  data: function() {
    return {
      docCount: null
    };
  },
  computed: {
    ...mapGetters('documents', ['requiredActionDocuments']),
    documents() {
      return this.requiredActionDocuments || [];
    }
  },
  created() {
    this.getRequiredActions();
  },
  methods: { ...mapActions('documents', ['getRequiredActions']) }
};
</script>
