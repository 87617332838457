<template>
  <div class="inline primary--text" tabindex="-1">
    <div
      class="ph-outer"
      :style="signerColorStyle"
      :class="[
        {
          'ph-outer--can-focus': canFocus,
          'ph-outer--active': actionRequired || editable,
          'ph-outer--small': small,
          'ph-outer--empty': editable && !dataUri,
          'ph-outer--only': signerIndex === null,
          'ph-outer--unassigned': signerIndex === -1
        },
        `ph-outer--${fullType}`
      ]"
    >
      <div class="ph-background" />
      <sr-placeholder-label
        v-if="editable && !dataUri"
        class="ph-text"
        :class="{ 'ph-text--multiline': multiline }"
        :style="smallStyle"
        :label="label"
        :signer-index="signerIndex"
        :is-standard="!comment"
        :type="fullType"
        tabindex="0"
      />
      <sr-checkbox
        v-else-if="fullType === 'checkbox'"
        ref="content"
        :label="label"
        :editable="editable"
        :required="required"
        :action-required="actionRequired"
        :initial-value="prefill && checkboxValue"
        :data-uri="dataUri"
        @fill="handleFill"
      />
      <div v-else class="ph-text">
        <sr-signature
          v-if="fullType === 'signature'"
          ref="content"
          :data-uri="dataUri"
          :comment="comment"
          :action-required="actionRequired"
          :width="width + spaceRight"
          :height="height"
          @fill="handleFill"
        />
        <sr-server-text-signature
          v-if="
            textRasterFallback && (fullType === 'text' || fullType === 'date')
          "
          ref="content"
          :full-type="fullType"
          :data-uri="dataUri"
          :label="label"
          :date-value="dateValue"
          :text="text"
          :action-required="actionRequired"
          :required="required"
          :editable="editable"
          :multiline="multiline"
          :prefill="prefill"
          :space-down="spaceDown"
          :space-right="spaceRight"
          :space-left="spaceLeft"
          :height="height"
          :width="width"
          @fill="handleFill"
        />
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
import variant from '@/utils/variant';
import SrSignature from './SrSignature';
import SrServerTextSignature from './SrServerTextSignature';
import SrCheckbox from './SrCheckbox';
import SrPlaceholderLabel from './SrPlaceholderLabel';
import BoxPlaceholderLabel from './BoxPlaceholderLabel';

import { getSignerColor } from '../../utils/signerColors';

// type is 't[ext]' | 'd[ate]' | 's[ignature]' | 'c[heckbox]'

export const DEFAULT_SIZE = {
  c: { width: 22, height: 22 },
  s: { width: 160, height: 60 },
  d: { width: 128, height: 20 },
  t: { width: 156, height: 20 }
};

export default {
  components: {
    SrPlaceholderLabel: variant(BoxPlaceholderLabel, SrPlaceholderLabel),
    SrCheckbox,
    SrSignature,
    SrServerTextSignature
  },
  model: {
    prop: 'position'
  },

  props: {
    required: { type: Boolean, default: false },
    prefill: { type: [String, Boolean], default: false },
    editable: { type: Boolean, default: true },
    actionRequired: { type: Boolean, default: false },
    canFocus: { type: Boolean, default: true },
    small: { type: Boolean, default: false },
    zoom: { type: Number, default: 1 },
    width: { type: Number, required: false, default: 1 },
    height: { type: Number, required: false, default: 1 },
    spaceLeft: { type: Number, required: false, default: 0 },
    spaceRight: { type: Number, required: false, default: 0 },
    spaceDown: { type: Number, required: false, default: 0 },
    type: { type: String, default: 'signature' },
    signerIndex: { type: Number, default: 0 },
    showText: { type: Boolean, default: true },
    dataUri: { type: String, default: '' },
    comment: { type: String, default: '' },
    dateValue: { type: String, default: '' },
    checkboxValue: { type: Boolean, default: false },
    text: { type: String, default: '' },
    multiline: { type: Boolean, default: false }
  },

  data: function() {
    return {
      textRasterFallback: true
    };
  },

  computed: {
    fullType() {
      return {
        c: 'checkbox',
        d: 'date',
        s: 'signature',
        t: 'text'
      }[this.type.slice(0, 1)];
    },
    signerColorStyle() {
      if (this.signerIndex === null || this.signerIndex === -1) {
        return '';
      }
      const color = getSignerColor(this.signerIndex);
      return (color && `color: ${color} !important`) || '';
    },
    smallStyle() {
      if (this.fullType !== 'text' && this.fullType !== 'date') {
        return '';
      }
      if (!this.small || this.zoom >= 1) {
        return '';
      }
      return `transform: scale(${this.zoom * 0.7});`;
    },
    label() {
      return (
        this.$props.comment ||
        {
          signature: this.$gettext('Signature'),
          checkbox: this.$gettext('Checkbox'),
          date: this.$gettext('Date'),
          text: this.$gettext('Text')
        }[this.fullType]
      );
    }
  },

  methods: {
    select() {
      this.$refs.content.select();
    },
    handleFill({ data_uri, text, overflowRTL, x, y, width, height }) {
      this.$emit('fill', {
        data_uri,
        text,
        overflowRTL,
        x,
        y,
        width,
        height
      });
    }
  }
};
</script>

<style scoped>
div.inline,
div.ph-outer,
div.ph-text,
div.ph-background {
  width: 100%;
  height: 100%;
  outline: none;
}

div.ph-outer {
  text-align: center;
}

div.ph-background {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
}
.signrequest-lib .ph-background,
.signrequest-lib .ph-outer {
  border-radius: 6px;
}

#home-box div.ph-outer.ph-outer--small div.ph-background {
  border-radius: 5px;
}
#home-box div.ph-outer--checkbox div.ph-background {
  border-radius: 14%;
}

div.ph-outer--can-focus:focus-within div.ph-background {
  border: 1px solid currentColor;
}

div.ph-outer--text:not(.ph-outer--empty) div.ph-background {
  left: -12px;
  right: -12px;
  width: calc(100% + 12px + 12px);
}
.mobile div.ph-outer--text:not(.ph-outer--empty) div.ph-background {
  left: -1vw;
  right: -1vw;
  width: calc(100% + 2vw);
}

div.ph-outer.ph-outer--active div.ph-background {
  background-color: currentColor;
  opacity: 0.1;
}
div.ph-outer:not(.ph-outer--active) div.ph-background {
  background-color: #111111;
  opacity: 0.05;
}

div.ph-text {
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
}
div.ph-outer--small div.ph-text {
  padding-top: 0;
  padding-bottom: 0;
}
.ltr div.ph-outer--small div.ph-text {
  transform-origin: left;
}
.rtl div.ph-outer--small div.ph-text {
  transform-origin: right;
}

div.ph-text.ph-text--multiline {
  align-items: flex-start;
}
div.ph-outer--active svg {
  width: 100%;
  height: 100%;
}
div.ph-outer--empty div.ph-text {
  padding: 0.3em 0.7em;
  overflow: hidden;
  white-space: nowrap;
}
/* checkboxes inside the document don't have label and should take all the
   space given */
.wrapper div.ph-outer--empty.ph-outer--checkbox div.ph-text {
  padding: 0;
}
div.ph-outer--empty div.ph-text span {
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

.ph-outer /deep/ img.ph-signature {
  max-width: 100%;
  min-width: 100%;
  height: 100%;
  width: 100%;
  display: block;
  pointer-events: none;
}
.ph-outer /deep/ label {
  overflow: hidden;
}
/* This is the style for homepage, where we have placeholders
 * only for current user
 */
.ph-outer.ph-outer--only {
  color: inherit;
}
/* This style is for placeholder that is not assigned to any user at all */
#home-box .ph-outer.ph-outer--unassigned {
  color: gray;
}

.signrequest-lib .ph-outer.ph-outer--unassigned {
  color: #4e4e4e;
}
.signrequest-lib .ph-outer:not(.ph-outer--unassigned) {
  border: 1px solid currentColor;
}
.signrequest-lib .ph-outer.ph-outer--unassigned .ph-background {
  background: #ffffff;
  border: 1px solid #d3d3d3;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.04);
  z-index: 0;
  opacity: 1;
}
.signrequest-lib .ph-outer.ph-outer--unassigned .ph-text {
  position: relative;
  z-index: 0;
}
</style>
