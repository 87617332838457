import { getTimeLocale } from '@/utils/langs';
import { format, differenceInDays, formatRelative } from 'date-fns';

function dateWithoutTimezone(date) {
  const dt = new Date(date);
  return new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);
}

function relativeDate(date, languageCode) {
  if (!date) {
    return '';
  }
  return formatRelative(date ? new Date(date) : Date.now(), new Date(), {
    locale: getTimeLocale(languageCode)
  });
}

function getDefaultDate(date, dateFormat, languageCode) {
  return formatDate(date || Date.now(), dateFormat, languageCode);
}

function formatDate(date, dateFormat, languageCode, ignoreTimezone = false) {
  if (!date) {
    return '';
  }
  return format(
    ignoreTimezone ? dateWithoutTimezone(date) : new Date(date),
    dateFormat,
    {
      locale: getTimeLocale(languageCode)
    }
  );
}

function getRelativeDateTime(date, languageCode) {
  return Math.abs(differenceInDays(new Date(date), new Date())) < 6
    ? relativeDate(date, languageCode)
    : formatLongDateTime(date, languageCode);
}

export function formatShortDate(date, languageCode, ignoreTimezone) {
  return formatDate(date, 'PP', languageCode, ignoreTimezone);
}

export function formatLongDateTime(date, languageCode) {
  return formatDate(date, 'PPp', languageCode);
}

export function formatLongRelativeDateTime(date, languageCode) {
  return getRelativeDateTime(date, languageCode);
}

export function longDateTime(fieldName) {
  return function getter() {
    const date = this[fieldName];
    const languageCode = this.$store.getters['conf/languageCode'];
    return formatLongDateTime(date, languageCode) || '';
  };
}

export function shortDate(fieldName, showCurrentTimeVariable) {
  return function getter() {
    const date = this[fieldName];
    const showCurrentTime = this[showCurrentTimeVariable] || false;
    const languageCode = this.$store.getters['conf/languageCode'];
    return (
      formatShortDate(showCurrentTime ? Date.now() : date, languageCode) || ''
    );
  };
}

export function relativeLongDateTime(fieldName) {
  return function getter() {
    const date = this[fieldName];
    const languageCode = this.$store.getters['conf/languageCode'];
    return formatLongRelativeDateTime(date, languageCode) || '';
  };
}
