import angular from 'angular';

/**
 * @ngdoc function
 * @name frontendApp.controller:LoginActivateCtrl
 * @description
 * # LoginActivateCtrl
 * Controller of the frontendApp
 */
angular.module('frontendApp').controller('LoginActivateCtrl', [
  '$scope',
  '$location',
  '$route',
  'UserService',
  '$window',
  'utils',
  function($scope, $location, $route, UserService, $window, utils) {
    UserService.waitForUser().then(function() {
      UserService.activateUser($route.current.params.token).then(function(
        resp
      ) {
        var next = '/';
        if (angular.isDefined(resp.data.next)) {
          next = resp.data.next.replace('#', 'r');
        }
        if (resp.data.user_logged_in) {
          // user reload needed
          $window.location.href = next;
        } else {
          utils.navigateTo(next);
        }
      });
    });
  }
]);
