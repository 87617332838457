<template>
  <div>
    <div class="payment-details-subtitle">
      <div v-if="isUpdateView">
        <h3 v-if="paymentMethod === 'card'" v-translate>Saved credit card</h3>
        <h3 v-if="paymentMethod === 'sepa'" v-translate>Saved bank account</h3>
      </div>
      <payment-method-button></payment-method-button>
    </div>
    <div v-if="isUpdateView">
      <view-edit-panel
        id="use-old-card"
        :add="false"
        :selected="!addNew"
        @selected="useOldPaymentDetails()"
      ></view-edit-panel>
      <view-edit-panel
        id="add-new-card"
        :add="true"
        :selected="addNew"
        @selected="addNewPaymentDetails()"
      ></view-edit-panel>
    </div>
    <div v-else class="payment-details-add">
      <credit-card v-if="paymentMethod === 'card'"></credit-card>
      <sepa v-if="paymentMethod === 'sepa'"></sepa>
      <billing-details-form v-if="!hasPaymentMethodData"></billing-details-form>
    </div>
  </div>
</template>
<script>
import CreditCard from '@/components/subscribe/CreditCard';
import Sepa from '@/components/subscribe/Sepa';
import ViewEditPanel from '@/components/subscribe/ViewEditPanel';
import PaymentMethodButton from '@/components/subscribe/PaymentMethodButton';
import BillingDetailsForm from '@/components/subscribe/BillingDetailsForm';
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  components: {
    ViewEditPanel,
    CreditCard,
    Sepa,
    BillingDetailsForm,
    PaymentMethodButton
  },
  data: () => {
    return {
      addNew: false
    };
  },
  computed: {
    ...mapState('subscription', [
      'subscriptionDetails',
      'paymentMethodDetails',
      'paymentMethod'
    ]),
    ...mapGetters('subscription', [
      'cardValid',
      'bankAccountLastFourDigits',
      'isUpdateView',
      'hasPaymentMethodData'
    ])
  },
  mounted() {
    // if use old or add new page is rendered set default old card and button enabled
    if (this.isUpdateView) this.useOldPaymentDetails();
  },
  methods: {
    ...mapActions('subscription', [
      'changeSubmitEnabled',
      'changeUseOld',
      'resetErrors'
    ]),
    useOldPaymentDetails() {
      this.resetErrors();
      this.addNew = false;
      this.changeUseOld({ useOld: true });
    },
    addNewPaymentDetails() {
      this.resetErrors();
      this.addNew = true;
      this.changeUseOld({ useOld: false });
    }
  }
};
</script>
<style lang="scss">
.payment-details-subtitle {
  display: flex;
  justify-content: space-between;

  h3 {
    margin-top: 1rem;
  }
}
.payment-details-add {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
}
.payment-method-form {
  min-height: 70px;
}
.subscribe-input-group {
  height: 50px;
  flex-direction: column;
  display: flex;
  margin-right: 7px;
}
.mobile .subscribe-input-group {
  margin-top: 30px;
}

.subscribe-input-label {
  font-size: 0.86rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
}

.subscribe-input-field {
  opacity: 0.84;
  background-color: #ffffff;
  padding: 15px 5px;
  margin-top: 5px;
  position: relative;
  width: 100%;
  border: 1px setting(colors, border) solid;
  &.StripeElement--focus,
  &:focus {
    outline: none;
    border-color: setting(colors, border-dark);
  }
}
</style>
