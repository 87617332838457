<template>
  <v-dialog
    v-model="showModal"
    max-width="max-content"
    transition="fade-transition"
    :content-class="contentClass"
  >
    <v-card>
      <subscribe @subscriptionCompleted="onSubscriptionCompleted" />
    </v-card>
  </v-dialog>
</template>

<script>
import ModalMixin from '@/components/modals/ModalMixin';
import Subscribe from '@/components/subscribe/Subscribe';
import { mapActions } from 'vuex';
export default {
  components: { Subscribe },
  mixins: [ModalMixin('subscribe')],
  props: {
    contentClass: { type: String, default: '' }
  },
  watch: {
    showModal: {
      immediate: false,
      async handler(showModal) {
        if (showModal) {
          await this.initSubscription({
            plan: this.modalOptions.plan,
            interval: this.modalOptions.interval,
            trial_days: this.modalOptions.trial_days
          });
        }
      }
    }
  },
  methods: {
    ...mapActions('users', ['getUser']),
    ...mapActions('subscription', ['initSubscription']),
    ...mapActions('events', ['trackSubscribeEvent']),
    onSubscriptionCompleted() {
      this.getUser({ forceReload: true });
      this.trackSubscribeEvent({
        name: 'Subscribe Modal',
        params: {
          feature: this.modalOptions.feature,
          trigger: this.modalOptions.trigger
        }
      });
      this.closeModal();
    }
  }
};
</script>
<style scoped>
/deep/ .v-dialog {
  border-radius: 25px;
}
</style>
