import Vue from 'vue';
import Vuex from 'vuex';

import api from './modules/api';
import messages from './modules/messages';
import conf from './modules/conf';
import { NotifyAngularPlugin } from '../plugins/notify-angular-plugin';
import documents from './modules/documents';
import modals from '@/store/modules/modals';
import events from '@/store/modules/events';
import permissions from '@/store/modules/permissions';
import users from '@/store/modules/users';
import fonts from '@/store/modules/fonts';
import templates from '@/store/modules/templates';
import signrequest from '@/store/modules/signrequest';
import subscription from '@/store/modules/subscription';
import trackEvents from '@/store/modules/trackEvents';
import experiments from '@/store/modules/experiments';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    messages,
    api,
    conf,
    documents,
    modals,
    events,
    permissions,
    users,
    fonts,
    templates,
    signrequest,
    subscription,
    trackEvents,
    experiments
  },
  plugins: [NotifyAngularPlugin],
  strict: process.env.NODE_ENV !== 'production'
});

export { store };
