import angular from 'angular';
import $ from 'jquery';

/**
 * @ngdoc directive
 * @name signRequestApp.directive:srPlaceholder
 * @description
 * # srPlaceholder
 */
angular.module('frontendApp').directive('srPlaceholder', [
  '$timeout',
  function($timeout) {
    return {
      // Restrict it to be an attribute in this case
      restrict: 'A',
      // responsible for registering DOM listeners as well as updating the DOM
      link: function(scope, element) {
        $timeout(function() {
          $(element).placeholder();
        });
      }
    };
  }
]);
