const siteSettingsJson = document.getElementById('site-settings'); // same as url: '/conf/settings/'
const siteSettings = siteSettingsJson
  ? JSON.parse(siteSettingsJson.textContent)
  : {};
const globalSettings = siteSettings.GLOBAL_SETTINGS
  ? siteSettings.GLOBAL_SETTINGS
  : {
      DEFAULT_PRIMARY_COLOR: '#366cd8'
    };

export default Object.freeze({
  frontendSettings: siteSettings.FRONTEND_SETTINGS,
  globalSettings: globalSettings,
  cookieDomain: globalSettings.VUE_APP_COOKIE_DOMAIN || '',
  debug: process.env.NODE_ENV === 'development',
  stripeKey: globalSettings.VUE_APP_STRIPE_KEY,
  appVersion: globalSettings.VUE_APP_VERSION || 'development',
  sentryFrontendDsn: globalSettings.SENTRY_FRONTEND_DSN,
  baseDomain: globalSettings.BASE_DOMAIN || 'localhost',
  allowNewHomeboxOptOut:
    typeof globalSettings.ALLOW_NEW_HOMEBOX_OPT_OUT === 'undefined'
      ? true
      : globalSettings.ALLOW_NEW_HOMEBOX_OPT_OUT,
  hideMarketing: !!globalSettings.HIDE_MARKETING,
  hideHelpCenter: !!globalSettings.HIDE_HELP_CENTER,
  // we only want to be complient when it's on-premise deployment (per request from Geert-Jan)
  enableStrictAccessibilityMode:
    typeof globalSettings.ENABLE_STRICT_ACCESSIBILITY_MODE === 'boolean'
      ? globalSettings.ENABLE_STRICT_ACCESSIBILITY_MODE
      : true,
  enableProductAnnouncementBanner:
    typeof globalSettings.ENABLE_PRODUCT_ANNOUNCEMENT_BANNER === 'boolean'
      ? globalSettings.ENABLE_PRODUCT_ANNOUNCEMENT_BANNER
      : false
});
