import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.modalOptions)?_c(VDialog,{ref:"modal",attrs:{"lazy":"","max-width":"436","content-class":_vm.contentClass},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c(VCard,{class:{
      mobile: _vm.$isMobile
    },attrs:{"role":"dialog","aria-hidden":!_vm.showModal,"aria-modal":_vm.showModal,"aria-labelledby":"confirm-modal-title"}},[_c(VCardTitle,{staticClass:"headline",attrs:{"id":"confirm-modal-title"}},[_vm._v("\n      "+_vm._s(_vm.title)+"\n    ")]),_c(VCardText,[_vm._v("\n      "+_vm._s(_vm.body)+"\n    ")]),_c(VCardActions,[_c('sr-button',{ref:"first",attrs:{"sr-style":"cancel","round":""},on:{"click":function($event){return _vm.close(false)}}},[_vm._v("\n        "+_vm._s(_vm.dismissButtonText)+"\n      ")]),_c('sr-button',{attrs:{"sr-style":_vm.confirmationButtonStyle,"round":""},on:{"click":function($event){return _vm.close(true)}}},[_vm._v("\n        "+_vm._s(_vm.confirmationButtonText)+"\n      ")])],1),_c('sr-button',{staticClass:"close",attrs:{"sr-style":"text"},on:{"click":function($event){return _vm.closeModal(false)}}},[_c(VIcon,{staticClass:"round",attrs:{"aria-label":"$gettext('Close confirmation dialog')"}},[_vm._v("\n        close\n      ")])],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }