export default (handlerNameOut, handlerNameIn) => ({
  data() {
    return {
      closeTimer: null
    };
  },
  methods: {
    // when focus is transferred from
    // one element in list to another
    // handleClose is called first,
    lazyFocusKeep() {
      window.clearTimeout(this.closeTimer);
      if (handlerNameIn) {
        this[handlerNameIn]();
      }
    },
    lazyFocusOut(event) {
      window.clearTimeout(this.closeTimer);
      this.closeTimer = window.setTimeout(() => {
        this[handlerNameOut]();
      }, 200);
    }
  }
});
