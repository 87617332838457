import angular from 'angular';

/**
 * @ngdoc function
 * @name frontendApp.controller:UserSessionsCtrl
 * @description
 * # UserSessionsCtrl
 * Controller of the frontendApp
 */
angular.module('frontendApp').controller('UserSessionsCtrl', [
  '$scope',
  '$route',
  'UserService',
  'utils',
  function($scope, $route, UserService, Utils) {
    $scope.updateSessions = function() {
      UserService.getAllSessions().then(function(response) {
        $scope.sessions = response.data;
      });
    };

    if (!angular.isDefined($scope.sessions)) {
      $scope.updateSessions();
    }
  }
]);
