import angular from 'angular';

/**
 * @ngdoc directive
 * @name frontendApp.directive:autoFocus
 * @description
 * # autoFocus an input field when rendered
 */
angular.module('frontendApp').directive('autoFocus', [
  '$timeout',
  function($timeout) {
    return {
      restrict: 'AC',
      scope: {
        focusOn: '=',
        focusOnValue: '='
      },
      link: function(scope, element) {
        // <input auto-focus focus-on="my_var">
        var doFocus = function() {
          $timeout(function() {
            element[0].focus();
          }, 100); // often we wait for modals to open
        };
        $timeout(function() {
          // or controllers to initialize
          if (angular.isDefined(scope.focusOn)) {
            // if we have a var for focus-on we wait for that to be true
            if (scope.focusOn) {
              doFocus();
            }
            scope.$watch('focusOn', function(n, o) {
              if (angular.isDefined(scope.focusOnValue)) {
                if (n === scope.focusOnValue) {
                  doFocus();
                }
              } else if (n) {
                doFocus();
              }
            });
          } else {
            // else we focus now
            doFocus();
          }
        });
      }
    };
  }
]);
