// Signer Colors - to be used in placeholders
import { getVariant } from '@/utils/variant';

const signerColors = [
  [0x7e, 0x37, 0x95],
  [0xe6, 0x86, 0x00],
  [0x61, 0xb0, 0x3d],
  [0x3f, 0x5c, 0xa8],
  [0xd7, 0x27, 0x27],
  [0xff, 0xff, 0x47],
  [0xc3, 0xd1, 0x40],
  [0x10, 0x9d, 0x59],
  [0xff, 0x47, 0xdf],
  [0x12, 0xa9, 0xcc],
  [0x66, 0x3f, 0x30]
];

const signerColorsBox = [
  '#f1811a',
  '#0061d5',
  '#26c281',
  '#1fb5d1',
  '#2486fc',
  '#f5b31b',
  '#9f3fed',
  '#4826c2',
  '#ed3757',
  '#003c84',
  '#ffd700',
  '#ee1ee0'
];

function cycle(component, round) {
  return (256 + component + round * 16) % 256;
}

function getSignerColorSr(index = 0) {
  const base = index % signerColors.length;
  const round = Math.floor(index / signerColors.length);
  const [r, g, b] = signerColors[base];
  return `rgb(${cycle(r, round * 4)}, ${g}, ${cycle(b, -round * 3)})`;
}

function getSignerColorBox(index = 0) {
  const base = index % signerColors.length;
  return signerColorsBox[base];
}

export function getSignerColor(index) {
  return (getVariant() === 'box' ? getSignerColorBox : getSignerColorSr)(index);
}
