<template>
  <v-form @submit.prevent="$emit('submit')">
    <homebox-screen>
      <template v-slot:title>
        <span class="settings-title">
          {{ title }}
        </span>
      </template>

      <div class="h-section">
        <sr-select-button
          v-for="file in files"
          ref="files"
          :key="file.uuid"
          data-test-id="file"
          :href="file.uuid | apiLink(screen)"
          inline
          @changed="$emit('selected', file)"
        >
          <span class="template-form__name">{{ file.name }}</span>
        </sr-select-button>
      </div>
    </homebox-screen>
  </v-form>
</template>
<script>
import { mapGetters } from 'vuex';
import HomeboxScreen from './HomeboxScreen';
import SrSelectButton from '@/components/elements/SrSelectButton.vue';
import FocusTrap from '@/mixins/FocusTrap';

export default {
  components: { HomeboxScreen, SrSelectButton },

  filters: {
    apiLink(uuid, screen) {
      return (
        {
          templates: `/#/?api=v1&source=self&template_uuid=${uuid}`
        }[screen] || null
      );
    }
  },
  mixins: [FocusTrap('firstButtonFocus', 'handleClose', 'shouldTrapFocus')],
  props: {
    screen: { type: String, required: true }
  },
  computed: {
    ...mapGetters('users', ['userTemplates']),
    ...mapGetters('signrequest/salesforce', ['allFiles']),
    ...mapGetters('modals', ['modalOpen']),
    // actual modals, like subscribe modal should be above sidepanel
    shouldTrapFocus() {
      return !this.modalOpen;
    },
    title() {
      return {
        templates: this.$gettext('Templates'),
        salesforce: 'Salesforce',
        recent: this.$gettext('Recently uploaded')
      }[this.$props.screen];
    },
    files() {
      return {
        templates: this.userTemplates,
        salesforce: this.allFiles
      }[this.$props.screen];
    }
  },
  methods: {
    handleClose() {
      this.$emit('close');
    },
    firstButtonFocus() {
      const { files } = this.$refs;
      if (files && files[0]) {
        files[0].focus();
      }
    }
  }
};
</script>
<style scoped>
.template-form__name {
  white-space: break-spaces;
  word-wrap: break-word;
}
.v-btn.inline {
  height: unset;
}
</style>
