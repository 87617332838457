export interface SocialProvider {
  provider: string;
  name: string;
  icon: string;
  oauthProviderName: string;
}

const socialProviders: SocialProvider[] = [
  {
    provider: 'google',
    name: 'Google',
    icon: require('@/images/icon-google.png'),
    oauthProviderName: 'google-oauth2'
  },
  {
    provider: 'facebook',
    name: 'Facebook',
    icon: require('@/images/icon-facebook.png'),
    oauthProviderName: 'facebook'
  },
  {
    provider: 'microsoft',
    name: 'Microsoft',
    icon: require('@/images/icon-microsoft.png'),
    oauthProviderName: 'microsoft-graph'
  },
  {
    provider: 'dropbox',
    name: 'Dropbox',
    icon: require('@/images/icon-dropbox.png'),
    oauthProviderName: 'dropbox-oauth2'
  },
  {
    provider: 'linkedin',
    name: 'LinkedIn',
    icon: require('@/images/icon-linkedin.png'),
    oauthProviderName: 'linkedin-oauth2'
  },
  {
    provider: 'salesforce',
    name: 'Salesforce',
    icon: require('@/images/icon-salesforce.png'),
    oauthProviderName: 'salesforce-oauth2'
  }
  // {
  //   provider: 'salesforce_sandbox',
  //   name: 'Salesforce Sandbox',
  //   icon: require('@/images/icon-salesforce.png'),
  //   oauthProviderName: 'salesforce-oauth2-sandbox'
  // }
];

export function providerUrl(provider: SocialProvider, next: string): string {
  return `/user/social/login/${
    provider.oauthProviderName
  }/?next=${encodeURIComponent(next)}`;
}

export default socialProviders;
