import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{ref:"modal",attrs:{"max-width":"444px","content-class":_vm.contentClass},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c(VCard,{class:{
      mobile: _vm.$isMobile
    },attrs:{"role":"dialog","aria-hidden":!_vm.showModal,"aria-modal":_vm.showModal,"aria-labelledby":"forward-modal-title"}},[_c('sr-button',{staticClass:"close",attrs:{"id":"close-icon","sr-style":"text","data-test-id":"close"},on:{"click":function($event){return _vm.closeModal()}}},[_c(VIcon,{staticClass:"round",attrs:{"aria-label":"$gettext('Close forward dialog')"}},[_vm._v("\n        close\n      ")])],1),_c(VCardText,[_c('h1',{staticClass:"srtext--text",attrs:{"id":"forward-modal-title"}},[_c('translate',[_vm._v("Forward document")])],1),_c('p',{staticClass:"srtext--text"},[_c('translate',[_vm._v("\n          Forwarding the document to someone else will send a notification to\n          whom you're assigning the document to. The original sender will also\n          receive a notification. You will be added as a Copy Only (CC)\n          recipient.\n        ")]),(_vm.forwardHelpUrl)?_c('a',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"learn-more-a",attrs:{"href":_vm.forwardHelpUrl,"target":"_blank","rel":"noopener noreferrer","data-test-id":"forward-help-url"}},[_vm._v("\n          Learn more\n        ")]):_vm._e()],1)]),_c('forward-form',{ref:"form",attrs:{"email-value":_vm.email,"reason-value":_vm.reason},on:{"submit":_vm.handleSubmit,"change":_vm.handleChange}},[_c('forward-buttons',{attrs:{"form-valid":_vm.formValid},on:{"closeModal":_vm.closeModal}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }