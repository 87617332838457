import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'announcement--container',
    { small: _vm.$vuetify.breakpoint.xsOnly, mobile: _vm.$isMobile }
  ]},[(_vm.show)?_c(VContainer,{staticClass:"announcement--content"},[_c(VLayout,{attrs:{"align-center":_vm.$vuetify.breakpoint.smAndUp}},[_c(VFlex,{attrs:{"grow":""}},[_c(VLayout,{attrs:{"align-center":"","justify-center":"","column":_vm.$vuetify.breakpoint.xsOnly}},[_c(VFlex,{attrs:{"shrink":""}},[_c(VLayout,{attrs:{"align-center":""}},[(_vm.logoSrc)?_c(VFlex,{staticStyle:{"margin-right":"21px","min-width":"67px","flex-basis":"67px"},attrs:{"shrink":"","text-xs-right":""}},[_c('img',{attrs:{"alt":_vm.logoAlt,"src":_vm.logoSrc,"width":_vm.logoWidth,"height":_vm.logoHeight}})]):_vm._e(),(_vm.heading)?_c(VFlex,{staticClass:"announcement--body",attrs:{"text-xs-left":"","align-content-center":"","shrink":""}},[_c('strong',[_vm._v(_vm._s(_vm.heading))]),_c('br',{staticClass:"hidden-lg-and-up"}),_vm._v("\n                "+_vm._s(_vm.subHeading)+"\n              ")]):_vm._e()],1)],1),_c(VFlex,{staticClass:"announcement--body",attrs:{"text-xs-left":"","align-content-center":"","shrink":""}},[_vm._t("default")],2),(_vm.buttonHref)?_c(VFlex,{staticStyle:{"min-width":"165px"},attrs:{"text-left":"","align-content-center":"","shrink":""}},[_c('sr-button',{attrs:{"round":"","sr-style":"flat-white","border":false,"href":_vm.buttonHref,"target":"_blank"}},[_vm._v("\n              "+_vm._s(_vm.buttonText)+"\n               \n              "),_c('img',{staticStyle:{"max-height":"17px"},attrs:{"alt":"Right arrow","src":require('@/images/arrow-right.svg')}})])],1):_vm._e()],1)],1),_c(VFlex,{staticStyle:{"min-width":"24px"},attrs:{"shrink":"","align-center":""}},[_c(VIcon,{attrs:{"dark":"","role":"button"},on:{"click":_vm.handleDismiss}},[_vm._v("\n          close\n        ")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }