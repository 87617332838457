<template>
  <AccountScreen key="documents" :title="$gettext('Documents')">
    <CheckLogin>
      <CheckDocumentViewPermission>
        <DocumentSearch></DocumentSearch>
      </CheckDocumentViewPermission>
    </CheckLogin>
  </AccountScreen>
</template>
<script>
import Vue from 'vue';
import AccountScreen from '@/components/AccountScreen.vue';
import CheckLogin from '@/components/CheckLogin.vue';
import CheckDocumentViewPermission from './CheckDocumentViewPermision';
import DocumentSearch from '@/components/DocumentSearch.vue';
export default Vue.extend({
  components: {
    AccountScreen,
    DocumentSearch,
    CheckLogin,
    CheckDocumentViewPermission
  }
});
</script>
