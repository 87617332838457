<template>
  <v-btn-toggle
    class="sr-radio-button primary--text"
    :value="value"
    :mandatory="mandatory"
    @change="updateValue($event)"
  >
    <v-btn
      v-for="opts in tabOptions"
      :key="opts.key"
      block
      flat
      active-class="primary"
      :value="opts.key"
      :disabled="opts.disabled"
    >
      {{ opts.title }}
    </v-btn>
  </v-btn-toggle>
</template>

<script>
export default {
  props: {
    mandatory: { type: Boolean, default: false },
    value: { type: String, required: true },
    tabOptions: { type: Array, required: true }
  },
  methods: {
    updateValue(v) {
      this.$emit('input', v);
    }
  }
};
</script>

<style scoped>
.sr-radio-button {
  box-shadow: none !important;
  width: 100%;
  border-color: currentColor;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
}

.sr-radio-button.v-btn-toggle .v-btn {
  opacity: 1;
  color: inherit !important;
  border-color: inherit;
  background-color: white;
}
.sr-radio-button.v-btn-toggle .v-btn[disabled] {
  opacity: 0.6;
  cursor: not-allowed;
  pointer-events: all; /* point-events:none prevents cursor change on hover */
}
.sr-radio-button.v-btn-toggle .v-btn[disabled] /deep/ .v-btn__content {
  color: #757b88; /* srgrey */
}

.sr-radio-button.v-btn-toggle .v-btn.primary {
  color: white !important;
}
</style>
