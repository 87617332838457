<template>
  <v-dialog
    v-model="showModal"
    lazy
    max-width="700"
    :content-class="contentClass"
  >
    <v-card v-if="completed" key="completed">
      <v-card-title v-translate class="headline">
        Almost done!
      </v-card-title>
      <v-card-text>
        <p v-translate>
          We’ve sent two confirmation emails, one to your current email address
          and one to your new email address.
        </p>
        <p v-translate>To complete your email address change:</p>
        <ol>
          <li v-translate>
            Confirm the change in your previous email account.
          </li>
          <li v-translate>Confirm the change in your new email account.</li>
        </ol>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="closeModal">
          <translate>Done</translate>
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-else key="change-your-email">
      <v-form @submit.prevent="handleSubmit">
        <v-card-title v-translate class="headline">
          Change your email
        </v-card-title>
        <v-card-text>
          <span v-translate>Use this form to change your email address.</span>
          <ul>
            <li>
              <span v-translate>
                The new email address cannot be connected to an existing
                SignRequest account. If that is the case, the new account should
                first be completely deleted.
              </span>
              <a v-if="howToDeleteUrl" v-translate :href="howToDeleteUrl">
                Learn more
              </a>
            </li>
            <li v-translate>
              For security reasons a confirmation email will be sent to your
              current and your new email address. The new email address will
              only become active after both email accounts have confirmed the
              transfer.
            </li>
            <li>
              <span v-translate="{ email: user.email }">
                After the transfer your current email
                <strong>%{ email }</strong>
                can no longer be used to access this account. You will, however,
                be able to generate a completely new account with this email
                address.
              </span>
            </li>
          </ul>
          <v-text-field
            v-model="newEmail"
            name="email"
            autofocus
            :label="$gettext('New email')"
            class="mt-2"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn flat @click="closeModal">
            <translate>Cancel</translate>
          </v-btn>
          <v-btn color="primary" :loading="loading" type="submit">
            <translate>Change Email</translate>
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import permissions from '@/mixins/permissions';
import ModalMixin from '@/components/modals/ModalMixin';
import { mapGetters, mapActions } from 'vuex';

export default {
  mixins: [permissions, ModalMixin('changeEmail')],
  props: {
    contentClass: { type: String, default: '' }
  },
  data: function() {
    return {
      newEmail: '',
      completed: false,
      loading: false
    };
  },
  computed: {
    ...mapGetters('conf', ['howToDeleteUrl']),
    ...mapGetters('users', ['user'])
  },
  methods: {
    ...mapActions('users', ['updateEmail']),
    ...mapActions('events', ['trackChangeEmailEvent']),
    async handleSubmit() {
      this.loading = true;
      const isDone = await this.updateEmail(this.newEmail);
      this.loading = false;

      if (!isDone) {
        return;
      }

      this.trackChangeEmailEvent({ email: this.newEmail });

      this.completed = true;
    }
  }
};
</script>
