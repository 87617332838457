<template>
  <v-pagination
    v-if="pageCount > 1"
    :value="value"
    :length="pageCount"
    total-visible="5"
    class="sr-pagination"
    @input="$emit('input', $event)"
  ></v-pagination>
</template>

<script>
export default {
  props: {
    resultCount: { type: Number, required: true },
    resultsPerPage: { type: Number, required: true },
    value: { type: Number, required: true }
  },
  computed: {
    pageCount() {
      return Math.ceil(this.resultCount / this.resultsPerPage);
    }
  }
};
</script>
