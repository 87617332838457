import { VCardText } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VForm,{ref:"form",staticClass:"ph-text-entry",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c(VCardText,{staticClass:"v-card__text__input srtext--text"},[_c('sr-input',{ref:"email",attrs:{"label":_vm.variant === 'sr'
          ? _vm.$gettext('Email of the new signer')
          : _vm.$gettext('Recipient'),"placeholder":_vm.$gettext('Email'),"name":"email","rules":[_vm.emailRule]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c(VCardText,{staticClass:"v-card__text__textarea srtext--text"},[_c('sr-input',{ref:"reason",attrs:{"name":"reason","element":"textarea","label":_vm.variant === 'sr'
          ? _vm.$gettext('Reason for changing signing responsibility')
          : _vm.$gettext('Message'),"max-height":100,"scrollbar":"","placeholder":_vm.$gettext(
          'Optional - Please note that this message will be visible to all parties.'
        )},model:{value:(_vm.reason),callback:function ($$v) {_vm.reason=$$v},expression:"reason"}})],1),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }