<template>
  <sr-field
    :is-empty="isEmpty"
    :has-placeholder="Boolean(placeholder)"
    :label="label"
    :label-for="uid"
    :help="help"
    :horizontal="horizontal"
    :validations="(hasError && hasState && validations) || null"
    @click:label="focus"
  >
    <template v-slot:append-outer>
      <slot name="append-outer" />
    </template>
    <template v-slot:append-inner>
      <slot name="append-inner" />
      <div
        v-if="$slots.value"
        class="sr-autocomplete--value"
        :class="{ 'sr-autocomplete--value-caret': showCaret }"
        @click="focus"
      >
        <slot name="value" />
      </div>
      <div v-if="showCaret" class="sr-autocomplete--caret" @click="focus">
        <v-icon>arrow_drop_down</v-icon>
      </div>
    </template>

    <sr-menu
      :id="`${uid}-list`"
      ref="menu"
      role="none"
      list-role="listbox"
      activate-on="open"
      class="sr-autocomplete"
      :class="{
        'sr-autocomplete--empty': isEmpty,
        'sr-autocomplete--no-items':
          filteredItems.length === 0 && !(showManual && enableManual),
        'sr-autocomplete--typing': Boolean(searchInput)
      }"
      @close="handleClose"
      @change:menuindex="selectedIdx = $event"
      @change:menuopen="menuOpen = $event"
    >
      <template v-slot:activator="{ on }">
        <input
          :id="uid"
          ref="input"
          v-model="searchInput"
          role="combobox"
          aria-autocomplete="list"
          :aria-expanded="String(keyMenuOpen)"
          :aria-controls="`${uid}-list`"
          type="text"
          class="srtext--text"
          autocomplete="off"
          v-bind="$attrs"
          :placeholder="placeholder"
          :readonly="readonly"
          :disabled="disabled"
          @focus="handleFocus(on)"
          @blur="handleBlur"
          @keydown="handleKeyDown"
        />
      </template>
      <div
        v-for="(item, idx) in filteredItems"
        :key="idx"
        role="none"
        @keydown.enter.stop="handleEnter"
      >
        <span v-if="item.header" class="sr-autocomplete--menuitem--header">
          {{ item.header }}
        </span>
        <sr-button
          v-else
          sr-style="text"
          role="option"
          tabindex="-1"
          :aria-selected="idx === keyMenuIndex"
          :class="{
            'sr-autocomplete--menuitem--active': idx === keyMenuIndex
          }"
          @click="handleChoose(item)"
        >
          <slot name="item" :item="item">
            {{ item.text }}
          </slot>
        </sr-button>
      </div>
      <sr-button
        v-if="showManual && enableManual && showManualEntry"
        key="add"
        sr-style="text"
        role="option"
        :tabindex="-1"
        data-test-id="manual-input"
        :class="{ 'v-list__tile--highlighted': isSelectedManual }"
        :aria-selected="isSelectedManual"
        @click="handleChooseManual"
      >
        <slot name="additem" :value="searchInput.trim()">
          <translate>Add</translate>
          <span>&nbsp;</span>
          <span>{{ searchInput }}</span>
        </slot>
      </sr-button>
    </sr-menu>
  </sr-field>
</template>
<script>
import SrField from '@/components/elements/SrField';
import SrButton from '@/components/elements/SrButton';
import SrMenu from '@/components/elements/SrMenu';

import validatable from 'vuetify/lib/mixins/validatable';
import uniqueId from '@/mixins/uniqueId';

export default {
  components: { SrField, SrButton, SrMenu },
  mixins: [validatable, uniqueId],
  inheritAttrs: false,
  props: {
    label: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    help: { type: String, default: '' },
    matchFn: { type: [Function, Boolean], required: false, default: false },
    items: { type: Array, required: true },
    limit: { type: Number, required: false, default: Infinity },
    horizontal: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    showCaret: { type: Boolean, required: false, default: false },
    showManualEntry: { type: Boolean, required: false, default: true },
    enableManual: { type: Boolean, required: false, default: true }
  },
  data() {
    return {
      selectedIdx: -1,
      menuOpen: false,
      searchInput: ''
    };
  },
  computed: {
    keyMenuIndex() {
      return this.selectedIdx;
    },
    keyMenuOpen() {
      return this.menuOpen;
    },
    filteredItems() {
      const input = this.searchInput.toLowerCase();
      const matchFn = this.matchFn || this.defaultMatchFn;
      const filteredItems = this.items
        .filter(option => matchFn(input, option))
        .slice(0, this.limit);
      return this.$props.enableManual || filteredItems.length
        ? filteredItems
        : this.items;
    },
    selectedItem() {
      return this.filteredItems[this.keyMenuIndex];
    },
    isSelectedManual() {
      return (
        this.keyMenuIndex === this.filteredItems.length ||
        this.filteredItems.length === 0
      );
    },
    isEmpty() {
      const hasValue = this.items.find(item => item.value === this.value);
      return Boolean(!hasValue && !this.searchInput && !this.value);
    },
    showManual() {
      if (this.matchFn) {
        return this.matchFn(this.searchInput, { manual: true });
      }
      return this.searchInput.trim() !== '';
    }
  },
  watch: {
    searchInput(value) {
      this.$emit('update:searchInput', value);
      this.$refs.menu.keyMenuIndex = 0;
    }
  },
  methods: {
    defaultMatchFn(input, option) {
      const matchI = text =>
        String(text)
          .toLowerCase()
          .includes(input);
      return matchI(option.text) || matchI(option.value);
    },
    close() {
      this.$refs.menu.close();
    },
    clear() {
      this.searchInput = '';
      this.close();
    },
    focus() {
      this.$refs.input.focus();
      this.$refs.menu.lazyFocusKeep();
    },
    handleChoose({ value, text }, { getFocus = true } = {}) {
      this.$emit('commit', { value, text });
      this.clear();
      if (getFocus) {
        this.$refs.input.focus();
      }
    },
    handleChooseManual(opts) {
      if (this.$props.readonly || this.$props.disabled) {
        // don't do anything when input is readonly
      } else if (this.searchInput && this.validate(true, this.searchInput)) {
        this.handleChoose(
          { value: this.searchInput, text: this.searchInput },
          opts
        );
      }
    },
    handleKeyDown(event) {
      if (this.$props.readonly || this.$props.disabled) {
        // don't do anything when input is readonly
      } else if (event.key === 'Enter') {
        const selectedOption = this.$refs.menu.keyMentSelectedOption();
        if (selectedOption) {
          selectedOption.click();
          event.preventDefault();
        } else if (this.enableManual) {
          this.handleChooseManual();
        }
      } else if (event.key === 'Backspace' && !this.searchInput) {
        this.$emit('clear');
      } else {
        this.$refs.menu.keyMenuKeyboard(event);
        this.$refs.menu.open();
      }
    },
    handleEnter(event) {
      // this method does nothing
      // event is stopped from propagating so form is not submitted
      // every time user selects something in dropdown
    },
    handleFocus(on) {
      if (!this.$props.readonly) {
        on.open();
      }
    },
    handleBlur() {
      if (this.$refs.menu) {
        this.$refs.menu.lazyFocusOut();
      }
    },
    handleClose() {
      if (this.$props.enableManual) {
        this.handleChooseManual({ getFocus: false });
      } else {
        this.searchInput = '';
      }
    }
  }
};
</script>
<style scoped>
.sr-menu /deep/ .sr-menu--content {
  z-index: 20;
  margin: 0 -1rem;
  width: calc(100% + 2rem);
  max-height: 240px;
  overflow-y: auto;
  padding-top: 0px;
}
.sr-menu /deep/ .sr-menu--content > div {
  margin: 0;
  padding: 0;
}
.sr-menu /deep/ .sr-menu--arrow {
  display: none;
}
.sr-autocomplete--menuitem--header {
  padding: 0 16px;
  font-size: 14px;
}
::v-deep .sr-field--control {
  position: relative;
}
.sr-autocomplete--value {
  position: absolute;
  font-size: 16px;
  font-weight: normal;
  z-index: 1;
  overflow: hidden;
  word-break: break-all;
}

.sr-field--horizontal .sr-autocomplete {
  padding: 1rem 0;
  min-height: 55px;
  border: 1px solid rgba(214, 214, 214, 0.84);
  margin-bottom: 0.5rem;
}

.sr-field--horizontal .sr-autocomplete--caret {
  top: 14px;
  z-index: 1;
}
.sr-field--horizontal .sr-autocomplete--value {
  top: 11px;
  left: 14px;
  right: 14px;
  z-index: 1;
  height: 35px;
}
.sr-field--vertical .sr-autocomplete--caret {
  top: 3px;
}

.sr-autocomplete--caret {
  position: absolute;
  font-size: 16px;
  font-weight: normal;
  z-index: 1;
  cursor: pointer;
}
.sr-menu-open ~ .sr-autocomplete--caret {
  transform: rotate(180deg);
  margin-top: -3px;
}

.ltr .sr-autocomplete--caret {
  right: 1em;
}
.rtl .sr-autocomplete--caret {
  left: 1em;
}
.sr-autocomplete--value-caret {
  /* 1em is left-right shift of caret, 24px is width */
  width: calc(100% - 24px - 1em);
  max-width: calc(100% - 24px - 1em);
}
.sr-autocomplete--typing ~ .sr-autocomplete--value,
.sr-autocomplete--open ~ .sr-autocomplete--value {
  display: none;
}
input::placeholder {
  opacity: 0;
}
input::placeholder {
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  opacity: 0.8;
}

.sr-field /deep/ .sr-field--error:empty {
  display: none;
}
.sr-button[role='option'] {
  width: 100%;
  opacity: 0.87;
  font-weight: 400;
  font-size: 16px;
  margin: 6px 0;
  padding: 0 1rem;
  outline-offset: -4px !important;
}
.sr-button.sr-button /deep/ .sr-button--content {
  justify-content: start;
}
input:focus {
  outline: none;
}
input {
  font-size: 16px;
  font-weight: normal;
  -webkit-appearance: none;
  letter-spacing: 0.27px;
  width: 100%;
}
.sr-menu.sr-autocomplete--no-items /deep/ .sr-menu--content {
  display: none;
}

.signrequest-lib input {
  border: none !important;
  box-shadow: none !important;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: 0.3px;
  padding: 0;
}
.signrequest-lib .sr-field /deep/ .sr-field--control {
  border: 1px solid #bcbcbc;
  border-radius: 6px;
  margin-top: 8px;
  flex-basis: unset;
  flex-grow: unset;
  padding: 7px 10px;
}
.signrequest-lib .sr-field /deep/ .sr-field--control:focus-within {
  border-color: #0061d5;
}
.signrequest-lib input:placeholder {
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
  color: #767676;
}
</style>
