<template>
  <div
    class="sr-menu"
    :class="{ 'sr-menu-open': isOpen, 'sr-menu--top': top }"
    :aria-expanded="isOpen"
    :aria-controls="id || uid"
    :role="role"
  >
    <div class="sr-menu--activator">
      <slot name="activator" :on="activatorOn" />
    </div>
    <div class="sr-menu--arrow" />
    <div
      :id="id || uid"
      ref="content"
      :role="listRole"
      class="sr-menu--content sr-menu--shadow"
      :class="containerClass"
      tabIndex="-1"
      @focusin="lazyFocusKeep"
      @focusout="lazyFocusOut"
      @keydown="keyMenuKeyboard"
      @touchmove.stop="handleNothing"
    >
      <slot />
    </div>
  </div>
</template>
<script>
import LazyFocus from '@/mixins/LazyFocus';
import KeyMenu from '@/mixins/KeyMenu';
import uniqueId from '@/mixins/uniqueId';

export default {
  mixins: [LazyFocus('handleLazyClose'), KeyMenu(), uniqueId],
  inheritAttrs: false,
  props: {
    id: { type: String, default: '' },
    containerClass: { type: String, default: '' },
    role: { type: String, required: false, default: 'none' },
    listRole: { type: String, required: false, default: 'menu' },
    activateOn: { type: String, required: false, default: 'click' },
    top: { type: Boolean, default: false }
  },
  data() {
    return {
      isOpen: false
    };
  },
  computed: {
    activatorOn() {
      return {
        [this.$props.activateOn]: this.handleClick
      };
    }
  },
  methods: {
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
      this.keyMenuClose();
      this.$emit('close');
    },
    handleLazyClose() {
      this.close();
    },
    handleClick(event) {
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        this.keyMenuOpened();
      }
      this.$nextTick(() => {
        if (this.isOpen && this.$props.activateOn === 'click') {
          this.keyMenuOpen();
        }
      });
    },
    handleNothing() {
      // this does nothing at all
      // even propagation is stopped by .stop modifer
    }
  }
};
</script>
<style scoped>
.sr-menu {
  position: relative;
}
.sr-menu--activator {
  width: 100%;
}

.sr-menu:not(.sr-menu-open) .sr-menu--content,
.sr-menu:not(.sr-menu-open) .sr-menu--arrow {
  display: none;
}

.sr-menu--content {
  position: absolute;
  top: calc(100% + 0.5em);
  border-radius: 20px;
  padding-top: 18px;
  background: white;
  overflow: visible;
  contain: contain;
  min-width: 256px;
  outline: none;
  z-index: 1;
}
.signrequest-lib .sr-menu--content {
  border-radius: 6px;
}
.sr-menu--content:empty {
  display: none;
}
.sr-menu--top .sr-menu--content {
  top: unset;
  bottom: 25px;
  position: fixed;
}

.sr-menu--content > * {
  padding-left: 20px;
  padding-right: 20px;
}
.sr-menu--arrow {
  display: block;
  position: absolute;
  content: ' ';
  top: 100%;
  font-size: 10px;
  color: white;
  border-left: 0.93em solid transparent !important;
  border-right: 0.93em solid transparent !important;
  border-bottom: 0.5em solid currentColor;
}
.ltr .sr-menu--arrow {
  left: calc(50% - 0.93em);
}
.rtl .sr-menu--arrow {
  left: calc(50% - 0.93em);
}

.sr-menu--files-menu {
  z-index: 22;
  width: 100%;
  overflow-y: auto;
  padding-top: unset;
  overflow-x: hidden;
}
.sr-menu--shadow {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
</style>
