import $ from 'jquery';
import angular from 'angular';
import _ from 'lodash';
import Mousetrap from 'mousetrap';
import { getSignerColor } from '@/utils/signerColors';

/**
 * @ngdoc function
 * @name frontendApp.controller:DocCtrl
 * @description
 * # DocCtrl
 * Controller of the frontendApp
 */
angular
  .module('frontendApp')
  .controller('DocCtrl', [
    '$scope',
    '$rootScope',
    '$route',
    'docService',
    '$window',
    '$document',
    '$location',
    '$vuex',
    'srHowSignModal',
    'messageService',
    'gettextCatalog',
    '$timeout',
    'srTermsAgreeModal',
    'srDeclineModal',
    'srNoActionNeededModal',
    'srLoginModal',
    'srHowPrepareModal',
    'srForwardDocumentModal',
    'srVerifyPhoneModal',
    'srVerifyBankAccountModal',
    'srPrepareHelpModal',
    'deviceService',
    'utils',
    'UserService',
    'localeService',
    'srBulkSendModal',
    'srUploadReplaceModal',
    'srPublicTemplateModal',
    'windowService',
    'srUploadAttachmentModal',
    'srRequireAttachmentModal',
    'srSetPasswordModal',
    'srEnterPasswordModal',
    'salesforceService',
    '$q',
    'bulkSendService',
    'fontService',
    'srLoginOrContinueModal',
    function(
      $scope,
      $rootScope,
      $route,
      docService,
      $window,
      $document,
      $location,
      $vuex,
      srHowSignModal,
      messageService,
      gettextCatalog,
      $timeout,
      srTermsAgreeModal,
      srDeclineModal,
      srNoActionNeededModal,
      srLoginModal,
      srHowPrepareModal,
      srForwardDocumentModal,
      srVerifyPhoneModal,
      srVerifyBankAccountModal,
      srPrepareHelpModal,
      deviceService,
      utils,
      UserService,
      localeService,
      srBulkSendModal,
      srUploadReplaceModal,
      srPublicTemplateModal,
      windowService,
      srUploadAttachmentModal,
      srRequireAttachmentModal,
      srSetPasswordModal,
      srEnterPasswordModal,
      salesforceService,
      $q,
      bulkSendService,
      fontService,
      srLoginOrContinueModal
    ) {
      $scope.localeService = localeService;
      $scope.UserService = UserService;

      $scope.uuid = utils.ensureUUID($route.current.params.uuid);
      $scope.is_bulk_send = Boolean($route.current.params.bulksend && true);
      $scope.bulkSendResultsReady = false;

      $scope.signer_uuid = utils.ensureUUID($route.current.params.signer_uuid);
      $scope.signer_token = utils.ensureJWT($route.current.params.signer_token);
      $scope.template_uuid = utils.ensureUUID(
        $route.current.params.template_uuid
      );
      $scope.is_template = Boolean($scope.template_uuid && true);

      $scope.hideLogo = Boolean($route.current.params.hide_logo || false);
      $scope.hideLogin = Boolean($route.current.params.hide_login || false);
      $scope.hideDecline = Boolean($route.current.params.hide_decline || false);
      $scope.hideTitle = Boolean($route.current.params.hide_title || false);

      const search = $location.search();
      $rootScope.isVueSignbox =
        $vuex.getters['conf/isVueSignbox'] &&
        (!$scope.is_template || $vuex.getters['conf/isVueTemplate']) &&
        !$route.current.params.prepare_doc;
      if ($scope.isVueSignbox) {
        return;
      }
      $scope.availableFontFamilies = fontService.availableFontFamilies;
      fontService.loadFonts();

      if ($route.current.params.prepare_doc) {
        // somehow '?' is added
        $scope.prepare_doc =
          $route.current.params.prepare_doc.indexOf('prepare_doc') > -1;
      } else if ($scope.is_template) {
        $scope.prepare_doc = true;
      } else {
        $scope.prepare_doc = false;
      }

      $scope.showLogin = function() {
        if ($scope.hideLogin) {
          return false;
        }
        if ($scope.signer && $scope.signer.in_person) {
          return false;
        }
        if ($scope.user && !$scope.user.logged_in) {
          return true;
        }
        return false;
      };

      $scope.showDecline = function() {
        if ($scope.hideDecline || $scope.prepare_doc) {
          return false;
        }
        if ($scope.signer && $scope.signer.action_needed) {
          return true;
        }
        return false;
      };

      $scope.getWhoDisplay = function(type) {
        if (type === 'm') {
          return gettextCatalog.getString('Only me');
        }
        if (type === 'mo') {
          return gettextCatalog.getString('Me and others');
        }
        if (type === 'o') {
          return gettextCatalog.getString('Only others');
        }
        return '';
      };

      $scope.hasSigningOthers = function() {
        var signers = $scope.doc.signrequest.signers;
        return (
          _.filter(signers, function(s) {
            return (
              !s.is_owner &&
              ['signature', 'approve', 'in_person'].indexOf(s.level) > -1
            );
          }).length > 0
        );
      };

      $scope.setWhoOnPrepare = function(value) {
        if ($scope.loading) {
          return;
        }
        var signingLevels = ['signature', 'approve', 'in_person'];
        var signingLevelsWithoutApprove = ['signature', 'in_person'];

        if (value === 'm') {
          angular.forEach($scope.doc.signrequest.signers, function(s) {
            if (!s.is_owner) {
              if (signingLevels.indexOf(s.level) > -1) {
                $scope.changeSignerLevel(s, 'cc');
              }
            } else {
              if (signingLevelsWithoutApprove.indexOf(s.level) === -1) {
                $scope.changeSignerLevel(s, 'signature');
              }
            }
          });
        }

        if (value === 'mo') {
          let changeOthers = !$scope.hasSigningOthers();
          angular.forEach($scope.doc.signrequest.signers, function(s) {
            if (s.is_owner) {
              if (signingLevels.indexOf(s.level) === -1) {
                $scope.changeSignerLevel(s, 'signature');
              }
            } else {
              if (changeOthers) {
                $scope.changeSignerLevel(s, 'signature');
              }
            }
          });
        }
        if (value === 'o') {
          let changeOthers = !$scope.hasSigningOthers();
          angular.forEach($scope.doc.signrequest.signers, function(s) {
            if (s.is_owner) {
              if (signingLevels.indexOf(s.level) > -1) {
                $scope.changeSignerLevel(s, 'cc');
              }
            } else {
              if (changeOthers) {
                if (signingLevels.indexOf(s.level) === -1) {
                  $scope.changeSignerLevel(s, 'signature');
                }
              }
            }
          });
        }
        $scope.setWho();
      };

      $scope.emailCount = 0;
      $scope.emaillist = $scope.emaillist || '';
      $scope.docService = docService;

      $scope.canCreateTemplate = function() {
        if ($scope.is_template || !$scope.prepare_doc) {
          return false;
        }
        if (
          angular.isObject($scope.doc) &&
          angular.isObject($scope.doc.template) &&
          $scope.doc.template.created
        ) {
          // the document is not a template itself but was created from a template
          if (!$scope.doc.template.uuid) {
            // if we do not get the uuid from the backend this means we do not have access to the template to
            // change/save or create a new template from this doc as it is owned by someone else
            // people can land in this state when they've used the public template link
            return false;
          }
        }
        return true;
      };

      $scope.includeSideBar = function() {
        // if we are not preparing the doc and attachments are disabled (can be done using the API) we don't need to show
        // the sidebar
        if ($scope.prepare_doc) {
          return true;
        } else if ($scope.doc && $scope.doc.signrequest && $scope.signer) {
          if ($scope.signer.action_needed && !$scope.signer.approve_only) {
            return !$scope.doc.signrequest.disable_attachments;
          }
        }
        return false;
      };

      $scope.editor_options_active = $scope.includeSideBar();
      $scope.toggleSideBar = function() {
        $scope.editor_options_active = !$scope.editor_options_active;
        $timeout(function() {
          // needed as the image width / height of the page changes
          // handled in doc-page.js
          $($window).trigger('resize');
        }, 500);
      };

      $scope.next = null;
      $scope.next_from_server = null;
      if (angular.isDefined(search.next) && search.next) {
        // may be overridden by 'next' from the server (for in person signing) and chained signing in
        // initDocResponse
        $scope.next = utils.redirectFilter(search.next, 'close');
      }

      $scope.loading = true;

      $scope.hasSignature = false;
      $scope.checkForSignatures = function() {
        var hasSignature = false;
        var placeholdersFilled = true;
        angular.forEach($scope.doc.pages, function(page) {
          angular.forEach(page.editors, function(editor) {
            if ($scope.doc.is_prepared) {
              if (
                editor.isEditable($scope.prepare_doc) &&
                !editor.placeholder.filled &&
                editor.placeholder.required
              ) {
                // editor.placeholder.required: we allow finishing a doc when the placeholder is not required (but also not skipped)
                placeholdersFilled = false;
              }
            } else if (
              editor.isEditable($scope.prepare_doc) &&
              editor.sig.data_uri &&
              (editor.sig.type === 's' || !editor.sig.type)
            ) {
              hasSignature = true;
            }
          });
        });
        if ($scope.doc.is_prepared) {
          $scope.hasSignature = placeholdersFilled;
        } else {
          $scope.hasSignature = hasSignature;
        }
      };

      $scope.openHowSignModal = function() {
        if ($scope.is_bulk_send || $scope.doc.is_prepared) {
          return;
        } else if ($scope.prepare_doc) {
          srHowPrepareModal.activate({});
        } else if ($scope.signer.action_needed) {
          srHowSignModal.activate(
            {},
            { approve_only: $scope.signer.approve_only }
          );
        } else {
          srNoActionNeededModal.activate(
            {},
            { signer: $scope.signer, doc: $scope.doc }
          );
        }
      };

      $scope.changeOriginalFile = async function() {
        await UserService.waitForUser();

        if (!UserService.hasPermOrModal($scope.doc.perm, 'replace_file')) {
          return;
        }

        // save the current template in the background
        if ($scope.is_template) {
          docService.saveTemplate($scope.doc.uuid, $scope.doc, true);
        } else if ($scope.prepare_doc) {
          docService.saveDocPrepare(
            $scope.doc.uuid,
            $scope.signer_uuid,
            $scope.doc,
            true
          );
        }
        srUploadReplaceModal.activate(
          {},
          { doc: $scope.doc, is_template: $scope.is_template }
        );
      };

      $scope.showPublicUrl = function() {
        if ($scope.is_template) {
          // save the current template in the background
          docService.saveTemplate($scope.doc.uuid, $scope.doc, true);
          srPublicTemplateModal.activate({}, { template: $scope.doc });
        }
      };

      $scope.openTermsAgreeModal = function() {
        srTermsAgreeModal.activate(
          {},
          {
            approve_only: $scope.signer.approve_only,
            disable_terms: $scope.doc.disable_terms,
            save: $scope.save
          }
        );
      };

      $scope.openPrepareHelpModal = function() {
        srPrepareHelpModal.activate();
      };

      $scope.openVerifyPhoneModal = function() {
        srVerifyPhoneModal.activate(
          {},
          { finishCallback: $scope.sign, signer: $scope.signer }
        );
      };

      $scope.openVerifyBankAccountModal = function() {
        srVerifyBankAccountModal.activate({}, { signer: $scope.signer });
      };

      $scope.openDeclineModal = function() {
        srDeclineModal.activate({}, { save: $scope.save });
      };

      $scope.openLoginModal = function(data) {
        let options = data || {};
        options.next = '/#' + $location.url();
        return $vuex.dispatch('modals/showLoginModal', { ...options });
      };

      // make users wait until all images are loaded
      $scope.checkPagesLoaded = function() {
        var pagesLoading = false;
        var pagesLoadingError = false;
        angular.forEach($scope.doc.pages, function(page, key) {
          if (!page.loaded) {
            pagesLoading = true;
          }
          if (page.error_loading) {
            pagesLoadingError = true;
          }
        });
        $scope.loading = pagesLoading;
        $scope.error_loading = pagesLoadingError;
        if (pagesLoading && !pagesLoadingError) {
          $timeout($scope.checkPagesLoaded, 500);
        }
      };

      $scope.getSignerByEmail = function(email) {
        if (!email || !$scope.doc || !$scope.doc.signrequest) {
          return;
        }
        return _.find($scope.doc.signrequest.signers, { email: email });
      };

      $scope.doc = {};
      $scope.doc.signer_select_options = [];
      $scope.doc.last_selected_signer_index = null;
      // used in placeholder modal
      $scope.updateSignerSelectOptions = function() {
        $scope.doc.signer_select_options = [];
        angular.forEach($scope.doc.signrequest.signers, function(item) {
          var signer_data = {
            value: item.signer_index,
            text: $scope.formatSignerLabel(item)
          };
          if (
            item.action_required &&
            !item.is_owner &&
            $scope.doc.last_selected_signer_index === null
          ) {
            // preselect a signer
            $scope.doc.last_selected_signer_index = signer_data.value;
          }
          $scope.doc.signer_select_options.push(signer_data);
        });
        if (
          $scope.doc.last_selected_signer_index === null &&
          $scope.doc.signer_select_options.length
        ) {
          // always select one
          $scope.doc.last_selected_signer_index = _.last(
            $scope.doc.signer_select_options
          ).value;
        }
      };

      $scope.deleteSigner = function(signer) {
        var index = $scope.doc.signrequest.signers.indexOf(signer);
        if (index > -1) {
          angular.forEach($scope.doc.pages, function(page) {
            angular.forEach(
              _.filter(page.editors, {
                is_placeholder: true,
                placeholder: { signer_index: String(signer.signer_index) }
              }),
              function(editor) {
                page.editors.splice(page.editors.indexOf(editor), 1);
              }
            );
            angular.forEach(
              _.filter(page.editors, {
                is_placeholder: true
              }),
              function(editor) {
                const phIndex = Number(editor.placeholder.signer_index);
                if (phIndex > signer.signer_index) {
                  editor.placeholder.signer_index = String(phIndex - 1);
                }
              }
            );
          });

          $scope.doc.signrequest.signers.splice(index, 1);
          if (signer.signer_index === $scope.doc.last_selected_signer_index) {
            $scope.doc.last_selected_signer_index = null;
          }
          $scope.doc.signrequest.signers.forEach(function(signer, index) {
            signer.signer_index = index;
          });
          $scope.updateSignerSelectOptions();
        }
      };

      $scope.changeSignerLevel = function(signer, level) {
        if ($scope.prepare_doc) {
          signer.level = level;
          signer.needs_to_sign = !~['cc', 'notify'].indexOf(signer.level);
          $scope.setWho();
        }
      };

      $scope.setWho = function() {
        // only needed to change the sign & send button, doesn't change anything in the backend
        if ($scope.prepare_doc) {
          var sender_signer = $scope.getOwnerSigner();
          if (sender_signer && sender_signer.needs_to_sign) {
            $scope.doc.signrequest.who = 'm';
            if (
              _.find($scope.doc.signrequest.signers, function(signer) {
                return !signer.is_owner && signer.needs_to_sign;
              })
            ) {
              $scope.doc.signrequest.who = 'mo';
            }
          } else {
            $scope.doc.signrequest.who = 'o';
          }
        }
      };

      $scope.emailValid = true;
      $scope.doc.new_signer_email = '';
      $scope.addSigner = function() {
        if ($scope.loading) {
          return;
        }
        if ($scope.is_template) {
          // we are adding an signer conf
          var signer = docService.newSigner({
            signer_index: $scope.doc.signrequest.signers.length
          });
          $scope.doc.signrequest.signers.push(signer);
          $scope.doc.last_selected_signer_index = signer.signer_index;
        } else {
          if ($scope.doc.new_signer_email) {
            $scope.emailValid = utils.EMAIL_REGEXP.test(
              $scope.doc.new_signer_email
            );
            if ($scope.emailValid) {
              if (
                !_.some($scope.doc.signrequest.signers, [
                  'email',
                  $scope.doc.new_signer_email
                ])
              ) {
                $scope.doc.signrequest.signers.push(
                  docService.newSigner({
                    signer_index: $scope.doc.signrequest.signers.length,
                    email: $scope.doc.new_signer_email
                  })
                );
                $scope.doc.last_selected_signer_index =
                  $scope.doc.signrequest.signers.lenght - 1;
              }
              $scope.doc.new_signer_email = '';
            }
          }
        }
        $scope.updateSignerSelectOptions();
        $scope.setWho();
      };

      $scope.checkSignerLanguage = function() {
        // force a particular signer language from the backend
        if (
          $scope.signer &&
          $scope.signer.force_language &&
          $scope.signer.language
        ) {
          localeService.checkOrSwitchLang($scope.signer.language);
        }
      };

      $scope.getOwnerSigner = function() {
        return _.find($scope.doc.signrequest.signers, function(signer) {
          return signer.is_owner;
        });
      };

      $scope.getOwnerSignerLanguage = function() {
        var owner_signer = $scope.getOwnerSigner();
        if (owner_signer) {
          return owner_signer.language;
        }
        return undefined;
      };

      $scope.initDocResponse = function(response) {
        $scope.loading = true;
        $scope.doc = response.data.doc;
        $scope.doc.last_selected_signer_index = null;

        $scope.doc.prepare_doc = $scope.prepare_doc;
        $scope.is_download_all = response.data.doc.is_download_all;
        // needed for placeholder colors
        $scope.doc.getSignerIndexForEmail = $scope.getSignerIndexForEmail;
        $scope.doc.checkForSignatures = $scope.checkForSignatures;
        $scope.doc.scrollToNextPlaceholder = $scope.scrollToNextPlaceholder;
        $scope.doc.updateSignerSelectOptions = $scope.updateSignerSelectOptions;

        $scope.updateSignerSelectOptions();

        $scope.signer = response.data.doc.signer || {};
        $scope.checkSignerLanguage();
        $scope.next_from_server = response.data.next;
        $scope.next =
          utils.redirectFilter(response.data.next, 'close') || $scope.next;
        $scope.redirect_url = utils.redirectFilter(
          response.data.redirect_url || ''
        );

        $scope.signer.action_needed =
          $scope.signer.action_required &&
          !docService.isDeclined($scope.doc) &&
          !docService.isSigned($scope.doc);

        $scope.doc.date_format_locale = $scope.getOwnerSignerLanguage();

        if (
          !$scope.signer.action_needed &&
          $scope.doc.signrequest.has_in_person_signer &&
          $scope.next
        ) {
          // redirect to the next 'in person' signer as the owner doesn't need to sign
          // DISABLED TODO: Fix for in person
          //$window.location = $scope.next;
          // full reload needed
          //$window.location.reload();
        }

        $scope.checkSubject();

        $scope.updateEditors();

        $scope.senderNeedsToSign =
          _.filter($scope.doc.signrequest.signers, {
            is_owner: true,
            needs_to_sign: true
          }).length || false;

        if ($scope.signer.signed) {
          if (
            $scope.signer.verify_bank_account &&
            !$scope.signer.bank_account_verified
          ) {
            $scope.openVerifyBankAccountModal();
          }
        } else {
          // check if we need to add attachments
          $scope.attachmentsStillRequired({ check_on_server: true });

          UserService.waitForUser().then(async function() {
            const shouldAskLogin =
              $scope.user &&
              $scope.signer.has_account &&
              $scope.signer.needs_to_sign &&
              !$scope.user.logged_in &&
              !$scope.signer.in_person &&
              !$scope.prepare_doc &&
              !$scope.is_download_all;
            if (shouldAskLogin) {
              const loginData = {
                filterOauths: true,
                oauths: $scope.signer.oauths,
                hideClose: false,
                email: $scope.signer.email,
                disableRegister: true
              };

              const shouldShowLogin = await $vuex.dispatch(
                'modals/openConfirmationModal',
                {
                  title: gettextCatalog.getString('Welcome back!'),
                  body: gettextCatalog.getString(
                    'Please log in to use your saved signatures or sign document without logging in'
                  ),
                  confirmationButtonText: gettextCatalog.getString('Login'),
                  dismissButtonText: gettextCatalog.getString('Sign now')
                }
              );
              if (shouldShowLogin) {
                const loggedIn = await $scope.openLoginModal(loginData);
                if (loggedIn) {
                  return;
                }
              }
            }
            if ($scope.doc.signrequest.esign_disclosure) {
              $scope.confirmEsign();
            } else if (!$scope.signer.has_account) {
              $scope.openHowSignModal();
            }
          });
        }
        $scope.checkPagesLoaded();
      };

      $scope.bulkSend = function() {
        docService
          .saveTemplate($scope.doc.uuid, $scope.doc, true)
          .then(function() {
            if ($scope.is_sf_bulk_send) {
              docService
                .bulksendFromTemplate(
                  $scope.user.email,
                  $scope.template_uuid,
                  $scope.emaillist,
                  $scope.integration_data,
                  $scope.integration_type
                )
                .then(function(resp) {
                  window.close();
                });
            } else {
              docService
                .bulksendFromTemplate(
                  $scope.user.email,
                  $scope.template_uuid,
                  $scope.emaillist
                )
                .then(function(resp) {
                  $location.path('#/');
                });
            }
          });
      };

      $scope.openForwardDocumentModal = function() {
        if ($scope.doc.can_be_forwarded) {
          srForwardDocumentModal.activate(
            {},
            {
              doc: $scope.doc,
              signer: $scope.signer,
              onDocForwarded: function() {
                // Something goes wrong with retrieving the document and set it correct again, so we just reload it now
                window.location.reload();
                // $scope.getDocument();
                // if ($scope.editor_options_active){
                //   $scope.toggleSideBar();
                // }
                // $('html,body').animate({
                //   scrollTop: 0
                // }, 1000, 'easeInOutQuad');
              }
            }
          );
        }
      };

      $scope.getForwardedToSignerStatusName = function() {
        if ($scope.signer && $scope.signer.forwarded_to_email) {
          var signer = $scope.getSignerByEmail(
            $scope.signer.forwarded_to_email
          );
          if (signer && signer.name) {
            return signer.name + ' - ' + signer.status;
          }
        }
        return;
      };

      $scope.sendBulkSend = function() {
        $scope.loading = true;
        $scope.resetTemplateConfigs();
        docService
          .saveTemplate($scope.doc.uuid, $scope.doc, true)
          .then(() =>
            bulkSendService.bulk_send(
              $scope.template_uuid,
              $scope.bulksend_id,
              $scope.task_id,
              $scope.bulkSendResults,
              $scope.emailsPasted
            )
          )
          .then(function() {
            UserService.refreshUser();
            utils.navigateTo('/');
            $scope.loading = false;
          })
          .catch(() => {
            $scope.loading = false;
          });
      };

      $scope.bulkSendPrefilledSignerData = null;
      $scope.togglePrefillFromBulkSendData = function(signerData) {
        if (signerData && $scope.bulkSendPrefilledSignerData !== signerData) {
          $scope.bulkSendPrefilledSignerData = signerData;
          $rootScope.$emit(
            'prefillFromText',
            $scope.bulkSendPrefilledSignerData
          );
          $scope.resetTemplateConfigs();
          $scope.template_configs = {
            name: $scope.doc.name,
            message: $scope.doc.signrequest.message
          };
          $scope.doc.name = $scope.bulkSendPrefilledSignerData.name;
          $scope.doc.signrequest.message =
            $scope.bulkSendPrefilledSignerData.message;
        } else {
          $scope.bulkSendPrefilledSignerData = null;
          $rootScope.$emit('clearPrefillFromText');
          $scope.resetTemplateConfigs();
        }
      };

      $scope.resetTemplateConfigs = function() {
        if (
          angular.isDefined($scope.template_configs) &&
          angular.isDefined(
            $scope.template_configs.name || $scope.template_configs.message
          )
        ) {
          $scope.doc.name = $scope.template_configs.name;
          $scope.doc.signrequest.message = $scope.template_configs.message;
        }
      };

      $scope.bulkSendCallback = function(results) {
        $scope.bulkSendResultsReady = true;
        $scope.bulkSendResults = results.results;
        $scope.bulksend_id = results.bulksend_id;
        $scope.task_id = results.task_id;
        $scope.emailsPasted = results.emailsPasted;
        $scope.numberOfResults = results.results.length;
      };

      $scope.bulkSendTemplateModal = function() {
        srBulkSendModal.activate(
          {},
          {
            doc: $scope.doc,
            template_uuid: $scope.template_uuid,
            emails: $scope.emaillist,
            callback: $scope.bulkSendCallback,
            senderNeedsToSign: $scope.senderNeedsToSign
          }
        );
      };

      $scope.getDocument = function(reloading) {
        reloading = reloading || false;
        if ($scope.template_uuid) {
          // we are editing a template
          docService
            .getTemplate($scope.template_uuid)
            .then($scope.initDocResponse);

          if ($scope.is_bulk_send) {
            $scope.bulkSendTemplateModal();
          }
        } else {
          docService
            .getDocument(
              $scope.uuid,
              $scope.signer_uuid,
              $scope.signer_token,
              reloading
            )
            .then(function(resp) {
              if (
                resp &&
                resp.data &&
                angular.isDefined(resp.data.doc) &&
                resp.data.doc.status.code === 'co'
              ) {
                // can be still converting in case of embedded signing flow
                $timeout(function() {
                  reloading = true;
                  $scope.getDocument(reloading);
                }, 1000);
              } else {
                if (resp.status === 200) {
                  // document link expired, redirect to /expired
                  if (resp.data.expired) {
                    var url =
                      '/expired/?uuid=' +
                      $scope.uuid +
                      '&signer=' +
                      $scope.signer_uuid;
                    utils.navigateTo(url);
                  } else {
                    $scope.initDocResponse(resp);
                  }
                }
              }
            });
        }
      };

      $scope.getDocument();

      $scope.zoomPages = function(event, inc_dec) {
        //angular.element('html').css('overflow-x', 'auto');
        event.preventDefault();
        event.stopPropagation();
        var maxWidth = 0;
        angular.forEach($scope.doc.pages, function(page, key) {
          // if page.width is not defined the page hasn't loaded yet, so do noting
          if (
            angular.isDefined(page.initialWidth) &&
            angular.isDefined(page.initialHeight)
          ) {
            page.css = page.css || {};
            page.zoom += inc_dec;
            // Set a max and min value for the zoom level
            if (page.zoom > 8) {
              page.zoom = 8;
            } else if (page.zoom < 0.5) {
              page.zoom = 0.5;
            }
            var newWidth = page.initialWidth * page.zoom;

            page.css.width = newWidth;
            // If this new width is bigger than the biggest one, update maxWidth
            if (newWidth > maxWidth) {
              maxWidth = newWidth;
            }
            // these are used to scale and place the signatures
            var newHeight = page.initialHeight * page.zoom;
            page.width = newWidth;
            page.height = newHeight;

            // If the widest image is wider then the scrollable element, set the scroll-x position to the center.
            var scrollable_el = angular.element('.editor__pages');
            if (maxWidth > scrollable_el.width()) {
              $timeout(function() {
                scrollable_el.scrollLeft(
                  (maxWidth - scrollable_el.width()) / 2
                );
              }, 0);
            }
          }
        });

        $rootScope.$broadcast('update-fonts');
      };

      $scope.formatSignerLabel = function formatSignerLabel(signer) {
        if (!signer.email && !signer.label) {
          return signer.signer_index
            ? gettextCatalog
                .getString('Contact %d')
                .replace('%d', signer.signer_index)
            : gettextCatalog.getString('Sender (you)');
        }
        return signer.email;
      };

      $scope.updateEditors = function() {
        angular.forEach($scope.doc.pages, function(page, key) {
          page.zoom = 1;
          page.css = page.css || {};
          page.editors = page.editors || [];
          page.signer = $scope.signer; // needed to check if the signer is allowed to make changes
          angular.forEach(page.sigs, function(sig, key) {
            var editor = docService.newEditor({
              x_pos: sig.x_pos,
              y_pos: sig.y_pos,
              show: sig.editable && !$scope.signer.signed,
              loaded: false,
              sig: sig
            });
            editor.saved = true;
            page.editors.push(editor);
          });
          angular.forEach(page.placeholders, function(placeholder, key) {
            placeholder.editable = placeholder.editable && $scope.prepare_doc;
            if (placeholder.editable || placeholder.action_required) {
              // Only if we can edit this placeholder (during preparing) or when we need to fill this placeholder
              // we want to show it.
              var editor = docService.newEditor({
                x_pos: placeholder.x_pos,
                y_pos: placeholder.y_pos,
                show: false,
                loaded: false,
                is_placeholder: true,
                placeholder: placeholder
              });
              editor.saved = true;
              page.editors.push(editor);
            }
          });
        });
        $scope.checkForSignatures();
      };

      $scope.required_attachments_filled = false;
      $scope.unfilled_required_attachments = [];
      $scope.attachmentsStillRequired = function(conf) {
        if (
          $scope.doc &&
          $scope.doc.signrequest &&
          $scope.doc.signrequest.disable_attachments
        ) {
          return false;
        }
        conf = conf || {};
        if ($scope.doc && $scope.signer) {
          if (
            $scope.signer.action_needed &&
            ($scope.signer.required_attachments.length ||
              $scope.doc.signrequest.required_attachments.length)
          ) {
            if (conf.check_on_server) {
              docService
                .checkRequiredSignerAttachments(
                  $scope.doc.uuid,
                  $scope.signer.uuid
                )
                .then(function(response) {
                  $scope.unfilled_required_attachments =
                    response.data.required_attachments;
                  $scope.required_attachments_filled = !response.data
                    .required_attachments.length;
                });
            }

            if (!$scope.required_attachments_filled) {
              return true;
            }
          }
        }
        return false;
      };

      $scope.sign = function() {
        // check that we have a verified phone number
        if (
          $scope.signer.verify_phone_number &&
          !$scope.signer.phone_number_verified
        ) {
          // let's verify the number first, $scope.sign is called again from the modal as callback after verification
          // backend will not let someone sign when signer.verify_phone_number is set and not verified
          $scope.openVerifyPhoneModal();
          return;
        }
        // test first if we have all required attachments or we only need to approve
        if ($scope.attachmentsStillRequired({ check_on_server: true })) {
          messageService.add(
            'error',
            gettextCatalog.getString(
              'You need to add required attachments to finalize this document.'
            ),
            5000
          );
          // open options for mobile
          $scope.editor_options_active = true;
        } else if ($scope.next_from_server) {
          $scope.save(function() {});
        } else {
          $scope.openTermsAgreeModal(); // save is called in the modal
        }
      };

      $scope.openSignerAttachmentModal = function() {
        var callback;
        if ($scope.prepare_doc || $scope.is_template) {
          if (!UserService.hasPermOrModal('pro', 'add_attachment')) {
            return;
          }
          callback = $scope.loadDocumentAttachments;
        } else {
          callback = $scope.loadAttachments;
        }
        srUploadAttachmentModal.activate(
          {},
          {
            callback: callback,
            isDocumentAttachment: $scope.isDocumentAttachment,
            isTemplate: $scope.is_template
          }
        );
      };

      $scope.serializeRequiredAttachments = function() {
        angular.forEach($scope.doc.signrequest.signers, function(signer) {
          if (typeof signer.required_attachments === 'string') {
            signer.required_attachments = utils.parseValues(
              signer.required_attachments,
              ','
            );
          }
        });
      };

      $scope.addRequiredAttachmentToSigner = function(signer, attachment) {
        signer.required_attachments = signer.required_attachments || [];
        signer.required_attachments.push(attachment);
      };

      $scope.openRequiredSignerAttachmentModal = function(signer) {
        srRequireAttachmentModal.activate(
          {},
          {
            callback: $scope.addRequiredAttachmentToSigner,
            signer: signer
          }
        );
      };

      $scope.setPasswordforSigner = function(signer, password) {
        signer.password = password;
      };

      $scope.openSetPasswordModal = function(signer) {
        srSetPasswordModal.activate(
          {},
          {
            callback: $scope.setPasswordforSigner,
            signer: signer
          }
        );
      };

      $scope.openHelp = function(selector) {
        if ($scope.hasSignature) {
          angular.element(selector).tooltipster('hide');
        } else {
          angular.element(selector).click();
        }
      };

      $scope.checkSubject = function() {
        if (!$scope.doc || !$scope.doc.signrequest) {
          return;
        }
        if (
          !$scope.doc.signrequest.subject &&
          $scope.doc.signrequest.default_subject
        ) {
          $scope.doc.signrequest.subject =
            $scope.doc.signrequest.default_subject;
        }
      };

      $scope.saveDocPrepare = function(conf) {
        conf = conf || { background: false, from_keyboard: false };
        $scope.loading = true;
        var deferred = $q.defer();
        if ($scope.is_template) {
          if ($scope.is_bulk_send) {
            // we might save the template but in that case we do not want to
            // by passing no signer data this clears the prefilled signer data in the template so we can
            // properly/safely save it
            $scope.togglePrefillFromBulkSendData(null);
          }
          docService
            .saveTemplate($scope.doc.uuid, $scope.doc, conf.background)
            .then(
              function(response) {
                $scope.loading = false;
                if (conf.from_keyboard) {
                  docService.getTemplates().then(function(resp) {
                    $rootScope.user.templates = resp.data.templates;
                    $vuex.commit('users/setUserTemplates', resp.data.templates);
                  });
                } else {
                  utils.navigateTo('/templates');
                }
                deferred.resolve(response);
              },
              function() {
                $scope.loading = false;
                deferred.reject();
              }
            );
        } else {
          docService
            .saveDocPrepare(
              $scope.doc.uuid,
              $scope.signer_uuid,
              $scope.doc,
              conf.background
            )
            .then(
              function(response) {
                if (response.data.status === 'SUCCESS') {
                  $scope.loading = conf.background;
                  deferred.resolve(response);
                } else {
                  $scope.loading = false;
                  deferred.reject();
                }
              },
              function(response) {
                $scope.loading = false;
                deferred.reject();
              }
            );
        }
        return deferred.promise;
      };

      $scope.wantToClose = function() {
        if ($scope.next && $scope.next === 'close') {
          // generally we want to close it
          if ($scope.prepare_doc && $scope.redirect_url) {
            // but not if we are preparing and we have to sign ourselves
            return false;
          }
          return true;
        }
        return false;
      };

      $scope.doClose = function() {
        // we are using some integration and we just want (and can as JS opened this window) close this window
        $location.path('/');
        // we wait 3 secs for the confirm message to appear and close after that
        $timeout(function() {
          utils.closeWindow();
        }, 3000);
      };

      $scope.doNext = function() {
        // called when we are done with this view
        // checks where we want the user to go based on the 'next' param and/or the redirect_url we got from the server
        // the special term 'close' make the window close with a timeout
        if ($scope.redirect_url) {
          // we got a redirect url from the server where we need to go next (we always respect the backend choice first)
          // user by API users to go to some page or when we are preparing or from us to go to the signing page
          utils.navigateTo($scope.redirect_url);
        } else if (
          $scope.signer.needs_email_confirm &&
          !$scope.signer.phone_number_verified &&
          !$rootScope.is_demo &&
          !$scope.signer.declined
        ) {
          // Signer has to verify mail when they didn't verify a phone number
          $rootScope.unregisteredSigner = $scope.signer;
          utils.navigateTo(
            `/confirm?config=${utils.makeEncodedUrlParam({
              email: $scope.signer.email
            })}`
          );
        } else if ($scope.next) {
          // we only use the next param if we didn't get a redirect url from the server
          if ($scope.wantToClose()) {
            $scope.doClose();
          } else {
            utils.navigateTo($scope.next);
          }
        } else if ($scope.user.logged_in || $scope.signer.in_person) {
          // if we are logged in or we are preparing we should go to home
          $location.path('/');
        } else {
          $rootScope.unregisteredSigner = $scope.signer;
          // others we want to register
          $rootScope.is_demo = false;
          const donePath =
            '/complete' + ($scope.signer.declined ? '?declined=1' : '');
          $location.path(donePath);
        }
      };

      $scope.showNext = function() {
        if ($scope.next && $scope.next !== 'close') {
          return true;
        }
        return false;
      };

      $scope.createTemplate = function(doc_uuid) {
        if ($scope.loading) {
          return;
        }

        UserService.hasPermOrModal(
          $scope.doc.perm,
          'create_template',
          function() {
            $scope.loading = true;
            $scope
              .saveDocPrepare({ background: true })
              .then(function(response) {
                if (response.data.status === 'SUCCESS') {
                  docService
                    .createTemplateFromDoc(doc_uuid)
                    .then(function(resp) {
                      if (
                        angular.isObject(resp.data.doc) &&
                        resp.data.doc.template_url
                      ) {
                        // open the template in a new tab
                        $window.open(resp.data.doc.template_url, '_blank');
                      }
                      $scope.loading = false;

                      docService.getTemplates().then(function(resp) {
                        $rootScope.user.templates = resp.data.templates;
                        $vuex.commit(
                          'users/setUserTemplates',
                          resp.data.templates
                        );
                      });
                      $scope.saveDocPrepare();
                    });
                }
              });
          }
        );
      };

      $scope.checkReminders = function($event) {
        UserService.waitForUser().then(function() {
          if (!UserService.hasPermOrModal($scope.doc.perm, 'send_reminders')) {
            $scope.doc.signrequest.send_reminders = false;
          }
        });
      };

      $scope.checkAttachments = function($event) {
        UserService.waitForUser().then(function() {
          if (!UserService.hasPermOrModal($scope.doc.perm, 'add_attachment')) {
            $scope.doc.signrequest.allow_attachments = !$scope.doc.signrequest
              .allow_attachments;
          }
        });
      };

      $scope.$watch('signer_uuid', function(signer_uuid) {
        $vuex.commit('users/setSignerFromAngular', signer_uuid);
        $scope.refreshUserForSigner(); // lives in user.js
      });

      $scope.$watch('hasSignature', function(n, o) {
        if (n === o) {
          return;
        }
        var tooltip = angular.element('#finalizeHelp.tooltipstered');
        if (n) {
          tooltip.tooltipster('disable');
        } else {
          tooltip.tooltipster('enable');
        }
      });

      $scope.sendSignRequest = function(event) {
        event.preventDefault();
        if ($scope.prepare_doc) {
          $scope.loading = true;
          $scope.saveDocPrepare({ background: true }).then(function(response) {
            if (response.data.status === 'SUCCESS') {
              if ($scope.next_from_server) {
                utils.navigateTo($scope.next_from_server);
              } else {
                docService
                  .sendSignRequest(
                    $scope.doc.uuid,
                    $scope.signer_uuid,
                    $scope.doc
                  )
                  .then(function(response) {
                    $scope.loading = false;

                    if (response.data.status === 'SUCCESS') {
                      $location.search({}).replace();
                      if (response.data.redirect_url) {
                        $scope.redirect_url = response.data.redirect_url;
                      }
                      if (response.data.force_reload) {
                        $scope.force_reload = response.data.force_reload;
                      }
                      $scope.doNext();
                      windowService.emit('sent', { doc_uuid: $scope.doc.uuid });
                    }
                  });
              }
            }
          });
        }
        return false;
      };

      $scope.decline = function() {
        $scope.openDeclineModal();
      };

      $scope.publicSigningLinkUsed = function() {
        return $scope.doc.template && $scope.doc.template.public_url;
      };

      $scope.updateUserAfterFinish = function() {
        if ($scope.signer) {
          if (
            $scope.signer.in_person ||
            $scope.doc.signrequest.has_in_person_signer ||
            $scope.publicSigningLinkUsed()
          ) {
            // user reload needed as we don't want signatures to hang around in the browser
            $scope.getUserAfterRouteChange();
          }
        }
      };

      $scope.updateCounter = function() {
        return $vuex.dispatch('documents/getRequiredActionsCount');
      };

      $scope.goHome = function() {
        $scope.updateUserAfterFinish();
        utils.navigateTo('/');
      };

      $scope.getSignerColor = function(index) {
        return `color: ${getSignerColor(index)};`;
      };

      $scope.showHint = function() {
        messageService.add(
          'warning',
          gettextCatalog.getString('Click in the document to add signature'),
          3000
        );
      };

      $scope.confirmEsign = async function() {
        const esignOk = await srTermsAgreeModal.activate(
          {},
          {
            hideClose: true,
            disable_terms: true,
            esign_disclosure: $scope.doc.signrequest.esign_disclosure
          }
        );
        if (esignOk) {
          return;
        }
        const { declined, message } =
          (await srDeclineModal.activate({}, {})) || {};
        if (!declined) {
          return $timeout(() => $scope.confirmEsign(), 0);
        }

        $scope.save(() => srTermsAgreeModal.deactivate(), message, declined);
      };

      $scope.save = function(callback, message, declined) {
        message = message || null;
        declined = angular.isDefined(declined) ? declined : false;
        $scope.signer.declined = declined;

        $scope.loading = true;
        var sigs = [];
        angular.forEach($scope.doc.pages, function(page, key) {
          angular.forEach(page.editors, function(editor, key) {
            if (editor.sig && editor.sig.data_uri && editor.sig.editable) {
              editor.saved = true;
              sigs.push(editor.sig);
            }
          });
        });
        if (sigs.length < 1 && !$scope.signer.approve_only && !declined) {
          // change the translations also in partials/fake_trans_strings.html if needed
          messageService.add(
            'error',
            gettextCatalog.getString('You have not added any signatures.'),
            3000
          );
          srTermsAgreeModal.deactivate();
          $scope.openHowSignModal();
          $scope.loading = false;
        } else {
          docService
            .saveSigs({
              doc_uuid: $scope.doc.uuid,
              signer_uuid: $scope.signer.uuid,
              sigs: sigs,
              message: message,
              declined: declined,
              signer_token: $scope.signer_token
            })
            .then(async function(response) {
              callback();
              $scope.loading = false;
              if (response.data && response.data.status === 'SUCCESS') {
                if (declined) {
                  messageService.add(
                    'warning',
                    gettextCatalog.getString('You have declined the document.'),
                    10000
                  );
                  windowService.emit('declined', { doc_uuid: $scope.doc.uuid });
                } else {
                  if ($scope.doc.signrequest.disable_emails) {
                    messageService.add(
                      'success',
                      gettextCatalog.getString('All done!'),
                      10000
                    );
                  } else {
                    messageService.add(
                      'success',
                      gettextCatalog.getString(
                        'You will receive an email with the signed document and signing log after all parties have signed.'
                      ),
                      10000
                    );
                  }
                  windowService.emit('signed', { doc_uuid: $scope.doc.uuid });
                }

                windowService.emit('finished', { doc_uuid: $scope.doc.uuid });

                // Update redirect url if it is set
                if (!$scope.next_from_server && response.data.redirect_url) {
                  $scope.redirect_url = response.data.redirect_url;
                }

                await UserService.trackEvent(
                  'SignRequest finished',
                  declined ? 'declined' : 'signed',
                  {
                    signed: !declined,
                    is_demo: $rootScope.is_demo,
                    next: $scope.next,
                    redirect_url: $scope.redirect_url,
                    has_account: $scope.signer.has_account,
                    in_person: $scope.signer.in_person,
                    is_owner: $scope.signer.is_owner,
                    language: $scope.signer.language,
                    needs_to_sign: $scope.signer.needs_to_sign,
                    approve_only: $scope.signer.approve_only,
                    is_prepared: $scope.doc.is_prepared
                  }
                );

                await $scope.updateCounter();
                $scope.updateUserAfterFinish();

                if (
                  $scope.signer.verify_bank_account &&
                  !$scope.signer.bank_account_verified &&
                  !declined
                ) {
                  $scope.openVerifyBankAccountModal();
                } else {
                  $scope.doNext();
                }
              } else if (response.data && !response.data.status) {
                messageService.add('error', 'E_OOPS');
              }
            });
        }
      };

      $scope.getNextPlaceholderElement = function() {
        var phs = _.sortBy($('.unfilled-placeholder.show-arrow'), function(
          item
        ) {
          return $(item).offset().top;
        });
        return phs[0];
      };

      $scope.scrollingToPlaceholder = false;
      $scope.scrollToNextPlaceholder = function() {
        var el = $scope.getNextPlaceholderElement();
        if (el) {
          if (!utils.isElementInViewport(el)) {
            el = $(el);
            var elOffset = el.offset().top;
            var elHeight = el.height();
            var windowHeight = $($window).height();
            var offset;
            if (elHeight < windowHeight) {
              offset = elOffset - (windowHeight / 2 - elHeight / 2);
            } else {
              offset = elOffset;
            }
            var duration = Math.min(
              Math.max(Math.abs(parseInt($window.scrollY - offset)), 500),
              2000
            );
            if (!$scope.scrollingToPlaceholder) {
              $scope.scrollingToPlaceholder = true;
              $('html,body').animate(
                {
                  scrollTop: offset
                },
                duration,
                'easeInOutQuad',
                function() {
                  $scope.scrollingToPlaceholder = false;
                }
              );
            }
          }
        }
      };

      $scope.arrowClass = 'bottom';
      $scope.getArrowStyle = function() {
        if ($scope.loading || $scope.prepare_doc) {
          return;
        }
        var el = $scope.getNextPlaceholderElement();
        if (el) {
          if (utils.isElementInViewport(el)) {
            el = $(el);
            var offset = el.offset();
            $scope.arrowClass = 'sr-arrow-right';
            return {
              position: 'absolute',
              display: 'block',
              top: offset.top + el.height() / 2 - 20 + 'px',
              left: offset.left - 60 + 'px'
            };
          } else if (el.getBoundingClientRect().top < 0) {
            $scope.arrowClass = 'sr-arrow-top';
            return {
              position: 'fixed',
              display: 'block',
              top: '55px',
              left: '50%',
              'margin-left': '-20px'
            };
          }
          $scope.arrowClass = 'sr-arrow-bottom';
          return {
            position: 'fixed',
            display: 'block',
            bottom: '50px',
            left: '50%',
            'margin-left': '-20px'
          };
        } else if (
          $scope.signer.action_needed &&
          ($scope.signer.approve_only ||
            $scope.hasSignature ||
            $scope.doc.is_prepared)
        ) {
          if ($scope.attachmentsStillRequired()) {
            if (!$scope.editor_options_active) {
              $scope.toggleSideBar();
            }

            // we need to add attachments, got to that button
            var attachment_button = $('#add-attachment');
            var attachment_button_offset = attachment_button.offset();
            $scope.arrowClass = 'sr-arrow-top sr-arrow--on-top';
            return {
              position: 'absolute',
              display: 'block',
              top:
                attachment_button_offset.top +
                attachment_button.height() +
                20 +
                'px',
              left:
                attachment_button_offset.left +
                attachment_button.width() / 2 -
                5 +
                'px'
            };
          } else {
            // we are finished, move arrow to finalize / approve / next button
            var finish_button = $('.editor__submit:visible').last();
            var finish_button_offset = finish_button.offset();
            $scope.arrowClass = 'sr-arrow-top sr-arrow--on-top';
            return {
              position: 'fixed',
              display: 'block',
              top: '55px',
              left:
                finish_button_offset.left +
                (finish_button.width() / 2 - 5) +
                'px'
            };
          }
        }
      };

      var updateArrowPosition = function() {
        $scope.$apply($scope.getArrowStyle);
      };

      $($window).on(
        'resize.sr-arrow scroll.sr-arrow',
        _.debounce(updateArrowPosition, 20, { maxWait: 20 })
      );

      $('.editor__pages').on(
        'scroll.sr-arrow',
        _.debounce(updateArrowPosition, 20, { maxWait: 20 })
      );

      Mousetrap.bind(
        ['ctrl+s', 'command+s'],
        function(event) {
          if ($scope.prepare_doc) {
            event.preventDefault();
            $scope.saveDocPrepare({ from_keyboard: true });
            return false;
          }
          return true;
        },
        'keydown'
      );
      $scope.$on('$destroy', function() {
        $scope.updateUserAfterFinish();
        $($window).off('resize.sr-arrow scroll.sr-arrow');
        $('.editor__pages').off('scroll.sr-arrow');
        Mousetrap.unbind(['ctrl+s', 'command+s'], 'keydown');
      });
    }
  ])
  .controller('SignerAttachmentsCtrl', [
    '$scope',
    'docService',
    '$route',
    'srUploadAttachmentModal',
    'UserService',
    function($scope, docService, $route, srUploadAttachmentModal, UserService) {
      if ($scope.isVueSignbox) {
        return;
      }

      if ($scope.prepare_doc) {
        $scope.loading = false;
        $scope.isDocumentAttachment = true;
      } else {
        $scope.loading = true;
        $scope.isDocumentAttachment = false;
      }
      $scope.doc_uuid = $route.current.params.uuid;
      $scope.signer_uuid = $route.current.params.signer_uuid;
      $scope.required_attachments = [];
      $scope.signer_attachments = [];

      $scope.loadDocumentAttachments = function() {
        if ($scope.is_template) {
          docService
            .getTemplateAttachments($scope.template_uuid)
            .then(function(response) {
              $scope.loading = false;
              if (!response || !response.data) {
                return;
              }
              $scope.signer_attachments = response.data.document_attachments;
            });
        } else {
          docService
            .getDocumentAttachments(
              $scope.doc_uuid,
              $scope.signer_uuid,
              $scope.signer_token
            )
            .then(function(response) {
              $scope.loading = false;
              if (!response || !response.data) {
                return;
              }
              if ($scope.prepare_doc) {
                $scope.signer_attachments = response.data.document_attachments;
              } else {
                $scope.document_attachments =
                  response.data.document_attachments;
              }
            });
        }
      };

      $scope.loadAttachments = function() {
        if ($scope.is_template) {
          return;
        }
        docService
          .getSignerAttachments(
            $scope.doc_uuid,
            $scope.signer_uuid,
            $scope.signer_token
          )
          .then(function(response) {
            $scope.loading = false;
            if (!response || !response.data) {
              return;
            }
            $scope.required_attachments = response.data.required_attachments;
            $scope.signer_attachments = response.data.signer_attachments;
            // call in parent scope to update if we need them still to sign
            $scope.attachmentsStillRequired({ check_on_server: true });
          });
      };

      if (!$scope.prepare_doc || $scope.isDocumentAttachment) {
        $scope.loadAttachments();
        $scope.loadDocumentAttachments();
      }

      $scope.deleteSignerAttachment = function(signer_attachment) {
        $scope.loading = true;
        if ($scope.is_template) {
          docService
            .deleteTemplateAttachment(signer_attachment.uuid)
            .then(function(response) {
              $scope.signer_attachments = response.data.signer_attachments;
              $scope.loading = false;
              $scope.attachmentsStillRequired({ check_on_server: true });
            });
        } else {
          docService
            .deleteSignerAttachment(
              signer_attachment.uuid,
              $scope.signer_uuid,
              $scope.isDocumentAttachment
            )
            .then(function(response) {
              $scope.required_attachments = response.data.required_attachments;
              if ($scope.isDocumentAttachment) {
                $scope.signer_attachments = response.data.document_attachments;
              } else {
                $scope.signer_attachments = response.data.signer_attachments;
              }
              $scope.loading = false;

              $scope.attachmentsStillRequired({ check_on_server: true });
            });
        }
      };

      $scope.openSignerAttachmentModal = async function() {
        var callback;
        if ($scope.prepare_doc || $scope.is_template) {
          await UserService.waitForUser();
          if (!UserService.hasPermOrModal($scope.doc.perm, 'add_attachment')) {
            return;
          }
          callback = $scope.loadDocumentAttachments;
        } else {
          callback = $scope.loadAttachments;
        }

        srUploadAttachmentModal.activate(
          {},
          {
            required_attachments: $scope.required_attachments,
            unfilled_required_attachments: $scope.unfilled_required_attachments,
            callback: callback,
            isDocumentAttachment: $scope.isDocumentAttachment,
            isTemplate: $scope.is_template
          }
        );
      };
    }
  ]);
