<template>
  <v-dialog
    ref="modal"
    v-model="showModal"
    lazy
    max-width="443"
    :content-class="contentClass"
  >
    <v-form ref="form" v-model="formValid" @submit.prevent="handleSubmit">
      <v-card
        role="dialog"
        :aria-hidden="!showModal"
        :aria-modal="showModal"
        aria-labelledby="terms-gmodal-title"
        :class="{
          mobile: $isMobile
        }"
      >
        <sr-button
          class="close"
          sr-style="text"
          data-test-id="close-verify-btn"
          @click="closeModal(false)"
        >
          <v-icon
            aria-label="$gettext('Close confirmation dialog')"
            class="round"
          >
            close
          </v-icon>
        </sr-button>
        <v-card-title id="terms-gmodal-title" class="headline">
          <span key="verify" v-translate>
            Please verify your phone number
          </span>
        </v-card-title>
        <v-card-text>
          <p v-if="text_sent" key="s" v-translate>
            A text message with the verification code has been sent to:
          </p>
          <p v-else key="a" v-translate>
            We will send a verification code to:
          </p>
        </v-card-text>
        <sr-tel-input
          ref="phone_number"
          v-model="phone_number"
          name="phone_number"
          :label="$gettext('Text message verification')"
          :rules="[fieldRequired]"
          :readonly="Boolean(modalOptions.verify_phone_number)"
        />
        <sr-input
          v-if="text_sent"
          ref="verification_code"
          v-model="verification_code"
          name="verification_code"
          class="verification-code-input"
          :label="$gettext('Enter the verification code')"
          :rules="[fieldRequired]"
        />
        <v-card-actions v-if="text_sent" key="sent">
          <sr-button
            sr-style="cancel"
            :disabled="sendTimer > 0"
            :loading="isLoading"
            round
            data-test-id="resend-verify-btn"
            @click="handleSendTextVerificationMessage"
          >
            <translate>
              Resend
            </translate>
            <span v-if="sendTimer > 0">({{ sendTimer }})</span>
          </sr-button>
          <sr-button
            :loading="isLoading"
            sr-style="fancy"
            round
            data-test-id="verify-btn"
            type="submit"
          >
            <translate>Verify</translate>
          </sr-button>
        </v-card-actions>
        <v-card-actions v-else key="send" class="presend">
          <sr-button
            sr-style="fancy"
            round
            :loading="isLoading"
            data-test-id="verify-send-btn"
            type="submit"
          >
            <translate>Send text message</translate>
          </sr-button>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import SrButton from '@/components/elements/SrButton';
import ModalMixin from '@/components/modals/ModalMixin';
import Screen from '@/mixins/Screen';
import SrInput from '@/components/elements/SrInput';
import SrTelInput from '@/components/elements/SrTelInput';

const timerSeconds = 120;

function initialState() {
  return {
    sendTimer: timerSeconds,
    timer: null,
    verification_code: '',
    phone_number_verified: false,
    phone_number: '',
    text_sent: false,
    formValid: false
  };
}

export default {
  components: { SrButton, SrInput, SrTelInput },
  mixins: [ModalMixin('verifyPhone'), Screen],
  props: {
    contentClass: { type: String, default: '' }
  },
  data: function() {
    return initialState();
  },
  computed: {
    ...mapGetters('api', ['isLoading']),
    returnValue: {
      set() {
        this.phone_number_verified = false;
      },
      get() {
        return this.phone_number_verified;
      }
    },
    fieldRequired() {
      return value => (value ? true : this.$gettext('This field is required'));
    }
  },
  watch: {
    showModal(value, oldValue) {
      if (value) {
        this.$nextTick(() => this.focusFirst());
        this.phone_number = this.modalOptions.verify_phone_number || null;
      } else {
        this.timer ? clearTimeout(this.timer) : null;
        this.resetData();
      }
    },
    sendTimer: {
      immediate: true,
      handler(value) {
        clearTimeout(this.timer);

        if (value <= 0) {
          return;
        }
        this.timer = setTimeout(() => {
          this.sendTimer--;
        }, 1000);
      }
    }
  },
  methods: {
    ...mapActions('signrequest', [
      'sendTextVerificationMessage',
      'verifySignerPhone'
    ]),
    resetData: function() {
      Object.assign(this.$data, initialState());
    },
    focusFirst() {
      if (!this.text_sent && !this.modalOptions.verify_phone_number) {
        this.$refs.phone_number.focus();
      } else if (this.text_sent) {
        this.$refs.verification_code.focus();
      }
    },
    handleSubmit() {
      this.text_sent
        ? this.handleVerifySignerPhone()
        : this.handleSendTextVerificationMessage();
    },
    async handleVerifySignerPhone() {
      if (!this.verification_code) {
        return;
      }
      this.phone_number_verified = await this.verifySignerPhone({
        code: this.verification_code,
        signer_uuid: this.modalOptions.signer_uuid
      });
      if (this.phone_number_verified) {
        this.closeModal();
      }
    },

    async handleSendTextVerificationMessage() {
      await this.sendTextVerificationMessage({
        signer_uuid: this.modalOptions.signer_uuid
      });
      this.sendTimer = timerSeconds;
      this.text_sent = true;
    }
  }
};
</script>

<style scoped>
button.sr-button {
  font-size: 18px;
  padding: 0 3rem;
  margin: 0px;
  height: 4rem;
  width: calc(50% - 0.75rem);
}

>>> .v-dialog {
  border-radius: 25px;
}
.v-card {
  padding: 0 3rem;
}
.mobile.v-card {
  padding: 0 2rem;
}
.v-card__title {
  padding: 0;
  padding-top: 4rem;
  padding-bottom: 2rem;
  font-weight: 900;
  font-size: 24px;
  line-height: 1.33;
}
.v-card__text {
  font-size: 17px;
  padding: 0;
}
.v-card__text p {
  margin-top: 0;
  margin-bottom: 24px;
  line-height: 1.33;
}
.v-card__text label,
.v-card__text span.form--error {
  display: block;
  font-size: 16px;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.27px;
}
.v-card__text label a {
  text-decoration: underline;
}

.v-card__actions {
  padding: 0px;
  padding-top: 38px;
  padding-bottom: 36px;
  justify-content: space-between;
}
.presend.v-card__actions {
  justify-content: center;
}
.presend.v-card__actions button {
  width: 250px;
}

button.sr-button.close {
  font-size: 14px;
  position: absolute;
  min-width: 1em;
  width: 1em;
  height: 1em;
  top: 2rem;
  color: #111111;
  opacity: 0.8;
  margin: 0;
  padding: 0;
}
.ltr .close {
  right: 2rem;
}
.rtl .close {
  left: 2rem;
}

>>> .verification-code-input.sr-field {
  margin: 0 -3rem;
  padding: 0 3rem;
  outline-offset: -0.5rem !important;
}
>>> .verification-code-input.sr-field::before,
>>> .verification-code-input.sr-field::after {
  position: absolute;
  content: '';
  border-top: 1px solid #d6d6d6;
  opacity: 0.84;
  left: 0;
  right: 0;
  transform: scaleY(0.5);
}
>>> .verification-code-input.sr-field::after {
  top: 0;
}
>>> .verification-code-input.sr-field::before {
  bottom: 0;
}
>>> .intl-tel-input .country-list {
  max-height: 130px;
}
</style>
