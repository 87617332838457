import { VMessages } from 'vuetify/lib/components/VMessages';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._g({staticClass:"sr-field",class:{
    'sr-field--empty': _vm.isEmpty,
    'sr-field--has-placeholder': _vm.hasPlaceholder,
    'sr-field--has-error': _vm.validations,
    'sr-field--horizontal': _vm.horizontal,
    'sr-field--vertical': !_vm.horizontal,
    'sr-field--prefix': _vm.$slots['append-prefix']
  }},_vm.$listeners),[_c('label',{staticClass:"srtext--text",class:{ 'error--text': _vm.validations },attrs:{"for":_vm.labelFor},on:{"click":function($event){return _vm.$emit('click:label')}}},[_vm._t("label",function(){return [_c('span',[_vm._v(_vm._s(_vm.label))])]}),(_vm.help)?_c('help-hint',{attrs:{"visible":"","bottom":""}},[_vm._v(_vm._s(_vm.help))]):_vm._e()],2),_c('div',{staticClass:"sr-field--control"},[_vm._t("append-prefix"),_vm._t("default"),_c('div',{staticClass:"sr-field--error",attrs:{"aria-hidden":!_vm.validations}},[(_vm.validations)?_c(VMessages,{class:{ 'error--text': _vm.validations },attrs:{"id":(_vm.labelFor + "--error"),"value":_vm.validations}}):_vm._e()],1),_vm._t("append-inner")],2),_vm._t("append-outer")],2)}
var staticRenderFns = []

export { render, staticRenderFns }