import angular from 'angular';

/**
 * @ngdoc function
 * @name frontendApp.controller:AccountCtrl
 * @description
 * # AccountCtrl
 * Controller of the frontendApp
 */
angular.module('frontendApp').controller('AccountCtrl', [
  '$scope',
  '$rootScope',
  'UserService',
  'docService',
  'gettextCatalog',
  'utils',
  'confService',
  function(
    $scope,
    $rootScope,
    UserService,
    docService,
    gettextCatalog,
    utils,
    confService
  ) {
    $scope.UserService = UserService;
    $scope.confService = confService;

    $scope.add_amount = 50.0;
    $scope.loading = false;

    $scope.isOwner = false;

    $scope.cancelDeleteUser = function() {
      $scope.loading = true;
      UserService.cancelDeleteUser().then(function(response) {
        $scope.loading = false;
        $scope.user.auto_termination_after =
          response.data.auto_termination_after;
      });
    };

    $scope.logoutUser = function() {
      $scope.loading = true;
      UserService.logout();
    };

    $scope.delAltEmail = function(alt_email) {
      UserService.delAlternativeEmail(alt_email.email).then(function() {
        $scope.user.alt_emails.splice(
          $scope.user.alt_emails.indexOf(alt_email),
          1
        );
      });
    };

    $scope.emailValid = true;
    $scope.altEmailData = { alternativeEmail: '' };
    $scope.addAltEmail = function() {
      if ($scope.altEmailData.alternativeEmail) {
        $scope.emailValid = utils.EMAIL_REGEXP.test(
          $scope.altEmailData.alternativeEmail
        );
        if ($scope.emailValid) {
          UserService.addAlternativeEmail(
            $scope.altEmailData.alternativeEmail
          ).then(function() {
            $scope.altEmailData.alternativeEmail = '';
            $scope.emailValid = true;
          });
        }
      } else {
        $scope.emailValid = false;
      }
    };
  }
]);
