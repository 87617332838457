<template>
  <div
    class="sr-color-drop"
    :class="{
      'color-menu-enabled': colorMenuEnabled,
      'color-menu-open': keyMenuIsOpen
    }"
    @focusin="lazyFocusKeep"
    @focusout="lazyFocusOut"
    @hoverin="keyMenuOpen"
  >
    <div
      class="color-menu"
      role="menu"
      :aria-label="$gettext('Signature color')"
    >
      <button
        v-for="(color, idx) in activeColors"
        :key="color.code"
        role="menuitem"
        type="button"
        :class="{ active: value === color.code }"
        :title="color.name"
        :data-color-id="color.code"
        :disabled="!colorMenuEnabled"
        :tabindex="idx ? -1 : 0"
        @click="onClick(color.code, $event)"
        @keydown="keyMenuKeyboard"
      >
        <div class="focus-circle" :style="`color: ${color.code};`">
          <span class="color-circle"></span>
        </div>

        <v-icon class="primary--text">
          keyboard_arrow_down
        </v-icon>
      </button>
    </div>
  </div>
</template>

<script>
import sortBy from 'lodash/sortBy';
import KeyMenu from '@/mixins/KeyMenu';
import LazyFocus from '@/mixins/LazyFocus';

export default {
  mixins: [KeyMenu(), LazyFocus('keyMenuClose', 'keyMenuOpened')],
  props: {
    name: { type: String, required: true },
    value: { type: String, required: true },
    colors: { type: Array, required: true }
  },
  computed: {
    activeColors() {
      /* put the active element up front */
      return sortBy(this.colors, color => color.code !== this.value);
    },
    colorMenuEnabled() {
      return this.colors && this.colors.length > 1;
    }
  },
  methods: {
    focusFirst() {
      this.keyMenuClose();
    },
    colorId(color) {
      return this.name + color.replace('#', '_');
    },
    onClick(color, event) {
      this.$emit('input', color);
      this.keyMenuToggle();
    }
  }
};
</script>

<style scoped>
.sr-color-drop {
  /* this needs to match the size of the first menu item */
  /* to give the illusion of seemless menu */
  position: relative;

  /* if width is not set, the entire thing will shift on absolute menu */
  width: 3rem;

  /* height is a bit nightmarish...
     we can't use flex+center cause color-menu changes height
     instead it should match the first <a>
  */
  height: 2.5rem;
}

.sr-color-drop:not(.color-menu-enabled) {
  width: 1.5rem;
}

.color-menu {
  z-index: 15;
  background-color: white;
  border-radius: 4px;

  transition: all 0.4s;
  width: 100%;
}

.color-menu-enabled .color-menu:hover,
.color-menu-enabled.color-menu-open .color-menu {
  position: absolute;
  top: 0px;
  right: 0px;

  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.color-menu button {
  display: none;
  padding: 0.5rem; /* focus-border is between */
  text-decoration: none;

  height: 2.5rem;
  align-items: center;
}

.color-menu button.active {
  display: flex;
}

.color-menu-enabled .color-menu:hover button {
  /* becomes visible on hover */
  display: flex;
}

.color-menu-enabled.color-menu-open .color-menu button {
  /* this wont work on ie, but ie can use mouse to do it */
  display: flex;
}

.color-menu button .focus-circle {
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 50%;
  display: block;

  /* rems suck because somehow browser does not know
  how to calculate scale(0.75) * 1rem; */
  width: 16px;
  height: 16px;
  opacity: 0.5;
  font-size: 0px;
  flex-shrink: 0;
}

.color-menu-enabled .color-menu button:focus .focus-circle,
.color-menu-enabled .color-menu button:hover .focus-circle {
  border: 1px solid currentColor;
}

.color-menu button span,
.color-menu button .v-icon {
  /* span should not receive, <a> will */
  pointer-events: none;
}

.color-menu button .color-circle {
  border-radius: 50%;
  display: block;
  width: 100%;
  height: 100%;

  transform: scale(0.75);
  background-color: currentColor;
  box-sizing: content-box;
}

.color-menu button .v-icon {
  display: inline-flex;
  font-size: 1rem;
  text-decoration: none;
  opacity: 0;
}

.color-menu-enabled .color-menu button:first-child .v-icon {
  opacity: 1;
  transition: transform 0.4s;
}

.color-menu--open .color-menu button .v-icon,
.color-menu button:focus .v-icon,
.color-menu:hover button .v-icon {
  transform: rotate(180deg);
}
</style>
