<template>
  <div
    :class="[
      'announcement--container',
      { small: $vuetify.breakpoint.xsOnly, mobile: $isMobile }
    ]"
  >
    <v-container v-if="show" class="announcement--content">
      <v-layout :align-center="$vuetify.breakpoint.smAndUp">
        <v-flex grow>
          <v-layout
            align-center
            justify-center
            :column="$vuetify.breakpoint.xsOnly"
          >
            <v-flex shrink>
              <v-layout align-center>
                <v-flex
                  v-if="logoSrc"
                  shrink
                  text-xs-right
                  style="margin-right: 21px; min-width: 67px; flex-basis: 67px;"
                >
                  <img
                    :alt="logoAlt"
                    :src="logoSrc"
                    :width="logoWidth"
                    :height="logoHeight"
                  />
                </v-flex>
                <v-flex
                  v-if="heading"
                  text-xs-left
                  align-content-center
                  class="announcement--body"
                  shrink
                >
                  <strong>{{ heading }}</strong>
                  <br class="hidden-lg-and-up" />
                  {{ subHeading }}
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex
              text-xs-left
              align-content-center
              class="announcement--body"
              shrink
            >
              <slot />
            </v-flex>
            <v-flex
              v-if="buttonHref"
              text-left
              align-content-center
              shrink
              style="min-width: 165px;"
            >
              <sr-button
                round
                sr-style="flat-white"
                :border="false"
                :href="buttonHref"
                target="_blank"
              >
                {{ buttonText }}
                &nbsp;
                <img
                  alt="Right arrow"
                  :src="require('@/images/arrow-right.svg')"
                  style="max-height: 17px;"
                />
              </sr-button>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex shrink align-center style="min-width: 24px;">
          <v-icon dark role="button" @click="handleDismiss">
            close
          </v-icon>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { PRODUCT_ANNOUNCEMENT_DISMISSED } from '@/utils/userTagNames';
import { setCookie, getCookie } from '@/utils/getJSONCookie';
import config from '@/config';
import Screen from '@/mixins/Screen';

export default {
  mixins: [Screen],
  props: {
    heading: { type: String, required: true },
    subHeading: { type: String, required: true },
    buttonText: { type: String, required: true },
    buttonHref: { type: String, required: true },
    logoSrc: { type: String, required: true },
    logoWidth: { type: Number, required: true },
    logoHeight: { type: Number, required: true },
    logoAlt: { type: String, required: true }
  },
  data() {
    return {
      dismissClicked: false
    };
  },
  computed: {
    ...mapGetters('users', ['userLoggedIn', 'hasTag']),
    show() {
      return (
        config.enableProductAnnouncementBanner &&
        this.userLoggedIn &&
        !this.dismissClicked &&
        !getCookie(PRODUCT_ANNOUNCEMENT_DISMISSED) &&
        !this.hasTag(PRODUCT_ANNOUNCEMENT_DISMISSED)
      );
    }
  },
  methods: {
    ...mapActions('users', ['setTag']),
    handleDismiss() {
      this.dismissClicked = true;
      setCookie(PRODUCT_ANNOUNCEMENT_DISMISSED, 'yes');
      if (this.userLoggedIn) this.setTag(PRODUCT_ANNOUNCEMENT_DISMISSED);
    }
  }
};
</script>
<style scoped lang="scss">
.announcement--container {
  background-color: #0861d4;
  padding-right: 8px;

  &:not(.mobile) {
    margin-right: -10px;
  }

  .sr-button {
    min-width: 150px;
  }
  strong {
    font-weight: 900;
    font-size: 20px;
    color: #fff;
    padding-right: 14px;
  }

  .announcement--content {
    margin: 0 auto;
    max-width: 1244px;
    color: #fff;
    font-family: 'Lato', 'Open Sans', Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-size: 17px;
    padding: 11px 24px;
    a {
      color: white;
      font-weight: bold;
    }
  }

  .announcement--body {
    padding-right: 21px;
  }

  &.small {
    .sr-button {
      width: 90vw;
      margin-top: 10px;
    }
    strong {
      font-size: 12px;
      line-height: normal;
    }
    .announcement--content {
      font-size: 11px;
      line-height: normal;
    }
  }
}
</style>
