<template>
  <v-form
    data-test-id="signer-details"
    :class="[showHelp && 'form-show-help']"
    class="homebox--details-form"
    autocomplete="off"
    @submit.prevent="$emit('submit')"
  >
    <homebox-screen :title-divider="true">
      <v-slide-y-transition origin="top right 0">
        <v-container class="pa-0 details-container">
          <h2>
            <sr-button
              inline
              sr-style="text"
              :title="$gettext('Close Recipient Panel')"
              @click="handleClose"
            >
              <circle-back-icon />
            </sr-button>
            <translate>Modify Recipient</translate>
          </h2>
          <div class="h-section">
            <sr-input
              v-if="template"
              ref="label"
              v-model="label"
              name="label"
              type="text"
              :label="$gettext('Recipient Name')"
              :placeholder="namePlaceholder"
              suppress-first-error
            >
              <template v-slot:label>
                <gettext-slots
                  :message="
                    $gettext(
                      '<strong>Recipient Name</strong> - (or placeholder name)'
                    )
                  "
                >
                  <template v-slot:strong="{ text }">
                    <strong>{{ text }}</strong>
                  </template>
                </gettext-slots>
              </template>
            </sr-input>
            <sr-input
              ref="email"
              v-model="emailField"
              name="email"
              type="email"
              autocomplete="off"
              :label="$gettext('Recipient Email')"
              :placeholder="$gettext('Email address, or search by name')"
              :readonly="!removable"
              :rules="[emailRule]"
              suppress-first-error
            >
              <template v-slot:label>
                <gettext-slots
                  v-if="template"
                  :message="
                    $gettext(
                      '<strong>Recipient email</strong> - (leave blank for placeholder)'
                    )
                  "
                >
                  <template v-slot:strong="{ text }">
                    <strong>{{ text }}</strong>
                  </template>
                </gettext-slots>
              </template>
            </sr-input>
          </div>

          <div class="h-section" @click="handleProLevel">
            <box-signer-level
              v-model="level"
              name="level"
              :items="levels"
              :label="$gettext('Role')"
              :readonly="!(canChangeSignLevel || allIsPermittedForDocument)"
            />
          </div>

          <v-slide-y-transition>
            <v-container
              v-if="value.level !== 'cc' && value.level !== 'notify'"
              class="pa-0"
            >
              <box-toggle-field
                v-if="canUseAttachments && isAttachmentsEnabled"
                v-model="required_attachments"
                :label="$gettext('Require Attachment')"
                :help="
                  $gettext(
                    'Recipient will be asked to upload a document when signing.'
                  )
                "
              >
                <box-attachments-field
                  v-model="required_attachments"
                  name="required_attachments"
                  :readonly="
                    !(canChangeRequireAttachment || allIsPermittedForDocument)
                  "
                />
              </box-toggle-field>
              <box-toggle-field
                v-model="verify_phone_number"
                :label="$gettext('Require 2-Factor Authentication')"
                :help="
                  $gettext(
                    'Recipient will recieve an SMS text message to verify their identity before signing.'
                  )
                "
              >
                <box-tel-input
                  v-model="verify_phone_number"
                  class="srtext--text"
                  name="verify_phone_number"
                >
                  <template v-slot:append>
                    <help-hint>
                      <div>
                        <translate>
                          For additional security you can request your signers
                          to also input a unique code they receive by a text
                          message. (Additional fees apply.)
                        </translate>
                        <br />
                        <br />
                        <translate>
                          Important: this is not required and does not function
                          as a notification that a SignRequest is sent by email.
                        </translate>
                      </div>
                    </help-hint>
                  </template>
                </box-tel-input>
              </box-toggle-field>

              <box-toggle-field
                v-if="!me"
                v-model="password"
                :label="$gettext('Require Password')"
                :help="
                  $gettext(
                    'Recipient must enter a password of your choice to access the documents.'
                  )
                "
              >
                <sr-input
                  v-model="password"
                  name="password"
                  type="text"
                  autocomplete="new-password"
                  :placeholder="$gettext('Enter password')"
                  :readonly="!(canChangePassword || allIsPermittedForDocument)"
                >
                  <template v-slot:append-outer>
                    <help-hint>
                      <div v-translate>
                        As an extra verification measure you can add a password
                        here. You should send this password separately to the
                        signer, for example with a text message or during a
                        phone call. The document contents will only be visible
                        after the signer clicks on the link and enters the
                        password.
                      </div>
                    </help-hint>
                  </template>
                </sr-input>
              </box-toggle-field>
            </v-container>
          </v-slide-y-transition>
          <div v-if="removable" class="h-section h-section__remove">
            <sr-button
              inline
              sr-style="text-danger"
              data-test-id="remove"
              @click="$emit('remove')"
            >
              <translate>Remove Recipient</translate>
            </sr-button>
          </div>
        </v-container>
      </v-slide-y-transition>
    </homebox-screen>
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapModel } from '@/utils/vuexExtra';
import { validateEmail } from '@/utils/format';
import makeFields from '@/utils/makeFields';
import permissions from '@/mixins/permissions';
import FocusTrap from '@/mixins/FocusTrap';

import GettextSlots from '@/components/elements/GettextSlots';
import HomeboxScreen from './HomeboxScreen';
import SrInput from '@/components/elements/SrInput';
import BoxTelInput from '@/components/elements/BoxTelInput';
import BoxSignerLevel from './BoxSignerLevel';
import BoxToggleField from './BoxToggleField';
import BoxAttachmentsField from './BoxAttachmentsField';

import HelpHint from './HelpHint';
import CircleBackIcon from '@/components/icons/CircleBackButton.vue';
import { Level } from './SignerEntry';

export default {
  components: {
    GettextSlots,
    CircleBackIcon,
    HomeboxScreen,
    SrInput,
    BoxTelInput,
    BoxSignerLevel,
    BoxToggleField,
    BoxAttachmentsField,
    HelpHint
  },
  mixins: [
    permissions,
    FocusTrap('firstInputFocus', 'handleClose', 'shouldTrapFocus')
  ],
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: { type: Object, required: true },
    me: { type: Boolean, default: false },
    template: { type: Boolean, default: false },
    usedEmails: { type: Array, required: true },
    removable: { type: Boolean, default: true }
  },
  computed: {
    ...mapModel('conf', 'showHelp', 'updateShowHelp'),
    ...mapGetters('documents', ['isAttachmentsEnabled']),
    ...mapGetters('signrequest', [
      'allIsPermittedForDocument',
      'othersNeedToSign',
      'firstSr',
      'selectedSr'
    ]),
    ...mapGetters('users', [
      'canChangeSignLevel',
      'canChangeSignOrder',
      'canChangeTel',
      'canChangePassword',
      'canChangeRequireAttachment'
    ]),
    ...mapGetters('modals', ['modalOpen']),
    ...makeFields(
      'email',
      'name',
      'label',
      'level',
      'order',
      'verify_phone_number',
      'required_attachments',
      'password'
    ),
    canUseAttachments() {
      return false;
    },
    // actual modals, like subscribe modal should be above sidepanel
    shouldTrapFocus() {
      return !this.modalOpen;
    },
    emailField: {
      get() {
        return this.email;
      },
      set(value) {
        this.formUpdate = { email: value, name: value };
      }
    },
    namePlaceholder() {
      return this.$gettext('"John Doe" or "Legal Dept"');
    },
    signerExistInFirstSr() {
      return (
        this.selectedSr &&
        this.selectedSr !== this.firstSr &&
        this.firstSr.form.signers
          .map(firstSrSigner => firstSrSigner.email)
          .includes(this.email)
      );
    },
    levels() {
      const contactsLevels = [
        'cc',
        'signature',
        'approve',
        'notify',
        'in_person'
      ];
      const selfOnlyLevels = ['signature', 'cc'];
      const selfLevelsWithExistingSigners = ['signature', 'approve', 'cc'];
      const selfLevels = this.othersNeedToSign
        ? selfLevelsWithExistingSigners
        : selfOnlyLevels;

      const levels = this.$props.me ? selfLevels : contactsLevels;
      return levels.map(level => ({
        value: level,
        text: Level.getLevelTitle.call(this, level)
      }));
    },
    emailRule() {
      return (email = '') => {
        if (email && this.usedEmails.includes(email.toLowerCase())) {
          return this.$gettext('This email is already used by another signer');
        }
        return validateEmail.call(this, email);
      };
    }
  },
  methods: {
    firstInputFocus() {
      const first = this.$refs.label || this.$refs.email;
      first.focus();
    },
    handleClose() {
      this.$emit('close');
    },
    handleProLevel() {
      const readonly = !(
        this.canChangeSignLevel || this.allIsPermittedForDocument
      );
      if (readonly) {
        this.showLoginOrSubscribeModal({ feature: 'level' });
      }
    }
  }
};
</script>
<style scoped>
h2 {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.3px;
  margin: 24px;
}
h2 svg {
  display: inline-block;
  height: 34px;
  width: 34px;
}
h2 > .sr-button {
  vertical-align: middle;
}

.homebox--details-form {
  height: 100%;
}
.homebox--details-form /deep/ .h-section--buttons .buttons.buttons-multi {
  justify-content: start;
}
.homebox--details-form /deep/ .intl-tel-input .selected-flag {
  padding: 0;
}

.sr-field /deep/ label strong + span {
  font-style: italic;
  font-weight: normal;
}

form /deep/ input.sr-input--element {
  width: unset;
}
.ltr .h-section /deep/ .v-input {
  margin-right: 1em;
  margin-top: 1em;
}
.rtl .h-section /deep/ .v-input {
  margin-left: 1em;
}
.h-section /deep/ .v-input__slot {
  margin-bottom: 0;
}
.h-section__remove button {
  padding: 0;
  margin: 18px 0;
  font-weight: bold;
}

.h-section /deep/ .sr-button svg {
  font-size: inherit;
  height: 1em;
  width: 1em;
}

.h-section /deep/ .sr-button span {
  margin: 0 0.3em;
}
.container.pa-0 {
  padding: 0;
}
</style>
