<template>
  <div class="loading-container">
    <v-fade-transition>
      <div v-show="activeCount > 0">
        <v-progress-linear
          color="primary"
          height="3"
          :value="progressPc"
          :active="activeCount > 0"
        ></v-progress-linear>
      </div>
    </v-fade-transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      doneLastSession: 0,
      started: new Date().getTime(),
      animationPc: 0
    };
  },
  computed: {
    ...mapGetters('api', ['activeCount', 'totalCount']),
    totalThisSession() {
      return this.totalCount - this.doneLastSession;
    },

    progressPc() {
      const { activeCount, totalThisSession } = this;

      if (activeCount === 0 || totalThisSession === 0) {
        return 100;
      }

      const pc = ((totalThisSession - activeCount) * 100) / totalThisSession;
      const bpc = pc + this.animationPc;

      return bpc;
    }
  },
  watch: {
    activeCount(v) {
      if (v === 0) {
        this.doneLastSession = this.totalCount;
        this.started = new Date().getTime();
      }

      this.animate();
    }
  },

  methods: {
    animate() {
      if (!this.activeCount) {
        // don't request another frame
        // this stops the animation
        return;
      }

      // the progress bar moves at 5pc per second
      const speed = 0.005;
      const delta = new Date().getTime() - this.started;
      this.animationPc = delta * speed;

      if (this.progressPc > 90) {
        this.started = new Date().getTime();
        this.animationPc = 0;
      }

      window.requestAnimationFrame(this.animate.bind(this));
    }
  }
};
</script>

<style scoped>
div.loading-container {
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1080;
}
div.v-progress-linear {
  margin-top: 0px;
}

div.v-progress-circular {
  position: absolute;
  margin-left: 10px;
  margin-top: -6px;
}

.fade-enter-active {
  transition: opacity 0.1s;
}
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
