import angular from 'angular';
import $ from 'jquery';

/**
 * @ngdoc directive
 * @name frontendApp.component:hideIfVisible
 * @description
 * # docPage
 */
angular.module('frontendApp').component('hideIfVisible', {
  template: require('../../views/directives/hide-if-visible.html'),
  transclude: true,
  bindings: {
    selector: '@'
  },
  controller: [
    '$window',
    'utils',
    '$scope',
    '$timeout',
    function($window, utils, $scope, $timeout) {
      var ctrl = this;
      var $els = $(ctrl.selector);
      var el = $els && $els[0];
      var updateShow = function() {
        $timeout(function() {
          ctrl.show = el && !utils.isElementInViewport(el);
        });
      };

      var onResize = function() {
        updateShow();
      };

      updateShow();

      angular.element($window).on('resize orientationchange', onResize);

      ctrl.$onDestroy = function() {
        angular.element($window).off('resize orientationchange', onResize);
      };
    }
  ]
});
