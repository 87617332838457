import { mapActions } from 'vuex';

function timeout(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
export default {
  data() {
    return { error: false };
  },
  computed: {
    loadingPage() {
      return this.$route.name === 'loading';
    },
    homePage() {
      return this.$route.name === 'home';
    }
  },
  watch: {
    loadingPage: {
      immediate: true,
      handler(value) {
        if (value) {
          this.removeLater();
        }
        if (this.homePage) {
          this.waitUser();
        } else {
          this.checkFonts();
        }
      }
    }
  },
  methods: {
    ...mapActions('users', ['getUser']),
    removeLoader() {
      const loader = document.getElementById('loader-overlay');
      if (loader) {
        loader.parentNode.removeChild(loader);
      }
    },
    async removeLater() {
      // if we are on loading page, wait 20 seconds and remove loader
      // anyway. error message would pop op instead.
      await timeout(20000);
      this.removeLoader();
      this.error = true;
    },
    async waitUser() {
      await this.getUser();
      return this.checkFonts();
    },
    async checkFonts() {
      const lato = document.createElement('span');
      lato.innerText = 'Signrequest';
      lato.style.fontFamily = 'Lato';
      lato.style.color = 'white';

      document.body.appendChild(lato);
      await Promise.race([
        document.fonts ? document.fonts.ready : true,
        // if fonts are stuck for some reason, remove loader in 3 seconds
        timeout(3000)
      ]);
      document.body.removeChild(lato);
      this.removeLoader();
    }
  },
  render(h) {
    return this.error
      ? h('div', { class: 'sr-container sr-container--narrow align--center' }, [
          h('h3', {}, ['Sorry! An error occurred...']),
          h('h4', {}, ['Please try again'])
        ])
      : null;
  }
};
