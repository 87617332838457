import Vue from 'vue';

export default new Vue({
  data() {
    return { scrollY: 0, screenHeight: 0, docEl: null };
  },
  computed: {
    scrollableParent() {
      let el = this.docEl;
      while (el && window.getComputedStyle(el).overflow !== 'scroll') {
        el = el.parentElement;
      }
      return el || window;
    }
  },
  methods: {
    init(el) {
      this.screenHeight = window.innerHeight;
      this.scrollY = window.scrollY;
      this.docEl = el;

      this.listenParentScroll();
      window.addEventListener('resize', this.handleResize);
    },
    deinit() {
      window.removeEventListener('resize', this.handleResize);
      this.unlistenParentScroll();

      this.docEl = null;
    },
    listenParentScroll() {
      this.scrollableParent.addEventListener('scroll', this.handleScroll);
    },
    unlistenParentScroll() {
      this.scrollableParent.removeEventListener('scroll', this.handleScroll);
    },
    handleResize() {
      if (this.scrollableParent === window) {
        this.screenHeight = window.innerHeight;
        this.screenWidth = window.innerWidth;
      } else {
        this.screenHeight = this.docEl.clientHeight;
        this.screenWidth = this.docEl.clientWidth;
      }
    },
    handleScroll(event) {
      if (this.scrollableParent === window) {
        this.scrollY = window.scrollY || window.pageYOffset || 0;
      } else {
        this.scrollY = event.target.scrollTop;
      }
    }
  }
});
