<template>
  <div>
    <h2>Notifications</h2>
    <h5>Email notifications</h5>
    <NotificationSettings
      notification-type="email"
      :user-notification-settings="user.email_settings"
    ></NotificationSettings>
  </div>
</template>

<script>
import permissions from '@/mixins/permissions';
import NotificationSettings from '@/components/elements/NotificationSettings';

export default {
  components: { NotificationSettings },
  mixins: [permissions]
};
</script>
