import angular from 'angular';

/**
 * @ngdoc directive
 * @name frontendApp.directive:loading
 * @description
 * # docPage
 */
angular
  .module('frontendApp')
  /**
   * Shows a spinner, shown when 'loading' is true or undefined. Also watches the 'loading' var if
   * defined on the parent scope
   * <loading loading="ctrl.loading"></loading>
   */
  .component('loading', {
    template: require('../../views/directives/loading.html'),
    controllerAs: 'ctrl',
    bindings: {
      loading: '<'
    },
    controller: [
      '$scope',
      '$element',
      '$attrs',
      function($scope, $element, $attrs) {
        var ctrl = this;
        ctrl.isLoading = function() {
          if (angular.isDefined(ctrl.loading)) {
            return ctrl.loading;
          }
          if (angular.isDefined($scope.$parent.loading)) {
            return $scope.$parent.loading;
          }
          return true;
        };
      }
    ]
  })
  .component('timer', {
    template: require('../../views/directives/timer.html'),
    controllerAs: 'ctrl',
    bindings: {
      seconds: '<',
      onDone: '&'
    },
    controller: [
      '$scope',
      '$element',
      '$attrs',
      'CountdownTimer',
      function($scope, $element, $attrs, CountdownTimer) {
        var ctrl = this;

        ctrl.timer = null;
        ctrl.ticked_percent = 0.0;
        ctrl.seconds_left = ctrl.seconds;
        $scope.$watch('ctrl.seconds', function(n, o) {
          if (n && n !== o) {
            ctrl.start();
          }
        });
        ctrl.start = function() {
          ctrl.ticked_percent = 0.0;
          ctrl.seconds_left = ctrl.seconds;
          ctrl.timer = new CountdownTimer(ctrl.seconds * 1000);
          ctrl.timer.on('done', function() {
            if (ctrl.onDone) {
              ctrl.onDone();
              ctrl.timer = null;
            }
          });
          ctrl.timer.on('tick', function(ticked) {
            ctrl.ticked_percent = ctrl.ticked_percent + ticked;
            ctrl.seconds_left =
              ctrl.seconds - Math.floor(ctrl.seconds * ctrl.ticked_percent) - 1;
          });
          ctrl.timer.start();
        };
      }
    ]
  })
  .directive('srTimerModel', [
    '$interval',
    function($interval) {
      return {
        require: 'ngModel',
        scope: {
          ngModel: '='
        },
        link: function(scope, element, attrs) {
          var ms = 1000;
          var ticker = $interval(function() {
            scope.ngModel = scope.ngModel - ms / 1000;
          }, ms);

          element.on('$destroy', function() {
            $interval.cancel(ticker);
          });
        }
      };
    }
  ]);
