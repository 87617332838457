import angular from 'angular';

/**
 * @ngdoc function
 * @name frontendApp.controller:BalanceCtrl
 * @description
 * # BalanceCtrl
 * Controller of the frontendApp
 */
angular
  .module('frontendApp')
  .controller('BalanceCtrl', [
    '$scope',
    '$location',
    '$route',
    'UserService',
    function($scope, $location, $route, UserService) {
      $scope.add_amount = 50.0;
      $scope.currency_symbol = '€';
      $scope.loading = false;

      $scope.isForTeam = function() {
        if (!$scope.user) {
          return;
        }

        return !$scope.personal && $scope.user.team_user.has_active_team;
      };

      $scope.getBalance = function() {
        if ($scope.user) {
          // $scope.personal is ng-initialized on include of the balance.html template
          if ($scope.isForTeam()) {
            return $scope.user.team_user.team.balance;
          }
          return $scope.user.balance;
        }
        return null;
      };

      $scope.createPayment = function() {
        $scope.loading = true;
        UserService.createPayment({
          amount: $scope.add_amount,
          next: $location.path(),
          team_user_uuid: $scope.isForTeam() ? $scope.user.team_user.uuid : null
        });
      };
    }
  ])
  .controller('PaymentFinishedCtrl', [
    '$scope',
    '$route',
    '$location',
    'UserService',
    '$window',
    'utils',
    function($scope, $route, $location, UserService, $window, utils) {
      $scope.loading_payment = true;
      $scope.payment = null;
      $scope.currency_symbol = '€';

      $scope.goToNext = function() {
        utils.navigateTo(
          utils.redirectFilter(
            $route.current.params.redirect_url ||
              $route.current.params.next ||
              '/'
          )
        );
      };

      UserService.getPaymentInfo($route.current.params.uuid).then(function(
        response
      ) {
        $scope.loading_payment = false;
        $scope.payment = response.data;
      });
    }
  ]);
