import angular from 'angular';
import SmoothSignature from '../external/smoothSignature';

/**
 * @ngdoc directive
 * @name frontendApp.directive:signaturePad
 * @description
 * # signaturePad
 */
angular.module('frontendApp').directive('signaturePad', [
  'docService',
  '$window',
  '$timeout',
  'srSignatureSelectModal',
  'utils',
  function(docService, $window, $timeout, srSignatureSelectModal, utils) {
    return {
      template: require('../../views/directives/signature-pad.html'),
      restrict: 'E',
      scope: {
        api: '=',
        availableColors: '='
      },
      link: function postLink(scope, element) {
        scope.show_pad = true;
        scope.closeModal = function() {
          srSignatureSelectModal.deactivate();
        };

        const canvas = element.find('#signature-canvas')[0];
        canvas.width = 1024;
        canvas.height = 400;

        scope.getCanvas = function() {
          return canvas;
        };

        scope.sigCanvasCtx = canvas.getContext('2d');
        scope.signaturePad = new SmoothSignature(canvas, {
          onchange() {
            setTimeout(() => {
              scope.$apply();
            }, 1);
          }
        });

        scope.$on('$destroy', function() {
          scope.signaturePad.destroy();
        });

        scope.api = {};
        scope.finish_callback = function() {};

        scope.api.toggleShowSigPad = function(callback) {
          //scope.show_pad = !scope.show_pad;
          if (typeof callback === typeof Function) {
            scope.finish_callback = callback;
          } else {
            scope.finish_callback = function() {};
          }
        };

        scope.saveSig = function() {
          if (scope.signaturePad.isEmpty()) {
            //scope.closeModal();
          } else {
            var type = 'image/png';
            var image = docService.newSigImage();
            image.src = scope.signaturePad.toDataURL(type);
            image.class = 'signature-image';
            image.width = canvas.width;
            image.height = canvas.height;
            image.from_text = false;
            scope.show_pad = false;
            scope.signaturePad.clear();
            scope.finish_callback(image);
          }
        };
      }
    };
  }
]);
