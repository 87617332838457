import angular from 'angular';
import _ from 'lodash';

/**
 * @ngdoc directive
 * @name frontendApp.directive:srSelectize
 * @description
 * # srSelectize
 * mostly copied from: https://github.com/theaqua/ng-selectize/
 */
angular.module('frontendApp').directive('srSelectize', [
  'gettextCatalog',
  'utils',
  '$timeout',
  function(gettextCatalog, utils, $timeout) {
    return {
      restrict: 'A',
      require: 'ngModel',
      scope: {
        srSelectize: '&',
        options: '=',
        srPlaceholder: '&',
        ngDisabled: '='
      },
      link: function(scope, element, attrs, ngModel) {
        var changing,
          options,
          selectize,
          invalidValues = [];
        // Default options
        options = angular.extend(
          {
            // enableSearch is not a selectize option but ours, see: https://github.com/selectize/selectize.js/issues/110
            enableSearch: false,
            plugins: [],
            delimiter: ',',
            render: {
              option_create: function(data, escape) {
                return (
                  '<div class="create">' +
                  gettextCatalog.getString('Add') +
                  ' <strong>' +
                  escape(data.input) +
                  '</strong>&hellip;</div>'
                );
              }
            },
            persist: true,
            mode: element[0].tagName === 'SELECT' ? 'single' : 'multi',
            createOnBlur: true,
            placeholder: scope.srPlaceholder
              ? scope.$eval(scope.srPlaceholder)
              : ''
            // currentStyle: {direction: 'rtl'}
          },
          scope.srSelectize() || {}
        );

        if (!options.enableSearch && !options.create) {
          // taken from https://github.com/selectize/selectize.js/issues/110
          options.onInitialize = function() {
            this.$control_input.attr('readonly', true);
          };
        }

        // Activate the widget
        selectize = element.selectize(options)[0].selectize;
        selectize.on('change', function() {
          setModelValue(selectize.getValue());

          if (attrs.disableDropdown) {
            selectize.blur();
            setTimeout(() => {
              selectize.close();
            }, 10);
          }
        });

        function setModelValue(value) {
          value = value && value !== '' ? value : undefined;
          if (changing) {
            return;
          }
          scope.$parent.$apply(function() {
            ngModel.$setViewValue(value);
          });

          if (options.mode === 'single') {
            selectize.blur();
          }
        }

        // Non-strict indexOf
        function indexOfLike(arr, val) {
          for (var i = 0; i < arr.length; i++) {
            if (arr[i] === val) {
              return i;
            }
          }
          return -1;
        }
        // Boolean wrapper to indexOfLike
        function contains(arr, val) {
          return indexOfLike(arr, val) !== -1;
        }

        function parseValues(values) {
          if (options.valueField && angular.isArray(values)) {
            return _.map(values, function(item) {
              if (item && item[options.valueField]) {
                return item[options.valueField];
              }
              return item;
            });
          }
          return utils.parseValues(values, options.delimiter);
        }

        // Store invalid items for late-loading options
        function storeInvalidValues(values, resultValues) {
          values.map(function(val) {
            if (
              !(contains(resultValues, val) || contains(invalidValues, val))
            ) {
              invalidValues.push(val);
            }
          });
        }
        function restoreInvalidValues(newOptions, values) {
          var i, index;
          for (i = 0; i < newOptions.length; i++) {
            index = indexOfLike(
              invalidValues,
              newOptions[i][selectize.settings.valueField]
            );
            if (index !== -1) {
              values.push(newOptions[i][selectize.settings.valueField]);
              invalidValues.splice(index, 1);
            }
          }
        }
        function setSelectizeValue(value) {
          var values = parseValues(value);
          if (changing || values === parseValues(selectize.getValue())) {
            return;
          }

          $timeout(function() {
            // moved the timeout, also see: https://github.com/evgenyrodionov/ng-selectize/issues/10
            // changing = true; must be inside the timeout though

            changing = true;
            if (
              !attrs.options &&
              options.create &&
              options.valueField &&
              options.labelField
            ) {
              // we no not have options and we do allow creating of items so when we set values here we need to set the
              // options as else selectize won't allow the values
              selectize.addOption(
                _.map(values, function(item) {
                  var data = {};
                  data[options.valueField] = item;
                  data[options.labelField] = item;
                  return data;
                })
              );
            }
            selectize.setValue(values, true);
            storeInvalidValues(values, parseValues(selectize.getValue()));

            changing = false;
          });
        }

        function parseOptions(options) {
          // when we have translated options we need the option text values to be functions that we call here
          // in order for gettextCatalog.getString to work
          var newOptions = [];
          if (options) {
            angular.forEach(options, function(option) {
              var parsedOption = angular.copy(option);
              if (angular.isFunction(parsedOption.text)) {
                parsedOption.text = parsedOption.text();
              }
              newOptions.push(parsedOption);
            });
          }
          return newOptions;
        }

        function setSelectizeOptions(newOptions) {
          newOptions = parseOptions(newOptions);
          var values = parseValues(ngModel.$viewValue);

          if (options.mode === 'multi' && newOptions) {
            restoreInvalidValues(newOptions, values);
          }

          if (newOptions) {
            if (Array.isArray(newOptions) && newOptions.length === 0) {
              selectize.clearOptions();
            }
            selectize.addOption(newOptions);
            angular.forEach(newOptions, function(option) {
              // re renders and refreshes the options when language changes
              selectize.updateOption(option.value, option);
            });
            setSelectizeValue(values);
          }
          selectize.close();
        }
        var toggleDisabled = function(disabled) {
          if (disabled) {
            selectize.disable();
            return;
          }
          selectize.enable();
        };
        scope.$watch('ngDisabled', toggleDisabled);

        scope.$parent.$watch(attrs.ngModel, setSelectizeValue);
        if (attrs.options) {
          scope.$parent.$watchCollection(
            attrs.options,
            setSelectizeOptions,
            true
          );
        }

        scope.$on('$destroy', function() {
          selectize.destroy();
        });

        scope.$on('gettextLanguageChanged', function() {
          if (scope.options) {
            setSelectizeOptions(scope.options);
          }
        });
      }
    };
  }
]);
