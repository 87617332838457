<script>
import accessibilityState from '@/mixins/TabDetectState';
import AppWrapper from './AppWrapper';
import SignatureModal from './modals/SignatureModal';
import TextEntryModal from './modals/TextEntryModal';
import CheckboxModal from './modals/CheckboxModal';
import TemplateMoveModal from '@/components/modals/TemplateMoveModal';
import ConfirmationModal from '@/components/modals/ConfirmationModal';
import LoginModal from '@/components/modals/LoginModal';
import IframeModal from '@/components/modals/IframeModal';
import EditPlaceholderModal from '@/components/document/placeholder/EditPlaceholderModal';
import UploadAttachmentsModal from '@/components/modals/UploadAttachmentsModal';
import SubscribeModal from '@/components/modals/SubscribeModal';
import EsignModal from '@/components/modals/EsignModal';
import DeclineModal from '@/components/modals/DeclineModal';
import TermsModal from '@/components/modals/TermsModal';
import ForwardModal from '@/components/document/forward/ForwardModal';
import EnterPasswordModal from './modals/EnterPasswordModal.vue';
import VerifyPhoneModal from './modals/VerifyPhoneModal.vue';
import MemberUpgradeModal from '@/components/modals/MemberUpgradeModal.vue';
import ConfirmMemberUpgradeModal from '@/components/modals/ConfirmMemberUpgradeModal.vue';
import TooManyDocumentsModal from '@/components/modals/TooManyDocumentsModal.vue';
import PublicLinkModal from '@/components/modals/PublicLinkModal';

export default {
  render(h) {
    const children = [
      TemplateMoveModal,
      AppWrapper,
      SignatureModal,
      TextEntryModal,
      CheckboxModal,
      LoginModal,
      IframeModal,
      EditPlaceholderModal,
      UploadAttachmentsModal,
      SubscribeModal,
      EsignModal,
      DeclineModal,
      TermsModal,
      ForwardModal,
      EnterPasswordModal,
      VerifyPhoneModal,
      MemberUpgradeModal,
      ConfirmMemberUpgradeModal,
      TooManyDocumentsModal,
      PublicLinkModal,
      ConfirmationModal
    ].map(comp =>
      h(comp, {
        props: { contentClass: accessibilityState.isActive() ? 'ay11-kb' : '' }
      })
    );
    return h(AppWrapper, { props: { id: 'modal-wrapper' } }, children);
  }
};
</script>
