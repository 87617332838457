import Vue from 'vue';

export default new Vue({
  data() {
    return { accessibilityStatus: false };
  },
  methods: {
    isActive() {
      return this.accessibilityStatus;
    },
    setState(value) {
      this.accessibilityStatus = value;
    }
  }
});
