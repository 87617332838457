import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '../styles/veutify.styl';

Vue.use(Vuetify, {
  iconfont: 'md',
  theme: {
    primary: '#366cd8',
    secondary: '#5eb235',
    error: '#d72727',
    application: '#fff',

    // black text used by default
    srdefault: '#222222',
    srtext: '#111111',
    srerror: '#e82a2a',

    // grey text used for list elements, inputs
    srgrey: '#757b88',

    // checked mark - green
    srgreen: '#5eb235',

    // links and such
    srlink: '#366cd8',

    // secondary button (cancel)
    srcancel: '#EAF0FC'
  }
});
