<template>
  <div>
    <div class="sr-layout">
      <div class="sr-layout__item one-half">
        <h4 id="dropbox">Dropbox</h4>
      </div>
      <!--
       -->
      <div class="sr-layout__item one-half">
        <img
          class="pull-right margin-top"
          alt="Dropbox"
          width="220"
          src="../images/dropbox_logo.png"
        />
      </div>
    </div>

    <div class="sr-layout">
      <div class="sr-layout__item three-quarters palm-one-whole">
        <p v-if="!user.integrations.dropbox.enabled">
          <span v-translate>
            Enable the Dropbox integration to automatically store signed
            documents in your linked Dropbox account.
          </span>
          <span v-translate>
            You can also create a SignRequest directly from a document stored in
            your Dropbox.
          </span>
        </p>
        <p v-if="user.integrations.dropbox.enabled">
          <span v-translate>The Dropbox integration is enabled.</span>
          <br />
          <span v-translate>
            Your signed documents and the signing log will be stored in a folder
            called 'SignRequest'.
          </span>
        </p>
      </div>
      <!--
       -->
      <div class="sr-layout__item one-quarter palm-one-whole">
        <p v-if="!user.integrations.dropbox.enabled" class="align--center">
          <a
            class="lap-and-up-pull-right button button--dropbox"
            href="/user/social/login/dropbox-oauth2/?next=/%23/account%23dropbox"
          >
            Enable Dropbox
          </a>
        </p>
        <p v-if="user.integrations.dropbox.enabled" class="align--center">
          <button
            v-translate
            class="pull-right button button--small button--error"
            @click="deleteIntegration('dropbox')"
          >
            Remove integration
          </button>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import permissions from '@/mixins/permissions';

export default {
  mixins: [permissions],
  computed: {
    ...mapGetters('users', ['user'])
  },
  methods: {
    ...mapActions('users', ['deleteIntegration'])
  }
};
</script>
