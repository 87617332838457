<template>
  <sr-autocomplete
    ref="autocomplete"
    v-bind="$attrs"
    :items="items"
    :value="value"
    :enable-manual="enableManual"
    class="sr-combobox"
    :class="{
      'sr-combobox--with-input': enableManual
    }"
    v-on="$listeners"
    @commit="handleCommit"
    @clear="handleClear"
  >
    <template v-slot:value>
      <slot v-if="$slots.value" name="value" />
      <div v-else class="sr-autocomplete--value">
        {{ selectedText }}
      </div>
    </template>
    <template v-slot:append-outer><slot name="append-outer" /></template>
    <template v-slot:append-inner>
      <sr-combobox-chip
        v-if="chips"
        :removable="removable"
        :value="value"
        @delete="handleDelete"
      >
        <template v-slot:chip="{ item }">
          <slot name="chip" :item="item" />
        </template>
      </sr-combobox-chip>
      <slot name="append-inner" />
    </template>
    <template v-slot:additem="{ value }">
      <slot name="additem" :value="value" />
    </template>
    <template v-slot:item="{ item }">
      <slot name="item" :item="item" />
    </template>
  </sr-autocomplete>
</template>
<script>
import { removeAtIdx } from '@/utils/fp';
import SrAutocomplete from '@/components/elements/SrAutocomplete';
import SrComboboxChip from '@/components/elements/SrComboboxChip';
export default {
  components: { SrAutocomplete, SrComboboxChip },
  inheritAttrs: false,
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    items: { type: Array, required: true },
    value: {
      type: [String, Number, Object, Array],
      required: false,
      default: null
    },
    multi: { type: Boolean, required: false, default: false },
    chips: { type: Boolean, required: false, default: false },
    removable: { type: Boolean, required: false, default: false },
    enableManual: { type: Boolean, required: false, default: true }
  },
  computed: {
    selectedText() {
      const selectedItem =
        this.$props.items.find(item => item.value === this.$props.value) ||
        null;
      return selectedItem ? selectedItem.text : null;
    }
  },
  methods: {
    focus() {
      this.$refs.autocomplete.focus();
    },
    handleCommit({ value }) {
      if (this.multi) {
        const oldValue = this.value || [];
        const newValue = oldValue.includes(value)
          ? oldValue
          : [...oldValue, value];
        this.$emit('change', newValue);
      } else {
        this.$emit('change', value);
      }
    },
    handleClear() {
      this.$emit('change', this.multi ? [] : null);
    },
    handleDelete(toDelete) {
      if (this.multi) {
        const newValue = removeAtIdx(this.value, toDelete);
        this.$emit('change', newValue);
      } else {
        this.handleClear();
        this.$emit('change', n);
      }
    }
  }
};
</script>
<style scoped>
.sr-combobox:not(.sr-combobox--with-input) /deep/ input {
  opacity: 0;
}
.sr-combobox-chip--chip-items:empty {
  display: none;
}
</style>
