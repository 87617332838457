<template>
  <v-form
    ref="form"
    v-model="valid"
    class="text-xs-center"
    @submit.prevent="submit"
  >
    <div class="text-xs-right">
      <v-btn icon small data-test-id="social-cancel" @click="$emit('cancel')">
        <v-icon>close</v-icon>
      </v-btn>
    </div>
    <v-card-text>
      <h1 v-translate class="font-weight-bold headline">
        Please agree to the Terms of Service and Privacy Notice
      </h1>
    </v-card-text>
    <agree-to-terms v-model="agreed" center />
    <sr-button
      class="button--fluid get-started mt-3"
      type="submit"
      sr-style="fancy"
      :round="true"
      :disabled="!agreed"
      data-test-id="social-login"
      @click="validate"
    >
      <translate
        :translate-params="{ socialProviderName: selectedProvider.name }"
      >
        Sign up with %{ socialProviderName }
      </translate>
    </sr-button>
  </v-form>
</template>

<script>
import AgreeToTerms from './AgreeToTerms';
import SrButton from '@/components/elements/SrButton.vue';
import { providerUrl } from '@/utils/socialProviders';
import { mapActions } from 'vuex';
import location from '@/utils/location';

export default {
  components: { AgreeToTerms, SrButton },
  props: {
    selectedProvider: { type: Object, required: true },
    next: { type: String, required: true }
  },
  data() {
    return {
      agreed: false,
      valid: true
    };
  },
  methods: {
    ...mapActions('events', ['trackSocialLoginModalEvent']),
    submit() {
      this.validate();
      if (!this.valid) return;

      this.trackSocialLoginModalEvent(
        `social used: ${this.selectedProvider.provider}}`
      );
      const url = providerUrl(this.selectedProvider, this.next);
      location.assign(url);
    },
    validate() {
      this.valid = this.$refs.form.validate();
    }
  }
};
</script>

<style scoped>
.sr-button {
  font-size: 18px;
  min-height: 55px;
  max-width: 250px;
}
.v-btn--icon {
  margin-top: -32px;
  margin-right: -5px;
}
</style>
