<template>
  <sr-field :label="label" :label-for="uid" is-empty :horizontal="horizontal">
    <div class="sr-logo--wrapper">
      <img v-if="value" class="customlogo" alt="logo" :src="value" />
      <div v-else-if="readonly" key="r" class="sr-logo--readonly">
        <translate>
          You can upload a logo after you've created your team.
        </translate>
      </div>
      <div v-else key="w" class="sr-logo--upload">
        <em v-translate>
          You can now upload a logo for this team.
        </em>
      </div>
    </div>
    <template v-slot:append-outer>
      <sr-file-select
        v-if="!readonly"
        class="sr-logo--file"
        @file="$emit('file', $event)"
      >
        <sr-button sr-style="link">
          <upload-icon />
          {{ placeholder }}
        </sr-button>
      </sr-file-select>
    </template>
  </sr-field>
</template>
<script>
import SrField from './SrField';
import SrButton from '@/components/elements/SrButton';
import SrFileSelect from '@/components/elements/SrFileSelect';
import UploadIcon from '@/components/icons/UploadIcon';
import uniqueId from '@/mixins/uniqueId';

export default {
  components: { SrField, SrButton, SrFileSelect, UploadIcon },
  mixins: [uniqueId],
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    label: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    value: { type: String, default: '' },
    name: { type: String, required: true },
    readonly: { type: Boolean, default: false },
    horizontal: { type: Boolean, default: false }
  }
};
</script>
<style scoped>
.sr-logo--wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.3rem;
}
.sr-logo--wrapper > * {
  flex-basis: auto;
}

.sr-field .sr-logo--file .sr-button {
  margin: 0;
  padding: 0;
  font-weight: normal;
}
svg {
  width: 1em;
  min-width: 1em;
  margin: 0;
}
.ltr svg {
  margin-right: 0.5ch;
}
.rtl svg {
  margin-left: 0.5ch;
}

.ltr .sr-logo--file {
  margin-left: auto;
}
.rtl .sr-logo--file {
  margin-right: auto;
}
.mobile .sr-logo--file {
  margin: 0;
}
em {
  line-height: normal;
}
</style>
