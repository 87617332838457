import angular from 'angular';

/**
 * @ngdoc service
 * @name frontendApp.bulkSendSErvice
 * @description
 * # bulkSendService
 * Factory in the frontendApp.
 */
angular.module('frontendApp').service('bulkSendService', [
  '$http',
  'messageService',
  'gettextCatalog',
  '$q',
  '$timeout',
  function($http, messageService, gettextCatalog, $q, $timeout) {
    this.results = [];
    this.checkResult = function(template_id, task_id) {
      var deferred = $q.defer();
      if (!task_id) {
        deferred.reject();
      }

      var _get = function() {
        $http
          .get(
            '/docs/get-bulk-send/' +
              template_id +
              '/csv/' +
              '?task_id=' +
              task_id
          )
          .then(
            function(resp) {
              let data = resp.data;
              if (!data.ready) {
                // retry this task id again a bit later as it didn't finish yet
                $timeout(function() {
                  _get();
                }, 1000);
              } else {
                if (data.status === 'ERROR') {
                  messageService.handleError(resp);
                  deferred.reject();
                } else {
                  deferred.resolve(data);
                }
              }
            },
            function(resp) {
              messageService.handleError(resp);
              deferred.reject();
            }
          );
      };

      if (task_id) {
        _get();
      }

      return deferred.promise;
    };

    this.bulk_send = function(
      template_id,
      bulksend_id,
      task_id,
      email_list,
      from_email
    ) {
      var deferred = $q.defer();
      from_email = from_email || false;
      $http
        .post('/docs/get-bulk-send/' + template_id + '/csv/', {
          send: true,
          task_id: task_id,
          bulksend_id: bulksend_id,
          email_list: email_list,
          from_email: from_email
        })
        .then(
          function(resp) {
            let data = resp.data;
            if (data.status === 'ERROR') {
              messageService.handleError(resp);
              deferred.reject();
            } else {
              deferred.resolve(data);
            }
          },
          function(resp) {
            messageService.handleError(resp);
            deferred.reject();
          }
        );
      return deferred.promise;
    };
  }
]);
