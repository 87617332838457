import angular from 'angular';

/**
 * @ngdoc directive
 * @name frontendApp.directive:input
 * @description
 * # add BIDI support to inputs
 */
var bidiInput = function() {
  return {
    restrict: 'E',
    link: function(scope, element) {
      if (~['text', 'textarea'].indexOf(element.prop('type'))) {
        try {
          element.prop('dir', 'auto');
        } catch (err) {
          // doesn't work for IE
        }
      }
    }
  };
};
angular
  .module('frontendApp')
  .directive('input', [bidiInput])
  .directive('textarea', [bidiInput]);
