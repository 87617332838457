import angular from 'angular';
import availableLangs from '@/utils/langs';

/**
 * @ngdoc service
 * @name frontendApp.confService
 * @description
 * # confService
 * Service in the frontendApp.
 */
angular
  .module('frontendApp')
  .run([
    'gettextCatalog',
    'ipnConfig',
    function(gettextCatalog, ipnConfig) {
      // current language should not be en-us,
      // as we don't have builtin en-us strings.
      // setting it null would result in checkCookieLang
      // loading us package through switchLang().
      // en-us is used as an override package where we
      // change strings for English-US without changing
      // translation in all other languages.
      gettextCatalog.setCurrentLanguage(null);
      // configure international phone number plugin
      ipnConfig.initialCountry = 'us';
    }
  ])
  .service('localeService', [
    '$rootScope',
    'gettextCatalog',
    'confService',
    'ipCookie',
    'UserService',
    '$filter',
    '$q',
    '$vuex',
    function(
      $rootScope,
      gettextCatalog,
      confService,
      ipCookie,
      UserService,
      $filter,
      $q,
      $vuex
    ) {
      var service = this;

      this.availableLangs = availableLangs;
      this.getActiveLang = function() {
        const lang = gettextCatalog.currentLanguage;
        if (service.availableLangs.hasOwnProperty(lang)) {
          return service.availableLangs[lang];
        }
        return service.availableLangs.en;
      };

      this.getActiveLangCode = function() {
        return gettextCatalog.currentLanguage;
      };

      this.langIsActive = function(lang) {
        return lang === gettextCatalog.currentLanguage;
      };

      this.isRTL = function() {
        return service.getActiveLang().dir === 'rtl';
      };

      this.getLocaleDir = function() {
        return service.getActiveLang().dir;
      };

      this.switchLang = function(lang, sync_backend = true) {
        return $vuex.dispatch('conf/switchLang', { lang, sync_backend });
      };

      this.checkOrSwitchLang = function(lang) {
        if (!service.langIsActive(lang)) {
          service.switchLang(lang, true);
        }
      };

      this.checkCookieLang = function() {
        // this is faster than checking with the backend first
        var cookie_lang = ipCookie('sr_lang');
        if (
          typeof cookie_lang === 'string' &&
          !service.langIsActive(cookie_lang)
        ) {
          service.switchLang(cookie_lang, false); // no need to sync the backend, it set's the cookie
        }
      };
    }
  ]);
