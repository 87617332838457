import * as Sentry from '@sentry/browser';
import $ from 'jquery';
import 'jquery-migrate';
import 'jquery.easing';
import 'magnific-popup';
import 'lodash';
import 'selectize';
import 'jquery-placeholder';
import 'intl-tel-input';
import angular from 'angular';
import './config';
import 'angular-animate';
import 'angular-cookies';
import 'angular-resource';
import 'angular-route';
import 'angular-sanitize';
import 'angular-touch';
import 'ng-file-upload';
import 'angular-gettext';
import 'angular-cookie';
import 'angular-dynamic-locale';
import 'angular-gravatar';
import 'angular-media-queries';
import './external/angularModalService';
import './external/css-modal';
import './external/international-phone-number';
import 'angular-stripe-checkout';
import 'angular-elastic';
import 'ng-iban';
import 'angular-countdown/release/angular-countdown';
import './templates-module';
import 'ngVue';
import 'ngVue/build/plugins.js';
import Home from '../views/Home';
import Subscribe from '../components/subscribe/Subscribe';

import UserManagement from '../components/UserManagement';
import { store } from '../store';
import { setAngularInjector } from '../plugins/angular-injector';
import Templates from '@/components/Templates';
import Account from '@/components/Account';
import Team from '@/components/team/Team';
import NavigationList from '@/components/NavigationList.vue';

import makeLazyComponent from '@/components/makeLazyComponent';

/**
 * @ngdoc overview
 * @name frontendApp
 * @description
 * # frontendApp
 *
 * Main module of the application.
 */
const app = angular.module('frontendApp', [
  'templates',
  'ngAnimate',
  'ngCookies',
  'ngResource',
  'ngRoute',
  'ngSanitize',
  'ngTouch',
  'ngFileUpload',
  'gettext',
  'ipCookie',
  'angularModalService',
  'tmh.dynamicLocale',
  'ui.gravatar',
  'config',
  'matchMedia',
  'srInternationalPhoneNumber',
  'stripe.checkout',
  'monospaced.elastic',
  'mm.iban',
  'countdown',
  'ngVue',
  'ngVue.plugins'
]);

app.run([
  '$injector',
  function($injector) {
    setAngularInjector($injector);
  }
]);

app.config([
  '$ngVueProvider',
  $ngVueProvider => {
    $ngVueProvider.setRootVueInstanceProps({
      store: store
    });
  }
]);

// main views!
app.value('Home', Home);

// tabs in the settings
app.value(
  'StyleGuide',
  makeLazyComponent(() =>
    import(/* webpackChunkName: "styleguide" */ '../components/StyleGuide')
  )
);
app.value('Templates', Templates);
app.value('UserManagement', UserManagement);
app.value('Account', Account);
app.value('NavigationList', NavigationList);
app.value('Subscribe', Subscribe);
app.value('Team', Team);

app
  .config([
    '$routeProvider',
    'StripeCheckoutProvider',
    'ENV',
    'confServiceProvider',
    function($routeProvider, StripeCheckoutProvider, ENV, confServiceProvider) {
      const confService = confServiceProvider.$get();
      StripeCheckoutProvider.defaults({
        key: ENV.stripe_publishable_key
      });

      $routeProvider
        .when('/', {
          template: require('../views/main.html'),
          controller: 'MainCtrl',
          reloadOnSearch: false
        })
        // this view is opened when coming from an installed 'progressive web app' (possibly in offline mode)
        .when('/shell', {
          template: require('../views/main.html'),
          controller: 'MainCtrl',
          reloadOnSearch: false
        })
        .when('/doc/:doc_uuid/', {
          template: require('../views/main.html'),
          controller: 'MainCtrl',
          reloadOnSearch: false
        })
        .when('/loading', {})
        .when('/document/:uuid/:signer_uuid/:prepare_doc?', {
          template: require('../views/doc.html'),
          controller: 'DocCtrl'
        })
        .when('/template/:template_uuid/', {
          template: require('../views/doc.html'),
          controller: 'DocCtrl'
        })
        .when('/sign-template/:public_template_id/', {
          template: require('../views/sign-template.html'),
          controller: 'SignTemplateCtrl'
        })
        .when('/login/activate/:token', {
          template: require('../views/login/activate.html'),
          controller: 'LoginActivateCtrl'
        })
        .when('/account/email_change/', {
          template: require('../views/login/email_change.html'),
          controller: 'EmailChangeCtrl'
        })
        .when('/email-change/validate-change-email/', {
          template: require('../views/login/email_change_confirm.html'),
          controller: 'EmailChangeConfirmCtrl'
        })
        .when('/reactivate-account/', {
          template: require('../views/login/reactivate-account.html'),
          controller: 'ReactivateAccountCtrl'
        })
        .when('/login/reset/:token', {
          template: require('../views/login/reset.html'),
          controller: 'LoginResetCtrl'
        })
        .when('/login/recover', {
          template: require('../views/login/recover.html'),
          controller: 'LoginRecoverCtrl'
        })
        .when('/login/validate-email/', {
          template: require('../views/login/validate-email.html'),
          controller: 'LoginValidateEmailCtrl'
        })
        .when('/login/validate-signrequest/', {
          template: require('../views/login/validate-email.html'),
          controller: 'SignRequestValidateEmailCtrl'
        })
        .when('/login/accept-invite/:token/:uuid/', {
          template: require('../views/login/accept-invite.html'),
          controller: 'AcceptInviteCtrl'
        })
        .when('/documents', {})
        .when('/expired', {
          controller: 'ExpiredCtrl'
        })
        .when('/login', {
          controller: 'RegisterCtrl'
        })
        .when('/register', {
          controller: 'RegisterCtrl'
        })
        .when('/templates', {
          template: require('../views/templates.html'),
          controller: 'TemplatesCtrl'
        })
        .when('/document-status/:uuid', {
          template: require('../views/doc-status.html'),
          controller: 'DocStatusCtrl'
        })
        .when('/signatures', {
          template: require('../views/signatures.html'),
          controller: 'SigCtrl'
        })
        .when('/teams', {
          template: require('../views/teams.html'),
          controller: 'TeamsCtrl'
        })
        .when('/account', {
          template: require('../views/account.html'),
          controller: 'AccountCtrl'
        })
        .when('/referral', {
          template: require('../views/referral-program.html'),
          controller: 'ReferralProgramCtrl'
        })
        .when('/billing', {
          template: require('../views/billing.html')
        })
        .when('/bundle', {
          template: require('../views/bundle.html')
        })
        .when('/sessions', {
          template: require('../views/sessions.html'),
          controller: 'UserSessionsCtrl'
        })
        .when('/subscribe', {
          controller: 'SubscribeCtrl'
        })
        .when('/oauths', {
          template: require('../views/oauths.html'),
          controller: 'UserOAuthsCtrl'
        })
        .when('/style-guide', {
          template: require('../views/style-guide.html')
        })
        .when('/complete', {
          // be aware that the backend also redirects to this route
          redirectTo: function(params, path, search) {
            if (search.declined) {
              window.location = confService.getPageUrlForLang(
                'complete_declined'
              );
            } else if (search.sent) {
              window.location = confService.getPageUrlForLang('complete_sent');
            } else {
              window.location = confService.getPageUrlForLang(
                'complete_signed'
              );
            }
          }
        })
        .when('/confirm', {
          controller: 'ConfirmCtrl'
        })
        .when('/users', {
          template: require('../views/users.html'),
          controller: 'OrgUserController'
        })
        .when('/terms', {
          redirectTo: function() {
            window.location = confService.getPageUrlForLang('terms');
          }
        })
        .when('/privacy', {
          redirectTo: function() {
            window.location = confService.getPageUrlForLang('privacy');
          }
        })
        .when('/about-us', {
          redirectTo: function() {
            window.location = confService.getPageUrlForLang('about');
          }
        })
        .when('/faq', {
          redirectTo: function() {
            window.location = confService.getPageUrlForLang('faq');
          }
        })
        .when('/legality', {
          redirectTo: function() {
            window.location = confService.getPageUrlForLang('legality');
          }
        })
        .when('/how-it-works', {
          redirectTo: function() {
            window.location = confService.getPageUrlForLang('how-it-works');
          }
        })
        .when('/payments/finished/:uuid', {
          template: require('../views/payment-finished.html'),
          controller: 'PaymentFinishedCtrl'
        })
        .when('/salesforce/:step', {
          template: require('../views/salesforce.html'),
          controller: 'SalesforceController'
        })
        .when('/demo', {
          redirectTo: function(params, path, search) {
            search.api = search.api || 'v1';
            search.is_demo = true;
            return '/?' + $.param(search);
          }
        })
        .otherwise({
          redirectTo: '/'
        });
    }
  ])

  .factory('HttpInterceptor', [
    '$rootScope',
    '$timeout',
    function($rootScope, $timeout) {
      return {
        request: function(config) {
          config.timeout = 120000; // in ms, 120 secs? yes, 120 secs, until we fix scanning docs for tags async
          return config;
        },
        responseError: function(resp) {
          if (resp.status === 401) {
            store.dispatch('api/handleAuth', { data: resp.data });
          }
          if (resp.status === 412) {
            if (resp.data['requirement'] === 'password') {
              $rootScope.openPasswordModal();
            }
            if (resp.data['requirement'] === 'text_message') {
              $rootScope.openVerifyPhoneModal(resp.data['phone_number']);
            }
          }
          return resp;
        }
      };
    }
  ])
  .config([
    '$httpProvider',
    function($httpProvider) {
      $httpProvider.defaults.xsrfCookieName = 'csrftoken';
      $httpProvider.defaults.xsrfHeaderName = 'X-CSRFToken';
      $httpProvider.interceptors.push('HttpInterceptor');
    }
  ])
  .config([
    '$animateProvider',
    function($animateProvider) {
      $animateProvider.classNameFilter(/angular-animate/);
    }
  ])
  .config([
    '$locationProvider',
    function($locationProvider) {
      $locationProvider.hashPrefix('');
    }
  ])
  .config([
    'gravatarServiceProvider',
    function(gravatarServiceProvider) {
      gravatarServiceProvider.defaults = {
        size: 24,
        default: 'mystery' // Mystery man as default for missing avatars
      };
      // Use https endpoint
      gravatarServiceProvider.secure = true;
    }
  ])
  .config([
    '$sceDelegateProvider',
    function($sceDelegateProvider) {
      $sceDelegateProvider.resourceUrlWhitelist([
        // Allow same origin resource loads.
        'self',
        // allow youtube for iframe video's
        'http*://www.youtube.com/**'
      ]);
    }
  ])

  // FastClick disabled for now as selecting options from a dropdown and selecting a checkbox is not working on iOS
  // Selectize references:
  // https://github.com/selectize/selectize.js/issues/479
  // https://gist.github.com/biesbjerg/bb28ba80862d57306a0e  <-- does fix the options select but not the checkbox
  // .run(function() {
  //     FastClick.attach(document.body);
  // })

  .run([
    '$rootScope',
    '$location',
    '$anchorScroll',
    '$window',
    'ENV',
    'utils',
    'windowService',
    'fontService',
    '$vueRouter',
    '$vuex',
    function(
      $rootScope,
      $location,
      $anchorScroll,
      $window,
      ENV,
      utils,
      windowService,
      fontService,
      $vueRouter,
      $vuex
    ) {
      $rootScope.isVueHomebox = $vuex.getters['conf/isVueHomebox'];
      $rootScope.oldApiFallback = $vuex.getters['conf/isOldApiFallback'];

      // put utils on scope
      $rootScope.utils = utils;

      // initialize the window event service for popup/iframe openers
      windowService.init();

      //when the route is changed scroll to the proper element.
      var init = false;
      var checkScroll = function() {
        if ($location.hash()) {
          $anchorScroll();
        }
      };
      $rootScope.previousRoute = undefined;
      function syncRouter() {
        const noop = () => null;
        $vueRouter.replace(location.hash.toString().slice(1)).then(noop, noop);
      }
      $rootScope.$on('$routeUpdate', syncRouter);

      $rootScope.$on('$routeChangeSuccess', function(
        event,
        newRoute,
        oldRoute
      ) {
        // for a controller to know where the user came from, may be undefined
        $rootScope.previousRoute = oldRoute;
        syncRouter();

        if (!init) {
          init = true;
          var cancelWatchLoader = $rootScope.$watch(
            function() {
              return $rootScope.appLoading;
            },
            function(o, n) {
              if (o !== n) {
                // from loading to not loading
                cancelWatchLoader();
                checkScroll();
              }
            }
          );
        } else {
          checkScroll();
        }
      });
      $rootScope.$watch(
        () => Boolean($vuex.getters['signrequest/hasSigningMode']),
        newVal => {
          document.querySelector('footer').setAttribute('aria-hidden', newVal);
        }
      );
      $rootScope.$watch(
        () => $vuex.getters['conf/localTheme'],
        newVal => {
          if (newVal.primaryColor) {
            $rootScope.primary_color = newVal.primaryColor;
          }
        }
      );
      $rootScope.$watch(
        () => $vuex.getters['users/getTeamDeleteAfter'],
        newVal => {
          $rootScope.team_delete_after = newVal;
        }
      );
    }
  ])

  .run([
    '$rootScope',
    'confFactory',
    'UserService',
    'templateService',
    '$location',
    'ipnConfig',
    'localeService',
    function(
      $rootScope,
      confFactory,
      UserService,
      templateService,
      $location,
      ipnConfig,
      localeService
    ) {
      $rootScope.bodyClass = '';

      // if (window.Intercom) {
      // window.Intercom('boot', {
      //   app_id: 'qnmbr6my'
      // });
      // }

      $rootScope.$on('$routeChangeSuccess', function(event, current) {
        try {
          // change styles based on current route/controller
          if (current && current.$$route) {
            $rootScope.bodyClass = current.$$route.controller;
          }

          // do nothing on redirects
          if (current && (current.$$route || current).redirectTo) {
            return;
          }

          // if (window.Intercom){
          //   if (~['DocCtrl', 'SignTemplateCtrl'].indexOf($rootScope.bodyClass)){
          //     // hide intercom on the signing page and template signing page
          //     window.Intercom('update', {hide_default_launcher: true});
          //   } else {
          //     window.Intercom('update');
          //   }
          // }
          UserService.trackUserPageView(current.params.signer_uuid);
        } catch (e) {
          Sentry.captureException(e);
        }
      });

      $rootScope.settingsLoaded = false;
      confFactory.getSettings().then(function(settings) {
        UserService.setThemeFromTeam(settings.team);
        $rootScope.cssReady = true;
        if (settings.country_iso_code) {
          ipnConfig.initialCountry = settings.country_iso_code.toLowerCase();
        }
        $rootScope.settingsLoaded = true;
      });

      $rootScope.isRTL = localeService.isRTL;
      $rootScope.getActiveLangCode = localeService.getActiveLangCode;

      function classWatch(getValue, getClass) {
        const add = className =>
          className && document.body.classList.add(className);
        const remove = className =>
          className && document.body.classList.remove(className);

        $rootScope.$watch(getValue, (newValue, oldValue) => {
          add(getClass(newValue));
          if (newValue !== oldValue) {
            remove(getClass(oldValue));
          }
        });
      }
      classWatch(
        () => $rootScope.bodyClass,
        value => value
      );
      classWatch(
        () => $rootScope.isRTL(),
        value => (value ? 'rtl' : 'ltr')
      );
      classWatch(
        () => $rootScope.getActiveLangCode(),
        lang => `lang-${lang}`
      );
      classWatch(
        () => $rootScope.isVueHomebox,
        isOn => (isOn && 'vue-homebox') || null
      );
      classWatch(
        () => $rootScope.isVueSignbox,
        isOn => (isOn && 'vue-sign') || null
      );
    }
  ]);

export default app;
