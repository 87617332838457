<template>
  <div class="activate-container" :class="{ mobile: $isMobile }">
    <div class="activate-well">
      <img
        :src="require('./images/sent.svg')"
        :alt="$gettext('Sent')"
        width="160px"
        height="116px"
      />
      <h1 v-if="activate" key="t0" v-translate>Activate your account</h1>
      <h1 v-else key="t1" v-translate>Almost done!</h1>

      <h2 v-if="activate" key="s0" v-translate>
        Almost there! Please check your inbox to activate your account.
      </h2>
      <h2 v-else key="s1" v-translate>
        Click on the confirmation link in your email inbox to continue and
        receive the signed contract.
      </h2>

      <div class="activate-mail-buttons">
        <sr-button
          sr-style="outline"
          round
          href="https://mail.google.com/"
          rel="noopener noreferrer"
        >
          <img
            alt="Gmail"
            :src="
              require('@/images/mail/logo-gmail-png-gmail-icon-download-png-and-vector-1.png')
            "
            :srcset="
              `${require('@/images/mail/logo-gmail-png-gmail-icon-download-png-and-vector-1@2x.png')} 2x,
              ${require('@/images/mail/logo-gmail-png-gmail-icon-download-png-and-vector-1@3x.png')}  3x`
            "
          />
          &nbsp;
          <translate>Open Gmail</translate>
        </sr-button>
        <sr-button
          sr-style="outline"
          round
          href="https://outlook.live.com/mail"
          rel="noopener noreferrer"
        >
          <img
            alt="Outlook"
            :src="require('@/images/mail/microsoft-office-outlook.png')"
            :srcset="
              `${require('@/images/mail/microsoft-office-outlook@2x.png')} 2x,
              ${require('@/images/mail/microsoft-office-outlook@3x.png')} 3x`
            "
          />
          &nbsp;
          <translate>Open Outlook</translate>
        </sr-button>
        <sr-button
          sr-style="outline"
          round
          href="https://mail.yahoo.com/"
          rel="noopener noreferrer"
        >
          <img
            alt="Yahoo"
            :src="require('@/images/mail/yahoo-massanger-512.png')"
            :srcset="
              `${require('@/images/mail/yahoo-massanger-512@2x.png')} 2x,
              ${require('@/images/mail/yahoo-massanger-512@3x.png')} 3x`
            "
          />
          &nbsp;
          <translate>Open Yahoo</translate>
        </sr-button>
      </div>

      <h3 v-if="email" key="b0">
        <translate>Didn’t receive any email?</translate>
        <span>&nbsp;</span>
        <a role="button" class="srlink--text" @click="handleResend">
          <translate>Click here</translate>
        </a>
      </h3>
      <h3 v-else key="b1">
        <a href="/">
          <span>←&nbsp;</span>
          <translate>Go back to the main page</translate>
        </a>
      </h3>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import { parseObjectFromEncodedUrlParam } from '@/utils/encodedParams';
import SrButton from '@/components/elements/SrButton';
import Screen from '@/mixins/Screen';

export default {
  components: {
    SrButton
  },
  mixins: [Screen],
  computed: {
    config() {
      return parseObjectFromEncodedUrlParam(this.$route.query.config) || {};
    },
    email() {
      return this.$route.query.email || this.config.email || null;
    },
    activate() {
      return Boolean(this.config.activate);
    }
  },
  methods: {
    ...mapActions('users', ['resendValidationEmail']),
    async handleResend() {
      this.resendValidationEmail({ email: this.email });
    }
  }
};
</script>
<style scoped>
.activate-container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* compensate for header */
  margin-top: -64px;
  min-height: 99vh;
}
.mobile.activate-container {
  margin-top: 0;
  min-height: unset;
  height: 100%;
  align-items: stretch;
}
.activate-well {
  max-width: 45.5rem;
  min-width: 45.5rem;
  max-height: 40rem;
  display: inline-block;
  border-radius: 15px;

  box-shadow: -2px 6px 44px 10px rgba(0, 0, 0, 0.06);

  background-color: #ffffff;
  text-align: center;
  padding: 0 3em;
}
.mobile .activate-well {
  min-width: unset;
  max-height: unset;
  border-radius: 0;
}
h1 {
  margin-top: 0;
  font-size: 34px;
  font-weight: 900;
  letter-spacing: 0.57px;
}
h2 {
  margin-top: 31px;
  margin-bottom: 31px;
  font-size: 16px;
  font-weight: 300;
  opacity: 0.6;
  letter-spacing: 0.27px;
}
h3 {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.23px;
  margin-bottom: 64px;
}
h3 a:hover {
  text-decoration: none;
}
.activate-well > img {
  margin: 46px 0;
}
.mobile .activate-well > img {
  margin: 26px 0;
}
.activate-mail-buttons {
  margin-bottom: 87px;
}
.mobile .activate-mail-buttons {
  margin-bottom: 34px;
}
.activate-mail-buttons .sr-button {
  color: rgba(17, 17, 17, 0.2) !important;
  min-width: 165px;
  min-height: 41px;
}

.activate-mail-buttons .sr-button ::v-deep span.sr-button--content {
  font-weight: 400;
  color: rgba(17, 17, 17, 0.6) !important;
}
</style>
<style>
body.ConfirmCtrl {
  background-color: #f7f9fe;
}
body.ConfirmCtrl header .sr-container {
  max-width: 100%;
  margin: 0 !important;
}
body.ConfirmCtrl header .app-header--usermenu {
  display: none;
}
body.ConfirmCtrl footer.site__footer {
  display: none;
}
body.ConfirmCtrl .site__header.mobile ~ .site__main {
  margin-top: 0;
  margin-bottom: 0;
  height: calc(100vh - 56px);
}
</style>
