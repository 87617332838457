<template>
  <v-layout wrap>
    <v-flex
      v-for="(name, key) in notificationSettingOptions"
      :key="key"
      data-test-id="notification-settings"
      xs6
      sm6
      md4
      @click="handleUpdate"
    >
      <v-checkbox
        :input-value="userNotificationSettings[key]"
        :label="name"
        :column="false"
        :height="14"
        hide-details
        :readonly="!canUpdateNotificationSettings"
        data-test-id="notification-type"
        @change="
          newValue => updateNotificationSetting(notificationType, key, newValue)
        "
      ></v-checkbox>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    notificationType: { type: String, required: true },
    userNotificationSettings: { type: Object, required: true }
  },
  computed: {
    ...mapGetters('users', ['canUpdateNotificationSettings']),
    notificationSettingOptions() {
      return {
        signrequest_signed: this.$gettext('Document signed'),
        signrequest_declined: this.$gettext('Document declined'),
        signer_email_bounced: this.$gettext('SignRequest email bounced'),
        signer_viewed_email: this.$gettext('Signer viewed email'),
        signer_viewed_document: this.$gettext('Signer viewed document'),
        signer_signed: this.$gettext('Signer signed')
      };
    }
  },
  methods: {
    ...mapActions('users', ['updateNotificationSettings']),
    ...mapActions('permissions', ['showLoginOrSubscribeModal']),
    handleUpdate() {
      if (!this.canUpdateNotificationSettings) {
        this.showLoginOrSubscribeModal({
          feature: 'update_notification_settings'
        });
      }
    },
    updateNotificationSetting(notificationType, key, value) {
      this.updateNotificationSettings({
        notificationType,
        settings: { ...this.userNotificationSettings, [key]: value }
      });
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .v-label {
  font-size: setting(font-size) * 1px;
  font-weight: setting(font-weights, normal);
}
</style>
