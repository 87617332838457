import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('sr-field',{attrs:{"is-empty":_vm.isEmpty,"has-placeholder":Boolean(_vm.placeholder),"label":_vm.label,"label-for":_vm.uid,"help":_vm.help,"horizontal":_vm.horizontal,"validations":(_vm.hasError && _vm.hasState && _vm.validations) || null},on:{"click:label":_vm.focus},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_vm._t("append-outer")]},proxy:true},{key:"append-inner",fn:function(){return [_vm._t("append-inner"),(_vm.$slots.value)?_c('div',{staticClass:"sr-autocomplete--value",class:{ 'sr-autocomplete--value-caret': _vm.showCaret },on:{"click":_vm.focus}},[_vm._t("value")],2):_vm._e(),(_vm.showCaret)?_c('div',{staticClass:"sr-autocomplete--caret",on:{"click":_vm.focus}},[_c(VIcon,[_vm._v("arrow_drop_down")])],1):_vm._e()]},proxy:true}],null,true)},[_c('sr-menu',{ref:"menu",staticClass:"sr-autocomplete",class:{
      'sr-autocomplete--empty': _vm.isEmpty,
      'sr-autocomplete--no-items':
        _vm.filteredItems.length === 0 && !(_vm.showManual && _vm.enableManual),
      'sr-autocomplete--typing': Boolean(_vm.searchInput)
    },attrs:{"id":(_vm.uid + "-list"),"role":"none","list-role":"listbox","activate-on":"open"},on:{"close":_vm.handleClose,"change:menuindex":function($event){_vm.selectedIdx = $event},"change:menuopen":function($event){_vm.menuOpen = $event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.searchInput),expression:"searchInput"}],ref:"input",staticClass:"srtext--text",attrs:{"id":_vm.uid,"role":"combobox","aria-autocomplete":"list","aria-expanded":String(_vm.keyMenuOpen),"aria-controls":(_vm.uid + "-list"),"type":"text","autocomplete":"off","placeholder":_vm.placeholder,"readonly":_vm.readonly,"disabled":_vm.disabled},domProps:{"value":(_vm.searchInput)},on:{"focus":function($event){return _vm.handleFocus(on)},"blur":_vm.handleBlur,"keydown":_vm.handleKeyDown,"input":function($event){if($event.target.composing){ return; }_vm.searchInput=$event.target.value}}},'input',_vm.$attrs,false))]}}])},[_vm._l((_vm.filteredItems),function(item,idx){return _c('div',{key:idx,attrs:{"role":"none"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.stopPropagation();return _vm.handleEnter.apply(null, arguments)}}},[(item.header)?_c('span',{staticClass:"sr-autocomplete--menuitem--header"},[_vm._v("\n        "+_vm._s(item.header)+"\n      ")]):_c('sr-button',{class:{
          'sr-autocomplete--menuitem--active': idx === _vm.keyMenuIndex
        },attrs:{"sr-style":"text","role":"option","tabindex":"-1","aria-selected":idx === _vm.keyMenuIndex},on:{"click":function($event){return _vm.handleChoose(item)}}},[_vm._t("item",function(){return [_vm._v("\n          "+_vm._s(item.text)+"\n        ")]},{"item":item})],2)],1)}),(_vm.showManual && _vm.enableManual && _vm.showManualEntry)?_c('sr-button',{key:"add",class:{ 'v-list__tile--highlighted': _vm.isSelectedManual },attrs:{"sr-style":"text","role":"option","tabindex":-1,"data-test-id":"manual-input","aria-selected":_vm.isSelectedManual},on:{"click":_vm.handleChooseManual}},[_vm._t("additem",function(){return [_c('translate',[_vm._v("Add")]),_c('span',[_vm._v(" ")]),_c('span',[_vm._v(_vm._s(_vm.searchInput))])]},{"value":_vm.searchInput.trim()})],2):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }