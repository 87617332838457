import { getAngularDeps } from './angular-injector';

export const NotifyAngularPlugin = store => {
  // called when the store is initialized
  store.subscribe((mutation, state) => {
    setTimeout(async () => {
      const { $rootScope } = await getAngularDeps('$rootScope');
      if ($rootScope) {
        return $rootScope.$applyAsync();
      }
    }, 0);
  });
};
