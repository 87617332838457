import { request } from '@/store/utils';
import { dropEmpty } from '@/utils/dropEmpty';

export default {
  namespaced: true,
  state: {
    isLoading: false,
    searchEnabled: false
  },
  mutations: {
    setLoading(state, value) {
      state.isLoading = value;
    }
  },
  getters: {
    isSearchEnabled(state) {
      return state.searchEnabled;
    },
    isLoading(state) {
      return state.isLoading;
    }
  },
  actions: {
    async getUserTemplates({ commit, dispatch }) {
      commit('setLoading', true);
      const url = '/docs/get-templates/';
      const response = await dispatch(
        'api/makeRequest',
        { url },
        { root: true }
      );

      commit('users/setUserTemplates', response.templates, { root: true });
      commit('setLoading', false);
    },
    async copyTemplate({ dispatch }, { uuid, box_file_id, box_folder_id }) {
      const url = `/docs/copy-template/${uuid}/`;
      const data = dropEmpty({
        box_file_id: box_file_id || null,
        box_folder_id: box_folder_id || null
      });
      await dispatch(
        'api/makeRequest',
        {
          url,
          method: 'POST',
          data
        },
        { root: true }
      );
      await dispatch('getUserTemplates');
    },
    async moveTemplate({ commit, dispatch }, { uuid, team }) {
      const url = `/docs/move-template/${uuid}/`;
      await dispatch(
        'api/makeRequest',
        { url, method: 'POST', data: { team } },
        { root: true }
      );
    },
    async deleteTemplate({ commit, dispatch }, { uuid, box_file_id }) {
      commit('setLoading', true);
      const url = `/docs/del-template/${uuid}/`;
      const data = dropEmpty({
        box_file_id: box_file_id || null
      });
      await dispatch(
        'api/makeRequest',
        { url, method: 'POST', data },
        { root: true }
      );
      commit('setLoading', false);
    },
    createTemplateReq: request('POST', '/docs/save-template/'),
    async createTemplate(
      { dispatch },
      { uuid, box_file_id, box_folder_id, box_file_version_id }
    ) {
      const data = {
        doc_uuid: uuid,
        box_file_id,
        box_folder_id,
        box_file_version_id
      };
      const { status, doc } = await dispatch('createTemplateReq', { data });
      if (status !== 'SUCCESS') {
        return null;
      }
      return doc;
    }
  }
};
