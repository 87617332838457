import { translate } from 'vue-gettext';
const $gettext = msgid => translate.gettext(msgid);
import { validateEmail } from '@/utils/format';

export function validateSigner(signerList, signer) {
  const otherEmails = signerList
    .filter(iterSigner => iterSigner !== signer)
    .map(iterSigner => iterSigner.email.toLowerCase());

  if (signer.email && otherEmails.includes(signer.email.toLowerCase())) {
    return $gettext('This email is already used by another signer');
  }

  if (!signer.email && !signer.label) {
    return true;
  }
  return validateEmail(signer.email);
}

export function checkSenderEmail(signerList, email) {
  const [{ email: senderEmail }] = signerList;
  return (email || '').toLowerCase() === (senderEmail || '').toLowerCase();
}

export function validateUniqueEmail(signerList, value) {
  if (!value) {
    return true;
  }
  if (checkSenderEmail(signerList, value)) {
    return $gettext(
      "You're using the same email address to send and receive from. Please change the email address of the receiver."
    );
  }
  const emails = signerList.map(iterSigner => iterSigner.email.toLowerCase());

  if (emails.includes(value.toLowerCase())) {
    return $gettext('This email is already used by another signer');
  }

  return validateEmail(value);
}

export default validateSigner;
