import { getCookie } from '@/utils/getJSONCookie';
import { formatQs } from '@/utils/url';

function initialState() {
  return {
    activeCount: 0,
    totalCount: 0,
    webRootURL: '#',
    apiRootURL: ''
  };
}

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    isLoading(state) {
      return state.activeCount !== 0;
    },
    activeCount: state => state.activeCount,
    totalCount: state => state.totalCount,
    makeURL(state) {
      return url => state.webRootURL + url;
    },
    makeApiURL(state) {
      return url => state.apiRootURL + url;
    },
    apiRootURL(state) {
      return state.apiRootURL;
    }
  },
  mutations: {
    reset(state) {
      Object.assign(state, initialState());
    },
    increment(state) {
      state.activeCount += 1;
      state.totalCount += 1;
    },
    setRoot(state, { web, api }) {
      state.apiRootURL = api;
      state.webRootURL = web;
    },
    decrement(state) {
      state.activeCount -= 1;
    }
  },
  actions: {
    async makeRequest(
      { state, commit, dispatch },
      {
        url,
        data,
        method = 'GET',
        silent = false,
        silentError = false,
        expectCodes = [],
        headers = {},
        cache = 'no-cache' as RequestCache,
        ignoreRoot = false
      }
    ) {
      commit('increment');

      let requestHeaders = { ...headers };

      let requestBody = null;
      if (method === 'POST' && data instanceof FormData) {
        requestHeaders['X-CSRFToken'] = getCookie('csrftoken');
        requestBody = data;
      } else if (method === 'POST') {
        requestHeaders['X-CSRFToken'] = getCookie('csrftoken');
        requestHeaders['Content-Type'] = 'application/json';
        requestBody = JSON.stringify(data);
      }

      if (method === 'GET') {
        url = formatQs(url, data);
      }

      try {
        const apiRootURL = ignoreRoot ? '' : state.apiRootURL;
        const response = await fetch(apiRootURL + url, {
          method,
          body: requestBody || null,
          headers: requestHeaders,
          cache,
          credentials: 'same-origin'
        });

        const responseData = await response.json();
        if (response.status === 401) {
          dispatch('handleAuth', { data: responseData });
        } else if (response.status === 412) {
          // do nothing, to be handled explicitely
        } else if (response.ok || expectCodes.includes(response.status)) {
          dispatch('handleResponse', {
            response: { status: response.status, data: responseData },
            silent,
            silentError
          });
        } else {
          dispatch('handleError', {
            response: { data: responseData }
          });
        }

        return responseData;
      } catch (error) {
        const isAborted = error.name === 'AbortError';
        if (isAborted) {
          return { aborted: true };
        }
        if (!silentError) {
          dispatch('handleError', { response: null });
        }
        return { error: true, netError: true };
      } finally {
        commit('decrement');
      }
    },
    async reloadHome() {
      window.location.href = '/';
    },
    async handleResponse(
      { dispatch },
      { response, timeout = 7000, silent, silentError }
    ) {
      const data =
        response && response.hasOwnProperty('data') ? response.data : response;
      const isError =
        (data && (data.status === 'ERROR' || data.error)) ||
        (response &&
          typeof response.status === 'number' &&
          response.status >= 400);

      const requestStatus =
        typeof (data && data.status) === 'string'
          ? data.status.toLowerCase()
          : isError
          ? 'error'
          : 'success';

      function createMessage(message) {
        if (!message) {
          return null;
        }
        dispatch(
          'messages/addMessage',
          message.level
            ? { type: message.level, msg: message.message, timeout }
            : { type: requestStatus, msg: message, timeout },
          { root: true }
        );
      }

      // never suppress errors
      // unless explicitly said to suppress error
      if ((silent && !isError) || !data || (isError && silentError)) {
        return;
      }

      createMessage(data.message || data.detail);
      if (Array.isArray(data.messages)) {
        data.messages.forEach(createMessage);
      }
    },
    handleAuth({ dispatch }, { data }) {
      if (data && data.hasOwnProperty('registered')) {
        dispatch(
          'modals/showLoginModal',
          {
            email: data.email,
            doRegister: !data.registered,
            backends: data.backends,
            // fields below are used by angular controller
            filterOauths: data.registered,
            oauths: data.backends,
            message_type: data.registered ? 'needs_login' : 'needs_register',
            hideClose: false
          },
          { root: true }
        );
      } else {
        dispatch('handleError', {
          response: { data }
        });
      }
    },
    handleError({ dispatch }, { response, timeout = 10000 }) {
      const data =
        response && response.hasOwnProperty('data') ? response.data : response;
      let message = 'E_OOPS';
      if (data && data.message) {
        message = data.message;
      } else if (data && data.detail) {
        message = data.detail;
      }
      dispatch(
        'messages/addMessage',
        { type: 'error', msg: message, timeout },
        { root: true }
      );
    }
  }
};
