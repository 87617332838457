<template>
  <div>
    <overlay :z-index="19" :show="drawerOpen" @click="handleClose" />

    <v-card class="homebox" :class="{ 'homebox--drawer-open': drawerOpen }">
      <signrequest-form
        key="form"
        v-model="form"
        class="main-form"
        :form-disabled="nextDisabled"
        :selected-signer="selectedSigner"
        :template="template"
        :is-bulk-send="isBulkSend"
        @focus="handleClose"
        @showDetails="handleDetails"
        @showSettings="handleSettings"
        @showTemplates="handleTemplates"
        @showSalesforce="handleSalesforce"
        @submit="handleSubmit"
      />

      <v-slide-x-transition mode="out-in">
        <div
          v-if="drawerOpen"
          class="homebox--options-form"
          role="dialog"
          :aria-modal="drawerOpen"
          :aria-hidden="!drawerOpen"
          aria-label="drawerLabel"
        >
          <a
            class="homebox--back-arrow"
            role="button"
            data-test-id="close-options"
            :title="$gettext('Close options dialog')"
            @click.prevent="handleClose"
          >
            <v-icon class="arrow" color="primary">
              close
            </v-icon>
          </a>

          <div class="homebox--overflow">
            <signer-details
              v-if="screen === 'details'"
              v-model="signer"
              :signer-index="selectedSigner"
              :removable="selectedSigner !== 0 || !userLoggedIn"
              :email-editable="
                selectedSigner !== 0 || !userLoggedIn || template
              "
              :template="template"
              :used-emails="otherSignerEmails"
              @submit="handleClose"
              @remove="handleRemoveSelected"
              @close="handleClose"
            />
            <files-form
              v-if="screen === 'templates' || screen === 'salesforce'"
              :screen="screen"
              @submit="handleClose"
              @selected="handleUseFile"
              @close="handleClose"
            />
            <settings-form
              v-if="screen === 'settings'"
              :template="template"
              :is-bulk-send="isBulkSend"
              @submit="handleClose"
              @close="handleClose"
            />
          </div>
        </div>
      </v-slide-x-transition>
    </v-card>
  </div>
</template>
<script>
import variant from '@/utils/variant';
import { mapGetters, mapActions } from 'vuex';
import { patch, replace, updateAtIdx, removeAtIdx } from '@/utils/fp';

import Overlay from '@/components/elements/Overlay';
import SignrequestForm from './SignrequestForm';
import BoxSignrequestForm from './BoxSignrequestForm';
import SignerDetails from './SignerDetails';
import BoxSignerDetails from './BoxSignerDetails';
import SettingsForm from './SettingsForm';
import FilesForm from './FilesForm';

export default {
  components: {
    SignrequestForm: variant(BoxSignrequestForm, SignrequestForm),
    SignerDetails: variant(BoxSignerDetails, SignerDetails),
    SettingsForm,
    FilesForm,
    Overlay
  },
  props: {
    template: { type: Boolean, default: false },
    isBulkSend: { type: Boolean, default: false },
    formDisabled: { type: Boolean, default: false }
  },
  data() {
    return {
      screen: 'form',
      selectedSigner: -1
    };
  },
  computed: {
    ...mapGetters('users', ['user', 'userLoggedIn']),
    ...mapGetters('signrequest', [
      'signrequestForm',
      'somebodyNeedsToSign',
      'currentFileLoaded',
      'firstUUID'
    ]),
    form: {
      get() {
        return this.signrequestForm;
      },
      set(value) {
        return this.updateSignrequestForm(value);
      }
    },
    signer: {
      get() {
        return this.form.signers[this.selectedSigner];
      },
      set(update) {
        this.updateSignrequestForm({
          signers: updateAtIdx(
            this.form.signers,
            this.selectedSigner,
            replace(update)
          )
        });
      }
    },
    otherSignerEmails() {
      return this.form.signers
        .filter((_signer, idx) => idx !== this.selectedSigner)
        .filter(signer => signer.email)
        .map(signer => signer.email.toLowerCase());
    },
    nextDisabled() {
      const [{ email: senderEmail }] = this.form.signers;
      if (this.$props.template) {
        return false;
      }
      return Boolean(
        this.$props.formDisabled || !this.somebodyNeedsToSign || !senderEmail
      );
    },
    drawerOpen() {
      return this.screen !== 'form';
    },
    drawerLable() {
      return (
        {
          settings: this.$gettext('Document settings'),
          details: this.$gettext('Signer details'),
          templates: this.$gettext('Templates'),
          salesforce: this.$gettext('Salesforce')
        }[this.screen] || null
      );
    },
    currentURL() {
      return this.$route ? this.$route.fullPath : null;
    }
  },
  watch: {
    user: {
      immediate: true,
      handler(newUser) {
        if (!this.template) {
          this.copyUser();
        }
      }
    },
    currentFileLoaded(newv, oldv) {
      if (!newv && oldv) {
        // file has been removed
        this.switchScreen('form');
      }
    },
    async form(newForm, oldForm) {
      const [{ email: newSenderEmail }] = newForm.signers;
      const [{ email: oldSenderEmail }] = oldForm.signers;

      if (
        this.user.logged_in ||
        newSenderEmail === oldSenderEmail ||
        !newSenderEmail
      ) {
        return;
      }
      this.checkExistingUser(newSenderEmail);
    },
    firstUUID(newValue, oldValue) {
      const url = new URL(location.hash.slice(1), location.origin);
      url.searchParams.delete('doc_uuid');
      url.searchParams.delete('source');
      url.searchParams.delete('api');
      if (newValue && !this.$props.template) {
        url.searchParams.set('api', 'v1');
        url.searchParams.set('source', 'self');
        url.searchParams.set('doc_uuid', newValue);
      }

      if (this.$route && newValue !== this.$route.query.doc_uuid) {
        location.hash = `#${url.pathname}${url.search}`;
      }
    },
    currentURL: {
      immediate: true,
      handler(newValue, oldValue) {
        if (!this.$props.template && newValue) {
          this.handleUrl(new URL(newValue, location.origin), !oldValue);
        }
      }
    },
    screen(value) {
      if (value === 'form') {
        this.$nextTick(() => {
          this.$el.querySelector('button').focus();
        });
      }
    }
  },
  beforeDestroy() {
    this.discardCurrentDocs();
  },
  methods: {
    ...mapActions('signrequest', [
      'copyUser',
      'updateSignrequestForm',
      'checkExistingUser',
      'fromTemplate',
      'fromRecent',
      'fromIntegration',

      'fromUrlApiv1',
      'discardCurrentDocs'
    ]),
    ...mapActions('events', ['trackOpenSettingsPageEvent']),
    async handleUrl(url, initial) {
      const canRestore = !url.searchParams.has('newdoconly') && initial;
      const openUpload = url.searchParams.has('open_upload');
      const isSignNow =
        url.searchParams.has('sign_now') || url.searchParams.has('do_redirect');

      if (openUpload) {
        this.handleTemplates();
      }

      if (
        url.searchParams.get('doc_uuid') !== this.firstUUID ||
        !url.searchParams.has('doc_uuid')
      ) {
        const ret = await this.fromUrlApiv1({ url, canRestore });
        if (ret && ret.selectFiles) {
          this.handleSalesforce();
        }
      }
      if (isSignNow && this.somebodyNeedsToSign) {
        this.handleSubmit();
      }
    },
    switchScreen(screen, selectedSigner = -1) {
      if (this.screen === screen && this.selectedSigner === selectedSigner) {
        // close
        this.screen = 'form';
        this.selectedSigner = -1;
      } else {
        // open
        this.screen = screen;
        this.selectedSigner = selectedSigner;
      }
    },
    handleDetails(index) {
      return this.switchScreen('details', index);
    },
    handleRemoveSelected() {
      if (this.selectedSigner === 0) {
        this.updateSignrequestForm({
          signers: updateAtIdx(
            this.form.signers,
            this.selectedSigner,
            patch({ email: '' })
          )
        });
      } else {
        const newSigners = removeAtIdx(this.form.signers, this.selectedSigner);
        // if first signer has level approve and we remove last signer so first signer should not have approve level
        const shouldFixLevel =
          newSigners.length === 1 && newSigners[0].level === 'approve';
        this.updateSignrequestForm({
          signers: shouldFixLevel
            ? updateAtIdx(newSigners, 0, patch({ level: 'cc' }))
            : newSigners
        });
      }
      this.handleClose();
    },
    handleClose() {
      return this.switchScreen('form');
    },
    handleSettings(settings) {
      this.trackOpenSettingsPageEvent();
      return this.switchScreen('settings');
    },
    handleTemplates() {
      return this.switchScreen('templates');
    },
    handleSalesforce() {
      return this.switchScreen('salesforce');
    },
    handleUseFile(file) {
      if (this.screen === 'templates') {
        this.fromTemplate(file);
      } else if (this.screen === 'salesforce') {
        this.fromIntegration(file);
      }
      this.handleClose();
    },
    handleSubmit() {
      this.$emit('submit');
    }
  }
};
</script>
<style scoped>
#home-box div.v-card.homebox {
  border-radius: 25px;
  background-color: #ffffff;
  box-shadow: -2px 6px 44px 10px rgba(0, 0, 0, 0.06);
}
.signrequest-lib .v-card {
  box-shadow: none;
  background-color: inherit !important;
}

div.v-card.homebox--drawer-open.homebox {
  border-radius: 25px 0px 0px 25px;
}

.rtl div.v-card.homebox--drawer-open.homebox {
  border-radius: 0px 25px 25px 0px;
}

.popout div.v-card.homebox {
  position: relative;
  z-index: 20;
}

#home-box div.homebox--options-form {
  position: absolute;
  height: 100%;
  width: 80%;
  left: 100%;
  right: initial;
  top: 0px;

  z-index: 21;
}

.rtl #home-box div.homebox--options-form {
  left: initial;
  right: 100%;
}

#home-box div.homebox--overflow {
  overflow-y: auto;
  scrollbar-width: thin;
  height: 100%;
  background: #f3f3f3;
}

div.homebox--options-form .homebox--back-arrow {
  display: block;
  position: absolute;
  left: -30px;
  top: 0px;
  background: #f3f3f3;
  padding: 0px 4px 5px 4px;
  width: 32px;

  border-radius: 0px 0px 0px 8px;
  text-decoration: none;
}

.rtl div.homebox--options-form .homebox--back-arrow {
  right: -32px;
  left: initial;
  border-radius: 0px 0px 8px 0px;
}

div.homebox--options-form .homebox--back-arrow .v-icon {
  font-size: 24px;
  line-height: 1rem;
}

.v-card div.homebox--overflow {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 25px;
  border-top-left-radius: 0px;
  border-top-right-radius: 25px;
}

.rtl .v-card div.homebox--overflow {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 25px;
  border-top-right-radius: 0px;
  border-top-left-radius: 25px;
}

.mobile div.homebox--options-form {
  left: 20%;
  right: initial;

  box-shadow: -2px 6px 44px 28px rgba(0, 0, 0, 0.4);
}

.rtl .mobile div.homebox--options-form {
  box-shadow: -2px 6px 44px 28px rgba(0, 0, 0, 0.4);
}

.rtl .mobile div.homebox--options-form {
  left: 0;
  right: initial;
}
.sr-field .help {
  top: calc(50% - 1em);
  position: absolute;
}
.ltr .sr-field .help {
  right: 1rem;
}
.rtl .sr-field .help {
  left: 1rem;
}
.homebox--options-form /deep/ .sr-combobox .sr-field--control {
  margin-top: 10px;
}
.signrequest-lib .homebox--drawer-open .main-form {
  display: none;
}
.signrequest-lib .homebox--options-form {
  position: static;
}
.signrequest-lib .ltr div.homebox--options-form .homebox--back-arrow {
  left: 0;
}
.signrequest-lib .rtl div.homebox--options-form .homebox--back-arrow {
  right: 0;
}
</style>
