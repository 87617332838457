<template>
  <div :class="`ph-text--${type}`">
    <icon :value="type" />
    <span
      :class="['ph-text--label', { 'ph-text--standard-label': isStandard }]"
    >
      {{ email || label }}
    </span>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import SignFieldCheckBox from '@/components/icons/SignFieldCheckBox';
import SignFieldTextInput from '@/components/icons/SignFieldTextInput';
import SignFieldSignature from '@/components/icons/SignFieldSignature';
import SignFieldCalendar from '@/components/icons/SignFieldCalendar';

const Icon = {
  props: {
    value: { type: String, required: true }
  },
  render(h) {
    const Component = {
      checkbox: SignFieldCheckBox,
      text: SignFieldTextInput,
      signature: SignFieldSignature,
      date: SignFieldCalendar
    }[this.$props.value];
    return h(Component || 'div');
  }
};
export default {
  components: {
    Icon
  },
  props: {
    label: { type: String, required: true },
    isStandard: { type: Boolean, required: false, default: false },
    signerIndex: { type: Number, default: 0 },
    type: { type: String, default: 'signature' }
  },
  computed: {
    ...mapGetters('signrequest', ['signrequestForm']),
    email() {
      const signer = this.signrequestForm.signers[this.$props.signerIndex];
      return signer && signer.email;
    }
  }
};
</script>
<style scoped>
.ph-text--label {
  font-size: 16px;
  flex-grow: 1;
}
.ph-outer--small .ph-text--label {
  font-size: 12px;
}

.wrapper .ph-text--checkbox .ph-text--label {
  display: none;
}

svg {
  display: block;
  width: 23px;
  min-width: 23px;
  flex-shrink: 0;
  height: auto;
  fill: currentColor;
}

.wrapper .ph-text--checkbox svg {
  height: 100%;
  width: 100%;
}
</style>
