import pick from 'lodash/pick';
import { getVariant } from '@/utils/variant';
import salesforce from '@/store/modules/salesforce';
import { getAngularDeps } from '@/plugins/angular-injector';
import { initialSigner, fixAttachments } from '@/utils/addSigner';
import { readFile } from '@/utils/readFile';
import { dropEmpty } from '@/utils/dropEmpty';
import { formatQs } from '@/utils/url';
import { request } from '@/store/utils';
import { merge, patch, append, prepend, zipPad } from '@/utils/fp';
import {
  serializeSignrequests,
  serializeTemplate,
  parseSignrequest,
  parseUrlApi
} from './signrequestApi';
import history from '@/utils/history';
import jstz from '@/utils/timeZone';
import { signs, signerNeedsPlaceholder } from '@/utils/signerLevel';

const PLACEHOLDER_UPDATE_MS = 500;
const CONVERT_RETRY_TIMEOUT = 1000;

const PROD_DOMAIN = 'https://signrequest.com';
const DEMO_PATH = '/static/demo/SignRequestDemoDocument.pdf';
const DEMO_URL = PROD_DOMAIN + DEMO_PATH;
const DEMO_NAME = 'SignRequestDemoDocument.pdf';

const AUTH_MARKER = Symbol('auth');
class AuthError extends Error {
  constructor(details) {
    super('Login required');
    this.details = details;
    this._marker = AUTH_MARKER;
  }
}
AuthError.isAuth = function(value) {
  return value && value._marker === AUTH_MARKER;
};

const RETRY_MARKER = Symbol('retry');
class RetryError extends Error {
  constructor(ms = 0) {
    super(`Should wait for: ${ms}`);
    this.ms = ms;
    this._marker = RETRY_MARKER;
  }
}
RetryError.isRetry = function(value) {
  return value && value._marker === RETRY_MARKER;
};

function timeout(timeMs) {
  return new Promise(resolve => {
    setTimeout(() => resolve(), timeMs);
  });
}

function canForceLogin(actionFn) {
  return async function(store, ...args) {
    let success = false;
    let ret;
    while (!success) {
      try {
        ret = await actionFn.call(this, store, ...args);
        success = true;
      } catch (e) {
        if (AuthError.isAuth(e)) {
          let isLogged = store.dispatch(
            'modals/showLoginModal',
            { ...e.details },
            { root: true }
          );
          if (!document.hasFocus() || !(await isLogged)) {
            return null;
          }
        } else {
          throw e;
        }
      }
    }
    return ret;
  };
}

function canRetry(actionFn) {
  return async function canRetryF(store, params = {}) {
    let retry = 0;
    let success = false;
    let ret;
    while (!success) {
      try {
        ret = await actionFn.call(this, store, { retry, ...params });
        success = true;
      } catch (e) {
        retry += 1;
        if (RetryError.isRetry(e)) {
          await timeout(e.ms);
        } else {
          throw e;
        }
      }
    }
    return ret;
  };
}

function trackProgress(actionFn, setterName) {
  return async function(store, ...args) {
    store.commit(setterName, true);
    try {
      const ret = await actionFn.call(this, store, ...args);
      store.commit(setterName, false);
      return ret;
    } catch (e) {
      store.commit(setterName, false);
      throw e;
    }
  };
}

function blocking(actionFn, progressGetter, progressSetter) {
  return async function(store, ...args) {
    if (store.getters[progressGetter]) {
      return null;
    }
    return trackProgress(actionFn, progressSetter).call(this, store, ...args);
  };
}

function confirmTermsAndConds(actionFn) {
  return async function(store, ...args) {
    // we don't have terms modal on box, because we wait for legal (per request from Michie)
    if (!this.getters['users/userAgreedToTerms'] && getVariant() === 'sr') {
      const ret = await store.dispatch(
        'modals/showTermsModal',
        {
          approve_only: store.getters.canApprove
        },
        { root: true }
      );

      if (ret !== true) {
        return null;
      }
      await store.dispatch('users/acceptTermsAndConditions', null, {
        root: true
      });
    }
    return actionFn.call(this, store, ...args);
  };
}

function wrapWith(wrappers, actionFn) {
  return wrappers.reduce((fn, wrapperFn) => wrapperFn(fn), actionFn);
}

function guessType(filename) {
  if (!filename) {
    return null;
  }
  const [ext] = filename
    .toLowerCase()
    .split('.')
    .slice(-1);
  return ext;
}

function isPdf(file) {
  if (!file) {
    return false;
  }
  return file.type === 'application/pdf' || guessType(file.name) === 'pdf';
}

function findById(list, { localId = null, uuid = null }) {
  return list.find(
    element =>
      element &&
      ((localId !== null && element.localId === localId) ||
        (uuid !== null && element.uuid === uuid))
  );
}

function updateById(
  list,
  { id = null, localId = null, idx = null, uuid = null },
  updateFn
) {
  const match = element =>
    (localId !== null && element.localId === localId) ||
    (id !== null && element.id === id) ||
    (uuid !== null && element.uuid === uuid);
  return list.map(element => (match(element) ? updateFn(element) : element));
}

function updateSR(state, params, fn) {
  if (params.append) {
    state.signrequests = append(
      state.signrequests,
      fn(merge(initialSignrequest(), { localId: state.nextSRIndex }))
    );
    state.nextSRIndex++;
  } else {
    state.signrequests = Object.freeze(
      updateById(state.signrequests, params, fn)
    );
  }
}

function initialForm() {
  return Object.freeze({
    signers: Object.freeze([initialSigner(0)]),
    name: '',
    message: '',
    subject: '',
    auto_expire_days: 0,
    send_reminders: false,
    disable_attachments: false,
    prefill_tags: [],
    copy: false
  });
}

function initialSignrequest() {
  return Object.freeze({
    uuid: null,
    file: null,
    claimed: false,
    contents: null,
    uploadPromise: null,
    integration: null,
    integrationId: null,
    localId: 0,
    form: Object.freeze(initialForm()),
    pageUUIDs: Object.freeze([]),
    placeholders: Object.freeze([]),
    attachments: Object.freeze([]),
    signers: Object.freeze([]),
    template: null,
    esignDisclosure: null,
    publicLink: null,
    perm: null,
    shortId: null,
    boxFileId: null,
    boxFileVersionId: null,
    boxFolderId: null,
    created: null
  });
}

function initialState() {
  return {
    prepareMode: false,
    signingMode: false,
    isDownloadingAll: false,
    nextSRIndex: 1,
    nextPlaceholderId: 0,
    currentFormId: 0,
    signrequests: Object.freeze([initialSignrequest()]),
    isPopup: null,
    popupParams: {},
    signParams: Object.freeze({
      signerToken: null,
      tooltip: null,
      disableAttachments: false,
      disableEmails: false,
      disableText: false,
      disableDate: false,
      disableTextSignatures: false,
      disableDrawSignatures: false,
      disableUploadSignatures: false,
      forceSignatureColor: null,
      useStampedSignatues: false,
      serverNext: null,
      hideDecline: false,
      next: null
    }),
    bulkSendParams: Object.freeze({
      enabled: false,
      bulksend_id: null,
      emailsPasted: null,
      results: Object.freeze([]),
      task_id: null
    }),
    createProgress: false,
    finalizeProgress: false,
    signer: null
  };
}

const isUsablePlaceholder = (placeholder, signers) =>
  signerNeedsPlaceholder(signers[placeholder.signer_index]);

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    firstUUID(state) {
      const [firstSR] = state.signrequests;
      return firstSR.uuid || null;
    },
    selectedSr(state) {
      return findById(state.signrequests, { localId: state.currentFormId });
    },
    firstSr(state) {
      return state.signrequests[0];
    },
    lastSr(state) {
      return state.signrequests[state.signrequests.length - 1];
    },
    signrequestFormSignersOrder(state, getters) {
      const { form } = getters.firstSr;
      return Object.freeze(
        form.signers.reduce((acc, signer) => {
          return signer.email !== '' ? { [signer.email]: signer.order } : null;
        }, {})
      );
    },
    signrequestForm(state, getters) {
      const { form } = getters.selectedSr;
      const { form: firstForm } = getters.firstSr;
      const orders = getters.signrequestFormSignersOrder;
      let signers = form.signers;
      if (orders) {
        signers = Object.freeze(
          form.signers.map(signer => {
            return orders[signer.email]
              ? merge(signer, { order: orders[signer.email] })
              : signer;
          })
        );
      }

      return form.copy
        ? merge(firstForm, { name: form.name, copy: true })
        : merge(form, { signers });
    },
    template(state, getters) {
      return getters.selectedSr.template || null;
    },
    signingContactsCount(state, getters) {
      return getters.signrequestForm.signers.filter(signer =>
        signs(signer.level)
      ).length;
    },
    senderNeedsToSign(state, getters) {
      const [sender] = getters.signrequestForm.signers;
      return signs(sender.level);
    },
    somebodyNeedsToSign(state, getters) {
      return getters.signingContactsCount > 0;
    },
    othersNeedToSign(state, getters) {
      return getters.signrequestForm.signers
        .slice(1)
        .map(signer => signer.level)
        .some(signs);
    },
    othersNeedPlaceholders(state, getters) {
      return getters.signrequestForm.signers
        .slice(1)
        .some(signerNeedsPlaceholder);
    },
    hasFile(state, getters) {
      return state.signrequests.some(signrequest => Boolean(signrequest.file));
    },
    currentFileLoaded(state, getters) {
      return Boolean(getters.selectedSr.contents);
    },
    currentFileLoading(state, getters) {
      return Boolean(getters.selectedSr.uploadPromise);
    },
    hasCurrentFile(state, getters) {
      return getters.currentFileLoaded || getters.currentFileLoading;
    },
    hasPrepareMode(state) {
      return state.prepareMode;
    },
    hasSigningMode(state) {
      return state.signingMode;
    },
    isDownloadingAll(state) {
      return state.isDownloadingAll;
    },
    nextSignerUrl(state, getters) {
      return getters.signParams.serverNext;
    },
    isLast(state, getters) {
      return getters.hasSigningMode
        ? getters.nextSignerUrl === null
        : getters.selectedSr == getters.lastSr;
    },
    firstFileLoaded(state, getters) {
      const [{ contents }] = state.signrequests;
      return Boolean(contents);
    },
    fileInfo(state) {
      return ({
        localId,
        file,
        uuid,
        contents,
        claimed,
        uploadPromise,
        form
      }) =>
        Object.freeze({
          localId,
          fileName: form.name || 'document',
          fileType: (file && file.type) || guessType(form.name) || null,
          fileSize: (file && file.size) || null,
          fileUUID: uuid || null,
          fileContents: contents || null,
          fileReady: !uploadPromise,
          claimed,
          isSelected: localId === state.currentFormId
        });
    },
    allFiles(state, getters) {
      return state.signrequests
        .filter(signrequest => signrequest.file)
        .map(getters.fileInfo);
    },
    selectedFileInfo(state, getters) {
      return getters.fileInfo(getters.selectedSr);
    },
    hasSelectedFile(state, getters) {
      return Boolean(getters.selectedSr.file);
    },
    integrationName(state) {
      const [{ integration }] = state.signrequests;
      return integration || null;
    },
    integrationDoneUrl(state, getters) {
      return (
        {
          salesforce: '/salesforce/done'
        }[getters.integrationName] || null
      );
    },
    isPopup(state) {
      return state.isPopup;
    },
    popupParams(state) {
      return state.popupParams;
    },
    signParams(state) {
      return state.signParams;
    },
    documentTooltip(state, getters) {
      return getters.signParams.tooltip;
    },
    placeholders(state, getters) {
      const signers = getters.hasSigningMode
        ? getters.signrequestSigners
        : getters.signrequestForm.signers;
      return getters.selectedSr.placeholders.map(placerholder =>
        isUsablePlaceholder(placerholder, signers)
          ? placerholder
          : merge(placerholder, { signer_index: -1 })
      );
    },
    placeholdersForOthers(state, getters) {
      return getters.placeholders.filter(
        placeholder => placeholder.signer_index !== 0
      );
    },
    autoFixPlaceholderMap(state, getters) {
      return getters.signrequestForm.signers
        .filter(signerNeedsPlaceholder)
        .map(signer => getters.signrequestForm.signers.indexOf(signer));
    },
    firstSigningIndex(state, getters) {
      const [first = -1] = getters.autoFixPlaceholderMap;
      return first;
    },
    defaultSignerIndex(state, getters) {
      const [lastPlaceholder] = getters.placeholders
        .filter(placeholder => placeholder.signer_index !== -1)
        .slice(-1);
      return lastPlaceholder
        ? lastPlaceholder.signer_index
        : getters.firstSigningIndex;
    },
    hasMisplacedPlaceholder(state, getters) {
      const signers = getters.signrequestForm.signers;
      return getters.selectedSr.placeholders.some(
        placeholder =>
          !signerNeedsPlaceholder(signers[placeholder.signer_index]) &&
          signerNeedsPlaceholder(
            signers[getters.autoFixPlaceholderMap[placeholder.signer_index]]
          )
      );
    },
    hasHiddenPlaceholder(state, getters) {
      const [{ form: firstForm }] = state.signrequests;

      const getForm = signrequest =>
        signrequest.form.copy ? firstForm : signrequest.form;

      return state.signrequests.some(signrequest =>
        signrequest.placeholders.some(
          placeholder =>
            !isUsablePlaceholder(placeholder, getForm(signrequest).signers)
        )
      );
    },
    hasActionablePlaceholders(state, getters) {
      return getters.placeholders.some(
        placeholder =>
          placeholder['action_required'] && !placeholder['editable']
      );
    },
    isReadyToFinalize(state, getters) {
      // approve-only users are not supposed to sign or
      // fill in any placeholders
      if (getters.canApprove) {
        return true;
      }
      // Actionable placehodlers are placeholders that were assigned
      // to current user by document sender (as opposed to someone else) AR && !E.
      // Self-added placeholders are AR && E, since they still belong to current
      // user, but can be deleted. Those only exist in free signing mode in absense
      // of sender-provided placeholders.
      // Required placeholders are ones that demand explicit input from user, per request
      // from document sender. Explicit input can also be "skip this checkbox".
      const hasFilledAllRequired = getters.placeholders
        .filter(
          placeholder =>
            placeholder['action_required'] && placeholder['required']
        )
        .every(placeholder => placeholder['data_uri']);
      // If document has at least one placeholder assigned to current user bu sender
      // we have to fill in ALL required placeholders. If all placeholders are optional
      // we still have to fill at least one of them to go furher.
      // If we only have prefilled text, checkbox or date it still counts.
      const hasFilledSomething = getters.placeholders.some(
        placeholder => placeholder['action_required'] && placeholder['data_uri']
      );
      // In free-signing mode we can add any number of text, date, checkbox and signatures
      // but can only go further once one signature is added.
      const hasFilledSignature = getters.placeholders.some(
        placeholder =>
          placeholder['action_required'] &&
          placeholder['data_uri'] &&
          placeholder['type'] === 's'
      );
      return getters.hasActionablePlaceholders
        ? hasFilledAllRequired && hasFilledSomething
        : hasFilledSignature;
    },
    signrequestActionRequiredSummary(state, getters) {
      return getters.placeholders
        .filter(p => p['action_required'])
        .reduce(
          (acc, p) => {
            const key = p['type'] === 's' ? 'signatures' : 'fields';
            acc[key] += 1;
            return acc;
          },
          { signatures: 0, fields: 0 }
        );
    },
    signrequestAttachments(state, getters) {
      return getters.selectedSr.attachments;
    },
    signrequestSigners(state, getters) {
      return getters.selectedSr.signers;
    },
    signerAttachments(state, getters) {
      return Object.freeze(
        getters.signrequestSigners.map(signer => ({
          isCurrent: signer === getters.currentSigner,
          name: signer.name,
          list: signer.attachments
        }))
      );
    },
    currentSigner(state, getters) {
      return getters.signrequestSigners.find(
        signer => signer.uuid === (state.signer && state.signer.uuid)
      );
    },
    currentSignerAttachments(state, getters) {
      return getters.currentSigner ? getters.currentSigner.attachments : [];
    },
    requiredAttachments(state, getters) {
      if (!getters.currentSigner) {
        return [];
      }
      const attachments = getters.currentSigner.attachments || [];
      const attachmentFor = {};
      attachments.forEach(attach => {
        const key =
          (attach.for_attachment && attach.for_attachment.uuid) || 'free';
        const uploads = attachmentFor[key] || [];
        attachmentFor[key] = [...uploads, attach];
      });
      const required = getters.currentSigner['required_attachments'].map(req =>
        Object.freeze({
          ...req,
          uploads: attachmentFor[req.uuid] || []
        })
      );
      return Object.freeze([
        ...required,
        { uuid: null, uploads: attachmentFor.free || [] }
      ]);
    },
    requiredAttachmentsFilled(state, getters) {
      return getters.requiredAttachments
        .filter(req => req.uuid)
        .every(req => req.uploads.length > 0);
    },
    hasRequiredAttachments(state, getters) {
      return getters.requiredAttachments.some(req => req.uuid);
    },
    signerLanguage(state, getters) {
      return getters.currentSigner ? getters.currentSigner.language : null;
    },
    isCreating(state) {
      return Boolean(state.createProgress);
    },
    isFinalizing(state) {
      return Boolean(state.finalizeProgress);
    },
    canSign(state, getters) {
      return Boolean(
        getters.currentSigner && getters.currentSigner.action_required
      );
    },
    hasSigned(state, getters) {
      return Boolean(getters.currentSigner && getters.currentSigner.signed);
    },
    canDecline(state, getters) {
      return Boolean(getters.canSign && !state.signParams.hideDecline);
    },
    hasDeclined(state, getters) {
      return Boolean(getters.currentSigner && getters.currentSigner.declined);
    },
    canApprove(state, getters) {
      return Boolean(
        getters.canSign &&
          getters.currentSigner &&
          getters.currentSigner.approve_only
      );
    },
    canAddAttachment(state, getters) {
      return !getters.signParams.disableAttachments && getters.canSign;
    },
    canForward(state, getters) {
      return getters.canSign;
    },
    hasForwarded(state, getters) {
      return Boolean(
        getters.currentSigner && getters.currentSigner.forwarded_on
      );
    },
    signatureOptions(_state, getters, _rootState, rootGetters) {
      let forceSignatureColor = getters.signParams.forceSignatureColor;
      if (!forceSignatureColor) {
        // signrequest can be sent to this team without forceSignatureColor
        // (team is a receiver, rare case)
        // in this case we force it based on the team setting
        const teamSettings = rootGetters['users/teamSettings'];
        if (teamSettings && teamSettings.default_force_signature_color) {
          forceSignatureColor = teamSettings.default_force_signature_color;
        }
      }

      return Object.freeze({
        forceSignatureColor,
        useStampedSignatues: getters.signParams.useStampedSignatues,
        text: !getters.signParams.disableTextSignatures,
        draw: !getters.signParams.disableDrawSignatures,
        upload: !getters.signParams.disableUploadSignatures
      });
    },
    canUsePlaceholderType(state, getters) {
      if (!getters.hasSigningMode) {
        return Object.freeze({
          text: true,
          date: true,
          checkbox: true,
          signature: true
        });
      }
      return Object.freeze({
        text: !getters.signParams.disableText,
        checkbox: !getters.signParams.disableText, // yes, checkbox doesn't have it's own flag
        date: !getters.signParams.disableDate,
        signature: true
      });
    },
    signerUUID(state, getters) {
      return state.signer ? state.signer.uuid : null;
    },
    downloadLink(_state, getters) {
      const { uuid } = getters.selectedSr;
      const { signerUUID } = getters;
      if (!signerUUID || !uuid) {
        return null;
      }
      return `/docs/download-doc/${uuid}/${signerUUID}`;
    },
    downloadAllLink(_state, getters) {
      const { uuid } = getters.selectedSr;
      const { signerUUID } = getters;
      return `/docs/download-all-documents/${uuid}/`;
    },
    showDownloadAll(_state, getters) {
      const { uuid } = getters.selectedSr;
      const { signerUUID } = getters;
      return signerUUID && uuid && getters.isDownloadingAll;
    },
    publicLink(_state, getters) {
      return (getters.selectedSr && getters.selectedSr.publicLink) || null;
    },
    isBulkSend(state) {
      return Boolean(state.bulkSendParams.enabled);
    },
    allIsPermittedForDocument(state) {
      return state.signrequests.some(sr => sr.perm === 'all' || sr.boxFileId);
    }
  },
  mutations: {
    reset(state) {
      Object.assign(state, initialState());
    },
    updateFormIdx(state, { localId, form }) {
      updateSR(state, { localId }, signrequest =>
        merge(signrequest, {
          form: merge(signrequest.form, form)
        })
      );
    },
    setCurrentFormId(state, { localId }) {
      state.currentFormId = localId;
    },
    setTemplateDefaults(state, fromId) {
      const [{ localId, form, template }] = state.signrequests;
      if (!template || !template.signrequest) {
        return;
      }

      if (localId !== fromId) {
        return;
      }
      const pickDefaults = conf =>
        conf ? pick(conf, ['level', 'order', 'required_attachments']) : {};

      // If at least one of contacts in a template has label
      // or email prefilled, we add all of them.
      const hasRoleOrEmail = template.signrequest.signers.some(
        signer => signer.email || signer.label
      );
      const signers = zipPad(form.signers, template.signrequest.signers)
        .map(([signer = {}, defaults = null], idx) =>
          merge(
            initialSigner(idx),
            defaults || {},
            signer,
            pickDefaults(defaults)
          )
        )
        .filter(signer => signer.email || signer.label || hasRoleOrEmail)
        .map(fixAttachments);
      updateSR(state, { localId }, signrequest =>
        merge(signrequest, {
          form: merge(signrequest.form, {
            auto_expire_days: template.auto_expire_days,
            subject: template.signrequest.subject,
            message: template.signrequest.message,
            send_reminders: Boolean(template.signrequest.send_reminders),
            name: form.name || template.name,
            signers
          })
        })
      );
    },
    pushFile(state, { file, name, uuid, integration, integrationId }) {
      const [
        { form: firstForm, file: firstFile, localId: firstLocalid }
      ] = state.signrequests;
      const makeNew = state.signrequests.length !== 1 || firstFile;

      const nextSR = {
        file: Object.freeze(file || { fake: true }),
        uuid: uuid || null,
        integration: integration || null,
        integrationId: integrationId || null,
        form: merge(firstForm, {
          name: (file && file.name) || name || null,
          copy: Boolean(makeNew)
        })
      };
      updateSR(
        state,
        { append: makeNew, localId: firstLocalid },
        patch(nextSR)
      );
      state.prepareMode = true;
    },
    setUploadPromise(state, { localId, promise }) {
      updateSR(state, { localId }, patch({ uploadPromise: promise }));
    },
    setFileContents(state, { localId, contents, type }) {
      updateSR(state, { localId }, signrequest => {
        if (!signrequest.file) {
          return signrequest;
        }
        return merge(signrequest, {
          contents,
          file: signrequest.file.fake
            ? merge(signrequest.file, { size: contents.length, type })
            : signrequest.file
        });
      });
    },
    uploaded(state, { localId, doc, isPrepared = false }) {
      const {
        uuid,
        name,
        template,
        signer,
        pageUUIDs,
        placeholders,
        attachments,
        signers,
        esignDisclosure,
        created,
        shortId,
        publicLink,
        perm,
        form,
        boxFileId,
        boxFileVersionId,
        boxFolderId,
        isTemplate
      } = parseSignrequest(doc);
      updateSR(state, { localId }, signrequest =>
        merge(signrequest, {
          uuid,
          claimed: true,
          pageUUIDs,
          template: Object.freeze(template || null),
          form: merge(signrequest.form, dropEmpty(form), {
            name: name || signrequest.form.name,
            signers:
              isPrepared || isTemplate ? signers : signrequest.form.signers
          }),
          placeholders,
          attachments,
          signers,
          esignDisclosure,
          created,
          shortId,
          boxFileId,
          boxFolderId,
          boxFileVersionId,
          publicLink,
          perm
        })
      );
      state.signer = Object.freeze(signer);
    },
    setPages(state, { localId, doc }) {
      const { pageUUIDs, placeholders } = parseSignrequest(doc);
      updateSR(state, { localId }, signrequest =>
        merge(signrequest, {
          pageUUIDs,
          placeholders: [
            ...signrequest.placeholders.filter(
              placeholder => placeholder.uuid === null
            ),
            ...placeholders
          ]
        })
      );
    },
    appendAttachment(state, { localId, attachment }) {
      updateSR(state, { localId }, signrequest =>
        merge(signrequest, {
          attachments: append(signrequest.attachments, attachment)
        })
      );
    },
    removeAttachment(state, { localId, uuid }) {
      updateSR(state, { localId }, signrequest =>
        merge(signrequest, {
          attachments: signrequest.attachments.filter(
            attachment => attachment.uuid !== uuid
          )
        })
      );
    },
    prependSignerAttachment(state, { localId, signerUUID, attachment }) {
      updateSR(state, { localId }, signrequest =>
        merge(signrequest, {
          signers: updateById(
            signrequest.signers,
            { uuid: signerUUID },
            signer =>
              merge(signer, {
                attachments: prepend(attachment, signer.attachments)
              })
          )
        })
      );
    },
    removeSignerAttachment(state, { localId, signerUUID, attachmentUUID }) {
      updateSR(state, { localId }, signrequest =>
        merge(signrequest, {
          signers: updateById(
            signrequest.signers,
            { uuid: signerUUID },
            signer =>
              merge(signer, {
                attachments: signer.attachments.filter(
                  attachment => attachment.uuid !== attachmentUUID
                )
              })
          )
        })
      );
    },
    signerPhoneVerified(state, { localId, signerUUID }) {
      updateSR(state, { localId }, signrequest =>
        merge(signrequest, {
          signers: updateById(
            signrequest.signers,
            { uuid: signerUUID },
            patch({ phone_number_verified: true })
          )
        })
      );
    },
    uploadFailed(state, { localId, keepFile }) {
      updateSR(
        state,
        { localId },
        keepFile
          ? patch({ claimed: false })
          : patch({ claimed: false, file: null, uuid: null })
      );
    },
    clearPromise(state, { localId }) {
      updateSR(state, { localId }, patch({ uploadPromise: null }));
    },
    discardFile(state, { localId }) {
      updateSR(state, { localId }, signrequest =>
        merge(initialSignrequest(), {
          localId,
          form: signrequest.form
        })
      );
    },
    discardSignrequest(state, idToDelete) {
      const newList = state.signrequests.filter(
        signrequest => signrequest.localId !== idToDelete
      );
      const [{ localId: firstFormId }] = newList;
      const [{ form: originalFirstForm }] = state.signrequests;

      if (state.currentFormId === idToDelete) {
        state.currentFormId = newList[newList.length - 1].localId;
      }
      state.signrequests = newList;
      updateSR(state, { localId: firstFormId }, signrequest =>
        signrequest.form.copy
          ? merge(signrequest, {
              form: merge(originalFirstForm, {
                name: signrequest.form.name,
                copy: false
              })
            })
          : signrequest
      );
    },
    replaceFile(state, { localId, file, name, contents }) {
      updateSR(state, { localId }, signrequest =>
        merge(signrequest, {
          file: Object.freeze(file || { fake: true }),
          contents,
          form: merge(signrequest.form, { name })
        })
      );
    },
    markReclaim(state) {
      state.signrequests = state.signrequests.map(patch({ claimed: false }));
    },
    setPopup(state, { flag, next, close, sr_window_id }) {
      state.isPopup = flag;
      state.popupParams = flag
        ? Object.freeze({
            next:
              next ||
              (close && close !== 'false' && close !== '0' ? 'close' : null),
            sr_window_id
          })
        : Object.freeze({});
    },
    setSignParams(state, params) {
      state.signParams = merge(params);
    },
    setDocumentTooltip(state, tooltip) {
      state.signParams = merge(state.signParams, { tooltip });
    },
    setBulkSendParams(state, params) {
      const [{ localId, form, template }] = state.signrequests;

      const [firstConfig] = params.results;
      const { prefill_tags, ...contacts } = firstConfig;
      const numberOfContacts = Object.keys(contacts).length;
      const emails = [null];
      for (let x = 1; x <= numberOfContacts; x++) {
        const email = contacts[`contact_${x}_email`];
        if (!email) {
          break;
        }
        emails.push(email);
      }
      const signers = zipPad(form.signers, emails).map(([signer = {}, email]) =>
        email ? merge(signer, { email }) : signer
      );
      state.bulkSendParams = merge(params, { enabled: true });
      updateSR(state, { localId }, signrequest =>
        merge(signrequest, {
          form: merge(signrequest.form, { signers })
        })
      );
    },
    setCreateProgress(state, value) {
      state.createProgress = value;
    },
    setFinalizeProgress(state, value) {
      state.finalizeProgress = value;
    },
    pushPlaceholder(
      state,
      { defaultSignerIndex, position: { x, y, width, height }, type, ...rest }
    ) {
      function fixPos(start, size) {
        const overflow = start + size - 1;
        return start - Math.max(overflow, 0);
      }

      const id = String(state.nextPlaceholderId);
      const updatePlaceholders = signrequest => {
        const [lastOfSameType] = signrequest.placeholders
          .filter(ph => ph.type === type)
          .slice(-1);
        const isEmpty = !rest.data_uri;
        const defaultWidth =
          lastOfSameType && isEmpty ? lastOfSameType.position.width : width;
        const defaultHeight =
          lastOfSameType && isEmpty ? lastOfSameType.position.height : height;
        const position = {
          x: fixPos(x, defaultWidth),
          y: fixPos(y, defaultHeight),
          width: defaultWidth,
          height: defaultHeight
        };
        const placeholders = append(
          signrequest.placeholders,
          merge(
            {
              id,
              editable: true,
              uuid: null,
              signer_index: defaultSignerIndex
            },
            rest,
            { position, type }
          )
        );
        return merge(signrequest, { placeholders });
      };
      updateSR(state, { localId: state.currentFormId }, updatePlaceholders);
      state.nextPlaceholderId += 1;
    },
    updatePlaceholder(state, update) {
      const updatePlaceholders = signrequest =>
        merge(signrequest, {
          placeholders: updateById(
            signrequest.placeholders,
            { id: update.id },
            patch(update)
          )
        });
      updateSR(state, { localId: state.currentFormId }, updatePlaceholders);
    },
    deactivatePlaceholders(state) {
      const update = {
        action_required: false
      };
      const updatePlaceholders = signrequest =>
        merge(signrequest, {
          placeholders: signrequest.placeholders.map(patch(update))
        });
      updateSR(state, { localId: state.currentFormId }, updatePlaceholders);
    },
    fillPlaceholder(state, { id, text, sposition = {}, data_uri }) {
      function fill(placeholder) {
        const origPosition = placeholder.origPosition || placeholder.position;
        const { x: origX, y: origY } = origPosition;
        const { x: sigX, y: sigY, overflowRTL } = sposition;
        const isFilled = data_uri && data_uri !== 'data:';
        const position = isFilled
          ? {
              x: overflowRTL ? sigX : origX + sigX,
              y: origY + sigY,
              width: sposition.width,
              height: sposition.height
            }
          : origPosition;
        return merge(placeholder, {
          text,
          origPosition,
          position,
          data_uri
        });
      }
      const updatePlaceholders = signrequest =>
        merge(signrequest, {
          placeholders: updateById(signrequest.placeholders, { id }, fill)
        });
      updateSR(state, { localId: state.currentFormId }, updatePlaceholders);
    },
    removePlaceholder(state, id) {
      const updatePlaceholders = signrequest =>
        merge(signrequest, {
          placeholders: signrequest.placeholders.filter(
            placeholder => placeholder.id !== id
          )
        });
      updateSR(state, { localId: state.currentFormId }, updatePlaceholders);
    },
    shiftPlaceholderIndexes(state, indexMap) {
      const fixIndex = index =>
        index < indexMap.length ? indexMap[index] : -1;
      const updatePlaceholders = signrequest =>
        merge(signrequest, {
          placeholders: signrequest.placeholders.map(placeholder =>
            merge(placeholder, {
              signer_index: fixIndex(placeholder.signer_index)
            })
          )
        });
      updateSR(state, { localId: state.currentFormId }, updatePlaceholders);
    },
    startSigning(state) {
      state.signingMode = true;
    },
    setDownloadAll(state, value) {
      state.isDownloadingAll = value;
    },
    updateSigner(state, update) {
      const updateSigners = signrequest =>
        merge(signrequest, {
          signers: updateById(signrequest.signers, update, patch(update))
        });
      updateSR(state, { localId: state.currentFormId }, updateSigners);
    }
  },
  actions: {
    resetData({ dispatch, commit }) {
      commit('reset');
      dispatch('copyUser');
    },
    copyUser({ dispatch, getters, rootGetters }) {
      const {
        signers: [myself, ...others],
        message,
        subject
      } = getters.signrequestForm;
      if (!rootGetters['users/userLoggedIn']) {
        return;
      }

      const { email } = rootGetters['users/user'];
      const teamSettings = rootGetters['users/teamSettings'];
      dispatch('updateSignrequestForm', {
        signers: [merge(myself, { email }), ...others],
        message: message || teamSettings.default_message || '',
        subject: subject || teamSettings.default_email_subject_line || ''
      });
    },
    updateSignrequestForm({ state, commit, getters }, update) {
      const localId =
        getters.selectedSr.form.copy && update.copy !== false
          ? getters.firstSr.localId
          : getters.selectedSr.localId;
      commit('updateFormIdx', { localId, form: update });
    },
    updateSignrequestFormIdx({ commit }, { form, localId }) {
      commit('updateFormIdx', { form, localId });
    },
    selectForm({ commit }, { localId }) {
      commit('setCurrentFormId', { localId });
    },
    setWho({ dispatch, getters }, value) {
      const {
        signers: [myself, ...others]
      } = getters.signrequestForm;
      const level = value === 'o' ? 'cc' : 'signature';
      return dispatch('updateSignrequestForm', {
        signers: [merge(myself, { level }), ...others]
      });
    },
    nextForm({ state, commit, getters }) {
      const idx = state.signrequests.indexOf(getters.selectedSr);
      const nextSr = state.signrequests[idx + 1];
      if (!nextSr) {
        return;
      }
      commit('setCurrentFormId', { localId: nextSr.localId });
    },
    async uploadFile(
      { dispatch },
      { url, file, box_folder_id, box_enterprise_id }
    ) {
      const data = new FormData();
      if (box_folder_id) {
        data.append('box_folder_id', box_folder_id);
      }
      if (box_enterprise_id) {
        data.append('box_enterprise_id', box_enterprise_id);
      }

      data.append('file', file);

      return await dispatch(
        'api/makeRequest',
        {
          url,
          method: 'POST',
          data
        },
        { root: true }
      );
    },
    async upload(
      { state, dispatch, getters, commit },
      {
        localId,
        file,
        link,
        templateUUID,
        docUUID,
        frontendId,
        publicTemplateId,
        fromEmail,
        integrationId,
        integration,
        box_file_id,
        box_folder_id,
        box_enterprise_id,
        no_copy,
        asTemplate
      }
    ) {
      const [{ uuid: parentUUID }] = state.signrequests;
      const url = formatQs(
        '/docs/upload/',
        dropEmpty({
          parent_doc: parentUUID && parentUUID !== docUUID ? parentUUID : null,
          as_template: asTemplate ? '1' : null
        })
      );
      let uploadReq, sfUUID;
      if (integration === 'salesforce') {
        let sfResponse = await dispatch('salesforce/upload', {
          uuid: integrationId
        });
        sfUUID = sfResponse.uuid;
      }

      if (file) {
        uploadReq = dispatch('uploadFile', {
          file,
          url,
          box_folder_id,
          box_enterprise_id
        });
      } else {
        const data = dropEmpty({
          doc_url: link,
          template_uuid: templateUUID,
          doc_uuid: docUUID || sfUUID,
          frontend_id: frontendId,
          public_template_id: publicTemplateId,
          from_email: fromEmail,
          box_file_id,
          box_folder_id,
          box_enterprise_id,
          box_token: global.DEVELOPER_TOKEN || null,
          no_copy
        });

        uploadReq = await dispatch(
          'api/makeRequest',
          { url, method: 'POST', data },
          { root: true }
        );
      }
      const response = await uploadReq;
      // We can't just take doc.uuid from response, since
      // it would only work for main document.
      // When second document is uploaded, we would have
      // UUID of main document in doc.uuid while uuid of newly-uploaded
      // document would go to extra_docs[:last].uuid.
      // Here we assume that document uploaded by docUUID would have
      // same uuid in response, as it's essentially GET operation.
      // We can't take uuid of last document either, because document
      // loaded by uuid could have extra docs already.
      const allDocs = [response.doc, ...(response.extra_docs || [])];
      const doc = docUUID
        ? findById(allDocs, { uuid: docUUID })
        : allDocs.slice(-1)[0];
      if (!doc) {
        dispatch('uploadFailed', {
          localId,
          silent: Boolean(response.message)
        });
        return response;
      }
      commit('uploaded', {
        localId,
        doc
      });
      commit('setTemplateDefaults', localId);

      return { doc, extra: docUUID ? doc.extra_docs : response.extra_docs };
    },
    async uploadFailed(
      { state, commit, dispatch },
      { localId, silent = false }
    ) {
      const { file } = findById(state.signrequests, { localId });
      if (!silent) {
        dispatch(
          'messages/addMessage',
          {
            type: 'error',
            msg: 'E_BAD_DOC'
          },
          { root: true }
        );
      }
      if (state.signrequests.length > 1) {
        commit('discardSignrequest', localId);
      } else {
        commit('uploadFailed', { localId, keepFile: isPdf(file) });
      }
    },
    async downloadExtra({ commit, dispatch, getters }, { extra = [] }) {
      return Promise.all(extra.map(async doc => dispatch('addFile', { doc })));
    },
    async apiDiscardFile({ state, dispatch }, { localId, silent = false }) {
      const { uuid } = findById(state.signrequests, { localId });
      if (uuid) {
        const url = `/docs/del-doc/${uuid}/`;
        await dispatch(
          'api/makeRequest',
          { url, method: 'POST', silent },
          { root: true }
        );
        dispatch('events/trackDocumentRemovedEvent', {}, { root: true });
      }
    },
    async discardFile(
      { state, commit, dispatch, getters },
      { localId, silent = false }
    ) {
      dispatch('apiDiscardFile', { localId, silent });
      if (state.signrequests.length > 1) {
        commit('discardSignrequest', localId);
      } else {
        commit('discardFile', { localId });
      }
    },
    async replaceFile({ state, dispatch, commit }, { localId, file }) {
      async function sync() {
        const url = `/docs/replace-original-file/signrequest/${uuid}/`;
        const data = new FormData();
        data.append('file', file);
        const { status } = await dispatch(
          'api/makeRequest',
          { url, method: 'POST', data },
          { root: true }
        );
        if (status !== 'SUCCESS') {
          return null;
        }
        const response = await dispatch('waitConverted', {
          localId,
          doc: { uuid, status: { code: 'co' } }
        });
        if (!response || !response.original_as_pdf) {
          return null;
        }
        if (!contents) {
          await dispatch('fetchContents', {
            localId,
            url: response.original_as_pdf
          });
        }
      }

      const { uuid, uploadPromise } = findById(state.signrequests, { localId });
      const { contents } = await (isPdf(file) ? readFile(file) : {});
      if (contents) {
        commit('replaceFile', { localId, name: file.name, file, contents });
      } else {
        commit('replaceFile', { localId, name: file.name });
      }

      await uploadPromise;

      commit('setUploadPromise', {
        localId,
        promise: sync().then(() => commit('clearPromise', { localId }))
      });
    },
    markReclaim({ commit }) {
      commit('markReclaim');
    },
    async readContents({ commit }, { localId, file }) {
      const { contents } = await readFile(file);
      commit('setFileContents', { contents, localId, type: file.type });
    },
    fetchContents: canRetry(async function fetchContents(
      { commit, dispatch },
      { localId, url: lazyUrl }
    ) {
      const url = await lazyUrl;
      if (!url) {
        return dispatch('uploadFailed', { localId, silent: true });
      }
      // needs a polifill in IE
      const response = await fetch(url, {
        mode: 'cors',
        credentials: 'omit'
      });
      if (response.status === 200) {
        const type = response.headers.get('Content-Type');
        const contents = new Uint8Array(await response.arrayBuffer());
        commit('setFileContents', { contents, localId, type });
      } else {
        dispatch('uploadFailed', { localId });
      }
    }),
    demoFile({ dispatch }) {
      return dispatch('addFile', {
        link: DEMO_URL,
        name: DEMO_NAME,
        source: 'demo'
      });
    },
    async fromFile({ commit, dispatch, getters }, file) {
      return dispatch('addFile', { file, source: 'file' });
    },
    async fromFileList({ dispatch }, files) {
      for (let file of files) {
        let ret = await dispatch('addFile', { file, source: 'file' });
        if (!ret || !ret.doc) {
          break;
        }
      }
    },
    fromTemplate(
      { dispatch },
      { uuid, name, box_file_id, box_folder_id, box_enterprise_id }
    ) {
      return dispatch('addFile', {
        name,
        templateUUID: uuid,
        box_file_id,
        box_folder_id,
        box_enterprise_id,
        source: 'template'
      });
    },
    fromIntegration({ dispatch }, { uuid, name, integration }) {
      return dispatch('addFile', {
        name,
        integrationId: uuid,
        integration,
        source: integration
      });
    },
    fromDropbox({ dispatch }, { link, name }) {
      return dispatch('addFile', { name, link, source: 'dropbox' });
    },
    fromBox(
      { dispatch },
      { name, no_copy, box_file_id, box_folder_id, box_enterprise_id }
    ) {
      return dispatch('addFile', {
        name,
        no_copy,
        source: 'box',
        box_file_id,
        box_folder_id,
        box_enterprise_id
      });
    },
    async addFile(
      { state, commit, dispatch, getters },
      {
        name,
        file,
        link,
        templateUUID,
        docUUID,
        frontendId,
        publicTemplateId,
        fromEmail,
        integrationId,
        integration,
        doc,
        box_file_id,
        box_folder_id,
        box_enterprise_id,
        no_copy,
        source,
        isPrepared,
        asTemplate
      }
    ) {
      if (isPdf(file)) {
        commit('pushFile', { file });
      } else if (file) {
        commit('pushFile', { name: file.name });
      } else if (doc) {
        commit('pushFile', { name: doc.name, uuid: docUUID });
      } else {
        commit('pushFile', {
          name,
          integration,
          integrationId
        });
      }
      const [{ localId }] = state.signrequests.slice(-1);
      if (doc) {
        commit('uploaded', { localId, doc, isPrepared });
        commit('setTemplateDefaults', localId);
      }

      async function getDoc() {
        const response = await uploadPromise;
        return response.doc;
      }

      async function getLink() {
        if (link === DEMO_URL) {
          return DEMO_PATH;
        }
        const apiDoc = await getDoc();
        if (apiDoc && apiDoc.original_as_pdf) {
          return apiDoc.original_as_pdf;
        }
        const response = await convertPromise;
        return response && response.original_as_pdf;
      }

      const uploadPromise = doc
        ? { doc }
        : dispatch('upload', {
            localId,
            file,
            link,
            templateUUID,
            docUUID,
            frontendId,
            publicTemplateId,
            fromEmail,
            integrationId,
            integration,
            box_file_id,
            box_folder_id,
            box_enterprise_id,
            no_copy,
            asTemplate
          });
      const convertPromise = dispatch('waitConverted', {
        localId,
        doc: getDoc(),
        asTemplate
      });
      const fetchPromise = isPdf(file)
        ? dispatch('readContents', { localId, file })
        : dispatch('fetchContents', {
            localId,
            url: getLink()
          });

      const waitPromise = Promise.all([
        uploadPromise,
        fetchPromise,
        convertPromise
      ]).then(() => commit('clearPromise', { localId }));
      commit('setUploadPromise', {
        localId,
        promise: waitPromise
      });
      const result = await uploadPromise;
      if (source) {
        dispatch(
          'events/trackDocumentUploadedEvent',
          { source },
          { root: true }
        );
      }
      return result;
    },
    async fromUrlApiv1(
      { state, commit, dispatch, getters },
      { url, canRestore }
    ) {
      const [{ localId }] = state.signrequests;
      const { searchParams, pathname } = url;
      const docQuery = {
        link: searchParams.get('doc_url'),
        docUUID: searchParams.get('doc_uuid'),
        frontendId: searchParams.get('frontend_id'),
        templateUUID: searchParams.get('template_uuid'),
        publicTemplateId: searchParams.get('public_template_id'),
        fromEmail: searchParams.get('from_email')
      };
      const isDoc = Object.values(docQuery).reduce(
        (acc, field) => acc || field,
        false
      );
      const isDemo = Boolean(searchParams.get('is_demo'));
      const isSalesforce = pathname === '/salesforce/form';
      const isApi = searchParams.get('api') === 'v1';
      const isInternal = searchParams.get('source') === 'self';

      dispatch('resetData');

      commit('setPopup', {
        flag:
          searchParams.has('popupWidth') || searchParams.has('sr_window_id'),
        next: searchParams.get('next'),
        close: searchParams.get('close'),
        sr_window_id: searchParams.get('sr_window_id')
      });
      if (isSalesforce) {
        return dispatch('salesforce/init', { url });
      }
      if (!isApi && !isDemo) {
        return dispatch('loadRecentDocs', { canRestore });
      }
      if (!isDemo && !isDoc) {
        return null;
      }

      const prefill = parseUrlApi(searchParams);

      await dispatch('users/getUser', {}, { root: true });
      if (
        docQuery.docUUID &&
        !docQuery.frontendId &&
        !isInternal &&
        !this.getters['users/userLoggedIn']
      ) {
        const logIn = await dispatch(
          'modals/showLoginModal',
          {},
          { root: true }
        );
        if (!logIn) {
          return null;
        }
      }
      if (this.getters['users/userLoggedIn']) {
        prefill.signers = prefill.signers.map((signer, idx) =>
          idx === 0
            ? merge(signer, { email: this.getters['users/user'].email })
            : signer
        );
      }
      commit('updateFormIdx', { localId, form: prefill });

      const { doc, extra } = await (isDemo
        ? dispatch('demoFile')
        : dispatch('addFile', docQuery));

      if (!doc) {
        return null;
      }
      await dispatch('downloadExtra', { extra });

      if (doc && doc.signrequest) {
        const { signers, ...rest } = doc.signrequest;
        commit('updateFormIdx', { localId, form: rest });

        if (signers.length) {
          commit('updateFormIdx', {
            localId,
            form: { signers: Object.freeze(signers) }
          });
        } else {
          commit('setTemplateDefaults', localId);
        }
      }

      return true;
    },
    async loadRecentDocs({ commit, dispatch }, { canRestore = false }) {
      const url = '/docs/user-docs/';
      const { latest_doc } = await dispatch(
        'api/makeRequest',
        {
          url,
          method: 'GET'
        },
        { root: true }
      );

      if (latest_doc && latest_doc.uuid && canRestore) {
        await dispatch('addFile', { doc: latest_doc });
        await dispatch('downloadExtra', { extra: latest_doc.extra_docs });
      }

      return null;
    },
    docStatusReq: request('GET', '/docs/doc-status/:uuid/'),
    restoreDocReq: request('GET', '/docs/user-doc/:uuid/:signer/'),
    restoreDocOrStatus({ dispatch }, { uuid, signer, signerToken, retry }) {
      if (!signer) {
        return dispatch('docStatusReq', { uuid });
      }
      return dispatch('restoreDocReq', {
        uuid,
        signer,
        data: dropEmpty({
          reloading: retry ? '1' : null
        }),
        headers: dropEmpty({
          'Signer-Token': signerToken || null
        })
      });
    },
    restoreDocRequest: canRetry(async function(
      { dispatch },
      { uuid, signer, signerToken, retry }
    ) {
      const { requirement, ...response } = await dispatch(
        'restoreDocOrStatus',
        { uuid, signer, signerToken, retry }
      );
      let isUnlocked = false;
      if (requirement === 'password') {
        isUnlocked = await dispatch(
          'modals/showEnterPasswordModal',
          {
            signer_uuid: signer
          },
          { root: true }
        );
      } else if (requirement === 'text_message') {
        isUnlocked = await dispatch(
          'modals/showVerifyPhoneModal',
          {
            signer_uuid: signer,
            verify_phone_number: response.phone_number
          },
          { root: true }
        );
      }
      if (isUnlocked) {
        throw new RetryError();
      }
      if (response.doc && response.doc.status.code === 'co') {
        throw new RetryError(CONVERT_RETRY_TIMEOUT);
      }
      return response;
    }),
    async restoreDoc(
      { commit, dispatch },
      { uuid, signer, signerToken, next, hideDecline }
    ) {
      const { doc, expired, next: serverNext } = await dispatch(
        'restoreDocRequest',
        {
          uuid,
          signer,
          signerToken
        }
      );
      if (!doc) {
        // something is wrong here, but message should be shown by http
        // middleware
        //redirect on expired
        if (expired) {
          const url = `/expired/?uuid=${uuid}&signer=${signer}`;
          await dispatch('navigateTo', url);
        }
        return;
      }

      await dispatch('resetData');
      await dispatch('addFile', { doc });
      await dispatch('resetLanguage');
      await commit('setSignParams', {
        signerToken,
        disableAttachments: Boolean(doc.signrequest.disable_attachments),
        disableEmails: Boolean(doc.signrequest.disable_emails),
        disableText: Boolean(doc.signrequest.disable_text),
        disableDate: Boolean(doc.signrequest.disable_date),
        disableTextSignatures: Boolean(doc.signrequest.disable_text_signatures),
        disableDrawSignatures: Boolean(doc.signrequest.disable_draw_signatures),
        disableUploadSignatures: Boolean(
          doc.signrequest.disable_upload_signatures
        ),
        forceSignatureColor: doc.signrequest.force_signature_color || null,
        useStampedSignatues: Boolean(doc.signrequest.use_stamped_signatures),
        serverNext: serverNext || null,
        hideDecline: Boolean(hideDecline),
        tooltip: null,
        next
      });
      if (doc.status.code === 'si' && doc.is_download_all) {
        await commit('setDownloadAll', true);
      }
      await commit('startSigning');
      await dispatch('showHelperModal', { uuid, signer });
      await dispatch('confirmEsign');
    },
    async prepareDoc(
      { getters, commit, dispatch },
      { uuid, signer, signerToken }
    ) {
      if (uuid === getters.selectedSr.uuid) {
        return;
      }
      const { doc } = await dispatch('restoreDocOrStatus', {
        uuid,
        signer,
        signerToken
      });
      if (!doc) {
        return null;
      }
      return dispatch('addFile', { doc, isPrepared: true });
    },
    async restoreTemplateRequest({ dispatch }, { uuid }) {
      const url = `/docs/get-template/${uuid}/`;
      return dispatch(
        'api/makeRequest',
        {
          url,
          method: 'GET'
        },
        { root: true }
      );
    },
    async restoreTemplate({ commit, dispatch }, { uuid }) {
      const { doc } = await dispatch('restoreTemplateRequest', { uuid });
      if (!doc) {
        return null;
      }
      commit('reset');
      await dispatch('addFile', { doc, asTemplate: true, docUUID: uuid });
    },
    async startBulkSend({ commit, dispatch }, { uuid }) {
      const params = await dispatch(
        'modals/showBulkSendModal',
        { uuid },
        { root: true }
      );
      if (!params) {
        return;
      }
      commit('setBulkSendParams', params);
    },
    async reclaim({ dispatch, state, commit }, localId) {
      const { uuid } = findById(state.signrequests, { localId });
      if (!uuid) {
        return null;
      }
      const { doc } = await dispatch('upload', { localId, docUUID: uuid });
      if (doc) {
        await dispatch('waitConverted', { localId, doc });
      }
    },
    waitConverted: canRetry(async function waitConverted(
      { state, commit, dispatch },
      { localId, doc: lazyDoc, asTemplate }
    ) {
      const { uuid, status } = (await lazyDoc) || {};
      const signrequest = findById(state.signrequests, { localId }) || {};
      if (!uuid || signrequest.uuid !== uuid) {
        return null;
      }

      // New homebox doesn't show document in error-converted
      // status, since they are useless just delete it and forget
      if (status.code === 'ec') {
        dispatch('apiDiscardFile', { localId, silent: true });
        dispatch('uploadFailed', { localId });
        return lazyDoc;
      }
      // We only need to wait for conversion if it's
      // still happening. Templates for example
      // are pre-converted and would have status = new
      // instead.
      // If no conversion had ever started for a document,
      // API would shamelessly return {ready: false} all the time!

      if (status.code !== 'co') {
        return lazyDoc;
      }

      const url = formatQs(
        `/docs/converted/${uuid}/`,
        dropEmpty({
          is_template: asTemplate ? '1' : null
        })
      );
      const { ready, error, netError, ...doc } = await dispatch(
        'api/makeRequest',
        { url, method: 'GET' },
        { root: true }
      );
      if (!error && !ready) {
        throw new RetryError(CONVERT_RETRY_TIMEOUT);
      }
      if (doc.pages && !error) {
        commit('setPages', { doc, localId });
      } else if (netError) {
        dispatch('uploadFailed', { localId, keepFile: true, silent: true });
      } else {
        dispatch('apiDiscardFile', { localId, silent: true });
        dispatch('uploadFailed', { localId });
      }
      return doc;
    }),
    async waitAllUploaded({ state, dispatch }) {
      await Promise.all(
        state.signrequests.map(signrequest => signrequest.uploadPromise)
      );
    },
    async waitAllReady({ state, dispatch }) {
      await dispatch('waitAllUploaded');
      await Promise.all(
        state.signrequests.map(signrequest =>
          signrequest.claimed ? null : dispatch('reclaim', signrequest.localId)
        )
      );
      return state.signrequests.reduce(
        (acc, signrequest) => acc && signrequest.claimed,
        true
      );
    },
    async checkExistingUser({ state, dispatch }, email) {
      let response;
      try {
        response = await dispatch(
          'users/checkEmail',
          {
            email
          },
          { root: true }
        );
      } catch (e) {
        // either failed or throtlled
        return null;
      }
      if (response.registered) {
        const [{ uuid: parentUUID }] = state.signrequests;
        const next = parentUUID && `/#/?api=v1&doc_uuid=${parentUUID}&new=yes`;
        dispatch('markReclaim');
        dispatch(
          'modals/showLoginModal',
          next ? { next, ...response } : response,
          { root: true }
        );
      }
    },
    async saveTemplate({ getters, dispatch }) {
      const doc = serializeTemplate(getters.firstSr);
      const { boxFileId, boxFolderId, boxFileVersionId } = getters.firstSr;
      const data = dropEmpty({
        doc: doc || null,
        uuid: doc.uuid || null,
        box_file_id: boxFileId || null,
        box_folder_id: boxFolderId || null,
        box_file_version_id: boxFileVersionId || null
      });
      const url = '/docs/save-template/';
      const { status } = await dispatch(
        'api/makeRequest',
        { url, method: 'POST', data },
        { root: true }
      );
      return status === 'SUCCESS';
    },
    async bulkSend({ state, dispatch }, { uuid }) {
      const url = `/docs/get-bulk-send/${uuid}/csv/`;
      const {
        task_id,
        bulksend_id,
        results,
        emailsPasted
      } = state.bulkSendParams;
      const data = {
        send: true,
        task_id,
        bulksend_id,
        email_list: results,
        from_email: emailsPasted || false
      };
      const { status } = await dispatch(
        'api/makeRequest',
        { url, method: 'POST', data },
        { root: true }
      );
      return status === 'SUCCESS';
    },
    create: wrapWith(
      [
        confirmTermsAndConds,
        canForceLogin,
        f => blocking(f, 'isCreating', 'setCreateProgress')
      ],
      async function create(
        { dispatch, commit, getters, state },
        { provisionalUUID = null } = {}
      ) {
        if (!(await dispatch('waitAllReady'))) {
          return null;
        }

        const [
          { uuid: parentUUID, integration, integrationId }
        ] = state.signrequests;
        const integrationDataKey = {
          salesforce: 'salesforce/getIntegrationData'
        }[integration];
        const integrationData = integrationDataKey
          ? getters[integrationDataKey](integrationId, parentUUID)
          : {};

        const url = '/docs/signrequest/';
        const data = serializeSignrequests(state.signrequests, {
          integrationData,
          provisionalUUID
        });
        const {
          status,
          redirect_url: documentURL,
          doc,
          ...authResponse
        } = await dispatch(
          'api/makeRequest',
          { url, method: 'POST', data },
          { root: true }
        );
        if (authResponse.registered) {
          dispatch('markReclaim');
          const next = `/#/?api=v1&doc_uuid=${parentUUID}&new=yes`;
          throw new AuthError({ next, ...authResponse }); // email, registered, backends
        }
        if (status !== 'SUCCESS') {
          return null;
        }
        if (provisionalUUID) {
          return documentURL;
        }

        dispatch('emitEvent', {
          name: 'sent',
          payload: { doc_uuid: parentUUID }
        });

        dispatch('resetData');
        dispatch(
          'users/addSignersToContacts',
          doc.signrequest.signers.slice(1),
          {
            root: true
          }
        );
        return documentURL;
      }
    ),
    async addAttachment({ state, dispatch, commit }, { localId, file }) {
      const { uuid } = findById(state.signrequests, { localId }) || {};
      if (!uuid) {
        return null;
      }
      const doneUrl = await dispatch('create', { provisionalUUID: uuid });
      if (!doneUrl) {
        return null;
      }
      const [, , signerUUID] = doneUrl.split('/');

      const url = `/docs/upload/document-attachment/${uuid}/${signerUUID}/`;
      const data = new FormData();
      data.append('file', file);

      const { signer_attachment: attachment } = await dispatch(
        'api/makeRequest',
        { url, method: 'POST', data },
        { root: true }
      );
      if (attachment) {
        commit('appendAttachment', {
          localId,
          attachment: Object.freeze(attachment)
        });
      }
    },
    async removeAttachment({ state, dispatch, commit }, { localId, uuid }) {
      const signrequest = findById(state.signrequests, { localId });
      if (!signrequest) {
        return null;
      }
      const attachment = findById(signrequest.attachments, { uuid });
      if (!attachment) {
        return null;
      }

      var url = `/docs/delete-document-attachment/${uuid}/`;
      if (attachment.download_url) {
        const signerUUID = attachment.download_url.split('/')[4];
        url = `${url}${signerUUID}/`;
      }

      const { status } = await dispatch(
        'api/makeRequest',
        { url, method: 'POST' },
        { root: true }
      );
      if (status === 'SUCCESS') {
        commit('removeAttachment', { localId, uuid });
      }
    },
    async addSignerAttachment(
      { getters, dispatch, commit },
      { file, uuid = null }
    ) {
      const { uuid: docUUID, localId } = getters.selectedSr;
      const { signerUUID } = getters;

      const data = new FormData();
      data.append('file', file);
      const uuids = [docUUID, signerUUID, uuid].filter(part => part);
      const url = `/docs/upload/signer-attachment/${uuids.join('/')}/`;

      const { signer_attachment: attachment } = await dispatch(
        'api/makeRequest',
        { url, method: 'POST', data },
        { root: true }
      );
      if (attachment) {
        commit('prependSignerAttachment', {
          localId,
          signerUUID,
          attachment: merge(
            {
              download_url: `/docs/download-signer-attachment/${attachment.uuid}/${signerUUID}/${attachment.name}`
            },
            attachment
          )
        });
      }
    },
    async removeSignerAttachment(
      { getters, dispatch, commit },
      { attachmentUUID }
    ) {
      const { signerUUID } = getters;
      const url = `/docs/delete-signer-attachment/${attachmentUUID}/${getters.signerUUID}/`;
      const { status } = await dispatch(
        'api/makeRequest',
        { url, method: 'POST' },
        { root: true }
      );
      if (status === 'SUCCESS') {
        commit('removeSignerAttachment', {
          localId: getters.selectedSr.localId,
          signerUUID,
          attachmentUUID
        });
      }
    },
    async createTemplateFromFile(
      { dispatch },
      { name, file, box_file_id, box_folder_id, box_enterprise_id }
    ) {
      const ret = await dispatch('addFile', {
        name,
        file,
        box_file_id,
        box_folder_id,
        box_enterprise_id,
        source: 'template-upload',
        asTemplate: true
      });
      await dispatch('waitAllUploaded');
      dispatch('resetData');

      return ret;
    },
    async createTemplate({ state, getters, dispatch }) {
      const {
        uuid,
        boxFileId,
        boxFolderId,
        boxFileVersionId
      } = getters.selectedSr;
      if (!uuid) {
        return null;
      }
      const ret = await dispatch('create', { provisionalUUID: uuid });
      if (!ret) {
        return null;
      }
      const url = '/docs/save-template/';
      const { status, doc } = await dispatch(
        'api/makeRequest',
        {
          url,
          method: 'POST',
          data: dropEmpty({
            doc_uuid: uuid,
            box_file_id: boxFileId || null,
            box_folder_id: boxFolderId || null,
            box_file_version_id: boxFileVersionId || null
          })
        },
        { root: true }
      );
      if (status !== 'SUCCESS') {
        return null;
      }
      return dispatch(
        'users/addTemplate',
        { uuid: doc.uuid, name: doc.name },
        { root: true }
      );
    },
    discardCurrentDocs({ commit }) {
      commit('reset');
    },
    checkTerms: confirmTermsAndConds(() => true),
    async checkAttachments({ dispatch, getters }) {
      if (getters.requiredAttachmentsFilled) {
        return true;
      }

      if (
        !(await dispatch('modals/openAttachmentsModal', null, { root: true }))
      ) {
        return false;
      }
      return getters.requiredAttachmentsFilled;
    },
    async checkPhone({ commit, dispatch, getters }) {
      const { localId } = getters.selectedSr;
      const {
        uuid,
        verify_phone_number,
        phone_number_verified
      } = getters.currentSigner;
      if (!verify_phone_number || phone_number_verified) {
        return true;
      }
      const ret = await dispatch(
        'modals/showVerifyPhoneModal',
        { signer_uuid: uuid, verify_phone_number },
        { root: true }
      );
      if (ret) {
        commit('signerPhoneVerified', { localId, signerUUID: uuid });
      }
      return ret;
    },
    finalize: wrapWith(
      [f => blocking(f, 'isFinalizing', 'setFinalizeProgress')],
      async function finalize({ state, dispatch, getters }) {
        const { hasSigned, isLast, nextSignerUrl } = getters;
        if (hasSigned && !isLast) {
          return nextSignerUrl;
        }

        if (isLast && !(await dispatch('checkTerms'))) {
          return null;
        }

        if (!(await dispatch('checkPhone'))) {
          return null;
        }
        if (!(await dispatch('checkAttachments'))) {
          return null;
        }
        return dispatch('saveSigs');
      }
    ),
    async saveSigs({ state, dispatch, getters }) {
      const { uuid: docUUID } = getters.selectedSr;
      const { signerUUID, signParams } = getters;
      const { pageUUIDs, placeholders } = findById(state.signrequests, {
        uuid: docUUID
      });
      const sigs = placeholders
        .filter(
          placeholder => placeholder.action_required && placeholder.data_uri
        )
        .filter(placeholder => placeholder.data_uri !== 'data:')
        .map(placeholder => ({
          page_uuid: pageUUIDs[placeholder.pageNum - 1],
          placeholder_uuid: placeholder.uuid,
          x_pos: placeholder.position.x,
          y_pos: placeholder.position.y,
          width: placeholder.position.width,
          height: placeholder.position.height,
          type: placeholder.type,
          text: placeholder.text || '',
          checkbox_value: placeholder.type === 'c' ? true : null,
          data_uri: placeholder.data_uri
        }));
      if (sigs.length === 0 && !getters.canApprove) {
        return null;
      }
      const data = dropEmpty({
        doc_uuid: docUUID,
        signer_uuid: signerUUID,
        sigs,
        box_token: global.DEVELOPER_TOKEN || null
      });
      const ret = await dispatch('saveSigsRequest', { params: data });

      if (!ret) {
        return null;
      }

      dispatch(
        'messages/addMessage',
        {
          type: 'success',
          msg: signParams.disableEmails ? 'O_DONE' : 'O_SENT',
          timeout: 10000
        },
        { root: true }
      );

      dispatch('emitEvent', { name: 'signed', payload: { doc_uuid: docUUID } });
      dispatch('emitEvent', {
        name: 'finished',
        payload: { doc_uuid: docUUID }
      });

      return ret;
    },
    async decline({ state, dispatch, getters }, { message }) {
      const { uuid: docUUID } = getters.selectedSr;
      const { signerUUID } = getters;

      const data = {
        doc_uuid: docUUID,
        signer_uuid: signerUUID,
        message
      };
      const ret = await dispatch('saveSigsRequest', {
        params: data,
        declined: true
      });

      if (!ret) {
        return null;
      }

      dispatch(
        'messages/addMessage',
        {
          type: 'warning',
          msg: 'W_DECLINED',
          timeout: 10000
        },
        { root: true }
      );

      dispatch('emitEvent', {
        name: 'decline',
        payload: { doc_uuid: docUUID }
      });
      dispatch('emitEvent', {
        name: 'finished',
        payload: { doc_uuid: docUUID }
      });
      dispatch('resetData');

      return ret;
    },
    async saveSigsRequest({ dispatch, getters }, { params, declined }) {
      const { in_person } = getters.currentSigner;
      const { error, status, next, redirect_url } = await dispatch(
        'api/makeRequest',
        {
          url: '/docs/user-sig/sigs/save/',
          method: 'POST',
          data: { ...params, signer_declined: Boolean(declined) },
          headers: dropEmpty({
            'Signer-Token': getters.signParams.signerToken
          })
        },
        { root: true }
      );

      // Something went wrong and no status was reported by API.
      // Explicit status=error with or without a message should be
      // handled in modules/api.
      if (!status) {
        dispatch(
          'messages/addMessage',
          { type: 'error', msg: 'E_OOPS' },
          { root: true }
        );
      }
      if (error || status !== 'SUCCESS') {
        return null;
      }
      if (next) {
        return next;
      }
      if (redirect_url) {
        return redirect_url;
      }
      if (getters.signParams.next) {
        return getters.signParams.next;
      }

      if (in_person || this.getters['users/userLoggedIn']) {
        return '/';
      }

      return declined ? '/complete?declined=1' : '/complete';
    },
    async forward({ dispatch, commit, getters }, { reason, email }) {
      const { uuid: docUUID } = getters.selectedSr;
      const { signerUUID } = getters;
      const url = `/docs/forward-document/${docUUID}/${signerUUID}/`;
      const data = {
        email
      };
      data.forwarded_reason = reason;
      const { status } = await dispatch(
        'api/makeRequest',
        {
          url,
          method: 'POST',
          data
        },
        { root: true }
      );
      if (status !== 'SUCCESS') {
        return null;
      }
      const { uuid } = getters.currentSigner;
      commit('deactivatePlaceholders');
      commit('updateSigner', {
        uuid,
        action_required: false,
        forwarded_on: 'now'
      });
    },
    async showHelperModal({ commit, dispatch, getters }, { uuid, signer }) {
      await dispatch('users/getUser', { signerUUID: signer }, { root: true });

      const { email, has_account } = getters.currentSigner;
      if (this.getters['users/userLoggedIn']) {
        return null;
      }
      if (!getters.canSign) {
        commit('setDocumentTooltip', 'nothing-to-do');
      } else if (has_account && getVariant() === 'sr') {
        const next = `/#/document/${uuid}/${signer}/`;
        return dispatch(
          'modals/showLoginOrContinue',
          { email, next },
          { root: true }
        );
      } else if (!getters.hasActionablePlaceholders) {
        commit('setDocumentTooltip', getters.canApprove ? 'approve' : 'sign');
      }
    },
    discardTooltip({ commit }) {
      commit('setDocumentTooltip', null);
    },
    confirmEsign: canRetry(async function confirmEsign({ dispatch, getters }) {
      const { esignDisclosure, uuid } = getters.selectedSr;
      const isBoxVariant = getVariant() === 'box';
      if ((!esignDisclosure && !isBoxVariant) || (isBoxVariant && !uuid)) {
        return true;
      }

      const esignOk = await dispatch(
        'modals/showTermsModal',
        {
          approve_only: getters.canApprove,
          esign_disclosure: esignDisclosure
        },
        { root: true }
      );
      if (esignOk) {
        return true;
      }
      if (isBoxVariant) {
        // disable the option to quit the modal without approval
        // decline and forward should be available through header
        throw new RetryError();
      }

      const { declined, message } = await dispatch(
        'modals/showDeclineModal',
        {},
        { root: true }
      );
      if (!declined) {
        await dispatch('modals/hideDeclineModal', {}, { root: true });
        throw new RetryError();
      }
      const nextUrl = await dispatch('decline', { message });
      await dispatch('modals/hideDeclineModal', {}, { root: true });
      await dispatch('navigateTo', nextUrl);
      return false;
    }),
    navigateTo({ rootGetters }, url) {
      if (url === null) {
        return null;
      }

      const push = path => history.push(rootGetters['api/makeURL'](path));

      if (url === 'close') {
        push('/');
        setTimeout(() => window.close(), 3000);
      } else if (url.startsWith('/file/')) {
        history.push(url);
      } else if (url.startsWith('/folder/')) {
        history.push(url);
      } else if (url === '') {
        push('/');
      } else if (url.startsWith('/')) {
        push(url);
      } else if (url.startsWith('document/')) {
        push('/' + url);
      } else if (url.startsWith('http://') || url.startsWith('https://')) {
        history.assign(url);
      }
    },
    async textToImage({ getters, dispatch }, { text }) {
      const { uuid: docUUID } = getters.selectedSr;
      const { signerUUID } = getters;

      return dispatch(
        'api/makeRequest',
        {
          url: '/docs/textimg/',
          method: 'POST',
          data: {
            text: text,
            doc_uuid: docUUID,
            signer_uuid: signerUUID
          }
        },
        { root: true }
      );
    },
    async stampImage({ getters, dispatch }, { data_uri, ...origSign }) {
      const { uuid: docUUID } = getters.selectedSr;
      const { signerUUID } = getters;
      const timezone = jstz.determine().name();
      const url = `/docs/signature-stamp/${docUUID}/${signerUUID}/`;
      const stampedSign = await dispatch(
        'api/makeRequest',
        {
          url,
          method: 'POST',
          data: { data_uri, timezone }
        },
        { root: true }
      );
      return merge(origSign, stampedSign, {
        is_stamped: true
      });
    },
    addPlaceholder(
      { commit, getters },
      {
        type,
        x = 0,
        y = 0,
        width,
        height,
        pageNum = 1,
        comment = '',
        external_id = null,
        required = false,
        multiline = false,
        prefill = false,
        ...rest
      }
    ) {
      commit('pushPlaceholder', {
        defaultSignerIndex: getters.defaultSignerIndex,
        type,
        position: { x, y, width, height },
        pageNum,
        comment,
        external_id,
        required,
        multiline,
        prefill,
        ...rest
      });
    },
    fillPlaceholder({ commit }, data) {
      commit('fillPlaceholder', data);
    },
    updatePlaceholder({ commit }, data) {
      commit('updatePlaceholder', data);
    },
    removePlaceholder({ commit }, id) {
      commit('removePlaceholder', id);
    },
    autoFixPlaceholderIndexes({ commit, getters }) {
      commit('shiftPlaceholderIndexes', getters.autoFixPlaceholderMap);
    },
    async resetLanguage({ state, dispatch }) {
      const language =
        state.signer && state.signer.force_language && state.signer.language;
      if (language) {
        dispatch('conf/switchLang', { lang: language }, { root: true });
      }
    },
    async resendDocumentEmail({ dispatch }, { uuid, signer }) {
      const url = `/docs/resend-document-email/${uuid}/${signer}/`;
      await dispatch('api/makeRequest', { url, method: 'GET' }, { root: true });
    },
    async getDocumentStatus({ dispatch }, { uuid }) {
      const url = `/docs/doc-status/${uuid}/`;
      const response = await dispatch(
        'api/makeRequest',
        { url, method: 'GET' },
        { root: true }
      );

      if (!response || !response.doc) {
        return null;
      }
      return response.doc;
    },
    async verifySignerPhone({ dispatch }, { signer_uuid, code }) {
      const response = await dispatch(
        'api/makeRequest',
        {
          url: '/verifications/text/verify-text-message/',
          method: 'POST',
          data: {
            signer_uuid,
            code
          }
        },
        { root: true }
      );
      return response.status === 'SUCCESS' ? true : false;
    },

    async sendTextVerificationMessage({ dispatch }, { signer_uuid }) {
      return await dispatch(
        'api/makeRequest',
        {
          url: '/verifications/text/send-text-message/',
          method: 'POST',
          data: {
            signer_uuid
          }
        },
        { root: true }
      );
    },
    async emitEvent({ dispatch }, { name, payload }) {
      // Implement emit windowService events
      // https://app.asana.com/0/1158294475182955/1200213520987931
      const { windowService } = await getAngularDeps('windowService');
      if (windowService) {
        windowService.emit(name, payload);
      }
    }
  },
  modules: { salesforce }
};
