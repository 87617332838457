<template>
  <v-container
    class="pa0 info-container"
    :class="{
      'info-container__free': !isPaid,
      'info-container__upsell': userLoggedIn && !isPaid,
      'info-container__lout': !userLoggedIn
    }"
    data-test-id="info-container"
  >
    <template v-if="!hideMarketing">
      <h1 v-if="isPaid" key="p" v-translate class="srtext--text">
        Legally binding digital signatures
      </h1>
      <h1 v-else-if="userLoggedIn" key="f" v-translate class="srtext--text">
        Use SignRequest to its full potential
      </h1>
      <h1 v-else key="a" class="srtext--text">
        {{
          $gettext('Boost efficiency with reliable e-signing').replace(
            '-',
            '\u2011'
          )
        }}
      </h1>

      <h2 v-if="isPaid" key="p2" v-translate class="primary--text">
        {{
          $gettext('Boost efficiency with reliable e-signing').replace(
            '-',
            '\u2011'
          )
        }}
      </h2>
      <h2 v-else-if="userLoggedIn" key="f2" v-translate class="primary--text">
        Upgrade to a Professional or Business plan today
      </h2>

      <h3 v-if="isPaid" key="p3">
        <a :href="getPageUrlForLang('features')">
          See all SignRequest features →
        </a>
      </h3>

      <template v-if="!$isMobile">
        <Features v-if="!isPaid && userLoggedIn" />
        <Reasons v-else-if="!userLoggedIn" />
      </template>

      <div>
        <free-trial
          v-if="!isPaid && !$isMobile"
          class="info-container--trial"
        />
        <sr-button
          v-if="!isPaid && !$isMobile"
          key="h"
          class="info-container--howto"
          :href="getPageUrlForLang('how-it-works')"
          sr-style="flat-white"
          round
          :border="false"
        >
          <span v-translate>How it works</span>
        </sr-button>
      </div>
    </template>
    <img
      class="info-container--illustration"
      :src="require('./dude.svg')"
      :alt="$gettext('Signing illustration')"
    />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import permissions from '@/mixins/permissions';
import Screen from '@/mixins/Screen';
import SrButton from '../elements/SrButton';
import Reasons from './Reasons';
import Features from './Features';
import FreeTrial from './FreeTrial';
import config from '@/config';

export default {
  components: { SrButton, Reasons, Features, FreeTrial },
  mixins: [permissions, Screen],
  computed: {
    ...mapGetters('conf', ['getPageUrlForLang']),
    ...mapGetters('users', ['isPaid', 'userLoggedIn']),
    hideMarketing() {
      return config.hideMarketing;
    }
  }
};
</script>

<style scoped>
.info-container {
  height: 100%;
  padding: 0 49px;
  min-height: 34rem;
  position: relative;
  text-align: center;
}

.ltr .info-container__free {
  text-align: left;
}
.rtl .info-container__free {
  text-align: right;
}
.mobile .info-container {
  margin-left: 0;
  margin-right: 0;
  min-height: unset;
  padding: 0 0.5rem;
  margin: 0 1rem;
  width: auto;
}

.ltr .mobile .info-container {
  text-align: right;
}
.rtl .mobile .info-container {
  text-align: left;
}

h1 {
  font-size: 40px;
  font-weight: 900;
  margin: auto;
  display: block;
}
.info-container__upsell h1 {
  font-size: 60px;
  text-align: center;
  max-width: 590px;
  line-height: 1.17;
}
.lang-de .info-container__upsell h1 {
  max-width: 610px;
}
.lang-fi .info-container__upsell h1 {
  font-size: 50px;
}

.mobile h1 {
  position: absolute;
  max-width: 219px;
  font-size: 34px;
  margin-top: 0;
}
@media only screen and (min-width: 600px) {
  .mobile h1 {
    max-width: 400px;
    font-size: 60px;
  }
}

.ltr .mobile h1 {
  text-align: left;
  left: 1rem;
}
.rtl .mobile h1 {
  text-align: right;
  right: 1rem;
}
h2 {
  display: block;
  font-weight: 300;
  font-size: 24px;
  line-height: 1.83;
  letter-spacing: 1px;
  margin: 0;
  margin-top: 9px;
}
.info-container__upsell h2 {
  font-size: 26px;
  text-align: center;
}
h3 {
  display: block;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.83;
  margin: 0;
  margin-top: 8px;
}
.mobile h2,
.mobile h3 {
  display: none;
}

.info-container--trial,
.info-container--howto {
  margin: auto;
  min-width: 13rem;
  height: 3.6rem;
  min-height: 3.6rem;
  font-size: 16px;
  font-weight: bold;
}
.info-container--howto {
  margin: auto 1rem;
}
img.info-container--illustration {
  margin-top: 4rem;
}

.info-container__free img.info-container--illustration {
  margin-top: 0;
  position: absolute;
  width: 458px;
  height: 383px;
  top: 13rem;
  right: 4rem;
  z-index: -1;
}
.info-container__upsell img.info-container--illustration {
  width: 410px;
  height: auto;
  top: 16rem;
}

.mobile img.info-container--illustration {
  height: 10rem !important;
  width: auto;
  margin: 0;
  margin-top: 0.5rem;
}
.mobile .info-container__upsell img.info-container--illustration {
  margin-top: 1.5rem;
}

.info-container__free.info-container__lout img.info-container--illustration {
  top: 19rem;
  width: auto;
  height: calc(100% - 19rem);
  right: 0;
  max-height: 27rem;
}

.mobile img.info-container--illustration {
  position: static;
}
</style>
